/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import SignaturePad from 'signature_pad'
import styled from 'styled-components'

export default function DrawSignature ({ formKey, onLoad }) {
  const ref = React.useRef()
  React.useEffect(() => {
    if (ref.current) {
      const signaturePad = new SignaturePad(ref.current)
      onLoad(signaturePad)
    }
  }, [onLoad])
  const width =
    window.screen.width <= 768 ? window.screen.width - 16 + '' : '460'
  return (
    <div>
      <SignatureCanvas
        ref={ref}
        id={`draw-signature-${formKey}`}
        width={width}
        height='128'
      />
    </div>
  )
}

const SignatureCanvas = styled.canvas`
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  background: #ffffff;
  border: 1px solid #aaaaaa;
`
