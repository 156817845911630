/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
export const COMPONENTS = {
  SYSTEM: 'System',
  DOCUMENTS: 'Documents',
  FORMS: 'Forms',
  WORKFLOWS: 'Workflows',
  PERMISSIONS: 'Permissions',
  APPS: 'Apps',
  SPACES: 'Spaces'
}

export const EVENT_TYPES = {
  SYSTEM_BOOTSTRAPPED: 'System Bootstrapped',
  DOCUMENT_UPDATE: 'Document Updated',
  DOCUMENT_DELETE: 'Document Deleted',
  DOCUMENT_SUBMIT: 'Document Submitted',
  DOCUMENT_ARCHIVE: 'Document Archived',
  DOCUMENT_APPROVAL: 'Document Approved',
  DOCUMENT_ACKNOWLEDGE: 'Document Acknowledged',
  DOCUMENT_SEND_BACK: 'Document Sent Back',
  DOCUMENT_DENIAL: 'Document Denied',
  DOCUMENT_REASSIGN: 'Document Reassign',
  DOCUMENT_RETRY: 'Document Retried',
  DOCUMENT_SKIP: 'Document Skipped',
  DOCUMENT_WITHDRAWN: 'Document Withdrawn',
  DOCUMENT_TASK_COMPLETE: 'Document Task Completed',
  // We hide this because we don't publish these events for privacy reasons, but
  // we have it commented because it's a documented event on the back end.
  // DOCUMENT_INITIATE: 'Document initiated',
  GADGET_UPDATE: 'Gadget Updated',
  WORKFLOW_STEP_ADD: 'Workflow Step Added',
  WORKFLOW_STEP_REMOVE: 'Workflow Step Removed',
  WORKFLOW_STEP_UPDATE: 'Workflow Step Updated',
  WORKFLOW_UPDATE: 'Workflow Updated',
  POLICY_GROUP_CREATED: 'Policy Group Created',
  POLICY_GROUP_DELETED: 'Policy Group Deleted',
  POLICY_GROUP_UPDATED: 'Policy Group Updated',
  POLICY_ATTACHED: 'Policy Attached',
  POLICY_EDITED: 'Policy Edited',
  POLICY_REMOVED: 'Policy Removed',
  IDENTITY_ATTACHED: 'Identity Attached to Policy',
  IDENTITY_REMOVED: 'Identity Removed from Policy',
  APPLICATION_CREATED: 'Application Created',
  APPLICATION_DELETED: 'Application Deleted',
  APPLICATION_UPDATED: 'Application Updated',
  SPACE_CREATED: 'Space Created',
  SPACE_DELETED: 'Space Deleted',
  SPACE_UPDATED: 'Space Updated'
}
