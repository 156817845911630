/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import { find } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router-dom'

import IdentityPicker from '../../../components/identity-picker'
import Loading from '../../../components/loading'
import { GraphQLError } from '../../../components/system-error'

const BUILD_ADMIN_POLICY_ID = '5cf7d98b5c18be79112cd4f2'
const BUILD_USER_POLICY_ID = '5d38badad5151fba67aa78b2'

export default function PermissionsPane () {
  const { data, error, loading } = useQuery(query)
  const { tenantAllowsAppCreation } = useOutletContext()
  if (loading) return <Loading />
  if (error) return <GraphQLError />
  const adminPg = find(data.systemPolicyGroups, { id: BUILD_ADMIN_POLICY_ID })
  const userPg = find(data.systemPolicyGroups, { id: BUILD_USER_POLICY_ID })
  return (
    <div className='mx-auto mb-36 mt-10 max-w-xs text-sm'>
      <fieldset>
        <label className='text-xl font-medium'>
          <Trans id='pages.spaces.permissions.system.admin' />
        </label>
        <div className='pb-3 text-sm'>
          {tenantAllowsAppCreation ? (
            <Trans id='pages.spaces.permissions.system.admin.data.create' />
          ) : (
            <Trans id='pages.spaces.permissions.system.admin.data' />
          )}
        </div>
        <IdentityPicker group={adminPg} preventLastRemoval />
      </fieldset>
      <fieldset className='pt-12'>
        <label className='text-xl font-medium'>
          <Trans id='pages.spaces.permissions.system.creators' />
        </label>
        <div className='pb-3 text-sm'>
          <Trans id='pages.spaces.permissions.system.creators.data' />
        </div>
        <IdentityPicker group={userPg} withAuthenticated />
      </fieldset>
    </div>
  )
}

const query = gql`
  query AdminSettingsPageQuery {
    systemPolicyGroups {
      id
      removable
      name
      policies {
        id
        version
        statements {
          action
          resource
          effect
        }
      }
      identities {
        type
        id
        label
      }
    }
  }
`
