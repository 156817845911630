/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import Pager from './pager'
import ResultsTable from './results-table'
import SearchBox from './search-box'

export default function ({
  loading,
  setFocus,
  params,
  response,
  onParamsChange
}) {
  const handleSearchChange = React.useCallback(
    query => {
      onParamsChange(params => ({ ...params, page: 1, query }))
    },
    [onParamsChange]
  )
  return (
    <MainWrapper>
      <MainContainer>
        <TopBar>
          <SearchBox
            loading={loading}
            setFocus={setFocus}
            onChange={handleSearchChange}
            value={params.query}
          />
          <MyPager
            onChange={page => onParamsChange({ ...params, page })}
            page={params.page}
            total={response.total}
          />
        </TopBar>
        <ResultsTable
          setFocus={setFocus}
          onChange={(sortBy, sortDir) =>
            onParamsChange({ ...params, sortBy, sortDir, page: 1 })
          }
          sortBy={params.sortBy}
          sortDir={params.sortDir}
          results={response.results}
        />
      </MainContainer>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex: 1;
`

const MainContainer = styled.div`
  padding: 1rem;
  border-top: none;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TopBar = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

const MyPager = styled(Pager)`
  margin-left: 10px;
`
