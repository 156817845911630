/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Checkbox from '../../../ui/checkbox'
import { Option, Select } from '../../../ui/select'

export const permittedCreditTypes = [
  {
    label: <Trans id='single.credit.option' />,
    type: 'single'
  },
  {
    label: <Trans id='multiple.credit.options' />,
    type: 'multiple'
  },
  {
    label: <Trans id='variable.credit.options.range' />,
    type: 'range'
  }
]

export const defaultOptions = {
  single: true,
  multiple: false,
  range: false,
  selected: 'single'
}

export function RequiredConfig ({ Gadgets }) {
  // sample shape of config, subject to change

  return (
    <>
      <Gadgets.Padded>
        <Gadgets.Custom
          configKey='options'
          label={i18n._('choose.credit.types.permitted')}
          defaultValue={defaultOptions}
        >
          {({ onChange, value }) => {
            return (
              <>
                {permittedCreditTypes.map(option => (
                  <Checkbox
                    key={`credit-checkbox-${option.type}`}
                    data-testid={`credit-checkbox-${option.type}`}
                    label={option.label}
                    checked={!!value[option.type]}
                    onChange={checked => {
                      const newValue = Object.assign({}, value)
                      newValue[option.type] = checked

                      if (
                        (checked && !value.selected) ||
                        (!checked && option.type === value.selected)
                      ) {
                        const firstChecked = permittedCreditTypes.find(
                          c => newValue[c.type]
                        )
                        if (firstChecked) {
                          newValue.selected = firstChecked.type
                        } else {
                          newValue.selected = null
                        }
                      }

                      onChange(newValue)
                    }}
                  />
                ))}
              </>
            )
          }}
        </Gadgets.Custom>
      </Gadgets.Padded>

      <Gadgets.Padded>
        <Gadgets.Custom
          configKey='options'
          label={i18n._('choose.which.should.be.selected.default')}
          defaultValue={defaultOptions}
        >
          {({ onChange, value }) => {
            const checkedItems = permittedCreditTypes.filter(
              item => value[item.type]
            )
            const defaultItem = value.selected

            if (checkedItems && checkedItems.length > 0) {
              return (
                <Select
                  data-testid='default-credit-select'
                  defaultValue={defaultItem}
                  onChange={selectedValue => {
                    const newValue = Object.assign({}, value)
                    newValue.selected = selectedValue
                    onChange(newValue)
                  }}
                >
                  {checkedItems.map(option => {
                    return (
                      <Option
                        key={`credit-selection-${option.type}`}
                        value={option.type}
                      >
                        {option.label}
                      </Option>
                    )
                  })}
                </Select>
              )
            } else {
              return (
                <ValidationMessage>
                  <Trans id='please.choose.permitted.credit.type' />
                </ValidationMessage>
              )
            }
          }}
        </Gadgets.Custom>
      </Gadgets.Padded>
    </>
  )
}
const ValidationMessage = styled.div`
  font-weight: 500;
  margin: 10px 0;
`
