/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`32`} height={`32`} viewBox={`0 0 32 32`} fill={`none`}{...props}><path d={`M16.25 3.5166C9.2086 3.5166 3.5 5.79913 3.5 8.6166C3.5 11.4341 9.2086 13.7166 16.25 13.7166C23.2914 13.7166 29 11.4341 29 8.6166C29 5.79913 23.2914 3.5166 16.25 3.5166Z`} stroke={`#666666`} strokeWidth={`3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M29 15.4167V8.6167`} stroke={`#666666`} strokeWidth={`3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M3.5 8.6167V15.4167C3.5 17.5134 6.66427 19.3142 11.1863 20.0985`} stroke={`#666666`} strokeWidth={`3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M3.5 15.4165V22.2165C3.5 24.686 7.89053 26.7498 13.7193 27.2156`} stroke={`#666666`} strokeWidth={`3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M22.35 27.4656C25.1667 27.4656 27.45 25.1823 27.45 22.3656C27.45 19.549 25.1667 17.2656 22.35 17.2656C19.5333 17.2656 17.25 19.549 17.25 22.3656C17.25 25.1823 19.5333 27.4656 22.35 27.4656Z`} stroke={`#666666`} strokeWidth={`3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M29.7167 29.732L26.3167 26.332`} stroke={`#666666`} strokeWidth={`3`} strokeLinecap={`round`} strokeLinejoin={`round`}/></svg>
);

let src = "<svg width=\"32\" height=\"32\" viewBox=\"0 0 32 32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M16.25 3.5166C9.2086 3.5166 3.5 5.79913 3.5 8.6166C3.5 11.4341 9.2086 13.7166 16.25 13.7166C23.2914 13.7166 29 11.4341 29 8.6166C29 5.79913 23.2914 3.5166 16.25 3.5166Z\" stroke=\"#666666\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M29 15.4167V8.6167\" stroke=\"#666666\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M3.5 8.6167V15.4167C3.5 17.5134 6.66427 19.3142 11.1863 20.0985\" stroke=\"#666666\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M3.5 15.4165V22.2165C3.5 24.686 7.89053 26.7498 13.7193 27.2156\" stroke=\"#666666\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M22.35 27.4656C25.1667 27.4656 27.45 25.1823 27.45 22.3656C27.45 19.549 25.1667 17.2656 22.35 17.2656C19.5333 17.2656 17.25 19.549 17.25 22.3656C17.25 25.1823 19.5333 27.4656 22.35 27.4656Z\" stroke=\"#666666\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M29.7167 29.732L26.3167 26.332\" stroke=\"#666666\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n";
export default src;
