/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { forEach, includes, keyBy } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as ObjectId from '../../components/object-id'
import * as Icons from '../../icons'
import Button from '../../ui/button'
import { Flex, Wrapper } from '../../ui/layout'
import { Option, Select } from '../../ui/select'
import { Body1 } from '../../ui/typography'
import Conditions from './branch-builder-conditions'

export const Config = ({ value, updateValue, errors, fieldsAll }) => (
  <Wrapper px={3}>
    <Body1 py={2}>
      <Trans id='route.configuration' />
    </Body1>
    <Route className='bg-light-gray-100 dark:bg-light-gray-300'>
      <DefaultTitle>
        <Trans id='default.route' />
      </DefaultTitle>
      <Select
        onChange={val => {
          updateValue(draft => {
            draft.subflows[0].disabled = val === 'disabled'
          })
        }}
        value={value.subflows[0].disabled ? 'disabled' : 'enabled'}
      >
        <Option value='enabled'>
          <Trans id='route.will.run.if.no.match.below' />
        </Option>
        <Option value='disabled'>
          <Trans id='route.will.never.run' />
        </Option>
      </Select>
    </Route>
    {value.subflows.map((flow, i) =>
      flow.rule ? (
        <Subflow
          key={flow.clientId}
          flow={flow}
          i={i}
          errors={errors}
          updateValue={updateValue}
          fieldsAll={fieldsAll}
        />
      ) : null
    )}
    <Flex justifyContent='flex-end'>
      <Button
        onClick={() =>
          updateValue(draft => {
            draft.subflows.push(newStep())
          })
        }
      >
        <Icons.Add fill='var(--white)' mr={2} />
        <Trans id='add.route' />
      </Button>
    </Flex>
  </Wrapper>
)

const Subflow = ({ flow, i, updateValue, errors, fieldsAll }) => {
  const handleChange = React.useCallback(
    rule => {
      updateValue(draft => {
        draft.subflows[i].rule = rule
      })
    },
    [i, updateValue]
  )
  return (
    <Route className='bg-light-gray-100 dark:bg-light-gray-300'>
      <Flex>
        <span>Route {i}</span>
        <Button
          transparent
          icon
          onClick={() =>
            updateValue(draft => {
              draft.subflows.splice(i, 1)
            })
          }
        >
          <Icons.Delete />
        </Button>
      </Flex>
      <Conditions
        fieldsAll={fieldsAll}
        errors={errors.filter(e => e.key === 'condition' && e.i === i)}
        onChange={handleChange}
        value={flow.rule}
      />
    </Route>
  )
}

const Route = styled.div`
  border-radius: 3px;
  margin-bottom: 8px;
  padding: 8px;
  padding-top: 0;
`

const DefaultTitle = styled.div`
  padding: 8px 0;
`

const newStep = () => {
  const id = ObjectId.generate()
  return {
    _id: id,
    clientId: id,
    steps: [],
    rule: { logicalOperator: 'and', expressions: [{}] }
  }
}

export const defaults = () => ({
  subflows: [{ steps: [] }, newStep()]
})

const unaryOperators = ['is empty', 'is not empty', 'is true', 'is false']

export const validate = (value, gadgets) => {
  const gadgetsByFormKey = keyBy(gadgets, 'formKey')
  const errors = []
  const error = (i, j, prefix, message) =>
    errors.push({ key: 'condition', i, j, prefix, message })
  forEach(value.subflows, ({ rule }, i) => {
    if (!rule) return
    if (rule.logicalOperator === 'always') return
    forEach(rule.expressions, ({ left, operator, right }, j) => {
      const checkRight = !includes(unaryOperators, operator)
      const prefix = `Route ${i} Rule ${j}:`
      if (!left || (checkRight && !right && right !== 0)) {
        error(i, j, prefix, 'Rule is incomplete')
      } else {
        if (left.error) error(i, j, prefix, left.error)
        if (checkRight && right.error) error(i, j, prefix, right.error)
      }
      if (left?.formKey && !gadgetsByFormKey[left.formKey]) {
        error(i, j, prefix, 'Rule is incomplete')
      }
      if (right?.formKey && !gadgetsByFormKey[right.formKey]) {
        error(i, j, prefix, 'Rule is incomplete')
      }
    })
  })
  return errors
}
