/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  compact,
  concat,
  find,
  flatMap,
  get,
  groupBy,
  isEmpty,
  map
} from 'lodash'
import React from 'react'

import { isValidEmail } from '../../components/email-validation'
import { guidedPersonPicker } from '../../components/feature-flags'
import { Tooltip, TooltipTrigger } from '../../components/new-tooltip'
import * as Icons from '../../icons'
import * as Lookup from '../../ui/lookup'
import Radios from '../../ui/radios'
import * as Errors from './errors'
import UserTypeahead from './user-typeahead'

export const Config = props => {
  const { value, errors, fieldsGroups } = props
  let categoryId = null
  if (get(value, 'assignee.type') === 'formRole' && value.assignee.value) {
    const formKey = get(value, 'assignee.value.formKey')
    const gadget = find(fieldsGroups, { formKey })
    categoryId = get(gadget, 'details.categoryId')
  }
  const newProps = {
    categoryId,
    errors: Errors.filter(errors, 'person-picker')
  }
  return guidedPersonPicker ? (
    <GuidedConfig {...props} {...newProps} />
  ) : (
    <FlatConfig {...props} {...newProps} />
  )
}

const FlatConfig = ({
  value,
  updateValue,
  errors,
  categoryId,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsGroups,
  fieldsEmails,
  title
}) => {
  return (
    <div className='relative px-4'>
      <div
        id='person-picker-title'
        required
        className='py-2 text-sm after:ml-1 after:text-red-400 after:content-["*"]'
      >
        {title}
      </div>
      <Radios
        aria-labelledby='person-picker-title'
        aria-describedby='person-picker-errors'
        aria-invalid={!!errors.length}
        name='person-picker'
        value={value.assignee.type}
        onChange={id =>
          updateValue(draft => {
            draft.assignee.type = id
            draft.assignee.value = null
          })
        }
        options={compact([
          {
            id: 'formRole',
            label: i18n._('person.in.role.of.group.on.form')
          },
          {
            id: 'globalRole',
            label: i18n._('person.in.role.specific.group')
          },
          { id: 'formUser', label: i18n._('person.specified.on.form') },
          !isEmpty(fieldsMultiUsers)
            ? {
                id: 'formMultiUser',
                label: i18n._('list.of.people.specified')
              }
            : null,
          fieldsEmails
            ? {
                id: 'formEmail',
                label: i18n._('email.specified.on.form')
              }
            : null,
          { id: 'globalUser', label: i18n._('any.specific.person') },
          fieldsEmails
            ? {
                id: 'email',
                label: i18n._('any.specific.email')
              }
            : null
        ])}
      />
      {value.assignee.type === 'formRole' ? (
        <>
          <label htmlFor='which-form-field' className='block py-2 text-sm'>
            <Trans id='which.field.on.form' />
          </label>
          <GadgetSelect
            id='which-form-field'
            gadgets={fieldsGroups}
            value={value}
            updateValue={updateValue}
          />
          {categoryId && (
            <>
              <label htmlFor='role-select' className='block py-2 text-sm'>
                <Trans id='which.role' />
              </label>
              <RoleSelect
                id={categoryId}
                value={
                  value.assignee.value.category
                    ? `${value.assignee.value.category.id}::${value.assignee.value.role.id}`
                    : ''
                }
                onChange={newValue => {
                  updateValue(draft => {
                    delete draft.assignee.error
                    if (!newValue) {
                      draft.assignee.value.category = null
                      draft.assignee.value.role = null
                    } else {
                      const { role, category } = newValue
                      draft.assignee.value = {
                        ...draft.assignee.value,
                        category,
                        role
                      }
                    }
                  })
                }}
              />
            </>
          )}
        </>
      ) : value.assignee.type === 'globalRole' ? (
        <>
          <div id='global-role-label' className='py-2 text-sm'>
            <Trans id='which.role.which.group' />
          </div>
          <RoleTypeahead
            aria-labelledby='global-role-label'
            value={value.assignee.value}
            onChange={newValue => {
              updateValue(draft => {
                if (!newValue) {
                  draft.assignee.value = null
                } else {
                  const { role, group } = newValue
                  draft.assignee.value = { role, group }
                }
              })
            }}
          />
        </>
      ) : value.assignee.type === 'formUser' ? (
        <>
          <label htmlFor='which-form-field' className='block py-2 text-sm'>
            <Trans id='which.field.on.form' />
          </label>
          <GadgetSelect
            id='which-form-field'
            gadgets={fieldsUsers}
            value={value}
            updateValue={updateValue}
          />
        </>
      ) : value.assignee.type === 'formMultiUser' ? (
        <>
          <label htmlFor='which-form-field' className='block py-2 text-sm'>
            <Trans id='which.field.on.form' />
          </label>
          <GadgetSelect
            id='which-form-field'
            gadgets={fieldsMultiUsers}
            value={value}
            updateValue={updateValue}
          />
        </>
      ) : value.assignee.type === 'formEmail' ? (
        <>
          <label htmlFor='which-form-field' className='block py-2 text-sm'>
            <Trans id='which.field.on.form' />
          </label>
          <GadgetSelect
            id='which-form-field'
            gadgets={fieldsEmails}
            value={value}
            updateValue={updateValue}
          />
        </>
      ) : value.assignee.type === 'globalUser' ? (
        <>
          <div id='global-user-label' className='py-2 text-sm'>
            <Trans id='which.user' />
          </div>
          <UserTypeahead
            aria-labelledby='global-user-label'
            value={value.assignee.value}
            darkModeDarkerBg
            onChange={newValue => {
              updateValue(draft => {
                draft.assignee.value = newValue && newValue.user
              })
            }}
          />
        </>
      ) : value.assignee.type === 'email' ? (
        <>
          <div id='email-label' className='py-2 text-sm'>
            <Trans id='email' />
          </div>
          <input
            aria-labelledby='email-label'
            value={value.assignee.value?.email}
            onChange={e => {
              updateValue(draft => {
                draft.assignee.value = { email: e.target.value }
              })
            }}
            type='email'
            className='kp-input w-full'
          />
        </>
      ) : null}
      <Errors.Config id='person-picker-errors' errors={errors} />
    </div>
  )
}

const GuidedConfig = ({
  value,
  updateValue,
  errors,
  categoryId,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsGroups,
  fieldsEmails,
  title
}) => {
  const [assigneeType, setAssigneeType] = React.useState(() => {
    if (
      ['formRole', 'formUser', 'formMultiUser', 'formEmail'].includes(
        value.assignee.type
      )
    ) {
      return 'fromForm'
    } else if (
      ['email', 'globalRole', 'globalUser'].includes(value.assignee.type)
    ) {
      return 'specific'
    }
  })
  const handleAssigneeTypeChange = type => {
    setAssigneeType(type)
    updateValue(draft => {
      draft.assignee.type = null
      draft.assignee.value = null
    })
  }
  const fields = concat(
    map(fieldsUsers, f => ({ ...f, assigneeType: 'formUser' })),
    map(fieldsMultiUsers, f => ({ ...f, assigneeType: 'formMultiUser' })),
    map(fieldsGroups, f => ({ ...f, assigneeType: 'formRole' })),
    map(fieldsEmails, f => ({ ...f, assigneeType: 'formEmail' }))
  )
  return (
    <div className='relative px-4'>
      <div
        id='person-picker-title'
        required
        className='py-2 text-sm after:ml-1 after:text-red-400 after:content-["*"]'
      >
        {title}
      </div>
      <Radios
        aria-labelledby='person-picker-title'
        aria-describedby='person-picker-errors'
        name='person-picker'
        value={assigneeType}
        onChange={handleAssigneeTypeChange}
        options={[
          {
            id: 'fromForm',
            label: (
              <WithTooltip
                id='fromForm'
                tooltip='You can automatically route to a group, people, and emails fields on the form.'
              >
                <Trans id='automate.route.based.on.field.on.form' />
              </WithTooltip>
            ),
            children: (
              <FromFormOptions
                value={value}
                updateValue={updateValue}
                categoryId={categoryId}
                fields={fields}
              />
            )
          },
          {
            id: 'specific',
            label: i18n._('specific.role.person.email'),
            children: (
              <SpecificOptions value={value} updateValue={updateValue} />
            )
          }
        ]}
      />
      <Errors.Config id='person-picker-errors' errors={errors} />
    </div>
  )
}

const FromFormOptions = ({ value, updateValue, categoryId, fields }) => (
  <>
    <GadgetSelect
      id='which-form-field'
      gadgets={fields}
      groupByProp='assigneeType'
      groupLabels={{
        formUser: 'Person',
        formMultiUser: 'List of People',
        formRole: 'Group',
        formEmail: 'Email'
      }}
      value={value}
      updateValue={updateValue}
    />
    {value.assignee.type === 'formRole' && categoryId && (
      <>
        <div className='text-xs !text-medium-gray-500'>
          <Trans id='tip.also.route.group.parent.role' />
        </div>
        <label htmlFor='role-select' className='block py-2 text-sm'>
          <Trans id='route.to.which.role' />
        </label>
        <RoleSelect
          id={categoryId}
          value={
            value.assignee.value.category
              ? `${value.assignee.value.category.id}::${value.assignee.value.role.id}`
              : ''
          }
          onChange={newValue => {
            updateValue(draft => {
              delete draft.assignee.error
              if (!newValue) {
                draft.assignee.value.category = null
                draft.assignee.value.role = null
              } else {
                const { role, category } = newValue
                draft.assignee.value = {
                  ...draft.assignee.value,
                  category,
                  role
                }
              }
            })
          }}
        />
      </>
    )}
  </>
)

const SpecificOptions = ({ value, updateValue }) => (
  <Radios
    aria-label='Which person, email, or group?'
    name='specific-options'
    value={value.assignee.type}
    onChange={id =>
      updateValue(draft => {
        draft.assignee.type = id
        draft.assignee.value = null
      })
    }
    options={[
      {
        id: 'globalUser',
        label: 'Person',
        children: (
          <UserTypeahead
            aria-label='Which user?'
            value={value.assignee.value}
            onChange={newValue => {
              updateValue(draft => {
                draft.assignee.value = newValue && newValue.user
              })
            }}
          />
        )
      },
      {
        id: 'email',
        label: (
          <WithTooltip
            id='email'
            tooltip='Actions sent to emails do not require an account to complete.'
          >
            <Trans id='email' />
          </WithTooltip>
        ),
        children: (
          <input
            aria-label='Email'
            value={value.assignee.value?.email}
            onChange={e => {
              updateValue(draft => {
                draft.assignee.value = { email: e.target.value }
              })
            }}
            type='email'
            className='kp-input w-full'
          />
        )
      },
      {
        id: 'globalRole',
        label: (
          <WithTooltip
            id='globalRole'
            tooltip='Roles can include multiple people like a committee.'
          >
            <Trans id='role.in.group' />
          </WithTooltip>
        ),
        children: (
          <RoleTypeahead
            aria-label='Which role from which group?'
            value={value.assignee.value}
            onChange={newValue => {
              updateValue(draft => {
                if (!newValue) {
                  draft.assignee.value = null
                } else {
                  const { role, group } = newValue
                  draft.assignee.value = { role, group }
                }
              })
            }}
          />
        )
      }
    ]}
  />
)

const GadgetSelect = ({
  id,
  gadgets,
  groupByProp,
  groupLabels,
  value,
  updateValue
}) => (
  <select
    className='kp-select'
    id={id}
    value={get(value.assignee.value, 'formKey')}
    onChange={e => {
      updateValue(draft => {
        const gadget = find(gadgets, { formKey: e.target.value })
        if (!gadget) {
          draft.assignee.value = null
        } else {
          const { formKey, label } = gadget
          draft.assignee.value = { formKey, label }
          if (gadget.assigneeType) {
            draft.assignee.type = gadget.assigneeType
          }
        }
      })
    }}
  >
    <option value='' />
    {groupByProp
      ? map(groupBy(gadgets, groupByProp), (gs, key) => (
          <optgroup key={key} label={get(groupLabels, key)}>
            {map(gs, item => (
              <option key={item.formKey} value={item.formKey}>
                {item.label}
              </option>
            ))}
          </optgroup>
        ))
      : gadgets.map(item => (
          <option key={item.formKey} value={item.formKey}>
            {item.label}
          </option>
        ))}
  </select>
)

const WithTooltip = ({ children, id, tooltip }) => (
  <>
    {children}{' '}
    <TooltipTrigger
      id={`${id}-option-tooltip`}
      className='relative inline-block align-sub'
    >
      <Icons.AlertHelp />
      <Tooltip className='w-60'>{tooltip}</Tooltip>
    </TooltipTrigger>
  </>
)

const formatUser = user =>
  `${user.displayName}${user.username ? ` (${user.username})` : ''}`

const hierarchicalCategoriesQuery = gql`
  query FetchHierarchicalCategories($id: ID!) {
    hierarchicalCategories(id: $id) {
      id
      label: name
      roleSchemas {
        id
        label: name
      }
    }
  }
`

const formatRoles = data =>
  flatMap(data.hierarchicalCategories, category => {
    const builtInRoles = [
      { id: 'members', label: '(All Roles)' },
      { id: 'admins', label: 'Administrators' }
    ]
    return map([...builtInRoles, ...category.roleSchemas], ({ id, label }) => ({
      id: `${category.id}::${id}`,
      label: `${category.label} - ${label}`,
      role: { id, label },
      category: { id: category.id, label: category.label }
    }))
  })

const RoleSelect = ({ id, onChange, value, ...rest }) => {
  const { data } = useQuery(hierarchicalCategoriesQuery, { variables: { id } })
  if (!data) return null
  const roles = formatRoles(data)
  return (
    <select
      className='kp-select'
      id='role-select'
      value={value}
      onChange={e => onChange(find(roles, { id: e.target.value }))}
    >
      <option value='' />
      {roles.map(item => (
        <option key={`${item.id}`} value={`${item.id}`}>
          {item.label}
        </option>
      ))}
    </select>
  )
}

const roleSearchQuery = gql`
  query RoleSearch($query: String!) {
    roles(args: { excludeAppBuilderGroups: true, query: $query }) {
      id
      id2: roleId
      label: name
      group {
        id
        label: name
      }
    }
  }
`

const formatRoles2 = data => {
  if (!data) return []
  const roles = map(data.roles, ({ id2, label, group }) => ({
    id: `${group.id}::${id2}`,
    label: `${group.label} - ${label}`,
    role: { id: id2, label },
    group: { id: group.id, label: group.label }
  }))
  return roles
}

const RoleLookup = ({ id, onChange, ...props }) => {
  // TODO:: Where should we splat props?
  const [query, setQuery] = React.useState('')
  const { data } = useQuery(roleSearchQuery, { variables: { query } })
  const options = formatRoles2(data)
  const aria = props['aria-labelledby']
  const lookup = Lookup.useLookup({
    id,
    options,
    onChange,
    query,
    setQuery,
    aria
  })
  return <Lookup.Control state={lookup} darkModeDarkerBg />
}

const RoleTypeahead = ({ value, ...props }) => {
  if (!value) return <RoleLookup id='role-typeahead' {...props} />
  return (
    <Lookup.Chip>
      <Lookup.ChipLabel>
        {value.group.label} - {value.role.label}
      </Lookup.ChipLabel>
      <button
        className='kp-button-transparent'
        onClick={() => {
          // TODO:: set focus to the textbox that is about to appear
          props.onChange(null)
        }}
      >
        <Icons.Close className='h-2 w-2' />
      </button>
    </Lookup.Chip>
  )
}

const getView = details => {
  const type = get(details, 'assignee.type')
  const value = get(details, 'assignee.value')
  if (!value) return null
  if (type === 'formUser') return value.label
  if (type === 'formMultiUser') return value.label
  if (type === 'globalUser') return formatUser(value)
  if (type === 'formEmail') return value.label
  if (type === 'globalRole') return `${value.group.label} - ${value.role.label}`
  if (type === 'formRole') {
    return `${value.category.label} - ${value.role.label}`
  }
  if (type === 'email') return value.email
}

export const View = ({
  details,
  errors = [],
  truncate,
  prefix,
  fontSize = '13px'
}) => {
  errors = Errors.filter(errors, 'person-picker')
  if (errors.length) return <Errors.View errors={errors} truncate={truncate} />
  const assignee = getView(details)
  return (
    <div
      className='flex items-center px-3 py-2 font-bold text-dark-gray-300'
      style={{ fontSize }}
    >
      {prefix && <div className='mr-1 font-normal'>{prefix}</div>}
      <Icons.User className='h-4 w-4 fill-black pr-1 dark:fill-medium-gray-300' />
      {truncate ? (
        <div
          className='absolute left-7 w-[calc(100%-40px)] overflow-hidden overflow-ellipsis whitespace-nowrap'
          title={assignee}
        >
          {assignee}
        </div>
      ) : (
        <div className='[word-break:break-word]'>{assignee}</div>
      )}
    </div>
  )
}

export const defaults = () => ({
  assignee: {
    type: 'formRole',
    value: null
  }
})

const _validate = (value, fieldsAll) => {
  if (!get(value, 'assignee.value')) return ['Assignee is required']
  if (value.assignee.type === 'formRole') {
    const formKey = value.assignee.value.formKey
    const gadget = find(fieldsAll, { formKey })
    if (gadget && !get(gadget, 'details.categoryId')) {
      return ['The selected form field is not assigned to a blueprint']
    }
    if (!value.assignee.value.role) return ['You must select a role']
  }
  if (
    value.assignee.type === 'email' &&
    !isValidEmail(value.assignee.value.email)
  ) {
    return ['Email is invalid']
  }
  return value.assignee.value.error ? [value.assignee.value.error] : []
}
export const validate = Errors.wrap(_validate, 'person-picker')
