/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateAppTileMutation = () => {
  const [updateAppTile] = useMutation(mutation)
  return (id, { isFavorite, name, backgroundColor, iconName }) => {
    return updateAppTile(
      getParams(id, isFavorite, name, backgroundColor, iconName)
    )
  }
}

const mutation = gql`
  mutation UpdateAppTile($args: UpdateAppTileInput!) {
    updateAppTile(args: $args) {
      id
      name
      isFavorite
      tileOptions {
        backgroundColor
        iconName
      }
    }
  }
`

const getParams = (id, isFavorite, name, backgroundColor, iconName) => ({
  variables: { args: { id, name, backgroundColor, iconName } },
  optimisticResponse: {
    updateAppTile: {
      __typename: 'App',
      id,
      isFavorite,
      name,
      tileOptions: {
        __typename: 'TileOptions',
        backgroundColor,
        iconName
      }
    }
  }
})
