/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { ReactComponent as Icon } from './icon.svg.jsx'
import { validateShape } from './validation'
import View from './view'

export default {
  meta: { label: 'Workflow Current Steps', hidden: true, Icon },
  sortSuffix: '.startDate',
  View,
  validateShape
}
