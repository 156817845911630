/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  split
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { isEmpty, uniqBy } from 'lodash'
import React from 'react'

import config from '../config'
import possibleTypes from './possible-types.json'

class GraphQLError extends Error {
  constructor (message) {
    super(message)
    this.name = 'GraphQLError'
  }
}

export const typePolicies = {
  Query: {
    fields: {
      groupsConnection: {
        keyArgs: ['args', ['query', 'categoryId', 'parentId', 'sort']],
        merge (existing, incoming) {
          if (!existing) return incoming
          const edges = uniqBy(
            [...(existing?.edges ?? []), ...(incoming?.edges ?? [])],
            'node.__ref'
          )
          return {
            ...incoming,
            edges,
            pageInfo: incoming.pageInfo
          }
        }
      }
    }
  },
  App: {
    fields: {
      viewer: { merge: true }
    }
  },
  Dataset: {
    fields: {
      draft: { merge: true }
    },
    keyFields (dataset) {
      const key = `Dataset:${dataset.id}`
      const formVersionId = dataset?.formVersion?.id
      return formVersionId ? `${key}:${formVersionId}` : key
    }
  },
  Field: {
    keyFields: false
  },
  Insight: {
    fields: {
      value: {
        keyArgs: false
      }
    }
  },
  PolicyGroup: {
    fields: {
      identities: { merge: false }
    }
  },
  RoleSchema: {
    keyFields: ['id', 'name']
  },
  Role: {
    fields: {
      membersConnection: {
        keyArgs: ['args', ['query', 'limit']],
        merge (existing, incoming) {
          if (!existing) return incoming
          const edges = uniqBy(
            [...(existing?.edges ?? []), ...(incoming?.edges ?? [])],
            'node.__ref'
          )
          return {
            ...incoming,
            edges
          }
        }
      }
    }
  },
  ActionsPaginatedEdge: {
    keyFields: ({ __typename, node }) => `${__typename}:${node.id}`
  },
  ActionsPaginatedConnection: {
    fields: {
      edges: {
        merge (existing = [], incoming) {
          const data = [...existing, ...incoming]
          const unique = [
            ...new Map(data.map(item => [item.__ref, item])).values()
          ]
          return unique.sort((a, b) => (a.__ref < b.__ref ? 1 : -1))
        }
      }
    }
  },
  Viewer: {
    fields: {
      spaces: { merge: false },
      appActionInfo: {
        merge: (_, incoming) => incoming
      }
    }
  },
  Tenant: {
    fields: { features: { merge: (_, incoming) => incoming } }
  },
  Document: {
    fields: {
      dependentDocumentConnection: {
        keyArgs: false,
        merge (existing, incoming) {
          if (!existing) return incoming
          const edges = uniqBy(
            [...(existing?.edges ?? []), ...(incoming?.edges ?? [])],
            'node.__ref'
          )
          return {
            ...incoming,
            edges
          }
        }
      },
      viewer: { merge: (_, incoming) => incoming }
    }
  }
}

export default new ApolloClient({
  ...config.apollo,
  cache: new InMemoryCache({
    typePolicies,
    possibleTypes
  }),
  link: from([
    onError(err => {
      if (err?.networkError?.response?.status === 401) {
        const url = err?.networkError?.response?.headers?.location || '/auth'
        const ret = encodeURIComponent(window.location.href)
        window.location.assign(`${url}/?return_to=${ret}`)
        return
      } else if (!isEmpty(err?.networkError)) {
        Sentry.withScope(scope => {
          scope.addBreadcrumb({
            message: <Trans id='generic.graphql.network.error' />
          })
          scope.setExtra(
            'networkError',
            JSON.stringify(err.networkError, null, 2)
          )
          Sentry.captureException(err?.networkError)
        })
      } else if (!isEmpty(err?.graphQLErrors)) {
        const pathArray = err.graphQLErrors[0]?.path
        Sentry.withScope(scope => {
          if (pathArray) {
            scope.setExtra('graphQLPath', pathArray.join('.'))
          }
          scope.setExtra(
            'graphQLErrorsObject',
            JSON.stringify(err.graphQLErrors, null, 2)
          )
          if (pathArray) {
            scope.setFingerprint(['graphql', 'error', pathArray.join('.')])
          }

          Sentry.captureException(
            new GraphQLError(err.graphQLErrors[0]?.message)
          )
        })
      } else {
        if (err?.networkError instanceof TypeError) {
          return // This is a subset of errors that spawns when requests are forcibly cancelled by the browser.
        }
        Sentry.withScope(scope => {
          scope.addBreadcrumb({ message: <Trans id='generic.graphql.error' /> })
          scope.setExtra('error', JSON.stringify(err, null, 2))
          Sentry.captureException(new Error(i18n._('generic.graphql.error')))
        })
      }
      console.error(err)
    }),
    setContext(({ operationName }, { headers }) => {
      const token = getToken()
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          'apollographql-operation-name': operationName
        }
      }
    }),
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      new WebSocketLink({
        uri: `wss://${window.location.hostname}${
          config.apollo.uri
        }?token=${getToken()}`,
        options: { reconnect: true }
      }),
      new HttpLink({
        uri: config.apollo.uri,
        credentials: 'same-origin'
      })
    )
  ])
})

function getToken () {
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  )
}
