/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { cloneDeep } from 'lodash'
import React from 'react'

import { Option, Select } from '../ui/select'

const trueFalse = [
  { key: 'true', lbl: () => i18n._('true') },
  { key: 'false', lbl: () => i18n._('false') }
]
const yesNo = [
  { key: 'yes', lbl: () => i18n._('yes') },
  { key: 'no', lbl: () => i18n._('no') }
]
const agreeDisagree = [
  { key: 'agree', lbl: () => i18n._('agree') },
  { key: 'disagree', lbl: () => i18n._('disagree') }
]
const agreeRange = [
  { key: 'strongly agree', lbl: () => i18n._('strongly.agree') },
  { key: 'agree', lbl: () => i18n._('agree') },
  { key: 'neutral', lbl: () => i18n._('neutral') },
  { key: 'disagree', lbl: () => i18n._('disagree') },
  { key: 'strongly disagree', lbl: () => i18n._('strongly.disagree') }
]
const onOff = [
  { key: 'on', lbl: () => i18n._('on') },
  { key: 'off', lbl: () => i18n._('off') }
]

export const presetsMap = {
  '--Presets--': [],
  'True/False': trueFalse,
  'Yes/No': yesNo,
  'Agree/Disagree': agreeDisagree,
  'Agree-Disagree (range)': agreeRange,
  'On/Off': onOff
}

export const gadgetPresets = {
  Radios: Object.keys(presetsMap),
  Checkboxes: Object.keys(presetsMap),
  Dropdown: Object.keys(presetsMap)
}

export default function Presets ({ onChange, gadgetType }) {
  return (
    <Select
      onChange={val =>
        onChange(
          cloneDeep(
            presetsMap[val].map(({ key, lbl }) => ({ key, lbl: lbl() }))
          )
        )
      }
      className='flex-1'
    >
      {gadgetPresets[gadgetType]?.map((preset, i) => (
        <Option key={preset + i} value={preset}>
          {preset}
        </Option>
      ))}
    </Select>
  )
}
