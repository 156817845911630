/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

export function useAnnouncekit (newEnabled) {
  const [unread, setUnread] = React.useState(window.announcekitUnread || 0)
  React.useEffect(() => {
    if (!newEnabled) return
    const unreadHandler = ({ detail }) => setUnread(detail.unread)
    document.addEventListener('announcekit:unread', unreadHandler)
    setupAnnouncekit('32GzFm')
    return () => {
      document.removeEventListener('announcekit:unread', unreadHandler)
    }
  }, [])

  const ref = React.useRef()
  React.useEffect(() => {
    if (newEnabled) return
    ref.current?.withWidget(widget => {
      addFrameTitle(widget)

      // The "button" created by announcekit-react is actually a div and
      // has some accessibility features missing.
      const openButton = widget?.container
      if (openButton) {
        openButton.role = 'button'
        openButton.tabIndex = 0
        openButton.addEventListener('keydown', handleKeyDown)
      }

      // The badge showing the unread count is rendered with an svg with no label,
      // so we need to add one.
      const badge = widget?.launcher?.badge
      if (badge) {
        const unreadCount = widget?.state?.ui?.unreadCount
        badge.setAttribute(
          'aria-label',
          i18n._('new.features.count', { unreadCount })
        )
        const svg = badge.querySelector('svg')
        if (svg) {
          // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/img_role#svg_and_roleimg
          svg.role = 'img'
        }
      }
    })

    return () => {
      ref.current?.withWidget(widget => {
        widget?.container?.removeEventListener('keydown', handleKeyDown)
      })
    }
  }, [])

  return [unread, () => window.announcekit.widget$32GzFm.open(), ref]
}

function setupAnnouncekit (key) {
  if (document.getElementById(`announce-kit-${key}`)) return
  window.announcekit = window.announcekit || {
    queue: [],
    on: function (n, x) {
      window.announcekit.queue.push([n, x])
    },
    push: function (x) {
      window.announcekit.queue.push(x)
    }
  }
  window.announcekit.push({
    widget: `https://announcekit.app/widgets/v2/${key}`,
    name: key
  })
  window.announcekit.on('widget-unread', function ({ unread }) {
    window.announcekitUnread = unread
    const event = new CustomEvent('announcekit:unread', { detail: { unread } })
    document.dispatchEvent(event)
  })
  window.announcekit.on('widget-init', function ({ widget }) {
    addFrameTitle(widget)
  })
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://cdn.announcekit.app/widget-v2.js'
  script.id = `announce-kit-${key}`
  document.body.append(script)
}

function addFrameTitle (widget) {
  const frame = widget?.frame?.frame
  if (frame) {
    frame.title = i18n._('new.feature.announcements')
  }
}

function handleKeyDown (e) {
  if ([' ', 'Enter'].includes(e.key)) {
    e.preventDefault()
    e.currentTarget.click()
  }
}
