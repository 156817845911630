/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`} fill={`none`}{...props}><g clipPath={`url(#clip0_1_11)`}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M0 0V16H16L8.00526 8.00528L16 0H0Z`} fill={`#00B586`}/></g><defs><clipPath id={`clip0_1_11`}><rect width={`16`} height={`16`} fill={`white`}/></clipPath></defs></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_1_11)\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0V16H16L8.00526 8.00528L16 0H0Z\" fill=\"#00B586\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_1_11\">\n<rect width=\"16\" height=\"16\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>\n";
export default src;
