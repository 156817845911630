/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M14 11.6667C13.7348 11.6667 13.4804 11.5613 13.2929 11.3738C13.1054 11.1862 13 10.9319 13 10.6667V7.42533C13.0385 6.15939 12.6228 4.92146 11.828 3.93533C11.0333 2.94921 9.9119 2.27999 8.66668 2.04867V0.666667C8.66668 0.489856 8.59644 0.320286 8.47141 0.195262C8.34639 0.0702379 8.17682 0 8.00001 0C7.8232 0 7.65363 0.0702379 7.52861 0.195262C7.40358 0.320286 7.33334 0.489856 7.33334 0.666667V2.04867C6.08812 2.27999 4.96676 2.94921 4.172 3.93533C3.37723 4.92146 2.96151 6.15939 3.00001 7.42533V10.6667C3.00001 10.9319 2.89465 11.1862 2.70712 11.3738C2.51958 11.5613 2.26523 11.6667 2.00001 11.6667C1.8232 11.6667 1.65363 11.7369 1.52861 11.8619C1.40358 11.987 1.33334 12.1565 1.33334 12.3333C1.33334 12.5101 1.40358 12.6797 1.52861 12.8047C1.65363 12.9298 1.8232 13 2.00001 13H14C14.1768 13 14.3464 12.9298 14.4714 12.8047C14.5964 12.6797 14.6667 12.5101 14.6667 12.3333C14.6667 12.1565 14.5964 11.987 14.4714 11.8619C14.3464 11.7369 14.1768 11.6667 14 11.6667Z`}/><path d={`M9.49065 14H6.50932C6.4684 14.0001 6.42893 14.0152 6.39844 14.0425C6.36795 14.0698 6.34857 14.1073 6.34398 14.148C6.33672 14.2095 6.33316 14.2714 6.33332 14.3333C6.33332 14.7754 6.50891 15.1993 6.82147 15.5118C7.13403 15.8244 7.55796 16 7.99998 16C8.44201 16 8.86594 15.8244 9.1785 15.5118C9.49106 15.1993 9.66665 14.7754 9.66665 14.3333C9.66681 14.2714 9.66325 14.2095 9.65598 14.148C9.65169 14.1072 9.63239 14.0695 9.60183 14.0421C9.57128 14.0148 9.53166 13.9998 9.49065 14Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M14 11.6667C13.7348 11.6667 13.4804 11.5613 13.2929 11.3738C13.1054 11.1862 13 10.9319 13 10.6667V7.42533C13.0385 6.15939 12.6228 4.92146 11.828 3.93533C11.0333 2.94921 9.9119 2.27999 8.66668 2.04867V0.666667C8.66668 0.489856 8.59644 0.320286 8.47141 0.195262C8.34639 0.0702379 8.17682 0 8.00001 0C7.8232 0 7.65363 0.0702379 7.52861 0.195262C7.40358 0.320286 7.33334 0.489856 7.33334 0.666667V2.04867C6.08812 2.27999 4.96676 2.94921 4.172 3.93533C3.37723 4.92146 2.96151 6.15939 3.00001 7.42533V10.6667C3.00001 10.9319 2.89465 11.1862 2.70712 11.3738C2.51958 11.5613 2.26523 11.6667 2.00001 11.6667C1.8232 11.6667 1.65363 11.7369 1.52861 11.8619C1.40358 11.987 1.33334 12.1565 1.33334 12.3333C1.33334 12.5101 1.40358 12.6797 1.52861 12.8047C1.65363 12.9298 1.8232 13 2.00001 13H14C14.1768 13 14.3464 12.9298 14.4714 12.8047C14.5964 12.6797 14.6667 12.5101 14.6667 12.3333C14.6667 12.1565 14.5964 11.987 14.4714 11.8619C14.3464 11.7369 14.1768 11.6667 14 11.6667Z\" />\n<path d=\"M9.49065 14H6.50932C6.4684 14.0001 6.42893 14.0152 6.39844 14.0425C6.36795 14.0698 6.34857 14.1073 6.34398 14.148C6.33672 14.2095 6.33316 14.2714 6.33332 14.3333C6.33332 14.7754 6.50891 15.1993 6.82147 15.5118C7.13403 15.8244 7.55796 16 7.99998 16C8.44201 16 8.86594 15.8244 9.1785 15.5118C9.49106 15.1993 9.66665 14.7754 9.66665 14.3333C9.66681 14.2714 9.66325 14.2095 9.65598 14.148C9.65169 14.1072 9.63239 14.0695 9.60183 14.0421C9.57128 14.0148 9.53166 13.9998 9.49065 14Z\" />\n</svg>\n";
export default src;
