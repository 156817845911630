/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.032 7.0406H3.62467C3.59092 7.04027 3.55806 7.02975 3.53041 7.01042C3.50275 6.99109 3.48158 6.96384 3.46968 6.93226C3.45779 6.90068 3.45572 6.86624 3.46375 6.83347C3.47177 6.80069 3.48952 6.77111 3.51467 6.7486L9.27267 1.68193C9.45368 1.51126 9.56138 1.27698 9.57304 1.02846C9.58471 0.77994 9.49944 0.536599 9.33521 0.349718C9.17097 0.162837 8.9406 0.0470142 8.69265 0.0266583C8.44469 0.00630245 8.19851 0.0830039 8.006 0.240598L0.551999 6.79993C0.381749 6.95008 0.245396 7.13473 0.151999 7.34163C0.0586024 7.54853 0.0102997 7.77293 0.0102997 7.99993C0.0102997 8.22693 0.0586024 8.45133 0.151999 8.65823C0.245396 8.86513 0.381749 9.04978 0.551999 9.19993L8.00467 15.7579C8.19733 15.9135 8.44269 15.9887 8.68945 15.9676C8.93622 15.9466 9.16531 15.831 9.32884 15.645C9.49237 15.459 9.57769 15.217 9.56698 14.9696C9.55626 14.7221 9.45033 14.4884 9.27133 14.3173L3.51333 9.2506C3.48819 9.22809 3.47044 9.19851 3.46241 9.16573C3.45438 9.13295 3.45645 9.09851 3.46835 9.06693C3.48025 9.03535 3.50141 9.00811 3.52907 8.98878C3.55673 8.96944 3.58959 8.95892 3.62333 8.9586H15.032C15.2821 8.952 15.5198 8.848 15.6944 8.66875C15.869 8.4895 15.9667 8.24916 15.9667 7.99893C15.9667 7.7487 15.869 7.50837 15.6944 7.32911C15.5198 7.14986 15.2821 7.04586 15.032 7.03926V7.0406Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.032 7.0406H3.62467C3.59092 7.04027 3.55806 7.02975 3.53041 7.01042C3.50275 6.99109 3.48158 6.96384 3.46968 6.93226C3.45779 6.90068 3.45572 6.86624 3.46375 6.83347C3.47177 6.80069 3.48952 6.77111 3.51467 6.7486L9.27267 1.68193C9.45368 1.51126 9.56138 1.27698 9.57304 1.02846C9.58471 0.77994 9.49944 0.536599 9.33521 0.349718C9.17097 0.162837 8.9406 0.0470142 8.69265 0.0266583C8.44469 0.00630245 8.19851 0.0830039 8.006 0.240598L0.551999 6.79993C0.381749 6.95008 0.245396 7.13473 0.151999 7.34163C0.0586024 7.54853 0.0102997 7.77293 0.0102997 7.99993C0.0102997 8.22693 0.0586024 8.45133 0.151999 8.65823C0.245396 8.86513 0.381749 9.04978 0.551999 9.19993L8.00467 15.7579C8.19733 15.9135 8.44269 15.9887 8.68945 15.9676C8.93622 15.9466 9.16531 15.831 9.32884 15.645C9.49237 15.459 9.57769 15.217 9.56698 14.9696C9.55626 14.7221 9.45033 14.4884 9.27133 14.3173L3.51333 9.2506C3.48819 9.22809 3.47044 9.19851 3.46241 9.16573C3.45438 9.13295 3.45645 9.09851 3.46835 9.06693C3.48025 9.03535 3.50141 9.00811 3.52907 8.98878C3.55673 8.96944 3.58959 8.95892 3.62333 8.9586H15.032C15.2821 8.952 15.5198 8.848 15.6944 8.66875C15.869 8.4895 15.9667 8.24916 15.9667 7.99893C15.9667 7.7487 15.869 7.50837 15.6944 7.32911C15.5198 7.14986 15.2821 7.04586 15.032 7.03926V7.0406Z\" />\n</svg>\n";
export default src;
