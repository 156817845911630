/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { map, range } from 'lodash'
import React from 'react'

import { Option, Select } from '../../../ui/select'

export function RequiredConfig ({ Gadgets }) {
  return (
    <>
      <Gadgets.Custom configKey='from' label='From'>
        {({ onChange, value }) => (
          <Select onChange={onChange} value={value}>
            <Option value='0'>0</Option>
            <Option value='1'>1</Option>
          </Select>
        )}
      </Gadgets.Custom>
      <Gadgets.Custom configKey='to' label='To'>
        {({ onChange, value }) => (
          <Select onChange={onChange} value={value}>
            {map(range(2, 11), num => (
              <Option key={num} value={num}>
                {num}
              </Option>
            ))}
          </Select>
        )}
      </Gadgets.Custom>
    </>
  )
}

export function OptionalConfig ({ Gadgets }) {
  return (
    <Gadgets.ConfigBox
      configKey='labelEnds.enabled'
      label={i18n._('add.custom.labels')}
    >
      <Gadgets.Text
        configKey='labelEnds.value.left'
        label={i18n._('left.label')}
      />
      <Gadgets.Text
        configKey='labelEnds.value.right'
        label={i18n._('right.label')}
      />
    </Gadgets.ConfigBox>
  )
}
