/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { compact, get, last, map, reduce } from 'lodash'
import React from 'react'
import { useImmer } from 'use-immer'

import * as Flowbot from '../../flowbot'
import { useSimulationEngine } from './client-simulation-engine'

export default function WorkflowSimulator ({
  appId,
  branding,
  isProduct,
  isTable,
  appName,
  currentUser,
  form,
  formSections,
  workflow,
  workflowSettings
}) {
  const nodes = workflow.steps
  const [simulation, simulateAction] = useSimulationEngine(
    workflow._id,
    form.id,
    appId,
    currentUser
  )
  const [imageCache, setImageCache] = useImmer({})
  const { simulationState, selected } = simulation
  const value = last(simulationState.states)
  const selectedId = determineSelectedId(workflow, selected)
  const ref = useAutoScroller(`wfStep-${selectedId ?? nodes[0]._id}`)
  return (
    <>
      <div
        className={cx('relative overflow-auto', {
          'min-[501px]:w-[calc(100vw-288px)]': isProduct,
          'min-[501px]:w-[calc(100vw-48px)]': !isProduct,
          'h-[calc(100vh-248px)]': isTable,
          'h-[calc(100vh-184px)]': !isTable
        })}
        ref={ref}
        isProduct={isProduct}
        isTable={isTable}
      >
        <div className='relative flex'>
          <div
            className={cx(
              'box-border shrink-0 p-[100px] pr-[calc(50vw+50px)]',
              {
                'h-[calc(100vh-248px)]': isTable,
                'h-[calc(100vh-184px)]': !isTable
              }
            )}
          >
            <Flowbot.Viewer
              fieldsAll={workflow.schema}
              formSections={formSections}
              nodes={nodes}
              onClick={() => {}}
              selectedId={selectedId}
              workflowSettings={workflowSettings}
            />
          </div>
        </div>
      </div>
      <Flowbot.Simulate
        isTable={isTable}
        appName={appName}
        currentUser={currentUser}
        selectedId={selectedId}
        value={value}
        form={form}
        branding={branding}
        imageCache={imageCache}
        setImageCache={setImageCache}
        simulationState={simulationState}
        simulateAction={simulateAction}
        workflow={workflow}
        rootWorkflow={workflow}
        workflowSettings={workflowSettings}
      />
    </>
  )
}

const determineSelectedId = (definition, selected) => {
  if (!selected) {
    return
  }
  const selectedStepId = get(
    definition.steps.find(s => s._id === selected.stepDefinitionId),
    'clientId'
  )
  if (selectedStepId) {
    return selectedStepId
  }
  const selectedFlow = definition._id === selected.flowId
  if (selectedFlow) {
    return definition.clientId
  }
  const subflows = reduce(
    definition.steps,
    (accum, step) => {
      if (step.subflows) {
        return accum.concat(step.subflows)
      }
      return accum
    },
    []
  )
  const potentialMatches = map(subflows, subflow => {
    return determineSelectedId(subflow, selected)
  })
  return compact(potentialMatches)[0]
}

const useAutoScroller = selected => {
  const ref = React.useRef()
  React.useEffect(() => {
    if (!selected || !ref.current) return
    const el = document.getElementById(selected)
    if (!el) return
    const { left, right, top, bottom } = el.getBoundingClientRect()
    let shouldScroll = false
    const scrollToConfig = {}
    const el2 = ref.current
    if (right > window.innerWidth / 2 - 99 || left < 0) {
      // Step is not in view
      let amountToScroll = right - (window.innerWidth / 2 - 100)
      if (left - amountToScroll < 0) {
        // Step is wider than viewport, show 50px from the left
        amountToScroll = left - 50
      }
      scrollToConfig.left = el2.scrollLeft + amountToScroll
      shouldScroll = true
    }
    const scrollTop = el2.scrollTop
    if (bottom > window.innerHeight) {
      // Step is below the viewport
      let amountToScroll = bottom - (window.innerHeight - 200)
      if (top - amountToScroll < 0) {
        // Step is taller than view port, show 100px from the top
        amountToScroll = top - 100
      }
      scrollToConfig.top = scrollTop + amountToScroll
      shouldScroll = true
    } else if (top < 100) {
      // step is above the viewport
      scrollToConfig.top = scrollTop + top - 200
      shouldScroll = true
    }
    if (shouldScroll) {
      el2.scrollTo(scrollToConfig)
    }
  }, [selected])
  return ref
}
// END DUPLICATED THINGS
