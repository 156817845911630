/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { includes, invert, isEmpty } from 'lodash'
import React from 'react'

export function check (data, config) {
  switch (config.type) {
    case 'contains':
      return includes(data, config.value)
    case 'notContains':
      return !includes(data, config.value)
    case 'isEmpty':
      return isEmpty(data)
    case 'isNotEmpty':
      return !isEmpty(data)
    default:
      return true
  }
}

export function configIsValid (config) {
  if (includes(['isEmpty', 'isNotEmpty'], config.type)) return true
  return config.type && config.value
}

export function component ({ onChange, value }) {
  return (
    <div className='flex items-center gap-2'>
      <select
        className='kp-select flex-1'
        onChange={e => onChange({ ...value, type: e.target.value })}
        value={value.type || ''}
      >
        <option value=''>- - -</option>
        <option value='contains'>
          <Trans id='contains' />
        </option>
        <option value='notContains'>
          <Trans id='does.not.contain' />
        </option>
        <option value='isEmpty'>
          <Trans id='is.empty' />
        </option>
        <option value='isNotEmpty'>
          <Trans id='is.not.empty' />
        </option>
      </select>
      {hasValue(value.type) && (
        <input
          className='kp-input flex-1'
          onChange={e => onChange({ ...value, value: e.target.value })}
          value={value.value || ''}
        />
      )}
    </div>
  )
}

const hasValue = type => type && !includes(['isEmpty', 'isNotEmpty'], type)

const uiToWf = {
  contains: 'contains',
  notContains: 'does not contain',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  const newExpr = {
    left: {
      dataType: 'text',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
  if (hasValue(expr.data.type)) {
    newExpr.right = {
      dataType: 'text',
      value: expr.data.value
    }
  }
  return newExpr
}

export function fromWorkflowFormat (expr) {
  const newExpr = {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
  if (hasValue(newExpr.data.type)) {
    newExpr.data.value = expr.right.value
  }
  return newExpr
}
