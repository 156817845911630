/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  compact,
  entries,
  get,
  has,
  includes,
  isNumber,
  isString,
  keyBy,
  set,
  trim,
  uniqBy
} from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { useImmer } from 'use-immer'

import { externalRichText } from '../../../components/feature-flags'
import Highlight from '../../../components/highlight'
import { ModalPage } from '../../../components/modal-page'
import Formbot, { validate } from '../../../formbot'
import {
  collectInputs,
  getOutputFields,
  getSearchParameter
} from '../../../formbot/integration-utils'
import { AlertError, AlertWarning } from '../../../icons'
import { findArray, findResultKeys } from './utils'

export default function TestResultModal ({
  id,
  integration,
  onClose,
  width,
  updateTestResult
}) {
  if (width < 1100) {
    return (
      <ModalPage title={i18n._('test.integration')} onClose={onClose}>
        <TestResult
          id={id}
          integration={integration}
          updateTestResult={updateTestResult}
        />
      </ModalPage>
    )
  }
  return (
    <TestResult
      id={id}
      integration={integration}
      updateTestResult={updateTestResult}
      sidebyside
    />
  )
}

function TestResult ({ id, integration, sidebyside, updateTestResult }) {
  const [{ status, headers, data, key }, setResults] = React.useState({})
  const [value, updateValue] = useImmer({ datum: null })
  const type = isTypeahead(integration)
    ? 'IntegrationTypeahead'
    : 'IntegrationFill'
  const errors = deduceErrors(data, headers, status, integration)
  const structure = buildStructure(integration, type, id, setResults)

  // This function call is not inside useEffect because it ultimately depends
  // on the setStatus function from new.jsx and edit.jsx and as such it will be
  // called on every render regardless.
  const validState =
    data && (!errors.length || errors?.every(error => !error.preventSave))
  updateTestResult(validState)

  return (
    <TestDetails sidebyside={sidebyside}>
      {errors.map((error, i) => (
        <IntegrationError key={`${error.message}::${i}`} error={error} />
      ))}
      <Formbot.Edit
        document={{ data: value }}
        onChange={(key, val) =>
          updateValue(value => {
            set(value, key, val)
          })
        }
        structure={structure}
        context={{ validations: validate({ data: value }, structure) }}
      />
      {!!status && (
        <>
          <DataLabel>
            <Trans id='status.colon' />
          </DataLabel>
          <Highlight language='plaintext'>{status}</Highlight>
          <DataLabel>
            <Trans id='headers.colon' />
          </DataLabel>
          <Highlight language='plaintext'>{formatHeaders(headers)}</Highlight>
          <DataLabel>
            <Trans id='body.colon' />
          </DataLabel>
          <Highlight key={key}>{formatBody(data)}</Highlight>
        </>
      )}
    </TestDetails>
  )
}

const ERROR_ICON_SIZE = 36
const ERROR_COLOR = '#D22E2F'
const ERROR_ICON_COLOR = '#D22E2F'
const WARNING_COLOR = '#C84501'
const WARNING_ICON_COLOR = '#EF6C05'

function IntegrationError ({ error }) {
  return (
    <ConfigErrorWrapper>
      {error.preventSave ? (
        <AlertError
          width={ERROR_ICON_SIZE}
          height={ERROR_ICON_SIZE}
          fill={ERROR_ICON_COLOR}
        />
      ) : (
        <AlertWarning
          width={ERROR_ICON_SIZE}
          height={ERROR_ICON_SIZE}
          fill={WARNING_ICON_COLOR}
        />
      )}
      <ConfigError warning={!error.preventSave}>
        {error.message}
        {error.solutions && (
          <>
            <details>
              <summary style={{ cursor: 'pointer' }}>
                <Trans id='click.to.expand.values' />
              </summary>
              <code>{error.solutions}</code>
            </details>
          </>
        )}
      </ConfigError>
    </ConfigErrorWrapper>
  )
}

const isTypeahead = integration => integration.__type.id === 'fetch'

const formatHeaders = headers =>
  entries(headers)
    .reduce((lines, [header, value]) => {
      value = Array.isArray(value) ? value : [value]
      return lines.concat(value.map(value => `${header}: ${value}`))
    }, [])
    .join('\n')

const formatBody = data =>
  typeof data === 'string' ? data : JSON.stringify(data, null, 2)

const str = value => (value == null ? value : String(value))

const deduceErrors = (data, headers, status, integration) => {
  if (status && !(status >= 200 && status < 300)) {
    return [
      {
        preventSave: true,
        message: i18n._('expected.status.200.range', { status })
      }
    ]
  }

  const contentType = get(headers, 'content-type')
  if (data && !includes(contentType, 'json')) {
    return [
      {
        preventSave: true,
        message: i18n._('value.content.type.invalid', { contentType })
      }
    ]
  }
  return isTypeahead(integration)
    ? deduceTypeaheadErrors(data, integration)
    : deduceFillErrors(data, integration)
}

const deduceTypeaheadErrors = (data, integration) => {
  if (!data || typeof data !== 'object') return []
  const results = integration.__path ? get(data, integration.__path) : data
  if (Array.isArray(results)) {
    return uniqBy(results.flatMap(validateItem(integration)), 'message')
  }
  // if (Array.isArray(results)) return findVal(results, validateItem(integration))
  const path = integration.__path || '<root>'
  return [
    {
      preventSave: true,
      message: i18n._('not.find.path.api.response', { path }),
      solutions: findArray(results)
    }
  ]
}

const deduceFillErrors = (data, integration) =>
  data ? validateItem(integration)(data) : []

const validateItem = integration => data => {
  const idPath = integration?.__idKey || 'id'
  const labelPath = integration?.__labelKey || 'label'
  const errors = []
  if (!validatePart(data, idPath)) {
    errors.push({
      preventSave: true,
      message: i18n._('result.idpath.invalid', { idPath }),
      solutions: findResultKeys(data)
    })
  }
  if (!validatePart(data, labelPath)) {
    errors.push({
      preventSave: true,
      message: i18n._('label.field.invalid', { labelPath }),
      solutions: findResultKeys(data)
    })
  }
  const outputs = getOutputFields(integration) || []
  for (let i = 0; i < outputs.length; ++i) {
    let { path, label } = outputs[i]
    path = path.replace('data.', '')
    if (!validatePart(data, path, true)) {
      errors.push({
        message: i18n._('label.result.at.path.invalid', { label, path }),
        solutions: findResultKeys(data)
      })
    }
  }
  return errors
}

const validatePart = (data, path, optional) => {
  if (!optional) {
    const value = str(get(data, path))
    return (isString(value) || isNumber(value)) && trim(value) !== ''
  }
  const parts = complexDotSplit(path)
  const key = parts.pop()
  const rootPath = parts.join('.')
  const obj = rootPath ? get(data, rootPath) : data
  return has(obj, key)
}

export function complexDotSplit (path, current = '', level = 0) {
  if (path.length === 0) return [current]
  const [char, ...rest] = path
  path = rest.join('')
  if (char === '[') level++
  if (char === ']') level--
  if (char !== '.' || level !== 0) {
    return complexDotSplit(path, current + char, level)
  }
  return [current, ...complexDotSplit(path, '', level)]
}

const buildStructure = (integration, type, id, testReport) => {
  const inputs = collectInputs(integration) || []
  const outputs = getOutputFields(integration) || []
  return {
    template: {
      id: 'root',
      type: 'Column',
      children: compact([
        !!inputs.length && {
          id: 'inputs',
          type: 'Section',
          label: 'Inputs',
          children: [
            {
              id: 'inputsColumn',
              type: 'Column',
              children: inputs.map(input => ({
                id: `input${input.__dataPath}`,
                formKey: `input${input.__dataPath}`,
                type: 'Text',
                label: input.__label,
                required: input.required
              }))
            }
          ]
        },
        {
          id: 'preview',
          type: 'Section',
          label: 'Form Preview',
          children: [
            {
              id: 'previewColumn',
              type: 'Column',
              children: [
                {
                  id: 'datum',
                  type,
                  label: 'FormLabel',
                  formKey: 'datum',
                  details: {
                    id,
                    searchParameter: getSearchParameter(integration),
                    inputFields: keyBy(
                      inputs.map(input => ({
                        dataPath: input.__dataPath,
                        type: 'form',
                        value: {
                          type: 'Text',
                          id: `data.input${input.__dataPath}`
                        },
                        required: input.required
                      })),
                      'dataPath'
                    ),
                    testIntegration: integration,
                    testReport: a => testReport({ ...a, key: Math.random() })
                  }
                }
              ]
            }
          ]
        },
        !!outputs.length && {
          id: 'additional',
          type: 'Section',
          label: 'Additional Preview',
          children: [
            {
              id: 'additionalColumn',
              type: 'Column',
              children: outputs.map(output => ({
                id: `output${output.path}`,
                formKey: `output${output.path}`,
                type: 'DataLink',
                label: output.label,
                details: {
                  parentId: 'data.datum',
                  selectedOutputField: output,
                  isExternal: externalRichText,
                  isTest: true
                }
              }))
            }
          ]
        }
      ])
    },
    metaFields: [],
    integrationFields: [],
    trashed: []
  }
}

const ConfigErrorWrapper = styled.div`
  padding: 8px 0;
  display: grid;
  grid-column-gap: 12px;
  grid-auto-flow: column;
`

const ConfigError = styled.p`
  margin: 0;
  font-weight: bold;
  color: ${p => (p.warning ? WARNING_COLOR : ERROR_COLOR)};
`

const TestDetails = styled.dl`
  margin: 0;
  padding: 16px;
  ${p =>
    p.sidebyside &&
    css`
      width: 540px;
      height: 100%;
      overflow: auto;
    `}
`

const DataLabel = styled.label`
  display: block;
  font-size: 16px;
  padding-top: 16px;
  & + pre {
    margin: 0;
  }
`
