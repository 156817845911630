/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'

const getWidth = ({ isDragging, invisible }) => {
  if (isDragging) return invisible ? 16 : 20
  return invisible ? 0 : 8
}

export default styled.div`
  width: ${getWidth}px;
  transition: width 300ms;
  position: relative;
  left: ${p => (p.first && p.invisible ? -8 : -4)}px;
  &::after {
    content: '';
    position: absolute;
    display: block;
    background: ${props => (props.invisible ? 'transparent' : '#aaa')};
    html.dark & {
      // Outlier: cx('dark:bg-light-gray-300', {'bg-transparent': invisible})
      background: ${props => (props.invisible ? 'transparent' : '#333')};
    }
    height: 2px;
    transition: width 300ms;
    width: ${props => (props.isDragging ? 28 : 16)}px;
  }

  div:focus > &::after {
    height: 150px;
    top: -75px;
    background: #e9f8fe;
    html.dark & {
      // Outlier: dark:bg-light-gray-200
      background: #222;
    }
    border: 1px dashed #558dd8;
    content: 'Drop Here';
    color: #558dd8;
    font-size: 10px;
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    text-align: center;
    line-height: 14px;
  }
`
