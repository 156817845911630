/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { cloneDeep } from 'lodash'

export const useSetConditionallyShared = (id, query) => {
  const [setConditionallyShared] = useMutation(mutation)
  return conditionallyShared =>
    setConditionallyShared(getParams(id, conditionallyShared, query))
}

const mutation = gql`
  mutation SeConditionallyShared($id: ID!, $conditionallyShared: Boolean!) {
    setConditionallyShared(
      applicationId: $id
      conditionallyShared: $conditionallyShared
    ) {
      status
    }
  }
`

const getParams = (id, conditionallyShared, query) => ({
  variables: {
    id,
    conditionallyShared
  },
  optimisticResponse: {
    setConditionallyShared: {
      __typename: 'SetConditionallyShared',
      status: 'ok'
    }
  },
  update: (store, { data: { setConditionallyShared } }) => {
    if (setConditionallyShared.status !== 'ok') return
    const rawData = store.readQuery({ ...query })
    const data = cloneDeep(rawData)
    data.app.dataSharedWith.conditionallyShared = conditionallyShared
    store.writeQuery({ ...query, data })
  }
})
