/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import Pagination from '../../../../components/data-table/pagination'
import Loading from '../../../../components/loading'
import { GraphQLError as Error } from '../../../../components/system-error'
import { useQuery } from '../../../../components/use-query'
import { Announcer } from '../../../../ui/a11y'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../../../ui/table'

export default function GroupHistory () {
  const { id } = useParams()
  const [params, updateParams] = useImmer(defaultParams)
  const { logs, totalCount, loading, error } = useLogsData(id, params)
  return (
    <TableWrapper>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map(col => (
                <TableHeaderCell key={col.id}>{col.label}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {logs.map(log => (
              <StyledTableRow key={log.id}>
                {columns.map(col => (
                  <TableCell key={col.id}>
                    {col.format?.(log[col.id]) || log[col.id]}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
          {totalCount !== 0 && (
            <Pagination
              onUpdate={({ skip, limit }) => {
                updateParams(draft => {
                  draft.skip = skip
                  draft.limit = limit
                })
              }}
              total={totalCount}
              skip={params.skip}
              limit={params.limit}
            />
          )}
          <Announcer id='document-list-pagination'>
            {getPaginationString(totalCount, params.skip, params.limit)}
          </Announcer>
        </Table>
      )}
    </TableWrapper>
  )
}

const logsQuery = gql`
  query GroupsHistory(
    $id: ID!
    $q: String
    $skip: Int!
    $limit: Int!
    $sort: [String!]
  ) {
    group(id: $id) {
      id
      historyConnection(
        args: { query: $q, skip: $skip, limit: $limit, sort: $sort }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          skip
          limit
        }
        edges {
          node {
            id
            createdAt
            updatedBy {
              id
              displayName
              email
            }
            operation
            description
          }
        }
      }
    }
  }
`

const StyledTableRow = styled(TableRow)`
  &:hover {
    background: initial !important;
  }
  html.dark &:hover {
    // Outlier: dark:hover:bg-light-gray-300
    background: #333 !important;
  }
`

const TableWrapper = styled.div`
  padding: 32px 16px;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  margin: 0 auto;
  position: relative;
  min-height: 50%;
  width: 1200px;
  th,
  td {
    padding: 8px 16px;
  }

  @media (max-width: 1232px) {
    width: 100%;
    padding: 16px;
  }

  @media (max-width: 768px) {
    padding: 8px;

    th,
    td {
      padding: 4px 8px;
      font-size: 12px;
    }

    th {
      font-size: 14px;
    }
  }

  @media (max-width: 360px) {
    th,
    td {
      font-size: 11px;
    }

    th {
      font-size: 13px;
    }
  }
`

const getPaginationString = (count, start, limit) => {
  if (!count) {
    return (
      <Trans
        id='no.matching.search.results'
        message='No matching search results'
      />
    )
  }
  const end = Math.min(start + limit, count)
  return i18n._(
    {
      id: 'pagesbuilder.doclist.count.users',
      message: 'Showing {start} to {end} of {count} total users'
    },
    {
      start: start + 1,
      end,
      count
    }
  )
}

const columns = [
  {
    id: 'createdAt',
    label: <Trans id='date.and.time' message='Date & Time' />,
    format: date =>
      i18n.date(new Date(date), { dateStyle: 'medium', timeStyle: 'medium' })
  },
  {
    id: 'updatedBy',
    label: <Trans id='user' message='User' />,
    format: user => (
      <div>
        <div>{user?.displayName ?? '--'}</div>
        {user?.email && (
          <div className='text-medium-gray-500'>{user?.email ?? '--'}</div>
        )}
      </div>
    )
  },
  {
    id: 'description',
    label: <Trans id='action' message='Action' />,
    format: description => localizeDescription(description)
  }
]

function localizeDescription (description) {
  switch (description) {
    case 'Created new group':
      return i18n._({
        id: 'created.new.group',
        message: 'Created new group'
      })
    default:
      return description
  }
}

const defaultParams = { skip: 0, limit: 25 }

function useLogsData (id, params) {
  const graphqlVariables = React.useMemo(() => {
    return { id, limit: params.limit, skip: params.skip }
  }, [params])
  const [queryInput, setQueryInput] = React.useState()
  const { data, loading, error } = useQuery({
    query: logsQuery,
    variables: graphqlVariables,
    fetchPolicy: 'network-only'
  })
  const logs = React.useMemo(() => {
    if (!data?.group?.historyConnection) return
    return data.group.historyConnection.edges.map(({ node }) => mapLog(node))
  }, [data])
  const totalCount = data?.group?.historyConnection?.totalCount ?? 0
  return {
    logs,
    totalCount,
    loading,
    error,
    queryInput,
    setQueryInput
  }
}

function mapLog (log) {
  return {
    ...log,
    createdAt: new Date(log.createdAt).toLocaleString(),
    operation: operationMap[log.operation] || log.operation
  }
}

const operationMap = {
  create: 'Created',
  delete: 'Deleted',
  update: 'Updated'
}
