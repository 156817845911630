/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { elixirBackend } from './feature-flags'

export const documentList = (appId, pageId, { tableId, slug } = {}) =>
  tableId
    ? `/app/${appId}/page/${pageId}/${slug}/general-data/${tableId}/document-list`
    : pageId
      ? `/app/${appId}/page/${pageId}/document-list`
      : `/document-list/${appId}`

export const form = (appId, pageId, { tableId, slug } = {}) =>
  tableId
    ? `/app/${appId}/page/${pageId}/${slug}/general-data/${tableId}/form`
    : pageId
      ? `/app/${appId}/page/${pageId}/form`
      : `/form/${appId}`

export const workflow = (appId, pageId, { tableId, slug } = {}) =>
  tableId
    ? `/app/${appId}/page/${pageId}/${slug}/general-data/${tableId}/workflow`
    : pageId
      ? `/app/${appId}/page/${pageId}/workflow`
      : `/workflow/${appId}`

export const publish = (appId, pageId, { tableId, slug } = {}) =>
  tableId
    ? `/app/${appId}/page/${pageId}/${slug}/general-data/${tableId}/publish`
    : pageId
      ? `/app/${appId}/page/${pageId}/publish`
      : `/publish/${appId}`

export const run = (appId, pageId, { tableId } = {}) =>
  tableId || pageId
    ? `/app/${appId}/page/${tableId || pageId}/run`
    : `/app/${appId}/run`

const prefix = elixirBackend ? '/platform' : '/app'

export const documentsCsv = (appId, pageId, { tableId } = {}) =>
  tableId || pageId
    ? `${prefix}/api/v0/apps/${appId}/${tableId || pageId}/documents.csv`
    : `${prefix}/api/v0/apps/${appId}/documents.csv`

export const bind = (...args) => ({
  documentList: () => documentList(...args),
  form: () => form(...args),
  workflow: () => workflow(...args),
  publish: () => publish(...args),
  run: () => run(...args),
  documentsCsv: () => documentsCsv(...args)
})
