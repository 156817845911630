/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default function StaticImageView ({ details }) {
  if (!(details?.image?.url || details?.tempImg)) {
    return null
  }
  let maxHeight = ''
  switch (details?.size) {
    case 'tiny':
      maxHeight = 'max-h-8'
      break
    case 'small':
      maxHeight = 'max-h-16'
      break
    case 'medium':
      maxHeight = 'max-h-64'
      break
    case 'large':
      maxHeight = 'max-h-[512px]'
      break
    case 'huge':
      maxHeight = 'max-h-[1024px]'
      break
    default:
      maxHeight = ''
  }
  let alignment = 'justify-center'
  if (details?.horizontalAlignment === 'left') {
    alignment = 'justify-start'
  } else if (details?.horizontalAlignment === 'right') {
    alignment = 'justify-end'
  }
  return (
    <div className={cx('m-4 flex', alignment, maxHeight)}>
      <img
        className='h-auto max-h-full object-contain'
        src={details?.image?.url || details?.tempImg}
        alt={details?.description}
      />
    </div>
  )
}
