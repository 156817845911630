/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import CalculationsConfig from '../../../pages-builder/calculations/calculation-config'

export function OptionalConfig ({ id, Gadgets, allGadgets, formKey }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text id={id} configKey='placeholder.value' />
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='calculation.enabled'
        label={i18n._('enable.calculations')}
        description={i18n._('enable.calculations.data')}
      >
        <Gadgets.Custom configKey='calculation.value'>
          {({ onChange, value }) => {
            return (
              <CalculationsConfig
                Gadgets={Gadgets}
                allGadgets={allGadgets}
                onChange={onChange}
                value={value}
                gadgetType='Number'
                id={id}
                formKey={formKey}
              />
            )
          }}
        </Gadgets.Custom>
      </Gadgets.ConfigBox>
    </>
  )
}
