/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M1.68421 3.28571H5V5.42856H1.26316V3.71429C1.26316 3.47759 1.45167 3.28571 1.68421 3.28571ZM6.14286 5.42856V3.28571H10V5.42856H6.14286ZM10 6.57142H6.14286L6.14286 12.7143H10V6.57142ZM11 12.7143V6.57142H14.7368V12.2857C14.7368 12.5224 14.5483 12.7143 14.3158 12.7143H11ZM11 5.42856V3.28571H14.3158C14.5483 3.28571 14.7368 3.47759 14.7368 3.71429V5.42856H11ZM1.26316 6.57142H5L5 12.7143H1.68421C1.45167 12.7143 1.26316 12.5224 1.26316 12.2857V6.57142ZM0 3.71429C0 2.76751 0.754047 2 1.68421 2H14.3158C15.246 2 16 2.76751 16 3.71429V12.2857C16 13.2325 15.246 14 14.3158 14H1.68421C0.754047 14 0 13.2325 0 12.2857V3.71429Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.68421 3.28571H5V5.42856H1.26316V3.71429C1.26316 3.47759 1.45167 3.28571 1.68421 3.28571ZM6.14286 5.42856V3.28571H10V5.42856H6.14286ZM10 6.57142H6.14286L6.14286 12.7143H10V6.57142ZM11 12.7143V6.57142H14.7368V12.2857C14.7368 12.5224 14.5483 12.7143 14.3158 12.7143H11ZM11 5.42856V3.28571H14.3158C14.5483 3.28571 14.7368 3.47759 14.7368 3.71429V5.42856H11ZM1.26316 6.57142H5L5 12.7143H1.68421C1.45167 12.7143 1.26316 12.5224 1.26316 12.2857V6.57142ZM0 3.71429C0 2.76751 0.754047 2 1.68421 2H14.3158C15.246 2 16 2.76751 16 3.71429V12.2857C16 13.2325 15.246 14 14.3158 14H1.68421C0.754047 14 0 13.2325 0 12.2857V3.71429Z\"/>\n</svg>\n";
export default src;
