/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M14.97 0H1.03C0.756935 0.000352797 0.495156 0.108984 0.30207 0.30207C0.108984 0.495156 0.000352797 0.756935 0 1.03L0 14.9693C0.000176359 15.2425 0.108729 15.5045 0.301834 15.6977C0.49494 15.8909 0.75682 15.9996 1.03 16H14.97C15.2432 15.9996 15.5051 15.8909 15.6982 15.6977C15.8913 15.5045 15.9998 15.2425 16 14.9693V1.03C15.9996 0.756935 15.891 0.495156 15.6979 0.30207C15.5048 0.108984 15.2431 0.000352797 14.97 0V0ZM12.5367 5.22933L7.87 11.5627C7.81596 11.6339 7.7483 11.6938 7.67095 11.7387C7.5936 11.7836 7.50809 11.8127 7.4194 11.8243C7.33071 11.8359 7.24059 11.8298 7.15429 11.8063C7.06799 11.7827 6.98722 11.7423 6.91667 11.6873L3.58333 9.02067C3.51362 8.96642 3.45538 8.89886 3.412 8.82192C3.36862 8.74498 3.34097 8.66018 3.33064 8.57245C3.32032 8.48473 3.32753 8.39582 3.35185 8.31091C3.37618 8.22599 3.41714 8.14676 3.47235 8.07781C3.52757 8.00886 3.59593 7.95157 3.67347 7.90927C3.75102 7.86697 3.83619 7.8405 3.92405 7.8314C4.01191 7.8223 4.10071 7.83075 4.18527 7.85626C4.26984 7.88177 4.3485 7.92383 4.41667 7.98L7.208 10.2133L11.4667 4.43733C11.5716 4.295 11.7288 4.20018 11.9036 4.17374C12.0785 4.1473 12.2567 4.1914 12.399 4.29633C12.5413 4.40127 12.6362 4.55845 12.6626 4.7333C12.689 4.90814 12.6449 5.08633 12.54 5.22867L12.5367 5.22933Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M14.97 0H1.03C0.756935 0.000352797 0.495156 0.108984 0.30207 0.30207C0.108984 0.495156 0.000352797 0.756935 0 1.03L0 14.9693C0.000176359 15.2425 0.108729 15.5045 0.301834 15.6977C0.49494 15.8909 0.75682 15.9996 1.03 16H14.97C15.2432 15.9996 15.5051 15.8909 15.6982 15.6977C15.8913 15.5045 15.9998 15.2425 16 14.9693V1.03C15.9996 0.756935 15.891 0.495156 15.6979 0.30207C15.5048 0.108984 15.2431 0.000352797 14.97 0V0ZM12.5367 5.22933L7.87 11.5627C7.81596 11.6339 7.7483 11.6938 7.67095 11.7387C7.5936 11.7836 7.50809 11.8127 7.4194 11.8243C7.33071 11.8359 7.24059 11.8298 7.15429 11.8063C7.06799 11.7827 6.98722 11.7423 6.91667 11.6873L3.58333 9.02067C3.51362 8.96642 3.45538 8.89886 3.412 8.82192C3.36862 8.74498 3.34097 8.66018 3.33064 8.57245C3.32032 8.48473 3.32753 8.39582 3.35185 8.31091C3.37618 8.22599 3.41714 8.14676 3.47235 8.07781C3.52757 8.00886 3.59593 7.95157 3.67347 7.90927C3.75102 7.86697 3.83619 7.8405 3.92405 7.8314C4.01191 7.8223 4.10071 7.83075 4.18527 7.85626C4.26984 7.88177 4.3485 7.92383 4.41667 7.98L7.208 10.2133L11.4667 4.43733C11.5716 4.295 11.7288 4.20018 11.9036 4.17374C12.0785 4.1473 12.2567 4.1914 12.399 4.29633C12.5413 4.40127 12.6362 4.55845 12.6626 4.7333C12.689 4.90814 12.6449 5.08633 12.54 5.22867L12.5367 5.22933Z\" />\n</svg>\n";
export default src;
