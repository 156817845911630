/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { associationsGadget } from '../../../components/feature-flags'
import { Branch as Icon } from '../../../icons'
import { RequiredConfig } from './config'
import Edit from './edit'
import View from './view'

export default {
  RequiredConfig,
  defaultValue: [],
  Edit,
  meta: {
    category: 'Special',
    hidden: !associationsGadget,
    Icon,
    label: 'Associations'
  },
  sampleValue: [],
  validateShape: v => v.any(),
  View
}
