/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87903 15.1539 3.84563 13.6541 2.34587C12.1544 0.846118 10.121 0.00247015 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z`}/><path d={`M11.4227 10.54L8.33333 7.70735V4.33335C8.33333 4.15654 8.2631 3.98697 8.13807 3.86195C8.01305 3.73693 7.84348 3.66669 7.66667 3.66669C7.48986 3.66669 7.32029 3.73693 7.19526 3.86195C7.07024 3.98697 7 4.15654 7 4.33335V8.00002C6.9999 8.09253 7.01905 8.18404 7.05624 8.26874C7.09342 8.35345 7.14783 8.42949 7.216 8.49202L10.522 11.522C10.6531 11.6399 10.8252 11.7017 11.0013 11.6941C11.1774 11.6865 11.3436 11.6101 11.464 11.4814C11.5832 11.351 11.6459 11.1786 11.6381 11.0021C11.6304 10.8256 11.5529 10.6594 11.4227 10.54Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87903 15.1539 3.84563 13.6541 2.34587C12.1544 0.846118 10.121 0.00247015 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z\"/>\n<path d=\"M11.4227 10.54L8.33333 7.70735V4.33335C8.33333 4.15654 8.2631 3.98697 8.13807 3.86195C8.01305 3.73693 7.84348 3.66669 7.66667 3.66669C7.48986 3.66669 7.32029 3.73693 7.19526 3.86195C7.07024 3.98697 7 4.15654 7 4.33335V8.00002C6.9999 8.09253 7.01905 8.18404 7.05624 8.26874C7.09342 8.35345 7.14783 8.42949 7.216 8.49202L10.522 11.522C10.6531 11.6399 10.8252 11.7017 11.0013 11.6941C11.1774 11.6865 11.3436 11.6101 11.464 11.4814C11.5832 11.351 11.6459 11.1786 11.6381 11.0021C11.6304 10.8256 11.5529 10.6594 11.4227 10.54Z\"/>\n</svg>\n";
export default src;
