/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'

export default function AppView ({ value }) {
  const appId = get(value, 'id')
  const { data, error } = useQuery(queryApp, {
    variables: {
      appId
    },
    skip: !appId
  })
  if (error) return <div>Error</div>
  if (!data || !data.app) return null
  return <div>{data.app.name}</div>
}

const queryApp = gql`
  query app($appId: ID!) {
    app(id: $appId) {
      id
      name
    }
  }
`
