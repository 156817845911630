/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export default [
  '3-d-box-corner',
  'ab-testing-chemistry-monitor-1',
  'accounting-bill',
  'accounting-bills-1',
  'accounting-calculator',
  'add-folder-1',
  'alert-diamond',
  'amazon-pin',
  'amazon-s-3-storage',
  'analytics-board-bars',
  'analytics-pie-2',
  'anchor',
  'app-window-graph',
  'app-window',
  'archive',
  'armchair-1',
  'arrow-thick-circle-down',
  'arrow-thick-circle-left',
  'arrow-thick-circle-right',
  'arrow-thick-circle-up',
  'attach-clipping-object',
  'avatar-islamic-women-hijab-1',
  'award-medal-1',
  'award-trophy-star',
  'baggage',
  'bandage',
  'beach-palm',
  'beanie-winter-2',
  'bicycle',
  'bin-2-alternate',
  'binocular',
  'board-game-dice-1',
  'book-address',
  'book-close-2',
  'book-flip-page',
  'book-library-shelf',
  'briefcase',
  'browser-page-mail',
  'bulb',
  'bus-station',
  'business-climb-top',
  'business-crossroad',
  'business-shark',
  'button-record-alternate',
  'by-content-sign',
  'cake-birthday',
  'calendar-2',
  'calendar-check-1',
  'calendar-favorite-1',
  'calendar-warning',
  'car-1',
  'cash-briefcase',
  'cash-payment-bag',
  'cash-payment-sign-2',
  'certified-certificate-1',
  'certified-certificate',
  'certified-diploma-2',
  'certified-ribbon-2',
  'chair-4',
  'check-badge',
  'check-shield',
  'checklist',
  'checkup-chart',
  'chef-gear-hat',
  'chess-rook',
  'clean-car-leaf',
  'cloud',
  'cocktail-martini',
  'coding-apps-website-big-data-volume-folder',
  'coding-apps-website-home',
  'coding-apps-website-web-form-text-area-1',
  'cog-1',
  'color-painting-palette',
  'comment-box',
  'common-file-bookmark',
  'common-file-double',
  'common-file-edit',
  'common-file-heart',
  'common-file-stack',
  'common-file-text',
  'compass-arrow',
  'computer-bug-1',
  'computer-chip',
  'content-browser-edit',
  'content-notebook-pencil',
  'content-pen-3',
  'conversation-chat-1',
  'create-chart',
  'credit-card-1-alternate',
  'crime-man-thief-2',
  'criteria-1',
  'crypto-blockchain-technology',
  'crypto-money-chip',
  'cursor-click',
  'data-file-bars',
  'data-transfer-circle',
  'database-2',
  'delivery-truck-3',
  'design-drawing-board-1',
  'design-tool-glue',
  'design-tool-magic-wand-1',
  'design-tool-magnet',
  'design-tool-quill-1',
  'design-tool-stamp',
  'desk-document-base-work',
  'desktop-monitor-smiley-1',
  'direction-button-arrows',
  'discount-circle',
  'discount-star-premium',
  'dislike',
  'do-not-disturb-sleep-mode',
  'donation-charity-handshake-heart',
  'download-thick-box',
  'drawer-download',
  'drawer-open',
  'drawer-upload',
  'duplicate-folder-alternate',
  'earth-1',
  'earth-3-alternate',
  'ecology-leaf',
  'edit-pdf',
  'elastic-compute-cloud-1',
  'election-debate-8',
  'email-action-read-document',
  'emoji-pile-poo',
  'envelope',
  'event',
  'face-id-1',
  'face-id-7',
  'factory-building-1',
  'factory-industrial-robot-arm-2',
  'fantasy-medieval-witch-cauldron',
  'farming-field-sun',
  'feedback-1',
  'file-database',
  'fill-and-sign',
  'fire-attention-warning',
  'flag-1',
  'flags',
  'flash',
  'flying-insect-honey',
  'folder-bookmark-1',
  'folder-check',
  'food-restaurant-symbol-fork-cross-knife',
  'forensic-science-tally-marks',
  'fruit-apple',
  'gauge-dashboard-1',
  'gift-box',
  'gold-bars',
  'gpon-splitter-1',
  'gps-location-compass',
  'graduation-hat',
  'greeting-card-heart-valentine',
  'group-3',
  'hand-stop',
  'hard-drive-1',
  'hardware-nut',
  'headphones-customer-support-human',
  'hierarchy-4',
  'hierarchy-7',
  'hotel-double-bed-2',
  'hourglass',
  'house-2',
  'house',
  'indooor-plant-5',
  'information-desk-customer',
  'insert-column-from-clipboard',
  'instrument-piano-keys',
  'insurance-head',
  'job-seach-team-woman',
  'kawaii-manga-cat-pawn-1',
  'keyboard',
  'keyhole-circle',
  'lab-flask-experiment',
  'lab-tube-bottle',
  'labor-worker',
  'laboratory-drug-file',
  'laptop-launch',
  'laptop',
  'layers-grid-add',
  'layers-stacked',
  'legal-hammer',
  'legal-scale-1',
  'library',
  'light-bulb-shine',
  'light-mode-night',
  'like',
  'loading-2',
  'lock-2',
  'locker-room-suitcase-key',
  'login-2',
  'login-3',
  'login-key',
  'love-it',
  'mailbox-in',
  'man-protester',
  'manual-book-content-sign',
  'maps-pin-1',
  'medical-cross',
  'meeting-camera-circle',
  'meeting-monitor-webcam',
  'megaphone-1',
  'messages-bubble-quotation',
  'messages-bubble-square-menu',
  'microphone',
  'microscope-1',
  'module-four',
  'module-puzzle-2',
  'module',
  'monetization-sponsor',
  'money-bag-dollar',
  'money-wallet',
  'monitor-exchange-arrow',
  'mood-rock',
  'movies-director-chair',
  'multiple-actions-chat',
  'multiple-circle',
  'multiple-neutral-2-1',
  'multiple-neutral-2',
  'music-note-2',
  'network-browser',
  'newspaper',
  'notepad-sketchpad',
  'notes-book',
  'notes-checklist-flip',
  'notes-diary',
  'office-building-double',
  'office-file-text',
  'office-stamp-document',
  'official-building-1',
  'official-building-2',
  'outdoors-shelter-home',
  'outdoors-tree-valley',
  'park-bench',
  'party-balloon',
  'passport',
  'pasta-bowl-warm',
  'patient-protection',
  'peace-flag',
  'pencil-write-3',
  'people-woman-graduate',
  'performance-increase-1',
  'personal-hotspot-connection-square',
  'pets-allow',
  'phone-book',
  'phone-charger',
  'picture-polaroid-human',
  'picture-polaroid-landscape',
  'pill',
  'pin-1',
  'pin',
  'piracy-pirate-2',
  'plane-take-off',
  'police-footstep',
  'police-footsteps',
  'police-hat',
  'police-rotating-light-1',
  'pool-black-ball',
  'pouch',
  'power-button-2',
  'presentation-audience',
  'products-briefcase',
  'programming-browser-1',
  'programming-language-code',
  'rabbit-side',
  'radioactive',
  'ranking-first',
  'rating-booklet',
  'rating-star-alternate',
  'rating-star-badge',
  'rating-star',
  'read-email-circle',
  'receipt-dollar',
  'recruiting-key-position',
  'recycling-trash-bin-2',
  'religion-devil-head',
  'religion-peace',
  'retouch-face',
  'reward-stars-3',
  'road-sign-no-entry',
  'road-sign-right-curve-ahead',
  'road-sign-u-turn-right',
  'road-sign-warning',
  'road-straight',
  'room-service-bring-plate',
  'safety-warning-electricity',
  'saving-piggy-bank',
  'school-building',
  'science-fiction-alien-1',
  'scissors-2',
  'screen-orientation-lock-rectangle',
  'screen',
  'search-circle-alternate',
  'send-email',
  'seo-search-laptop',
  'server-error-desktop',
  'sesame-street-yip-yips',
  'settings-human',
  'settings-vertical',
  'shelf-books',
  'shield-lock',
  'shield-settings',
  'shipment-box',
  'shipment-truck',
  'shirt-plain',
  'shop-1',
  'shop-sign-open-1',
  'shop-street-sign-1',
  'shopping-cart-empty-1',
  'show-theater-masks',
  'sign-badge-badge',
  'signature-sign',
  'single-neutral-actions-chat',
  'single-neutral-actions-edit-2',
  'single-neutral-actions-view',
  'single-neutral-actions-warning',
  'single-neutral-book',
  'single-neutral-flag-2',
  'skull',
  'social-profile-avatar',
  'space-rocket-launch',
  'speakers',
  'sprinting-running',
  'stairs-ascend',
  'style-one-pin-star',
  'surveillance-camera',
  'synchronize-arrows-three',
  'tablet',
  'tags-1',
  'taking-pictures-circle',
  'target-center-1',
  'task-list-approve',
  'task-list-check-1',
  'task-list-edit-1',
  'task-list-to-do',
  'team-chat',
  'technology-ar-searching-ar-map-2',
  'technology-contact-access-check',
  'technology-privacy-consent-profile-approve',
  'temperature-thermometer-low',
  'text-book',
  'ticket-1',
  'time-clock-circle-1',
  'time-nine-to-five-1',
  'tool-box',
  'tools-kitchen-scale-1',
  'touch-password-approved',
  'travel-insurance-cover',
  'trekking-person',
  'trends-hot-flame',
  'upload-thick-box',
  'video-edit-brightness',
  'video-game-mario-mushroom-1',
  'video-game-pacman',
  'video-player',
  'vinyl-record-player-1',
  'vip-crown-queen-1',
  'volume-control-full',
  'warehouse-1',
  'warehouse-cart-packages',
  'watch-time',
  'water-protection-drop',
  'water-straw',
  'wheel-chair-1',
  'work-from-home-computer-meeting',
  'work-from-home-laptop-meeting',
  'worker-lay-off-fired-user-group',
  'workflow-coaching-whistle',
  'workflow-data-table-1',
  'workflow-teamwork-handshake',
  'workflow-teamwork-user-high-five',
  'wrench',
  'xbox-controller',
  'yoga-arms-stretch'
]
