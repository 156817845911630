/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { Space } from '../../../ui/layout'
import { Body1, Body2 } from '../../../ui/typography'

export default ({ hide, title, sources, onChange, isValid }) => {
  const [ids, setIds] = React.useState([])
  return (
    <div>
      <Container>
        <Wrapper className='bg-white'>
          <Header className='border-b border-light-gray-300 bg-light-gray-100 text-medium-gray-500 dark:bg-light-gray-300'>
            <div>{title}</div>
            <Button transparent icon small onClick={hide} aria-label='close'>
              <Icons.Close width='12px' height='12px' />
            </Button>
          </Header>
          <Body>
            {map(sources, (source, i) => (
              <Column key={i} className='border-r border-light-gray-300'>
                {map(source(ids[i - 1]), ({ id, label, icon }) => (
                  <Choice
                    className={cx({
                      'bg-light-gray-200 dark:bg-light-gray-300': ids[i] === id,
                      'bg-inherit': ids[i] !== id
                    })}
                    key={id}
                    onClick={() => setIds([...ids.slice(0, i), id])}
                    selected={ids[i] === id}
                  >
                    {icon && (
                      <>
                        {icon}
                        <Space w='16px' />
                      </>
                    )}
                    <Body1>{label}</Body1>
                  </Choice>
                ))}
              </Column>
            ))}
          </Body>
          <Header className='border-t border-light-gray-300 bg-light-gray-100 text-medium-gray-500 dark:bg-light-gray-300'>
            <Space expand />
            <Button
              disabled={!isValid(ids)}
              onClick={() => onChange(ids[ids.length - 1])}
            >
              <Trans id='continue' />
            </Button>
          </Header>
        </Wrapper>
      </Container>
    </div>
  )
}

const Container = styled.div`
  position: fixed;
  z-index: 3;
`

const Wrapper = styled.div`
  box-shadow:
    0px 4px 5px rgba(26, 26, 26, 0.2),
    0px 3px 14px rgba(26, 26, 26, 0.12),
    0px 8px 10px rgba(26, 26, 26, 0.14);
  display: flex;
  flex-direction: column;
  width: 700px;
  min-height: 370px;
  position: fixed;
  border-radius: 4px;
  left: calc(50% - 350px);
  top: 80px;
`

const Header = styled(Body2)`
  text-transform: uppercase;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Body = styled.div`
  display: flex;
  flex: 1;
`

const Column = styled.div`
  flex: 1;
  overflow: auto;
  max-height: 335px;
`

const Choice = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`
