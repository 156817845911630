/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import React from 'react'

export const useInitWorkflow = () => {
  const [initWorkflow] = useInitWorkflowMutation()
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  return (appId, datasetId) =>
    initWorkflow(appId, datasetId, timeZone).then(
      resp => resp.data.initializeWorkflow.actionId
    )
}

export const useInitWorkflowMutation = () => {
  const [mutate, opts] = useMutation(mutation, { ignoreResults: true })
  const initWorkflow = React.useCallback(
    (appId, pageId, tz) => mutate(getParams(appId, pageId, tz)),
    [mutate]
  )
  return [initWorkflow, opts.error]
}

const mutation = gql`
  mutation InitWorkflow($appId: ID!, $pageId: ID, $timeZone: String) {
    initializeWorkflow(
      args: { id: $appId, pageId: $pageId, timeZone: $timeZone }
    ) {
      actionId
    }
  }
`

const getParams = (appId, pageId, timeZone) => ({
  variables: {
    appId,
    pageId,
    timeZone
  }
})
