/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { includes, invert, map } from 'lodash'
import React from 'react'

export default function createProgDisc (options) {
  function check (data, config) {
    switch (config.type) {
      case 'isSelected':
        return data === config.value
      case 'isNotSelected':
        return data !== config.value
      case 'isEmpty':
        return !data
      case 'isNotEmpty':
        return !!data
      default:
        return true
    }
  }

  function configIsValid (config) {
    if (includes(['isEmpty', 'isNotEmpty'], config.type)) return true
    return config.type && config.value
  }

  function component ({ value, onChange }) {
    return (
      <div className='flex items-center gap-2'>
        <select
          className='kp-select flex-1'
          onChange={e => onChange({ ...value, type: e.target.value })}
          value={value.type || ''}
        >
          <option value=''>- - -</option>
          <option value='isSelected'>
            <Trans id='is' />
          </option>
          <option value='isNotSelected'>
            <Trans id='is.not' />
          </option>
          <option value='isEmpty'>
            <Trans id='is.empty' />
          </option>
          <option value='isNotEmpty'>
            <Trans id='is.not.empty' />
          </option>
        </select>
        {hasValue(value.type) && (
          <select
            className='kp-select flex-1'
            onChange={e => onChange({ ...value, value: e.target.value })}
            value={value.value || ''}
          >
            <option value=''>- - -</option>
            {map(options, ({ key, lbl }) => (
              <option key={key} value={key}>
                {lbl}
              </option>
            ))}
          </select>
        )}
      </div>
    )
  }

  function toWorkflowFormat (expr, gadget) {
    const newExpr = {
      left: {
        dataType: 'text',
        formKey: expr.formKey,
        label: gadget.label
      },
      operator: uiToWf[expr.data.type]
    }
    if (hasValue(expr.data.type)) {
      newExpr.right = {
        dataType: 'text',
        value: expr.data.value
      }
    }
    return newExpr
  }

  function fromWorkflowFormat (expr) {
    const newExpr = {
      formKey: expr.left.formKey,
      data: { type: wfToUi[expr.operator] }
    }
    if (hasValue(newExpr.data.type)) {
      newExpr.data.value = expr.right.value
    }
    return newExpr
  }

  return {
    check,
    component,
    configIsValid,
    toWorkflowFormat,
    fromWorkflowFormat
  }
}

const hasValue = type => type && !includes(['isEmpty', 'isNotEmpty'], type)

const uiToWf = {
  isSelected: 'is',
  isNotSelected: 'is not',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)
