/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { includes, isArray, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'

export const Config = ({ id, errors }) => {
  if (!errors.length) return null
  if (errors.length === 1) {
    return (
      <ConfigError className='text-[#c23e38] dark:text-red-500' id={id}>
        Warning: {errors[0].message}
      </ConfigError>
    )
  }
  return (
    <ul id={id}>
      {map(errors, ({ message }, i) => (
        <li key={i}>
          <ConfigError className='text-[#c23e38] dark:text-red-500'>
            Warning: {message}
          </ConfigError>
        </li>
      ))}
    </ul>
  )
}

const ConfigError = styled.div`
  font-weight: bold;
`

export const View = ({ errors, truncate }) => {
  if (!errors.length) return null
  if (errors.length === 1) {
    const { message } = errors[0]
    return (
      <ViewError className='text-[#c23e38] dark:text-red-500'>
        <Icon
          className='fill-[#c23e38] dark:fill-red-500'
          height={18}
          width={18}
        />
        {truncate ? (
          <Shortened title={message}>{message}</Shortened>
        ) : (
          <Broken>{message}</Broken>
        )}
      </ViewError>
    )
  }
  return (
    <ErrorList>
      {map(errors, ({ message }, i) => (
        <ViewError className='text-[#c23e38] dark:text-red-500' as='li' key={i}>
          <Icon
            className='fill-[#c23e38] dark:fill-red-500'
            height={18}
            width={18}
          />
          {truncate ? (
            <Shortened title={message}>{message}</Shortened>
          ) : (
            <Broken>{message}</Broken>
          )}
        </ViewError>
      ))}
    </ErrorList>
  )
}

const ErrorList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ViewError = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
`

const Shortened = styled.div`
  white-space: nowrap;
  position: absolute;
  left: 36px;
  width: calc(100% - 48px);
  overflow: hidden;
  text-overflow: ellipsis;
`

const Broken = styled.div`
  word-break: break-word;
`

const Icon = styled(Icons.AlertError)`
  margin-right: 4px;
`

export const wrap =
  (fn, key) =>
  (...args) =>
    fn(...args).map(message => ({ key, message }))

export const filter = (errors, keys) => {
  if (!isArray(keys)) keys = [keys]
  return errors.filter(error => includes(keys, error.key))
}
