/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import VoronoiContext from './context'

export default function Gatherer (props) {
  const [state, setState] = React.useState({
    voronoiData: null,
    width: null,
    height: null,
    left: null,
    top: null
  })
  const ref = React.useRef()
  const recalculatorRef = React.useRef({})
  React.useEffect(() => {
    if (props.dragging) {
      const voronoiData = {}
      for (const id in recalculatorRef.current) {
        const fn = recalculatorRef.current[id]
        const points = fn()
        if (points) voronoiData[id] = points
      }
      let { width, height, left, top } =
        ref.current.firstChild.getBoundingClientRect()
      const container = props.container?.current
      if (container) {
        const d = container.getBoundingClientRect()
        const { scrollLeft, scrollTop } = container
        left = d.left - scrollLeft
        top = d.top - scrollTop
      }
      setState({ voronoiData, width, height, left, top })
    } else {
      setState({
        voronoiData: null,
        width: null,
        height: null,
        left: null,
        top: null
      })
    }
  }, [props.dragging])

  const onRecalculate = (id, fn) => {
    recalculatorRef.current[id] = fn
    return () => {
      delete recalculatorRef.current[id]
    }
  }

  return (
    <div ref={ref} className='relative flex' data-testid='voronoi-gatherer'>
      <VoronoiContext.Provider value={{ onRecalculate, ...state }}>
        {props.children}
      </VoronoiContext.Provider>
    </div>
  )
}
