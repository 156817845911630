/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8.00003 13C7.78197 13.0003 7.56637 12.954 7.3676 12.8644C7.16883 12.7747 6.99147 12.6437 6.84737 12.48L0.306699 5.04533C0.0999714 4.79897 -0.00216172 4.4815 0.0221381 4.16081C0.0464379 3.84012 0.195242 3.54166 0.436732 3.32926C0.678221 3.11686 0.993229 3.00736 1.3144 3.0242C1.63556 3.04103 1.9374 3.18285 2.15537 3.41933L7.8747 9.92066C7.89034 9.93851 7.90961 9.95281 7.93123 9.96261C7.95284 9.9724 7.9763 9.97747 8.00003 9.97747C8.02376 9.97747 8.04722 9.9724 8.06884 9.96261C8.09045 9.95281 8.10972 9.93851 8.12537 9.92066L13.8447 3.41933C13.9502 3.29362 14.0797 3.19025 14.2258 3.11532C14.3718 3.04039 14.5313 2.99541 14.6949 2.98301C14.8586 2.97061 15.023 2.99105 15.1787 3.04312C15.3343 3.09519 15.4779 3.17785 15.6012 3.28624C15.7244 3.39462 15.8247 3.52655 15.8962 3.67426C15.9677 3.82197 16.009 3.98249 16.0176 4.14638C16.0262 4.31026 16.0019 4.47421 15.9462 4.62859C15.8906 4.78297 15.8046 4.92466 15.6934 5.04533L9.1547 12.4773C9.01034 12.6414 8.83271 12.7728 8.63363 12.863C8.43454 12.9531 8.21856 12.9998 8.00003 13Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8.00003 13C7.78197 13.0003 7.56637 12.954 7.3676 12.8644C7.16883 12.7747 6.99147 12.6437 6.84737 12.48L0.306699 5.04533C0.0999714 4.79897 -0.00216172 4.4815 0.0221381 4.16081C0.0464379 3.84012 0.195242 3.54166 0.436732 3.32926C0.678221 3.11686 0.993229 3.00736 1.3144 3.0242C1.63556 3.04103 1.9374 3.18285 2.15537 3.41933L7.8747 9.92066C7.89034 9.93851 7.90961 9.95281 7.93123 9.96261C7.95284 9.9724 7.9763 9.97747 8.00003 9.97747C8.02376 9.97747 8.04722 9.9724 8.06884 9.96261C8.09045 9.95281 8.10972 9.93851 8.12537 9.92066L13.8447 3.41933C13.9502 3.29362 14.0797 3.19025 14.2258 3.11532C14.3718 3.04039 14.5313 2.99541 14.6949 2.98301C14.8586 2.97061 15.023 2.99105 15.1787 3.04312C15.3343 3.09519 15.4779 3.17785 15.6012 3.28624C15.7244 3.39462 15.8247 3.52655 15.8962 3.67426C15.9677 3.82197 16.009 3.98249 16.0176 4.14638C16.0262 4.31026 16.0019 4.47421 15.9462 4.62859C15.8906 4.78297 15.8046 4.92466 15.6934 5.04533L9.1547 12.4773C9.01034 12.6414 8.83271 12.7728 8.63363 12.863C8.43454 12.9531 8.21856 12.9998 8.00003 13Z\" />\n</svg>\n";
export default src;
