/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { NodeEditor } from 'flume'
import qs from 'querystring'
import React from 'react'

import * as Icons from '../icons'
import Legend from './legend'
import NewButton from './new'

export default function FlumeEditor ({ ports, ...props }) {
  return (
    <>
      <NodeEditor {...props} />
      <NewButton />
      <a
        className='absolute bottom-3 right-20 flex flex-col items-center text-white dark:text-black'
        href={`https://team.kualibuild.com/app/5fa9a9bba041a40022995dd5/start?${qs.stringify(
          { url: window.location.href, user: window?.loggedInUser?.displayName }
        )}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icons.Message className='h-12 w-12' />
        <Icons.EmojiSad className='absolute left-3 top-2.5 h-6 w-6 fill-black' />
      </a>
      <Legend ports={ports} />
    </>
  )
}
