/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { updateParentQueryCache } from '../../components/hierarchy-view'

export const useDeleteGroupMutation = groupId => {
  const [deleteGroup] = useMutation(mutation)
  return () => deleteGroup(getParams(groupId))
}

const mutation = gql`
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      group {
        id
        parentId
        categoryId
      }
    }
  }
`

const getParams = groupId => {
  return {
    variables: { id: groupId },
    update (cache, result) {
      const group = result.data?.deleteGroup?.group
      if (!group) return
      updateParentQueryCache(cache, group)
    }
  }
}
