/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import * as Icons from '../../../icons'
import { ReactComponent as InspectionIllustration } from '../../../illustrations/inspection.svg.jsx'
import Checkbox from '../../../ui/checkbox'
import InfoBox from '../../../ui/info-box'
import ShareButton from '../../form/share-button'

export const FirstPublish = ({ publish }) => {
  const [showDraft, setShowDraft] = React.useState(false)
  return (
    <div className='flex justify-center pt-36'>
      <InspectionIllustration className='relative mt-40 shrink-0' />
      <InfoBox
        title={i18n._('pagesbuilder.publish.first')}
        subtitle={i18n._('pagesbuilder.publish.first.subtitle')}
        width={400}
      >
        <div className='my-6 h-px bg-medium-gray-100' />
        <div className='flex items-center'>
          <span className='text-base font-medium'>
            <Trans id='pagesbuilder.publish.unsubmitted.drafts' />
          </span>
          <TooltipTrigger
            as={Icons.AlertInfo}
            label={i18n._('pagesbuilder.publish.show.drafts')}
            ml={2}
            tooltipId='show-drafts-help'
          />
          <Tooltip id='show-drafts-help' place='right'>
            <div className='w-48'>
              <Trans id='pagesbuilder.publish.show.drafts.help' />
            </div>
          </Tooltip>
        </div>
        <Checkbox
          checked={showDraft}
          label={i18n._('pagesbuilder.publish.show.saved.drafts')}
          onChange={() => setShowDraft(!showDraft)}
        />
        <button
          className='kp-button-solid mt-4'
          onClick={() => publish(showDraft)}
        >
          <Trans id='publish' />
        </button>
      </InfoBox>
    </div>
  )
}

export const NoChanges = ({ app, q, getSchema, showDraft }) => {
  return (
    <div className='flex justify-center pt-36'>
      <InspectionIllustration className='relative mt-40 shrink-0' />
      <InfoBox
        title={i18n._('pagesbuilder.publish.no.changes')}
        subtitle={i18n._('pagesbuilder.publish.no.changes.subtitle')}
        width={400}
      >
        <div className='mt-4'>
          <ShareButton
            query={q}
            isPublished={app?.dataset?.isPublished}
            canAnonymousCreate={app?.canAnonymousCreate}
            getSchema={getSchema}
          />
        </div>
        <div className='my-6 h-px bg-light-gray-300' />
        <div className='flex items-center'>
          <span className='text-base font-medium'>
            <Trans id='pagesbuilder.publish.unsubmitted.drafts' />
          </span>
          <TooltipTrigger
            as={Icons.AlertInfo}
            label={i18n._('pagesbuilder.publish.show.drafts')}
            ml={2}
            tooltipId='show-drafts-help'
          />
          <Tooltip id='show-drafts-help' place='right'>
            <div style={{ width: 190 }}>
              <Trans id='pagesbuilder.publish.show.drafts.help' />
            </div>
          </Tooltip>
        </div>
        <Checkbox
          checked={showDraft}
          label={i18n._('pagesbuilder.publish.show.saved.drafts')}
          disabled
        />
      </InfoBox>
    </div>
  )
}
