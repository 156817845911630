/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import React from 'react'

import { useIds } from '../../../components/use-ids'

export const useUpdateDataSettingsMutation = () => {
  const { appId, datasetId } = useIds()
  const [updateDataSettings] = useMutation(mutation, { ignoreResults: true })
  return React.useCallback(
    (sort, columns, query) =>
      updateDataSettings(getParams({ appId, datasetId, sort, columns }, query)),
    [updateDataSettings]
  )
}

const mutation = gql`
  mutation UpdateDataSettings(
    $appId: ID!
    $datasetId: ID
    $sort: [String!]
    $columns: [String!]
  ) {
    updateAppSettings(
      args: {
        id: $appId
        pageId: $datasetId
        documentListConfig: { sort: $sort, columns: $columns }
      }
    ) {
      id
      dataset(id: $datasetId) {
        id
        documentListConfig {
          sort
          columns
        }
      }
    }
  }
`

const getParams = ({ appId, datasetId, sort, columns }, query) => {
  return {
    variables: { appId, datasetId, sort, columns },
    refetchQueries: [query]
  }
}
