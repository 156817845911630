/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M0.921368 7.58948L8.77337 15.3021L8.7847 15.3128C9.03601 15.5569 9.37327 15.6922 9.72357 15.6896C10.0739 15.687 10.4091 15.5466 10.6567 15.2988L15.3667 10.5901C15.4913 10.4657 15.5899 10.3177 15.657 10.1549C15.724 9.99207 15.7582 9.81757 15.7574 9.64148C15.7567 9.46538 15.7211 9.29117 15.6526 9.12892C15.5842 8.96667 15.4843 8.81958 15.3587 8.69614L7.53337 1.00014C7.52137 0.987476 7.51004 0.975476 7.49737 0.964143C7.06028 0.564203 6.4954 0.332437 5.90337 0.310143H1.57537C1.22175 0.310143 0.882608 0.450618 0.632559 0.700667C0.382511 0.950715 0.242035 1.28985 0.242035 1.64348L0.246702 5.97348C0.271412 6.57542 0.510731 7.14865 0.921368 7.58948ZM3.24203 4.31014C3.24203 4.11236 3.30068 3.91902 3.41057 3.75457C3.52045 3.59012 3.67663 3.46195 3.85935 3.38626C4.04208 3.31058 4.24314 3.29077 4.43713 3.32936C4.63111 3.36794 4.80929 3.46318 4.94914 3.60304C5.08899 3.74289 5.18424 3.92107 5.22282 4.11505C5.26141 4.30903 5.2416 4.5101 5.16591 4.69283C5.09023 4.87555 4.96205 5.03173 4.79761 5.14161C4.63316 5.25149 4.43982 5.31014 4.24203 5.31014C3.97682 5.31014 3.72246 5.20479 3.53493 5.01725C3.34739 4.82971 3.24203 4.57536 3.24203 4.31014Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M0.921368 7.58948L8.77337 15.3021L8.7847 15.3128C9.03601 15.5569 9.37327 15.6922 9.72357 15.6896C10.0739 15.687 10.4091 15.5466 10.6567 15.2988L15.3667 10.5901C15.4913 10.4657 15.5899 10.3177 15.657 10.1549C15.724 9.99207 15.7582 9.81757 15.7574 9.64148C15.7567 9.46538 15.7211 9.29117 15.6526 9.12892C15.5842 8.96667 15.4843 8.81958 15.3587 8.69614L7.53337 1.00014C7.52137 0.987476 7.51004 0.975476 7.49737 0.964143C7.06028 0.564203 6.4954 0.332437 5.90337 0.310143H1.57537C1.22175 0.310143 0.882608 0.450618 0.632559 0.700667C0.382511 0.950715 0.242035 1.28985 0.242035 1.64348L0.246702 5.97348C0.271412 6.57542 0.510731 7.14865 0.921368 7.58948ZM3.24203 4.31014C3.24203 4.11236 3.30068 3.91902 3.41057 3.75457C3.52045 3.59012 3.67663 3.46195 3.85935 3.38626C4.04208 3.31058 4.24314 3.29077 4.43713 3.32936C4.63111 3.36794 4.80929 3.46318 4.94914 3.60304C5.08899 3.74289 5.18424 3.92107 5.22282 4.11505C5.26141 4.30903 5.2416 4.5101 5.16591 4.69283C5.09023 4.87555 4.96205 5.03173 4.79761 5.14161C4.63316 5.25149 4.43982 5.31014 4.24203 5.31014C3.97682 5.31014 3.72246 5.20479 3.53493 5.01725C3.34739 4.82971 3.24203 4.57536 3.24203 4.31014Z\" />\n</svg>\n";
export default src;
