/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import highlight from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import plaintext from 'highlight.js/lib/languages/plaintext'
import 'highlight.js/styles/base16/darcula.css'
import React from 'react'

highlight.registerLanguage('json', json)
highlight.registerLanguage('plaintext', plaintext)

export default function Highlight ({ language, children }) {
  const [codeRef, setCodeRef] = React.useState(null)
  React.useEffect(() => {
    if (codeRef) highlight.highlightElement(codeRef)
  }, [codeRef])
  return (
    <pre>
      <code ref={setCodeRef} className={language}>
        {children}
      </code>
    </pre>
  )
}
