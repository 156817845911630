/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// To add calculation ability to a gadget, add which functions should be available to the gadget in this default export.  Then add the CalculationConfig component to the gadget's config.jsx file, and pass in the gadget type to the CalculationConfig component, which will reference this file and add the functions to the gadget.
import { CALC_FUNCTIONS } from './calculation-constants'

const { SUM, SUBTRACT, PRODUCT, MIN, MAX, AVG, MEDIAN, COUNT } = CALC_FUNCTIONS

const STANDARD = [SUM, SUBTRACT, PRODUCT, AVG, MEDIAN, MIN, MAX, COUNT]

export default {
  Number: STANDARD,
  Currency: STANDARD,
  Repeater: ['none', ...STANDARD],
  Table: ['none', ...STANDARD]
}
