/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

// Wrap the table in a `div` to prevent the `height: 1px` hack
// from collapsing the table's height in the parent container
export const Table = React.forwardRef((props, ref) => (
  <div>
    <StyledTable {...props} ref={ref} />
  </div>
))

const StyledTable = styled.table`
  background-color: #fff;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  height: 1px; /* Hack to get chrome to render table cells without auto padding */
  .formbot-gadget {
    border: none;
  }
  th {
    border: 1px solid var(--light-gray-300);
  }
  td {
    border: 1px solid var(--light-gray-300);
  }
  .print-label {
    display: none;
  }

  @media print {
    table-layout: fixed;
    /* .scrollable & {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: auto;
      tbody {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      tr {
        display: flex;
        border: 1px solid #ddd;
        flex-wrap: wrap;
        html.dark & {
          border: 1px solid #333;
        }
      }
      thead {
        display: none;
      }
      tfoot {
        display: flex;
      }
      tfoot > * {
        display: hidden;
      }
      tfoot > .calculation {
        display: flex;
      }
      th {
        display: flex;
      }
      td {
        display: flex;
        border: none;
      }
      .print-label {
        display: inline;
      }
    } */
  }
`

export const Thead = styled.thead``

export const Th = styled.th`
  height: 37px;
  font-weight: 500;
  padding: 8px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #eee;
  border: 1px solid var(--light-gray-300);
  html.dark & {
    // Outlier: dark:bg-light-gray-300 dark:text-black
    background: #333;
    color: #fff;
  }
  border-left: none;
`

export const InnerTh = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media print {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`

export const Tbody = styled.tbody`
  padding: 0;
  > tr {
    height: 100%;
  }
`

export const Tr = styled.tr`
  padding: 0;
`

export const Td = styled.td`
  padding: 0;
  height: inherit;
  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }
`

export const Tfoot = styled.tfoot`
  height: 48px;
  > * {
    text-align: right;
  }
`

export const EditCellWrapper = styled.div`
  display: grid;
  height: 100%;
  & > .formbot-gadget {
    justify-content: center;
  }
`
