/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, find, map } from 'lodash'
import React from 'react'

import { convertToIdLabel, convertTruthyKeys } from './utils'

export default function CheckboxesView ({ value = [], details }) {
  if (!details) {
    return <div>{value.map(value => value?.label ?? value).join(', ')}</div>
  }
  const options = filter(details?.options, o => o.key)
  const correctedValue = convertToIdLabel(convertTruthyKeys(value), details)
  return (
    <div>
      {map(options, o => {
        const match = find(correctedValue, val => val.id === o.key)
        return match ? <div key={o.key}>{match?.label || o.lbl}</div> : null
      })}
    </div>
  )
}
