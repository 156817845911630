/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled, { css } from 'styled-components'

export default function Assembler ({ desc, template, gridded, readOnly }) {
  return (
    <Gadget
      gridded={gridded}
      className='formbot-gadget ql-read-only'
      id={`gadget-${template.id}`}
      readOnly={readOnly}
    >
      {desc}
    </Gadget>
  )
}

const Gadget = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 100%;
  min-width: 12.5rem;
  opacity: ${p => (p.readOnly ? 0.7 : 1)};

  &:only-child {
    flex-grow: 1;
  }

  ${p =>
    p.gridded &&
    css`
      border: 1px solid #ddd;
      border-left: none;
      border-top: none;
      padding: 0 16px 8px 16px;
    `}
  html.dark & {
    ${p =>
      p.gridded &&
      css`
        border: 1px solid #333;
        border-left: none;
        border-top: none;
        padding: 0 16px 8px 16px;
      `}
    * {
      color: #bbb;
    }
  }
`
