/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export default function useFocusFirstInput () {
  const focusRef = React.useRef()
  React.useEffect(() => {
    focusFirstInput(focusRef)
  }, [])
  return focusRef
}

export function focusFirstInput (focusRef) {
  if (focusRef.current) {
    const selector = ['input', 'select', 'textarea']
      .map(
        s =>
          `${s}:not([type="hidden"]):not([disabled]):not([readonly]):not([class^='ql-'])`
      )
      .join(',')
    const input = focusRef.current.querySelector(
      `.ql-editor[contenteditable='true'], ${selector}`
    )
    if (input) {
      input.focus()
    }
  }
}
