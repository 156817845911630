/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { clone } from 'lodash'
import React from 'react'

export const useUpdateGroupMutation = group => {
  const [updateGroup, { loading }] = useMutation(mutation)
  return [
    React.useCallback(
      data => updateGroup(getParams(group, data)),
      [updateGroup, group]
    ),
    { loading }
  ]
}

const mutation = gql`
  mutation UpdateGroup($groupId: ID!, $data: JSON!) {
    updateGroup(groupId: $groupId, data: $data) {
      ... on Group {
        id
        name
        parentId
        categoryId
        parent {
          id
          label: name
        }
        hasChildren
        category {
          id
          parentId
          roleSchemas {
            id
            name
            description
          }
          fieldSchemas {
            id
            name
            type
          }
        }
        roles {
          id
          name
          membersConnection {
            edges {
              node {
                id
                label: displayName
              }
            }
          }
        }
        roleSchemas {
          id
          name
          description
        }
        fields {
          id
          value
        }
        fieldSchemas {
          id
          name
          type
        }
        historyConnection(args: { skip: 0, limit: 10 }) {
          edges {
            node {
              id
            }
          }
        }
        access {
          isAdmin
          isParentAdmin
        }
      }
    }
  }
`

const getParams = (group, rawData) => {
  const data = { ...rawData }
  if (data.role?.id) data.role = data.role.id
  const optimisticRes = clone(data)
  if (data.roles) {
    optimisticRes.roles = data.roles.map(r => {
      const thisRole = group.roles.find(role => role.roleId === r.id)
      return {
        ...r,
        id: `${group.id}::${r.id}`,
        memberIds: thisRole?.memberIds,
        membersConnection: thisRole?.membersConnection,
        name: thisRole?.name
      }
    })
  }
  return {
    variables: { groupId: group.id, data },
    optimisticResponse: {
      updateGroup: {
        ...group,
        ...optimisticRes,
        historyConnection: null
      }
    }
  }
}
