/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

import { docListRedirect, formatNumber } from '../../utils'

export default function Chart ({ insight, value }) {
  return (
    <div className='flex flex-col gap-2 text-base text-medium-gray-500'>
      {value.buckets.map((v, i) => (
        <div
          key={i}
          className={cx(
            'flex items-center justify-between rounded bg-white p-2',
            { 'cursor-pointer hover:bg-light-gray-200': v.item }
          )}
          onClick={() => v.item && docListRedirect(insight, v.item)}
        >
          <span className='font-medium'>{label(v.item)}</span>
          <span>{formatNumber(v.value)}</span>
        </div>
      ))}
    </div>
  )
}

function label (item) {
  return item?.label || item
}
