/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M3.55544 1.34824C4.87103 0.469192 6.41775 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.58225 15.5308 11.129 14.6518 12.4446C13.7727 13.7602 12.5233 14.7855 11.0615 15.391C9.59966 15.9965 7.99113 16.155 6.43928 15.8463C4.88743 15.5376 3.46197 14.7757 2.34315 13.6569C1.22433 12.538 0.462403 11.1126 0.153721 9.56072C-0.15496 8.00887 0.00346641 6.40034 0.608967 4.93853C1.21447 3.47672 2.23985 2.22729 3.55544 1.34824ZM4.81157 4.66666H11.1884C11.419 4.66666 11.6402 4.75827 11.8033 4.92135C11.9664 5.08442 12.058 5.3056 12.058 5.53623V10.4638C12.058 10.6944 11.9664 10.9156 11.8033 11.0787C11.6402 11.2417 11.419 11.3334 11.1884 11.3334H4.81157C4.58095 11.3334 4.35977 11.2417 4.19669 11.0787C4.03362 10.9156 3.942 10.6944 3.942 10.4638V5.53623C3.942 5.3056 4.03362 5.08442 4.19669 4.92135C4.35977 4.75827 4.58095 4.66666 4.81157 4.66666ZM8.80579 8.45798L11.1246 7.06667C11.2184 7.00456 11.2845 6.90853 11.3089 6.79875C11.3334 6.68897 11.3144 6.57398 11.2558 6.47794C11.1973 6.38189 11.1038 6.31226 10.9951 6.28368C10.8863 6.25509 10.7707 6.26977 10.6725 6.32464L8.35362 7.71015C8.24473 7.7744 8.12062 7.80829 7.9942 7.80829C7.86777 7.80829 7.74366 7.7744 7.63477 7.71015L5.31592 6.32464C5.21774 6.26977 5.10211 6.25509 4.99333 6.28368C4.88455 6.31226 4.79108 6.38189 4.73255 6.47794C4.67403 6.57398 4.655 6.68897 4.67946 6.79875C4.70392 6.90853 4.76998 7.00456 4.86375 7.06667L7.1826 8.45798C7.42752 8.60576 7.70814 8.68386 7.9942 8.68386C8.28025 8.68386 8.56087 8.60576 8.80579 8.45798Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.55544 1.34824C4.87103 0.469192 6.41775 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.58225 15.5308 11.129 14.6518 12.4446C13.7727 13.7602 12.5233 14.7855 11.0615 15.391C9.59966 15.9965 7.99113 16.155 6.43928 15.8463C4.88743 15.5376 3.46197 14.7757 2.34315 13.6569C1.22433 12.538 0.462403 11.1126 0.153721 9.56072C-0.15496 8.00887 0.00346641 6.40034 0.608967 4.93853C1.21447 3.47672 2.23985 2.22729 3.55544 1.34824ZM4.81157 4.66666H11.1884C11.419 4.66666 11.6402 4.75827 11.8033 4.92135C11.9664 5.08442 12.058 5.3056 12.058 5.53623V10.4638C12.058 10.6944 11.9664 10.9156 11.8033 11.0787C11.6402 11.2417 11.419 11.3334 11.1884 11.3334H4.81157C4.58095 11.3334 4.35977 11.2417 4.19669 11.0787C4.03362 10.9156 3.942 10.6944 3.942 10.4638V5.53623C3.942 5.3056 4.03362 5.08442 4.19669 4.92135C4.35977 4.75827 4.58095 4.66666 4.81157 4.66666ZM8.80579 8.45798L11.1246 7.06667C11.2184 7.00456 11.2845 6.90853 11.3089 6.79875C11.3334 6.68897 11.3144 6.57398 11.2558 6.47794C11.1973 6.38189 11.1038 6.31226 10.9951 6.28368C10.8863 6.25509 10.7707 6.26977 10.6725 6.32464L8.35362 7.71015C8.24473 7.7744 8.12062 7.80829 7.9942 7.80829C7.86777 7.80829 7.74366 7.7744 7.63477 7.71015L5.31592 6.32464C5.21774 6.26977 5.10211 6.25509 4.99333 6.28368C4.88455 6.31226 4.79108 6.38189 4.73255 6.47794C4.67403 6.57398 4.655 6.68897 4.67946 6.79875C4.70392 6.90853 4.76998 7.00456 4.86375 7.06667L7.1826 8.45798C7.42752 8.60576 7.70814 8.68386 7.9942 8.68386C8.28025 8.68386 8.56087 8.60576 8.80579 8.45798Z\" />\n</svg>\n";
export default src;
