/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isEmpty } from 'lodash'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import Button from '../../../ui/button'
import { FORM_STATUSES } from './constants'

export default function SaveButton ({ save, status, validations, canTest }) {
  if (canTest && status === FORM_STATUSES.UNTESTED) {
    return (
      <>
        <TooltipTrigger
          tooltipId='api-integration-form-save-button-tooltip'
          label={i18n._('why.save.disabled')}
        >
          <Button disabled='true'>
            <Trans id='save' />
          </Button>
        </TooltipTrigger>
        <Tooltip id='api-integration-form-save-button-tooltip'>
          <Trans id='run.successful.test.to.save' />
        </Tooltip>
      </>
    )
  } else {
    return (
      <Button
        disabled={status === FORM_STATUSES.SAVING || !isEmpty(validations)}
        onClick={save}
      >
        {status === FORM_STATUSES.SAVING ? (
          <Trans id='saving...' />
        ) : (
          <Trans id='save' />
        )}
      </Button>
    )
  }
}
