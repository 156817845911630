/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { some } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import * as Icons from '../../icons'

export const Basic = ({ manifest, onClick, selected, fade, ...rest }) => {
  const truncate = manifest.truncate ? manifest.truncate(rest.details) : false
  const errors = manifest.validate
    ? manifest.validate(
        rest.details,
        rest.fbProps.fieldsAll,
        rest.fbProps.lineage
      )
    : []
  const [fading, setFading] = React.useState(fade)
  React.useEffect(() => {
    if (fade) {
      const timeout = setTimeout(() => setFading(true), 500)
      return () => clearTimeout(timeout)
    }
    setFading(false)
  }, [fade])
  const name = rest.details.stepName || manifest.name
  const hasEdit = manifest.canHaveEditPerms
    ? hasEditPerms(
        rest.details.sectionSettings,
        rest.fbProps?.workflowSettings?.defaultFormToViewOnly,
        rest.fbProps?.formSections
      )
    : false
  const hasHidden = rest.details.sectionSettings?.enabled
    ? some(
        rest.fbProps?.formSections,
        section => rest.details.sectionSettings.value?.[section.id] === 'hide'
      )
    : false
  const hasAttachment = rest.details.exportOptions?.length || false
  const hasReminders = rest.details.reminderSettings?.enabled
  const overridesDenySetting = rest.details.ifDenied?.enabled != null
  const overridesSendbackSetting = rest.details.disableSendback != null
  const overridesHistorySetting = rest.details.disableDocumentHistory != null
  return (
    <Wrapper
      tabIndex={onClick && !rest.fbProps.a11yDrag ? 0 : null}
      aria-label={!rest.fbProps.a11yDrag ? name : null}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick(e)
        if (e.key === 'Delete') {
          e.stopPropagation()
          rest.fbProps.removeStep(rest.details.clientId)
        }
      }}
      onClick={onClick}
      selected={selected}
      truncate={truncate}
      id={`wfStep-${rest.details.clientId}`}
    >
      <Inner startFade={fade && !fading}>
        <Title error={!!errors.length}>
          <Icon as={manifest.Icon} fill={manifest.color} />
          {truncate ? (
            <Shortened title={name}>{name}</Shortened>
          ) : (
            <Broken title={name}>{name}</Broken>
          )}

          <Icons.Move
            className='absolute left-3 w-0 bg-black fill-white focus:w-4 focus:rounded-sm focus:p-0.5 focus:opacity-100 dark:bg-light-gray-100 dark:fill-black'
            role='button'
            tabIndex={onClick && !rest.fbProps.a11yDrag ? 0 : null}
            aria-label={!rest.fbProps.a11yDrag ? `Move ${name}` : null}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                e.stopPropagation()
                rest.fbProps.setA11yDrag({ id: rest.details.clientId })
              }
            }}
          />
        </Title>
        <manifest.Node {...rest} errors={errors} />
      </Inner>
      <Badges>
        {hasHidden && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('hidden.sections')}
              tooltipId={`hidden-tag-${rest.details.clientId}`}
            >
              <Icons.Hidden width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip id={`hidden-tag-${rest.details.clientId}`} place='left'>
              <Trans id='this.step.hidden.sections' />
            </Tooltip>
          </>
        )}
        {hasEdit && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('editable.sections')}
              tooltipId={`edit-tag-${rest.details.clientId}`}
            >
              <Icons.Edit width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip id={`edit-tag-${rest.details.clientId}`} place='left'>
              <Trans id='step.has.editable.sections' />
            </Tooltip>
          </>
        )}
        {hasAttachment && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('includes.attachment')}
              tooltipId={`attachment-tag-${rest.details.clientId}`}
            >
              <Icons.Attachment width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip
              id={`attachment-tag-${rest.details.clientId}`}
              place='left'
            >
              <Trans id='notifications.includes.attachment.document' />
            </Tooltip>
          </>
        )}
        {hasReminders && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('has.reminders.configured')}
              tooltipId={`reminders-tag-${rest.details.clientId}`}
            >
              <Icons.Notification width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip id={`reminders-tag-${rest.details.clientId}`} place='left'>
              <Trans id='step.automated.reminders.configured' />
            </Tooltip>
          </>
        )}
        {overridesDenySetting && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('overrides.deny.setting')}
              tooltipId={`deny-tag-${rest.details.clientId}`}
            >
              <Icons.DenyOverride width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip id={`deny-tag-${rest.details.clientId}`} place='left'>
              <Trans id='step.overrides.workflow.deny' />
            </Tooltip>
          </>
        )}
        {overridesSendbackSetting && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('overrides.sendback.setting')}
              tooltipId={`sb-tag-${rest.details.clientId}`}
            >
              <Icons.SendbackOverride width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip id={`sb-tag-${rest.details.clientId}`} place='left'>
              <Trans id='step.overrides.workflow.send.back.settings' />
            </Tooltip>
          </>
        )}
        {overridesHistorySetting && (
          <>
            <TooltipTrigger
              as={Tag}
              label={i18n._('overrides.history.setting')}
              tooltipId={`history-tag-${rest.details.clientId}`}
            >
              <Icons.Clipboard width={12} height={12} fill='#ffffff' />
            </TooltipTrigger>
            <Tooltip id={`history-tag-${rest.details.clientId}`} place='left'>
              <Trans id='step.overrides.workflow.status.settings' />
            </Tooltip>
          </>
        )}
      </Badges>
    </Wrapper>
  )
}

const hasEditPerms = (sectionSettings, defaultFormToViewOnly, formSections) => {
  if (sectionSettings?.enabled) {
    return some(
      formSections,
      section =>
        sectionSettings.value?.[section.id] === 'edit' ||
        (!sectionSettings.value?.[section.id] &&
          defaultFormToViewOnly === false)
    )
  } else {
    return defaultFormToViewOnly === false
  }
}

const Shortened = styled.div`
  white-space: nowrap;
  position: absolute;
  left: 36px;
  width: calc(100% - 48px);
  overflow: hidden;
  text-overflow: ellipsis;
`

const Broken = styled.div`
  word-break: break-word;
  max-height: 28px;
  overflow: hidden;
`

const Wrapper = styled.div`
  border: 4px solid;
  border-color: ${p => (p.selected ? 'var(--medium-gray-400)' : 'transparent')};
  border-radius: 4px;
  cursor: ${p => (p.onClick ? 'pointer' : 'not-allowed')};
  min-width: 150px;
  max-width: ${p => (p.truncate ? 'inherit' : '200px')};
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  [data-whatinput='mouse'] & {
    outline: none;
  }
  [data-whatinput='mouse'] &::-moz-focus-inner {
    border: 0;
  }
`

const Inner = styled.div`
  background: ${p => (p.startFade ? '#fffde9' : 'white')};
  html.dark & {
    // Outlier: dark:bg-light-gray-300
    background: #333;
  }
  transition: background 1s;
  box-shadow:
    0px 3px 3px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 5px;
`

const Title = styled.div`
  background: rgba(0, 0, 0, 0.03);
  color: ${p => (p.error ? '#c23e38' : '#767676')};
  html.dark & {
    // Outlier: cx({ 'text-red-500: error, 'text-medium-gray-400': !error}, dark:bg-light-gray-200)
    background: #222;
    color: ${p => (p.error ? '#ff6d6b' : '#999999')};
  }
  font-weight: ${p => (p.error ? 'bold' : 400)};
  font-size: 13px;
  line-height: 14px;
  padding: 8px 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`

const Icon = styled.i`
  box-sizing: content-box;
  padding-right: 8px;
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin: 0 2px;
  background: #666666;
  border-radius: 4px;
  & + .__react_component_tooltip:not(.show) {
    display: none;
  }
`
const Badges = styled.div`
  position: absolute;
  bottom: -10px;
  right: 8px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
`
