/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

// adapted from https://github.com/sindresorhus/p-debounce/releases/tag/v4.0.0
export const pDebounce = (fn, wait) => {
  if (!Number.isFinite(wait)) {
    throw new TypeError('Expected `wait` to be a finite number')
  }
  let timeout
  let resolveList = []
  return function (...arguments_) {
    return new Promise(resolve => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        const result = fn.apply(this, arguments_)
        for (resolve of resolveList) resolve(result)
        resolveList = []
      }, wait)
      resolveList.push(resolve)
    })
  }
}
