/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.18 2.66666H0.82C0.602522 2.66666 0.393952 2.75305 0.240172 2.90683C0.0863926 3.06061 0 3.26918 0 3.48666L0 12.5133C0 12.7308 0.0863926 12.9394 0.240172 13.0932C0.393952 13.2469 0.602522 13.3333 0.82 13.3333H15.18C15.3975 13.3333 15.606 13.2469 15.7598 13.0932C15.9136 12.9394 16 12.7308 16 12.5133V3.48666C16 3.26918 15.9136 3.06061 15.7598 2.90683C15.606 2.75305 15.3975 2.66666 15.18 2.66666ZM6.66667 9.48666C6.66667 9.63518 6.60767 9.77762 6.50265 9.88264C6.39763 9.98766 6.25519 10.0467 6.10667 10.0467H3.14667C3.07344 10.0467 3.00094 10.0321 2.93336 10.0039C2.86579 9.97568 2.80449 9.93433 2.75303 9.88224C2.70156 9.83015 2.66094 9.76836 2.63352 9.70046C2.60611 9.63255 2.59245 9.55988 2.59333 9.48666V6.51332C2.59245 6.4401 2.60611 6.36743 2.63352 6.29952C2.66094 6.23162 2.70156 6.16983 2.75303 6.11774C2.80449 6.06565 2.86579 6.0243 2.93336 5.99607C3.00094 5.96785 3.07344 5.95332 3.14667 5.95332H6.12667C6.27519 5.95332 6.41763 6.01232 6.52265 6.11734C6.62767 6.22236 6.68667 6.3648 6.68667 6.51332L6.66667 9.48666ZM13.24 10.1533H9.15333C9.02073 10.1533 8.89355 10.1006 8.79978 10.0069C8.70601 9.91311 8.65333 9.78593 8.65333 9.65332C8.65333 9.52071 8.70601 9.39354 8.79978 9.29977C8.89355 9.206 9.02073 9.15332 9.15333 9.15332H13.26C13.3926 9.15332 13.5198 9.206 13.6136 9.29977C13.7073 9.39354 13.76 9.52071 13.76 9.65332C13.76 9.78593 13.7073 9.91311 13.6136 10.0069C13.5198 10.1006 13.3926 10.1533 13.26 10.1533H13.24ZM13.24 7.69332H9.15333C9.02073 7.69332 8.89355 7.64064 8.79978 7.54688C8.70601 7.45311 8.65333 7.32593 8.65333 7.19332C8.65333 7.06072 8.70601 6.93354 8.79978 6.83977C8.89355 6.746 9.02073 6.69332 9.15333 6.69332H13.26C13.3926 6.69332 13.5198 6.746 13.6136 6.83977C13.7073 6.93354 13.76 7.06072 13.76 7.19332C13.76 7.32593 13.7073 7.45311 13.6136 7.54688C13.5198 7.64064 13.3926 7.69332 13.26 7.69332H13.24Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.18 2.66666H0.82C0.602522 2.66666 0.393952 2.75305 0.240172 2.90683C0.0863926 3.06061 0 3.26918 0 3.48666L0 12.5133C0 12.7308 0.0863926 12.9394 0.240172 13.0932C0.393952 13.2469 0.602522 13.3333 0.82 13.3333H15.18C15.3975 13.3333 15.606 13.2469 15.7598 13.0932C15.9136 12.9394 16 12.7308 16 12.5133V3.48666C16 3.26918 15.9136 3.06061 15.7598 2.90683C15.606 2.75305 15.3975 2.66666 15.18 2.66666ZM6.66667 9.48666C6.66667 9.63518 6.60767 9.77762 6.50265 9.88264C6.39763 9.98766 6.25519 10.0467 6.10667 10.0467H3.14667C3.07344 10.0467 3.00094 10.0321 2.93336 10.0039C2.86579 9.97568 2.80449 9.93433 2.75303 9.88224C2.70156 9.83015 2.66094 9.76836 2.63352 9.70046C2.60611 9.63255 2.59245 9.55988 2.59333 9.48666V6.51332C2.59245 6.4401 2.60611 6.36743 2.63352 6.29952C2.66094 6.23162 2.70156 6.16983 2.75303 6.11774C2.80449 6.06565 2.86579 6.0243 2.93336 5.99607C3.00094 5.96785 3.07344 5.95332 3.14667 5.95332H6.12667C6.27519 5.95332 6.41763 6.01232 6.52265 6.11734C6.62767 6.22236 6.68667 6.3648 6.68667 6.51332L6.66667 9.48666ZM13.24 10.1533H9.15333C9.02073 10.1533 8.89355 10.1006 8.79978 10.0069C8.70601 9.91311 8.65333 9.78593 8.65333 9.65332C8.65333 9.52071 8.70601 9.39354 8.79978 9.29977C8.89355 9.206 9.02073 9.15332 9.15333 9.15332H13.26C13.3926 9.15332 13.5198 9.206 13.6136 9.29977C13.7073 9.39354 13.76 9.52071 13.76 9.65332C13.76 9.78593 13.7073 9.91311 13.6136 10.0069C13.5198 10.1006 13.3926 10.1533 13.26 10.1533H13.24ZM13.24 7.69332H9.15333C9.02073 7.69332 8.89355 7.64064 8.79978 7.54688C8.70601 7.45311 8.65333 7.32593 8.65333 7.19332C8.65333 7.06072 8.70601 6.93354 8.79978 6.83977C8.89355 6.746 9.02073 6.69332 9.15333 6.69332H13.26C13.3926 6.69332 13.5198 6.746 13.6136 6.83977C13.7073 6.93354 13.76 7.06072 13.76 7.19332C13.76 7.32593 13.7073 7.45311 13.6136 7.54688C13.5198 7.64064 13.3926 7.69332 13.26 7.69332H13.24Z\" />\n</svg>\n";
export default src;
