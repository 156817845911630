/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'

export default function IntegrationFillView ({ value, details }) {
  return (
    <MinHeightDiv>
      {details?.headless ? null : get(value, 'label')}
    </MinHeightDiv>
  )
}

const MinHeightDiv = styled.div`
  min-height: 19px;
`
