/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import AbbreviationIcon from '../../../components/abbreviation-icon'
import * as Icons from '../../../icons'

function SpaceIcon ({ name }) {
  if (name === 'Home') {
    return <Icons.Home width={32} height={32} />
  }
  return <AbbreviationIcon name={name} className='!w-8 shrink-0 !font-bold' />
}

export default React.memo(SpaceIcon)
