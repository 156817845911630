/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../../icons'
import Button from '../../../../ui/button'
import ZoomableImage from './zoomable-image'

export default function DisplayImage ({
  fieldLabel,
  imagePreview,
  imagePreviewZoomed,
  onDelete,
  value
}) {
  const thumbnail = value?.thumbnail
  if (thumbnail?.temporaryUrl || imagePreview) {
    return (
      <Wrapper>
        <ZoomableImage
          small={
            thumbnail?.permaLink
              ? thumbnail.permaLink
              : thumbnail?.temporaryUrl || imagePreview
          }
          large={
            value.medium?.permaLink
              ? value.medium?.permaLink
              : value.medium?.temporaryUrl || imagePreviewZoomed
          }
          largeHeight={value.medium?.height}
          largeWidth={value.medium?.width}
          filename={value?.filename}
        />
        {onDelete && (
          <Button
            title={`Remove image for field ${fieldLabel}`}
            transparent
            small
            ml={3}
            onClick={onDelete}
          >
            <Icons.Delete />
          </Button>
        )}
      </Wrapper>
    )
  }
  return null
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  padding: 8px;
`
