/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../../components/spinner'
import Button from '../../../../ui/button'
import Input from '../../../../ui/input'
import { Flex } from '../../../../ui/layout'
import { Option, Select } from '../../../../ui/select'
import { Body1 } from '../../../../ui/typography'

export default function NewBlueprintPopup ({
  label,
  onCancel,
  onSubmit,
  submitting,
  blueprints
}) {
  const [name, setName] = React.useState('')
  const [parentId, setParentId] = React.useState(null)
  const invalid = !name
  return (
    <div>
      <Wrapper data-testid={`popover-${label}`}>
        <Body1 htmlFor='new-blueprint-name' as='label' mb={1}>
          <Trans id='new.blueprint.name' />
        </Body1>
        <Input
          disabled={submitting}
          id='new-blueprint-name'
          width='100%'
          mb={3}
          onChange={setName}
        />
        <Body1 htmlFor='choose-parent' as='label' mb={1}>
          <Trans id='choose.parent.blueprint' />
        </Body1>
        <Select
          disabled={submitting}
          id='choose-parent'
          mb={3}
          onChange={setParentId}
        >
          <Option value={null}>No Parent</Option>
          {blueprints?.map(blueprint => (
            <Option
              key={blueprint.id + '' + blueprint.name}
              value={blueprint.id}
            >
              {blueprint.name}
            </Option>
          ))}
        </Select>

        <Flex>
          <Button
            disabled={submitting}
            outline
            width='100%'
            mr={2}
            onClick={onCancel}
          >
            <Trans id='cancel' />
          </Button>
          <Button
            width='100%'
            disabled={invalid || submitting}
            onClick={e => {
              e.preventDefault()
              onSubmit({ name, parentId })
            }}
          >
            {submitting ? <Spinner size={16} /> : <Trans id='continue' />}
          </Button>
        </Flex>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  padding: 16px;
  width: 276px;

  ${Spinner} {
    margin: 24px;
  }
`
