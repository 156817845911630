/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { cloneDeep, find, findIndex, get, isEqual, set } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import { CalcFooter } from '../../../pages-builder/calculations/calculation-components'
import calculate from '../../../pages-builder/calculations/calculation-functions'
import Button from '../../../ui/button'
import { Wrapper } from '../../../ui/layout'
import { useFormbot } from '../../engine/formbot-react/hooks'
import * as TableColumn from '../table-column/edit'
import { ScrollParentProvider } from './scroll-parent'
import {
  EditCellWrapper,
  InnerTh,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from './ui'
import * as utils from './utils'

export default function TableEdit (props) {
  const value = useSetDefault(props)
  if (!value) return null
  const updatedValue = cloneDeep(value)
  const handleChange = (i, formKey, newValue) => {
    set(updatedValue, `${i}.${formKey}`, newValue)
    props.onChange(updatedValue)
  }
  const Component = props.context.configMode ? ConfigModeTable : EditModeTable
  return (
    <>
      <Component {...props} handleChange={handleChange} />
      {props.details.allowAdditionalRows?.enabled && <AddButton {...props} />}
    </>
  )
}

function useSetDefault ({ details, childrenTemplate, onChange, value }) {
  const formbot = useFormbot()
  React.useEffect(() => {
    if (value) return
    const count = details.defaultRowCount || 2
    const defaultValue = [...new Array(count)].map(() =>
      utils.newRow(childrenTemplate, formbot)
    )
    onChange([utils.newRow(childrenTemplate, formbot, true), ...defaultValue])
  }, [formbot, childrenTemplate, value, details, onChange])
  return value
}

function AddButton ({ childrenTemplate, details, onChange, value }) {
  const formbot = useFormbot()
  const maxRows = details.allowAdditionalRows?.maxRows
  const disallowAdd =
    maxRows && value.filter(row => !row._isFooter).length >= maxRows
  return (
    <Wrapper pt={3}>
      <Button
        outline
        disabled={disallowAdd}
        onClick={() => {
          if (disallowAdd) return
          onChange([...value, utils.newRow(childrenTemplate, formbot)])
        }}
      >
        <Icons.Add
          mr={2}
          className={cx(
            disallowAdd && 'fill-light-gray-300 dark:fill-medium-gray-100'
          )}
        />
        <Trans id='add.another.row' />
      </Button>
    </Wrapper>
  )
}

function ConfigModeTable (props) {
  const { details, children, value, handleChange } = props
  const showDelete = details.allowAdditionalRows?.enabled

  const showFooter = details.calculationFooter?.enabled
  const footerCalcFunctions = details.calculationFooter?.columns

  return (
    <ConfigModeWrapper
      count={props.childrenTemplate.length}
      showDelete={showDelete}
    >
      {children(value, handleChange, showFooter, footerCalcFunctions)}
      {showDelete && (
        <ConfigDeleteWrapper>
          <TableColumn.Inner value={value}>
            {(row, i) => (
              <DeleteTableCell
                key={row._rowId}
                as='div'
                style={{
                  height: `${
                    100 / (showFooter ? value.length : value.length - 1)
                  }%`,
                  width: '100%'
                }}
              >
                <DeleteButton i={i} onChange={props.onChange} value={value} />
              </DeleteTableCell>
            )}
          </TableColumn.Inner>
        </ConfigDeleteWrapper>
      )}
    </ConfigModeWrapper>
  )
}

function EditModeTable (props) {
  const [ref, setRef] = React.useState()
  const formbot = useFormbot()
  const { formKey, details, fbRenderEdit, gridded, id, value, context } = props
  const showDelete = details.allowAdditionalRows?.enabled
  const template = props.childrenTemplate.filter(g =>
    props.progDisc(utils.removeRowBasedProgDisc(g))
  )
  const validations = template.flatMap(gadget => {
    const singleValidation = get(context, ['validations', gadget.formKey], [])
    if (!singleValidation.length) return []
    return gadget.formKey
  })

  const calcFooter = props.details?.calculationFooter

  const calcValuesMap =
    calcFooter?.columns &&
    Object.keys(calcFooter?.columns).reduce((acc, columnId) => {
      const columnWithFormKey = props.children.find(
        child => child.id === columnId
      )
      const formKey = determineFormKey(columnWithFormKey, props.children)
      const columnValues = value.map(e => {
        if (e._isFooter) return null
        return get(e, formKey)
      })
      return {
        ...acc,
        [columnId]: {
          ...columnWithFormKey,
          values: columnValues,
          calcFunction: calcFooter?.columns[columnId]
        }
      }
    }, [])
  return (
    <ScrollParentProvider parent={ref}>
      <EditWrapper ref={setRef}>
        <Table
          aria-labelledby={props['aria-labelledby']}
          {...(props['aria-describedby'] && {
            'aria-describedby': props['aria-describedby']
          })}
          gridded={gridded}
          id={id}
        >
          <Thead>
            <Tr>
              {template.map(gadget => {
                if (gadget.details?.headless) {
                  return <SneakyCell key={gadget.formKey} />
                }
                return (
                  <WideTh id={`${gadget.id}-label`} key={gadget.formKey}>
                    <InnerTh>
                      <span
                        className={cx({
                          'required-field': gadget.required,
                          'invalid-field': validations.includes(gadget.formKey)
                        })}
                      >
                        {gadget.label}
                      </span>
                      <utils.Description
                        description={gadget.description}
                        id={gadget.id}
                        labelSize={props.context?.labelSize}
                      />
                    </InnerTh>
                  </WideTh>
                )
              })}
              {showDelete && <Th />}
            </Tr>
          </Thead>
          <Tbody>
            {value.map((row, i) => {
              if (row._isFooter) return null

              return createEditRow(
                row,
                i,
                template,
                fbRenderEdit,
                formbot,
                formKey,
                props,
                value,
                showDelete,
                details
              )
            })}
          </Tbody>
          {calcFooter?.enabled && (
            <Tfoot>
              {createEditRow(
                find(value, { _isFooter: true }),
                findIndex(value, { _isFooter: true }),
                template,
                fbRenderEdit,
                formbot,
                formKey,
                props,
                value,
                showDelete,
                details,
                true,
                calcValuesMap
              )}
            </Tfoot>
          )}
        </Table>
      </EditWrapper>
    </ScrollParentProvider>
  )
}

const SneakyCell = styled.div``
function RegularCell ({ children }) {
  return (
    <EditCellWrapper style={{ marginRight: '-1px' }}>
      {children}
    </EditCellWrapper>
  )
}

const determineFormKey = (columnWithFormKey, allColumns) => {
  const formKey = columnWithFormKey?.formKey
  if (!formKey && columnWithFormKey?.details?.parentId) {
    const parentId = columnWithFormKey.details.parentId.replace('data.', '')
    const parent = allColumns.find(child => child.id === parentId)
    return `${parent.formKey}.${columnWithFormKey.details.selectedOutputField?.path}`
  }
  return formKey
}

const createEditRow = (
  row,
  i,
  template,
  fbRenderEdit,
  formbot,
  formKey,
  props,
  value,
  showDelete,
  details,
  isFooter,
  calcValuesMap
) => {
  const gadgets = utils.getCellGadgets(template, formKey, i, formbot)
  return (
    <Tr key={row._rowId}>
      {template.map(gadget => {
        const g = cloneDeep(gadget)
        g.formKey = `${formKey}.${i}.${gadget.formKey}`
        const shouldShow = props.progDisc(
          utils.finalizeRowBasedProgDisc(gadget, i),

          // process every gadget in the row in case there is chaining
          gadgets.map(ga => utils.finalizeRowBasedProgDisc(ga, i))
        )
        const ThisWrapper = gadget.details?.headless ? SneakyCell : RegularCell
        const gadgetFormKey = determineFormKey(gadget, template)
        if (isFooter && !gadget?.details?.headless) {
          return (
            <FooterTd key={gadgetFormKey}>
              <CalcCell
                gadgetFormKey={gadgetFormKey}
                onChange={props.handleChange}
                cellValue={value[0]?.[gadgetFormKey]}
                gadget={calcValuesMap?.[gadget?.id]}
              />
            </FooterTd>
          )
        }

        return (
          <Td
            className={cx(
              gadget.details?.calculation?.enabled && 'border-l-light-gray-300',
              'dark:border-medium-gray-400'
            )}
            key={gadget.formKey}
          >
            <ThisWrapper>
              {shouldShow ? (
                fbRenderEdit(
                  gadget.type,
                  row[gadget.formKey],
                  {
                    ...gadget.details,
                    value,
                    rowId: row._rowId,
                    rowIndex: i,
                    parentFormKey: formKey,
                    parentDetails: details,
                    parentValue: value,
                    validations: gadget.validations
                  },
                  props.handleChange.bind(null, i, gadget.formKey),
                  `${i}.${gadget.formKey}`,
                  `${formKey}.${i}.${gadget.formKey}`
                )
              ) : (
                <utils.HiddenGadget />
              )}
            </ThisWrapper>
          </Td>
        )
      })}
      {showDelete &&
        (isFooter ? (
          <Th />
        ) : (
          <DeleteTableCell>
            <DeleteButton i={i} onChange={props.onChange} value={value} />
          </DeleteTableCell>
        ))}
    </Tr>
  )
}

const CalcCell = ({ gadgetFormKey, onChange, gadget, cellValue }) => {
  const calculated = calculate?.[gadget?.calcFunction]?.(gadget.values)

  React.useEffect(() => {
    if (isEqual(calculated, cellValue)) return
    if (calculated || calculated === 0 || calculated === null) {
      onChange(0, gadgetFormKey, calculated)
    }
  }, [calculated, cellValue, gadget?.formKey])

  const gadgetType = gadget?.details?.selectedOutputField?.type || gadget?.type
  return (
    <CalcFooter
      type={gadgetType}
      total={calculated}
      calcFunction={gadget?.calcFunction}
      mode='edit'
    />
  )
}

function DeleteButton ({ i, onChange, value }) {
  const disallowDelete = value.length === 2
  return (
    <DeleteButtonWrapper className='border-b border-r border-light-gray-300'>
      <Button
        transparent
        icon
        disabled={disallowDelete}
        title={disallowDelete ? <Trans id='cannot.remove.last.row' /> : null}
        onClick={() => {
          if (disallowDelete) return
          const updatedValue = cloneDeep(value)
          updatedValue.splice(i, 1)
          onChange(updatedValue)
        }}
        aria-label={i18n._('delete.row')}
      >
        <Icons.Delete
          className={cx(
            disallowDelete && 'fill-light-gray-400 dark:fill-medium-gray-100'
          )}
        />
      </Button>
    </DeleteButtonWrapper>
  )
}

const ConfigModeWrapper = styled.div`
  display: flex;
  width: 1px;
  min-width: 100%;
  > * {
    flex: 1;
    width: ${p =>
      p.showDelete
        ? `calc(${Math.floor(100 / p.count)}% - ${Math.ceil(36 / p.count)}px)`
        : `${Math.floor(100 / p.count)}%`};
  }
  > :first-child {
    border-left: 1px solid #e7e7e7;
    html.dark & {
      // Outlier: dark:bg-light-gray-300
      border-left: 1px solid #333;
    }
  }
`

const WideTh = styled(Th)`
  min-width: 150px;
`

const DeleteTableCell = styled(Td)`
  width: 1px;
`

const DeleteButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const ConfigDeleteWrapper = styled.div`
  width: initial;
  flex: initial;
`

const EditWrapper = styled.div`
  overflow: auto;
  width: 1px;
  min-width: 100%;
`

export const FooterTd = styled.td`
  background-color: #eee;
  html.dark & {
    // Outlier: dark:bg-medium-gray-200 dark:border-light-gray-300
    border-color: #333;
    background-color: #777;
  }
`
