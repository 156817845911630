/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useSubscription } from '@apollo/client'
import { Trans } from '@lingui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Button from '../../ui/button'
import { EmptyLI, UL } from '../../ui/ul'
import PopoverButton from './popover-button'

const EXPORT_CSV_SUBSCRIPTION = gql`
  subscription ExportCSV($exportId: ID!) {
    csvExported(exportId: $exportId) {
      event
    }
  }
`
const CSV_ERROR_MESSAGE =
  'Failed to export: remove sort order then try exporting again.'

export default function ExportButton ({ appName, csvRoute, params = {} }) {
  const alerts = useAlerts()
  const exportId = React.useMemo(() => shortid(), [])
  const { data } = useSubscription(EXPORT_CSV_SUBSCRIPTION, {
    variables: { exportId }
  })
  React.useEffect(() => {
    const event = data?.csvExported.event
    if (event === 'ERROR') alerts.type2(CSV_ERROR_MESSAGE, 'error')
  }, [data, alerts])

  if (!params?.versionConfig) {
    params.versionConfig = 'LATEST_VERSION'
  }

  return (
    <PopoverButton
      label={
        <>
          <Icons.Download className='fill-blue-500' mr={2} />
          <Trans id='export' />
        </>
      }
      buttonProps={{ small: true, transparent: true }}
    >
      {hide => (
        <UL>
          <EmptyLI>
            <MenuButton
              transparent
              forwardedAs='a'
              target='_blank'
              rel='noopener noreferrer'
              download={`${appName}-documents.csv`}
              href={`${csvRoute}?${getQueryString({ ...params, exportId })}`}
              onClick={hide}
            >
              <Icons.ExportView mr={2} />
              <Trans id='current.view' />
            </MenuButton>
          </EmptyLI>
          <EmptyLI>
            <MenuButton
              transparent
              forwardedAs='a'
              target='_blank'
              rel='noopener noreferrer'
              download={`${appName}-documents.csv`}
              href={`${csvRoute}?${getQueryString({ exportId })}`}
              onClick={hide}
            >
              <Icons.ExportAll mr={2} />
              <Trans id='all.data' />
            </MenuButton>
          </EmptyLI>
        </UL>
      )}
    </PopoverButton>
  )
}

function getQueryString ({
  columns,
  fields,
  query,
  sort,
  exportId,
  versionConfig
}) {
  const queryParams = new URLSearchParams()
  if (!isEmpty(columns)) {
    queryParams.append('columns', JSON.stringify(columns))
  }
  if (!isEmpty(fields)) {
    queryParams.append('fields', JSON.stringify(fields))
  }
  if (!isEmpty(query)) {
    queryParams.append('query', query)
  }
  if (!isEmpty(sort)) {
    queryParams.append('sort', JSON.stringify(sort))
  }
  if (exportId) {
    queryParams.append('exportId', exportId)
  }
  if (versionConfig) {
    queryParams.append('versionConfig', versionConfig)
  }
  return queryParams.toString()
}

const MenuButton = styled(Button)`
  border-radius: 0;
  justify-content: flex-start;
  width: 100%;
  color: #111;
  &:hover {
    background-color: #eee;
  }
  html.dark & {
    // Outlier: dark:text-black dark:hover:bg-light-gray-100
    color: #fff;
    &:hover {
      background-color: #111;
    }
  }
`
