/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import AppsOrProducts from '../../components/apps-or-products'
import Spinner from '../../components/spinner'
import { GraphQLError } from '../../components/system-error'

export default function General () {
  const { data, error } = useQuery(q.query, q)

  if (error) return <GraphQLError fillHeight />
  if (!data) {
    return (
      <LoadingPane>
        <Spinner size={200} />
        <div className='max-w-md pt-10 text-center text-dark-gray-200'>
          <Trans id='pages.usage.hang.tight' />
        </div>
      </LoadingPane>
    )
  }

  return (
    <Pane>
      <CardHolder>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans
              id='pages.usage.total.apps.or.products'
              values={{ appsOrProducts: <AppsOrProducts /> }}
            />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.totalAppCount}{' '}
          </h2>
        </Card>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans id='pages.usage.total.submissions' />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.totalDocumentCount.toLocaleString()}
          </h2>
        </Card>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans id='pages.usage.total.spaces' />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.totalSpaceCount.toLocaleString()}
          </h2>
        </Card>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans id='pages.usage.total.integrations' />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.totalIntegrationCount.toLocaleString()}
          </h2>
        </Card>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans id='pages.usage.total.users' />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.usersConnection.totalCount.toLocaleString()}
          </h2>
        </Card>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans id='pages.usage.total.groups' />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.groupsConnection.totalCount.toLocaleString()}
          </h2>
        </Card>
        <Card className='bg-white dark:bg-light-gray-300'>
          <h1 className='text-dark-gray-500 dark:text-black'>
            <Trans id='pages.usage.total.blueprints' />
          </h1>
          <h2 className='text-dark-gray-200 dark:text-black'>
            {data.categoriesConnection.totalCount.toLocaleString()}
          </h2>
        </Card>
      </CardHolder>
    </Pane>
  )
}

const LoadingPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`

const q = {
  fetchPolicy: 'cache-and-network',
  query: gql`
    query Details {
      totalDocumentCount
      totalAppCount
      totalSpaceCount
      totalIntegrationCount
      usersConnection(args: { limit: 1 }) {
        totalCount
      }
      groupsConnection(args: { limit: 1 }) {
        totalCount
      }
      categoriesConnection(args: { limit: 1 }) {
        totalCount
      }
    }
  `
}

const Pane = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 64px 40px;
`

const CardHolder = styled.div`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  min-width: 320px;
  height: 140px;
  border-radius: 0.5rem;
  padding: 16px 24px;
  line-height: initial;
  flex: 1;

  & h2 {
    font-size: 40px;
    font-weight: 500;
  }
`
