/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { cloneDeep, findIndex, pick } from 'lodash'

import { useQueryContext } from './use-query-context'

export const useModifyPolicyMutation = (roleId, policyId) => {
  const [modifyPolicy] = useMutation(mutation)
  const query = useQueryContext()
  return policy => modifyPolicy(getParams(roleId, policyId, policy, query))
}

const mutation = gql`
  mutation ModifyPolicy(
    $roleId: String!
    $policyId: String!
    $policy: PolicyInput!
  ) {
    editPolicy(args: { roleId: $roleId, policyId: $policyId, policy: $policy })
  }
`

const getParams = (roleId, policyId, policy, query) => ({
  variables: { roleId, policyId, policy: clean(policy) },
  optimisticResponse: {
    editPolicy: 'ok'
  },
  refetchQueries: [query],
  update: (store, { data: { editPolicy } }) => {
    if (editPolicy !== 'ok') return
    const rawData = store.readQuery({ ...query })
    const data = cloneDeep(rawData)
    const policyGroupIndex = findIndex(data.app.listPolicyGroups, {
      id: roleId
    })
    if (policyGroupIndex === -1) return
    const policyIndex = findIndex(
      data.app.listPolicyGroups[policyGroupIndex].policies,
      { id: policyId }
    )
    if (policyIndex === -1) return
    data.app.listPolicyGroups[policyGroupIndex].policies[policyIndex] = {
      ...data.app.listPolicyGroups[policyGroupIndex].policies[policyIndex],
      ...policy
    }
    store.writeQuery({ ...query, data })
  }
})

function clean (policy) {
  const policyInput = pick(policy, ['statements', 'version'])
  policyInput.statements = policyInput.statements.map(statement =>
    pick(statement, ['action', 'effect', 'resource'])
  )
  return policyInput
}
