/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useAddNewVersionMutation = pathname => {
  const [addNewVersion, { loading }] = useMutation(mutation)
  const onDocumentList = pathname.endsWith('/action')
  return [
    (documentSetId, timezone) =>
      addNewVersion(getParams(documentSetId, timezone, onDocumentList)),
    { loading }
  ]
}

const mutation = gql`
  mutation NewVersion($documentSetId: ID!, $timezone: String!) {
    addNewVersion(documentSetId: $documentSetId, timezone: $timezone) {
      __typename
      ... on Redirect {
        url
        newActionId
      }

      ... on Error {
        reason
      }
    }
  }
`

const getParams = (documentSetId, timezone, onDocumentList) => ({
  variables: { documentSetId, timezone },
  refetchQueries: onDocumentList ? ['ListPageQuery'] : []
})
