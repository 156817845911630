/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { ApolloProvider, gql } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useQuery } from '../components/use-query'
import apolloClient from './apollo-client'

export default props => (
  <ApolloProvider client={apolloClient}>
    <Anonymous {...props} />
  </ApolloProvider>
)

function Anonymous () {
  const { publishUrlPath } = useParams()
  const resp = useQuery(getQuery(publishUrlPath))
  const appId = get(resp, 'data.app.id')
  if (appId) return <Navigate to={`/app/${appId}/start`} replace />
  return null
}

const getQuery = appPath => ({
  variables: { appPath },
  query: gql`
    query AnonymousPageRedirectQuery($appPath: String!) {
      app: appRedirect(args: { url: $appPath }) {
        id
      }
    }
  `
})
