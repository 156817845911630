/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'

import { Flex } from './layout'

const Wrapper = styled.input`
  appearance: none;
  width: 28px;
  height: 16px;
  background: #666;
  border-radius: 16px;
  position: relative;
  transition: all 150ms;
  cursor: pointer;
  flex-shrink: 0;
  &::after {
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background: white;
    margin: 2px;
    position: absolute;
    transition: all 150ms;
    left: 0px;
    top: 0;
  }
  &:checked::after {
    left: calc(100% - 16px);
  }
  &:checked {
    background: #3369a3;
  }
  &:disabled {
    background: #888888;
    cursor: not-allowed;
  }
`

const Label = styled.span`
  ${p => p.disabled && 'color: #888888;'}
`

export default React.forwardRef(
  ({ disabled, value, onChange, off, on, className, ...rest }, ref) => (
    <Flex
      as='label'
      disabled={disabled}
      className={cx('text-sm', disabled ? 'cursor-auto' : 'cursor-pointer')}
    >
      {off && <Label disabled={disabled}>{off}</Label>}
      <Wrapper
        aria-checked={value}
        className={cx(className, 'mx-1')}
        type='checkbox'
        disabled={disabled}
        ref={ref}
        checked={value}
        onChange={e => onChange(e.target.checked)}
        {...rest}
      />
      {on && <Label disabled={disabled}>{on}</Label>}
    </Flex>
  )
)
