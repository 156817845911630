/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  difference,
  filter,
  find,
  keyBy,
  map,
  upperFirst,
  without,
  xor
} from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Checkbox from '../../ui/checkbox'
import ConfigBox from '../../ui/config-box'
import { Space, Wrapper } from '../../ui/layout'
import { Body1 } from '../../ui/typography'
import EmailContent from './email-content'
import * as Errors from './errors'

export const Config = ({
  appAllowExport,
  value,
  updateValue,
  errors,
  fieldsAll,
  toggle,
  defaultSubject: _defaultSubject
}) => {
  const [enabled, setEnabled] = React.useState(
    toggle ? value?.enabled || false : true
  )
  const [defaultSubject, setDefaultSubject] = React.useState(_defaultSubject)
  const subjectErrors = Errors.filter(errors, 'email-builder-subject')
  const selectedExportOptions = value?.exportOptions || []
  const bodyErrors = Errors.filter(errors, 'email-builder-body')

  const onExportChange = (ids, checked) => {
    let newValue = xor(selectedExportOptions, ids)
    if (newValue.includes('comments') && !newValue.includes('history')) {
      if (checked) newValue.push('history')
      else newValue = without(newValue, 'comments')
    }
    if (newValue.includes('history') && !newValue.includes('document')) {
      if (checked) newValue.push('document')
      else newValue = without(newValue, 'history', 'comments')
    }
    updateValue(draft => {
      draft.exportOptions = newValue
    })
  }

  const MainElement = (
    <>
      <EmailContent
        allFormFields={keyBy(fieldsAll, 'formKey')}
        value={
          value.subject
            ? `<p>${value.subject}</p>`
            : defaultSubject
              ? `<p>${defaultSubject}</p>`
              : undefined
        }
        onChange={value => {
          setDefaultSubject(undefined)
          return updateValue(draft => {
            draft.subject = value
          })
        }}
        type='subject'
        embedded={toggle}
      />
      <Errors.Config id='email-subject-errors' errors={subjectErrors} />
      <Body1 py={2} required>
        <Trans id='email.body' />
      </Body1>
      <EmailContent
        allFormFields={keyBy(fieldsAll, 'formKey')}
        value={value.body}
        onChange={value =>
          updateValue(draft => {
            draft.body = value
          })
        }
      />
      <Errors.Config errors={bodyErrors} />
      {appAllowExport && (
        <>
          <Checkbox
            label='Attach PDF of the completed document'
            onChange={checked => onExportChange(['document'], checked)}
            checked={selectedExportOptions.includes('document')}
          />
          <Wrapper pl={3}>
            <Checkbox
              label='Include workflow status in PDF'
              onChange={checked => {
                onExportChange(['history', 'comments'], checked)
              }}
              checked={selectedExportOptions.includes('history')}
            />
          </Wrapper>
        </>
      )}
    </>
  )

  return toggle ? (
    <ConfigBox
      configKey='customNotification'
      description={
        <div>
          <Trans id='setting.enables.custom.message.in.notification' />
        </div>
      }
      enabled={enabled}
      label={i18n._('customize.email.notification')}
      save={() => () => {
        const visible = !enabled
        setEnabled(visible)
        updateValue(draft => {
          draft.enabled = visible
        })
      }}
    >
      {MainElement}
      <Space h='40px' />
    </ConfigBox>
  ) : (
    <Wrapper px={3}>{MainElement}</Wrapper>
  )
}

export const View = ({ details, errors }) => {
  errors = Errors.filter(errors, [
    'email-builder-subject',
    'email-builder-body'
  ])
  if (errors.length) return <Errors.View errors={errors} />
  if (!details) return null
  return <ViewWrapper>Subject: {details.subject || '(blank)'}</ViewWrapper>
}

const ViewWrapper = styled(Wrapper)`
  font-size: 13px;
  padding: 8px 12px;
  padding-top: 0;
`

export const defaults = () => ({
  subject: '',
  body: ''
})

const re = /{{([^}]*)}}/g
const validateField = fieldName =>
  Errors.wrap((value, fieldsAll, lineage) => {
    if (!value[fieldName]) return [`${upperFirst(fieldName)} is required`]
    const rawKeys = value[fieldName].match(re) || []
    const keys = map(rawKeys, key => key.substr(2, key.length - 4))
    const missingKeys = filter(
      keys,
      formKey =>
        !find(fieldsAll, { formKey }) || !isFormKeyInLineage(formKey, lineage)
    )
    return missingKeys.length ? ['Email variables are missing'] : []
  }, `email-builder-${fieldName}`)

const isFormKeyInLineage = (formKey, lineage) => {
  const echoStepIds = formKey.match(/(?<=echo\.)[^.]+/g)
  return difference(echoStepIds, lineage).length === 0
}

export const validate = (value, fieldsAll, lineage) => [
  ...validateField('body')(value, fieldsAll, lineage),
  ...validateField('subject')(value, fieldsAll, lineage)
]
