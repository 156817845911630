/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import { Flex } from '../../../ui/layout'

export default function ({ total, page, onChange, className }) {
  const pageStart = (page - 1) * 30 + 1
  const pageEnd = page * 30 > total ? total : page * 30
  return (
    <Flex className={className}>
      <Pagination aria-live='polite'>
        {getPagination(total, pageStart, pageEnd)}
      </Pagination>
      <Button
        outline
        disabled={pageStart === 1}
        className='dark:border-medium-gray-100 dark:disabled:border-dark-gray-100 dark:disabled:text-medium-gray-300 dark:disabled:hover:bg-transparent'
        onClick={() => onChange(page - 1)}
        mr={2}
      >
        <Trans id='pages.old.pager.prev' />
      </Button>
      <Button
        outline
        className='dark:border-medium-gray-100 dark:disabled:border-dark-gray-100 dark:disabled:text-medium-gray-300 dark:disabled:hover:bg-transparent'
        disabled={pageEnd === total}
        onClick={() => onChange(page + 1)}
      >
        <Trans id='pages.old.pager.next' />
      </Button>
    </Flex>
  )
}

const Pagination = styled.div`
  margin-right: 10px;
  white-space: nowrap;
`

function getPagination (total, pageStart, pageEnd) {
  if (total === 0) return `${i18n._('pages.old.pager.no.results')}`
  if (total === 1) return `${i18n._('pages.old.pager.one.result')}`
  return (
    <Trans
      id='pages.old.pager.total'
      values={{ start: pageStart, end: pageEnd, total }}
    />
  )
}
