/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export const FormbotContext = React.createContext(null)
export const FormbotDataContext = React.createContext(null)
export const IdFormkeyMapContext = React.createContext(null)

export function useFormbot () {
  return React.useContext(FormbotContext)
}

export function useFormbotData () {
  return React.useContext(FormbotDataContext)
}

export function useIdFormkeyMap () {
  return React.useContext(IdFormkeyMapContext)
}
