/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.4743 6.72001L9.26857 0.514291C8.92647 0.188681 8.47228 0.00708008 8 0.00708008C7.52772 0.00708008 7.07352 0.188681 6.73142 0.514291L0.52571 6.72001C0.356676 6.88717 0.223018 7.08664 0.1327 7.30655C0.0423816 7.52646 -0.00274928 7.76229 -3.93827e-06 8.00001C-0.000978327 8.23407 0.0450511 8.46595 0.135356 8.68189C0.22566 8.89784 0.358401 9.09346 0.52571 9.25715L6.73142 15.4629C7.0679 15.7992 7.52421 15.9882 8 15.9882C8.47578 15.9882 8.93209 15.7992 9.26857 15.4629L15.4743 9.25715C15.6416 9.09346 15.7743 8.89784 15.8646 8.68189C15.9549 8.46595 16.001 8.23407 16 8.00001C16.0027 7.76229 15.9576 7.52646 15.8673 7.30655C15.777 7.08664 15.6433 6.88717 15.4743 6.72001ZM7.14285 3.98858C7.14285 3.76125 7.23316 3.54323 7.3939 3.38249C7.55465 3.22174 7.77267 3.13143 8 3.13143C8.22733 3.13143 8.44534 3.22174 8.60609 3.38249C8.76683 3.54323 8.85714 3.76125 8.85714 3.98858V7.41715C8.85714 7.64448 8.76683 7.86249 8.60609 8.02324C8.44534 8.18399 8.22733 8.27429 8 8.27429C7.77267 8.27429 7.55465 8.18399 7.3939 8.02324C7.23316 7.86249 7.14285 7.64448 7.14285 7.41715V3.98858ZM8 11.9886C7.77396 11.9886 7.553 11.9215 7.36506 11.796C7.17712 11.6704 7.03063 11.4919 6.94413 11.2831C6.85763 11.0742 6.835 10.8445 6.8791 10.6228C6.9232 10.4011 7.03204 10.1974 7.19187 10.0376C7.35171 9.87777 7.55534 9.76892 7.77704 9.72482C7.99873 9.68073 8.22852 9.70336 8.43735 9.78986C8.64618 9.87636 8.82467 10.0228 8.95025 10.2108C9.07583 10.3987 9.14285 10.6197 9.14285 10.8457C9.14285 11.1488 9.02245 11.4395 8.80812 11.6538C8.59379 11.8682 8.3031 11.9886 8 11.9886Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.4743 6.72001L9.26857 0.514291C8.92647 0.188681 8.47228 0.00708008 8 0.00708008C7.52772 0.00708008 7.07352 0.188681 6.73142 0.514291L0.52571 6.72001C0.356676 6.88717 0.223018 7.08664 0.1327 7.30655C0.0423816 7.52646 -0.00274928 7.76229 -3.93827e-06 8.00001C-0.000978327 8.23407 0.0450511 8.46595 0.135356 8.68189C0.22566 8.89784 0.358401 9.09346 0.52571 9.25715L6.73142 15.4629C7.0679 15.7992 7.52421 15.9882 8 15.9882C8.47578 15.9882 8.93209 15.7992 9.26857 15.4629L15.4743 9.25715C15.6416 9.09346 15.7743 8.89784 15.8646 8.68189C15.9549 8.46595 16.001 8.23407 16 8.00001C16.0027 7.76229 15.9576 7.52646 15.8673 7.30655C15.777 7.08664 15.6433 6.88717 15.4743 6.72001ZM7.14285 3.98858C7.14285 3.76125 7.23316 3.54323 7.3939 3.38249C7.55465 3.22174 7.77267 3.13143 8 3.13143C8.22733 3.13143 8.44534 3.22174 8.60609 3.38249C8.76683 3.54323 8.85714 3.76125 8.85714 3.98858V7.41715C8.85714 7.64448 8.76683 7.86249 8.60609 8.02324C8.44534 8.18399 8.22733 8.27429 8 8.27429C7.77267 8.27429 7.55465 8.18399 7.3939 8.02324C7.23316 7.86249 7.14285 7.64448 7.14285 7.41715V3.98858ZM8 11.9886C7.77396 11.9886 7.553 11.9215 7.36506 11.796C7.17712 11.6704 7.03063 11.4919 6.94413 11.2831C6.85763 11.0742 6.835 10.8445 6.8791 10.6228C6.9232 10.4011 7.03204 10.1974 7.19187 10.0376C7.35171 9.87777 7.55534 9.76892 7.77704 9.72482C7.99873 9.68073 8.22852 9.70336 8.43735 9.78986C8.64618 9.87636 8.82467 10.0228 8.95025 10.2108C9.07583 10.3987 9.14285 10.6197 9.14285 10.8457C9.14285 11.1488 9.02245 11.4395 8.80812 11.6538C8.59379 11.8682 8.3031 11.9886 8 11.9886Z\" />\n</svg>\n";
export default src;
