/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { formatNumber } from '../../utils'

export default function Chart ({ value }) {
  return (
    <div className='text-4xl text-medium-gray-500'>
      {formatNumber(value.total)}
    </div>
  )
}
