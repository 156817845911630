/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import { TextView } from '../../gadget-helpers'

export default function LinkView ({ value, gridded, condensed, details }) {
  const linkText =
    details?.customLinkText?.enabled && (details?.customLinkText?.value ?? '')
  return (
    <TextView {...{ condensed, gridded }}>
      {!!value && (
        <Link
          href={value}
          target='_blank'
          rel='noopener noreferrer'
          onClick={e => e.stopPropagation()}
        >
          {linkText || <Trans id='click.to.visit' message='Click to visit' />}
        </Link>
      )}
    </TextView>
  )
}

const Link = styled.a`
  color: #0066cc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
