/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.298 12.8133C14.8087 11.8333 13.1373 11.2193 10.8247 10.3687C10.3847 10.208 10.1073 10.1413 9.98333 9.78334C9.91314 9.61167 9.88586 9.42547 9.90387 9.24088C9.92188 9.05629 9.98462 8.87887 10.0867 8.724C11.0033 7.73334 11.4533 6.24934 11.4533 4.33334C11.4533 1.80001 9.72 0.666672 8 0.666672C6.28 0.666672 4.54667 1.80001 4.54667 4.33334C4.54667 6.24934 4.99667 7.73334 5.91333 8.724C6.01538 8.87887 6.07813 9.05629 6.09613 9.24088C6.11414 9.42547 6.08686 9.61167 6.01667 9.78334C5.89267 10.1413 5.61667 10.208 5.17533 10.3687C2.86267 11.2193 1.19133 11.8353 0.702 12.8133C0.270093 13.7046 0.0308928 14.6768 0 15.6667C0 15.7551 0.035119 15.8399 0.0976311 15.9024C0.160143 15.9649 0.244928 16 0.333333 16H15.6667C15.7551 16 15.8399 15.9649 15.9024 15.9024C15.9649 15.8399 16 15.7551 16 15.6667C15.9691 14.6768 15.7299 13.7046 15.298 12.8133Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.298 12.8133C14.8087 11.8333 13.1373 11.2193 10.8247 10.3687C10.3847 10.208 10.1073 10.1413 9.98333 9.78334C9.91314 9.61167 9.88586 9.42547 9.90387 9.24088C9.92188 9.05629 9.98462 8.87887 10.0867 8.724C11.0033 7.73334 11.4533 6.24934 11.4533 4.33334C11.4533 1.80001 9.72 0.666672 8 0.666672C6.28 0.666672 4.54667 1.80001 4.54667 4.33334C4.54667 6.24934 4.99667 7.73334 5.91333 8.724C6.01538 8.87887 6.07813 9.05629 6.09613 9.24088C6.11414 9.42547 6.08686 9.61167 6.01667 9.78334C5.89267 10.1413 5.61667 10.208 5.17533 10.3687C2.86267 11.2193 1.19133 11.8353 0.702 12.8133C0.270093 13.7046 0.0308928 14.6768 0 15.6667C0 15.7551 0.035119 15.8399 0.0976311 15.9024C0.160143 15.9649 0.244928 16 0.333333 16H15.6667C15.7551 16 15.8399 15.9649 15.9024 15.9024C15.9649 15.8399 16 15.7551 16 15.6667C15.9691 14.6768 15.7299 13.7046 15.298 12.8133Z\" />\n</svg>\n";
export default src;
