/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import Line from '../components/line'
import steps from '../steps'
import * as Assemblers from './assemblers'

const FlowbotView = ({
  freezeFirst,
  nodes,
  parentId,
  onClick,
  selectedId,
  fieldsAll,
  formSections,
  lineage,
  workflowSettings
}) => {
  if (!nodes || !nodes.length) {
    return (
      <div>
        <Trans id='workflow.is.empty' />
      </div>
    )
  }
  return (
    <div className='flex shrink-0 items-center text-sm'>
      {!parentId && (
        <div className='relative z-[1] flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-full bg-medium-gray-500 text-[9px] uppercase text-white'>
          <Trans id='start' />
        </div>
      )}
      <Line first invisible={parentId} />
      {nodes.map((node, i) => {
        const freeze = i === 0 && freezeFirst
        const step = renderStep(steps, node, FlowbotView, {
          onClick: freeze ? undefined : onClick,
          selectedId,
          fieldsAll,
          formSections,
          lineage,
          workflowSettings
        })
        return [
          step,
          <Line
            key={`line-${node.clientId}`}
            invisible={parentId && i === nodes.length - 1}
          />
        ]
      })}
      {!parentId && (
        <div className='relative z-[1] flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-full bg-medium-gray-500 text-[9px] uppercase text-white'>
          <Trans id='end' />
        </div>
      )}
    </div>
  )
}

export default props => (
  <div role='application' aria-roledescription='Workflow Tester'>
    <FlowbotView key='flowbot' {...props} />
  </div>
)

const renderStep = (steps, node, Flowbot, fbProps) => {
  const manifest = steps[node.type]
  const Assembler = manifest.Assembler || Assemblers.Basic
  return (
    <Assembler
      key={`step-${node.clientId}`}
      manifest={manifest}
      onClick={
        fbProps.onClick &&
        (e => {
          e.stopPropagation()
          fbProps.onClick(node)
        })
      }
      selected={node.clientId === fbProps.selectedId}
      details={node}
      Flowbot={Flowbot}
      fbProps={fbProps}
    />
  )
}
