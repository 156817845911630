/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Radios from '../../../ui/radios'
import * as Chooser from '../../data-chooser'
import RoleMultiselect from '../user-typeahead/role-multiselect'

export function RequiredConfig ({
  Gadgets,
  updateDataLookupSource,
  indexed,
  indexType,
  value,
  id
}) {
  if (!updateDataLookupSource) return null
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.Multiselect
        id='users'
        onSelect={updateDataLookupSource}
        indexed={indexed}
        indexType={indexType}
      />

      <Gadgets.Custom
        id={`${id}.roleIds`}
        configKey='roleIds'
        label={i18n._('Group/Role')}
      >
        {({ onChange, value }) => (
          <RoleMultiselect
            aria-labelledby='global-role-label'
            id={`${id}.roleIds`}
            onChange={onChange}
            value={value}
          />
        )}
      </Gadgets.Custom>
    </Gadgets.Padded>
  )
}

export function OptionalConfig ({ Gadgets }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' testid='placeholder' />
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='additionalKey.enabled'
        label={i18n._('add.extra.user.details')}
      >
        <Gadgets.Custom
          configKey='additionalKey.value'
          label={i18n._('when.person.selected.add.to.name')}
          defaultValue=''
        >
          {({ onChange, value }) => (
            <Radios
              nowrap
              id='configAdditionalKey'
              pt={1}
              options={[
                {
                  id: '',
                  label: <Option value='Nothing' example='John Smith' />,
                  ariaLabel: <Trans id='nothing' />
                },
                {
                  id: 'username',
                  label: (
                    <Option value='Username' example='John Smith (jsmith)' />
                  ),
                  ariaLabel: <Trans id='username' />
                },
                {
                  id: 'email',
                  label: (
                    <Option
                      value='Email'
                      example='John Smith (jsmith@kuali.co)'
                    />
                  ),
                  ariaLabel: <Trans id='email' />
                }
              ]}
              value={value}
              onChange={onChange}
            />
          )}
        </Gadgets.Custom>
      </Gadgets.ConfigBox>
    </>
  )
}

const Value = styled.span`
  display: inline-block;
  width: 80px;
`

const Example = styled.span`
  color: #666;
  font-size: 14px;
`

const Option = ({ value, example }) => (
  <span>
    <Value>{value}</Value>
    <Example>{example}</Example>
  </span>
)
