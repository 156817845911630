/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`28`} height={`11`} viewBox={`0 0 28 11`} fill={`none`}{...props}><path d={`M20.6717 0.542999C20.531 0.373126 20.3545 0.236388 20.1549 0.14253C19.9552 0.048673 19.7373 8.02188e-06 19.5167 0H7.72672C7.50611 8.02188e-06 7.28823 0.048673 7.08859 0.14253C6.88895 0.236388 6.71246 0.373126 6.57172 0.542999L0.271719 8.139C0.114086 8.36387 0.0212113 8.62771 0.00322212 8.90174C-0.014767 9.17577 0.0428193 9.44949 0.169702 9.69304C0.296585 9.93659 0.487895 10.1406 0.722777 10.2829C0.957658 10.4252 1.2271 10.5003 1.50172 10.5H25.7387C26.0133 10.5003 26.2828 10.4252 26.5177 10.2829C26.7525 10.1406 26.9439 9.93659 27.0707 9.69304C27.1976 9.44949 27.2552 9.17577 27.2372 8.90174C27.2192 8.62771 27.1264 8.36387 26.9687 8.139L20.6717 0.542999Z`} fill={`#3369A3`}/></svg>
);

let src = "<svg width=\"28\" height=\"11\" viewBox=\"0 0 28 11\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M20.6717 0.542999C20.531 0.373126 20.3545 0.236388 20.1549 0.14253C19.9552 0.048673 19.7373 8.02188e-06 19.5167 0H7.72672C7.50611 8.02188e-06 7.28823 0.048673 7.08859 0.14253C6.88895 0.236388 6.71246 0.373126 6.57172 0.542999L0.271719 8.139C0.114086 8.36387 0.0212113 8.62771 0.00322212 8.90174C-0.014767 9.17577 0.0428193 9.44949 0.169702 9.69304C0.296585 9.93659 0.487895 10.1406 0.722777 10.2829C0.957658 10.4252 1.2271 10.5003 1.50172 10.5H25.7387C26.0133 10.5003 26.2828 10.4252 26.5177 10.2829C26.7525 10.1406 26.9439 9.93659 27.0707 9.69304C27.1976 9.44949 27.2552 9.17577 27.2372 8.90174C27.2192 8.62771 27.1264 8.36387 26.9687 8.139L20.6717 0.542999Z\" fill=\"#3369A3\"/>\n</svg>";
export default src;
