/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get } from 'lodash'
import React from 'react'

import Badge from '../../../ui/badge'
import { useFormbotData } from '../../engine/formbot-react/hooks'

export default function DataLinkView ({ details, fbRenderView, fbValidate }) {
  const data = useFormbotData()
  if (!details.selectedGadget) return <div data-testid='no-selected-gadget' />
  const { type, formKey } = details.selectedGadget
  const value = get(data, formKey)
  if (value == null || value === '') {
    return (
      <div>
        <Badge>
          <Trans id='data.not.available' />
        </Badge>
      </div>
    )
  }
  if (!fbValidate(type, value, details.selectedGadget)) {
    return <div data-testid='invalid-data' />
  }
  return fbRenderView(type, value, details.selectedGadget.details)
}
