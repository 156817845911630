/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'

export const TextView = styled.div`
  padding: ${p => (p.condensed ? '8px 0' : p.gridded ? '0 16px 16px' : 0)};
  min-height: ${p => (p.condensed || p.gridded ? 'initial' : '19px')};
`
