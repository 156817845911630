/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Spinner from './spinner'

export default () => (
  <Wrapper className='loading bg-[#f7f7f7] dark:bg-light-gray-300'>
    <MySpinner size={200} />
    <ClearCacheInformation />
  </Wrapper>
)

function ClearCacheInformation () {
  if (!/\.cloudfront\.net$/.test(window.location.host)) return null
  return (
    <BlurbWrapper>
      <div>
        <Trans id='stuck.on.this.page' />
      </div>
      <div>
        <Trans id='clear.cache.instructions' />{' '}
        <a
          href='https://support.google.com/accounts/answer/32050'
          target='_blank'
          rel='noreferrer noopener'
        >
          Chrome
        </a>
        ,{' '}
        <a
          href='https://support.mozilla.org/en-US/kb/how-clear-firefox-cache'
          target='_blank'
          rel='noreferrer noopener'
        >
          Firefox
        </a>
        ,{' '}
        <a
          href='https://support.apple.com/guide/safari/clear-your-browsing-history-sfri47acf5d6/mac'
          target='_blank'
          rel='noreferrer noopener'
        >
          Safari
        </a>
        ,{' '}
        <a
          href='https://support.microsoft.com/en-us/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4'
          target='_blank'
          rel='noreferrer noopener'
        >
          Edge
        </a>
      </div>
    </BlurbWrapper>
  )
}

export const LoadingPage = () => {
  return (
    <PageWrapper className='loading bg-white dark:bg-light-gray-300'>
      <MySpinner size={200} />
    </PageWrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const PageWrapper = styled(Wrapper)`
  border-radius: 8px;
`

const MySpinner = styled(Spinner)`
  border-color: transparent;
  border-top-color: #00b586;
  border-width: 7px;
`

const BlurbWrapper = styled.div`
  padding-top: 16px;
  text-align: center;
  font-size: 16px;
`
