/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.588 1.44934C15.431 1.34249 15.2481 1.27991 15.0585 1.26818C14.8689 1.25645 14.6797 1.296 14.5107 1.38268C13.6353 1.73847 12.7043 1.93815 11.76 1.97268C10.6828 1.89341 9.62049 1.67401 8.6 1.32001C6.362 0.666678 5.58667 0.512011 3.87067 0.733344C3.6296 0.764787 3.40823 0.882973 3.24799 1.0658C3.08774 1.24862 2.99958 1.48356 3 1.72668V11.74C3.00001 11.881 3.02984 12.0204 3.08752 12.1491C3.1452 12.2777 3.22944 12.3927 3.3347 12.4865C3.43996 12.5803 3.56386 12.6508 3.69829 12.6933C3.83271 12.7359 3.97461 12.7495 4.11467 12.7333C5.618 12.5607 6.318 12.7073 8.40934 13.3193C9.491 13.6942 10.6178 13.9231 11.76 14C12.9268 13.9567 14.0753 13.6963 15.1467 13.232C15.5947 13.0613 16 12.832 16 12.2133V2.26668C16.0013 2.10748 15.9647 1.95027 15.893 1.80811C15.8213 1.66595 15.7168 1.54295 15.588 1.44934Z`}/><path d={`M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16C1.26522 16 1.51957 15.8946 1.70711 15.7071C1.89464 15.5196 2 15.2652 2 15V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0V0Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.588 1.44934C15.431 1.34249 15.2481 1.27991 15.0585 1.26818C14.8689 1.25645 14.6797 1.296 14.5107 1.38268C13.6353 1.73847 12.7043 1.93815 11.76 1.97268C10.6828 1.89341 9.62049 1.67401 8.6 1.32001C6.362 0.666678 5.58667 0.512011 3.87067 0.733344C3.6296 0.764787 3.40823 0.882973 3.24799 1.0658C3.08774 1.24862 2.99958 1.48356 3 1.72668V11.74C3.00001 11.881 3.02984 12.0204 3.08752 12.1491C3.1452 12.2777 3.22944 12.3927 3.3347 12.4865C3.43996 12.5803 3.56386 12.6508 3.69829 12.6933C3.83271 12.7359 3.97461 12.7495 4.11467 12.7333C5.618 12.5607 6.318 12.7073 8.40934 13.3193C9.491 13.6942 10.6178 13.9231 11.76 14C12.9268 13.9567 14.0753 13.6963 15.1467 13.232C15.5947 13.0613 16 12.832 16 12.2133V2.26668C16.0013 2.10748 15.9647 1.95027 15.893 1.80811C15.8213 1.66595 15.7168 1.54295 15.588 1.44934Z\" />\n<path d=\"M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16C1.26522 16 1.51957 15.8946 1.70711 15.7071C1.89464 15.5196 2 15.2652 2 15V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0V0Z\" />\n</svg>\n";
export default src;
