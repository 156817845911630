/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useRemoveIntegrationMutation = () => {
  const [removeIntegration] = useMutation(mutation)
  const query = useQueryContext()
  return id => removeIntegration(getParams(id, query))
}

const mutation = gql`
  mutation RemoveIntegration($id: ID!) {
    removeIntegration(args: { id: $id }) {
      id
    }
  }
`

const getParams = (id, query) => ({
  variables: { id },
  refetchQueries: [query]
})
