/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6669 0H13.6669C13.623 9.2898e-06 13.5796 0.00867031 13.5391 0.0254869C13.4986 0.0423034 13.4618 0.066945 13.4309 0.098L7.07755 6.452C6.01137 5.96514 4.80802 5.86831 3.67771 6.17843C2.54739 6.48855 1.56194 7.18591 0.893491 8.1487C0.22504 9.11149 -0.0839276 10.2785 0.0205533 11.4459C0.125034 12.6134 0.636323 13.707 1.46511 14.5358C2.29391 15.3646 3.38752 15.8759 4.55495 15.9803C5.72237 16.0848 6.88939 15.7758 7.85219 15.1074C8.81498 14.4389 9.51233 13.4535 9.82246 12.3232C10.1326 11.1929 10.0358 9.98952 9.54889 8.92333L11.0902 7.38133L12.3789 7.81067C12.487 7.84609 12.6025 7.85324 12.7142 7.83142C12.8259 7.80961 12.9301 7.75956 13.017 7.68604C13.1039 7.61252 13.1705 7.51799 13.2105 7.41144C13.2505 7.30488 13.2626 7.18986 13.2456 7.07733L13.0049 5.46667L13.4889 4.98267L14.4596 5.424C14.5752 5.47678 14.7035 5.4954 14.8294 5.47766C14.9553 5.45993 15.0734 5.40658 15.17 5.3239C15.2665 5.24122 15.3374 5.13266 15.3743 5.01102C15.4112 4.88937 15.4126 4.75972 15.3782 4.63733L15.0409 3.43133L15.9029 2.56933C15.9338 2.53832 15.9583 2.50151 15.975 2.46102C15.9917 2.42053 16.0003 2.37714 16.0002 2.33333V0.333333C16.0002 0.244928 15.9651 0.160143 15.9026 0.0976311C15.8401 0.0351189 15.7553 0 15.6669 0V0ZM4.66689 12.3333C4.66689 12.5311 4.60824 12.7245 4.49836 12.8889C4.38847 13.0534 4.2323 13.1815 4.04957 13.2572C3.86684 13.3329 3.66578 13.3527 3.4718 13.3141C3.27782 13.2755 3.09963 13.1803 2.95978 13.0404C2.81993 12.9006 2.72469 12.7224 2.6861 12.5284C2.64752 12.3344 2.66732 12.1334 2.74301 11.9507C2.81869 11.7679 2.94687 11.6117 3.11132 11.5019C3.27577 11.392 3.46911 11.3333 3.66689 11.3333C3.9321 11.3333 4.18646 11.4387 4.37399 11.6262C4.56153 11.8138 4.66689 12.0681 4.66689 12.3333Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6669 0H13.6669C13.623 9.2898e-06 13.5796 0.00867031 13.5391 0.0254869C13.4986 0.0423034 13.4618 0.066945 13.4309 0.098L7.07755 6.452C6.01137 5.96514 4.80802 5.86831 3.67771 6.17843C2.54739 6.48855 1.56194 7.18591 0.893491 8.1487C0.22504 9.11149 -0.0839276 10.2785 0.0205533 11.4459C0.125034 12.6134 0.636323 13.707 1.46511 14.5358C2.29391 15.3646 3.38752 15.8759 4.55495 15.9803C5.72237 16.0848 6.88939 15.7758 7.85219 15.1074C8.81498 14.4389 9.51233 13.4535 9.82246 12.3232C10.1326 11.1929 10.0358 9.98952 9.54889 8.92333L11.0902 7.38133L12.3789 7.81067C12.487 7.84609 12.6025 7.85324 12.7142 7.83142C12.8259 7.80961 12.9301 7.75956 13.017 7.68604C13.1039 7.61252 13.1705 7.51799 13.2105 7.41144C13.2505 7.30488 13.2626 7.18986 13.2456 7.07733L13.0049 5.46667L13.4889 4.98267L14.4596 5.424C14.5752 5.47678 14.7035 5.4954 14.8294 5.47766C14.9553 5.45993 15.0734 5.40658 15.17 5.3239C15.2665 5.24122 15.3374 5.13266 15.3743 5.01102C15.4112 4.88937 15.4126 4.75972 15.3782 4.63733L15.0409 3.43133L15.9029 2.56933C15.9338 2.53832 15.9583 2.50151 15.975 2.46102C15.9917 2.42053 16.0003 2.37714 16.0002 2.33333V0.333333C16.0002 0.244928 15.9651 0.160143 15.9026 0.0976311C15.8401 0.0351189 15.7553 0 15.6669 0V0ZM4.66689 12.3333C4.66689 12.5311 4.60824 12.7245 4.49836 12.8889C4.38847 13.0534 4.2323 13.1815 4.04957 13.2572C3.86684 13.3329 3.66578 13.3527 3.4718 13.3141C3.27782 13.2755 3.09963 13.1803 2.95978 13.0404C2.81993 12.9006 2.72469 12.7224 2.6861 12.5284C2.64752 12.3344 2.66732 12.1334 2.74301 11.9507C2.81869 11.7679 2.94687 11.6117 3.11132 11.5019C3.27577 11.392 3.46911 11.3333 3.66689 11.3333C3.9321 11.3333 4.18646 11.4387 4.37399 11.6262C4.56153 11.8138 4.66689 12.0681 4.66689 12.3333Z\" />\n</svg>\n";
export default src;
