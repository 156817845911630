/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'
import {
  alignItems,
  flexDirection,
  height,
  justifyContent,
  space,
  width
} from 'styled-system'

export const Wrapper = styled.div`
  position: relative;
  ${space}
  ${width}
  ${height}
`

export const Flex = styled.div`
  display: flex;
  ${space}
  ${alignItems}
  ${justifyContent}
  ${flexDirection}
`
Flex.defaultProps = { alignItems: 'center' }

export const HR = styled.div`
  border-bottom: 1px solid lightgray;
  width: 100%;
  ${space}
  html.dark & {
    // Outlier: dark:border-light-gray-300
    border-bottom: 1px solid #333;
  }
`

export const Space = styled.div`
  display: ${p => (p.w ? 'inline-block' : 'block')};
  flex-grow: ${p => (p.expand ? 1 : 'initial')};
  flex-shrink: ${p => (p.w || p.h ? 0 : 'initial')};
  width: ${p => p.w || 'initial'};
  height: ${p => p.h || 'initial'};
`
