/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useImportCMConfiguration = () => {
  const [importCMConfig] = useMutation(mutation)
  return (url, apiKey, name) => importCMConfig(getParams(url, apiKey, name))
}

const mutation = gql`
  mutation ImportCMConfiguration(
    $url: String!
    $apiKey: String!
    $name: String!
  ) {
    importCMConfiguration: importCmConfiguration(
      url: $url
      apiKey: $apiKey
      name: $name
    ) {
      id
      firstPageId
    }
  }
`

const getParams = (url, apiKey, name) => ({
  variables: { url, apiKey, name }
})
