/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, debounce } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import shortid from 'shortid'
import styled from 'styled-components'

import DropdownEdit from '../../../../formbot/gadgets/dropdown/edit'
import * as Icons from '../../../../icons'
import { NoApps } from '../../../../illustrations'
import { useAlerts } from '../../../../ui/alerts'
import Button from '../../../../ui/button'
import Checkbox from '../../../../ui/checkbox'
import Input from '../../../../ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../../../ui/table'
import Textarea from '../../../../ui/textarea'
import { useUpdateGroupMutation } from './components/mutation.update-group'

export default function GroupFields () {
  const { group } = useOutletContext()
  const alerts = useAlerts()
  const canAdminister = group?.access?.isAdmin
  const [fields, setFields] = React.useState(group?.fields ?? [])
  const [updateGroup] = useUpdateGroupMutation(group)
  const [schema, setSchema] = React.useState(
    (group?.category
      ? group?.category?.fieldSchemas
      : group?.fieldSchemas ?? []
    )?.map(el => ({ editing: false, val: el }))
  )

  const debouncedUpdate = React.useCallback(
    debounce(fields => {
      updateGroup({ fields })
        .then(() =>
          alerts.type3(
            i18n._({ id: 'group.saved', message: 'Group saved.' }),
            'success'
          )
        )
        .catch(() =>
          alerts.type3(
            i18n._({
              id: 'failed.save.group',
              message: 'Failed to save group'
            }),
            'error'
          )
        )
    }, 1000),
    []
  )

  const handleUpdate = fields => {
    setFields(fields)
    debouncedUpdate(fields)
  }

  if (!schema?.length && group?.category) {
    return (
      <NoContentWrap>
        <h1 className='pb-3 text-4xl text-medium-gray-400'>
          <Trans id='no.fields' message='No Fields' />
        </h1>
        <NoApps />
      </NoContentWrap>
    )
  }

  const columns = headerFields(group?.category, canAdminister)
  const updates = { handleUpdate, updateGroup, setFields, setSchema }

  return (
    <TableWrapper categorized={group?.category}>
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map((field, i) => (
              <TableHeaderCell key={`${i}.${field.label}`}>
                {field.label}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {schema.map((field, i) => {
            return (
              <StyledTableRow key={field.val.id}>
                {columns.map(column =>
                  column.render({
                    i,
                    field,
                    fields,
                    schema,
                    alerts,
                    ...updates
                  })
                )}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
      {!group.category && canAdminister && (
        <Button
          icon
          transparent
          mt={3}
          onClick={() => {
            const newId = shortid.generate()
            setSchema(schema => {
              const newSchema = cloneDeep(schema)
              newSchema.push({
                editing: true,
                val: { id: newId, name: '', type: 'text' }
              })
              return newSchema
            })
          }}
        >
          <Icons.Add mr={2} className='fill-blue-500' />
          <Trans id='add.field' message='Add Field' />
        </Button>
      )}
    </TableWrapper>
  )
}

const types = {
  text: Input,
  checkbox: Checkbox,
  textarea: Textarea
}

const headerFields = (categorized, canAdminister) => {
  return [
    {
      label: <Trans id='field.name' name='Field Name' />,
      key: 'name',
      render ({ field, setSchema }) {
        return (
          <TableCell key={`${field.id}-name`}>
            {field.editing ? (
              <Input
                placeholder={i18n._({
                  id: 'field.name',
                  message: 'Field Name'
                })}
                value={field.val?.name ?? ''}
                onChange={e => {
                  setSchema(schema => {
                    const newSchema = cloneDeep(schema)
                    newSchema.find(f => f.val.id === field.val.id).val.name = e
                    return newSchema
                  })
                }}
              />
            ) : (
              field.val?.name ?? '--'
            )}
          </TableCell>
        )
      }
    },
    !categorized && {
      label: <Trans id='type' message='Type' />,
      key: 'category.fieldSchema.type',
      render ({ field, setSchema }) {
        return (
          <TableCell key={`${field.id}-type`}>
            {field.editing ? (
              <DropdownEdit
                configKey='GroupChild'
                darkerModeDarkerBg
                details={{
                  options: [
                    { key: 'text', lbl: <Trans id='text' message='Text' /> },
                    {
                      key: 'checkbox',
                      lbl: <Trans id='checkbox' message='Checkbox' />
                    },
                    {
                      key: 'textarea',
                      lbl: <Trans id='textarea' message='Textarea' />
                    }
                  ]
                }}
                onChange={e => {
                  setSchema(schema => {
                    const newSchema = cloneDeep(schema)
                    newSchema.find(f => f.val.id === field.val.id).val.type =
                      e?.id ?? 'text'
                    return newSchema
                  })
                }}
                value={field.val?.type ?? 'text'}
              />
            ) : (
              <CapSpan>{field.val?.type ?? '--'}</CapSpan>
            )}
          </TableCell>
        )
      }
    },
    {
      label: <Trans id='value' message='Value' />,
      key: 'value',
      render ({ i, field, fields, handleUpdate }) {
        const Component = types[field.val.type]
        const fieldVal = fields.find(el => el.id === field.val.id)?.value
        return (
          <ComponentTableCell key={`${field.id}-value`}>
            <Component
              aria-label={
                field.val?.name
                  ? i18n._(
                      { id: 'which.named.input', message: '{name} input' },
                      { name: field.val.name }
                    )
                  : i18n._(
                      { id: 'which.row.input', message: 'row {num} input' },
                      { num: i + 1 }
                    )
              }
              disabled={!canAdminister}
              value={fieldVal}
              checked={fieldVal === 'true' || fieldVal === true}
              onChange={e => {
                const newFields = cloneDeep(fields)
                const exists = newFields.find(f => f.id === field.val.id)
                if (exists) {
                  exists.value = e
                } else {
                  newFields.push({ id: field.val.id, value: e })
                }
                handleUpdate(newFields)
              }}
            />
          </ComponentTableCell>
        )
      }
    },
    !categorized &&
      canAdminister && {
        label: <Trans id='actions' message='Actions' />,
        key: 'action',
        render ({
          field,
          setSchema,
          updateGroup,
          schema,
          fields,
          setFields,
          alerts
        }) {
          return (
            <TableCell key={`${field.id}-actions`}>
              {field.editing ? (
                <Button
                  onClick={() => {
                    setSchema(schema => {
                      const newSchema = cloneDeep(schema)
                      newSchema.find(f => f.val.id === field.val.id).editing =
                        false
                      return newSchema
                    })
                    updateGroup({
                      fieldSchemas: schema.map(f => f.val)
                    })
                      .then(() =>
                        alerts.type3(
                          i18n._({
                            id: 'group.saved',
                            message: 'Group saved.'
                          }),
                          'success'
                        )
                      )
                      .catch(() =>
                        alerts.type3(
                          i18n._({
                            id: 'failed.save.group',
                            message: 'Failed to save group'
                          }),
                          'error'
                        )
                      )
                  }}
                >
                  Save
                </Button>
              ) : (
                <>
                  <Button
                    icon
                    transparent
                    onClick={() => {
                      const newFields = fields.filter(
                        f => f.id !== field.val.id
                      )
                      const newSchema = schema.filter(
                        f => f.val.id !== field.val.id
                      )
                      setSchema(newSchema)
                      setFields(newFields)
                      updateGroup({
                        fields: newFields,
                        fieldSchemas: newSchema.map(f => f.val)
                      })
                        .then(() =>
                          alerts.type3(
                            i18n._({
                              id: 'field.deleted',
                              message: 'Field deleted'
                            }),
                            'success'
                          )
                        )
                        .catch(() =>
                          alerts.type3(
                            i18n._({
                              id: 'failed.delete.field',
                              message: 'Failed to delete field'
                            }),
                            'error'
                          )
                        )
                    }}
                  >
                    <Icons.Delete />
                  </Button>
                  <Button
                    icon
                    transparent
                    ml={2}
                    onClick={() => {
                      setSchema(schema => {
                        const newSchema = cloneDeep(schema)
                        newSchema.find(f => f.val.id === field.val.id).editing =
                          true
                        return newSchema
                      })
                    }}
                  >
                    <Icons.Edit />
                  </Button>
                </>
              )}
            </TableCell>
          )
        }
      }
  ].filter(Boolean)
}

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: initial !important;
  }
  html.dark &:hover {
    // Outlier: dark:hover:bg-light-gray-300
    background: #333 !important;
  }
`

const TableWrapper = styled.div`
  padding: 32px 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  max-width: ${p => (p.categorized ? '42vw' : '64vw')};
  margin: 0 auto;

  th,
  td {
    height: 52px;
  }
`

const NoContentWrap = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ComponentTableCell = styled(TableCell)`
  label {
    margin-bottom: 8px;
  }
  textarea,
  input:not([type='checkbox']) {
    width: 100%;
  }
  textarea {
    margin-top: 8px;
    margin-bottom: 4px;
  }
`

const CapSpan = styled.span`
  text-transform: capitalize;
`
