/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Wrapper } from '../../ui/layout'
import Textarea from '../../ui/textarea'
import { Body1 } from '../../ui/typography'
import * as Errors from './errors'

export const Config = ({ errors, required, value, updateValue }) => {
  const [charCount, setCharCount] = React.useState(
    value?.instructions?.value?.length || 0
  )
  return (
    <Wrapper px={3}>
      <Body1 py={2} as='label' htmlFor='custom-step-label' required={required}>
        <Trans id='instructions' />
      </Body1>
      <Textarea
        id='custom-step-label'
        width='100%'
        maxLength={260}
        placeholder={i18n._('add.instructions.for.task')}
        value={value?.instructions?.value || ''}
        onChange={val => {
          setCharCount(val.length)
          updateValue(draft => {
            draft.instructions = { enabled: true, value: val }
          })
        }}
      />
      <Errors.Config
        id='instructions-errors'
        errors={Errors.filter(errors, 'instructions')}
      />
      {charCount > 0 && <CharCount>{`${charCount}/260`}</CharCount>}
    </Wrapper>
  )
}

export const View = ({ details, errors }) => {
  errors = Errors.filter(errors, ['instructions'])
  if (errors.length) return <Errors.View errors={errors} />
  return null
}

const _validate = value => {
  if (!get(value, 'instructions.value')) {
    return [i18n._('instructions.are.required')]
  }
  return []
}
export const validate = Errors.wrap(_validate, 'instructions')

const CharCount = styled.div`
  text-align: right;
  color: #666666;
  font-size: 12px;
`
