/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { clamp } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { CalcFunctionSelector } from '../../../pages-builder/calculations/calculation-config'
import Input from '../../../ui/input'

export function RequiredConfig ({ Gadgets }) {
  return (
    <>
      <Gadgets.Custom
        configKey='defaultRowCount'
        label={i18n._('start.with.this.many.rows', { max: 15 })}
      >
        {({ onChange, value }) => (
          <NumericInput
            id='defaultRowCount'
            onChange={onChange}
            value={value}
            placeholder='2'
          />
        )}
      </Gadgets.Custom>
    </>
  )
}

export function OptionalConfig ({ id, Gadgets, childrenTemplate }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='allowAdditionalRows.enabled'
        label={i18n._('allow.user.add.additional.rows')}
      >
        <Gadgets.Custom
          configKey='allowAdditionalRows.maxRows'
          label={i18n._('limit.number.rows.optional', { max: 15 })}
        >
          {({ onChange, value }) => (
            <NumericInput
              id={id}
              placeholder={i18n._('max.number.rows')}
              onChange={onChange}
              value={value}
            />
          )}
        </Gadgets.Custom>
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='calculationFooter.enabled'
        label={i18n._('enable.calculation.footer')}
      >
        {childrenTemplate.map((column, columnIndex) => {
          const columnType =
            column.details?.selectedOutputField?.type || column.type
          if (!['Number', 'Currency'].includes(columnType)) return null
          return (
            <Gadgets.Custom
              key={column.id + columnIndex}
              configKey={`calculationFooter.columns.${column.id}`}
              unPadded
            >
              {({ onChange, value }, i) => {
                return (
                  <CalcFunctionSelector
                    key={value + i}
                    onChange={onChange}
                    gadgetType='Table'
                    value={value}
                  >
                    <h5 className='mr-2'>
                      {column?.customName?.value ||
                        column.label ||
                        column.formKey}
                    </h5>
                  </CalcFunctionSelector>
                )
              }}
            </Gadgets.Custom>
          )
        })}
      </Gadgets.ConfigBox>
    </>
  )
}

const NumericInput = ({ onChange, value, ...rest }) => (
  <SmallInput
    onChange={newValue => {
      if (newValue === '') return onChange(null)
      const number = parseInt(newValue, 10)
      if (!isNaN(number)) return onChange(clamp(number, 1, 15))
    }}
    type='number'
    min='1'
    step='1'
    value={value || ''}
    {...rest}
  />
)

const SmallInput = styled(Input)`
  max-width: 50%;
`
