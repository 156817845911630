/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { map } from 'lodash'
import React from 'react'

import Spinner from '../../components/spinner'
import Radios from '../../ui/radios'

export default function SendbackContent ({
  steps,
  onCancel,
  onClick,
  submitting
}) {
  const [comment, setComment] = React.useState('')
  const [index, setIndex] = React.useState('0')
  const options = map(steps, ({ label }, i) => ({ label, id: `${i}` }))

  return (
    <div className='text-sm'>
      <div className='block bg-light-gray-100 px-4 py-2 text-xs uppercase text-medium-gray-500 dark:bg-light-gray-300 dark:text-dark-gray-500'>
        <Trans id='pagesrunner.action.send.back' />
      </div>
      <div className='p-4' data-testid='popover-sendback'>
        <label
          htmlFor='sendBackTo'
          className='block text-xs text-medium-gray-500'
        >
          <Trans id='pagesrunner.action.send.back.to' />
        </label>
        <Radios
          id='sendBackTo'
          name='sendBackTo'
          aria-label={i18n._('pagesrunner.action.send.back.send.to')}
          options={options}
          value={index}
          onChange={index => setIndex(index)}
          mt={1}
          mb={3}
        />
        <label
          htmlFor='comment'
          required
          className='block text-xs text-medium-gray-500 after:ml-1 after:text-red-400 after:content-["*"]'
        >
          <Trans id='pagesrunner.action.send.back.add.comments' />
        </label>
        <textarea
          className='kp-textarea w-full'
          id='comment'
          rows={3}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        <div className='flex gap-2'>
          <button className='kp-button-outline w-full' onClick={onCancel}>
            <Trans id='cancel' />
          </button>
          <button
            className='kp-button-solid w-full dark:disabled:text-black'
            disabled={!comment || submitting}
            title={
              comment
                ? undefined
                : i18n._('pagesrunner.action.send.back.comments.required')
            }
            onClick={e => {
              e.preventDefault()
              onClick(comment, steps[index])
            }}
          >
            {submitting ? (
              <Spinner size={20} />
            ) : (
              `${i18n._('pagesrunner.action.send.back')}`
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
