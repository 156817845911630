/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6454 1.98937C15.5294 1.78346 15.3607 1.61211 15.1566 1.49286C14.9525 1.3736 14.7204 1.31074 14.4841 1.3107H1.51607C1.27985 1.31081 1.04789 1.37369 0.843944 1.49288C0.639995 1.61208 0.471375 1.78332 0.355337 1.98908C0.239299 2.19484 0.18001 2.42774 0.183536 2.66394C0.187063 2.90014 0.253278 3.13116 0.375407 3.33337L6.85941 14.0467C6.97818 14.2429 7.14556 14.4052 7.34538 14.5178C7.5452 14.6305 7.7707 14.6896 8.00007 14.6896C8.22945 14.6896 8.45494 14.6305 8.65476 14.5178C8.85458 14.4052 9.02197 14.2429 9.14074 14.0467L15.6247 3.33337C15.7469 3.13125 15.8132 2.9003 15.8168 2.66416C15.8205 2.42802 15.7613 2.19515 15.6454 1.98937Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6454 1.98937C15.5294 1.78346 15.3607 1.61211 15.1566 1.49286C14.9525 1.3736 14.7204 1.31074 14.4841 1.3107H1.51607C1.27985 1.31081 1.04789 1.37369 0.843944 1.49288C0.639995 1.61208 0.471375 1.78332 0.355337 1.98908C0.239299 2.19484 0.18001 2.42774 0.183536 2.66394C0.187063 2.90014 0.253278 3.13116 0.375407 3.33337L6.85941 14.0467C6.97818 14.2429 7.14556 14.4052 7.34538 14.5178C7.5452 14.6305 7.7707 14.6896 8.00007 14.6896C8.22945 14.6896 8.45494 14.6305 8.65476 14.5178C8.85458 14.4052 9.02197 14.2429 9.14074 14.0467L15.6247 3.33337C15.7469 3.13125 15.8132 2.9003 15.8168 2.66416C15.8205 2.42802 15.7613 2.19515 15.6454 1.98937Z\" />\n</svg>\n";
export default src;
