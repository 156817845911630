/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { find, get, last, map, remove } from 'lodash'
import React from 'react'

import { useLocalStorageState } from '../../components/use-local-storage-state'
import * as Icons from '../../icons'
import Checkbox from '../../ui/checkbox'
import * as BranchBuilder from '../components/branch-builder'
import * as CustomTitle from '../components/custom-title'
import {
  InfoPage,
  InfoTitle,
  SimulateWrapper
} from '../components/simulator-parts'
import { ReactComponent as BranchIcon } from '../components/simulator-parts/branch-icon.svg.jsx'
import SimulationError from '../components/simulator-parts/error-page'
import { ReactComponent as RouteCompleteIcon } from '../components/simulator-parts/route-complete-icon.svg.jsx'
import { Simulation } from '../engine/simulate'

const Node = ({ details, Flowbot, fbProps }) => (
  <>
    {map(details.subflows, ({ disabled, steps, rule, clientId }, i) =>
      disabled ? null : (
        <div
          className='border-b border-light-gray-400 p-2 last:border-b-0 dark:border-light-gray-200'
          key={`subflow-${clientId}`}
        >
          <div
            className={cx(
              'absolute w-[calc(100%-16px)] overflow-hidden overflow-ellipsis whitespace-nowrap rounded border-4 px-1 font-bold',
              clientId && clientId === fbProps.selectedId
                ? 'border-medium-gray-400'
                : 'border-transparent'
            )}
            title={getRouteLabel(i, rule)}
          >
            {getRouteLabel(i, rule)}
          </div>
          <div className='h-8 w-64' />
          <Flowbot
            parentId={`${details.clientId}::${i}`}
            a11yLabel={`in route ${i} of ${details.stepName || 'Branch'}`}
            nodes={steps}
            {...fbProps}
            lineage={[...(fbProps.lineage || []), details._id]}
          />
        </div>
      )
    )}
  </>
)

function getRouteLabel (num, rule) {
  if (!rule) return 'Default Route'
  const len = rule.expressions.length
  const and = rule.logicalOperator === 'and'
  const prefix = `Route ${num}: `
  if (rule.logicalOperator === 'always') return `${prefix}Always`
  if (len === 1) {
    const expr = rule.expressions[0]
    const a = get(expr, 'left.label')
    const b = get(expr, 'operator')
    const c =
      get(expr, 'right.label') ||
      get(expr, 'right.value.label') ||
      get(expr, 'right.value')
    if (!a || !b) return `${prefix}Route not configured`
    const suffix = c === null || c === undefined ? '' : ` ${c}`
    return `${prefix}${a} ${b}${suffix}`
  }
  if (and) return `${prefix}${len} conditions are met`
  return `${prefix}at least 1 of ${len} conditions are met`
}

const Config = ({ value, updateValue, errors, fieldsAll }) => (
  <>
    <CustomTitle.Config
      value={value}
      updateValue={updateValue}
      defaultName='Branch'
      label='Step Label'
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
    <BranchBuilder.Config
      value={value}
      updateValue={updateValue}
      errors={errors}
      fieldsAll={fieldsAll}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
  </>
)

const Simulate = ({
  form,
  headless,
  value,
  simulateAction,
  simulationState,
  ...rest
}) => {
  const [simulatorPreferences, setSimulatorPreferences] = useLocalStorageState(
    'simulatorPreferences',
    { showBranchIntro: true }
  )
  const { status } = value
  if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  }
  if (status === 'No Match') {
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <NoMatch />
      </SimulateWrapper>
    )
  }
  const activeSubflow = find(get(value, 'subflows', []), sf => sf.active)
  if (!activeSubflow) {
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <Intro
          toggleShowIntros={checked => {
            simulationState.meta.simulatorPreferences.showBranchIntro = !checked
            setSimulatorPreferences({
              ...simulatorPreferences,
              showBranchIntro: !checked
            })
          }}
        />
      </SimulateWrapper>
    )
  } else {
    return (
      <BranchWrapper
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
        {...rest}
      />
    )
  }
}

const NoMatch = () => {
  return (
    <InfoPage justifyContent='center' flexDirection='column'>
      <BranchIcon />
      <InfoTitle>
        <Trans id='no.conditions.match' />
      </InfoTitle>
      <div className='w-72 text-center text-base'>
        <Trans id='therefore.none.routes.taken' />
      </div>
    </InfoPage>
  )
}

const Intro = ({ toggleShowIntros }) => {
  return (
    <InfoPage justifyContent='center' flexDirection='column'>
      <BranchIcon />
      <InfoTitle>
        <Trans id='parallel.routes' />
      </InfoTitle>
      <div className='w-72 text-center text-base'>
        <Trans id='multiple.routes.occur.same.time' />
      </div>
      <div className='mt-8 text-center text-sm text-dark-gray-400'>
        <Checkbox
          id='disable-branch-intro'
          label={i18n._('do.not.show.again')}
          onChange={toggleShowIntros}
        />
      </div>
    </InfoPage>
  )
}

const BranchWrapper = ({ headless, value, ...props }) => {
  const activeSubflow = find(value.subflows, { active: true })
  return (
    <SimulateWrapper headless={headless} buttons={<div />}>
      <BranchHeader
        activeSubflow={activeSubflow}
        headless={headless}
        value={value}
      />
      <SubStep {...props} activeSubflow={activeSubflow} value={value} />
    </SimulateWrapper>
  )
}

const BranchHeader = ({ activeSubflow, headless, value }) => {
  // ensure default flows come first
  const defaultFlows = remove(
    value.allSubflows,
    sf => sf.shouldRun === 'default'
  )
  value.allSubflows = defaultFlows.concat(value.allSubflows)
  const defaultOffset = 1 - defaultFlows.length
  return (
    <div className='flex flex-col bg-white px-4 pb-4'>
      {!headless && (
        <div className='my-4 flex items-center text-base font-medium'>
          <BranchIcon className='mr-2.5 h-5 w-5' />
          <Trans id='parallel.routes.colon' />
        </div>
      )}
      <div className='flex flex-wrap justify-start text-base font-bold'>
        {map(value.allSubflows, ({ flowId, shouldRun }, i) => (
          <div
            className={cx('mr-4 whitespace-nowrap text-center', {
              'border-b-2 border-dark-gray-300 text-dark-gray-300':
                activeSubflow.flowId === flowId,
              'text-medium-gray-300': activeSubflow.flowId !== flowId
            })}
            key={flowId}
            selected={activeSubflow.flowId === flowId}
          >
            {shouldRun === 'default'
              ? 'Default Route'
              : `Route ${i + defaultOffset}`}
          </div>
        ))}
      </div>
    </div>
  )
}

const SubStep = ({
  activeSubflow,
  simulateAction,
  simulationState,
  value,
  workflow,
  ...props
}) => {
  const latestState = last(activeSubflow.states)
  if (latestState.status === 'complete') {
    return (
      <SimulateWrapper
        headless
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <InfoPage justifyContent='center' flexDirection='column'>
          <RouteCompleteIcon />
          <InfoTitle>
            <Trans id='route.complete' />
          </InfoTitle>
        </InfoPage>
      </SimulateWrapper>
    )
  }
  const stepDefinition = find(workflow.steps, { _id: value.stepDefinitionId })
  const subflowDefinition = find(stepDefinition.subflows, {
    _id: activeSubflow.flowId
  })
  return (
    <Simulation
      {...props}
      headless
      simulateAction={simulateAction}
      simulationState={simulationState}
      value={latestState}
      workflow={subflowDefinition}
    />
  )
}

export default {
  name: 'Branch',
  Icon: Icons.Branch,
  color: '#999',
  truncate: () => true,
  validate: BranchBuilder.validate,
  defaultTemplate: () => ({
    ...BranchBuilder.defaults()
  }),
  Config,
  Node,
  Simulate
}
