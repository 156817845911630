/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as d3 from 'd3'
import FocusTrap from 'focus-trap-react'
import { findLastIndex, last, split } from 'lodash'
import React from 'react'
import { DropTarget } from 'react-dnd'

import VoronoiContext from './context'

export default function Voronoi ({
  a11yDragItem,
  endA11yDrag,
  isInvalid,
  onDrop,
  onHover,
  show
}) {
  const { voronoiData, width, height, top, left } =
    React.useContext(VoronoiContext)
  const component = React.useMemo(
    () => (
      <InnerVoronoi
        a11yDragItem={a11yDragItem}
        endA11yDrag={endA11yDrag}
        isInvalid={isInvalid}
        onDrop={onDrop}
        onHover={onHover}
        show={show}
        voronoiData={voronoiData}
        width={width}
        height={height}
        top={top}
        left={left}
      />
    ),
    [
      a11yDragItem,
      endA11yDrag,
      isInvalid,
      onDrop,
      onHover,
      show,
      voronoiData,
      width,
      height,
      top,
      left
    ]
  )
  return component
}

const InnerVoronoi = DropTarget('VORONOI', {}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  class _InnerVoronoi extends React.Component {
    state = { polygons: null, points: null }

    containerRef = React.createRef()

    componentDidUpdate (prevProps) {
      if (prevProps.isOver && !this.props.isOver) this.props.onHover(null)
      if (prevProps.a11yDragItem && !this.props.a11yDragItem) {
        this.props.onHover(null)
      }
      if (prevProps.voronoiData && !this.props.voronoiData) {
        setTimeout(() => this.setState({ polygons: null, points: null }))
      }
      if (!prevProps.voronoiData && this.props.voronoiData) {
        const { voronoiData, width, height, left, top } = this.props
        const points = []
        for (const id in voronoiData) {
          for (let i = 0; i < voronoiData[id].points.length; ++i) {
            const point = voronoiData[id].points[i]
            point.id = getGadgetId(id)
            point.dir = point[2]
            point.data = voronoiData[id].data
            point.dimensions = { ...voronoiData[id].dimensions }

            point[0] -= left
            point[1] -= top
            point.dimensions.top -= top
            point.dimensions.left -= left

            if (voronoiData[id].ariaLabel) {
              point.ariaLabel = getAriaLabel(
                point.dir,
                voronoiData[id].ariaLabel
              )
            }

            points.push(point)
          }
        }
        // Put the points in a fairly reasonable tab order for keyboard use
        points.sort((a, b) => {
          if (a[1] < b[1]) return -1
          if (b[1] < a[1]) return 1
          if (a[0] < b[0]) return -1
          if (b[0] < a[0]) return 1
          return 0
        })
        const polygons = d3.voronoi().size([width, height]).polygons(points)
        setTimeout(() => this.setState({ polygons, points }))
      }
    }

    getInitialFocus = () => {
      if (!this.containerRef.current) return
      const zones = Array.from(
        this.containerRef.current.querySelectorAll('[data-gadget-id]')
      )
      if (!zones.length) return
      const currentGadgetIndex = findLastIndex(
        zones,
        node => node.dataset.gadgetId === this.props.a11yDragItem.id
      )
      // Default to the next available drop zone, but if it's the last
      // gadget in the form, then find the zone before it.
      let index = currentGadgetIndex + 1
      if (index >= zones.length) {
        index = findLastIndex(
          zones,
          node => node.dataset.gadgetId !== this.props.a11yDragItem.id,
          currentGadgetIndex
        )
      }
      return zones[index]
    }

    render () {
      const { polygons, points } = this.state
      if (!polygons) return null
      const draw = d3.line()
      const inner = (
        <div ref={this.containerRef}>
          <svg
            className='absolute left-0 top-0 z-[1] h-full opacity-100'
            style={{ width: this.props.width }}
          >
            {polygons.map((polygon, i) => (
              <Path
                key={i}
                d={draw(polygon)}
                onDrop={this.props.onDrop}
                onHover={this.props.onHover}
                dropContext={polygon.data}
                show={this.props.show}
                a11yDragItem={this.props.a11yDragItem}
                isValidA11yDropZone={
                  this.props.a11yDragItem &&
                  !this.props.isInvalid?.(polygon.data?.id, polygon.data?.dir)
                }
              />
            ))}
            {this.props.show &&
              points.map(([x, y], i) => (
                <circle
                  key={i}
                  cx={x}
                  cy={y}
                  r={3}
                  fill='green'
                  style={{ pointerEvents: 'none' }}
                />
              ))}
          </svg>
        </div>
      )
      if (this.props.a11yDragItem) {
        const focusTrapOptions = {
          clickOutsideDeactivates: true,
          onDeactivate: this.props.endA11yDrag
        }
        if (this.props.a11yDragItem.id) {
          focusTrapOptions.initialFocus = this.getInitialFocus
        }

        return (
          <FocusTrap focusTrapOptions={focusTrapOptions}>{inner}</FocusTrap>
        )
      }

      return this.props.connectDropTarget(inner)
    }
  }
)

const Path = DropTarget(
  'VORONOI',
  {
    drop (props, monitor) {
      props.onDrop(props.dropContext, monitor.getItem())
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    dragContext: monitor.getItem(),
    isOver: monitor.isOver()
  })
)(
  class _Path extends React.Component {
    componentDidUpdate (prevProps) {
      if (this.props.isOver && !prevProps.isOver) {
        this.props.onHover(this.props.dropContext, this.props.dragContext)
      }
    }

    render () {
      const a11yProps = this.props.isValidA11yDropZone
        ? {
            'aria-label': this.props.dropContext.ariaLabel,
            tabIndex: 0,
            role: 'button',
            onFocus: () => {
              this.props.onHover(
                this.props.dropContext,
                this.props.a11yDragItem
              )
            },
            onKeyDown: e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                this.props.onDrop(
                  this.props.dropContext,
                  this.props.a11yDragItem
                )
              }
            },
            className: 'focus:outline-none' // the drop zone will make it visible
          }
        : {}
      return this.props.connectDropTarget(
        <path
          d={this.props.d}
          stroke={this.props.show ? 'red' : 'none'}
          fill='transparent'
          data-gadget-id={this.props.dropContext.id}
          {...a11yProps}
        />
      )
    }
  }
)

function getAriaLabel (dir, suffix) {
  let prefix = dir + ' of'
  if (dir === 'top') {
    prefix = 'above'
  } else if (dir === 'bottom') {
    prefix = 'below'
  }
  return prefix + ' ' + suffix
}

// If the gadget is inside a repeater, then the full composite
// id is used as the key in `voronoiData` in order to be unique.
// Now we need to translate it back to just the gadget id so we
// can match against the form template data.
function getGadgetId (id) {
  return last(split(id, '.'))
}
