/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8.38384 1.01823C6.86013 1.11989 5.39335 1.63689 4.14267 2.51315C3.08651 3.25959 2.22487 4.24856 1.63012 5.39701C1.03536 6.54545 0.724855 7.81984 0.724674 9.11315C0.709542 10.4067 1.00518 11.685 1.58674 12.8406C2.16829 13.9962 3.01877 14.9952 4.06667 15.7538C4.24539 15.871 4.4623 15.915 4.67257 15.8768C4.88284 15.8386 5.07039 15.7211 5.19648 15.5486C5.32256 15.376 5.37752 15.1616 5.35001 14.9497C5.32251 14.7377 5.21463 14.5445 5.04867 14.4098C4.21918 13.8017 3.54569 13.0056 3.08345 12.0868C2.62121 11.168 2.38339 10.1527 2.38951 9.12415C2.39562 8.09565 2.6455 7.08324 3.11863 6.17C3.59177 5.25676 4.27468 4.4687 5.11134 3.87049C6.01915 3.23283 7.07174 2.83181 8.1737 2.70375C9.27566 2.5757 10.3922 2.72467 11.422 3.13715C11.4476 3.1473 11.4702 3.16363 11.4879 3.1847C11.5056 3.20576 11.5178 3.23088 11.5233 3.25782C11.5288 3.28493 11.5276 3.31297 11.5197 3.33948C11.5118 3.36599 11.4974 3.39014 11.478 3.40982L10.2413 4.64515C10.1946 4.69177 10.1628 4.75122 10.1498 4.81596C10.1369 4.8807 10.1435 4.94781 10.1688 5.00879C10.1941 5.06978 10.2369 5.12188 10.2918 5.1585C10.3468 5.19512 10.4113 5.2146 10.4773 5.21449H14.944C15.0324 5.21449 15.1172 5.17937 15.1797 5.11685C15.2422 5.05434 15.2773 4.96956 15.2773 4.88115V0.414485C15.2774 0.348601 15.2579 0.284181 15.2214 0.229363C15.1848 0.174543 15.1328 0.131784 15.072 0.106485C15.0109 0.081904 14.944 0.0756865 14.8795 0.088595C14.815 0.101504 14.7556 0.132977 14.7087 0.179152L12.9807 1.90648C12.9557 1.93161 12.9233 1.94808 12.8883 1.95348C12.8532 1.95887 12.8174 1.95292 12.786 1.93648C11.43 1.23414 9.90755 0.916568 8.38384 1.01823Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8.38384 1.01823C6.86013 1.11989 5.39335 1.63689 4.14267 2.51315C3.08651 3.25959 2.22487 4.24856 1.63012 5.39701C1.03536 6.54545 0.724855 7.81984 0.724674 9.11315C0.709542 10.4067 1.00518 11.685 1.58674 12.8406C2.16829 13.9962 3.01877 14.9952 4.06667 15.7538C4.24539 15.871 4.4623 15.915 4.67257 15.8768C4.88284 15.8386 5.07039 15.7211 5.19648 15.5486C5.32256 15.376 5.37752 15.1616 5.35001 14.9497C5.32251 14.7377 5.21463 14.5445 5.04867 14.4098C4.21918 13.8017 3.54569 13.0056 3.08345 12.0868C2.62121 11.168 2.38339 10.1527 2.38951 9.12415C2.39562 8.09565 2.6455 7.08324 3.11863 6.17C3.59177 5.25676 4.27468 4.4687 5.11134 3.87049C6.01915 3.23283 7.07174 2.83181 8.1737 2.70375C9.27566 2.5757 10.3922 2.72467 11.422 3.13715C11.4476 3.1473 11.4702 3.16363 11.4879 3.1847C11.5056 3.20576 11.5178 3.23088 11.5233 3.25782C11.5288 3.28493 11.5276 3.31297 11.5197 3.33948C11.5118 3.36599 11.4974 3.39014 11.478 3.40982L10.2413 4.64515C10.1946 4.69177 10.1628 4.75122 10.1498 4.81596C10.1369 4.8807 10.1435 4.94781 10.1688 5.00879C10.1941 5.06978 10.2369 5.12188 10.2918 5.1585C10.3468 5.19512 10.4113 5.2146 10.4773 5.21449H14.944C15.0324 5.21449 15.1172 5.17937 15.1797 5.11685C15.2422 5.05434 15.2773 4.96956 15.2773 4.88115V0.414485C15.2774 0.348601 15.2579 0.284181 15.2214 0.229363C15.1848 0.174543 15.1328 0.131784 15.072 0.106485C15.0109 0.081904 14.944 0.0756865 14.8795 0.088595C14.815 0.101504 14.7556 0.132977 14.7087 0.179152L12.9807 1.90648C12.9557 1.93161 12.9233 1.94808 12.8883 1.95348C12.8532 1.95887 12.8174 1.95292 12.786 1.93648C11.43 1.23414 9.90755 0.916568 8.38384 1.01823Z\" />\n</svg>\n";
export default src;
