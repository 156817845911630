/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isEmpty } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { Popover2 } from '../../../ui/popover'
import Radios from '../../../ui/radios'

export default function SendbackButton ({
  currentUser,
  simulateAction,
  simulationState,
  validations,
  value
}) {
  return (
    <Popover2
      bottom={48}
      right={-85}
      role='dialog'
      trigger={
        <Button
          id='sendback-btn'
          disabled={!isEmpty(validations) || simulationState.processing}
          outline
          mr={2}
        >
          <Trans id='send.back' />
        </Button>
      }
    >
      {hide => (
        <SendbackOptions
          currentStep={value}
          currentUser={currentUser}
          hide={hide}
          simulateAction={simulateAction}
          simulationState={simulationState}
        />
      )}
    </Popover2>
  )
}

const SendbackOptions = ({
  currentStep,
  currentUser,
  hide,
  simulateAction,
  simulationState
}) => {
  const sbOptions = currentStep.sendbackOptions
  const [value, setValue] = React.useState(get(sbOptions, '0.id'))
  return (
    <SendbackOptionsWrapper>
      <SOTitle>
        <div>
          <Trans id='send.back.options' />
        </div>
        <Button
          aria-label={i18n._('close.send.back.option')}
          icon
          transparent
          onClick={() => hide()}
        >
          <Icons.Close />
        </Button>
      </SOTitle>
      <SOLabel id='sb-to'>
        <Trans id='send.back.to' />
      </SOLabel>
      <Radios
        aria-labelledby='sb-to'
        name='sb-options'
        options={sbOptions}
        onChange={id => setValue(id)}
        value={value}
      />
      <Button
        mt={3}
        disabled={simulationState.processing}
        onClick={() => {
          simulateAction(
            'sendback',
            {
              ...simulationState,
              sendBackFrom: currentStep,
              sendBackTo: value
            },
            {
              completedBy: currentUser
            }
          )
          hide()
        }}
      >
        <Trans id='finish' />
      </Button>
    </SendbackOptionsWrapper>
  )
}

const SendbackOptionsWrapper = styled.div`
  width: 350px;
  padding: 25px;
`
const SOTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`
const SOLabel = styled.div`
  font-size: 12px;
  color: var(--medium-gray-500);
  margin-bottom: 4px;
`
