/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isEmpty } from 'lodash'
import React from 'react'

import * as Icons from '../../icons'
import ConfigBox from '../../ui/config-box'
import * as CustomTitle from '../components/custom-title'
import * as EmailBuilder from '../components/email-builder'
import * as PersonPicker from '../components/person-picker'
import * as SectionSettings from '../components/section-settings'
import {
  SimulateButtons,
  SimulateHeader,
  SimulateWrapper
} from '../components/simulator-parts'
import ActionEmail from '../components/simulator-parts/action-email'
import SimulationContext from '../components/simulator-parts/context'
import SimulationError from '../components/simulator-parts/error-page'
import SimulationFormEditor from '../components/simulator-parts/form-editor'

const Node = ({ details, errors }) => (
  <>
    <PersonPicker.View details={details} errors={errors} />
    {details.customNotification?.enabled && (
      <EmailBuilder.View errors={errors} />
    )}
  </>
)

const Config = ({
  appId,
  value,
  updateValue,
  errors,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsGroups,
  fieldsAll,
  formSections,
  workflowSettings
}) => {
  const updateNotification = fn => {
    updateValue(draft => {
      const customNotification = draft.customNotification || {}
      fn(customNotification)
      draft.customNotification = customNotification
    })
  }
  const historyEnabled = !(
    value.disableDocumentHistory ?? workflowSettings.disableDocumentHistory
  )
  return (
    <>
      <CustomTitle.Config
        value={value}
        updateValue={updateValue}
        defaultName='Acknowledge'
        label={i18n._('step.label')}
      />
      <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
      <PersonPicker.Config
        value={value}
        updateValue={updateValue}
        errors={errors}
        fieldsMultiUsers={fieldsMultiUsers}
        fieldsUsers={fieldsUsers}
        fieldsGroups={fieldsGroups}
        title={i18n._('who.acknowledge.this.step')}
      />
      <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
      <EmailBuilder.Config
        toggle
        value={value?.customNotification || {}}
        updateValue={updateNotification}
        errors={errors}
        fieldsAll={fieldsAll}
        defaultSubject='Acknowledge:'
      />
      <SectionSettings.Config
        appId={appId}
        defaultFormToViewOnly={workflowSettings.defaultFormToViewOnly}
        formSections={formSections}
        value={value}
        updateValue={updateValue}
      />
      <ConfigBox
        label={
          workflowSettings.disableDocumentHistory
            ? i18n._('pagesbuilder.workflow.step.override.history.label')
            : i18n._('pagesbuilder.workflow.step.disable.history.label')
        }
        enabled={
          workflowSettings.disableDocumentHistory
            ? historyEnabled
            : !historyEnabled
        }
        save={() => () =>
          updateValue(draft => {
            draft.disableDocumentHistory = !(
              draft.disableDocumentHistory ??
              workflowSettings.disableDocumentHistory
            )
          })
        }
      />
    </>
  )
}

const Simulate = ({
  appName,
  branding,
  form,
  headless,
  imageCache,
  simulateAction,
  simulationState,
  updateImageCache,
  value
}) => {
  const { doc, handleChange, metaFields, template, usedFields, validations } =
    React.useContext(SimulationContext)
  const { assignees, status } = value
  const [currentUser, setCurrentUser] = React.useState()
  if (!currentUser && assignees.length) {
    setCurrentUser(assignees[0])
  }
  const changeUser = newUser => setCurrentUser(newUser.user)
  if (status === 'Notified') {
    return (
      <SimulateWrapper
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <SimulateHeader
          selectedUser={currentUser}
          onCurrentUserChange={changeUser}
          userOptions={assignees}
        />
        <ActionEmail
          actionType='acknowledgement'
          appName={appName}
          branding={branding}
          currentUser={currentUser}
          customNotification={value.customNotification}
          imageCache={imageCache}
          stepName={value.stepName}
          onClick={() => simulateAction('next', simulationState)}
          simulationState={simulationState}
        />
      </SimulateWrapper>
    )
  }
  if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  }
  const buttons = (
    <SimulateButtons>
      <button
        className='kp-button-outline'
        disabled={simulationState.processing}
        id='back-btn'
        onClick={() => simulateAction('back', simulationState)}
      >
        <Trans id='back' />
      </button>
      <button
        className='kp-button-solid'
        disabled={!isEmpty(validations) || simulationState.processing}
        id='default-btn'
        onClick={() =>
          simulateAction('acknowledge', simulationState, {
            completedBy: currentUser
          })
        }
      >
        <Trans id='acknowledge' />
      </button>
    </SimulateButtons>
  )
  return (
    <SimulateWrapper
      buttons={buttons}
      headless={headless}
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <SimulateHeader
        selectedUser={currentUser}
        onCurrentUserChange={changeUser}
        userOptions={assignees}
      />
      <SimulationFormEditor
        currentUser={currentUser}
        doc={doc}
        handleChange={handleChange}
        imageCache={imageCache}
        labelSize={form.labelSize}
        metaFields={metaFields}
        template={template}
        updateImageCache={updateImageCache}
        usedFields={usedFields}
        validations={validations}
      />
    </SimulateWrapper>
  )
}

export default {
  name: 'Acknowledge',
  Icon: Icons.Like,
  color: '#9270c0',
  validate: (value, fieldsAll, lineage) => {
    const validations = [...PersonPicker.validate(value, fieldsAll)]
    if (value?.customNotification?.enabled) {
      const more = EmailBuilder.validate(
        value?.customNotification || {},
        fieldsAll,
        lineage
      )
      return validations.concat(more)
    }
    return validations
  },
  defaultTemplate: () => ({
    ...PersonPicker.defaults(),
    customNotification: { ...EmailBuilder.defaults(), enabled: false }
  }),
  canHaveEditPerms: true,
  Config,
  Node,
  Simulate
}
