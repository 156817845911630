/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import Input from '../../../ui/input'

export default React.forwardRef((props, ref) => (
  <Input
    ref={ref}
    // Prevent the scrollwheel from changing the value
    onWheel={e => e.target.blur()}
    min='0'
    step='0.25'
    width='3rem'
    {...props}
  />
))
