/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { RequiredConfig } from './config'
import Edit from './edit'
import { validateShape } from './validations'
import View from './view'

export default {
  View,
  Edit,
  RequiredConfig,

  validateShape,

  defaultValue: {},

  sampleValue: {},

  meta: {
    label: 'Survey Grid',
    hidden: true
  }
}
