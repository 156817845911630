/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'
import styled from 'styled-components'

import Tooltip, { TooltipTrigger } from '../components/tooltip'
import * as Icons from '../icons'
import { Flex } from './layout'
import Toggle from './toggle'

const ConfigBoxWrapper = styled.div`
  ${p => p.topBorder && 'border-top: 1px solid var(--light-gray-300);'}
  border-bottom: 1px solid var(--light-gray-300);
  background: ${p => (p.enabled ? '#f1faff' : 'var(--white)')};
  html.dark & {
    // Outlier: dark:bg-[#346BA733]
    background: ${p => (p.enabled ? '#346BA733' : 'var(--white)')};
  }
`

const ConfigBoxTitle = styled(Flex)`
  justify-content: space-between;
  padding: 13px 16px;
`

const ConfigBoxChildren = styled.div`
  padding: 0 16px;
`

const BorderlessWrapper = styled.div`
  & ${ConfigBoxTitle} {
    padding: 13px 0;
  }
  & ${ConfigBoxChildren} {
    padding: 0;
  }
`

const ToggleWrapper = styled.div`
  position: relative;
`
const DisabledToggleTooltipTrigger = styled(TooltipTrigger)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export default function ConfigBox ({
  borderless,
  configKey,
  enabled,
  label,
  topBorder,
  disableToggle,
  disableToggleText,
  children,
  description,
  deprecated,
  save
}) {
  const Wrapper = borderless ? BorderlessWrapper : ConfigBoxWrapper
  return (
    <Wrapper enabled={enabled} topBorder={topBorder}>
      <ConfigBoxTitle>
        <Flex>
          {label}
          {description && (
            <>
              <TooltipTrigger
                as={Icons.AlertHelp}
                label={i18n._('ui.configbox.option.info')}
                ml={2}
                tooltipId={`config-box-${configKey}`}
              />
              <Tooltip
                id={`config-box-${configKey}`}
                place='left'
                delayHide={200}
                clickable
              >
                <div style={{ width: 206 }}>{description}</div>
              </Tooltip>
            </>
          )}
          {deprecated && enabled && (
            <>
              <TooltipTrigger
                as={Icons.AlertWarning}
                label={i18n._('ui.configbox.deprecation')}
                ml={2}
                className='fill-red-400'
                tooltipId={`config-box-deprecated-${configKey}`}
              />
              <Tooltip
                id={`config-box-deprecated-${configKey}`}
                place='left'
                delayHide={200}
                clickable
              >
                <div style={{ width: 206 }}>{deprecated}</div>
              </Tooltip>
            </>
          )}
        </Flex>
        <ToggleWrapper>
          <Toggle
            value={enabled}
            disabled={disableToggle}
            onChange={save(configKey)}
            aria-label={label}
            {...(description && {
              'aria-describedby': `config-box-${configKey}`
            })}
          />
          {disableToggle && disableToggleText && (
            <>
              <Tooltip id={`config-box-disabled-${configKey}`} place='left'>
                <div style={{ width: 206 }}>{disableToggleText}</div>
              </Tooltip>
              <DisabledToggleTooltipTrigger
                label={i18n._('ui.configbox.disabled.option')}
                tooltipId={`config-box-disabled-${configKey}`}
              />
            </>
          )}
        </ToggleWrapper>
      </ConfigBoxTitle>
      {enabled && children && <ConfigBoxChildren>{children}</ConfigBoxChildren>}
    </Wrapper>
  )
}
