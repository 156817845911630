/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'

export default styled.span`
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  color: #121212;
  background-color: #ccc;
  border-radius: 2px;
  text-transform: uppercase;
  display: inline-block;
  padding: 3px 8px;
  white-space: nowrap;
`
