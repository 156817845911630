/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { map, reject } from 'lodash'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import * as Icons from '../../icons'
import * as VoronoiDND from '../../voronoi-dnd'

export default ({ steps, beginDrag, endDrag, setA11yDrag, a11yDrag }) => {
  const visibleSteps = reject(
    map(steps, (step, key) => ({ ...step, key })),
    'hidden'
  )
  return (
    <div
      className='dark-mode-shadow rounded bg-white text-sm shadow-sm shadow-medium-gray-400 dark:bg-light-gray-300'
      role='application'
      aria-roledescription={i18n._('add.steps')}
    >
      <div className='bg-light-gray-100 px-4 py-2 text-xs uppercase text-medium-gray-500 dark:bg-light-gray-200'>
        <Trans id='add.steps' />
      </div>
      {map(visibleSteps, step => (
        <React.Fragment key={step.key}>
          {step.helpText && (
            <Tooltip id={`${step.name}-help`} place='right'>
              <div className='w-48'>{step.helpText}</div>
            </Tooltip>
          )}
          <Item
            key={step.key}
            beginDrag={beginDrag}
            endDrag={endDrag}
            setA11yDrag={setA11yDrag}
            a11yDragging={a11yDrag && a11yDrag.type === step.key}
            Icon={step.Icon}
            color={step.color}
            text={step.name}
            dragContext={{ type: step.key }}
            helpText={step.helpText}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

const Item = VoronoiDND.draggable(function (props) {
  return props.connectDragSource(
    <div
      tabIndex={0}
      role='button'
      aria-label={`Add new ${props.text} workflow step`}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          props.setA11yDrag({ type: props.dragContext.type })
        }
      }}
    >
      <div
        className={cx(
          'flex cursor-grab items-center gap-2 px-4 py-2 transition-opacity dark:hover:bg-light-gray-200',
          {
            'opacity-20 dark:opacity-80': props.isDragging || props.a11yDragging
          }
        )}
      >
        <props.Icon fill={props.color} /> {props.text}
        {props.helpText && (
          <>
            <TooltipTrigger
              as={Icons.AlertHelp}
              label={`${props.text} help`}
              tooltipId={`${props.text}-help`}
            />
          </>
        )}
      </div>
    </div>
  )
})
