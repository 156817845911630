/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { padStart } from 'lodash'
import React from 'react'

import { TextView } from '../../gadget-helpers'

export default function ({ value, gridded, condensed }) {
  return <TextView {...{ condensed, gridded }}>{format(value)}</TextView>
}

function format (seconds) {
  if (seconds === null) return ''
  const allMinutes = Math.floor(seconds / 60)
  const hours = Math.floor(allMinutes / 60)
  const minutes = padStart(allMinutes % 60, 2, '0')
  const suffix = hours < 12 ? 'AM' : 'PM'
  return `${hours % 12 || 12}:${minutes} ${suffix}`
}
