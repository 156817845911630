/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import Input from '../../../ui/input'
import { Flex } from '../../../ui/layout'
import { Option, Select } from '../../../ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../../ui/table'
import CREATE_CATEGORY_FIELD_MUTATION from '../gql/mutation.create-category-field'
import DELETE_CATEGORY_FIELD_MUTATION from '../gql/mutation.delete-category-field'
import UPDATE_CATEGORY_FIELD_MUTATION from '../gql/mutation.update-category-field'

export default function BlueprintsFields () {
  const { blueprint, canManageIdentity } = useOutletContext()
  const { fieldSchemas } = blueprint

  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map(col => (
              <TableHeaderCell key={col.id}>{col.label}</TableHeaderCell>
            ))}
            {canManageIdentity && (
              <TableHeaderCell>
                <Trans id='actions' message='Actions' />
              </TableHeaderCell>
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {fieldSchemas?.map(field => (
            <TableRowComponent
              key={field.id}
              blueprint={blueprint}
              field={field}
              canManageIdentity={canManageIdentity}
            />
          ))}
          {canManageIdentity && <EditRow blueprint={blueprint} />}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

const TableRowComponent = ({ blueprint, field, canManageIdentity }) => {
  const [toggleEdit, setToggleEdit] = React.useState(false)
  const [deleteField, { loading: deleteFieldLoading }] = useMutation(
    DELETE_CATEGORY_FIELD_MUTATION
  )
  const alerts = useAlerts()

  if (toggleEdit) {
    return (
      <UpdateRow
        blueprint={blueprint}
        field={field}
        setToggleEdit={setToggleEdit}
      />
    )
  }

  return (
    <TableRow key={field.id}>
      {columns?.map(col => (
        <TableCell key={col.id}>{field[col.id]}</TableCell>
      ))}
      {canManageIdentity && (
        <TableCell>
          <Button
            disabled={deleteFieldLoading}
            transparent
            aria-label={i18n._({ id: 'remove.field', message: 'Remove Field' })}
            icon
            onClick={e => {
              e.stopPropagation()
              deleteField({
                variables: {
                  id: field.id,
                  parentId: blueprint.id
                }
              })
                .then(() =>
                  alerts.type3(
                    i18n._({ id: 'field.removed', message: 'Field removed' }),
                    'success'
                  )
                )
                .catch(err => {
                  Sentry.captureException(err)
                  alerts.type3(
                    i18n._({
                      id: 'could.not.delete.field',
                      message: "Couldn't delete field"
                    }),
                    'error'
                  )
                })
            }}
          >
            {deleteFieldLoading ? <Spinner size={16} /> : <Icons.Delete />}
          </Button>
          <Button
            disabled={deleteFieldLoading}
            transparent
            aria-label={i18n._({ id: 'edit.field', message: 'Edit Field' })}
            icon
            onClick={() => setToggleEdit(!toggleEdit)}
          >
            <Icons.Edit />
          </Button>
        </TableCell>
      )}
    </TableRow>
  )
}

const EditRow = ({ blueprint }) => {
  const [name, setName] = React.useState('')
  const [type, setType] = React.useState('text')
  const [createField, { loading: createFieldLoading }] = useMutation(
    CREATE_CATEGORY_FIELD_MUTATION
  )
  const alerts = useAlerts()

  return (
    <TableRow>
      <TableCell>
        <StyledInput
          value={name}
          placeholder={i18n._({ id: 'field.name', message: 'Field Name' })}
          onChange={setName}
        />
      </TableCell>
      <TableCell>
        <Select defaultValue='text' onChange={setType} darkerModeDarkerBg>
          <Option value='text'>
            <Trans id='text' message='Text' />
          </Option>
          <Option value='textarea'>
            <Trans id='textarea' message='Textarea' />
          </Option>
          <Option value='checkbox'>
            <Trans id='checkbox' message='Checkbox' />
          </Option>
        </Select>
      </TableCell>
      <TableCell>
        <StyledButton
          className='kp-button-solid'
          disabled={createFieldLoading || !name}
          aria-label={i18n._({ id: 'add.field', message: 'Add Field' })}
          onClick={() =>
            createField({
              variables: {
                name,
                type,
                categoryId: blueprint.id
              }
            })
              .then(() => {
                alerts.type3(
                  i18n._({ id: 'field.added', message: 'Field added' }),
                  'success'
                )
                setName('')
              })
              .catch(err => {
                alerts.type3(
                  i18n._({
                    id: 'could.not.create.field',
                    message: "Couldn't create field"
                  }),
                  'error'
                )
                Sentry.captureException(err)
              })
          }
        >
          {createFieldLoading ? (
            <Spinner size={16} />
          ) : (
            <Trans id='create' message='Create' />
          )}
        </StyledButton>
      </TableCell>
    </TableRow>
  )
}

const UpdateRow = ({ blueprint, field, setToggleEdit }) => {
  const [name, setName] = React.useState(field.name)
  const [type, setType] = React.useState(field.type)
  const [updateField, { loading: updateFieldLoading }] = useMutation(
    UPDATE_CATEGORY_FIELD_MUTATION
  )
  const alerts = useAlerts()

  return (
    <TableRow>
      <TableCell>
        <StyledInput
          autoFocus
          value={name}
          placeholder={i18n._({ id: 'field.name', message: 'Field Name' })}
          onChange={setName}
        />
      </TableCell>
      <TableCell>
        <Select
          defaultValue={type ?? 'text'}
          onChange={setType}
          darkerModeDarkerBg
        >
          <Option value='text'>
            <Trans id='text' message='Text' />
          </Option>
          <Option value='textarea'>
            <Trans id='textarea' message='Textarea' />
          </Option>
          <Option value='checkbox'>
            <Trans id='checkbox' message='Checkbox' />
          </Option>
        </Select>
      </TableCell>
      <TableCell>
        <Flex>
          <StyledButton
            className='kp-button-outline mr-2'
            disabled={updateFieldLoading}
            onClick={() => setToggleEdit(toggleEdit => !toggleEdit)}
          >
            <Trans id='cancel' message='Cancel' />
          </StyledButton>
          <StyledButton
            className='kp-button-solid'
            disabled={updateFieldLoading || !name}
            onClick={() =>
              updateField({
                variables: {
                  parentId: blueprint.id,
                  id: field.id,
                  data: {
                    name,
                    type
                  }
                }
              })
                .then(() => {
                  setName('')
                  setToggleEdit(toggleEdit => !toggleEdit)
                  alerts.type3(
                    i18n._({ id: 'field.updated', message: 'Field updated' }),
                    'success'
                  )
                })
                .catch(err => {
                  alerts.type3(
                    i18n._({
                      id: 'could.not.update.field',
                      message: "Couldn't update field"
                    }),
                    'error'
                  )
                  Sentry.captureException(err)
                })
            }
          >
            {updateFieldLoading ? (
              <Spinner size={16} />
            ) : (
              <Trans id='save' message='Save' />
            )}
          </StyledButton>
        </Flex>
      </TableCell>
    </TableRow>
  )
}

const TableWrapper = styled.div`
  padding: 32px 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  max-width: 64vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  table {
    width: initial;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid var(--light-gray-300);
    height: 52px;
  }

  @media (max-width: 768px) {
    max-width: 99vw;
  }
`

const StyledInput = styled(Input)`
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledButton = styled.button`
  width: 75px;
`

const columns = [
  { id: 'name', label: <Trans id='field.name' message='Field Name' /> },
  {
    id: 'type',
    label: <Trans id='type' message='Type' />
  }
]
