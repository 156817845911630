/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import React from 'react'

export const useUpdatePageMutation = (appId, pageId) => {
  const [updatePage] = useMutation(mutation)
  return React.useCallback(
    params => updatePage(getParams(appId, pageId, params)),
    [updatePage, appId, pageId]
  )
}

const mutation = gql`
  mutation UpdateAppPage(
    $appId: ID!
    $pageId: ID!
    $params: PbUpdatePageInput!
  ) {
    updatePage(appId: $appId, pageId: $pageId, params: $params) {
      id
      pages {
        id
        label {
          value
          locked
        }
        icon {
          value
          locked
        }
        details
        orderLocked
        removeLocked
      }
      maintenanceTables {
        id
        label {
          value
          locked
        }
        icon {
          value
          locked
        }
        details
        orderLocked
        removeLocked
      }
    }
  }
`

const getParams = (appId, pageId, params) => {
  return {
    variables: { appId, pageId, params }
  }
}
