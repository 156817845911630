/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery } from '@apollo/client'
import { map } from 'lodash'
import React from 'react'

import * as Lookups from '../../../components/lookup-controls'
import useDebouncedValue from '../../../components/use-debounced-value'

export default function RoleMultiselect ({ id, value = [], ...props }) {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const [fetch, { data, loading }] = useLazyQuery(roleSearchQuery)

  React.useEffect(() => {
    fetch({ variables: { query: debouncedQuery } })
  }, [debouncedQuery, fetch])
  const options = formatRoles2(data)
  const onChange = role => {
    props.onChange([...value, role])
  }
  return (
    <Lookups.Multiselect
      id={id}
      options={options}
      value={value}
      query={query}
      setQuery={setQuery}
      onChange={onChange}
      aria={{
        labelledby: props['aria-labelledby'],
        describedby: props['aria-describedby']
      }}
      loading={loading}
      {...props}
    />
  )
}

const formatRoles2 = data => {
  if (!data) return []
  const roles = map(data.roles, ({ id2, label, group }) => ({
    id: `${group.id}::${id2}`,
    label: `${group.label} - ${label}`,
    role: { id: id2, label },
    group: { id: group.id, label: group.label }
  }))
  return roles
}

const roleSearchQuery = gql`
  query RoleSearch($query: String!) {
    roles(args: { excludeAppBuilderGroups: true, query: $query }) {
      id
      id2: roleId
      label: name
      group {
        id
        label: name
      }
    }
  }
`
