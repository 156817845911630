/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M10.7913 2.34067C10.7277 2.28037 10.6433 2.24677 10.5556 2.24677C10.468 2.24677 10.3836 2.28037 10.32 2.34067L2.7113 9.94934C2.64881 10.0118 2.61371 10.0966 2.61371 10.185C2.61371 10.2734 2.64881 10.3582 2.7113 10.4207L5.56464 13.274C5.62715 13.3365 5.71192 13.3716 5.8003 13.3716C5.88869 13.3716 5.97346 13.3365 6.03597 13.274L13.6446 5.66667C13.7071 5.60416 13.7422 5.51939 13.7422 5.43101C13.7422 5.34262 13.7071 5.25785 13.6446 5.19534L10.7913 2.34067Z`}/><path d={`M1.99999 11.14C1.9596 11.0998 1.90961 11.0707 1.85477 11.0553C1.79993 11.04 1.74207 11.039 1.68672 11.0523C1.63137 11.0657 1.58037 11.093 1.53857 11.1317C1.49678 11.1704 1.4656 11.2191 1.44799 11.2733L0.0213213 15.5533C0.00163538 15.612 -0.00130739 15.675 0.0128229 15.7353C0.0269532 15.7956 0.057598 15.8508 0.101321 15.8946C0.145433 15.938 0.200562 15.9684 0.260732 15.9826C0.320902 15.9968 0.383816 15.9943 0.442655 15.9753L4.72265 14.5493C4.77694 14.5317 4.8258 14.5005 4.86458 14.4587C4.90336 14.4169 4.93075 14.3658 4.94413 14.3103C4.9575 14.2549 4.95643 14.1969 4.94099 14.142C4.92556 14.087 4.89629 14.037 4.85599 13.9966L1.99999 11.14Z`}/><path d={`M15.312 0.678021C14.864 0.250587 14.2686 0.0121155 13.6494 0.0121155C13.0301 0.0121155 12.4347 0.250587 11.9867 0.678021L11.512 1.15335C11.4495 1.21586 11.4144 1.30063 11.4144 1.38902C11.4144 1.47741 11.4495 1.56218 11.512 1.62469L14.3647 4.47802C14.4272 4.54051 14.512 4.57562 14.6004 4.57562C14.6887 4.57562 14.7735 4.54051 14.836 4.47802L15.312 4.00002C15.7515 3.55894 15.9982 2.96167 15.9982 2.33902C15.9982 1.71638 15.7515 1.11911 15.312 0.678021Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M10.7913 2.34067C10.7277 2.28037 10.6433 2.24677 10.5556 2.24677C10.468 2.24677 10.3836 2.28037 10.32 2.34067L2.7113 9.94934C2.64881 10.0118 2.61371 10.0966 2.61371 10.185C2.61371 10.2734 2.64881 10.3582 2.7113 10.4207L5.56464 13.274C5.62715 13.3365 5.71192 13.3716 5.8003 13.3716C5.88869 13.3716 5.97346 13.3365 6.03597 13.274L13.6446 5.66667C13.7071 5.60416 13.7422 5.51939 13.7422 5.43101C13.7422 5.34262 13.7071 5.25785 13.6446 5.19534L10.7913 2.34067Z\" />\n<path d=\"M1.99999 11.14C1.9596 11.0998 1.90961 11.0707 1.85477 11.0553C1.79993 11.04 1.74207 11.039 1.68672 11.0523C1.63137 11.0657 1.58037 11.093 1.53857 11.1317C1.49678 11.1704 1.4656 11.2191 1.44799 11.2733L0.0213213 15.5533C0.00163538 15.612 -0.00130739 15.675 0.0128229 15.7353C0.0269532 15.7956 0.057598 15.8508 0.101321 15.8946C0.145433 15.938 0.200562 15.9684 0.260732 15.9826C0.320902 15.9968 0.383816 15.9943 0.442655 15.9753L4.72265 14.5493C4.77694 14.5317 4.8258 14.5005 4.86458 14.4587C4.90336 14.4169 4.93075 14.3658 4.94413 14.3103C4.9575 14.2549 4.95643 14.1969 4.94099 14.142C4.92556 14.087 4.89629 14.037 4.85599 13.9966L1.99999 11.14Z\" />\n<path d=\"M15.312 0.678021C14.864 0.250587 14.2686 0.0121155 13.6494 0.0121155C13.0301 0.0121155 12.4347 0.250587 11.9867 0.678021L11.512 1.15335C11.4495 1.21586 11.4144 1.30063 11.4144 1.38902C11.4144 1.47741 11.4495 1.56218 11.512 1.62469L14.3647 4.47802C14.4272 4.54051 14.512 4.57562 14.6004 4.57562C14.6887 4.57562 14.7735 4.54051 14.836 4.47802L15.312 4.00002C15.7515 3.55894 15.9982 2.96167 15.9982 2.33902C15.9982 1.71638 15.7515 1.11911 15.312 0.678021Z\" />\n</svg>\n";
export default src;
