/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useImpersonateMutation = userId => {
  const [impersonate] = useMutation(mutation)
  return () => impersonate(getParams(userId))
}

const mutation = gql`
  mutation Impersonate($userId: ID!) {
    impersonate(userId: $userId)
  }
`

const getParams = userId => ({ variables: { userId } })
