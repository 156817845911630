/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { filter } from 'lodash'
import React from 'react'

import * as Affiliations from '../../../components/lookups/affiliations'
import * as Groups from '../../../components/lookups/groups'
import * as Roles from '../../../components/lookups/roles'
import * as Users from '../../../components/lookups/users'
import { useAddIdentityMutation } from './mutation.add-identity-to-role'
import { useRemoveIdentityMutation } from './mutation.remove-identity-from-role'

export function UserManager ({ id, label, group, leaveLast }) {
  const users = filter(group.identities, { type: 'USER' })
  const removeIdentity = useRemoveIdentityMutation(group.id)
  const addIdentity = useAddIdentityMutation(group.id)
  return (
    <div>
      <h4>{label}</h4>
      <Users.Multiselect
        standalone
        id={id}
        noDarkerModeDarkerBg
        placeholder={i18n._('pagesbuilder.member.add.user')}
        onAdd={obj => addIdentity({ ...obj, type: 'USER' })}
        onRemove={id => {
          if (leaveLast && users.length < 2) return
          removeIdentity(id)
        }}
        value={users}
      />
    </div>
  )
}

export function GroupManager ({ id, label, group }) {
  const groups = filter(group.identities, { type: 'GROUP' })
  const removeIdentity = useRemoveIdentityMutation(group.id)
  const addIdentity = useAddIdentityMutation(group.id)
  return (
    <div>
      <h4>{label}</h4>
      <Groups.Multiselect
        standalone
        noDarkerModeDarkerBg
        id={id}
        placeholder={i18n._('pagesbuilder.member.add.group')}
        onAdd={obj => addIdentity({ ...obj, type: 'GROUP' })}
        onRemove={removeIdentity}
        value={groups}
      />
    </div>
  )
}

export function RoleManager ({ id, label, group }) {
  const roles = filter(group.identities, { type: 'ROLE' })
  const removeIdentity = useRemoveIdentityMutation(group.id)
  const addIdentity = useAddIdentityMutation(group.id)
  return (
    <div>
      <h4>{label}</h4>
      <Roles.Multiselect
        standalone
        noDarkerModeDarkerBg
        id={id}
        placeholder={i18n._('pagesbuilder.member.add.role')}
        onAdd={obj => addIdentity({ ...obj, type: 'ROLE' })}
        onRemove={removeIdentity}
        value={roles}
      />
    </div>
  )
}

const userAffiliationsQuery = gql`
  query UserAffiliations {
    userAffiliations {
      orgDN: orgDn
      affiliation
    }
  }
`

export function AffiliationManager ({ id, label, group }) {
  const { data, loading } = useQuery(userAffiliationsQuery, {
    fetchPolicy: 'network-only'
  })
  const removeIdentity = useRemoveIdentityMutation(group.id)
  const addIdentity = useAddIdentityMutation(group.id)
  const selected = filter(group.identities, { type: 'AFFILIATION' })
  const affiliations = data?.userAffiliations ?? []
  const hasAffiliations = !loading && affiliations.length > 0
  if (!hasAffiliations && selected.length === 0) return null
  return (
    <div>
      <h4>{label}</h4>
      {hasAffiliations && (
        <Affiliations.Multiselect
          standalone
          noDarkerModeDarkerBg
          id={id}
          affiliations={data?.userAffiliations}
          placeholder={i18n._('pagesbuilder.member.add.affiliation')}
          onAdd={obj => addIdentity({ ...obj, type: 'AFFILIATION' })}
          onRemove={removeIdentity}
          value={selected}
        />
      )}
    </div>
  )
}
