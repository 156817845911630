/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'
import { VisuallyHidden } from '../../ui/a11y'
import Button from '../../ui/button'
import Input from '../../ui/input'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: ${p => (p.small ? '190' : '370')}px;
  max-width: 100%;
  margin-right: ${p => (p.small ? '0' : '8')}px;
  @media (max-width: ${p => p.collapse}px) {
    margin-bottom: 8px;
  }
`

const SearchIcon = styled(Icons.Search)`
  position: absolute;
  top: 8px;
  left: 8px;
`

const Input2 = styled(Input)`
  background: ${p => (p.small ? 'white' : '#f6f7f8')};
  padding-left: 32px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: ${p => (p.small ? '' : 'none')};
  width: ${p => (p.small ? 'calc(100% - 8px)' : 'calc(100% - 54px)')};
  html.dark & {
    // Outlier: dark:border-medium-gray-200
    border-color: var(--medium-gray-200);
  }
`

const CloseButton = styled(Button)`
  position: absolute;
  right: ${p => (p.small ? '12' : '54')}px;
  top: ${p => (p.small ? '4' : '0')}px;
  &:hover {
    background: none;
  }
  &:active {
    border-color: transparent;
  }
`

const SmallSearchInput = styled(Input2)`
  border-radius: 4px;
`

const GoButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`

export default React.forwardRef(({ reset, ...props }, ref) => (
  <Wrapper collapse={props.collapse}>
    <SearchIcon className='fill-dark-gray-100 dark:fill-medium-gray-300' />
    <Input2
      ref={ref}
      aria-label={i18n._('search')}
      placeholder={i18n._('search')}
      {...props}
    />
    {props.value && (
      <CloseButton transparent icon onClick={reset}>
        <VisuallyHidden>
          <Trans id='clear.search' />
        </VisuallyHidden>
        <Icons.Close
          width={10}
          height={10}
          className='fill-dark-gray-100 dark:fill-medium-gray-300'
        />
      </CloseButton>
    )}
    <GoButton outline onClick={props.onBlur}>
      <Trans id='go' message='GO' />
    </GoButton>
  </Wrapper>
))

export const SmallSearch = React.forwardRef(({ reset, ...props }, ref) => (
  <Wrapper collapse={props.collapse}>
    <SearchIcon className='fill-dark-gray-100 dark:fill-medium-gray-300' />
    <SmallSearchInput
      onKeyDown={e => e.key === 'Escape' && reset?.()}
      small='true'
      ref={ref}
      aria-label={i18n._('search')}
      placeholder={i18n._('search')}
      {...props}
    />
    {props.value && (
      <CloseButton small transparent icon onClick={reset}>
        <VisuallyHidden>
          <Trans id='clear.search' />
        </VisuallyHidden>
        <Icons.Close
          width={10}
          height={10}
          className='fill-dark-gray-100 dark:fill-medium-gray-300'
        />
      </CloseButton>
    )}
  </Wrapper>
))
