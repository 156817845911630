/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get, includes, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Calculation from '../../../pages-builder/calculations/calculation-components'
import { toDollars } from '../../../pages-builder/calculations/calculation-functions'
import Input from '../../../ui/input'
import { useFormbotData } from '../../engine/formbot-react/hooks'

export default function CurrencyEdit (props) {
  const formbotData = useFormbotData()

  const { context, details, formKey, gridded, id, onChange, value } = props
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const isCalculation = details?.calculation?.enabled
  const Component = gridded ? MyInput : Input
  const validations = get(context, ['validations', formKey], [])
  return (
    <>
      {isCalculation ? (
        <Calculation
          gridded={gridded}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          formbotData={formbotData}
          details={details}
          {...props}
          type='Currency'
        />
      ) : (
        <Component
          aria-labelledby={props['aria-labelledby']}
          {...(props['aria-describedby'] && {
            'aria-describedby': props['aria-describedby']
          })}
          aria-required={props.required}
          id={id}
          onChange={rawVal => {
            if (rawVal === '-') return onChange(-0)
            const isNegative = rawVal.startsWith('-') || rawVal.startsWith('$-')
            const val = rawVal.replace(/[^\d]/g, '')
            if (val === '') return onChange(null)
            if (includes(['0', '00'], val) && value === 0) return onChange(null)
            const multiplier = isNegative ? -1 : 1
            const number = parseInt(val, 10) * multiplier
            if (number > Number.MAX_SAFE_INTEGER) return
            if (!isNaN(number)) return onChange(number)
          }}
          placeholder={placeholder}
          value={toDollars(value) ?? ''}
        />
      )}
      {map(validations, message => (
        <Error className='text-red-500'>{message}</Error>
      ))}
    </>
  )
}

const MyInput = styled(Input)`
  border: none;
  outline: none;
  padding: 0 16px 16px 16px;
  background: none;
  width: 100%;
  text-align: right;
  html.dark & {
    // Outlier: dark:bg-light-gray-400
    background: #444;
  }
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`
