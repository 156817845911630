/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Checkbox from '../../../../ui/checkbox'
import Input from '../../../../ui/input'
import Radios from '../../../../ui/radios'

export const types = {
  all: ['*'],
  docs: ['.pdf', '.docx', '.txt', '.pptx', '.xlsx'],
  images: ['image/*'],
  videos: ['video/*']
}

// This could be memoized
export const getAccepts = value => {
  const values = value instanceof Array ? value : [value]
  return values
    .reduce((accepts, type) => accepts.concat(types[type]), [])
    .join(',')
}

// TODO this could be memoized
const getRootValue = value => {
  /* istanbul ignore next */
  if (!value) return null
  switch (value) {
    case 'all':
    case 'custom':
      return value
    default:
      return 'selected'
  }
}

// TODO this could be memoized
const getSelectedValues = value => {
  return value instanceof Array
    ? value.reduce((selected, value) => {
        selected[value] = true
        return selected
      }, {})
    : {}
}

export default function FileTypePicker ({
  id,
  label,
  onChange,
  value = 'all',
  customValue = ''
}) {
  const handleRootChange = val => {
    switch (val) {
      case 'custom':
        return onChange({ value: 'custom', customValue })
      case 'selected':
        return onChange({ value: [], customValue: null })
      default:
        // 'all'
        return onChange({ value: 'all', customValue: null })
    }
  }

  const handleSelectedChange = (checked, e) => {
    e.stopPropagation()
    const name = e.target.name
    let selected =
      value instanceof Array
        ? value.slice()
        : /* istanbul ignore next: Should only happen if value goes from "all" or "custom" to one of the "selected" options */ []
    selected = checked
      ? selected.concat(name)
      : selected.filter(item => item !== name)
    onChange({ value: selected, customValue: null })
  }

  const handleCustomChange = (value, e) => {
    onChange({ value: 'custom', customValue: value })
  }

  const rootValue = getRootValue(value)
  const selectedValues = getSelectedValues(value)
  return (
    <MyRadios
      id={id}
      name={id}
      options={[
        {
          id: 'all',
          label: <Trans id='acept.all.file.types' />
        },
        {
          id: 'selected',
          label: (
            <div>
              <div>
                <Trans id='only.accept.selected.file.types' />
              </div>
              {rootValue === 'selected' && (
                <div data-testid='selected-options'>
                  <Checkbox
                    id={`${id}--docs`}
                    name='docs'
                    label={i18n._('documents.pdf.docx')}
                    checked={Boolean(selectedValues.docs)}
                    onChange={handleSelectedChange}
                  />
                  <Checkbox
                    id={`${id}--images`}
                    name='images'
                    label={i18n._('images.png.jpg')}
                    checked={Boolean(selectedValues.images)}
                    onChange={handleSelectedChange}
                  />
                  <Checkbox
                    id={`${id}--videos`}
                    name='videos'
                    label={i18n._('videos.mov.mp4')}
                    checked={Boolean(selectedValues.videos)}
                    onChange={handleSelectedChange}
                  />
                </div>
              )}
            </div>
          ),
          ariaLabel: <Trans id='only.accept.selected.file.types' />
        },
        {
          id: 'custom',
          label: (
            <div style={{ position: 'relative' }}>
              <div>
                <Trans id='custom.accept.value' />
              </div>
              {rootValue === 'custom' && (
                <Input
                  id={`${id}--custom-value`}
                  data-testid='custom-value-field'
                  placeholder='e.g. image/*,.txt'
                  stopPropagation
                  width='100%'
                  value={customValue || ''}
                  onChange={handleCustomChange}
                />
              )}
            </div>
          ),
          ariaLabel: <Trans id='custom.accept.value' />
        }
      ]}
      value={rootValue}
      onChange={handleRootChange}
    />
  )
}

const MyRadios = styled(Radios)`
  margin: 12px;
`
