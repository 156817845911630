/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const colors = [
  '#E43935',
  '#50b189',
  '#F7BD1B',
  '#A4D835',
  '#6CC459',
  '#EF6C05',
  '#1697A6',
  '#468DCB',
  '#465BCB',
  '#7146CB',
  '#A173FF',
  '#E373FF',
  '#CB3E96'
]

export default name => {
  const charCode =
    name
      .split('')
      .map(l => l.charCodeAt())
      .reduce((acc, val) => acc + val, 0) % colors.length

  return colors[charCode]
}
