/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as KualiK } from '../../../components/logo-grey.svg.jsx'
import { CalcFooter } from '../../../pages-builder/calculations/calculation-components'
import calculate from '../../../pages-builder/calculations/calculation-functions'
import { EditCellWrapper, InnerTh, Td, Th } from '../table/ui'
import * as utils from '../table/utils'

export default function Edit ({ details, fbRenderEdit, context, id }) {
  const {
    gadget,
    handleChange,
    value,
    showFooter,
    footerCalcFunctions,
    parentFormKey
  } = details

  const calcFunction = footerCalcFunctions?.[details.gadget?.id]
  const isCalculation = details.gadget?.details?.calculation?.enabled
  const canonicalGadget = context.canonicalGadgetsMap[id]
  return (
    <Inner
      gadget={gadget}
      value={value}
      labelSize={context?.labelSize}
      showFooter={showFooter}
      calcFunction={calcFunction}
      isCanonicalGadget={!!canonicalGadget}
    >
      {(row, i) => (
        <StyledTd
          key={row._rowId}
          ReadOnlyData
          as='div'
          style={{
            height: `${100 / (value.length - 1)}%`,
            background: isCalculation && '#f7f7f7'
          }}
        >
          <EditCellWrapper>
            {fbRenderEdit(
              gadget.type,
              get(row, gadget.formKey),
              {
                ...gadget.details,
                disableDND: true,
                rowId: row._rowId,
                rowIndex: i,
                parentFormKey,
                parentValue: value,
                validations: gadget.validations
              },
              handleChange.bind(null, i, gadget.formKey),
              `${i}.${gadget.formKey}`,
              `${parentFormKey}.${i}.${gadget.formKey}`
            )}
          </EditCellWrapper>
        </StyledTd>
      )}
    </Inner>
  )
}

export function Inner ({
  gadget = {},
  value,
  labelSize,
  children,
  showFooter,
  calcFunction,
  isCanonicalGadget
}) {
  const rowsToRender = map(value.slice(1), (row, i) => children(row, i + 1))

  return (
    <StyledTableColumn className='formbot-table-column'>
      <Th as='div' className='relative'>
        <InnerTh>
          <span
            className={cx(
              { 'required-field': gadget.required },
              'overflow-hidden text-ellipsis'
            )}
            title={gadget.label}
          >
            {gadget.label}
          </span>
          <utils.Description
            description={gadget.description}
            id={gadget.id}
            labelSize={labelSize}
          />
          {isCanonicalGadget && (
            <KualiK className='canonical-gadget-k absolute right-[10px] top-3 h-3 w-3' />
          )}
        </InnerTh>
      </Th>
      {/* Th is 37px tall. We want the body to be 100% minus that.  If showFooter is true, subtract another 48px for the footer. */}
      <div
        style={{
          height: `calc(100% - ${showFooter ? 85 : 37}px)`
        }}
      >
        {rowsToRender}
      </div>
      {showFooter && (
        <TableColumnFooter
          value={value}
          calcFunction={calcFunction}
          gadget={gadget}
        />
      )}
    </StyledTableColumn>
  )
}

const TableColumnFooter = ({ value, calcFunction, gadget }) => {
  const columnValues = value?.map(row => {
    return row[gadget.formKey]
  })
  const total = calculate[calcFunction]?.(columnValues)
  return (
    <CalcFooter
      value={value}
      type={gadget?.type}
      total={total}
      calcFunction={calcFunction}
      mode='config'
    />
  )
}

// TODO:: Consider writing image snapshot tests against a Table Gadget rendered
// in both config and edit mode
// https://github.com/americanexpress/jest-image-snapshot

const StyledTd = styled(Td)``

const StyledTableColumn = styled.div`
  height: 100%;
`
