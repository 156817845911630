/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import _ from 'lodash'
import React from 'react'

import {
  AggregationDropdown,
  GadgetDropdown,
  Help,
  Label
} from '../../components'

const facetGadgets = [
  'Radios',
  'Dropdown',
  'WorkflowStatus',
  'IntegrationTypeahead',
  'GroupTypeahead',
  'UserTypeahead',
  'FormTypeahead'
]

export default function Config ({ details, setDetails, loading, gadgets }) {
  return (
    <>
      <div>
        <Label>
          <Trans id='pagesbuilder.dashboard.faceted' />
          <Help>
            <Trans id='pagesbuilder.dashboard.faceted.help' />
          </Help>
        </Label>
        <GadgetDropdown
          gadgets={_.filter(gadgets, g => facetGadgets.includes(g.type))}
          value={details.facetKey}
          onChange={facetKey => setDetails(d => ({ ...d, facetKey }))}
        />
      </div>

      <AggregationDropdown
        value={details.aggregation}
        onChange={aggregation => setDetails(d => ({ ...d, aggregation }))}
      />

      {!!details.aggregation && details.aggregation !== 'count' && (
        <GadgetDropdown
          gadgets={_.filter(gadgets, { type: 'Number' })}
          value={details.formKey}
          onChange={formKey => setDetails(d => ({ ...d, formKey }))}
        />
      )}
    </>
  )
}
