/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// NOTE: imported separately for easier mocking in tests
import { get, isEqual, map } from 'lodash'
import debounce from 'lodash/debounce'
import React from 'react'
import styled from 'styled-components'

import Input from '../../../ui/input'

export default class TextEdit extends React.Component {
  constructor (props) {
    super(props)

    this.state = { lastUpdate: props.value, text: props.value }
    this.debouncedOnChange = debounce(val => {
      this.setState({ lastUpdate: val })
      this.props.onChange(val)
    }, 750)
  }

  UNSAFE_componentWillReceiveProps ({ value }) {
    if (this.state.lastUpdate === value) {
      // NOTE: lastUpdate is important because our onChange prop
      // will often set new state upstream in consumer applications,
      // which will then provide a new value prop back down through our
      // component tree. We ensure we don't re-render a stale value this
      // way when a user is typing quickly!
      return
    }

    if (this.state.text !== value) {
      this.setState({ text: value })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      this.props.value === nextProps.value &&
      get(this, 'state.text') === get(nextState, 'text') &&
      this.props.error === nextProps.error &&
      isEqual(this.props.context, nextProps.context) &&
      isEqual(this.props.details, nextProps.details)
    ) {
      return false
    }
    return true
  }

  handleChange = text => {
    this.debouncedOnChange(text)
    this.setState({ text })
  }

  render () {
    const { context, details, formKey, gridded, id, required } = this.props
    const placeholder =
      details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
    const Component = gridded ? MyInput : Input
    const validations = get(context, ['validations', formKey], [])
    return (
      <>
        <Component
          aria-labelledby={this.props['aria-labelledby']}
          {...(this.props['aria-describedby'] && {
            'aria-describedby': this.props['aria-describedby']
          })}
          aria-required={required}
          id={id}
          onChange={this.handleChange}
          placeholder={placeholder}
          value={this.state.text ?? undefined}
        />
        {map(validations, message => (
          <Error className='text-red-500'>{message}</Error>
        ))}
      </>
    )
  }
}

const MyInput = styled(Input)`
  border: none;
  outline: none;
  padding: 0 16px 16px 16px;
  background: none;
  width: 100%;
  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`
