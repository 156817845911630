/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'

export default gql`
  query GetCategories($query: String) {
    categoriesConnection(args: { limit: 200, query: $query }) {
      categories {
        id
        name
        parentId
        groupCount
        roleSchemas {
          id
          name
          description
        }
        fieldSchemas {
          id
          name
          type
        }
      }
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        skip
        limit
      }
      totalCount
    }
  }
`
