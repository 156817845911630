/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

const breakpoints = Object.values({
  phone: '400px',
  tablet: '768px',
  desktop: '1024px',
  widescreen: '1440px'
})

export default {
  breakpoints,
  colors: {
    black: '#1a1a1a'
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512]
}
