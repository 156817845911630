/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  compact,
  filter,
  flatMap,
  flow,
  get,
  keyBy,
  mapValues,
  reject
} from 'lodash'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import ColumnsButton from '../../components/data-table/columns-button'
import ExportButton from '../../components/data-table/export-button'
import Header from '../../components/data-table/header'
import Pagination from '../../components/data-table/pagination'
import Pills from '../../components/data-table/pills'
import SearchBar from '../../components/data-table/search-bar'
import SortButton from '../../components/data-table/sort-button'
import { filtersOnDocList } from '../../components/feature-flags'
import { LoadingPage } from '../../components/loading'
import Spinner from '../../components/spinner'
import { GraphQLError as Error } from '../../components/system-error'
import { useTileTransition } from '../../components/tile-transition'
import { useDocumentTitle } from '../../components/use-document-title'
import { useIds } from '../../components/use-ids'
import { formbot, gadgets } from '../../formbot'
import {
  FormbotContext,
  IdFormkeyMapContext
} from '../../formbot/engine/formbot-react/hooks'
import { traverseTemplate } from '../../formbot/engine/formbot/utils'
import FiltersConfig from '../../formbot/filter-config'
import * as Icons from '../../icons'
import { useInitWorkflow } from '../../pages-runner/run/components/mutation.init-workflow'
import { Announcer } from '../../ui/a11y'
import { Table, TableBody } from '../../ui/table'
import AnimatedOutlet from '../app-modals-outlet'
import * as Empty from './components/empty'
import localizeLabel from './components/localize-label'
import Row from './components/row'
import ShareButton from './components/share-button'
import {
  gqlToParams,
  useLoadById,
  useQueryParams,
  useSetDefaults
} from './components/use-params'
import { QueryContextProvider } from './components/use-query-context'
import Views from './components/views'

export default function ListById () {
  useDocumentTitle('Documents')
  const [params, storageKey] = useLoadById()
  if (!params) return <LoadingPage />
  return <List defaultParams={params} storageKey={storageKey} />
}

function List ({ defaultParams, storageKey }) {
  const previousDataRef = React.useRef(null)
  const { appId, datasetId } = useIds()
  const { finish } = useTileTransition()
  const queryParams = useQueryParams(defaultParams, storageKey)
  const q = getListPageQuery(appId, datasetId, queryParams.gqlParams)
  const { query, ...qParams } = q
  const gqlResult = useQuery(query, qParams)
  previousDataRef.current = gqlResult?.data?.app || previousDataRef.current
  const app = previousDataRef.current
  const switchingDatasets =
    app?.id !== appId || (datasetId && app?.dataset?.id !== datasetId)
  useSetDefaults(queryParams.updateParams, app?.dataset)
  React.useEffect(() => {
    if (app) finish()
  }, [app])
  if (!app || switchingDatasets) return <LoadingPage />
  if (gqlResult.error) return <Error error={gqlResult.error} />
  return (
    <QueryContextProvider query={q}>
      <AnimatedOutlet
        context={{
          app,
          reset: queryParams.reset,
          refetch: gqlResult.refetch
        }}
      />
      <FormbotContext.Provider value={formbot}>
        <IdFormkeyMapContext.Provider
          value={buildRepeatableColumnIdMap(app.dataset.form.gadgetIndexTypes)}
        >
          <ListInner
            queryParams={queryParams}
            gqlResult={gqlResult}
            app={app}
          />
        </IdFormkeyMapContext.Provider>
      </FormbotContext.Provider>
    </QueryContextProvider>
  )
}

function buildRepeatableColumnIdMap (gadgetIndexTypes) {
  const repeatableGadgets = filter(gadgetIndexTypes, g =>
    ['Repeater', 'Table'].includes(g?.gadgetType)
  )
  const repeatableColumnGadgets = flatMap(repeatableGadgets, g => {
    if (g.gadgetType === 'Table') {
      return (
        g.childrenTemplate?.map(g2 => ({
          id: `data.${g2.id}`,
          formKey: `data.${g.formKey}.*.${g2.formKey}`
        })) ?? []
      )
    }

    const children = []
    traverseTemplate({ children: g.childrenTemplate }, g2 => {
      children.push({
        id: `data.${g2.id}`,
        formKey: `data.${g.formKey}.data.*.data.${g2.formKey}`
      })
    })
    return children
  })
  return mapValues(keyBy(repeatableColumnGadgets, 'id'), 'formKey')
}

function ListInner ({ queryParams, gqlResult, app }) {
  const initWorkflow = useInitWorkflow()
  const navigate = useNavigate()
  const { appId, datasetId, routes } = useIds()
  const { params, gqlParams, updateParams, reset } = queryParams
  const [queryInput, setQueryInput] = React.useState(params.query || '')
  const [activeView, setActiveView] = React.useState('')
  const [newAppLoading, setNewAppLoading] = React.useState(false)
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false)
  const runUrl = routes.run()

  if (
    app.dataset.documentConnection.totalCount === 0 &&
    !params.query &&
    (filtersOnDocList
      ? !params.filter?.operators?.length
      : !params.filters.length)
  ) {
    return (
      <Empty.NoResults
        formUrl={
          app.viewer.canSubmitDocuments && app.dataset.isPublished
            ? runUrl
            : null
        }
      />
    )
  }
  const visibleColumns = flow(
    a => compact(a),
    a => filter(a, 'visible')
  )(localizeLabels(params.columns))

  function localizeLabels (columns) {
    return columns.map(column => {
      return {
        ...column,
        label: localizeLabel(column.id, column.label)
      }
    })
  }

  const filterTitle = !queryParams.gqlParams.fields?.operators?.length
    ? 'Filter'
    : `${queryParams.gqlParams.fields.operators?.length} Filters`
  return (
    <div className='p-4 text-sm'>
      <div className='mb-2 flex items-center max-[1270px]:block'>
        <SearchBar
          collapse={1270}
          value={queryInput}
          onChange={setQueryInput}
          reset={() => {
            setQueryInput('')
            updateParams(draft => {
              draft.skip = 0
              draft.query = ''
            })
            setActiveView(false)
          }}
          onBlur={() => {
            updateParams(draft => {
              draft.skip = 0
              draft.query = queryInput
            })
            setActiveView(false)
          }}
          onEnter={() => {
            updateParams(draft => {
              draft.skip = 0
              draft.query = queryInput
            })
            setActiveView(false)
          }}
        />
        <div className='flex flex-1 flex-wrap items-center justify-between gap-2'>
          <div className='flex flex-wrap items-center gap-2'>
            <SortButton
              columns={localizeLabels(params.columns)}
              value={params.sorts}
              update={e => {
                setActiveView(false)
                return updateParams(e)
              }}
            />
            <ColumnsButton
              className='h-[500px] w-[470px] p-2'
              value={localizeLabels(params.columns)}
              update={e => {
                setActiveView(false)
                return updateParams(e)
              }}
            />
            <Views
              gqlParams={gqlParams}
              myFilters={app.dataset.myFilters.edges}
              setFilter={(connection, id) => {
                setActiveView(id)
                setQueryInput(connection.query || '')
                updateParams(() => gqlToParams(app.dataset.form, connection))
              }}
              reset={() => {
                setQueryInput('')
                setActiveView('')
                reset()
              }}
              activeView={activeView}
              setActiveView={setActiveView}
            />
            {filtersOnDocList && (
              <>
                <button
                  className='kp-button-transparent kp-button-sm'
                  onClick={() => setShowAdvancedSettings(true)}
                >
                  <Icons.Filter className='mr-2 fill-current' /> {filterTitle}
                </button>
                <FiltersConfig
                  isOpen={showAdvancedSettings}
                  setIsOpen={setShowAdvancedSettings}
                  purpose='doc-list'
                  hasVersions={app?.dataset?.allowNewVersions}
                  params={{
                    ...params,
                    columns: params.columns.flatMap(col => ({
                      ...col,
                      formKey: `${col.formKey}${get(gadgets, [col.type, 'filterSuffix'], '')}`
                    })),
                    versionConfig: params?.versionConfig ?? 'LATEST_VERSION'
                  }}
                  updateParams={updateParams}
                />
              </>
            )}
            {gqlResult.loading && <Spinner size={15} />}
          </div>
          <div className='flex flex-wrap items-center gap-2'>
            <ExportButton
              appName={app.name}
              csvRoute={routes.documentsCsv()}
              params={gqlParams}
            />
            <ShareButton params={gqlParams} />
            {app.viewer.canManage && (
              <Link
                className='kp-button-transparent kp-button-sm'
                to='settings'
              >
                <Icons.Settings className='mr-2 fill-blue-500' />
                <Trans
                  id='pagesbuilder.doclist.data.settings'
                  message='Data Settings'
                />
              </Link>
            )}
            {app.viewer.canSubmitDocuments && (
              <button
                className='kp-button-solid'
                onClick={() => {
                  setNewAppLoading(true)
                  initWorkflow(appId, datasetId)
                    .then(actionId => navigate(`${actionId}/action`))
                    .catch(() => null)
                    .then(() => setNewAppLoading(false))
                }}
                disabled={newAppLoading}
              >
                {newAppLoading ? (
                  <Spinner size='20' />
                ) : (
                  <Trans id='pagesbuilder.doclist.new' message='New' />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      {!filtersOnDocList && (
        <Pills
          columns={params.columns}
          value={params.filters}
          remove={field => {
            updateParams(draft => {
              draft.filters = reject(draft.filters, { field })
            })
          }}
        />
      )}
      <div style={{ minHeight: 'initial', position: 'relative' }}>
        <Table>
          <Header
            params={params}
            updateParams={e => {
              setActiveView(false)
              return updateParams(e)
            }}
            columns={visibleColumns}
          />
          <TableBody>
            {app.dataset.documentConnection.edges.map(({ node }) => (
              <Row
                key={node.id}
                appId={appId}
                document={node}
                columns={visibleColumns}
                schema={app?.dataset?.form?.schema}
                allowNewVersions={app?.dataset?.allowNewVersions}
                className='h-9'
              />
            ))}
          </TableBody>
          {app.dataset.documentConnection.totalCount !== 0 && (
            <Pagination
              onUpdate={({ skip, limit }) =>
                updateParams(draft => {
                  draft.skip = skip
                  draft.limit = limit
                })
              }
              total={app.dataset.documentConnection.totalCount}
              skip={params.skip}
              limit={params.limit}
            />
          )}
        </Table>
        <Announcer id='document-list-pagination'>
          {getPaginationString(
            app.dataset.documentConnection.totalCount,
            params.skip,
            params.limit
          )}
        </Announcer>
        {app.dataset.documentConnection.totalCount === 0 && (
          <Empty.NoMatching
            reset={() => {
              setQueryInput('')
              updateParams(draft => {
                draft.query = null
                draft.filters = []
                if (filtersOnDocList) {
                  draft.filter = null
                  draft.versionConfig = 'LATEST_VERSION'
                }
              })
            }}
          />
        )}
      </div>
    </div>
  )
}

const getPaginationString = (count, start, limit) => {
  if (!count) {
    return `${i18n._({ id: 'pagesbuilder.doclist.no.matching', message: 'No matching search results' })}`
  }
  const end = Math.min(start + limit, count)
  return `${i18n._(
    {
      id: 'pagesbuilder.doclist.count.documents',
      message: 'Showing {start} to {end} documents'
    },
    {
      start: start + 1,
      end,
      count
    }
  )}`
}

const getListPageQuery = (
  appId,
  pageId,
  { skip, limit, sort, query, fields, versionConfig }
) => ({
  variables: { appId, pageId, skip, limit, sort, query, fields, versionConfig },
  query: gql`
    query ListPageQuery(
      $appId: ID!
      $pageId: ID
      $skip: Int!
      $limit: Int!
      $sort: [String!]
      $query: String
      $fields: Operator
      $versionConfig: VersionConfig
    ) {
      app(id: $appId) {
        id
        name
        viewer {
          canSubmitDocuments
          canManage
        }
        dataset(id: $pageId) {
          id
          isPublished
          allowNewVersions
          myFilters {
            edges {
              node {
                id
                name
                connection {
                  sort
                  fields
                  query
                  columns
                }
              }
            }
          }
          documentListConfig {
            columns
            sort
          }
          form: formContainer {
            id
            gadgetIndexTypes
            schema {
              id
              formKey
              type
              label
              details
            }
          }
          documentConnection(
            args: {
              skip: $skip
              limit: $limit
              sort: $sort
              query: $query
              fields: $fields
              versionConfig: $versionConfig
            }
            keyBy: ID
          ) {
            totalCount
            edges {
              node {
                id
                viewer {
                  canEdit
                  canDelete
                  actions {
                    id
                    details
                    type
                  }
                }
                data
                meta
                integration
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              skip
              limit
            }
          }
        }
      }
    }
  `
})
