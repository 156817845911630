/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useRemoveUserMutation = (groupId, roleId) => {
  const [removeUser, { loading }] = useMutation(mutation, {
    update (cache, result) {
      updateParentQueryCache(cache, groupId, roleId)
    }
  })
  return [userId => removeUser(getParams(groupId, roleId, userId)), { loading }]
}

const mutation = gql`
  mutation RemoveGroupRoleUser(
    $groupId: ID!
    $roleId: String
    $userId: String
  ) {
    removeGroupRoleUser(groupId: $groupId, roleId: $roleId, userId: $userId) {
      query {
        group(id: $groupId) {
          id
          access {
            isAdmin
            isParentAdmin
          }
        }
      }
      result {
        id
        members {
          id
          label: displayName
        }
        memberIds
      }
    }
  }
`

const getParams = (groupId, roleId, userId) => {
  return { variables: { groupId, roleId, userId } }
}

function updateParentQueryCache (cache, groupId, roleId) {
  cache.evict({
    fieldName: 'membersConnection',
    args: { args: { limit: 12, skip: 0, query: '', sort: '' } },
    id: `Role:${groupId}::${roleId}`
  })
}
