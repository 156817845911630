/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'
import { color, height, space, width } from 'styled-system'

import Button from './button'
import { Flex as LayoutFlex } from './layout'

export const Wrapper = styled(LayoutFlex)`
  ${color}
  ${width}
  ${height}
`

Wrapper.defaultProps = {
  width: '100%',
  height: '100vh',
  backgroundColor: '#e5e5e5',
  justifyContent: 'center',
  alignItems: 'center'
}

export const InnerWrapper = styled(LayoutFlex)`
  align-items: center;
  ${height}

  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Blurb = styled.div`
  ${color}
  ${height}
  ${width}
  ${space}

  @media screen and (max-width: 450px) {
    margin-left: 0px;
    margin-bottom: 8px;
  }
  border-radius: 10px;
  html.dark & {
    // Outlier: dark:bg-light-gray-300
    background: #333;
  }
`

Blurb.defaultProps = {
  backgroundColor: '#fff',
  width: '300px',
  height: 'min-content',
  marginLeft: '8px',
  padding: '16px 24px'
}

Blurb.Heading = styled.h3`
  font-size: 20px;
  margin-bottom: 8px;
  html.dark & {
    // Outlier: dark:text-black (?)
    color: white;
  }
`

Blurb.Message = styled.div`
  margin-bottom: 0;
  html.dark & {
    // Outlier: dark:text-black (?)
    color: white;
  }
`

Blurb.Action = styled(Button)`
  margin-top: 8px;
  html.dark & {
    // Outlier: dark:text-black (?)
    color: white;
  }
`
