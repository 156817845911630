/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import React from 'react'

import { pDebounce } from '../../../components/p-debounce'
import { useAlerts } from '../../../ui/alerts'

export const useUpdateBranding = id => {
  const [updateBranding] = useMutation(mutation)
  const alerts = useAlerts()
  return React.useCallback(
    pDebounce(
      obj =>
        updateBranding(getParams(id, obj)).then(() =>
          alerts.type3(i18n._('branding.saved'), 'success')
        ),
      500
    ),
    [updateBranding]
  )
}

const mutation = gql`
  mutation UpdateBranding($id: ID, $brand: BrandingUpdate!) {
    updateBranding(spaceId: $id, brand: $brand) {
      id
      color
      logo
      emailLogo
      maxHeight
      width
      height
      alt
      exists
    }
  }
`

const getParams = (id, brand) => ({ variables: { id, brand } })
