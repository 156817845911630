/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'

import Loading from '../loading'
import PagesNav from '../pages-nav'
import ChangeMessage from './change-message'
import CompareToggle from './compare-toggle'
import HelpLink from './help-link'
import Wrapper from './page-wrapper'
import WorkflowToggle from './workflow-toggle'

export default function FormbotContainer ({
  animate,
  branding,
  loading,
  actionButtons,
  paginationButtons,
  formbot,
  pages,
  helpLink,
  openHistory,
  workflowToggle,
  compareToggle,
  header,
  hideSidebars,
  className,
  width
}) {
  if (loading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }
  // For view and edit forms, as well as initial submit form and approvals
  return (
    <Wrapper className={cx(className, 'text-sm')}>
      <MainContainer>
        {/* Review/Status Toggle and Pages Navigation */}

        {!hideSidebars || width <= 1024 ? (
          <PagesContainer className='print:hidden'>
            {helpLink && <HelpLink helpLink={helpLink} />}
            {workflowToggle && <WorkflowToggle {...workflowToggle} />}
            {openHistory && (
              <ChangeMessage openHistory={openHistory} width={width} />
            )}
            {compareToggle && <CompareToggle {...compareToggle} />}
            {pages && (
              <PagesNav
                pages={pages}
                brandColor={branding?.color}
                paginationButtons={paginationButtons}
              />
            )}
          </PagesContainer>
        ) : (
          <PagesContainer className='print:hidden' />
        )}

        {/* Form */}
        <FormContainer
          className={`mx-10 ${
            animate
              ? hideSidebars
                ? 'kp-dh-animate-move-left'
                : 'kp-dh-animate-move-right'
              : hideSidebars
                ? '-translate-x-[220px] transform'
                : ''
          }`}
        >
          {header}
          <FormContent>{formbot}</FormContent>
        </FormContainer>

        <ActionsContainer className='print:hidden'>
          {!hideSidebars && (
            <Actions>
              {actionButtons && (
                <ActionsHeading>
                  <Trans id='actions' />
                </ActionsHeading>
              )}
              <ButtonContainer>{actionButtons}</ButtonContainer>
            </Actions>
          )}
        </ActionsContainer>
      </MainContainer>
    </Wrapper>
  )
}

const PagesContainer = styled.div`
  padding: 0 15px 24px;

  &:empty {
    padding: 0px;
  }

  @media screen and (min-width: 767px) {
    max-width: 300px;
  }
`

const ActionsContainer = styled.div`
  padding: 15px;
`

const ActionsHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #555555;
  padding-top: 9px;
  margin-bottom: 7px;
`

const FormContainer = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`

const Actions = styled.div`
  top: 0;
  position: sticky;
`

const FormContent = styled.div`
  & > :first-child {
    background: var(--white);
    padding: 12px 24px;
    min-height: 600px;
  }

  @media print {
    & > :first-child {
      box-shadow: none;
      padding: 0;
      min-height: auto;
      height: auto;
    }
  }
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: row;
    flex: 1;

    & ${FormContainer} {
      flex: 1;
    }

    & ${PagesContainer}, & ${ActionsContainer} {
      padding: 8px 0 20px 20px;
      width: 220px;
    }
  }
`
