/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from '../../components/query-context'

export const useActivateUserMutation = userId => {
  const q = useQueryContext()
  const [activateUser, { loading }] = useMutation(mutation, {
    refetchQueries: [q]
  })
  return [() => activateUser(getParams(userId)), { loading }]
}

const mutation = gql`
  mutation ActivateUser($userId: ID!) {
    activateUser(userId: $userId) {
      id
      active
    }
  }
`

const getParams = userId => ({ variables: { userId } })
