/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateWorkflowAppSettingsMutation = (appId, pageId) => {
  const [updateWorkflowAppSettings] = useMutation(mutation)
  return value => updateWorkflowAppSettings(getParams(appId, pageId, value))
}

const mutation = gql`
  mutation UpdateWorkflowAppSettings(
    $appId: ID!
    $pageId: ID
    $allowNewVersions: Boolean!
    $disableDocumentHistoryNonAdmins: Boolean!
  ) {
    updateAppSettings(
      args: {
        id: $appId
        pageId: $pageId
        allowNewVersions: $allowNewVersions
        disableDocumentHistoryNonAdmins: $disableDocumentHistoryNonAdmins
      }
    ) {
      id
      dataset(id: $pageId) {
        id
        allowNewVersions
        disableDocumentHistoryNonAdmins
      }
    }
  }
`

const getParams = (
  appId,
  pageId,
  { allowNewVersions, disableDocumentHistoryNonAdmins }
) => ({
  variables: {
    appId,
    pageId,
    allowNewVersions,
    disableDocumentHistoryNonAdmins
  },
  refetchQueries: ['getDocument']
})
