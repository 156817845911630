/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`44`} height={`44`} viewBox={`0 0 44 44`} fill={`none`}{...props}><path d={`M15.5682 23.4005L13.623 31.1793L21.4019 29.2342L33.0692 17.5668L27.2356 11.7332L15.5682 23.4005Z`} stroke={`#BBBBBB`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M39.875 10.7617L41.8202 12.7069C42.3357 13.2226 42.6253 13.9219 42.6253 14.6511C42.6253 15.3803 42.3357 16.0797 41.8202 16.5954L35.75 22.6656`} stroke={`#BBBBBB`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M33.0705 17.567L39.8758 10.7616C40.3914 10.2459 40.681 9.54658 40.681 8.81738C40.681 8.08818 40.3914 7.38883 39.8758 6.87313L37.9307 4.92797C37.415 4.41242 36.7156 4.1228 35.9864 4.1228C35.2572 4.1228 34.5579 4.41242 34.0422 4.92797L27.2368 11.7333`} stroke={`#BBBBBB`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M8.25 28.8733H4.125C3.76386 28.8733 3.40627 28.9444 3.07262 29.0826C2.73898 29.2208 2.43582 29.4234 2.18046 29.6787C1.9251 29.9341 1.72253 30.2373 1.58433 30.5709C1.44613 30.9046 1.375 31.2622 1.375 31.6233V37.1233C1.375 37.8526 1.66473 38.5521 2.18046 39.0678C2.69618 39.5836 3.39565 39.8733 4.125 39.8733H39.875C40.6043 39.8733 41.3038 39.5836 41.8195 39.0678C42.3353 38.5521 42.625 37.8526 42.625 37.1233V31.6233C42.625 30.8939 42.3353 30.1945 41.8195 29.6787C41.3038 29.163 40.6043 28.8733 39.875 28.8733H30.25`} stroke={`#BBBBBB`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/></svg>
);

let src = "<svg width=\"44\" height=\"44\" viewBox=\"0 0 44 44\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M15.5682 23.4005L13.623 31.1793L21.4019 29.2342L33.0692 17.5668L27.2356 11.7332L15.5682 23.4005Z\" stroke=\"#BBBBBB\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M39.875 10.7617L41.8202 12.7069C42.3357 13.2226 42.6253 13.9219 42.6253 14.6511C42.6253 15.3803 42.3357 16.0797 41.8202 16.5954L35.75 22.6656\" stroke=\"#BBBBBB\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M33.0705 17.567L39.8758 10.7616C40.3914 10.2459 40.681 9.54658 40.681 8.81738C40.681 8.08818 40.3914 7.38883 39.8758 6.87313L37.9307 4.92797C37.415 4.41242 36.7156 4.1228 35.9864 4.1228C35.2572 4.1228 34.5579 4.41242 34.0422 4.92797L27.2368 11.7333\" stroke=\"#BBBBBB\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M8.25 28.8733H4.125C3.76386 28.8733 3.40627 28.9444 3.07262 29.0826C2.73898 29.2208 2.43582 29.4234 2.18046 29.6787C1.9251 29.9341 1.72253 30.2373 1.58433 30.5709C1.44613 30.9046 1.375 31.2622 1.375 31.6233V37.1233C1.375 37.8526 1.66473 38.5521 2.18046 39.0678C2.69618 39.5836 3.39565 39.8733 4.125 39.8733H39.875C40.6043 39.8733 41.3038 39.5836 41.8195 39.0678C42.3353 38.5521 42.625 37.8526 42.625 37.1233V31.6233C42.625 30.8939 42.3353 30.1945 41.8195 29.6787C41.3038 29.163 40.6043 28.8733 39.875 28.8733H30.25\" stroke=\"#BBBBBB\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n";
export default src;
