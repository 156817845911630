/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import instructionsImg from './instructions.gif'

export default () => (
  <div className='ml-32 flex items-center'>
    <img className='h-[140px] w-[230px]' src={instructionsImg} alt='' />
    <div className='ml-4 w-[130px] text-base text-dark-gray-200'>
      <Trans id='pagesbuilder.drag.step' />
    </div>
  </div>
)
