/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import * as Illustrations from '../../illustrations'

export default function EmptyConfig () {
  return (
    <div className='flex flex-col items-center'>
      <div className='mb-2.5 mt-64 w-52 rounded-lg bg-light-gray-100 p-5 text-center text-base dark:bg-light-gray-300'>
        <Trans id='pagesbuilder.form.empty.edit' />
      </div>
      <Illustrations.EditGadget className='relative -left-6 shrink-0' />
    </div>
  )
}
