/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`12`} height={`12`} viewBox={`0 0 12 12`} fill={`none`}{...props}><path d={`M12 6C12 5.46957 11.7893 4.96086 11.4142 4.58579C11.0391 4.21071 10.5304 4 10 4H8.66667C8.48986 4 8.32029 3.92976 8.19526 3.80474C8.07024 3.67971 8 3.51014 8 3.33333V2C8 1.46957 7.78929 0.960859 7.41422 0.585786C7.03914 0.210713 6.53043 0 6 0C5.46957 0 4.96086 0.210713 4.58579 0.585786C4.21071 0.960859 4 1.46957 4 2V3.33333C4 3.51014 3.92976 3.67971 3.80474 3.80474C3.67971 3.92976 3.51014 4 3.33333 4H2C1.46957 4 0.960858 4.21071 0.585785 4.58579C0.210712 4.96086 0 5.46957 0 6C0 6.53043 0.210712 7.03914 0.585785 7.41421C0.960858 7.78929 1.46957 8 2 8H3.33333C3.51014 8 3.67971 8.07024 3.80474 8.19526C3.92976 8.32029 4 8.48986 4 8.66667V10C4 10.5304 4.21071 11.0391 4.58579 11.4142C4.96086 11.7893 5.46957 12 6 12C6.53043 12 7.03914 11.7893 7.41422 11.4142C7.78929 11.0391 8 10.5304 8 10V8.66667C8 8.48986 8.07024 8.32029 8.19526 8.19526C8.32029 8.07024 8.48986 8 8.66667 8H10C10.5304 8 11.0391 7.78929 11.4142 7.41421C11.7893 7.03914 12 6.53043 12 6Z`} fill={`#49AA30`}/></svg>
);

let src = "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M12 6C12 5.46957 11.7893 4.96086 11.4142 4.58579C11.0391 4.21071 10.5304 4 10 4H8.66667C8.48986 4 8.32029 3.92976 8.19526 3.80474C8.07024 3.67971 8 3.51014 8 3.33333V2C8 1.46957 7.78929 0.960859 7.41422 0.585786C7.03914 0.210713 6.53043 0 6 0C5.46957 0 4.96086 0.210713 4.58579 0.585786C4.21071 0.960859 4 1.46957 4 2V3.33333C4 3.51014 3.92976 3.67971 3.80474 3.80474C3.67971 3.92976 3.51014 4 3.33333 4H2C1.46957 4 0.960858 4.21071 0.585785 4.58579C0.210712 4.96086 0 5.46957 0 6C0 6.53043 0.210712 7.03914 0.585785 7.41421C0.960858 7.78929 1.46957 8 2 8H3.33333C3.51014 8 3.67971 8.07024 3.80474 8.19526C3.92976 8.32029 4 8.48986 4 8.66667V10C4 10.5304 4.21071 11.0391 4.58579 11.4142C4.96086 11.7893 5.46957 12 6 12C6.53043 12 7.03914 11.7893 7.41422 11.4142C7.78929 11.0391 8 10.5304 8 10V8.66667C8 8.48986 8.07024 8.32029 8.19526 8.19526C8.32029 8.07024 8.48986 8 8.66667 8H10C10.5304 8 11.0391 7.78929 11.4142 7.41421C11.7893 7.03914 12 6.53043 12 6Z\" fill=\"#49AA30\"/>\n</svg>\n";
export default src;
