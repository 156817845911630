/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import ReorderableList from '../../../components/reorderable-list'
import Presets from '../../multiselect-presets'

export function RequiredConfig ({ Gadgets, indexType }) {
  return (
    <Gadgets.Custom
      configKey='options'
      label={i18n._('options')}
      group
      defaultValue={[]}
    >
      {({ onChange, value }) => (
        <ReorderableList
          onChange={onChange}
          value={value}
          indexType={indexType}
        >
          <Presets onChange={onChange} gadgetType='Dropdown' />
        </ReorderableList>
      )}
    </Gadgets.Custom>
  )
}
