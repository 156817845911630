/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useUpdateIntegrationMutation = id => {
  const [updateIntegration] = useMutation(mutation)
  const query = useQueryContext()
  return data => updateIntegration(getParams(id, data, query))
}

const mutation = gql`
  mutation UpdateIntegration($id: ID!, $data: JSON!) {
    updateIntegration(args: { id: $id, data: $data }) {
      id
    }
  }
`

const getParams = (id, data, query) => ({
  variables: { id, data },
  refetchQueries: [query]
})
