/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { isEqual } from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'

import { autoUpdating } from '../../../components/feature-flags'
import { Option, Select } from '../../../ui/select'
import * as Chooser from '../../data-chooser'
import { ReactComponent as Illustration } from '../data-lookup/illustration-integration.svg.jsx'
import AutoUpdateConfig from '../form-typeahead/auto-update-config'
import { LinkedFieldsChooser, useFields } from '../form-typeahead/config.new'
import { useLatestDatasetInfo } from '../form-typeahead/use-latest-dataset-info'

export function RequiredConfig ({ value, ...props }) {
  const hasChosenDataset = Object.keys(value).length !== 0

  return hasChosenDataset ? (
    <RequiredConfigWithDataset {...props} value={value} />
  ) : (
    <RequiredConfigWithoutDataset {...props} />
  )
}

function RequiredConfigWithoutDataset ({ Gadgets, updateDataLookupSource }) {
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.TermsTypeahead onSelect={updateDataLookupSource} />
      <div className='mt-9 flex items-center justify-center'>
        <div className='flex h-24 w-36 items-center justify-center rounded-xl bg-light-gray-100 px-5 text-center text-base text-dark-gray-200 dark:bg-light-gray-300'>
          <Trans id='choose.source.to.begin' />
        </div>
        <Illustration />
      </div>
    </Gadgets.Padded>
  )
}

function RequiredConfigWithDataset ({
  Gadgets,
  updateDataLookupSource,
  onChange,
  indexed,
  indexType,
  selectedLinks,
  value,
  formKey
}) {
  const { appId } = useParams()
  const [showLinkedFieldsChooser, setShowLinkedFieldsChooser] =
    React.useState(false)
  React.useEffect(() => {
    if (!value) return
    if (!value.outputFields) setShowLinkedFieldsChooser(true)
  }, [value?.outputFields])
  const datasetInfo = useLatestDatasetInfo(appId, value?.id, value?.pageId)
  React.useEffect(() => {
    if (!datasetInfo || !value) return
    const prevDatasetInfo = {
      label: value.label,
      tileOptions: value.tileOptions,
      allowNewVersions: value.allowNewVersions
    }
    if (!isEqual(prevDatasetInfo, datasetInfo)) {
      return onChange({ ...value, ...datasetInfo })
    }
  }, [datasetInfo, value])

  const { outputFieldsById } = useFields({
    id: value.id,
    formKey,
    value,
    selectedLinks
  })

  const filteredOutputFieldsByType = type => {
    return Object.values(outputFieldsById).filter(field => field.type === type)
  }

  const formatValue = value => {
    const id = value?.id
    if (id && id.startsWith('data.')) return id.replace('data.', '')
    return id
  }

  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.TermsTypeahead
        id={value.id}
        label={value.label}
        tileOptions={value.tileOptions}
        isProduct={value.isProduct}
        value={value}
        onSelect={updateDataLookupSource}
        indexed={indexed}
        indexType={indexType}
        selectedLinks={selectedLinks}
        hasLinked={!!selectedLinks}
      />
      <button
        className='text-left text-text-link underline'
        onClick={() => setShowLinkedFieldsChooser(true)}
      >
        <Trans id='edit.available.date' />
      </button>
      {showLinkedFieldsChooser && value && (
        <LinkedFieldsChooser
          formKey={formKey}
          value={value}
          selectedLinks={selectedLinks}
          onHide={() => setShowLinkedFieldsChooser(false)}
          onContinue={outputFields => {
            onChange({ ...value, outputFields })
            setShowLinkedFieldsChooser(false)
          }}
        />
      )}
      {autoUpdating && (
        <AutoUpdateConfig
          id={formKey}
          Gadgets={Gadgets}
          className='mt-4'
          hasVersions={value?.allowNewVersions}
        />
      )}
      {Object.keys(outputFieldsById).length !== 0 && (
        <>
          <Gadgets.Label className='pt-6'>
            <Trans id='map.fields' />
          </Gadgets.Label>
          <div className='flex items-center gap-2 pt-2'>
            <Gadgets.Label
              htmlFor='startDate'
              className='w-28 dark:text-medium-gray-100'
            >
              <Trans id='start.date' />
            </Gadgets.Label>
            <Select
              id='startDate'
              className='flex-1'
              onChange={id => {
                onChange({ ...value, startDate: outputFieldsById[id] })
              }}
              value={formatValue(value?.startDate)}
            >
              <Option value=''>- - -</Option>
              {filteredOutputFieldsByType('Date').map(gadget => (
                <Option key={gadget.id} value={gadget.id}>
                  {gadget.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className='flex items-center gap-2 pt-2'>
            <Gadgets.Label
              htmlFor='endDate'
              className='w-28 dark:text-medium-gray-100'
            >
              <Trans id='end.date' />
            </Gadgets.Label>
            <Select
              id='endDate'
              className='flex-1'
              onChange={id => {
                onChange({ ...value, endDate: outputFieldsById[id] })
              }}
              value={formatValue(value?.endDate)}
            >
              <Option value=''>- - -</Option>
              {filteredOutputFieldsByType('Date').map(gadget => (
                <Option key={gadget.id} value={gadget.id}>
                  {gadget.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className='flex items-center gap-2 pt-2'>
            <Gadgets.Label
              htmlFor='previousTerm'
              className='w-28 dark:text-medium-gray-100'
            >
              <Trans id='previous.term' />
            </Gadgets.Label>
            <Select
              id='previousTerm'
              className='flex-1'
              onChange={id => {
                onChange({ ...value, previousTerm: outputFieldsById[id] })
              }}
              value={formatValue(value?.previousTerm)}
            >
              <Option value=''>- - -</Option>
              {filteredOutputFieldsByType('FormTypeahead').map(gadget => (
                <Option key={gadget.id} value={gadget.id}>
                  {gadget.label}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
    </Gadgets.Padded>
  )
}
