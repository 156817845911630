/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { map } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { Problem } from '../illustrations'
import { Blurb, InnerWrapper, Wrapper } from '../ui/errors'

export default class ErrorBoundary extends React.Component {
  state = {
    error: null
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ error })

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render () {
    const { children, ...rest } = this.props
    const { error } = this.state

    if (!error) {
      return React.cloneElement(React.Children.only(children), rest)
    }
    const ErrorComponent = ERROR_CODE_MAP[error.code] || ERROR_CODE_MAP.default
    return (
      <Wrapper className='text-sm'>
        <InnerWrapper>
          <Problem />
          <Blurb width='100%'>
            <Blurb.Heading>Oops!</Blurb.Heading>
            <Blurb.Message>
              {error.title || <Trans id='something.went.wrong' />}
            </Blurb.Message>
            <ErrorComponent error={error} />
            <Blurb.Action outline onClick={() => window.location.reload()}>
              <Trans id='refresh' />
            </Blurb.Action>
          </Blurb>
        </InnerWrapper>
      </Wrapper>
    )
  }
}

const ERROR_CODE_MAP = {
  default: () => null,
  pd_dependency_cycle: DependencyCycleError
}

function DependencyCycleError ({ error }) {
  const { callStack } = error.details

  const brokenGadgets = []
  for (let i = callStack.length - 1; i >= 0; --i) {
    if (map(brokenGadgets, 'formKey').includes(callStack[i].formKey)) break
    brokenGadgets.push(callStack[i])
  }
  brokenGadgets.reverse()

  return (
    <div>
      <div className='pt-4'>
        <Trans id='several.fields.conditional.visibility' />
      </div>
      <div className='pt-4'>
        <Trans id='creating.infinite.loop' />
      </div>
      <ul className='my-4 list-disc pl-10'>
        {map(brokenGadgets, gadget => (
          <li key={gadget.formKey}>
            <Link
              className='text-text-link underline'
              to={`?showGadget=${gadget.id}`}
            >
              {gadget.label}
            </Link>
          </li>
        ))}
      </ul>
      <div className='pt-4'>
        <Trans id='fix.to.republish' />
      </div>
      <br />
    </div>
  )
}
