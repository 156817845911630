/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'
import styled from 'styled-components'

import { columns } from './columns'
import SearchRow from './row'
import TableHeader from './table-header'

export default function ({ sortBy, sortDir, onChange, setFocus, results }) {
  return (
    <Table>
      <TableHeaderRow className='border-b-2 border-b-light-gray-200 bg-white text-dark-gray-100 dark:border-b-light-gray-300 dark:bg-light-gray-300'>
        {columns.map(({ key, field }) => (
          <TableHeader
            key={key}
            sortBy={sortBy}
            sortDir={sortDir}
            field={field}
            onSort={onChange}
          >
            {lookupLabel(key)}
          </TableHeader>
        ))}
      </TableHeaderRow>
      <Body>
        {results.map((result, index) => (
          <SearchRow
            key={index}
            setFocus={cb => setFocus(cb(index))}
            result={result}
          />
        ))}
      </Body>
    </Table>
  )
}

const lookupLabel = key => {
  switch (key) {
    case 'documentType':
      return i18n._({ id: 'document.type', message: 'Document Type' })
    case 'serialNumber':
      return i18n._({ id: 'document.number', message: 'Document #' })
    case 'submitter':
      return i18n._({ id: 'submitted.by', message: 'Submitted By' })
    case 'step':
      return i18n._({ id: 'workflow.step', message: 'Workflow Step' })
    case 'createdAt':
      return i18n._({ id: 'created.on', message: 'Created On' })
    case 'action':
      return i18n._({ id: 'action.requested', message: 'Action Requested' })
    default:
      return key
  }
}

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem hsla(0, 0%, 0%, 0.15);
  height: calc(100vh - 156px);
`

const TableHeaderRow = styled.div`
  display: flex;
  padding: 0.25em 0;
  font-weight: 500;
  text-transform: capitalize;
`

const Body = styled.div`
  overflow: auto;
  flex: 1;
`
