/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M10.212 1.43067L13.236 4.45467C13.2983 4.51714 13.3333 4.60177 13.3333 4.69V14.3333C13.3333 14.4217 13.2982 14.5065 13.2357 14.569C13.1732 14.6315 13.0884 14.6667 13 14.6667H2.99998C2.91157 14.6667 2.82679 14.6315 2.76428 14.569C2.70177 14.5065 2.66665 14.4217 2.66665 14.3333V1.66667C2.66665 1.57826 2.70177 1.49348 2.76428 1.43096C2.82679 1.36845 2.91157 1.33333 2.99998 1.33333H9.97665C10.0649 1.33335 10.1495 1.36835 10.212 1.43067ZM10.3906 0H2.66665C2.31302 0 1.97389 0.140476 1.72384 0.390524C1.47379 0.640573 1.33331 0.979711 1.33331 1.33333V14.6667C1.33331 15.0203 1.47379 15.3594 1.72384 15.6095C1.97389 15.8595 2.31302 16 2.66665 16H13.3333C13.6869 16 14.0261 15.8595 14.2761 15.6095C14.5262 15.3594 14.6666 15.0203 14.6666 14.6667V4.276C14.6666 4.0992 14.5963 3.92966 14.4713 3.80467L10.862 0.195333C10.737 0.0703004 10.5674 3.77583e-05 10.3906 0Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M10.212 1.43067L13.236 4.45467C13.2983 4.51714 13.3333 4.60177 13.3333 4.69V14.3333C13.3333 14.4217 13.2982 14.5065 13.2357 14.569C13.1732 14.6315 13.0884 14.6667 13 14.6667H2.99998C2.91157 14.6667 2.82679 14.6315 2.76428 14.569C2.70177 14.5065 2.66665 14.4217 2.66665 14.3333V1.66667C2.66665 1.57826 2.70177 1.49348 2.76428 1.43096C2.82679 1.36845 2.91157 1.33333 2.99998 1.33333H9.97665C10.0649 1.33335 10.1495 1.36835 10.212 1.43067ZM10.3906 0H2.66665C2.31302 0 1.97389 0.140476 1.72384 0.390524C1.47379 0.640573 1.33331 0.979711 1.33331 1.33333V14.6667C1.33331 15.0203 1.47379 15.3594 1.72384 15.6095C1.97389 15.8595 2.31302 16 2.66665 16H13.3333C13.6869 16 14.0261 15.8595 14.2761 15.6095C14.5262 15.3594 14.6666 15.0203 14.6666 14.6667V4.276C14.6666 4.0992 14.5963 3.92966 14.4713 3.80467L10.862 0.195333C10.737 0.0703004 10.5674 3.77583e-05 10.3906 0Z\" />\n</svg>\n";
export default src;
