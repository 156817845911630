/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get, includes } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Button from '../../ui/button'
import { Flex } from '../../ui/layout'

const DisabledDottedInput = styled.div`
  background: #fff;
  border: 1px solid #bebebe;
  border-radius: 4px;
  cursor: not-allowed;
  padding-left: 8px;
  margin-right: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  height: 32px;
`

const Dot = styled.div`
  background: #c4c4c4;
  border-radius: 7px;
  margin: 0 5px;
  height: 7px;
  width: 7px;
`

export default function secretFieldsDecorator ({ template, context }) {
  const secrets = get(context, 'filledSecrets') || []
  if (!template.formKey || !includes(secrets, template.formKey)) return {}
  return {
    gadget: gadget => (
      <Flex>
        <DisabledDottedInput>
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
        </DisabledDottedInput>
        <Button outline onClick={() => context.editSecret(template.formKey)}>
          <Trans id='edit' />
        </Button>
      </Flex>
    )
  }
}
