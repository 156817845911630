/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useRemovePolicyGroupMutation = roleId => {
  const [removePolicyGroup] = useMutation(mutation)
  const query = useQueryContext()
  return () => removePolicyGroup(getParams(roleId, query))
}

const mutation = gql`
  mutation RemovePolicyGroup($roleId: String!) {
    deletePolicyGroup(args: { roleId: $roleId })
  }
`

const getParams = (roleId, query) => ({
  variables: { roleId },
  refetchQueries: [query]
})
