/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import React from 'react'

export const useUpdateAppHelpLink = appId => {
  const [updateFormSettings] = useMutation(mutation)
  return React.useCallback(
    helpLink => updateFormSettings(getParams(appId, helpLink)),
    [updateFormSettings, appId]
  )
}

const mutation = gql`
  mutation UpdateAppHelpLink($appId: ID!, $helpLink: String) {
    updateAppHelpLink(id: $appId, helpLink: $helpLink) {
      id
      helpLink
    }
  }
`

const getParams = (appId, helpLink) => {
  return {
    variables: { appId, helpLink },
    optimisticResponse: {
      __typename: 'Mutation',
      updateAppHelpLink: {
        __typename: 'App',
        id: appId,
        helpLink
      }
    }
  }
}
