/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13.6293 6.17996C13.6012 6.12571 13.5587 6.08023 13.5065 6.04849C13.4543 6.01674 13.3944 5.99996 13.3333 5.99996H8.41596L9.65863 0.403963C9.67555 0.328862 9.66595 0.250221 9.63147 0.181394C9.59698 0.112567 9.53974 0.0577974 9.46946 0.0263863C9.39917 -0.00502492 9.32019 -0.0111412 9.2459 0.00907614C9.17163 0.0292935 9.10663 0.074598 9.06196 0.137296L2.3953 9.47063C2.35906 9.52048 2.33735 9.5794 2.33258 9.64084C2.32781 9.70228 2.34017 9.76384 2.36828 9.81869C2.39639 9.87353 2.43916 9.9195 2.49183 9.95151C2.5445 9.98351 2.605 10.0003 2.66663 9.99996H7.58396L6.3413 15.5933C6.32438 15.6684 6.33398 15.747 6.36846 15.8159C6.40295 15.8847 6.46019 15.9395 6.53047 15.9709C6.60075 16.0023 6.67974 16.0084 6.75402 15.9882C6.8283 15.968 6.8933 15.9227 6.93796 15.86L13.6046 6.52663C13.6402 6.4767 13.6614 6.41793 13.6657 6.35677C13.6701 6.2956 13.6575 6.23443 13.6293 6.17996Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.6293 6.17996C13.6012 6.12571 13.5587 6.08023 13.5065 6.04849C13.4543 6.01674 13.3944 5.99996 13.3333 5.99996H8.41596L9.65863 0.403963C9.67555 0.328862 9.66595 0.250221 9.63147 0.181394C9.59698 0.112567 9.53974 0.0577974 9.46946 0.0263863C9.39917 -0.00502492 9.32019 -0.0111412 9.2459 0.00907614C9.17163 0.0292935 9.10663 0.074598 9.06196 0.137296L2.3953 9.47063C2.35906 9.52048 2.33735 9.5794 2.33258 9.64084C2.32781 9.70228 2.34017 9.76384 2.36828 9.81869C2.39639 9.87353 2.43916 9.9195 2.49183 9.95151C2.5445 9.98351 2.605 10.0003 2.66663 9.99996H7.58396L6.3413 15.5933C6.32438 15.6684 6.33398 15.747 6.36846 15.8159C6.40295 15.8847 6.46019 15.9395 6.53047 15.9709C6.60075 16.0023 6.67974 16.0084 6.75402 15.9882C6.8283 15.968 6.8933 15.9227 6.93796 15.86L13.6046 6.52663C13.6402 6.4767 13.6614 6.41793 13.6657 6.35677C13.6701 6.2956 13.6575 6.23443 13.6293 6.17996Z\" />\n</svg>\n";
export default src;
