/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get } from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import shortid from 'shortid'
import styled from 'styled-components'

import { gadgets } from '../../formbot'
import * as Icons from '../../icons'
import * as Illustration from '../../illustrations'
import { VisuallyHidden } from '../../ui/a11y'
import Button from '../../ui/button'
import { Flex } from '../../ui/layout'
import Radios from '../../ui/radios'
import { Option, Select } from '../../ui/select'
import { Body1, Subtitle2 } from '../../ui/typography'
import { CheckedLI, UL } from '../../ui/ul'
import PopoverButton from './popover-button'

export const newSort = () => ({
  id: shortid.generate(),
  field: '',
  ascending: false
})

const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
`

const MobileRadios = styled(Radios)`
  margin-right: 8px;
  @media (max-width: 500px) {
    margin-left: 4px;
  }
`

const MobileBody1 = styled(Body1)`
  padding: 16px;
  @media (max-width: 500px) {
    padding: 16px 2px 16px 8px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
`

const ColumnList = styled.div`
  margin-bottom: 10px;
`

const Sort = React.forwardRef(
  (
    { first, field, ascending, remove, update, columns, focusRef, ...rest },
    ref
  ) => (
    <FlexWrap mt={3} ref={ref} {...rest}>
      <Flex>
        <Icons.Drag
          mr={1}
          className='fill-medium-gray-500 dark:fill-medium-gray-300'
          width={14}
          height={14}
        />
        <Body1 width={85} mr={1} className='text-nowrap'>
          {first
            ? i18n._({ id: 'sort.by', message: 'Sort by' })
            : i18n._({ id: 'then.by', message: 'Then by' })}
        </Body1>
        <Select
          darkerModeDarkerBg
          ref={focusRef}
          data-testid='sort-field'
          value={field}
          onChange={field => update({ field })}
          width={120}
        >
          <Option value='' />
          {columns.map(column => (
            <Option key={column.formKey} value={column.formKey}>
              {column.label}
            </Option>
          ))}
        </Select>
      </Flex>
      <Flex>
        <MobileRadios
          inline
          nowrap
          options={[
            {
              id: false,
              label: i18n._({ id: 'a-z', message: 'A ➞ Z' }),
              ariaLabel: i18n._('sort.descending'),
              htmlId: `sort-on-${rest.id}`
            },
            {
              id: true,
              label: i18n._({ id: 'z-a', message: 'Z ➞ A' }),
              ariaLabel: i18n._('sort.ascending'),
              htmlId: `sort-off-${rest.id}`
            }
          ]}
          value={ascending}
          onChange={ascending => update({ ascending })}
        />
        <Button icon transparent onClick={remove}>
          <VisuallyHidden>
            <Trans id='remove.sort' message='Remove Sort' />
          </VisuallyHidden>
          <Icons.Delete />
        </Button>
      </Flex>
    </FlexWrap>
  )
)

export const Sorts = ({ columns, value, update }) => {
  const [focusLast, setFocusLast] = React.useState(false)
  const [itemToFocus, setItemToFocus] = React.useState(null)
  React.useEffect(() => {
    if (itemToFocus) itemToFocus.focus()
  }, [itemToFocus])
  return (
    <MobileBody1 width={480}>
      <VisuallyHidden id='sortTitle'>
        <Trans id='sorting' message='Sorting' />
      </VisuallyHidden>
      <VisuallyHidden id='sortDesc'>
        <Trans
          id='you.have.length.sorts.set'
          message='You have {length} sorts set.'
          values={{ length: value.length }}
        />
      </VisuallyHidden>
      <Subtitle2 pl={3}>
        <Trans id='sort.order' message='Sort Order' />
      </Subtitle2>
      <DragDropContext
        onDragEnd={result => {
          if (!result.destination) return
          update(draft => {
            const [column] = draft.splice(result.source.index, 1)
            draft.splice(result.destination.index, 0, column)
          })
        }}
      >
        <Droppable droppableId='droppable2'>
          {(provided, snapshot) => (
            <ColumnList {...provided.droppableProps} ref={provided.innerRef}>
              {value.map((sort, i) => (
                <Draggable key={sort.id} draggableId={sort.id} index={i}>
                  {(provided, snapshot) => (
                    <Sort
                      ref={provided.innerRef}
                      focusRef={
                        (!focusLast && i === 0) ||
                        (focusLast && i === value.length - 1)
                          ? setItemToFocus
                          : undefined
                      }
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      {...sort}
                      first={i === 0}
                      columns={columns}
                      update={val =>
                        update(draft => {
                          draft[i] = Object.assign(draft[i], val)
                        })
                      }
                      remove={() => {
                        setTimeout(() => {
                          update(draft => {
                            draft.splice(i, 1)
                          })
                        })
                      }}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ColumnList>
          )}
        </Droppable>
        <Button
          mt={3}
          mb={1}
          outline
          onClick={() => {
            update(draft => {
              draft.push(newSort())
            })
            setFocusLast(true)
          }}
        >
          <Trans
            id='add.another.sort.column'
            message='Add another sort column'
          />
        </Button>
      </DragDropContext>
    </MobileBody1>
  )
}

export const EmptySorts = ({ onClick }) => (
  <Body1 p={3} width={480}>
    <VisuallyHidden id='sortTitle'>
      <Trans id='sorting' message='Sorting' />
    </VisuallyHidden>
    <VisuallyHidden id='sortDesc'>
      <Trans id='no.sorts.set' message='There are no sorts set.' />
    </VisuallyHidden>
    <Subtitle2>
      <Trans id='use.sort.to.colon' message='Use a Sort to:' />
    </Subtitle2>
    <Flex py={2}>
      <Illustration.Layout width={80} height={51.5} mr={3} aria-hidden />
      <span>
        <Trans
          id='sorting.gives.power.rearrange.data'
          message='Sorting gives you the power to rearrange data.'
        />
      </span>
    </Flex>
    <UL>
      <CheckedLI>
        <Trans
          id='sort.columns.alphabetically.or.values'
          message='Sort columns alphabetically or by increasing or decreasing values.'
        />
      </CheckedLI>
      <CheckedLI>
        <Trans
          id='sort.multiple.columns.in.specific.order'
          message='Sort multiple columns in a specific order.'
        />
      </CheckedLI>
    </UL>
    <Button mt={2} mb={1} outline onClick={onClick}>
      <Trans id='add.sort.column' message='Add a sort column' />
    </Button>
  </Body1>
)

export default ({ columns, value, update }) => {
  const sortableColumns = columns.filter(
    column => !(column.unsortable || get(gadgets, [column.type, 'unsortable']))
  )

  const sortLabel = (
    <>
      <Icons.Sort className='fill-blue-500' mr={2} />
      {value.length
        ? i18n._({
            id: 'sort.length',
            message: 'Sort ({length})',
            values: { length: value.length }
          })
        : i18n._({ id: 'sort', message: 'Sort' })}
    </>
  )
  return (
    <PopoverButton
      label={sortLabel}
      aria-labelledby='sortTitle'
      aria-describedby='sortDesc'
      data-testid='popover-sorts'
      buttonProps={{ transparent: true, small: true }}
    >
      {() =>
        value.length ? (
          <Sorts
            columns={sortableColumns}
            value={value}
            update={updater =>
              update(draft => {
                updater(draft.sorts)
              })
            }
          />
        ) : (
          <EmptySorts
            onClick={() =>
              setTimeout(() =>
                update(draft => {
                  draft.sorts = [newSort()]
                })
              )
            }
          />
        )
      }
    </PopoverButton>
  )
}
