/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useMoveLinksToSpaceMutation = () => {
  const [moveLinks] = useMutation(moveLinksMutation)
  return (linkIds, spaceId, oldSpaceId) =>
    moveLinks(getParams(linkIds, spaceId, oldSpaceId))
}

const moveLinksMutation = gql`
  mutation MoveLinksToSpace($linkIds: [ID!]!, $spaceId: ID!, $oldSpaceId: ID!) {
    moveLinksToSpace(linkIds: $linkIds, spaceId: $spaceId) {
      query {
        space(id: $oldSpaceId) {
          id
          links {
            id
          }
        }
      }
    }
  }
`

const getParams = (linkIds, spaceId, oldSpaceId) => ({
  variables: { linkIds, spaceId, oldSpaceId }
})
