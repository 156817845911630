/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import { TextView } from '../../gadget-helpers'

export default function NumberView ({ value, gridded, condensed }) {
  return <StyledTextView {...{ condensed, gridded }}>{value}</StyledTextView>
}

const StyledTextView = styled(TextView)`
  text-align: right;
`
