/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateWorkflowViewerOnSubmissionsMutation = (appId, pageId) => {
  const [updateWorkflowViewerOnSubmissions] = useMutation(mutation)
  return value =>
    updateWorkflowViewerOnSubmissions(getParams(appId, pageId, value))
}

const mutation = gql`
  mutation UpdateWorkflowViewerOnSubmissions(
    $appId: ID!
    $pageId: ID
    $value: Boolean!
  ) {
    updateAppSettings(
      args: { id: $appId, pageId: $pageId, workflowViewerOnSubmission: $value }
    ) {
      id
      dataset(id: $pageId) {
        id
        workflowViewerOnSubmission
      }
    }
  }
`

const getParams = (appId, pageId, value) => ({
  variables: {
    appId,
    pageId,
    value
  }
})
