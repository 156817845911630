/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { debounce, throttle } from 'lodash'
import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import PopoverButton from '../../../../../components/data-table/popover-button'
import { SmallSearch } from '../../../../../components/data-table/search-bar'
import InfiniteScroll from '../../../../../components/infinite-scroll'
import { ModalPage } from '../../../../../components/modal-page'
import Spinner from '../../../../../components/spinner'
import { GraphQLError as Error } from '../../../../../components/system-error'
import { useQuery } from '../../../../../components/use-query'
import UserTypeahead from '../../../../../formbot/gadgets/user-typeahead/edit'
import * as Icons from '../../../../../icons'
import * as Illustration from '../../../../../illustrations'
import { useAlerts } from '../../../../../ui/alerts'
import Button from '../../../../../ui/button'
import hashColor from '../../../utils/get-kuali-color-by-hash'
import { useAddUserMutation } from './mutation.add-group-role-user'
import { useRemoveUserMutation } from './mutation.remove-group-role-user'

export default function RoleView () {
  const { group } = useOutletContext()
  const alerts = useAlerts()
  const { id, roleId } = useParams()
  const [addUser, { loading: addUserLoading }] = useAddUserMutation(id, roleId)
  const [params, updateParams] = useImmer(defaultParams)
  const canAdminister = group?.access?.isAdmin

  const role = group?.roles?.find(r => {
    const compareVal = r?.id?.split('::')[1]
    return roleId === compareVal || roleId === r?.id
  })

  const reset = React.useCallback(() => {
    setQueryInput('')
    updateParams(() => defaultParams)
  }, [updateParams])

  const debouncedSearch = React.useCallback(
    debounce(q => {
      updateParams(draft => {
        draft.skip = 0
        draft.q = q
      })
    }, 400),
    []
  )

  const {
    pageInfo,
    members,
    loading,
    refetchRoleMembers,
    throttledLoadMore,
    error,
    queryInput,
    setQueryInput
  } = useRoleData(params, id, roleId)

  const scrollFetch = ({ atBottom }) => {
    if (atBottom) {
      throttledLoadMore()
    }
  }

  return (
    <ModalPage
      darkTop
      side
      title={
        localizeRoleName(role) ?? (
          <Trans id='missing.name' message='Missing Name' />
        )
      }
      width='375px'
      closeAriaLabel={i18n._({
        id: 'close.role.view',
        message: 'close role view'
      })}
    >
      <StyledModalContainer className='text-sm'>
        <StyledTopSection className='border-b border-b-light-gray-300'>
          <StyledSmallSearch
            aria-label={`${localizeRoleName(role) ?? 'Role'} search`}
            value={queryInput}
            onChange={newQueryInput => {
              setQueryInput(newQueryInput)
              debouncedSearch(newQueryInput)
            }}
            reset={reset}
            onBlur={() =>
              updateParams(draft => {
                draft.skip = 0
                draft.q = queryInput
              })
            }
            onEnter={() =>
              updateParams(draft => {
                draft.skip = 0
                draft.q = queryInput
              })
            }
          />
          {canAdminister && (
            <PopoverButton
              hideArrow
              disableBranding
              buttonProps={{
                disabled: addUserLoading,
                transparent: true,
                icon: true,
                width: 120
              }}
              label={
                addUserLoading ? (
                  <Spinner size={16} />
                ) : (
                  <>
                    <Icons.Add className='fill-blue-500' mr={2} />
                    <Trans id='add.person' message='Add Person' />
                  </>
                )
              }
              right={-16}
              bottom={-267}
              style={{
                width: '375px',
                height: '260px',
                padding: '12px'
              }}
            >
              {hide => (
                <UserTypeahead
                  filterFn={el => !role?.memberIds?.includes(el.id)}
                  onChange={e => {
                    hide()
                    addUser(e.id)
                      .then(() => {
                        refetchRoleMembers()
                        alerts.type3(
                          i18n._({
                            id: 'user.added.to.role',
                            message: 'User added to role'
                          }),
                          'success'
                        )
                      })
                      .catch(err => {
                        Sentry.captureException(err)
                        alerts.type3(
                          i18n._({
                            id: 'failed.add.user.role',
                            message: 'Failed to add user to role'
                          }),
                          'error'
                        )
                      })
                  }}
                />
              )}
            </PopoverButton>
          )}
        </StyledTopSection>
        <UserSection>
          {!role?.memberIds?.length ? (
            <NoResults />
          ) : !addUserLoading && members?.length === 0 && queryInput ? (
            <NoMatching reset={reset} />
          ) : (
            <InfiniteScroll onBoundary={scrollFetch}>
              {error ? (
                <Error />
              ) : (
                members?.map(member => (
                  <ListItem
                    canAdminister={canAdminister}
                    refetchRoleMembers={refetchRoleMembers}
                    member={member}
                    id={id}
                    roleId={roleId}
                    key={member.id}
                  />
                ))
              )}
              {addUserLoading ? (
                <StyledLoadingItem className='bg-white text-medium-gray-500 dark:bg-light-gray-300'>
                  <Trans id='adding.person' message='Adding person...' />
                  <Spinner size={24} />
                </StyledLoadingItem>
              ) : (
                pageInfo?.hasNextPage && (
                  <NextPageItem
                    handleClick={throttledLoadMore}
                    loading={loading}
                  />
                )
              )}
            </InfiniteScroll>
          )}
        </UserSection>
      </StyledModalContainer>
    </ModalPage>
  )
}

const localizeRoleName = role => {
  switch (role?.name) {
    case 'Administrators':
      return i18n._({
        id: 'administrators',
        message: 'Administrators'
      })
    case 'Members':
      return i18n._({
        id: 'members',
        message: 'Members'
      })
    default:
      return role?.name
  }
}

const ListItem = ({
  member,
  id,
  roleId,
  refetchRoleMembers,
  canAdminister
}) => {
  const alerts = useAlerts()
  const [removeUser, { loading: removeUserLoading }] = useRemoveUserMutation(
    id,
    roleId
  )
  const active = member.active

  const name =
    member.lastName && member.firstName
      ? member.lastName + ', ' + member.firstName
      : member.label

  return (
    <StyledListItem className='bg-white dark:bg-light-gray-300'>
      <CircleBox>
        <Circle active={active} color={hashColor(member.label)}>
          {(member.firstName?.toUpperCase()[0] || '') +
            (member.lastName?.toUpperCase()[0] || '') ||
            member.label?.toUpperCase()[0]}
        </Circle>
      </CircleBox>
      <StyledName active={active}>
        <h4 className='flex items-center' title={name}>
          {name}
          {!active && (
            <span className='text-2xs ml-1 inline-block rounded-sm bg-medium-gray-300 p-1 uppercase text-white dark:text-black'>
              <Trans id='inactive' message='inactive' />
            </span>
          )}
        </h4>
        <h5>{member.email}</h5>
      </StyledName>
      {canAdminister && (
        <Button
          disabled={removeUserLoading}
          aria-label={i18n._({
            id: 'remove.user.from.role',
            message: 'Remove user from role'
          })}
          transparent
          icon
          onClick={() => {
            removeUser(member.id)
              .then(() => {
                alerts.type3(
                  i18n._({
                    id: 'user.removed.from.role',
                    message: 'User removed from role'
                  }),
                  'success'
                )
                refetchRoleMembers()
              })
              .catch(err => {
                Sentry.captureException(err)
                alerts.type3(
                  i18n._({
                    id: 'could.not.remove.user.role',
                    message: "Couldn't remove user from role"
                  }),
                  'error'
                )
              })
          }}
        >
          {removeUserLoading ? <Spinner size={16} /> : <Icons.Delete />}
        </Button>
      )}
    </StyledListItem>
  )
}

const NextPageItem = ({ handleClick, loading }) => {
  if (loading) {
    return (
      <StyledListItemFetch>
        <Spinner size={32} />
      </StyledListItemFetch>
    )
  }
  return (
    <StyledListItemFetch onClick={handleClick}>
      <Trans id='scroll.to.load.more' message='Scroll to load more' />
    </StyledListItemFetch>
  )
}

const StyledModalContainer = styled.div`
  height: 100%;
  width: 100%;
`

const StyledSmallSearch = styled(SmallSearch)`
  border-radius: 4px;
  border: none;
  color: #666666;
`

const Section = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul {
    max-height: 200px;
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
    margin-right: 16px;
  }
`

const CircleBox = styled.div`
  height: 32px;
  position: relative;
`

const Circle = styled.div`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background-color: ${p => (!p.active && '#ababab') || (p.color ?? '#E43935')};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 12px;
  opacity: ${p => (!p.active && 0.5) || 1};
`

const StyledName = styled.div`
  width: 100%;
  overflow: hidden;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    color: ${p => !p.active && 'var(--medium-gray-500)'};
  }
  h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    color: #888888;
  }
`

const StyledTopSection = styled(Section)`
  height: 65px;

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
    margin-right: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
`

const UserSection = styled.div`
  height: calc(100% - 65px);
`

const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 12px;
`

const StyledLoadingItem = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  gap: 8px;
  border-radius: 4px;
`

const StyledListItemFetch = styled.button`
  width: 100%;
  outline: none;
  border: none;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  justify-content: center;
  color: #3369a3;
  cursor: pointer;
  height: 52px;
`

const defaultParams = {
  skip: 0,
  limit: 25,
  q: ''
}

function useThrottle (cb, delay) {
  const options = { leading: true, trailing: false }
  const cbRef = React.useRef(cb)
  React.useEffect(() => {
    cbRef.current = cb
  })
  return React.useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  )
}

function useRoleData (params, groupId, roleId) {
  const graphqlVariables = React.useMemo(() => {
    return {
      limit: params.limit,
      skip: params.skip,
      q: params.q,
      groupId,
      roleId
    }
  }, [params, groupId, roleId])
  const [queryInput, setQueryInput] = React.useState('')
  const q = {
    query: roleMemberQuery,
    variables: graphqlVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  }
  const { data, loading, error, refetch, fetchMore } = useQuery(q)
  const members = data?.group?.role?.membersConnection?.edges?.map(
    el => el.node
  )
  const pageInfo = data?.group?.role?.membersConnection?.pageInfo

  const loadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        q: params.q,
        groupId: graphqlVariables.groupId,
        roleId: graphqlVariables.roleId,
        skip: (pageInfo?.skip || 0) + (pageInfo?.limit || 0),
        limit: pageInfo?.limit
      }
    }).catch(err => {
      Sentry.captureException(err)
    })
  }, [pageInfo, graphqlVariables, members, fetchMore])

  const throttledLoadMore = useThrottle(loadMore, 1000)

  return {
    pageInfo,
    q,
    name: data?.group?.role?.name,
    members,
    refetchRoleMembers: refetch,
    throttledLoadMore,
    loading,
    error,
    queryInput,
    setQueryInput
  }
}

const roleMemberQuery = gql`
  query GroupRoleMembers(
    $groupId: ID!
    $roleId: ID!
    $limit: Int
    $skip: Int
    $q: String
  ) {
    group(id: $groupId) {
      id

      role(id: $roleId) {
        id
        name
        membersConnection(args: { limit: $limit, skip: $skip, query: $q }) {
          pageInfo {
            limit
            skip
            hasNextPage
          }
          totalCount
          edges {
            node {
              id
              username
              firstName
              lastName
              email
              active
              label: displayName
            }
          }
        }
      }
    }
  }
`

const NoMatching = ({ reset }) => (
  <Wrapper>
    <Illustration.Empty width={214} height={298} />
    <Message>
      <p>
        <strong>
          <Trans
            id='no.matching.search.results'
            message='No matching search results'
          />
        </strong>
      </p>
      <p>
        <Trans
          id='please.try.again.general.search.terms'
          message='Please try again using more general search terms'
        />
      </p>
    </Message>
    <Button onClick={reset}>
      <Trans id='clear.all.filters' message='Clear All Filters' />
    </Button>
  </Wrapper>
)

const NoResults = () => (
  <Wrapper>
    <Illustration.Review width={280} height={298} />
    <Message>
      <p>
        <strong>
          <Trans id='no.results.yet' message='No results yet' />
        </strong>
      </p>
      <p>
        <Trans
          id='role.no.users.assigned'
          message='This role has no users assigned'
        />
      </p>
    </Message>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  height: 100%;
`

const Message = styled.div`
  margin: 25px 0;
  text-align: center;
`
