import shortid from 'shortid'

const cmTree = {
  id: 'root',
  children: [
    {
      children: [],
      id: shortid.generate(),
      gadgets: [
        {
          type: 'Spacer',
          ruleText: 'Start here'
        }
      ]
    }
  ],
  gadgets: []
}

export default cmTree
