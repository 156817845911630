/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateLinkMutation = (spaceId, id) => {
  const [updateLink, opts] = useMutation(updateLinkMutation)
  return [args => updateLink(getParams(spaceId, id, args)), opts]
}

const updateLinkMutation = gql`
  mutation UpdateLink($spaceId: ID!, $id: ID!, $args: UpdateLinkInput!) {
    updateLink(spaceId: $spaceId, id: $id, args: $args) {
      result {
        id
        title
        description
        url
        imageUrl
      }
    }
  }
`

const getParams = (spaceId, id, args) => ({
  variables: { spaceId, id, args }
})
