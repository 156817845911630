/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { keyBy, mapValues, partition, sample } from 'lodash'
import React from 'react'
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams
} from 'react-router-dom'

import { AppIcon } from '../../components/app-icon'
import {
  emphasizeResult,
  winnowList
} from '../../components/escape-string-regexp'
import { productBuilder } from '../../components/feature-flags'
import { PortalOrange } from '../../components/portals'
import * as routes from '../../components/routes'
import Spinner from '../../components/spinner'
import { useTileTransition } from '../../components/tile-transition'
import useDebouncedValue from '../../components/use-debounced-value'
import * as Icons from '../../icons'
import * as Illustrations from '../../illustrations'
import { useInitWorkflow } from '../../pages-runner/run/components/mutation.init-workflow'
import AnimatedOutlet from '../home-modals-outlet'
import { PortalLink } from '../spaces/components/link-forms'
import {
  useCopyAppMutation,
  useCreateAppTileMutation
} from './components/mutation.create-app-tile'
import { useUpdateAppFavoriteMutation } from './components/mutation.update-app-favorites'
import { useUpdateAppTileMutation } from './components/mutation.update-app-tile'
import { TileEditor } from './components/tile-editor'
import * as tileOptions from './components/tile-options'

export default function HomeOuter () {
  return (
    <>
      <AnimatedOutlet context={useOutletContext()} />
      <Home />
    </>
  )
}

function Home () {
  const { suite } = useParams()
  const { data } = useQuery(homeQuery, { variables: { suite } })
  const tenantFeatures = data?.tenant?.features ?? {}
  return data ? (
    data.viewer.spaces.length ? (
      <HomeNav spaces={data.viewer.spaces} tenantFeatures={tenantFeatures} />
    ) : (
      <div className='flex items-start justify-center pt-36'>
        <Illustrations.NoApps className='relative left-6 mt-32' />
        <div className='inline-block w-72 rounded-lg bg-white px-8 py-10 dark:bg-light-gray-300'>
          <div>
            <h3 className='pb-2 text-xl font-bold'>
              <Trans id='you.do.not.have.access' />
            </h3>
            <div className='text-base'>
              <Trans id='gain.access.contact.admin' />
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <div className='flex h-[calc(100vh-192px)] items-center justify-center md:h-[calc(100vh-80px)]'>
      <Spinner size={200} />
    </div>
  )
}

function useSearcher (query, suite) {
  const debouncedQuery = useDebouncedValue(query, 500)
  const { data, loading } = useQuery(searchQuery, {
    variables: { query: debouncedQuery, suite },
    skip: !query || query !== debouncedQuery
  })

  if (loading) return
  if (!data) return
  return { results: data.searchSpaceContents, query }
}

function HomeNav ({ spaces, tenantFeatures }) {
  const navigate = useNavigate()
  const { spaceId, suite } = useParams()
  const [q, setQ] = React.useState('')
  const searchResults = useSearcher(q, suite)
  React.useEffect(() => {
    if (!spaceId) navigate(`/${suite}/space/${spaces[0].id}`, { replace: true })
  }, [spaceId])

  const ALL_APPS = {
    id: 'all-apps',
    name: i18n._({ id: 'home.all.apps', message: 'All apps' }),
    wrap: name => (
      <div className='flex items-center'>
        <Icons.Archive className='mr-2 fill-medium-gray-400 dark:fill-medium-gray-300' />
        {name}
      </div>
    )
  }

  const FAVORITES = {
    id: 'favorites',
    name: i18n._({ id: 'home.favorites', message: 'Favorites' }),
    wrap: name => (
      <div className='flex items-center'>
        <Icons.FavoriteFilled className='mr-2 fill-medium-gray-400 dark:fill-medium-gray-300' />
        {name}
      </div>
    )
  }

  const MY_APPS = {
    id: 'my-apps',
    name: i18n._({ id: 'home.my.apps', message: 'My apps' }),
    wrap: name => (
      <div className='flex items-center'>
        <Icons.User className='mr-2 fill-medium-gray-400 dark:fill-medium-gray-300' />
        {name}
      </div>
    )
  }

  const SHARED_WITH_ME = {
    id: 'shared-with-me',
    name: i18n._({ id: 'home.shared.with.me', message: 'Shared with me' }),
    wrap: name => (
      <div className='flex items-center'>
        <Icons.Users className='mr-2 fill-medium-gray-400 dark:fill-medium-gray-300' />
        {name}
      </div>
    )
  }

  const winnowedSpaces = winnowList(
    [ALL_APPS, FAVORITES, MY_APPS, SHARED_WITH_ME, ...spaces],
    q,
    'name'
  )

  return (
    <main className='flex h-[calc(100vh-192px)] flex-col overflow-hidden md:h-[calc(100vh-80px)]'>
      <div className='flex h-0 flex-1'>
        <div className='flex w-72 flex-col'>
          {winnowedSpaces.length ? (
            <ul className='flex-1 overflow-auto border-r border-r-light-gray-300 px-6 py-4 text-sm text-dark-gray-500'>
              {winnowedSpaces.map(space => (
                <li key={space.id}>
                  <Link
                    className={cx(
                      'my-1 block break-words rounded-lg px-4 py-2 hover:bg-light-gray-200 dark:hover:bg-light-gray-300',
                      {
                        'bg-light-gray-200 font-medium dark:bg-light-gray-300':
                          spaceId === space.id && !q
                      }
                    )}
                    onClick={() => setQ('')}
                    to={`/${suite}/space/${space.id}`}
                  >
                    {formatName(space, q)}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <h3 className='mr-6 flex-1 border-r border-r-light-gray-300 px-6 pt-14 font-medium'>
              <Trans id='index.spaces.no.search.results' />
            </h3>
          )}
        </div>
        {spaceId ? (
          <div className='flex flex-1 flex-col'>
            <PortalOrange>
              <SearchInput
                value={q}
                onChange={setQ}
                loading={q && q !== searchResults?.query}
              />
            </PortalOrange>
            <HomeInner
              tenantFeatures={tenantFeatures}
              spaces={spaces}
              searchResults={searchResults}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    </main>
  )
}

function formatName (space, q) {
  const name = emphasizeResult(space.name, q)
  if (space.suite && productBuilder) {
    return (
      <div className='flex items-center gap-4'>
        <img
          className='h-4 w-4'
          src={`${process.env.PUBLIC_URL}/icons-suite/${space.suite.icon}.svg`}
        />
        {space.suite.name || name}
      </div>
    )
  }
  if (space.wrap) return space.wrap(name)
  return name
}

function HomeInner ({ tenantFeatures, spaces, searchResults }) {
  const { spaceId, suite } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useQuery(spaceQuery, {
    variables: { spaceId, suite },
    fetchPolicy: 'cache-and-network'
  })
  const [selected, setSelected] = React.useState(null)
  const [appDetails, setAppDetails] = React.useState({})
  const [navigateTo, setNavigateTo] = React.useState(null)
  const { toApp } = useTileTransition()
  const [hasError, setHasError] = React.useState(false)
  const createApp = useCreateAppTileMutation()
  const updateApp = useUpdateAppTileMutation()
  const updateFavorite = useUpdateAppFavoriteMutation(spaceId)
  const copyApp = useCopyAppMutation()
  const { finish, appId } = useTileTransition()
  const initWorkflow = useInitWorkflow()

  const handleError = err => {
    setHasError(true)
    throw err
  }
  const select = app => {
    setAppDetails(getAppProps(app))
    setHasError(false)
    setSelected(app.id)
  }
  const deselect = () => setSelected(null)

  React.useEffect(() => {
    if (!loading && appId) {
      const el = document.getElementById(`app-${appId}`)
      const dimensions = el && el.getBoundingClientRect()
      finish(dimensions)
    }
  }, [loading])

  React.useEffect(() => {
    if (!navigateTo) return
    const app = navigateTo
    const el = document.getElementById(`app-${app.id}`)
    const dimensions = el?.getBoundingClientRect()
    if (!dimensions) return
    setNavigateTo(null)
    toApp({ app, dimensions, to: routes.form(app.id, app.firstPageId) })
  })

  if (!data?.space) {
    return (
      <div className='flex flex-1 items-center justify-center'>
        <Spinner size={200} />
      </div>
    )
  }

  let { apps: allApps, links, viewer } = data.space
  const { canCreateApps, canCreateProducts } = viewer

  let spaceNameMap
  if (searchResults) {
    spaceNameMap = mapValues(keyBy(spaces, 'id'), 'name')
    spaceNameMap[''] = 'Home'
    ;[allApps, links] = partition(searchResults.results, { __typename: 'App' })
  }

  if (!allApps.length && !links.length) {
    if (searchResults) {
      return (
        <div className='flex flex-1 flex-col'>
          <h3 className='flex items-center gap-2 pt-14 font-medium'>
            <Trans id='no.search.results' />
          </h3>
        </div>
      )
    }
    if (spaceId === 'all-apps') {
      return (
        <div className='flex flex-1 flex-col items-center pt-14 text-center'>
          <h3 className='text-lg font-medium'>
            <Trans id='no.apps' />
          </h3>
          <p>
            <Trans id='all.apps.access.here' />
          </p>
        </div>
      )
    }
    if (spaceId === 'favorites') {
      return (
        <div className='flex flex-1 flex-col items-center pt-14 text-center'>
          <h3 className='text-lg font-medium'>
            <Trans id='no.apps.favorited' />
          </h3>
          <p>
            <Trans id='apps.you.favorite.show.here' />
          </p>
        </div>
      )
    }
    if (spaceId === 'my-apps') {
      return (
        <div className='flex flex-1 flex-col items-center pt-14 text-center'>
          <h3 className='text-lg font-medium'>
            <Trans id='no.apps.created' />
          </h3>
          <p>
            <Trans id='apps.you.create.show.here' />
          </p>
        </div>
      )
    }
    if (spaceId === 'shared-with-me') {
      return (
        <div className='flex flex-1 flex-col items-center pt-14 text-center'>
          <h3 className='text-lg font-medium'>
            <Trans id='no.apps.shared' />
          </h3>
          <p>
            <Trans id='apps.shared.show.here' />
          </p>
        </div>
      )
    }
  }

  const [products, apps] = partition(allApps, app =>
    ['product', 'productTemplate'].includes(app.type)
  )

  const showNewAppButton =
    canCreateApps && !tenantFeatures?.appCreationDisabled && !searchResults
  const showNewProductButton =
    canCreateProducts &&
    productBuilder &&
    !tenantFeatures?.appCreationDisabled &&
    !searchResults
  const isKualiAdmin = data?.viewer?.isKualiAdmin

  return (
    <div className='flex h-full flex-1 flex-col gap-10 overflow-auto p-6'>
      {(!!products.length || showNewProductButton) && (
        <div className='flex flex-wrap gap-6'>
          {products.map(app => {
            const to = getLink(app)
            if (!to) return null
            return (
              <App
                key={app.id}
                id={app.id}
                type={app.type}
                canFavorite
                isFavorite={app.isFavorite}
                firstPageId={app.firstPageId}
                {...(selected === app.id ? appDetails : getAppProps(app))}
                spaceName={searchResults && spaceNameMap[app.spaceId || '']}
                onClick={
                  to.includes('/run')
                    ? () =>
                        initWorkflow(app.id, app.firstPageId)
                          .then(actionId => navigate(`action/${actionId}`))
                          .catch(() => null)
                    : dimensions => toApp({ app, dimensions, to })
                }
                to={to}
                toggleFavorite={() =>
                  updateFavorite(app.id, !app.isFavorite).catch(handleError)
                }
              >
                {isKualiAdmin && (
                  <TileEditor
                    label={`edit ${app.name}`}
                    isProduct
                    value={appDetails}
                    onChange={setAppDetails}
                    defaultShow={selected === app.id}
                    hasError={hasError}
                    onSave={hide => {
                      updateApp(app.id, appDetails).catch(handleError)
                      hide()
                    }}
                    onOpen={() => select(app)}
                    onClose={deselect}
                    appId={app.id}
                    appCreationDisabled={tenantFeatures?.appCreationDisabled}
                  />
                )}
              </App>
            )
          })}
          {selected === 'CREATE_PRODUCT' && (
            <App {...appDetails} type='product' id='newly-created'>
              <TileEditor
                isProduct
                value={appDetails}
                onChange={setAppDetails}
                hasError={hasError}
                defaultShow
                onSave={hide =>
                  createApp(appDetails, 'product')
                    .catch(handleError)
                    .then(({ data }) => {
                      hide()
                      setNavigateTo(data.data)
                    })
                }
                onClose={deselect}
                appCreationDisabled={tenantFeatures?.appCreationDisabled}
              />
            </App>
          )}
          {showNewProductButton && (
            <App
              id='createProduct'
              type='product'
              name={i18n._('index.spaces.new.product')}
              newButton
              onClick={() => {
                setAppDetails({
                  name: '',
                  backgroundColor: sample(tileOptions.colors),
                  iconName: 'pylon',
                  spaceId: spaceId !== 'home' ? spaceId : undefined
                })
                setHasError(false)
                setSelected('CREATE_PRODUCT')
              }}
            />
          )}
        </div>
      )}
      {(!!apps.length || showNewAppButton) && (
        <div className='flex flex-wrap gap-6'>
          {apps.map(app => {
            const to = getLink(app)
            if (!to) return null
            const onCopy =
              app.viewer.canDuplicate && (async () => copyApp(app.id, {}))
            return (
              <App
                key={app.id}
                id={app.id}
                type={app.type}
                canFavorite
                isFavorite={app.isFavorite}
                firstPageId={app.firstPageId}
                {...(selected === app.id ? appDetails : getAppProps(app))}
                spaceName={searchResults && spaceNameMap[app.spaceId || '']}
                onClick={
                  to.includes('/run')
                    ? () =>
                        initWorkflow(app.id, app.firstPageId)
                          .then(actionId => navigate(`action/${actionId}`))
                          .catch(() => null)
                    : dimensions => toApp({ app, dimensions, to })
                }
                to={to}
                toggleFavorite={() =>
                  updateFavorite(app.id, !app.isFavorite).catch(handleError)
                }
              >
                {app.viewer.canManage && (
                  <TileEditor
                    label={`edit ${app.name}`}
                    value={appDetails}
                    onChange={setAppDetails}
                    defaultShow={selected === app.id}
                    hasError={hasError}
                    onSave={hide => {
                      updateApp(app.id, appDetails).catch(handleError)
                      hide()
                    }}
                    onOpen={() => select(app)}
                    onClose={deselect}
                    onCopy={onCopy}
                    appId={app.id}
                    appCreationDisabled={tenantFeatures?.appCreationDisabled}
                  />
                )}
              </App>
            )
          })}
          {selected === 'CREATE' && (
            <App {...appDetails} id='newly-created'>
              <TileEditor
                value={appDetails}
                onChange={setAppDetails}
                hasError={hasError}
                defaultShow
                onSave={hide =>
                  createApp(appDetails)
                    .catch(handleError)
                    .then(({ data }) => {
                      hide()
                      setNavigateTo(data.data)
                    })
                }
                onClose={deselect}
                appCreationDisabled={tenantFeatures?.appCreationDisabled}
              />
            </App>
          )}
          {showNewAppButton && (
            <App
              id='create'
              name={i18n._('index.spaces.new.app')}
              newButton
              onClick={() => {
                setAppDetails({
                  name: '',
                  backgroundColor: sample(tileOptions.colors),
                  iconName: sample(tileOptions.icons),
                  spaceId: spaceId !== 'home' ? spaceId : undefined
                })
                setHasError(false)
                setSelected('CREATE')
              }}
            />
          )}
        </div>
      )}
      {tenantFeatures.portals && !!links.length && (
        <div className='flex flex-wrap gap-6'>
          {links.map(link => (
            <PortalLink
              key={link.id}
              link={link}
              spaceName={searchResults && spaceNameMap[link.spaceId2 || '']}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const App = ({
  id,
  type,
  firstPageId,
  name,
  spaceName,
  backgroundColor,
  newButton,
  iconName,
  children,
  onClick,
  to,
  canFavorite,
  isFavorite,
  toggleFavorite
}) => {
  const ref = React.useRef()
  const isRunPage = to?.startsWith('/run')
  const isProduct = ['product', 'productTemplate'].includes(type)
  const Wrapper = to ? Link : 'button'
  return (
    <Wrapper
      className='group relative flex w-[120px] cursor-pointer flex-col rounded-lg text-center'
      onDragStart={/* istanbul ignore next */ e => e.preventDefault()}
      to={to}
      target={isRunPage ? '_blank' : '_self'}
      aria-label={name || <Trans id='untitled.app' />}
      onClick={
        onClick &&
        (e => {
          if (!to) {
            onClick()
          } else if (e.altKey) {
            e.preventDefault()
            window.open(routes.run(id, firstPageId), '_blank')
          } else if (!e.ctrlKey && !e.metaKey && !isRunPage) {
            e.preventDefault()
            onClick(ref.current.getBoundingClientRect())
          }
        })
      }
    >
      {isProduct && (
        <div className='absolute -right-3 -top-3 z-10 flex h-10 w-10 items-center justify-center rounded-full bg-dark-gray-300 dark:bg-medium-gray-300'>
          <svg
            width='16px'
            height='16px'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <polygon fill='white' points='0 0 0 20 20 20 10 10 20 0' />
          </svg>
        </div>
      )}
      <div
        style={!newButton ? { background: backgroundColor } : {}}
        className={cx(
          newButton && 'bg-[#e4e4e4] dark:bg-light-gray-300',
          'flex h-[120px] w-[120px] items-center justify-center rounded-lg transition-all group-hover:shadow-lg group-hover:brightness-90'
        )}
        ref={ref}
        id={`app-${id}`}
      >
        <AppIcon
          iconName={iconName}
          isCreate={id.startsWith('create')}
          isProduct={isProduct}
          className='!w-20'
        />
      </div>
      <div
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onKeyUp={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {canFavorite && (
          <button
            className='kp-button-transparent kp-button-lg kp-button-icon absolute left-1 top-1 opacity-0 hover:bg-transparent focus:opacity-100 active:border-transparent group-hover:opacity-100 dark:hover:bg-transparent'
            aria-label={`${
              isFavorite ? <Trans id='unfavorite' /> : <Trans id='favorite' />
            } ${name}`}
            onClick={toggleFavorite}
          >
            <div
              className={cx('flex rounded-full bg-white p-2 dark:bg-black', {
                'opacity-60': !isFavorite
              })}
            >
              <Icons.FavoriteFilled
                className='h-3 w-3'
                style={{ fill: isFavorite ? 'red' : backgroundColor }}
              />
            </div>
          </button>
        )}
        {children}
      </div>
      <label className='mt-2 w-full cursor-pointer break-words text-sm font-bold text-dark-gray-100'>
        {name || 'Untitled App'}
      </label>
      {spaceName && (
        <label className='break-all pt-1 text-xs text-medium-gray-300'>
          ({spaceName})
        </label>
      )}
    </Wrapper>
  )
}

function SearchInput ({ onChange, value, loading }) {
  const [expanded, setExpanded] = React.useState(false)
  const ref = React.useRef()
  return (
    <div className='flex items-center lg:-ml-[300px]'>
      <div
        className={cx(
          'relative mt-4 w-full max-w-full overflow-hidden p-1 transition-all md:mt-0',
          { 'md:ml-[199px] md:w-0 md:p-0': !expanded, 'md:w-72': expanded }
        )}
      >
        <label className='sr-only' htmlFor='search-bar'>
          <Trans id='index.spaces.search.apps' />
        </label>
        {loading ? (
          <Spinner size={16} className='absolute left-3 top-3' />
        ) : (
          <Icons.Search className='absolute left-3 top-3 fill-light-gray-500 dark:fill-medium-gray-300' />
        )}
        <input
          ref={ref}
          tabIndex={expanded ? 0 : -1}
          id='search-bar'
          className='kp-input w-full pl-8 text-black outline-none ring-4 focus:ring-aqua-200'
          role='search'
          placeholder={i18n._('index.spaces.search')}
          onKeyUp={e => {
            if (e.key === 'Escape') {
              if (value) onChange('')
              else setExpanded(false)
            }
          }}
          onChange={e => onChange(e.target.value)}
          value={value}
        />
        <button
          aria-label={i18n._('clear.search')}
          tabIndex={expanded ? 0 : -1}
          className={cx(
            'kp-button-transparent kp-button-icon absolute right-1 top-1 hover:bg-transparent active:border-transparent md:block',
            { hidden: !value, block: value }
          )}
          onClick={() => {
            if (!value) return setExpanded(false)
            onChange('')
            ref.current.focus()
          }}
        >
          <Icons.Close width={10} height={10} />
        </button>
      </div>
      <button
        tabIndex={expanded ? -1 : 0}
        className={cx(
          'hidden items-center gap-2 overflow-hidden rounded-lg py-1 transition-all hover:bg-[rgba(0,0,0,0.2)] md:flex',
          { 'w-0 px-0': expanded, 'w-[89px] px-2': !expanded }
        )}
        onClick={() => {
          setExpanded(true)
          ref.current.focus()
        }}
      >
        <Icons.Search className='fill-current' />
        <Trans id='index.spaces.search' />
      </button>
    </div>
  )
}

const homeQuery = gql`
  query HomePage($suite: String!) {
    tenant {
      id
      features {
        spaces
        portals
        appCreationDisabled
      }
    }
    viewer {
      id
      spaces(type: HOME, suite: $suite) {
        id
        name
        suite {
          id
          name
          icon
        }
      }
    }
  }
`

const searchQuery = gql`
  query SearchSpaces($query: String!, $suite: String!) {
    searchSpaceContents(query: $query, suite: $suite) {
      ... on App {
        id
        spaceId
        name
        type
        firstPageId
        isFavorite
        tileOptions {
          backgroundColor
          iconName
        }
        viewer {
          canDuplicate
          canViewDocuments
          canSubmitDocuments
          canManage
        }
      }
      ... on Link {
        id
        spaceId2: spaceId
        title
        description
        url
        imageUrl
        createdAt
      }
      __typename
    }
  }
`

const spaceQuery = gql`
  query GetSpaceData($spaceId: ID!, $suite: String!) {
    space: spacePortal(id: $spaceId, suite: $suite) {
      id
      apps {
        id
        name
        type
        firstPageId
        isFavorite
        tileOptions {
          backgroundColor
          iconName
        }
        viewer {
          canDuplicate
          canViewDocuments
          canSubmitDocuments
          canManage
        }
      }
      viewer {
        id
        canCreateApps
        canCreateProducts
      }
      links {
        id
        title
        description
        url
        imageUrl
        createdAt
      }
    }
    viewer {
      id
      isKualiAdmin
    }
  }
`

const getAppProps = app => ({
  name: app.name,
  backgroundColor: app.tileOptions?.backgroundColor,
  iconName: app.tileOptions?.iconName
})

const getLink = ({ id, viewer, firstPageId }) => {
  if (viewer.canViewDocuments) return routes.documentList(id, firstPageId)
  if (viewer.canManage) return routes.form(id, firstPageId)
  if (viewer.canSubmitDocuments) return routes.run(id, firstPageId)
  return null
}
