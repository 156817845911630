/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import _ from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import shortid from 'shortid'

import * as Icons from '../../../../icons'
import { GadgetDropdown, Label } from '../../components'

const INVALID_TYPES = ['Table', 'Repeater', 'Rules', 'Iframe', 'DataLink']

const newColumn = () => ({
  id: shortid.generate(),
  label: '',
  formKey: '',
  type: ''
})

export default function Config ({ details, setDetails, loading, gadgets }) {
  gadgets = _.filter(gadgets, g => !INVALID_TYPES.includes(g.type))
  const gadgetMap = _.keyBy(gadgets, 'formKey')
  return (
    <>
      <div>
        <Label>
          <Trans id='pagesbuilder.dashboard.columns' />
        </Label>
        <DragDropContext
          onDragEnd={result => {
            if (!result.destination) return
            setDetails(d => {
              const details = _.cloneDeep(d)
              const { id } = details.columns[result.source.index]
              const i = details.columns.findIndex(c => c.id === id)
              const [column] = details.columns.splice(i, 1)
              details.columns.splice(result.destination.index, 0, column)
              return details
            })
          }}
        >
          <Droppable droppableId='table-insight'>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {details.columns.map((column, i) => (
                  <Draggable key={column.id} draggableId={column.id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className='py-2'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className='flex items-center gap-3 rounded bg-white px-4 py-2'>
                          <div {...provided.dragHandleProps}>
                            <Icons.Drag />
                          </div>
                          <div className='flex flex-1 flex-col'>
                            <label>Column {i + 1}</label>
                            <GadgetDropdown
                              className='mb-2'
                              gadgets={gadgets}
                              value={column.formKey}
                              onChange={gadget => {
                                setDetails(d => {
                                  const details = _.cloneDeep(d)
                                  const col = details.columns[i]
                                  col.formKey = gadget
                                  col.type = gadgetMap[gadget].type
                                  col.label = gadgetMap[gadget].label
                                  return details
                                })
                              }}
                            />
                            <label>Label</label>
                            <input
                              className='kp-input'
                              value={column.label}
                              onChange={e =>
                                setDetails(d => {
                                  const details = _.cloneDeep(d)
                                  details.columns[i].label = e.target.value
                                  return details
                                })
                              }
                            />
                          </div>
                          <button
                            className='kp-button-transparent kp-button-icon'
                            type='button'
                            onClick={() =>
                              setDetails(d => {
                                const details = _.cloneDeep(d)
                                details.columns.splice(i, 1)
                                return details
                              })
                            }
                          >
                            <Icons.Remove />
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <button
          className='kp-button-solid gap-2'
          type='button'
          onClick={() =>
            setDetails(d => {
              const details = _.cloneDeep(d)
              details.columns.push(newColumn())
              return details
            })
          }
        >
          <Icons.Add />
          <Trans id='pagesbuilder.dashboard.add.column' />
        </button>
      </div>

      <div>
        <Label>
          <Trans id='pagesbuilder.dashboard.sort.header' />
        </Label>
        <select
          className='kp-select'
          disabled={!gadgets.length}
          value={details.sortField}
          onChange={e => setDetails(d => ({ ...d, sortField: e.target.value }))}
        >
          <option value=''>
            {!gadgets.length && i18n._('pagesbuilder.dashboard.select.dataset')}
          </option>
          {details.columns.map(column => (
            <option key={column.id} value={column.formKey}>
              {column.label}
            </option>
          ))}
        </select>
        <select
          className='kp-select mt-2'
          value={details.sortDirection}
          onChange={e =>
            setDetails(d => ({ ...d, sortDirection: e.target.value }))
          }
        >
          <option value='asc'>
            <Trans id='pagesbuilder.dashboard.sort.ascending' />
          </option>
          <option value='desc'>
            <Trans id='pagesbuilder.dashboard.sort.descending' />
          </option>
        </select>
      </div>
    </>
  )
}
