/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export function addImagesToBody (body, imageCache) {
  const matches = [...body.matchAll(/<a id="(.*?)".*?<\/a>/g)]
  let updatedBody = body
  matches.forEach(match => {
    const formKey = match[1].split('.')[1]
    updatedBody = updatedBody.replace(
      /<a id="(.*?)".*?<\/a>/,
      `<img src="${
        imageCache[`${formKey}-zoomed`]
      }" max-width="200px" style="max-width:200px" />`
    )
  })
  return updatedBody
}
