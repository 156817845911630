/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { forEachRight, values } from 'lodash'
import shortid from 'shortid'

import steps from '../steps'

export default function validate (workflow, formSchema = []) {
  const allErrors = []
  iterateSteps(workflow.steps, (step, lineage) => {
    const manifest = steps[step.type]
    if (!manifest.validate) return
    const errors = manifest.validate(
      step,
      [...values(workflow.schema), ...formSchema],
      lineage
    )
    forEachRight(errors, error => {
      allErrors.push({
        id: shortid.generate(),
        stepId: step._id,
        stepName: step.stepName,
        error: error.message
      })
    })
  })
  return allErrors
}

const iterateSteps = (steps, cb, lineage = []) => {
  forEachRight(steps, step => {
    if (step.subflows) {
      forEachRight(step.subflows, subflow => {
        iterateSteps(subflow.steps, cb, [...lineage, step._id])
      })
    }
    cb(step, lineage)
  })
}
