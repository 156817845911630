/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import _ from 'lodash'
import React from 'react'

export default function Legend ({ ports }) {
  return (
    <div className='pointer-events-none absolute bottom-2 left-2 grid grid-cols-2 p-2 text-xs text-medium-gray-400 before:absolute before:left-0 before:top-0 before:h-full before:w-full before:rounded before:bg-black before:opacity-60'>
      {_.map(_.reject(ports, 'hideFromLegend'), ({ label, color }, key) => (
        <div key={key} className='relative flex items-center pr-2'>
          <div className='IoPorts_port__1_a6J !mr-4' data-port-color={color} />
          {label}
        </div>
      ))}
    </div>
  )
}
