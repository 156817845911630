/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled, { keyframes } from 'styled-components'

import { ReactComponent as SimulateCompleted } from './simulate-complete.svg.jsx'
import { ReactComponent as Sparkle } from './sparkle.svg.jsx'

export default () => {
  return (
    <Wrapper>
      <Sparkle1 />
      <Sparkle2 />
      <Sparkle3 />
      <SimulateCompleted />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const sparkle = keyframes`
  40% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  60% {
    opacity: 0
  }
`

const Spark = styled(Sparkle)`
  position: absolute;
  opacity: 0;
  animation: 2s linear infinite ${sparkle};
`

const Sparkle1 = styled(Spark)`
  left: 2px;
  top: 10px;
  animation-delay: 0ms;
`
const Sparkle2 = styled(Spark)`
  right: 15px;
  animation-delay: 300ms;
`
const Sparkle3 = styled(Spark)`
  right: 0;
  top: 15px;
  animation-delay: 150ms;
`
