/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import * as Icons from '../icons'

export default ({ className, inputClassName, onChange, value, ...props }) => {
  const ref = React.useRef()
  return (
    <div className={cx('flex justify-end', className)}>
      <div className='relative flex w-96 max-w-full'>
        <Icons.Search className='absolute left-2 top-2 fill-light-gray-500 dark:fill-medium-gray-300' />
        <input
          ref={ref}
          role='search'
          placeholder={i18n._('search')}
          className={cx(
            'h-8 w-full min-w-0 rounded border border-light-gray-500 bg-light-gray-100 pl-8 pr-2 text-sm dark:bg-light-gray-300',
            inputClassName
          )}
          onKeyUp={e => {
            if (e.keyCode === 27 || e.key === 'esc') onChange('')
          }}
          onChange={e => onChange(e.target.value)}
          value={value}
          {...props}
        />
        {value && (
          <button
            className='kp-button-transparent kp-button-icon absolute right-0 top-0 dark:hover:bg-light-gray-400'
            onClick={() => {
              onChange('')
              ref.current.focus()
            }}
          >
            <span className='sr-only'>
              <Trans id='clear.search' />
            </span>
            <Icons.Close width={10} height={10} />
          </button>
        )}
      </div>
    </div>
  )
}
