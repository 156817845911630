/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'
import Button from '../../ui/button'
import Input from '../../ui/input'
import { Flex } from '../../ui/layout'

export default [
  {
    id: 'dataset',
    label: 'Standard Data Set',
    getUrl: (_, url) => {
      if (url.endsWith('/external') || url.endsWith('/dashboard')) {
        return ['INTERNAL', url.replace(/\/[a-z-]+$/, '/document-list')]
      }
      return ['INTERNAL', url]
    },
    Config: () => <div />
  },
  {
    id: 'dashboard',
    label: <Trans id='dashboard' />,
    getUrl: (_, url) => {
      if (url.endsWith('/dashboard')) return ['INTERNAL', url]
      return ['INTERNAL', url.replace(/\/[a-z-]+$/, '/dashboard')]
    },
    Config: () => <div />
  },
  {
    id: 'link',
    label: <Trans id='external.link' />,
    getUrl: dataset => ['EXTERNAL', dataset.details.href.value],
    Config: ({ value: { href }, onChange }) => (
      <EditMenuBlock>
        <label>
          <Trans id='link.href' />
        </label>
        <Flex>
          <Input
            value={href.value}
            onChange={value => onChange({ href: { ...href, value } })}
          />
          <Button
            transparent
            small
            icon
            onClick={() =>
              onChange({ href: { ...href, locked: !href.locked } })
            }
          >
            {href.locked ? <Icons.Lock fill='#C83397' /> : <Icons.Unlock />}
          </Button>
        </Flex>
      </EditMenuBlock>
    )
  },
  {
    id: 'iframe',
    label: 'Iframe',
    helpLink: 'https://docs.kualibuild.ninja/platform/external-components',
    getUrl: (_, url) => {
      if (url.endsWith('/external')) return ['INTERNAL', url]
      return ['INTERNAL', url.replace(/\/[a-z-]+$/, '/external')]
    },
    Config: ({ value: { src }, onChange }) => (
      <EditMenuBlock>
        <label>Iframe URL</label>
        <Flex>
          <Input
            value={src.value}
            onChange={value => onChange({ src: { ...src, value } })}
          />
          <Button
            transparent
            small
            icon
            onClick={() => onChange({ src: { ...src, locked: !src.locked } })}
          >
            {src.locked ? <Icons.Lock fill='#C83397' /> : <Icons.Unlock />}
          </Button>
        </Flex>
      </EditMenuBlock>
    )
  },
  {
    id: 'heading',
    label: <Trans id='heading' />,
    getUrl: () => ['NONE'],
    Config: () => <div />
  },
  {
    id: 'jsComponent',
    label: 'JS Component',
    helpLink: 'https://docs.kualibuild.ninja/platform/external-components',
    getUrl: (_, url) => {
      if (url.endsWith('/external')) return ['INTERNAL', url]
      return ['INTERNAL', url.replace(/\/[a-z-]+$/, '/external')]
    },
    Config: ({ value: { src }, onChange }) => (
      <EditMenuBlock>
        <label>JS Component URL</label>
        <Flex>
          <Input
            value={src.value}
            onChange={value => onChange({ src: { ...src, value } })}
          />
          <Button
            transparent
            small
            icon
            onClick={() => onChange({ src: { ...src, locked: !src.locked } })}
          >
            {src.locked ? <Icons.Lock fill='#C83397' /> : <Icons.Unlock />}
          </Button>
        </Flex>
      </EditMenuBlock>
    )
  }
]

const EditMenuBlock = styled.div`
  padding: 8px 16px 0;
`
