/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { DatePicker } from '../../../components/temporal-pickers'
import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { useFormbotData } from '../../engine/formbot-react/hooks'
import { getDateObject } from './util'

export default function DateEdit ({
  a11yDesc,
  details,
  id,
  value,
  onChange,
  ...props
}) {
  const formbotData = useFormbotData()
  const data = React.useMemo(
    () => ({
      ...formbotData,
      meta: {
        ...formbotData?.meta,
        submittedAt: formbotData?.meta?.submittedAt ?? Date.now()
      }
    }),
    [formbotData]
  )
  const handleChange = dateObject => {
    if (!dateObject) return onChange(null)
    // convert dateObject to milliseconds: 1543536000000
    // to simplify sorting and filtering

    // Create UTC date with time = midnight
    const utcDate = Date.UTC(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate()
    )
    onChange(utcDate)
  }

  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const [minDate, maxDate] = getMinMaxDates(details, data)
  return (
    <Group
      onKeyUp={e => {
        if (e.key === 'Escape') e.stopPropagation()
      }}
    >
      <DatePicker
        id={id}
        placeholder={placeholder}
        onChange={handleChange}
        value={getDateObject(value)}
        minDate={minDate}
        maxDate={maxDate}
      />
      {value && (
        <Button
          icon
          transparent
          onClick={() => handleChange(null)}
          aria-label={i18n._('clear.selected.date')}
        >
          <Icons.Close />
        </Button>
      )}
    </Group>
  )
}

function getMinMaxDates (details, data) {
  if (!details?.limitDates?.enabled) return [null, null]
  if (details.limitDates.value?.type === 'rolling') {
    return [
      getRollingDate(get(details, 'limitDates.value.rolling.min'), data),
      getRollingDate(get(details, 'limitDates.value.rolling.max'), data)
    ]
  }
  return [
    getDateObject(get(details, 'limitDates.value.min')),
    getDateObject(get(details, 'limitDates.value.max'))
  ]
}

function getRollingDate ({ from, offset } = {}, data) {
  if (!from) return null
  const value = getDateObject(get(data, from))
  if (!value) return null
  value.setDate(value.getDate() + (offset ?? 0))
  return value
}

const Group = styled.div`
  display: flex;
  flex-direction: row;
  & > :first-child {
    flex-grow: 1;
  }
`
