/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { find, get, includes, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Lookups from '../../../components/lookup-controls'
import Spinner from '../../../components/spinner'
import { useAppIds } from '../../../components/use-app-ids-context'
import useDebouncedValue from '../../../components/use-debounced-value'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import { Option, Select } from '../../../ui/select'
import { useInputs } from '../../integration-utils'

export default function IntegrationTypeahead (props) {
  const hasSearch = props.details?.searchParameter
  return hasSearch ? <Typeahead {...props} /> : <Dropdown {...props} />
}

function Dropdown ({ value, onChange, details, ...props }) {
  const id = details?.id
  const inputs = useInputs(details)
  const { data, loading, error, refetch } = useIntegrations(id, inputs, details)
  React.useEffect(() => {
    if ((error || inputs === null) && value !== null) onChange(null)
  }, [error, inputs, onChange, value])
  if (error || inputs === null) return null
  const options = map(data, item => ({ ...item, id: String(item.id) }))
  return (
    <>
      {!!details.testIntegration && inputs !== null && (
        <RefreshButton
          onClick={() => {
            onChange(null)
            refetch()
          }}
        >
          Refetch
        </RefreshButton>
      )}
      {loading && (
        <div className='absolute right-4 top-4 pl-2'>
          <Spinner size={16} />
        </div>
      )}
      <Select
        aria-labelledby={props['aria-labelledby']}
        {...(props['aria-describedby'] && {
          'aria-describedby': props['aria-describedby']
        })}
        aria-required={props.required}
        className={props.className}
        id={props.id}
        onChange={id => onChange(find(options, { id }))}
        value={get(value, 'id')}
      >
        <Option value='' />
        {!loading &&
          map(options, ({ id, label }) => (
            <Option key={id} value={id}>
              {label}
            </Option>
          ))}
      </Select>
    </>
  )
}

function Typeahead ({ value, onChange, details, ...props }) {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { id, searchParameter } = details
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const searchParam = searchParameter.id.replace(/^(url|query):/, '')
  let inputs = useInputs(details)
  if (searchParameter.required && !debouncedQuery) inputs = null
  if (inputs) inputs = { ...inputs, [searchParam]: debouncedQuery }
  const { data, loading } = useIntegrations(id, inputs, details)
  const options = map(data, item => ({ ...item, id: String(item.id) }))
  return (
    <Lookups.Typeahead
      id={props.id}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      value={value}
      query={query}
      setQuery={setQuery}
      aria={{
        labelledby: props['aria-labelledby'],
        describedby: props['aria-describedby']
      }}
      gridded={props.gridded}
      loading={loading}
    />
  )
}

const useIntegrations = (id, inputs, details) => {
  const alerts = useAlerts()
  const [refetching, setRefetching] = React.useState(false)
  const { appId, actionId } = useAppIds()
  const isTesting = !!details.testIntegration
  let q = getInvokeIntegrationQuery({ appId, actionId, id, data: inputs })
  if (isTesting) q = getTestIntegrationQuery(id, inputs, details, alerts)
  const { data, error, loading, refetch } = useQuery(...q)
  const app = actionId ? data?.action?.document?.app : data?.app
  let options = app?.sharedWithMe?.integration?.invoke ?? []
  if (isTesting) {
    options = formatTestData(
      data?.testFetchIntegration,
      details.testIntegration
    )
  }
  return {
    data: options,
    error,
    loading: loading || refetching,
    refetch: () => {
      setRefetching(true)
      refetch()
        .then(resp => details.testReport(resp.data.testFetchIntegration))
        .catch(error => {
          alerts.type3(error.message, 'error')
          details.testReport({})
        })
        .finally(() => setRefetching(false))
    }
  }
}

const invokeIntegtationQuery = gql`
  query InvokeIntegrationQuery($appId: ID!, $id: ID!, $data: JSON) {
    app(id: $appId) {
      id
      sharedWithMe {
        integration(id: $id) {
          id
          invoke(data: $data)
        }
      }
    }
  }
`

const invokeIntegrationWithActionQuery = gql`
  query InvokeIntegrationWithActionQuery(
    $actionId: String!
    $id: ID!
    $data: JSON
  ) {
    action(actionId: $actionId) {
      id
      document {
        id
        app {
          id
          sharedWithMe {
            integration(id: $id) {
              id
              invoke(data: $data)
            }
          }
        }
      }
    }
  }
`

const getInvokeIntegrationQuery = ({ appId, actionId, id, data }) => [
  actionId ? invokeIntegrationWithActionQuery : invokeIntegtationQuery,
  {
    variables: { appId, actionId, id, data },
    skip: (!appId && !actionId) || !id || data === null
  }
]

const getTestIntegrationQuery = (id, data, details, alerts) => [
  gql`
    query TestIntegration($id: ID, $integration: JSON!, $data: JSON) {
      testFetchIntegration(
        args: { id: $id, integration: $integration, data: $data }
      ) {
        status
        headers
        data
      }
    }
  `,
  {
    fetchPolicy: 'network-only',
    variables: { id, data, integration: details.testIntegration },
    skip: !details.testIntegration || data === null,
    onCompleted: data => details.testReport(data.testFetchIntegration),
    onError: error => {
      alerts.type3(error.message, 'error')
      details.testReport({})
    }
  }
]

const str = value => (value == null ? value : String(value))
const formatTestData = ({ data, headers, status } = {}, integration) => {
  if (!data || typeof data !== 'object') return null
  if (!(status >= 200 && status < 300)) return null

  const contentTypes = get(headers, 'content-type')
  if (!includes(contentTypes, 'json')) {
    return null
  }
  const results = integration.__path ? get(data, integration.__path, []) : data
  const idPath = integration?.__idKey || 'id'
  const labelPath = integration?.__labelKey || 'label'
  return map(results, value => {
    const id = str(get(value, idPath))
    const label = str(get(value, labelPath))
    return { id, label, data: value }
  })
}

const RefreshButton = styled(Button)`
  position: absolute;
  top: -44px;
  right: 14px;
`
