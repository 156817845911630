/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import Spinner from '../../components/spinner'

export default function WithCommentContent ({
  children,
  required,
  label,
  onCancel,
  onClick,
  submitting,
  width
}) {
  const [data, setData] = React.useState({})
  const invalid =
    required === true ? !data.comment : required?.some?.(field => !data[field])
  return (
    <>
      <div className='block bg-light-gray-100 px-4 py-2 text-sm uppercase text-medium-gray-500 dark:bg-light-gray-300 dark:text-dark-gray-500'>
        {label}
      </div>
      <div className='p-4' data-testid={`popover-${label}`} style={{ width }}>
        {children?.(data, setData)}
        <label
          className={cx('block text-sm text-medium-gray-500', {
            'after:ml-1 after:text-red-400 after:content-["*"]':
              required === true || required?.includes('comment')
          })}
          htmlFor='comment'
          required={required === true || required?.includes('comment')}
        >
          <Trans id='pagesrunner.action.comment.add' />
        </label>
        <textarea
          className='kp-textarea w-full'
          id='comment'
          rows={3}
          onChange={e => setData({ ...data, comment: e.target.value })}
        />
        <div className='flex gap-2'>
          <button
            className='kp-button-outline w-full'
            disabled={submitting}
            onClick={onCancel}
          >
            <Trans id='cancel' />
          </button>
          <button
            className='kp-button-solid w-full dark:disabled:text-black'
            disabled={invalid || submitting}
            title={
              invalid
                ? i18n._('pagesrunner.action.comment.required')
                : undefined
            }
            onClick={e => {
              e.preventDefault()
              onClick(data)
            }}
          >
            {submitting ? <Spinner size={20} /> : label}
          </button>
        </div>
      </div>
    </>
  )
}
