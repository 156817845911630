/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useRemoveAppConditionalPermissionMutation = (appId, datasetId) => {
  const [removeAppConditionalPermission, other] = useMutation(mutation)

  return [
    id => removeAppConditionalPermission(getParams(appId, datasetId, id)),
    other
  ]
}

const mutation = gql`
  mutation RemoveAppConditionalPermission(
    $appId: ID!
    $datasetId: ID
    $id: ID!
  ) {
    removeAppConditionalPermission(
      appId: $appId
      datasetId: $datasetId
      id: $id
    ) {
      id
      status
      dataset {
        id
        conditionalPermissions {
          id
          description
          identity {
            type
            roles {
              roleId
              categoryId
              label
            }
          }
          access {
            type
            states {
              draft
              inprogress
              complete
            }
          }
        }
      }
    }
  }
`

const getParams = (appId, datasetId, id) => ({
  variables: {
    appId,
    datasetId,
    id
  },
  refetchQueries: ['FormPageQuery']
})
