import { i18n } from '@lingui/core'
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale'
import { I18nProvider } from '@lingui/react'
import { enCA, enGB, es, fr } from 'date-fns/locale'
import React, { useEffect } from 'react'

import { multipleLanguages } from './components/feature-flags'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import { messages as frMessages } from './locales/fr/messages'

const defaultLocale = () => 'en'

const localeMessages = {
  en: enMessages,
  es: esMessages,
  fr: frMessages
}

// loads the correct locale for date-fns/locale
export const getFormatLocale = locale => {
  switch (locale) {
    case 'en-CA':
      return enCA
    case 'en-GB':
      return enGB
    default:
      if (locale.startsWith('es')) return es
      if (locale.startsWith('fr')) return fr
      return null
  }
}

export const locales = {
  en: 'English',
  es: 'Espańol',
  fr: 'Français'
}

export async function saveLocale (locale) {
  window.localStorage.setItem('lang', locale)
}

// /**
//  * We do a dynamic import of just the catalog that we need
//  */
export async function loadLocale (locale) {
  const fallbackLocale = getFallbackLocale(locale)
  // dynamic import not working with esbuild yet
  // const { messages } = await import(`./locales/${fallbackLocale}/messages`)
  const messages = localeMessages[fallbackLocale]
  i18n.load(locale, messages)
  i18n.activate(locale)
}

export const getFallbackLocale = locale => {
  const fallback = locale.slice(0, 2)
  if (Object.keys(locales).includes(fallback)) {
    return fallback
  } else {
    return defaultLocale()
  }
}

const I18nWrapper = props => {
  useEffect(() => {
    if (multipleLanguages) {
      const locale = detect(fromStorage('lang'), fromNavigator(), defaultLocale)
      loadLocale(locale)
    } else {
      loadLocale(defaultLocale())
    }
  }, [])

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>
}

export default I18nWrapper
