/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.8573 2.51326C10.6066 1.637 9.13987 1.11999 7.61616 1.01833C6.09245 0.916675 4.56999 1.23425 3.21399 1.93659C3.18259 1.95303 3.14675 1.95898 3.11173 1.95358C3.0767 1.94818 3.04432 1.93172 3.01932 1.90659L1.29132 0.179258C1.24441 0.133084 1.18504 0.101611 1.1205 0.0887019C1.05596 0.0757932 0.989049 0.0820109 0.92799 0.106592C0.867157 0.131891 0.815185 0.17465 0.778639 0.229469C0.742094 0.284288 0.722612 0.348707 0.722656 0.414592V4.88126C0.722656 4.96966 0.757775 5.05445 0.820287 5.11696C0.882799 5.17947 0.967584 5.21459 1.05599 5.21459H5.52266C5.58867 5.21471 5.65323 5.19522 5.70816 5.15861C5.76308 5.12199 5.8059 5.06988 5.83118 5.0089C5.85646 4.94792 5.86306 4.8808 5.85015 4.81606C5.83724 4.75133 5.80539 4.69188 5.75866 4.64526L4.52199 3.40992C4.50255 3.39025 4.48825 3.36609 4.48034 3.33959C4.47243 3.31308 4.47117 3.28504 4.47666 3.25793C4.48223 3.23099 4.49441 3.20586 4.51209 3.1848C4.52978 3.16374 4.55242 3.1474 4.57799 3.13726C5.60783 2.72477 6.72433 2.57581 7.82629 2.70386C8.92826 2.83191 9.98085 3.23294 10.8887 3.87059C11.7253 4.46881 12.4082 5.25686 12.8814 6.17011C13.3545 7.08335 13.6044 8.09575 13.6105 9.12426C13.6166 10.1528 13.3788 11.1681 12.9165 12.0869C12.4543 13.0057 11.7808 13.8018 10.9513 14.4099C10.7854 14.5446 10.6775 14.7378 10.65 14.9498C10.6225 15.1617 10.6774 15.3761 10.8035 15.5487C10.9296 15.7212 11.1172 15.8388 11.3274 15.877C11.5377 15.9152 11.7546 15.8711 11.9333 15.7539C12.9812 14.9953 13.8317 13.9963 14.4133 12.8407C14.9948 11.6851 15.2905 10.4068 15.2753 9.11326C15.2751 7.81995 14.9646 6.54556 14.3699 5.39711C13.7751 4.24867 12.9135 3.2597 11.8573 2.51326Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11.8573 2.51326C10.6066 1.637 9.13987 1.11999 7.61616 1.01833C6.09245 0.916675 4.56999 1.23425 3.21399 1.93659C3.18259 1.95303 3.14675 1.95898 3.11173 1.95358C3.0767 1.94818 3.04432 1.93172 3.01932 1.90659L1.29132 0.179258C1.24441 0.133084 1.18504 0.101611 1.1205 0.0887019C1.05596 0.0757932 0.989049 0.0820109 0.92799 0.106592C0.867157 0.131891 0.815185 0.17465 0.778639 0.229469C0.742094 0.284288 0.722612 0.348707 0.722656 0.414592V4.88126C0.722656 4.96966 0.757775 5.05445 0.820287 5.11696C0.882799 5.17947 0.967584 5.21459 1.05599 5.21459H5.52266C5.58867 5.21471 5.65323 5.19522 5.70816 5.15861C5.76308 5.12199 5.8059 5.06988 5.83118 5.0089C5.85646 4.94792 5.86306 4.8808 5.85015 4.81606C5.83724 4.75133 5.80539 4.69188 5.75866 4.64526L4.52199 3.40992C4.50255 3.39025 4.48825 3.36609 4.48034 3.33959C4.47243 3.31308 4.47117 3.28504 4.47666 3.25793C4.48223 3.23099 4.49441 3.20586 4.51209 3.1848C4.52978 3.16374 4.55242 3.1474 4.57799 3.13726C5.60783 2.72477 6.72433 2.57581 7.82629 2.70386C8.92826 2.83191 9.98085 3.23294 10.8887 3.87059C11.7253 4.46881 12.4082 5.25686 12.8814 6.17011C13.3545 7.08335 13.6044 8.09575 13.6105 9.12426C13.6166 10.1528 13.3788 11.1681 12.9165 12.0869C12.4543 13.0057 11.7808 13.8018 10.9513 14.4099C10.7854 14.5446 10.6775 14.7378 10.65 14.9498C10.6225 15.1617 10.6774 15.3761 10.8035 15.5487C10.9296 15.7212 11.1172 15.8388 11.3274 15.877C11.5377 15.9152 11.7546 15.8711 11.9333 15.7539C12.9812 14.9953 13.8317 13.9963 14.4133 12.8407C14.9948 11.6851 15.2905 10.4068 15.2753 9.11326C15.2751 7.81995 14.9646 6.54556 14.3699 5.39711C13.7751 4.24867 12.9135 3.2597 11.8573 2.51326Z\" />\n</svg>\n";
export default src;
