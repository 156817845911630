/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import ReorderableList from '../../../components/reorderable-list'
import { Option, Select } from '../../../ui/select'
import Presets from '../../multiselect-presets'

export function RequiredConfig ({ Gadgets, indexType }) {
  return (
    <>
      <Gadgets.Padded>
        <Gadgets.Custom
          configKey='options'
          label={i18n._('options')}
          group
          defaultValue={[]}
        >
          {({ onChange, value }) => (
            <ReorderableList
              onChange={onChange}
              value={value}
              indexType={indexType}
            >
              <Presets onChange={onChange} gadgetType='Radios' />
            </ReorderableList>
          )}
        </Gadgets.Custom>
      </Gadgets.Padded>

      <Gadgets.Custom
        configKey='options'
        id='radios-options-default'
        label={i18n._('select.default.option')}
      >
        {({ onChange, value }) => {
          return (
            <Select
              id='radios-options-default'
              data-testid='select-default'
              defaultValue={
                value ? value.find(item => item.defaultRadio)?.lbl || '' : ''
              }
              onChange={selectedValue => {
                // set radio as default if selected value matches label
                const radiosWithDefaultSet = [...value].map(item => {
                  return {
                    ...item,
                    defaultRadio: item.lbl === selectedValue
                  }
                })
                onChange(radiosWithDefaultSet)
              }}
            >
              <Option value=''>No default</Option>
              {value &&
                value.map((item, i) => (
                  <Option key={item.lbl} value={item.lbl}>
                    {item.lbl}
                  </Option>
                ))}
            </Select>
          )
        }}
      </Gadgets.Custom>
    </>
  )
}
