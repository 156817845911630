/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { clamp, padStart } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Input from '../../../ui/input'

const re = /^(0?[1-9]|1[0-2]):([0-5][0-9]) ([AP]M)$/

export default function ({
  details,
  gridded,
  value,
  errors,
  onChange,
  ...rest
}) {
  const [data, setData] = React.useState(() => format(value))
  const [hasError, setHasError] = React.useState(false)
  const Component = gridded ? MyInput : Input
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  return (
    <Wrapper>
      <Component
        aria-labelledby={rest['aria-labelledby']}
        {...(rest['aria-describedby'] && {
          'aria-describedby': rest['aria-describedby']
        })}
        aria-required={rest.required}
        id={rest.id}
        value={data}
        onChange={newValue => {
          if (hasError) setHasError(false)
          if (newValue.length > data.length) {
            const [hours, ...rest] = newValue.split(':')
            const [minutes, ...rest2] = rest.join(':').split(' ')
            let suffix = rest2.join(' ').toUpperCase()
            if (suffix) {
              if (!/^(0?[1-9]|1[0-2])$/.test(hours)) return
              if (!/^[0-5]?[0-9]$/.test(minutes)) return
              if (suffix === 'A') suffix = 'AM'
              if (suffix === 'P') suffix = 'PM'
              if (suffix !== 'AM' && suffix !== 'PM') return
              const time = `${hours}:${padStart(minutes, 2, '0')} ${suffix}`
              setData(time)
              onChange(parse(time))
            } else if (minutes) {
              if (!/^(0?[1-9]|1[0-2])$/.test(hours)) return
              if (minutes.length === 1) {
                if (/^[0-5]$/.test(minutes)) {
                  if (newValue.endsWith(' ')) {
                    setData(`${hours}:0${minutes} `)
                  } else {
                    setData(newValue)
                  }
                } else if (/^[6-9]$/.test(minutes)) {
                  setData(`${hours}:0${minutes} `)
                }
              } else if (minutes.length === 2) {
                const num = parseInt(minutes)
                if (isNaN(num)) return
                setData(`${hours}:${padStart(clamp(num, 0, 59), 2, '0')} `)
              }
            } else if (hours) {
              if (newValue.length === 1) {
                if (/^[0-1]$/.test(newValue)) return setData(newValue)
                if (/^[2-9]$/.test(newValue)) return setData(`${newValue}:`)
              } else if (newValue.length === 2) {
                const num = parseInt(newValue)
                if (isNaN(num)) return
                setData(`${clamp(num, 1, 12)}:`)
              }
            }
          } else {
            if (value !== null) onChange(null)
            setData(newValue)
          }
        }}
        onBlur={() => {
          if (data && !re.test(data)) setHasError(true)
        }}
        placeholder={placeholder}
      />
      <TimeIcon />
      {hasError && (
        <Error className='text-red-400'>
          Not saved. Must be in the format: 12:00 AM
        </Error>
      )}
      {gridded && errors}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const TimeIcon = styled(Icons.Time)`
  position: absolute;
  top: 0px;
  left: 16px;
`

const MyInput = styled(Input)`
  border: none;
  outline: none;
  padding: 0 16px 16px 40px;
  background: none;
  width: 100%;
  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`

function format (seconds) {
  if (seconds === null) return ''
  const allMinutes = Math.floor(seconds / 60)
  const hours = Math.floor(allMinutes / 60)
  const minutes = padStart(allMinutes % 60, 2, '0')
  const suffix = hours < 12 ? 'AM' : 'PM'
  return `${hours % 12 || 12}:${minutes} ${suffix}`
}

function parse (time) {
  const [, hours, minutes, suffix] = time.match(re)
  return ((+hours % 12) * 60 + +minutes + (suffix === 'PM' ? 60 * 12 : 0)) * 60
}
