/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import DisplayFile from './parts/display-file'

export default function FileUploadView ({
  details,
  value,
  noFileText = <Trans id='no.file.attached' />
}) {
  return (
    <DisplayFile
      value={value}
      noValue={<span data-test='no-file'>{noFileText}</span>}
    />
  )
}
