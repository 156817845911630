/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { cloneDeep, filter, includes } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import ErrorBoundary from '../../../components/error-boundary'

export default function GadgetDropdownEdit (props) {
  const { onChange, value, StaticFormbot, gridded } = props
  let options = [
    { key: 'Text', lbl: 'Text' },
    { key: 'Email', lbl: 'Email' },
    { key: 'Number', lbl: 'Number' },
    { key: 'Boolean', lbl: 'Boolean' },
    { key: 'GroupTypeahead', lbl: 'Group Typeahead' },
    { key: 'UserTypeahead', lbl: 'User Typeahead' },
    { key: 'RichText', lbl: 'Rich Text' },
    { key: 'UserMultiselect', lbl: 'User Multiselect' },
    { key: 'Timestamp', lbl: 'Timestamp' },
    { key: 'Currency', lbl: 'Currency' }
    // TODO:: get the full gadget list here
    // We should filter out Smart Gadgets but make sure to keep showing Group/User Typeahead
  ]
  options = filter(options, option => option.key !== 'DataLink')
  if (props.details?.filter) {
    options = filter(options, option =>
      includes(props.details.filter, option.key)
    )
  }
  return (
    <>
      <StaticFormbot value={cloneDeep(value)} update={onChange}>
        {({ Dropdown, Custom }, stateValue) => (
          <>
            {!gridded && <br />}
            <Dropdown
              configKey='type'
              unPadded
              options={options}
              gridded={gridded}
            />
            {stateValue.type && (
              <Custom
                configKey='details'
                defaultValue={{}}
                withGadgets
                unPadded
              >
                {({ onChange, value, Gadgets }) => (
                  <ConfigView
                    id={props.id}
                    gadgetType={stateValue.type.id}
                    render={props.fbRenderConfig}
                    onChange={onChange}
                    value={value}
                    Gadgets={Gadgets}
                  />
                )}
              </Custom>
            )}
          </>
        )}
      </StaticFormbot>
    </>
  )
}

const ConfigView = ({ id, gadgetType, render, onChange, value, Gadgets }) => {
  const config = render(gadgetType, value, onChange, Gadgets)
  if (!config) return null
  return (
    <Padding>
      <ErrorBoundary>{config}</ErrorBoundary>
    </Padding>
  )
}

const Padding = styled.div`
  padding: 0 16px 6px;
`
