/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`64`} height={`64`} viewBox={`0 0 64 64`} fill={`none`}{...props}><path d={`M45.3333 63.6672C46.3942 63.6672 47.4116 63.2457 48.1617 62.4956C48.9119 61.7454 49.3333 60.728 49.3333 59.6672C49.3333 58.6063 48.9119 57.5889 48.1617 56.8387C47.4116 56.0886 46.3942 55.6672 45.3333 55.6672H48C49.0608 55.6672 50.0783 55.2457 50.8284 54.4956C51.5786 53.7454 52 52.728 52 51.6672C52 50.6063 51.5786 49.5889 50.8284 48.8387C50.0783 48.0886 49.0608 47.6672 48 47.6672H50.6666C51.7275 47.6672 52.7449 47.2457 53.4951 46.4956C54.2452 45.7454 54.6666 44.728 54.6666 43.6672C54.6666 42.6063 54.2452 41.5889 53.4951 40.8387C52.7449 40.0886 51.7275 39.6672 50.6666 39.6672H53.3333C54.3942 39.6672 55.4116 39.2457 56.1617 38.4956C56.9119 37.7454 57.3333 36.728 57.3333 35.6672C57.3333 34.6063 56.9119 33.5889 56.1617 32.8387C55.4116 32.0886 54.3942 31.6672 53.3333 31.6672H33.712C33.5099 31.6676 33.3103 31.6221 33.1283 31.5341C32.9464 31.4461 32.7868 31.3178 32.6617 31.1591C32.5366 31.0003 32.4493 30.8152 32.4062 30.6177C32.3632 30.4203 32.3656 30.2156 32.4133 30.0192C33.6017 25.5122 34.3563 20.9019 34.6666 16.2512C34.6666 8.25117 26.6666 8.7845 26.6666 13.0005C26.7103 18.4444 24.6577 23.6964 20.9342 27.668C17.2108 31.6397 12.102 34.0265 6.66665 34.3338H2.66665C2.31302 34.3338 1.97389 34.4743 1.72384 34.7244C1.47379 34.9744 1.33331 35.3135 1.33331 35.6672V57.0005C1.33331 57.3541 1.47379 57.6933 1.72384 57.9433C1.97389 58.1934 2.31302 58.3338 2.66665 58.3338C22.4613 58.5498 17.5733 63.6672 28.0133 63.6672H45.3333Z`} fill={`#49AA30`}/></svg>
);

let src = "<svg width=\"64\" height=\"64\" viewBox=\"0 0 64 64\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M45.3333 63.6672C46.3942 63.6672 47.4116 63.2457 48.1617 62.4956C48.9119 61.7454 49.3333 60.728 49.3333 59.6672C49.3333 58.6063 48.9119 57.5889 48.1617 56.8387C47.4116 56.0886 46.3942 55.6672 45.3333 55.6672H48C49.0608 55.6672 50.0783 55.2457 50.8284 54.4956C51.5786 53.7454 52 52.728 52 51.6672C52 50.6063 51.5786 49.5889 50.8284 48.8387C50.0783 48.0886 49.0608 47.6672 48 47.6672H50.6666C51.7275 47.6672 52.7449 47.2457 53.4951 46.4956C54.2452 45.7454 54.6666 44.728 54.6666 43.6672C54.6666 42.6063 54.2452 41.5889 53.4951 40.8387C52.7449 40.0886 51.7275 39.6672 50.6666 39.6672H53.3333C54.3942 39.6672 55.4116 39.2457 56.1617 38.4956C56.9119 37.7454 57.3333 36.728 57.3333 35.6672C57.3333 34.6063 56.9119 33.5889 56.1617 32.8387C55.4116 32.0886 54.3942 31.6672 53.3333 31.6672H33.712C33.5099 31.6676 33.3103 31.6221 33.1283 31.5341C32.9464 31.4461 32.7868 31.3178 32.6617 31.1591C32.5366 31.0003 32.4493 30.8152 32.4062 30.6177C32.3632 30.4203 32.3656 30.2156 32.4133 30.0192C33.6017 25.5122 34.3563 20.9019 34.6666 16.2512C34.6666 8.25117 26.6666 8.7845 26.6666 13.0005C26.7103 18.4444 24.6577 23.6964 20.9342 27.668C17.2108 31.6397 12.102 34.0265 6.66665 34.3338H2.66665C2.31302 34.3338 1.97389 34.4743 1.72384 34.7244C1.47379 34.9744 1.33331 35.3135 1.33331 35.6672V57.0005C1.33331 57.3541 1.47379 57.6933 1.72384 57.9433C1.97389 58.1934 2.31302 58.3338 2.66665 58.3338C22.4613 58.5498 17.5733 63.6672 28.0133 63.6672H45.3333Z\" fill=\"#49AA30\"/>\n</svg>";
export default src;
