/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useAddUserMutation = (groupId, roleId) => {
  const [addUser, { loading }] = useMutation(mutation, {
    update (cache, result) {
      updateParentQueryCache(cache, groupId, roleId)
    }
  })
  return [userId => addUser(getParams(groupId, roleId, userId)), { loading }]
}

const mutation = gql`
  mutation AddGroupRoleUser($groupId: ID!, $roleId: String, $userId: String!) {
    addGroupRoleUser(groupId: $groupId, roleId: $roleId, userId: $userId) {
      result {
        id
        members {
          id
          label: displayName
        }
        memberIds
      }
    }
  }
`

const getParams = (groupId, roleId, userId) => {
  return { variables: { groupId, roleId, userId } }
}

function updateParentQueryCache (cache, groupId, roleId) {
  cache.evict({
    fieldName: 'membersConnection',
    args: { args: { limit: 12, skip: 0, query: '', sort: '' } },
    id: `Role:${groupId}::${roleId}`
  })
}
