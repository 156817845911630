/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.0153 2.22998L8.52333 0.469978C8.18051 0.377988 7.81949 0.377988 7.47667 0.469978L0.984667 2.22998C0.703476 2.30935 0.455562 2.47767 0.278052 2.70974C0.100542 2.94182 0.00298845 3.22515 0 3.51731L0 11.8786C1.09935e-06 12.1555 0.0862059 12.4256 0.246648 12.6512C0.40709 12.8769 0.633809 13.047 0.895333 13.138L7.656 15.49C7.87239 15.5653 8.10024 15.6023 8.32933 15.5993C8.56718 15.6003 8.80308 15.5564 9.02467 15.47L15.1413 13.1366C15.3892 13.0344 15.6022 12.8626 15.7546 12.642C15.9069 12.4214 15.9922 12.1613 16 11.8933V3.51731C15.9977 3.22498 15.9004 2.94134 15.7227 2.70914C15.5451 2.47693 15.2969 2.30876 15.0153 2.22998ZM7.82533 1.75731C7.93976 1.72664 8.06024 1.72664 8.17467 1.75731L12.2793 2.86998C12.3145 2.87968 12.3455 2.90065 12.3676 2.92967C12.3897 2.95869 12.4017 2.99417 12.4017 3.03064C12.4017 3.06712 12.3897 3.1026 12.3676 3.13162C12.3455 3.16064 12.3145 3.18161 12.2793 3.19131L8.37 4.31531C8.34099 4.32332 8.31035 4.32332 8.28133 4.31531L3.85733 3.15464C3.82188 3.14516 3.79056 3.12423 3.76821 3.09512C3.74587 3.06601 3.73376 3.03034 3.73376 2.99364C3.73376 2.95695 3.74587 2.92128 3.76821 2.89217C3.79056 2.86305 3.82188 2.84213 3.85733 2.83264L7.82533 1.75731ZM1.44533 11.9173C1.41264 11.906 1.38429 11.8847 1.36422 11.8565C1.34415 11.8283 1.33336 11.7946 1.33333 11.76V4.08731C1.33338 4.06145 1.33943 4.03595 1.351 4.01281C1.36257 3.98968 1.37934 3.96954 1.4 3.95398C1.42026 3.93859 1.44372 3.92795 1.46865 3.92288C1.49358 3.9178 1.51934 3.9184 1.544 3.92465L7.544 5.49931C7.57971 5.50861 7.61132 5.52949 7.63388 5.55869C7.65644 5.58789 7.66868 5.62375 7.66867 5.66065V13.8473C7.66844 13.8735 7.66198 13.8993 7.6498 13.9225C7.63763 13.9456 7.6201 13.9656 7.59867 13.9806C7.57698 13.996 7.55196 14.006 7.52565 14.0099C7.49934 14.0137 7.4725 14.0112 7.44733 14.0026L1.44533 11.9173Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.0153 2.22998L8.52333 0.469978C8.18051 0.377988 7.81949 0.377988 7.47667 0.469978L0.984667 2.22998C0.703476 2.30935 0.455562 2.47767 0.278052 2.70974C0.100542 2.94182 0.00298845 3.22515 0 3.51731L0 11.8786C1.09935e-06 12.1555 0.0862059 12.4256 0.246648 12.6512C0.40709 12.8769 0.633809 13.047 0.895333 13.138L7.656 15.49C7.87239 15.5653 8.10024 15.6023 8.32933 15.5993C8.56718 15.6003 8.80308 15.5564 9.02467 15.47L15.1413 13.1366C15.3892 13.0344 15.6022 12.8626 15.7546 12.642C15.9069 12.4214 15.9922 12.1613 16 11.8933V3.51731C15.9977 3.22498 15.9004 2.94134 15.7227 2.70914C15.5451 2.47693 15.2969 2.30876 15.0153 2.22998ZM7.82533 1.75731C7.93976 1.72664 8.06024 1.72664 8.17467 1.75731L12.2793 2.86998C12.3145 2.87968 12.3455 2.90065 12.3676 2.92967C12.3897 2.95869 12.4017 2.99417 12.4017 3.03064C12.4017 3.06712 12.3897 3.1026 12.3676 3.13162C12.3455 3.16064 12.3145 3.18161 12.2793 3.19131L8.37 4.31531C8.34099 4.32332 8.31035 4.32332 8.28133 4.31531L3.85733 3.15464C3.82188 3.14516 3.79056 3.12423 3.76821 3.09512C3.74587 3.06601 3.73376 3.03034 3.73376 2.99364C3.73376 2.95695 3.74587 2.92128 3.76821 2.89217C3.79056 2.86305 3.82188 2.84213 3.85733 2.83264L7.82533 1.75731ZM1.44533 11.9173C1.41264 11.906 1.38429 11.8847 1.36422 11.8565C1.34415 11.8283 1.33336 11.7946 1.33333 11.76V4.08731C1.33338 4.06145 1.33943 4.03595 1.351 4.01281C1.36257 3.98968 1.37934 3.96954 1.4 3.95398C1.42026 3.93859 1.44372 3.92795 1.46865 3.92288C1.49358 3.9178 1.51934 3.9184 1.544 3.92465L7.544 5.49931C7.57971 5.50861 7.61132 5.52949 7.63388 5.55869C7.65644 5.58789 7.66868 5.62375 7.66867 5.66065V13.8473C7.66844 13.8735 7.66198 13.8993 7.6498 13.9225C7.63763 13.9456 7.6201 13.9656 7.59867 13.9806C7.57698 13.996 7.55196 14.006 7.52565 14.0099C7.49934 14.0137 7.4725 14.0112 7.44733 14.0026L1.44533 11.9173Z\" />\n</svg>\n";
export default src;
