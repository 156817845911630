/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

let processUnique = null
let index = Math.floor(Math.random() * 0xffffff)

export function generate () {
  const buf = new Uint8Array(12)
  // 4-byte timestamp
  const time = Math.floor(Date.now() / 1000)
  buf[0] = time >>> 24
  buf[1] = time >>> 16
  buf[2] = time >>> 8
  buf[3] = time & 0xff
  // 5-byte process unique
  if (!processUnique) processUnique = crypto.getRandomValues(new Uint8Array(5))
  buf[4] = processUnique[0]
  buf[5] = processUnique[1]
  buf[6] = processUnique[2]
  buf[7] = processUnique[3]
  buf[8] = processUnique[4]
  // 3-byte counter
  const inc = (index = (index + 1) % 0xffffff)
  buf[11] = inc & 0xff
  buf[10] = (inc >> 8) & 0xff
  buf[9] = (inc >> 16) & 0xff

  let out = ''
  for (let i = 0; i < 12; ++i) {
    out += buf[i].toString(16).padStart(2, '0')
  }
  return out
}
