/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`} fill={`none`}{...props}><g clipPath={`url(#clip0_12385_73870)`}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M13 4.66667C13 4.48986 13.0702 4.32029 13.1953 4.19526C13.3203 4.07024 13.4899 4 13.6667 4H14.6667C15.0203 4 15.3594 4.14048 15.6095 4.39052C15.8595 4.64057 16 4.97971 16 5.33333V14.6667C16 15.0203 15.8595 15.3594 15.6095 15.6095C15.3594 15.8595 15.0203 16 14.6667 16H5.33333C4.97971 16 4.64057 15.8595 4.39052 15.6095C4.14048 15.3594 4 15.0203 4 14.6667V13.6667C4 13.4899 4.07024 13.3203 4.19526 13.1953C4.32029 13.0702 4.48986 13 4.66667 13C4.84348 13 5.01305 13.0702 5.13807 13.1953C5.2631 13.3203 5.33333 13.4899 5.33333 13.6667V14.6667H14.6667V5.33333H13.6667C13.4899 5.33333 13.3203 5.2631 13.1953 5.13807C13.0702 5.01305 13 4.84348 13 4.66667Z`} fill={`#666666`}/><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M1.33333 0C0.979711 0 0.640573 0.140476 0.390524 0.390524C0.140476 0.640573 0 0.979711 0 1.33333L0 10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.979711 11.8595 0.640573 11.6095 0.390524C11.3594 0.140476 11.0203 0 10.6667 0L1.33333 0Z`} fill={`#666666`}/></g><defs><clipPath id={`clip0_12385_73870`}><rect width={`16`} height={`16`} fill={`white`}/></clipPath></defs></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_12385_73870)\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 4.66667C13 4.48986 13.0702 4.32029 13.1953 4.19526C13.3203 4.07024 13.4899 4 13.6667 4H14.6667C15.0203 4 15.3594 4.14048 15.6095 4.39052C15.8595 4.64057 16 4.97971 16 5.33333V14.6667C16 15.0203 15.8595 15.3594 15.6095 15.6095C15.3594 15.8595 15.0203 16 14.6667 16H5.33333C4.97971 16 4.64057 15.8595 4.39052 15.6095C4.14048 15.3594 4 15.0203 4 14.6667V13.6667C4 13.4899 4.07024 13.3203 4.19526 13.1953C4.32029 13.0702 4.48986 13 4.66667 13C4.84348 13 5.01305 13.0702 5.13807 13.1953C5.2631 13.3203 5.33333 13.4899 5.33333 13.6667V14.6667H14.6667V5.33333H13.6667C13.4899 5.33333 13.3203 5.2631 13.1953 5.13807C13.0702 5.01305 13 4.84348 13 4.66667Z\" fill=\"#666666\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.33333 0C0.979711 0 0.640573 0.140476 0.390524 0.390524C0.140476 0.640573 0 0.979711 0 1.33333L0 10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.979711 11.8595 0.640573 11.6095 0.390524C11.3594 0.140476 11.0203 0 10.6667 0L1.33333 0Z\" fill=\"#666666\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_12385_73870\">\n<rect width=\"16\" height=\"16\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>\n";
export default src;
