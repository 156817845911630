/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { invert, isEmpty } from 'lodash'
import React from 'react'

export function check (data, config) {
  switch (config.type) {
    case 'isEmpty':
      return isEmpty(data)
    case 'isNotEmpty':
      return !isEmpty(data)
    default:
      return true
  }
}

export const configIsValid = config => !!config.type

export function component ({ onChange, value }) {
  return (
    <select
      className='kp-select flex-1'
      onChange={e => onChange({ ...value, type: e.target.value })}
      value={value.type || ''}
    >
      <option value=''>- - -</option>
      <option value='isEmpty'>
        <Trans id='is.empty' />
      </option>
      <option value='isNotEmpty'>
        <Trans id='is.not.empty' />
      </option>
    </select>
  )
}

const uiToWf = {
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  return {
    left: {
      dataType: 'user-list',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
}

export function fromWorkflowFormat (expr) {
  return {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
}
