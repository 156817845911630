/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export default function CreditsView ({ value, gridded }) {
  let credits

  switch (value && value.type) {
    case 'single':
      credits = value.credits
      break
    case 'multiple':
      credits = value.credits.join(' / ')
      break
    case 'range':
      credits = [value.credits.min, value.credits.max].join('-')
      break
    default:
      credits = ''
  }

  return <div>{credits}</div>
}
