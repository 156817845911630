/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import React from 'react'

export const useSaveWorkflowMutation = (appId, pageId) => {
  const [saveWorkflow, mutationResult] = useMutation(mutation)
  return [
    React.useCallback(
      steps => saveWorkflow(getParams(appId, pageId, { appId, pageId, steps })),
      [saveWorkflow, appId, pageId]
    ),
    mutationResult
  ]
}

const mutation = gql`
  mutation SaveWorkflow($appId: ID!, $pageId: ID, $args: UpdateWorkflowInput!) {
    updateWorkflowV2(args: $args) {
      query {
        app(id: $appId, isConfiguration: true) {
          id
          name
          type
          hasDraft
          dataset(id: $pageId) {
            id
            labelSize
            allowExport
            workflowViewerOnSubmission
            form: formVersion {
              id
              schema {
                id
                formKey
                type
                label
                details
                fromIntegration
              }
              template
            }
            workflow
          }
        }
      }
    }
  }
`

const getParams = (appId, pageId, args) => ({
  variables: {
    appId,
    pageId,
    args
  }
})
