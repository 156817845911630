/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import AppMultiselect from '../../../components/app-multiselect'

export const UI = ({ gadget, onChange, value }) => {
  const values = get(value, 'values') || []
  const handleStatusChange = React.useCallback(
    values => {
      const filter = value || {
        field: gadget.formKey,
        type: 'App',
        values: []
      }
      filter.values = values
      values.length ? onChange(filter) : onChange(null)
    },
    [value, onChange, gadget]
  )
  return (
    <Wrapper>
      <div>
        <Trans id='filter' />
      </div>
      <AppMultiselect value={values} onChange={handleStatusChange} />
    </Wrapper>
  )
}

export const Pill = ({ label, filter, gadget }) => {
  const values = get(filter, 'values') || []
  const str = React.useMemo(() => {
    return map(values, 'name').join(', ')
  }, [values])
  return (
    <span>
      {label}: {str}
    </span>
  )
}

export const toGraphQL = myFilter =>
  map(myFilter.values, value => ({
    field: `${myFilter.field}`,
    type: 'IS',
    value
  }))

export const fromGraphQL = gqlFilters => ({
  field: gqlFilters[0].field,
  type: 'App',
  values: map(gqlFilters, 'id')
})

const Wrapper = styled.div`
  width: 250px;
  padding: 8px 16px;
  min-height: 300px;
`
