/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useStaticImageUploadMutation = appId => {
  const [staticImageUpload] = useMutation(mutation)
  return (filename, contentType) =>
    staticImageUpload(getParams(appId, filename, contentType))
}

const mutation = gql`
  mutation StaticImageUpload(
    $appId: ID!
    $filename: String!
    $contentType: String!
  ) {
    staticImageUpload(
      args: { appId: $appId, filename: $filename, contentType: $contentType }
    ) {
      url
      fields
      keyId
    }
  }
`

const getParams = (appId, filename, contentType) => ({
  variables: { appId, filename, contentType }
})
