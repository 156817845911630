/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { merge, partialRight } from 'lodash'
import _sanitize from 'sanitize-html'

const extendedAllowedAttributes = merge(
  {},
  _sanitize.defaults.allowedAttributes,
  { '*': ['style', 'class'] }
)

const extendedAllowedTags = _sanitize.defaults.allowedTags

export default partialRight(_sanitize, {
  allowedAttributes: extendedAllowedAttributes,
  allowedTags: extendedAllowedTags
})
