/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useMoveIntegrationsToSpaceMutation = () => {
  const [moveIntegrations] = useMutation(moveIntegrationsMutation)
  return (integrationIds, spaceId, oldSpaceId, sort) =>
    moveIntegrations(getParams(integrationIds, spaceId, oldSpaceId, sort))
}

const moveIntegrationsMutation = gql`
  mutation MoveIntegrationsToSpace(
    $integrationIds: [ID!]!
    $spaceId: ID!
    $oldSpaceId: ID
    $sort: String
  ) {
    data: moveIntegrationsToSpace(
      integrationIds: $integrationIds
      spaceId: $spaceId
    ) {
      query {
        integrationsConnection(spaceId: $oldSpaceId, args: { sort: $sort }) {
          edges {
            node {
              id
              data
              filledSecrets
              invokeUrl
            }
          }
        }
      }
    }
  }
`

const getParams = (integrationIds, spaceId, oldSpaceId, sort) => ({
  variables: { integrationIds, spaceId, oldSpaceId, sort }
})
