/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

function getScrollParent (node) {
  if (node == null) return null
  if (node.scrollHeight > node.clientHeight) return node
  return getScrollParent(node.parentNode)
}

export function useScrollToTop (deps = []) {
  const ref = React.useRef()
  React.useEffect(() => {
    getScrollParent(ref.current)?.scrollTo(0, 0)
  }, deps)
  return ref
}
