/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import * as Icons from '../../../icons'

export default function AssociationsEdit ({ details }) {
  return (
    <div>
      {details.source ? (
        <div>{details.source.details?.label}</div>
      ) : (
        <div className='flex h-8 w-full items-center justify-center gap-1 rounded bg-light-gray-100 text-dark-gray-100'>
          <Icons.Link className='h-4 w-4' />
          <span>No linked documents</span>
        </div>
      )}
    </div>
  )
}
