/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { sample } from 'lodash'

export const useCreateSpaceMutation = () => {
  const [createSpace] = useMutation(createSpaceMutation)
  return (name, parentId, isRoot, updateSiblingsId) =>
    createSpace(getParams(name, parentId, isRoot, updateSiblingsId))
}

const createSpaceMutation = gql`
  mutation CreateSpace($name: String!, $parentId: ID) {
    data: createSpace(name: $name, parentId: $parentId) {
      id
      name
      parentId
    }
  }
`

const getParams = (name, parentId, isRoot, updateSiblingsId) => ({
  variables: { name, parentId },
  optimisticResponse: {
    data: {
      id: `new-space-${parentId}`,
      __typename: 'Space',
      name,
      parentId
    }
  },
  update (cache, { data: { data } }) {
    if (isRoot) {
      cache.modify({
        id: 'Viewer:VIEWER',
        fields: {
          'spaces({"type":"ADMIN_ROOT"})' (existingSpaces, b) {
            return [...existingSpaces, data]
          }
        }
      })
    } else if (updateSiblingsId) {
      cache.modify({
        id: `Space:${updateSiblingsId}`,
        fields: {
          siblings (existingSiblings) {
            return [...existingSiblings, data]
          }
        }
      })
    } else {
      cache.modify({
        id: `Space:${data.parentId}`,
        fields: {
          children (existingChildren) {
            return [...existingChildren, data]
          }
        }
      })
    }
  }
})

const ADJECTIVES = [
  'Flying',
  'Sparkly',
  'Friendly',
  'Whispering',
  'Dusty',
  'Candy',
  'Dusky',
  'Flowering',
  'Fiery',
  'Verdant',
  'Ancient',
  'Vibrant',
  'Morning',
  'Evening',
  'Afternoon',
  'Esoteric',
  'Lugubrious',
  'Gauche',
  'Extrinsic',
  'Protean',
  'Deciduous',
  'Pragmatic',
  'Diminutive',
  'Unctuous',
  'Pithy',
  'Fractious',
  'Fatuous',
  'Pecuniary',
  'Jejune',
  'Auspicious',
  'Abstemious',
  'Churlish',
  'Quotidian',
  'Loquacious',
  'Timorous',
  'Supercilious',
  'Insipid',
  'Sycophantic',
  'Meretricious',
  'Ingratiating',
  'Amenable',
  'Austere',
  'Stochastic',
  'Rarefied',
  'Hirsute',
  'Bibulous',
  'Fulsome',
  'Insouciant',
  'Recreant',
  'Importunate',
  'Empyrean',
  'Teleological',
  'Supernumerary',
  'Flagitious',
  'Limpid'
]

const NOUNS = [
  'Mountains',
  'Woods',
  'Trees',
  'Valleys',
  'Bluffs',
  'Rivers',
  'Streams',
  'Deserts',
  'Fjords',
  'Canals',
  'Lakes',
  'Trails',
  'Parks',
  'Gold',
  'Painting',
  'Grass',
  'Parrot',
  'Afternoon',
  'Pencil',
  'Airport',
  'Guitar',
  'Piano',
  'Ambulance',
  'Hair',
  'Pillow',
  'Animal',
  'Hamburger',
  'Pizza',
  'Answer',
  'Helicopter',
  'Planet',
  'Apple',
  'Helmet',
  'Plastic',
  'Holiday',
  'Honey',
  'Potato',
  'Balloon',
  'Horse',
  'Banana',
  'Hospital',
  'Quill',
  'Battery',
  'House',
  'Rain',
  'Beach',
  'Hydrogen',
  'Rainbow',
  'Beard',
  'Ice',
  'Raincoat',
  'Bed',
  'Insect',
  'Refrigerator',
  'Restaurant',
  'Iron',
  'River',
  'Branch',
  'Island',
  'Rocket',
  'Breakfast',
  'Jackal',
  'Room',
  'Jelly',
  'Rose',
  'Camera',
  'Jewelry',
  'Candle',
  'Sandwich',
  'Car',
  'Juice',
  'School',
  'Caravan',
  'Kangaroo',
  'Scooter',
  'Carpet',
  'Shampoo',
  'Cartoon',
  'Kitchen',
  'Shoe',
  'Kite',
  'Soccer',
  'Knife',
  'Spoon',
  'Crayon',
  'Lamp',
  'Stone',
  'Crowd',
  'Sugar',
  'Leather',
  'Death',
  'Library',
  'Lighter',
  'Telephone',
  'Diamond',
  'Lion',
  'Television',
  'Dinner',
  'Lizard',
  'Tent',
  'Lock',
  'Tomato',
  'Dog',
  'Lunch',
  'Toothbrush',
  'Dream',
  'Machine',
  'Traffic',
  'Dress',
  'Magazine',
  'Train',
  'Truck',
  'Egg',
  'Eggplant',
  'Market',
  'Umbrella',
  'Match',
  'Van',
  'Elephant',
  'Microphone',
  'Vase',
  'Energy',
  'Monkey',
  'Vegetable',
  'Engine',
  'Morning',
  'Vulture',
  'Motorcycle',
  'Wall',
  'Evening',
  'Nail',
  'Whale',
  'Eye',
  'Napkin',
  'Window',
  'Needle',
  'Wire',
  'Nest',
  'Xylophone',
  'Fish',
  'Yacht',
  'Flag',
  'Night',
  'Yak',
  'Flower',
  'Notebook',
  'Zebra',
  'Football',
  'Ocean',
  'Zoo',
  'Forest',
  'Oil',
  'Garden',
  'Fountain',
  'Orange',
  'Gas',
  'Oxygen',
  'Furniture',
  'Oyster',
  'Glass',
  'Garage',
  'Ghost',
  'Moon'
]
export const generateRandomName = () => {
  return `${sample(ADJECTIVES)} ${sample(NOUNS)}`
}
