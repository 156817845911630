/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM11.2187 8.596L6.298 11.056C6.19639 11.1068 6.08349 11.1307 5.97001 11.1256C5.85653 11.1205 5.74624 11.0865 5.6496 11.0268C5.55296 10.9671 5.47318 10.8837 5.41783 10.7845C5.36248 10.6853 5.3334 10.5736 5.33334 10.46V5.53867C5.33334 5.42511 5.36235 5.31343 5.41762 5.21423C5.47289 5.11503 5.55258 5.03159 5.64914 4.97182C5.7457 4.91205 5.85593 4.87794 5.96937 4.87273C6.08281 4.86751 6.1957 4.89135 6.29734 4.942L11.2193 7.4C11.3308 7.45515 11.4246 7.54036 11.4901 7.64601C11.5557 7.75166 11.5904 7.87354 11.5903 7.99787C11.5902 8.12221 11.5554 8.24405 11.4897 8.34962C11.4241 8.4552 11.3302 8.54031 11.2187 8.59533V8.596Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM11.2187 8.596L6.298 11.056C6.19639 11.1068 6.08349 11.1307 5.97001 11.1256C5.85653 11.1205 5.74624 11.0865 5.6496 11.0268C5.55296 10.9671 5.47318 10.8837 5.41783 10.7845C5.36248 10.6853 5.3334 10.5736 5.33334 10.46V5.53867C5.33334 5.42511 5.36235 5.31343 5.41762 5.21423C5.47289 5.11503 5.55258 5.03159 5.64914 4.97182C5.7457 4.91205 5.85593 4.87794 5.96937 4.87273C6.08281 4.86751 6.1957 4.89135 6.29734 4.942L11.2193 7.4C11.3308 7.45515 11.4246 7.54036 11.4901 7.64601C11.5557 7.75166 11.5904 7.87354 11.5903 7.99787C11.5902 8.12221 11.5554 8.24405 11.4897 8.34962C11.4241 8.4552 11.3302 8.54031 11.2187 8.59533V8.596Z\" />\n</svg>\n";
export default src;
