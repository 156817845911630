/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { flatten, map } from 'lodash'

import { countries, languages, provinces, states } from './data'
import buildEdit from './edit'
import { ReactComponent as IconCountry } from './icon-country.svg.jsx'
import { ReactComponent as IconLanguage } from './icon-language.svg.jsx'
import { ReactComponent as IconState } from './icon-state.svg.jsx'
import progressiveDisclosure from './progressive-disclosure'
import validateShape from './validations'
import buildView from './view'

function buildGadget (label, Icon, _options, hasMultiple) {
  const options = hasMultiple ? flatten(map(_options, 'options')) : _options
  return {
    getAssembler: ({ Basic, GriddedText }, gridded) =>
      gridded ? GriddedText : Basic,
    View: buildView(options),
    Edit: buildEdit(_options, hasMultiple),

    validateShape: validateShape(options),

    progressiveDisclosure: progressiveDisclosure(options),

    defaultValue: undefined,

    sampleValue: '',

    meta: {
      label,
      Icon,
      category: 'Special'
    }
  }
}

export const CountryDropdown = buildGadget('Country', IconCountry, countries)
export const LanguagesDropdown = buildGadget(
  'Language',
  IconLanguage,
  languages
)
export const StateDropdown = buildGadget(
  'State/Province',
  IconState,
  [
    { lbl: 'States', options: states },
    { lbl: 'Provinces', options: provinces }
  ],
  true
)
