/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { isEmpty } from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as KualiK } from '../../../components/logo-grey.svg.jsx'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import * as Icons from '../../../icons'
import { Flex } from '../../../ui/layout'

export const Basic = ({
  isCanonicalGadget,
  headless,
  label,
  LabelElement = 'label',
  desc,
  displayDescInline,
  gadget,
  template,
  errors,
  gridded,
  highlight,
  readOnly,
  warning,
  noRelative,
  previousGadget
}) => (
  <Gadget
    headless={headless}
    noRelative={noRelative}
    gridded={gridded}
    className={cx('formbot-gadget')}
    data-testid={`gadget-${template.label || template.id}`}
    id={`gadget-${template.id}`}
    highlight={highlight}
    readOnly={readOnly}
  >
    {isCanonicalGadget && <KualiKBadge />}
    {!isEmpty(warning) && <Warning id={template.id} message={warning} />}
    {(label || !isEmpty(desc)) && (
      <Flex2 py={2} inline={displayDescInline}>
        {label && (
          <LabelElement
            id={`${template.id}-label`}
            {...(LabelElement === 'label' && { htmlFor: template.id })}
          >
            {label}
          </LabelElement>
        )}
        {!isEmpty(desc) && desc}
      </Flex2>
    )}
    {gadget}
    {previousGadget && <PreviousWrapper>{previousGadget}</PreviousWrapper>}
    {errors}
  </Gadget>
)

export const BasicWithoutLabel = props => (
  <Basic {...props} LabelElement='div' />
)

export const GriddedText = ({
  isCanonicalGadget,
  label,
  desc,
  displayDescInline,
  gadget,
  template,
  gridded,
  highlight,
  readOnly,
  warning,
  noRelative,
  previousGadget
}) => (
  <GriddedGadget
    noRelative={noRelative}
    gridded={gridded}
    className={cx('formbot-gadget')}
    highlight={highlight}
    readOnly={readOnly}
  >
    {isCanonicalGadget && <KualiKBadge />}
    <Padded id={`gadget-${template.id}`}>
      {!isEmpty(warning) && <Warning id={template.id} message={warning} />}
      {(label || !isEmpty(desc)) && (
        <Flex2 mb={2} inline={displayDescInline}>
          {label && (
            <label htmlFor={template.id} id={`${template.id}-label`}>
              {label}
            </label>
          )}
          {!isEmpty(desc) && desc}
        </Flex2>
      )}
    </Padded>
    {gadget}
    {previousGadget && <PreviousWrapper>{previousGadget}</PreviousWrapper>}
  </GriddedGadget>
)

export const Group = ({
  desc,
  displayDescInline,
  errors,
  gadget,
  isCanonicalGadget,
  label,
  noRelative,
  template,
  warning
}) => (
  <fieldset
    className={cx(
      noRelative ? 'static' : 'relative',
      'border border-l-0 border-t-0 border-light-gray-300 px-4 py-2'
    )}
    data-testid={`gadget-${template.label || template.id}`}
    id={`gadget-${template.id}`}
  >
    {isCanonicalGadget && <KualiKBadge />}
    {!isEmpty(warning) && <Warning id={template.id} message={warning} />}
    <legend className='sr-only'>{label}</legend>
    {(label || !isEmpty(desc)) && (
      <div className={cx('py-2', { 'flex items-center': displayDescInline })}>
        <div aria-hidden='true'>{label}</div>
        {!isEmpty(desc) && desc}
      </div>
    )}
    {gadget}
    {errors}
  </fieldset>
)

const KualiKBadge = () => (
  <KualiK className='canonical-gadget-k absolute right-[10px] top-[11px] h-3 w-3' />
)

const Warning = ({ id, message }) => (
  <Right>
    <StyledTooltip id={`${id}-warning`} place='top'>
      <div>{message}</div>
    </StyledTooltip>
    <TooltipTrigger
      as={Icons.AlertWarning}
      label='Warning'
      tooltipId={`${id}-warning`}
    />
  </Right>
)

const Padded = styled.div`
  padding: 16px 16px 0px 16px;
`

const Flex2 = styled(Flex)`
  align-items: ${p => (p.inline ? 'center' : 'flex-start')};
  flex-direction: ${p => (p.inline ? 'row' : 'column')};
`

const Gadget = styled.div`
  ${p =>
    p.headless &&
    css`
      background: repeating-linear-gradient(
        45deg,
        #fafafa,
        #fafafa 10px,
        #fff 10px,
        #fff 20px
      );
    `}
  position: ${p => (p.noRelative ? 'static' : 'relative')};
  display: flex;
  flex-shrink: 0;
  flex-direction: column;

  ${p =>
    p.gridded &&
    css`
      border: 1px solid var(--light-gray-300);
      border-left: none;
      border-top: none;
      padding: 8px 16px;
    `}

  &:only-child {
    flex-grow: 1;
  }

  height: 100%;

  ${p =>
    p.highlight &&
    css`
      background: ${p.highlight};
    `}
`

const GriddedGadget = styled(Gadget)`
  padding: 0 !important;
`

const Right = styled.div`
  position: absolute;
  right: 15px;
`

const StyledTooltip = styled(Tooltip)`
  width: 200px;
`
const PreviousWrapper = styled.div`
  background-color: azure;
`
