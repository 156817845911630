/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { filter, find } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'
import Checkbox from '../../ui/checkbox'
import { Space } from '../../ui/layout'
import * as Affiliations from '../lookups/affiliations'
import * as Groups from '../lookups/groups'
import * as Roles from '../lookups/roles'
import * as Users from '../lookups/users'
import { useAddIdentityMutation } from './mutation.add-identity-to-role'
import { useRemoveIdentityMutation } from './mutation.remove-identity-from-role'

export default function IdentityPicker ({
  group,
  withAuthenticated,
  preventLastRemoval
}) {
  const [which, setWhich] = React.useState('users')
  const addIdentity = useAddIdentityMutation(group.id, group.identities)
  const removeIdentity = useRemoveIdentityMutation(group.id, group.identities)
  const { loading, data } = useQuery(query, { fetchPolicy: 'network-only' })
  const showAffiliations = !loading && !!data?.userAffiliations?.length
  return (
    <>
      {withAuthenticated && (
        <CheckboxStyles>
          <Checkbox
            id='permission-all-authenticated-users'
            name='permission-all-authenticated-users'
            label={i18n._('all.authenticated.people')}
            checked={!!find(group.identities, { id: '$$AUTHENTICATED$$' })}
            onChange={checked => {
              if (checked) {
                addIdentity({
                  id: '$$AUTHENTICATED$$',
                  type: 'VIRTUAL',
                  label: 'Authenticated'
                })
              } else {
                removeIdentity('$$AUTHENTICATED$$')
              }
            }}
          />
        </CheckboxStyles>
      )}
      <Nav>
        <div>
          <Trans id='search.for.colon' />
        </div>
        <Space expand />
        <button
          data-active={which === 'users'}
          onClick={() => setWhich('users')}
        >
          <Trans id='people' />
        </button>
        <button
          data-active={which === 'groups'}
          onClick={() => setWhich('groups')}
        >
          <Trans id='groups' />
        </button>
        <button
          data-active={which === 'roles'}
          onClick={() => setWhich('roles')}
        >
          <Trans id='roles' />
        </button>
        {showAffiliations && (
          <button
            data-active={which === 'affiliations'}
            onClick={() => setWhich('affiliations')}
          >
            <Trans id='affiliations' />
          </button>
        )}
      </Nav>
      {which === 'users' && <UserManager group={group} add={addIdentity} />}
      {which === 'groups' && <GroupManager group={group} add={addIdentity} />}
      {which === 'roles' && <RoleManager group={group} add={addIdentity} />}
      {which === 'affiliations' && showAffiliations && (
        <AffiliationManager
          group={group}
          add={addIdentity}
          allAffiliations={data.userAffiliations}
        />
      )}
      <div className='mt-2 flex flex-wrap gap-1'>
        {group.identities
          .filter(identity => identity.type !== 'VIRTUAL')
          .map(identity => (
            <div
              key={identity.id}
              className='flex items-center gap-2 rounded-full bg-light-gray-200 px-4 py-1.5 dark:bg-light-gray-300'
            >
              <IdentityIcon type={identity.type} />
              <span className='text-sm text-medium-gray-500'>
                {identity.label}
              </span>
              {!(preventLastRemoval && group.identities.length === 1) && (
                <button
                  aria-label='Remove Identity'
                  onClick={() => removeIdentity(identity.id)}
                >
                  <Icons.Close className='h-2 w-2' />
                </button>
              )}
            </div>
          ))}
      </div>
    </>
  )
}

const UserManager = ({ group, add }) => (
  <Users.Multiselect
    standalone
    hideResults
    id={`${group.id}-users`}
    placeholder='Add a Person...'
    onAdd={obj => add({ ...obj, type: 'USER' })}
    value={filter(group.identities, { type: 'USER' })}
  />
)

const GroupManager = ({ group, add }) => (
  <Groups.Multiselect
    standalone
    hideResults
    id={`${group.id}-groups`}
    placeholder='Add a Group...'
    onAdd={obj => add({ ...obj, type: 'GROUP' })}
    value={filter(group.identities, { type: 'GROUP' })}
  />
)

const RoleManager = ({ group, add }) => (
  <Roles.Multiselect
    standalone
    hideResults
    id={`${group.id}-roles`}
    placeholder='Add a Role...'
    onAdd={obj => add({ ...obj, type: 'ROLE' })}
    value={filter(group.identities, { type: 'ROLE' })}
  />
)

const AffiliationManager = ({ group, add, allAffiliations }) => (
  <Affiliations.Multiselect
    standalone
    hideResults
    id={`${group.id}-affiliations`}
    affiliations={allAffiliations}
    placeholder='Add an Affiliation...'
    onAdd={obj => add({ ...obj, type: 'AFFILIATION' })}
    value={filter(group.identities, { type: 'AFFILIATION' })}
  />
)

function IdentityIcon ({ type }) {
  if (type === 'USER') {
    return <Icons.User />
  }
  if (type === 'GROUP') {
    return <Icons.Users />
  }
  if (type === 'ROLE') {
    return <Icons.CowboyHat />
  }
  if (type === 'AFFILIATION') {
    return <Icons.BusinessCard />
  }
  return null
}

const query = gql`
  query UserAffiliations {
    userAffiliations {
      orgDN: orgDn
      affiliation
    }
  }
`

const CheckboxStyles = styled.div`
  > label {
    margin: 0;
    margin-bottom: 4px;
  }
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  margin-bottom: 4px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-left: 8px;
    border-bottom: 2px solid transparent;
    color: #666;
  }
  button[data-active='true'] {
    color: var(--dark-gray-500);
    font-weight: 500;
    border-bottom-color: #3369a3;
  }
`
