/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash'

import { elixirBackend } from '../components/feature-flags'
import possibleTypes from '../gql/possible-types.json'

class GraphQLError extends Error {
  constructor (message) {
    super(message)
    this.name = 'GraphQLError'
  }
}

const uri = elixirBackend
  ? '/platform/api/vhax/graphql'
  : '/app/api/vhax/graphql'

export default new ApolloClient({
  uri,
  cache: new InMemoryCache({ possibleTypes }),
  link: from([
    onError(err => {
      if (err?.networkError?.response?.status === 401) {
        Sentry.withScope(scope => {
          scope.setFingerprint(['unauthorized', 'graphql'])
          Sentry.captureException(err.networkError)
        })
      } else if (!isEmpty(err?.networkError)) {
        Sentry.withScope(scope => {
          scope.addBreadcrumb({ message: 'Generic GraphQL Network Error' })
          scope.setExtra(
            'networkError',
            JSON.stringify(err.networkError, null, 2)
          )
          Sentry.captureException(err?.networkError)
        })
      } else if (!isEmpty(err?.graphQLErrors)) {
        const pathArray = err.graphQLErrors[0]?.path
        Sentry.withScope(scope => {
          if (pathArray) {
            scope.setExtra('graphQLPath', pathArray.join('.'))
          }
          scope.setExtra(
            'graphQLErrorsObject',
            JSON.stringify(err.graphQLErrors, null, 2)
          )
          if (pathArray) {
            scope.setFingerprint(['graphql', 'error', pathArray.join('.')])
          }

          Sentry.captureException(
            new GraphQLError(err.graphQLErrors[0]?.message)
          )
        })
      } else {
        if (err?.networkError instanceof TypeError) {
          return // This is a subset of errors that spawns when requests are forcibly cancelled by the browser.
        }
        Sentry.withScope(scope => {
          scope.addBreadcrumb({
            message: 'Generic GraphQL Error'
          })
          scope.setExtra('error', JSON.stringify(err, null, 2))
          Sentry.captureException(new Error('Generic GraphQL Error'))
        })
      }
      console.error(err)
    }),
    setContext(({ operationName }, { headers }) => {
      return {
        headers: {
          ...headers,
          'apollographql-operation-name': operationName
        }
      }
    }),
    new HttpLink({ uri })
  ])
})
