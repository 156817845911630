/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useAddIdentityMutation = (roleId, identities) => {
  const [addIdentity] = useMutation(mutation)
  return identity => addIdentity(getParams(roleId, identity, identities))
}

const mutation = gql`
  mutation AddIdentityToRole(
    $roleId: String!
    $identity: PolicyGroupIdentityInput!
  ) {
    attachIdentityToRoleV2(args: { roleId: $roleId, identity: $identity }) {
      result {
        id
        identities {
          id
          type
          label
        }
      }
    }
  }
`

const getParams = (roleId, identity, identities) => ({
  variables: { roleId, identity },
  optimisticResponse: {
    attachIdentityToRoleV2: {
      result: {
        __typename: 'PolicyGroup',
        id: roleId,
        identities: [
          ...identities,
          {
            ...identity,
            __typename: 'PolicyGroupIdentity'
          }
        ]
      }
    }
  }
})
