/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import FocusTrap from 'focus-trap-react'
import { cloneDeep, find, get, reject } from 'lodash'
import React from 'react'
import shortid from 'shortid'

import { gadgets } from '../../formbot'
import { VisuallyHidden } from '../../ui/a11y'
import Button, { ButtonGroup } from '../../ui/button'
import { Wrapper } from '../../ui/layout'
import Popover from '../../ui/popover'

const SortConfiguration = ({ value, onChange, hasFilter }) => (
  <ButtonGroup mb={hasFilter ? 2 : 0}>
    <Button
      outline
      onClick={() => onChange(false)}
      data-active={value === false}
      aria-pressed={value === false}
      aria-label={i18n._('sort.descending')}
    >
      A ➞ Z
    </Button>
    <Button
      outline
      onClick={() => onChange(true)}
      data-active={value === true}
      aria-pressed={value === true}
      aria-label={i18n._('sort.ascending')}
    >
      Z ➞ A
    </Button>
    <Button
      outline
      onClick={() => onChange(undefined)}
      data-active={value !== true && value !== false}
      aria-pressed={value !== true && value !== false}
      aria-label={i18n._('remove.sort')}
    >
      <Trans id='no.sort' />
    </Button>
  </ButtonGroup>
)

export default ({ gadget, params, updateParams }) => {
  const field = gadget.formKey
  const filters = find(params.filters, { field })
  const sort = find(params.sorts, { field })
  const FilterUI = get(gadgets, [gadget.type, 'filters', 'UI'])
  const sortable = !(
    gadget.unsortable || get(gadgets, [gadget.type, 'unsortable'])
  )
  if (!FilterUI && !sortable) return null
  return (
    <Popover
      top={32}
      data-testid={`popover-${field}`}
      role='dialog'
      aria-labelledby='headerPopoverTitle'
      aria-describedby='headerPopoverDesc'
    >
      <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
        <Wrapper p={2}>
          <VisuallyHidden id='headerPopoverTitle'>
            Sort {FilterUI ? 'and Filter ' : ''}Configuration
          </VisuallyHidden>
          <VisuallyHidden id='headerPopoverDesc'>
            Define how your selected column is sorted{' '}
            {FilterUI ? 'and filtered ' : ''}using the following controls
          </VisuallyHidden>
          {sortable && (
            <SortConfiguration
              hasFilter={!!FilterUI}
              value={cloneDeep((sort || {}).ascending)}
              onChange={ascending =>
                updateParams(draft => {
                  draft.skip = 0
                  draft.sorts = []
                  if (ascending !== undefined) {
                    draft.sorts.push({
                      id: shortid.generate(),
                      field,
                      type: gadget.type,
                      ascending
                    })
                  }
                })
              }
            />
          )}
          {FilterUI && (
            <FilterUI
              gadget={gadget}
              value={cloneDeep(filters)}
              onChange={newFilter => {
                updateParams(draft => {
                  draft.skip = 0
                  draft.filters = reject(draft.filters, { field })
                  if (newFilter) draft.filters.push(newFilter)
                })
              }}
            />
          )}
        </Wrapper>
      </FocusTrap>
    </Popover>
  )
}
