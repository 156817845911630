/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import FocusTrap from 'focus-trap-react'
import React from 'react'
import ReactDOM from 'react-dom'

import { Preserve, Transition } from './tailwind-transition'
import { useEscapePress } from './use-escape-click'

export default function CenterModal ({ id, children, showing, dismiss }) {
  const [ref, setRef] = React.useState(null)
  useEscapePress(dismiss)
  return ReactDOM.createPortal(
    <Preserve show={showing} timeout={300}>
      <>
        {ref && (
          <FocusTrap
            focusTrapOptions={{ escapeDeactivates: false }}
            containerElements={[ref]}
          />
        )}
        <div
          id={id}
          className='relative z-50'
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
          ref={setRef}
        >
          <Transition
            className='fixed inset-0 bg-dark-gray-300 opacity-75 dark:bg-light-gray-300'
            entering='transition-opacity duration-300 ease-out'
            enteringFrom='opacity-0'
            enteringTo='opacity-100'
            leaving='transition-opacity duration-200 ease-in'
            leavingFrom='opacity-100'
            leavingTo='opacity-0'
          />
          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div
              className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'
              onClick={dismiss}
            >
              <Transition
                className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-5xl sm:p-6'
                entering='transition-all duration-300 ease-out'
                enteringFrom='translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95'
                enteringTo='translate-y-0 opacity-100 sm:scale-100'
                leaving='transition-all duration-200 ease-in'
                leavingFrom='translate-y-0 opacity-100 sm:scale-100'
                leavingTo='translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95'
                onClick={e => e.stopPropagation()}
              >
                {children}
              </Transition>
            </div>
          </div>
        </div>
      </>
    </Preserve>,
    document.body
  )
}
