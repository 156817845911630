/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import Edit from './edit'

export default {
  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,
  defaultValue: { type: null, details: {} },
  Edit,
  meta: {
    hidden: true,
    Icon: null,
    label: 'Gadget Dropdown'
  },
  subFields: null,
  sampleValue: { type: 'Text', details: {} },
  progressiveDisclosure: null,
  validateShape: v => v.any(),
  View: () => null
}
