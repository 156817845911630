/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import Loading from '../../components/loading'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import AnimatedOutlet from '../home-modals-outlet'
import MainContent from './components/main-content'
import MainContentSmall from './components/main-content-sm'
import { useBreakpoints } from './components/use-breakpoints'

export default function OldActionsOuter () {
  return (
    <>
      <AnimatedOutlet context={useOutletContext()} />
      <OldActions />
    </>
  )
}

function OldActions () {
  const [params, setParams] = React.useState({
    query: '',
    page: 1,
    sortBy: 'createdAt',
    sortDir: 'desc'
  })
  const q = getOldActionsQuery(params)
  const { data, loading, error } = useQuery(q)
  return (
    <main>
      {error ? (
        <Error error={error} />
      ) : (
        <OldActionsInner
          loading={loading && !data}
          params={params}
          setParams={setParams}
          data={data}
        />
      )}
    </main>
  )
}

function OldActionsInner ({ loading, params, setParams, data }) {
  const response = parse(data)
  const size = useBreakpoints()
  const ref = React.useRef()
  const focus = key => {
    if (!ref.current) return
    if (key === -1) {
      const search = ref.current.querySelector('#search-box')
      if (search) search.focus()
      return
    }
    const listNodes = ref.current.querySelectorAll('.kc-table-row')
    if (listNodes.length === 0) return
    if (key > listNodes.length - 1) key = listNodes.length - 1
    listNodes[key].focus()
  }
  const Component = size === 'small' ? MainContentSmall : MainContent
  return (
    <Wrapper ref={ref} className='text-sm'>
      {loading ? (
        <Loading />
      ) : (
        <Component
          loading={loading}
          setFocus={focus}
          onParamsChange={setParams}
          params={params}
          response={response}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 50vh;
`

const getSort = (sortBy, sortDir) => {
  if (!sortBy) return null
  const direction = sortDir === 'asc' ? '' : '-'
  return [`${direction}${sortBy}`]
}

const parse = data => {
  return {
    results: (data?.actions?.edges ?? []).map(({ node }) => ({
      ...node,
      createdAt: +node.createdAt,
      type: node.type === 'Initiate' ? 'Form Fill' : node.type
    })),
    total: data?.actions?.totalCount ?? 0
  }
}

const oldActionsQuery = gql`
  query OldActions($args: ActionConnectionInput!) {
    actions: actionsConnection(args: $args) {
      totalCount
      edges {
        node {
          id
          type
          createdAt
          display
          details
          handlerUrl
        }
      }
    }
  }
`

const getOldActionsQuery = ({ page, query, sortBy, sortDir }) => ({
  errorPolicy: 'all',
  variables: {
    args: {
      skip: 30 * (page - 1),
      limit: 30,
      sort: getSort(sortBy, sortDir),
      query
    }
  },
  query: oldActionsQuery
})
