/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

const AnimationContext = React.createContext()

export function Preserve ({ children, show, timeout }) {
  const [showing, setShowing] = React.useState(show)
  React.useEffect(() => {
    if (show) return setShowing(true)
    const timer = setTimeout(() => setShowing(false), timeout)
    return () => clearTimeout(timer)
  }, [show, timeout])
  return (
    <AnimationContext.Provider value={show}>
      {showing ? children : null}
    </AnimationContext.Provider>
  )
}

export function Transition ({
  className,
  entering,
  enteringFrom,
  enteringTo,
  leaving,
  leavingFrom,
  leavingTo,
  ...props
}) {
  const ref = React.useRef()
  const show = React.useContext(AnimationContext)
  React.useEffect(() => {
    if (show) {
      ref.current.className = cx(className, enteringFrom)
      setTimeout(() => {
        ref.current.className = cx(className, entering, enteringTo)
      }, 0)
    } else {
      ref.current.className = cx(className, leavingFrom)
      setTimeout(() => {
        ref.current.className = cx(className, leaving, leavingTo)
      }, 0)
    }
  }, [show])
  return <div ref={ref} {...props} />
}
