/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { debounce } from 'lodash'
import React from 'react'
import { useHref, useLocation, useOutletContext } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import * as Empty from '../../../components/data-table/empty'
import Header from '../../../components/data-table/header'
import Pagination from '../../../components/data-table/pagination'
import PopoverButton from '../../../components/data-table/popover-button'
import Row from '../../../components/data-table/row'
import { SmallSearch } from '../../../components/data-table/search-bar'
import Loading from '../../../components/loading'
import { StaticOutlet } from '../../../components/static-outlet'
import { GraphQLError as Error } from '../../../components/system-error'
import { useQuery } from '../../../components/use-query'
import * as Icons from '../../../icons'
import { Announcer } from '../../../ui/a11y'
import { Table, TableBody } from '../../../ui/table'
import Toggle from '../../../ui/toggle'
import HierarchyView from './components/hierarchy-view'
import NewGroupPopup from './components/new-group-popup'

export default function Groups () {
  const { blueprints, outerLoading, canManageIdentity } = useOutletContext()
  const location = useLocation()
  const [showHierarchy, toggleShowing] = React.useState(false)
  const [params, updateParams] = useImmer(defaultParams)
  const debouncedSearch = React.useCallback(
    debounce(q => {
      updateParams(draft => {
        draft.skip = 0
        draft.q = q
      })
    }, 400),
    []
  )
  const {
    groups,
    totalCount,
    loading,
    error,
    queryInput,
    setQueryInput,
    refetch
  } = useGroupsData(params)
  const groupsPath = useHref('.')
  if (error) {
    return <Error error={error} />
  }

  const visibleColumns = params.columns.filter(column => column.visible)
  if (outerLoading) return <Loading />
  return (
    <>
      <TransitionGroup>
        <CSSTransition
          key={location.pathname.endsWith('/groups')}
          timeout={450}
        >
          <StaticOutlet context={{ blueprints, refetch }} />
        </CSSTransition>
      </TransitionGroup>
      <Wrapper>
        <TopSection>
          <h1>
            <Trans
              id='groups.using.blueprints'
              message='Groups Using Blueprints'
            />
          </h1>
          <ButtonGrouping>
            <Toggle
              aria-label={i18n._({
                id: 'toggle.hierarchy.view',
                message: 'Toggle Hierarchy View'
              })}
              on={i18n._({ id: 'show.hierarchy', message: 'Show Hierarchy' })}
              value={showHierarchy}
              onChange={() => {
                setQueryInput('')
                updateParams(draft => {
                  draft.skip = 0
                  draft.q = null
                })
                toggleShowing(!showHierarchy)
              }}
            />
            <SmallSearch
              value={queryInput ?? ''}
              onChange={newQueryInput => {
                setQueryInput(newQueryInput)
                debouncedSearch(newQueryInput)
                toggleShowing(false)
              }}
              reset={() => {
                setQueryInput('')
                updateParams(draft => {
                  draft.skip = 0
                  draft.q = null
                })
              }}
              onEnter={() => {
                updateParams(draft => {
                  draft.skip = 0
                  draft.q = queryInput
                })
              }}
            />
            {canManageIdentity && (
              <PopoverButton
                hideArrow
                buttonProps={{ transparent: false }}
                label={
                  <>
                    <Icons.Add fill='var(--white)' mr={2} />
                    <Trans id='add.group' message='Add Group' />
                  </>
                }
                right={1}
              >
                {hide => (
                  <div style={{ width: '100%' }}>
                    <NewGroupPopup
                      onCancel={hide}
                      blueprints={[
                        { name: '--Unorganized--', id: '' },
                        ...(blueprints ?? [])
                      ]}
                      refetch={refetch}
                      path={groupsPath}
                    />
                  </div>
                )}
              </PopoverButton>
            )}
          </ButtonGrouping>
        </TopSection>
        {showHierarchy ? (
          <HierarchyView />
        ) : loading ? (
          <StyledSpinnerContainer>
            <Loading size={400} />
          </StyledSpinnerContainer>
        ) : (
          <TableWrapper>
            <Table>
              <Header
                params={params}
                updateParams={updateParams}
                columns={visibleColumns}
              />
              <TableBody>
                {groups.map(group => (
                  <Row
                    key={group.id}
                    document={group}
                    columns={visibleColumns}
                    data-testid={group.id}
                    linkTo={`${group.id}/view`}
                  />
                ))}
              </TableBody>
              {totalCount !== 0 && (
                <Pagination
                  onUpdate={({ skip, limit }) => {
                    updateParams(draft => {
                      draft.skip = skip
                      draft.limit = limit
                    })
                  }}
                  total={totalCount}
                  skip={params.skip}
                  limit={params.limit}
                />
              )}
              <Announcer id='document-list-pagination'>
                {getPaginationString(totalCount, params.skip, params.limit)}
              </Announcer>
            </Table>
            {totalCount === 0 &&
              (params.q ? (
                <Empty.NoMatching
                  reset={() => {
                    setQueryInput('')
                    updateParams(draft => {
                      draft.q = null
                    })
                  }}
                />
              ) : (
                <Empty.NoResults />
              ))}
          </TableWrapper>
        )}
      </Wrapper>
    </>
  )
}

const PADDING = 16
const WIDTH = 1200
const FIRST_MEDIA_WIDTH = WIDTH + PADDING * 2

const StyledSpinnerContainer = styled.div`
  grid-column: 2;
  height: 100%;
  position: relative;
`

const Wrapper = styled.div`
  padding-bottom: ${PADDING}px;
  display: grid;
  grid-template-columns:
    calc((100vw - ${WIDTH}px) / 2)
    ${WIDTH}px
    calc(100% - (${WIDTH}px + 100vw) / 2);
  grid-template-rows: min-content minmax(0, 1fr);
  align-items: start;
  width: 100%;
  height: 100%;
  max-height: 100%;

  @media (max-width: ${FIRST_MEDIA_WIDTH}px) {
    grid-template-columns:
      calc(${PADDING}px)
      calc(100vw - (${PADDING}px * 2))
      calc(${PADDING}px - (100vw - 100%));
  }

  @media (max-width: 800px) {
    padding: 0;
  }
`

const TableWrapper = styled.div`
  grid-column: 2;
  width: ${WIDTH}px;

  td {
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 200px;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  th {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  @media (max-width: ${FIRST_MEDIA_WIDTH}px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    button {
      white-space: normal;
    }

    th {
      padding: 4px;
    }
  }

  @media (max-width: 478px) {
    td {
      max-width: 100px;
    }
  }
`

const ButtonGrouping = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

const TopSection = styled.div`
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  padding: 16px 0 32px;
  h1 {
    margin: 0;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
  }

  @media (max-width: ${FIRST_MEDIA_WIDTH}px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    display: block;
    padding: 16px 8px;

    ${ButtonGrouping} {
      justify-content: space-between;
      gap: 8px;
      align-items: center;
    }
  }
`

const getPaginationString = (count, start, limit) => {
  if (!count) {
    return (
      <Trans
        id='no.matching.search.results'
        message='No matching search results'
      />
    )
  }
  const end = Math.min(start + limit, count)
  return i18n._(
    {
      id: 'pagesbuilder.doclist.count.users',
      message: 'Showing {start} to {end} of {count} total users'
    },
    {
      start: start + 1,
      end,
      count
    }
  )
}

const defaultColumns = [
  {
    type: 'Text',
    formKey: 'name',
    label: <Trans id='group.name' message='Group Name' />,
    details: {},
    visible: true
  },
  {
    unsortable: true,
    type: 'Text',
    formKey: 'category.name',
    label: <Trans id='blueprint.used' message='Blueprint Used' />,
    details: {},
    visible: true
  },
  {
    unsortable: true,
    type: 'Text',
    formKey: 'roles',
    label: <Trans id='no.of.roles' message='No. of Roles' />,
    details: {},
    visible: true,
    transform: r => r.filter(role => !/admin|member/.test(role.id)).length
  }
]

const defaultParams = {
  skip: 0,
  limit: 25,
  q: undefined,
  sorts: [],
  columns: defaultColumns
}

function useGroupsData (params) {
  const graphqlVariables = React.useMemo(() => {
    const sort = params.sorts
      .filter(({ field }) => field)
      .map(({ ascending, field }) => {
        const prefix = ascending ? '-' : ''
        return `${prefix}${field}`
      })
    return {
      active: params.active,
      limit: params.limit,
      skip: params.skip,
      sort,
      q: params.q
    }
  }, [params])
  const [queryInput, setQueryInput] = React.useState()
  const q = {
    query: groupsQuery,
    variables: graphqlVariables,
    fetchPolicy: 'network-only'
  }
  const { data, loading, error, refetch } = useQuery(q)
  const groups = React.useMemo(() => {
    if (!data?.groupsConnection) return
    return data.groupsConnection.edges
      .slice(params.skip, params.skip + params.limit)
      .map(({ node: group }) => group)
  }, [data, params.limit, params.skip])
  const totalCount = data?.groupsConnection?.totalCount ?? 0
  return {
    q,
    groups,
    totalCount,
    loading,
    error,
    queryInput,
    setQueryInput,
    refetch
  }
}

const groupsQuery = gql`
  query Groups(
    $q: String
    $skip: Int!
    $limit: Int!
    $sort: [String!]
    $categoryId: ID
  ) {
    groupsConnection(
      args: {
        query: $q
        skip: $skip
        limit: $limit
        sort: $sort
        categoryId: $categoryId
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        skip
        limit
      }
      edges {
        node {
          id
          newId
          name
          parentId
          category {
            id
            name
          }
          roleSchemas {
            id
            name
            description
          }
          roles {
            id
            name
          }
        }
      }
    }
  }
`
