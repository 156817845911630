/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import 'what-input'

import './app.dist.css'
import './components/browser-check/browser-check'
import config from './config'
import I18nWrapper from './i18n'
import routes from './routes'

const deployPreviewRE = /^builder-ui-pr-([a-zA-Z0-9-]+)\.onrender\.com$/
const [, n] = deployPreviewRE.exec(window.location.hostname) || []
if (n) {
  window.location = `https://buildprs.kualibuild.com/app/pr-num/${n}`
  throw new Error('redirecting')
}

const { origin, hash, pathname } = window.location
if (hash) {
  window.location.replace(`${origin}/${hash.slice(2)}`)
  throw new Error('redirecting')
}
if (pathname.startsWith('/app/builder')) {
  window.location.replace(`${origin}${pathname.replace('/app/builder', '')}`)
  throw new Error('redirecting')
}

Sentry.init(config.sentry)

if (!window.loggedInUser) {
  const cookie = (document.cookie || '')
    .split(/\s*;\s*/g)
    .reduce((map, item) => {
      const splitIndex = item.indexOf('=')
      if (splitIndex !== -1) {
        const key = item.substring(0, splitIndex)
        const value = decodeURIComponent(item.substring(splitIndex + 1))
        map[key] = value
      }
      return map
    }, {})
  if (cookie.authToken) {
    fetch('/api/v1/users/current', {
      headers: {
        Authorization: `Bearer ${cookie.authToken}`
      }
    })
      .then(res => res.json())
      .then(user => {
        window.loggedInUser = user
      })
  }
}

sessionStorage.setItem(
  'appSwitcher:apps',
  JSON.stringify([
    { iconName: 'layers', name: 'Build', url: '/' },
    { iconName: 'person', name: 'Users', url: '/users' },
    { iconName: 'people', name: 'Groups', url: '/groups' },
    { iconName: 'assignment_turned_in', name: 'Actions', url: '/app/actions' }
  ])
)

const router = createBrowserRouter(routes)
const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(
  <I18nWrapper>
    <RouterProvider router={router} />
  </I18nWrapper>
)
