/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M7 14C7 15.1 6.1 16 5 16C3.9 16 3 15.1 3 14C3 12.9 3.9 12 5 12C6.1 12 7 12.9 7 14ZM5 6C3.9 6 3 6.9 3 8C3 9.1 3.9 10 5 10C6.1 10 7 9.1 7 8C7 6.9 6.1 6 5 6ZM5 0C3.9 0 3 0.9 3 2C3 3.1 3.9 4 5 4C6.1 4 7 3.1 7 2C7 0.9 6.1 0 5 0ZM11 4C12.1 4 13 3.1 13 2C13 0.9 12.1 0 11 0C9.9 0 9 0.9 9 2C9 3.1 9.9 4 11 4ZM11 6C9.9 6 9 6.9 9 8C9 9.1 9.9 10 11 10C12.1 10 13 9.1 13 8C13 6.9 12.1 6 11 6ZM11 12C9.9 12 9 12.9 9 14C9 15.1 9.9 16 11 16C12.1 16 13 15.1 13 14C13 12.9 12.1 12 11 12Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M7 14C7 15.1 6.1 16 5 16C3.9 16 3 15.1 3 14C3 12.9 3.9 12 5 12C6.1 12 7 12.9 7 14ZM5 6C3.9 6 3 6.9 3 8C3 9.1 3.9 10 5 10C6.1 10 7 9.1 7 8C7 6.9 6.1 6 5 6ZM5 0C3.9 0 3 0.9 3 2C3 3.1 3.9 4 5 4C6.1 4 7 3.1 7 2C7 0.9 6.1 0 5 0ZM11 4C12.1 4 13 3.1 13 2C13 0.9 12.1 0 11 0C9.9 0 9 0.9 9 2C9 3.1 9.9 4 11 4ZM11 6C9.9 6 9 6.9 9 8C9 9.1 9.9 10 11 10C12.1 10 13 9.1 13 8C13 6.9 12.1 6 11 6ZM11 12C9.9 12 9 12.9 9 14C9 15.1 9.9 16 11 16C12.1 16 13 15.1 13 14C13 12.9 12.1 12 11 12Z\" />\n</svg>\n";
export default src;
