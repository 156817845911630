/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'
import { flex, space, width } from 'styled-system'

const Input = styled.input`
  height: 32px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: normal;
  border: ${p => (p.error ? '2px solid var(--red-400)' : '1px solid #bebebe')};
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 0;
  ${flex}
  ${space}
  ${width}
  html.dark & {
    // Outlier: dark:bg-light-gray-300 dark:border-light-gray-500
    background: #333;
    border: ${p => (p.error ? '2px solid var(--red-500)' : '1 px solid #555')};
  }
`

export default React.forwardRef(
  ({ onChange, onEnter, stopPropagation, ...props }, ref) => (
    <Input
      ref={ref}
      onKeyDown={onEnter ? e => e.keyCode === 13 && onEnter(e) : undefined}
      onChange={
        onChange
          ? e => {
              if (stopPropagation) e.stopPropagation()
              onChange(e.target.value)
            }
          : undefined
      }
      {...props}
    />
  )
)
