/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { map } from 'lodash'

import { OptionalConfig, RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import View from './view'

export default {
  RequiredConfig,
  OptionalConfig,
  defaultValue: undefined,
  Edit,
  meta: {
    initialTemplate: () => ({
      childrenTemplate: [],
      details: {
        defaultRowCount: 2
      }
    }),
    category: 'Special',
    Icon,
    label: 'Table'
  },
  renderChildren: (template, renderChild, isConfig) => {
    if (!isConfig) return template.childrenTemplate
    return (value, handleChange, showFooter, footerCalcFunctions) => {
      return map(template.childrenTemplate, gadget =>
        renderChild({
          id: gadget.id,
          formKey: gadget.formKey,
          type: 'TableColumn',
          details: {
            gadget,
            handleChange,
            value,
            parentFormKey: template.formKey,
            showFooter,
            footerCalcFunctions,
            childrenTemplate: template.childrenTemplate
          }
        })
      )
    }
  },
  unsortable: true,
  subFields: null,
  sampleValue: [],
  progressiveDisclosure: null,
  validateShape: v => v.any(),
  View
}
