/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { useQuery } from '../../components/use-query'

export default function () {
  const { publishUrlPath, appId, documentId } = useParams()
  const { search } = useLocation()
  if (appId) {
    return (
      <Navigate
        to={`/document-list/${appId}/${documentId}/view${search}`}
        replace
      />
    )
  }
  return (
    <PublishUrlPathRedirect
      publishUrlPath={publishUrlPath}
      documentId={documentId}
      search={search}
    />
  )
}

function PublishUrlPathRedirect ({ publishUrlPath, documentId, search }) {
  const resp = useQuery(getQuery(publishUrlPath))
  const appId = get(resp, 'data.app.id')
  if (!appId) return null
  const to = `/document-list/${appId}/${documentId}/view${search}`
  return <Navigate to={to} replace />
}

const getQuery = appPath => ({
  variables: { appPath },
  query: gql`
    query ViewPageRedirectQuery($appPath: String!) {
      app: appRedirect(publishUrlPath: $appPath) {
        id
      }
    }
  `
})
