/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'
import { space, width } from 'styled-system'

import * as Icons from '../icons'
import { Wrapper } from './layout'

const SelectTrigger = styled.select`
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: ${props => (props.large ? 40 : props.small ? 24 : 32)}px;
  padding-left: 8px;
  padding-right: 19px;
  margin: 0;
  position: relative;
  transition: all 0.1s ease-in-out;
  user-select: none;
  border-radius: 4px;
  outline: none;
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  background: #fff;
  border: 1px solid ${props => (props.minimal ? 'transparent' : '#bbb')};
  color: #111;
  &::-moz-focus-inner {
    border: 0;
  }
  [data-whatinput='keyboard'] &:focus {
    border-color: #3369a3;
  }
  &:disabled {
    cursor: default;
    background: ${props => (props.minimal ? '#fff' : '#eee')};
    border-color: ${props => (props.minimal ? 'transparent' : '#ccc')};
    html.dark & {
      // Outlier: dark:disabled:bg-light-gray-500
      background: #555;
    }
  }
  ${space}
  ${width}
`

const SelectFocus = styled.div`
  position: absolute;
  pointer-events: none;
  [data-whatinput='keyboard'] select:focus + & {
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    border: 4px solid #8edfee;
    border-radius: 8px;
    z-index: 1;
  }
`

const SelectArrow = styled(Icons.HtmlSelect)`
  position: absolute;
  pointer-events: none;
  right: 4px;
  top: ${props => props.top}px;
  fill: ${props =>
    props.disabled ? 'var(--medium-gray-100)' : 'var(--dark-gray-300)'};
`

export const Select = React.forwardRef(
  ({ className, style, onChange, mb, small, ...props }, ref) => {
    return (
      <Wrapper className={className} style={style} mb={mb}>
        <Wrapper>
          <SelectTrigger
            onChange={onChange ? e => onChange(e.target.value) : undefined}
            {...props}
            small={small ? 'true' : undefined}
            ref={ref}
            className={cx(
              props.darkerModeDarkerBg
                ? 'dark:border-light-gray-500 dark:bg-light-gray-300'
                : 'dark:border-light-gray-300 dark:bg-light-gray-400',
              'dark:text-black'
            )}
          />
          <SelectFocus />
          <SelectArrow
            top={props.large ? 12 : props.small ? 4 : 8}
            disabled={props.disabled}
          />
        </Wrapper>
      </Wrapper>
    )
  }
)

export const Option = styled.option``

export const Divider = styled.option``
Divider.defaultProps = {
  disabled: true,
  children: '────────────────',
  value: ''
}
