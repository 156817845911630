/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { applyPatches, enablePatches, produceWithPatches } from 'immer'
import React from 'react'

enablePatches()

const DEFAULT_ON_CHANGE = _next => {}

export function useUndoRedoImmer (
  initialValue,
  limit = 100,
  onChange = DEFAULT_ON_CHANGE
) {
  const [[val, changes, i], setValue] = React.useState([initialValue, {}, -1])

  const update = React.useCallback(
    (updater, cacheOrDatabase) => {
      if (cacheOrDatabase === 'database') {
        const [next] = produceWithPatches(val, updater)
        onChange(next)
      } else {
        setValue(([val, changes, i]) => {
          const [next, redo, undo] = produceWithPatches(val, updater)
          i++
          const newChanges = { ...changes }
          newChanges[i] = { redo, undo }
          delete newChanges[i + 1]
          delete newChanges[i - limit]
          if (cacheOrDatabase !== 'cache') {
            onChange(next)
          }
          return [next, newChanges, i]
        })
      }
    },
    [limit, onChange]
  )

  const undo = React.useCallback(() => {
    setValue(([val, changes, i]) => {
      const next = applyPatches(val, changes[i].undo)
      onChange(next)
      return [next, changes, i - 1]
    })
  }, [onChange])

  const redo = React.useCallback(() => {
    setValue(([val, changes, i]) => {
      const next = applyPatches(val, changes[i + 1].redo)
      onChange(next)
      return [next, changes, i + 1]
    })
  }, [onChange])

  return [
    val,
    update,
    i in changes ? undo : null,
    i + 1 in changes ? redo : null
  ]
}
