/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import { BrandingHeader } from '../../../components/branding-header'
import { ReactComponent as Illustration } from './illustration.svg.jsx'

export default function FormPreview ({ branding }) {
  return (
    <div className='flex flex-col overflow-auto border-t-2 border-t-light-gray-300 bg-light-gray-100 pt-10 dark:bg-light-gray-200'>
      <div className='flex'>
        <div className='w-40' />
        <div className='relative flex min-h-[500px] flex-1 flex-col items-center justify-center bg-white'>
          <div className='relative -top-6 w-full p-6'>
            <BrandingHeader branding={branding} />
          </div>
          <div className='mb-4 w-[200px] rounded-lg bg-light-gray-100 p-8 text-center text-base font-normal text-dark-gray-200 dark:bg-light-gray-300'>
            <Trans id='this.is.where.form.appear' />
          </div>
          <Illustration />
        </div>
        <div className='flex w-64 flex-col px-5 pt-44'>
          <h2 className='mb-2 text-lg font-bold text-dark-gray-100'>
            <Trans id='actions' />
          </h2>
          <button
            className='kp-button-brand kp-button-lg mb-2 w-full'
            style={{ '--kp-brand': branding.color }}
          >
            <Trans id='submit' />
          </button>
          <button
            className='kp-button-brand kp-button-lg mb-2 w-full'
            style={{ '--kp-brand': branding.color }}
          >
            <Trans id='save' />
          </button>
          <button className='kp-button-lg kp-button-outline'>
            <Trans id='discard' />
          </button>
        </div>
      </div>
      <div className='flex-1' />
    </div>
  )
}
