/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'

import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'
import SpaceIcon from './space-icon'

export default function SpaceFinder ({
  onSelect,
  spaceId,
  filterIntegrationDisabled
}) {
  const q = getSpacesQuery()
  const { data, loading, error } = useQuery(q.query, q.options)
  const [searchValue, setSearchValue] = React.useState('')
  const [processing, setProcessing] = React.useState(false)
  const spaceData =
    !loading && data
      ? transformSpaceData(data.spaces, spaceId, filterIntegrationDisabled)
      : []
  if (error) {
    Sentry.captureException(error)
  }
  const searchResults = (
    !searchValue || !data?.spaces
      ? spaceData
      : spaceData.filter(sp => {
          return sp.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        })
  ).slice(0, 10)
  const onClick = space => () => {
    setProcessing(true)
    onSelect(space.id)
  }

  return (
    <div className='w-[440px] p-4'>
      <div>
        <label htmlFor='space-search'>
          <Trans id='pages.spaces.finder.search.for' />
        </label>
      </div>
      <div className='flex items-center'>
        <input
          className='kp-input w-72 border border-medium-gray-100'
          id='space-search'
          role='search'
          placeholder={i18n._('pages.spaces.finder.search')}
          onChange={e => setSearchValue(e.target.value)}
          value={searchValue}
        />
        {searchValue.length < 40 && <Icons.Search className='-ml-6' />}
      </div>
      {error && (
        <div className='mt-4 text-red-500'>
          <div>
            <Trans id='pages.spaces.finder.warning' />
          </div>
          <div>{error.message}</div>
        </div>
      )}
      {loading && !error && <Spinner size={32} className='mx-16 my-4' />}
      {!loading && !error && (
        <ul>
          {searchResults.map(space => (
            <li key={space.id} className='flex justify-between pt-4'>
              <div className='flex w-72 shrink-0'>
                <div className='shrink-0'>
                  <SpaceIcon name={space.name} />
                </div>
                <div className='flex flex-col overflow-hidden whitespace-nowrap px-4'>
                  <div className='overflow-hidden text-ellipsis'>
                    {space.name}
                  </div>
                  <div className='overflow-hidden overflow-ellipsis text-xs text-medium-gray-500 [direction:rtl]'>
                    {space.breadcrumb.join(' > ')}
                  </div>
                </div>
              </div>
              <button
                className='kp-button-solid'
                disabled={processing}
                onClick={onClick(space)}
              >
                <Trans id='pages.spaces.finder.move' />
              </button>
            </li>
          ))}
          {searchResults.length === 0 && (
            <div className='pt-4'>
              <Trans id='pages.spaces.finder.none' />
            </div>
          )}
        </ul>
      )}
    </div>
  )
}

const getSpacesQuery = () => ({
  query: gql`
    query Spaces {
      spaces {
        id
        name
        parentId
        allowIntegrations
      }
    }
  `,
  options: { fetchPolicy: 'network-only' }
})

function transformSpaceData (spaces, spaceId, filterIntegrationDisabled) {
  return spaces
    .filter(sp => sp.id !== spaceId)
    .filter(sp => {
      if (!filterIntegrationDisabled) return true
      return sp.allowIntegrations
    })
    .map(sp => ({ ...sp, breadcrumb: determineBreadcrumb(spaces, sp) }))
    .sort((a, b) => {
      if (!a.parentId) return -1
      if (!b.parentId) return 1
      return a.name < b.name ? -1 : 1
    })
}

function determineBreadcrumb (spaces, space) {
  if (!space?.parentId) return []
  const parent = spaces.find(sp => sp.id === space.parentId)
  return [...determineBreadcrumb(spaces, parent), parent?.name]
}
