/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { cloneDeep } from 'lodash'

export const useToggleAnonymousCreateMutation = (appId, query) => {
  const [toggleAnonymousCreate] = useMutation(mutation)
  return () => toggleAnonymousCreate(getParams(appId, query))
}

const mutation = gql`
  mutation ToggleAnonymousCreate($id: String!) {
    toggleAnonymousCreate(args: { applicationId: $id })
  }
`

const getParams = (id, query) => {
  return {
    variables: { id },
    update: (store, { data: { toggleAnonymousCreate } }) => {
      const rawData = store.readQuery({ ...query })
      const data = cloneDeep(rawData)
      data.app.canAnonymousCreate = toggleAnonymousCreate
      store.writeQuery({ ...query, data })
    }
  }
}
