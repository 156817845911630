/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useDuplicateMutation = () => {
  const [duplicate, { loading }] = useMutation(mutation)

  return [
    (documentId, timezone) => duplicate(getParams(documentId, timezone)),
    { loading }
  ]
}

const mutation = gql`
  mutation Duplicate($id: ID!, $timezone: String!) {
    duplicateDocument(id: $id, timezone: $timezone) {
      __typename
      ... on Redirect {
        url
        newActionId
      }

      ... on Error {
        reason
      }
    }
  }
`

const getParams = (documentId, timezone) => ({
  variables: {
    id: documentId,
    timezone
  }
})
