/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get, includes, invert, isObject, pick, some } from 'lodash'
import React from 'react'

import IntegrationTypeaheadEdit from './edit'

export function check (data, config) {
  const formValue = isObject(data) ? data.id : data
  const configId = get(config, 'value.id')
  switch (config.type) {
    case 'isSelected':
      return formValue === configId
    case 'isNotSelected':
      return formValue !== configId
    case 'isEmpty':
      return !formValue
    case 'isNotEmpty':
      return !!formValue
    default:
      return true
  }
}

export function configIsValid (config) {
  if (includes(['isEmpty', 'isNotEmpty'], config.type)) return true
  return config.type && config.value
}

const REQUIRED_INPUTS_TOOLTIP =
  'This option is not available for integrations that have required inputs'

export function component ({ details, onChange, value }) {
  // If we have required inputs for an integration, we won't know what those
  // are in the configuration.
  const hasRequiredInputs = some(details.inputFields, (fieldDetails, field) => {
    return fieldDetails.required && fieldDetails.type !== 'static'
  })
  return (
    <div className='flex items-center gap-2'>
      <select
        className='kp-select flex-1'
        data-testid='pd-operator'
        aria-label='operator'
        onChange={e => onChange({ ...value, type: e.target.value })}
        value={value.type || ''}
      >
        <option value=''>- - -</option>
        <option
          value='isSelected'
          disabled={hasRequiredInputs}
          title={hasRequiredInputs && REQUIRED_INPUTS_TOOLTIP}
        >
          <Trans id='is' />
        </option>
        <option
          value='isNotSelected'
          disabled={hasRequiredInputs}
          title={hasRequiredInputs && REQUIRED_INPUTS_TOOLTIP}
        >
          <Trans id='is.not' />
        </option>
        <option value='isEmpty'>
          <Trans id='is.empty' />
        </option>
        <option value='isNotEmpty'>
          <Trans id='is.not.empty' />
        </option>
      </select>
      {hasValue(value.type) && (
        <IntegrationTypeaheadEdit
          className='flex-1'
          details={details}
          id='select-integration'
          onChange={val =>
            onChange({
              ...value,
              value: val && pick(val, ['id', 'label'])
            })
          }
          value={value.value || ''}
        />
      )}
    </div>
  )
}

const hasValue = type => type && !includes(['isEmpty', 'isNotEmpty'], type)

const uiToWf = {
  isSelected: 'is',
  isNotSelected: 'is not',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  const newExpr = {
    left: {
      dataType: 'option',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
  if (hasValue(expr.data.type) && expr.data.value) {
    newExpr.right = {
      dataType: 'option',
      value: expr.data.value
    }
  }
  return newExpr
}

export function fromWorkflowFormat (expr) {
  const newExpr = {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
  if (hasValue(newExpr.data.type)) {
    newExpr.data.value = expr.right.value
  }
  return newExpr
}
