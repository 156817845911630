/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import FocusTrap from 'focus-trap-react'
import React from 'react'

import * as VoronoiDND from '../../voronoi-dnd'
import Line from '../components/line'
import steps from '../steps'
import * as Assemblers from './assemblers'

const Flowbot = ({
  freezeFirst,
  nodes,
  parentId,
  a11yLabel,
  beginDrag,
  endDrag,
  onClick,
  selectedId,
  justCreatedId,
  faderId,
  fieldsAll,
  a11yDrop,
  a11yDrag,
  setA11yDrag,
  isInvalidDrop,
  removeStep,
  isDragging,
  formSections,
  lineage,
  workflowSettings
}) => {
  if (!nodes.length) {
    return (
      <VoronoiDrop
        id={`${parentId}::inner`}
        a11yLabel={a11yLabel}
        inside
        data='new-flow'
        isInvalidDrop={isInvalidDrop}
        a11yDrag={a11yDrag}
        a11yDrop={a11yDrop}
        className='group'
      >
        <div className='flex h-[60px] w-full items-center justify-center border border-dashed border-light-gray-400 bg-light-gray-100 text-center after:text-[10px] after:font-medium after:text-medium-gray-400 after:opacity-50 after:content-["Drag_Steps_Here"] group-focus:border-[#558dd8] group-focus:bg-[#e9f8fe] group-focus:after:text-[#558dd8] group-focus/voronoi:after:content-["Drop_Here"] dark:bg-light-gray-300 dark:after:text-dark-gray-500 dark:after:opacity-100 dark:group-focus:bg-dark-gray-400' />
      </VoronoiDrop>
    )
  }
  return (
    <div className='flex shrink-0 items-center text-sm'>
      {!parentId && (
        <div className='relative z-[1] flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-full bg-medium-gray-500 text-[9px] uppercase text-white dark:bg-light-gray-300 dark:text-black'>
          <Trans id='start' />
        </div>
      )}
      {freezeFirst ? (
        <Line first invisible={parentId} />
      ) : (
        <VoronoiDrop
          id={`${nodes[0].clientId}::first`}
          a11yLabel={`before ${getNodeName(nodes[0])}`}
          inside
          data='left'
          isInvalidDrop={isInvalidDrop}
          a11yDrag={a11yDrag}
          a11yDrop={a11yDrop}
        >
          <Line
            first
            isDragging={isDragging || a11yDrag}
            invisible={parentId}
          />
        </VoronoiDrop>
      )}
      {nodes.map((node, i) => {
        const freeze = i === 0 && freezeFirst
        const step = renderStep(steps, node, Flowbot, {
          beginDrag,
          endDrag,
          onClick: freeze ? undefined : onClick,
          selectedId,
          justCreatedId,
          faderId,
          fieldsAll,
          a11yDrop,
          a11yDrag,
          setA11yDrag,
          isInvalidDrop,
          removeStep,
          isDragging,
          workflowSettings,
          formSections,
          lineage
        })
        return [
          freeze ? (
            step
          ) : (
            <DraggableStep
              key={node.clientId}
              a11yDragging={a11yDrag && a11yDrag.id === node.clientId}
              dragContext={{ id: node.clientId }}
              beginDrag={() => beginDrag(node)}
              endDrag={endDrag}
            >
              {step}
            </DraggableStep>
          ),
          <VoronoiDrop
            key={`line-${node.clientId}`}
            id={node.clientId}
            a11yLabel={`after ${getNodeName(node)}`}
            inside
            isInvalidDrop={isInvalidDrop}
            a11yDrag={a11yDrag}
            a11yDrop={a11yDrop}
          >
            <Line
              isDragging={isDragging || a11yDrag}
              invisible={parentId && i === nodes.length - 1}
            />
          </VoronoiDrop>
        ]
      })}
      {!parentId && (
        <div className='relative z-[1] flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-full bg-medium-gray-500 text-[9px] uppercase text-white dark:bg-light-gray-300 dark:text-black'>
          <Trans id='end' />
        </div>
      )}
    </div>
  )
}

export default props =>
  props.a11yDrag ? (
    <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
      <div role='application' aria-roledescription='Workflow Builder'>
        <Flowbot key='flowbot' {...props} />
      </div>
    </FocusTrap>
  ) : (
    <div role='application' aria-roledescription='Workflow Builder'>
      <Flowbot key='flowbot' {...props} />
    </div>
  )

const DraggableStep = VoronoiDND.draggable(function (props) {
  const style = {}
  if (props.isDragging || props.a11yDragging) style.opacity = 0.2
  return props.connectDragSource(<div style={style}>{props.children}</div>)
})

const renderStep = (steps, node, Flowbot, fbProps) => {
  const manifest = steps[node.type]
  const Assembler = manifest.Assembler || Assemblers.Basic
  return (
    <Assembler
      key={`step-${node.clientId}`}
      manifest={manifest}
      onClick={
        fbProps.onClick &&
        (e => {
          e.stopPropagation()
          fbProps.onClick(node)
        })
      }
      selected={node.clientId === fbProps.selectedId}
      fade={node.clientId === fbProps.faderId}
      details={node}
      Flowbot={Flowbot}
      fbProps={fbProps}
    />
  )
}

const getNodeName = node => {
  const manifest = steps[node.type]
  return node.stepName || manifest.name
}

function VoronoiDrop ({
  id,
  className,
  a11yLabel,
  isInvalidDrop,
  a11yDrag,
  a11yDrop,
  ...rest
}) {
  return (
    <VoronoiDND.Item
      className={cx('outline-none', className)}
      id={id}
      tabIndex={a11yDrag && !isInvalidDrop(id) ? 0 : null}
      role='button'
      aria-label={`Drop step ${a11yLabel}`}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          e.stopPropagation()
          a11yDrop(id)
        }
      }}
      {...rest}
    />
  )
}
