/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { debounce } from 'lodash'
import React from 'react'
import {
  Link,
  Outlet,
  useHref,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import PopoverButton from '../../../components/data-table/popover-button'
import { ModalPage } from '../../../components/modal-page'
import { StaticOutlet } from '../../../components/static-outlet'
import DropdownEdit from '../../../formbot/gadgets/dropdown/edit'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import Input from '../../../ui/input'
import { Tab, TabInner, Tabs } from '../../../ui/tabs'
import DELETE_BLUEPRINT from '../gql/mutation.delete-category'
import UPDATE_BLUEPRINT from '../gql/mutation.update-category'
import NewGroupPopup from '../groups/components/new-group-popup'

export function BlueprintsDetails () {
  const { blueprints, canManageIdentity } = useOutletContext()
  const { categoryId } = useParams()
  const blueprint = blueprints?.find(blueprint => blueprint.id === categoryId)

  return (
    <ModalPage
      className='text-sm'
      title={
        <Title>
          <StyledSpan1 className='text-medium-gray-500'>
            <Trans id='blueprint.name.colon' message='Blueprint Name:' />
          </StyledSpan1>{' '}
          <StyledSpan2 className='text-dark-gray-500'>
            {blueprint?.name ?? <Trans id='loading' message='Loading' />}
          </StyledSpan2>
        </Title>
      }
      nav={<BlueprintsDetailsNav />}
    >
      <Outlet context={{ blueprints, blueprint, canManageIdentity }} />
    </ModalPage>
  )
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  overflow: hidden;
`

const StyledSpan1 = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const StyledSpan2 = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: 500;
`

function BlueprintsDetailsNav () {
  return (
    <Tabs>
      <Tab to='info'>
        <TabInner>
          <Trans id='general' message='General' />
        </TabInner>
      </Tab>
      <Tab to='roles'>
        <TabInner>
          <Trans id='roles' message='Roles' />
        </TabInner>
      </Tab>
      <Tab to='fields'>
        <TabInner>
          <Trans id='fields' message='Fields' />
        </TabInner>
      </Tab>
      <Tab to='history'>
        <TabInner>
          <Trans id='history' message='History' />
        </TabInner>
      </Tab>
    </Tabs>
  )
}

export function BlueprintDetailsBasicInfo () {
  const { blueprints, blueprint, canManageIdentity } = useOutletContext()
  const alerts = useAlerts()
  const [deleteBlueprint] = useMutation(DELETE_BLUEPRINT)
  const [updateBlueprint] = useMutation(UPDATE_BLUEPRINT)
  const closeAlertsRef = React.useRef()
  const [name, setName] = React.useState(blueprint.name)
  const [parent, setParent] = React.useState(blueprint.parentId)
  const debouncedUpdate = React.useCallback(
    debounce(e => {
      updateBlueprint(e)
        .then(() =>
          alerts.type3(
            i18n._({ id: 'blueprint.saved', message: 'Blueprint Saved' }),
            'success'
          )
        )
        .catch(() =>
          alerts.type3(
            i18n._({
              id: 'error.saving.blueprint',
              message: 'Error saving blueprint'
            }),
            'error'
          )
        )
    }, 1000),
    []
  )
  const navigate = useNavigate()
  const location = useLocation()
  const groupsPath = useHref('../../../groups')

  return (
    <>
      <TransitionGroup>
        <CSSTransition key={location.pathname} timeout={450}>
          <StaticOutlet context={{ blueprints, prefix: '../../../..' }} />
        </CSSTransition>
      </TransitionGroup>
      <StyledDetailsContainer>
        <h3>
          <Trans id='basic.information' message='Basic Information' />
        </h3>
        <Label className='text-dark-gray-300' htmlFor='blueprint-name'>
          <Trans id='blueprint.name' message='Blueprint Name' />
        </Label>
        <Input
          value={name}
          id='blueprint-name'
          configKey='name'
          disabled={!canManageIdentity}
          onChange={async e => {
            setName(e)
            await debouncedUpdate({
              variables: { id: blueprint.id, data: { name: e } }
            })
          }}
        />
        <Label className='text-dark-gray-300'>
          <Trans id='parent.blueprint' message='Parent Blueprint' />
        </Label>
        {canManageIdentity ? (
          <DropdownEdit
            darkerModeDarkerBg
            value={parent}
            details={{
              noEmptyOption: true,
              options: blueprints?.reduce(
                (acc, val) => {
                  if (isDescendant(blueprints, blueprint.id, val.id)) {
                    return acc
                  }
                  return [...acc, { key: val.id, lbl: val.name }]
                },
                [{ key: ':uncategorized:', lbl: '- - -' }]
              )
            }}
            onChange={e => {
              if (!e?.id) return
              setParent(e.id)
              updateBlueprint({
                variables: {
                  id: blueprint.id,
                  data: { parentId: e.id === ':uncategorized:' ? null : e.id }
                }
              })
                .then(() =>
                  alerts.type3(
                    i18n._({
                      id: 'blueprint.saved',
                      message: 'Blueprint Saved'
                    }),
                    'success'
                  )
                )
                .catch(() =>
                  alerts.type3(
                    i18n._({
                      id: 'error.changing.parent',
                      message: 'Error changing parent'
                    }),
                    'error'
                  )
                )
            }}
          />
        ) : (
          <Input value={blueprints.find(b => b.id === parent)?.name} disabled />
        )}
        <Button
          transparent
          icon
          as={Link}
          to='group-list'
          style={{ alignSelf: 'flex-start' }}
          mt={3}
        >
          <Icons.Visible mr={2} className='fill-blue-500' />
          <Trans
            id='view.groups.using.blueprint'
            message='View Groups using this Blueprint'
          />
        </Button>
        {canManageIdentity && (
          <>
            <h3>
              <Trans id='add.group.btn' message='Add Group' />
            </h3>
            <span>
              <Trans
                id='will.create.new.group.based.on.blueprint'
                message='This will create a new group, based on this blueprint.'
              />
            </span>
            <PopoverButton
              icon
              hideArrow
              buttonProps={{ transparent: false, marginTop: '16px' }}
              label={
                <>
                  <Icons.Add fill='var(--white)' mr={2} />
                  <Trans id='add.group.btn' message='Add Group' />
                </>
              }
            >
              {hide => (
                <div style={{ width: '100%' }}>
                  <NewGroupPopup
                    blueprint={blueprint}
                    onCancel={hide}
                    blueprints={blueprints}
                    path={groupsPath}
                  />
                </div>
              )}
            </PopoverButton>
            <h3>
              <Trans id='delete.blueprint' message='Delete Blueprint' />
            </h3>
            <span>
              <Trans
                id='delete.blueprint.no.longer.referenced'
                message='This can only be done when a blueprint is no longer being referenced by any groups.'
              />
            </span>
            <StyledDeleteButton
              mt={3}
              icon
              disabled={blueprint?.groupCount > 0}
              onClick={() => {
                closeAlertsRef.current?.()
                closeAlertsRef.current = alerts.type2(
                  i18n._({
                    id: 'blueprint.will.permanently.deleted',
                    message: 'Blueprint will be permanently deleted.'
                  }),
                  'error',
                  close => (
                    <Button
                      small
                      ml={2}
                      onClick={() => {
                        close()
                        deleteBlueprint({
                          variables: { id: blueprint.id }
                        })
                          .then(() =>
                            alerts.type3(
                              i18n._({
                                id: 'successfully.deleted.blueprint',
                                message: 'Successfully deleted blueprint'
                              }),
                              'success'
                            )
                          )
                          .catch(err => {
                            alerts.type3(
                              i18n._({
                                id: 'could.not.delete.blueprint',
                                message: "Couldn't delete blueprint"
                              }),
                              'error'
                            )
                            Sentry.captureException(err)
                          })
                        navigate('../..')
                      }}
                    >
                      <Trans id='continue' message='Continue' />
                    </Button>
                  )
                )
              }}
            >
              <Icons.Delete fill='#FFFFFF' />
              <Trans id='delete.blueprint' message='Delete Blueprint' />
            </StyledDeleteButton>
          </>
        )}
      </StyledDetailsContainer>
    </>
  )
}

const isDescendant = (blueprints, blueprintId, id) => {
  const ancestors = getAncestors(blueprints, id).map(e => e.id)
  return ancestors.includes(blueprintId)
}

const getAncestors = (blueprints, id, visited = new Set()) => {
  const blueprint = blueprints.find(blueprint => blueprint.id === id)
  if (!blueprint || visited.has(blueprint)) return []
  visited.add(blueprint)
  return [...getAncestors(blueprints, blueprint.parentId, visited), blueprint]
}

const StyledDetailsContainer = styled.div`
  margin: 0 auto 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 344px;

  padding-bottom: 32px;
  h3 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  span {
    color: #666666;
  }
`

const StyledDeleteButton = styled(Button)`
  width: 157px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  &[disabled] {
    background-color: #ccc !important;
    color: white;
    box-shadow: none;
  }
`

const Label = styled.div.attrs({ as: 'label' })`
  padding-top: 16px;
  &:first-of-type {
    padding-top: 0;
  }
`
