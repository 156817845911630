/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'
import { color, height, space, width } from 'styled-system'

const Base = styled.div`
  font-family: 'Roboto', sans-serif;
  ${space}
  ${width}
  ${height}
  ${color}
`
Base.defaultProps = { color: 'var(--black)', m: 0 }
const defaultProps = as => ({ ...Base.defaultProps, as })

export const H1 = styled(Base)`
  font-size: 32px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 48px;
`
H1.defaultProps = defaultProps('h1')

export const H2 = styled(Base)`
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 36px;
`
H2.defaultProps = defaultProps('h2')

export const H3 = styled(Base)`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 28px;
`
H3.defaultProps = defaultProps('h3')

export const H4 = styled(Base)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
`
H4.defaultProps = defaultProps('h4')

export const H5 = styled(Base)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
`
H5.defaultProps = defaultProps('h5')

export const H6 = styled(Base)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
`
H6.defaultProps = defaultProps('h6')

export const Subtitle1 = styled(Base)`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 24px;
`

export const Subtitle2 = styled(Base)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
`

export const Body1 = styled(Base)`
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  &::after {
    ${p => p.required && "content: '\\00a0*';"}
    ${p => p.required && 'color: var(--red-400);'}
  }
`

export const Body2 = styled(Base)`
  display: block;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
`
