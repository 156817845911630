/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M1.01151 11.6587H4.01151C4.09992 11.6587 4.1847 11.6938 4.24721 11.7563C4.30972 11.8188 4.34484 11.9036 4.34484 11.992V14.992C4.32571 15.1876 4.3685 15.3843 4.46719 15.5543C4.56588 15.7243 4.71547 15.859 4.89484 15.9393C4.99593 15.9799 5.10392 16.0005 5.21284 16C5.35275 15.9957 5.49031 15.963 5.61718 15.9038C5.74405 15.8447 5.85757 15.7604 5.95084 15.656L15.6122 5.99468C15.8615 5.74472 16.0016 5.40607 16.0016 5.05301C16.0016 4.69995 15.8615 4.3613 15.6122 4.11134L11.8902 0.39001C11.7668 0.265855 11.62 0.167446 11.4583 0.100496C11.2966 0.0335454 11.1232 -0.000610868 10.9482 9.97571e-06C10.7732 -0.000670427 10.5999 0.0334613 10.4383 0.100417C10.2767 0.167373 10.13 0.265815 10.0068 0.39001L0.344844 10.0507C0.193164 10.1754 0.0844344 10.3446 0.0339652 10.5344C-0.016504 10.7242 -0.00617062 10.9251 0.0635104 11.1087C0.144162 11.288 0.279003 11.4375 0.449058 11.5361C0.619114 11.6348 0.815825 11.6777 1.01151 11.6587Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M1.01151 11.6587H4.01151C4.09992 11.6587 4.1847 11.6938 4.24721 11.7563C4.30972 11.8188 4.34484 11.9036 4.34484 11.992V14.992C4.32571 15.1876 4.3685 15.3843 4.46719 15.5543C4.56588 15.7243 4.71547 15.859 4.89484 15.9393C4.99593 15.9799 5.10392 16.0005 5.21284 16C5.35275 15.9957 5.49031 15.963 5.61718 15.9038C5.74405 15.8447 5.85757 15.7604 5.95084 15.656L15.6122 5.99468C15.8615 5.74472 16.0016 5.40607 16.0016 5.05301C16.0016 4.69995 15.8615 4.3613 15.6122 4.11134L11.8902 0.39001C11.7668 0.265855 11.62 0.167446 11.4583 0.100496C11.2966 0.0335454 11.1232 -0.000610868 10.9482 9.97571e-06C10.7732 -0.000670427 10.5999 0.0334613 10.4383 0.100417C10.2767 0.167373 10.13 0.265815 10.0068 0.39001L0.344844 10.0507C0.193164 10.1754 0.0844344 10.3446 0.0339652 10.5344C-0.016504 10.7242 -0.00617062 10.9251 0.0635104 11.1087C0.144162 11.288 0.279003 11.4375 0.449058 11.5361C0.619114 11.6348 0.815825 11.6777 1.01151 11.6587Z\" />\n</svg>\n";
export default src;
