/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { map } from 'lodash'
import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import AbbreviationIcon from '../../components/abbreviation-icon'
import { AppIcon, AppSquare } from '../../components/app-icon'
import * as routes from '../../components/routes'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { AlertHelp } from '../../icons'
import { Flex, Wrapper } from '../../ui/layout'
import { Subtitle1 } from '../../ui/typography'

export default function UsedBy () {
  const { integration } = useOutletContext()
  const apps = integration?.appsUsing
  return (
    <PageWrapper className='text-sm'>
      <Questions>
        <AppsPane apps={apps} />
      </Questions>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Questions = styled.div`
  padding: 16px 0;
`

function AppsPane ({ apps }) {
  if (!apps.length) {
    return (
      <Pane>
        <NoAppsMessage>
          <Trans id='no.apps.using.integration' />
        </NoAppsMessage>
      </Pane>
    )
  }

  const dateFormatter = new Intl.DateTimeFormat([], { dateStyle: 'medium' })

  return (
    <Pane>
      <Flex justifyContent='space-between' align-items='center'>
        <Label>
          <Trans id='apps.using.integration' />
        </Label>
        <TooltipTrigger
          as={AlertHelp}
          label={i18n._('apps.using.integration')}
          ml={2}
          className='fill-blue-500 dark:fill-medium-gray-300'
          tooltipId='apps-using-this-integration'
        />
        <Tooltip id='apps-using-this-integration' place='bottom'>
          <div style={{ width: 180 }}>
            <Trans id='apps.listed.use.integration' />
          </div>
        </Tooltip>
      </Flex>
      <table data-testid='app-table' className='kp-table'>
        <thead>
          <tr>
            <th>
              <Trans id='name' />
            </th>
            <th>
              <Trans id='creator' />
            </th>
            <th>
              <Trans id='date.created' />
            </th>
          </tr>
        </thead>
        <tbody>
          {map(apps, app => (
            <tr
              key={app.id}
              className='hover:bg-light-gray-100 dark:hover:bg-light-gray-300'
            >
              <td>
                <AppNameWrapper>
                  <AppSquare
                    backgroundColor={app.tileOptions?.backgroundColor}
                    className='w-8'
                  >
                    <AppIcon
                      iconName={app.tileOptions?.iconName}
                      isProduct={app.type === 'product'}
                    />
                  </AppSquare>
                  <GetLink app={app} />
                </AppNameWrapper>
              </td>
              <td>
                <CreatorChip className='bg-light-gray-200 dark:bg-medium-gray-200'>
                  <NameIcon name={app.createdBy?.displayName} />
                  <span>{app.createdBy?.displayName}</span>
                </CreatorChip>
              </td>
              <td>{formatDate(app.createdAt, dateFormatter)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Pane>
  )
}

function formatDate (createdAt, formatter) {
  const date = new Date(+createdAt)
  if (Number.isNaN(date)) return null
  try {
    return formatter.format(date)
  } catch (error) {
    return null
  }
}

const Pane = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 16px auto 150px;
  width: fit-content;
  min-width: 40vw;
`

const AppNameWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`

const CreatorChip = styled.div`
  align-items: center;
  border-radius: 9999px;
  display: inline-flex;
  gap: 8px;
  padding-right: 16px;
`

const NameIcon = styled(AbbreviationIcon)`
  border-radius: 9999px;
  font-size: 9px;
`

const NoAppsMessage = styled(Subtitle1)`
  max-width: 450px;
  text-align: center;
`

function GetLink ({ app: { id, viewer = {}, firstPageId, name } }) {
  if (viewer.canViewDocuments || viewer.canManage) {
    return (
      <Link
        className='text-text-link underline'
        to={routes.documentList(id, firstPageId)}
      >
        {name}
      </Link>
    )
  }
  if (viewer.canSubmitDocuments) {
    return (
      <Link
        className='text-text-link underline'
        to={routes.run(id, firstPageId)}
      >
        {name}
      </Link>
    )
  }
  return <span>{name}</span>
}

const Label = styled(Wrapper).attrs({ as: 'label' })`
  display: block;
  font-weight: 500;
  padding-bottom: 4px;
`
