/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import { useCreatePolicyGroupMutation } from './mutation.create-policy-group'

export default function NewPolicyGroup ({ appId }) {
  const createNewPolicyGroup = useCreatePolicyGroupMutation(appId)
  const [newRoleText, setNewRoleText] = React.useState('')
  return (
    <>
      <label className='block'>
        <Trans id='pagesbuilder.permissions.policy' />
      </label>
      <input
        type='text'
        className='kp-input w-72'
        placeholder={i18n._('pagesbuilder.permissions.rolename')}
        onChange={e => setNewRoleText(e.target.value)}
        value={newRoleText}
      />
      <button
        className='kp-button-solid ml-2'
        disabled={newRoleText.length === 0}
        onClick={() => {
          createNewPolicyGroup(newRoleText)
          setNewRoleText('')
        }}
      >
        <Trans id='pagesbuilder.permissions.create' />
      </button>
    </>
  )
}
