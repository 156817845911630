/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { map } from 'lodash'

export function validateShape (v, gadget) {
  return v.oneOfType([
    v.arrayOf(v.oneOf(map(gadget.details.options, 'key'))),
    v.oneOf(map(gadget.details.options, 'key')),
    v.shape({ id: v.string(), label: v.string() }),
    v.arrayOf(v.shape({ id: v.string(), label: v.string() }))
  ])
}
