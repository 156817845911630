/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useAddAppConditionalPermissionMutation = (appId, datasetId) => {
  const [addAppConditionalPermission, other] = useMutation(mutation)

  return [
    conditionalPermissions =>
      addAppConditionalPermission(
        getParams(appId, datasetId, conditionalPermissions)
      ),
    other
  ]
}

const mutation = gql`
  mutation addAppConditionalPermission(
    $args: AddAppConditionalPermissionInput!
  ) {
    addAppConditionalPermission(args: $args) {
      id
      status
      dataset {
        id
        conditionalPermissions {
          id
          description
          formKey
          identity {
            type
            roles {
              roleId
              categoryId
              label
            }
          }
          access {
            type
            states {
              draft
              inprogress
              complete
            }
          }
        }
      }
    }
  }
`

const getParams = (appId, datasetId, conditionalPermission) => ({
  variables: {
    args: {
      appId,
      datasetId,
      conditionalPermission
    }
  },
  refetchQueries: ['FormPageQuery']
})
