/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { minMaxCharacters, regex } from '../../validations'
import { RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import View from './view'

export default {
  getAssembler: ({ Group }) => Group,
  RequiredConfig,
  defaultValue: {
    logic: 'all',
    children: [],
    data: []
  },
  Edit,
  meta: {
    category: 'Special',
    Icon,
    label: 'Rules'
  },
  validationOptions: [minMaxCharacters, regex],
  validateShape: v => v.any(),
  View
}
