/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87903 15.1539 3.84563 13.6541 2.34587C12.1544 0.846118 10.121 0.00247015 8 0V0ZM12.618 5.46667L8.05467 11.6593C8.0008 11.7308 7.93322 11.7908 7.85589 11.8359C7.77856 11.881 7.69303 11.9102 7.60429 11.9219C7.51554 11.9336 7.42536 11.9274 7.339 11.9039C7.25265 11.8803 7.17186 11.8398 7.10134 11.7847L3.84267 9.17933C3.7743 9.12462 3.71737 9.05697 3.67514 8.98025C3.63291 8.90353 3.6062 8.81924 3.59654 8.73221C3.57704 8.55642 3.62816 8.38009 3.73867 8.242C3.84918 8.10391 4.01001 8.01538 4.1858 7.99587C4.36158 7.97637 4.53791 8.02749 4.676 8.138L7.39334 10.312L11.5447 4.678C11.5946 4.603 11.6593 4.53892 11.7348 4.48962C11.8102 4.44032 11.8948 4.40683 11.9836 4.39117C12.0724 4.37551 12.1634 4.37801 12.2511 4.39851C12.3389 4.41902 12.4216 4.4571 12.4942 4.51047C12.5668 4.56383 12.6279 4.63136 12.6737 4.70899C12.7194 4.78661 12.749 4.87271 12.7606 4.96209C12.7722 5.05146 12.7655 5.14226 12.741 5.22898C12.7165 5.31571 12.6746 5.39656 12.618 5.46667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87903 15.1539 3.84563 13.6541 2.34587C12.1544 0.846118 10.121 0.00247015 8 0V0ZM12.618 5.46667L8.05467 11.6593C8.0008 11.7308 7.93322 11.7908 7.85589 11.8359C7.77856 11.881 7.69303 11.9102 7.60429 11.9219C7.51554 11.9336 7.42536 11.9274 7.339 11.9039C7.25265 11.8803 7.17186 11.8398 7.10134 11.7847L3.84267 9.17933C3.7743 9.12462 3.71737 9.05697 3.67514 8.98025C3.63291 8.90353 3.6062 8.81924 3.59654 8.73221C3.57704 8.55642 3.62816 8.38009 3.73867 8.242C3.84918 8.10391 4.01001 8.01538 4.1858 7.99587C4.36158 7.97637 4.53791 8.02749 4.676 8.138L7.39334 10.312L11.5447 4.678C11.5946 4.603 11.6593 4.53892 11.7348 4.48962C11.8102 4.44032 11.8948 4.40683 11.9836 4.39117C12.0724 4.37551 12.1634 4.37801 12.2511 4.39851C12.3389 4.41902 12.4216 4.4571 12.4942 4.51047C12.5668 4.56383 12.6279 4.63136 12.6737 4.70899C12.7194 4.78661 12.749 4.87271 12.7606 4.96209C12.7722 5.05146 12.7655 5.14226 12.741 5.22898C12.7165 5.31571 12.6746 5.39656 12.618 5.46667Z\" />\n</svg>\n";
export default src;
