/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.3333 7C15.4217 7 15.5065 6.96488 15.569 6.90237C15.6315 6.83986 15.6667 6.75507 15.6667 6.66667C15.6645 4.89921 14.9615 3.20474 13.7117 1.95496C12.4619 0.705176 10.7674 0.00211714 8.99999 0C8.91158 0 8.8268 0.035119 8.76429 0.0976311C8.70178 0.160143 8.66666 0.244928 8.66666 0.333333V6.35C8.66683 6.5224 8.73535 6.68769 8.85719 6.80966C8.97903 6.93163 9.14426 7.00031 9.31666 7.00067L15.3333 7Z`}/><path d={`M15.6666 7.99997H9.33331C9.23506 7.98743 9.13541 8.00756 9.04973 8.05725C8.96405 8.10694 8.8971 8.18344 8.85919 8.27494C8.82128 8.36645 8.81453 8.46789 8.83997 8.56361C8.86542 8.65933 8.92164 8.74403 8.99998 8.80464L13.4786 13.2833C13.5412 13.3458 13.6259 13.3809 13.7143 13.3809C13.8027 13.3809 13.8875 13.3458 13.95 13.2833C14.602 12.6347 15.1188 11.8633 15.4707 11.0136C15.8226 10.164 16.0025 9.25295 16 8.33331C16 8.2449 15.9649 8.16012 15.9023 8.0976C15.8398 8.03509 15.7551 7.99997 15.6666 7.99997Z`}/><path d={`M7.0587 16C7.9695 15.9981 8.87085 15.8153 9.7105 15.4624C10.5501 15.1095 11.3114 14.5934 11.95 13.944C12.0125 13.8815 12.0476 13.7967 12.0476 13.7083C12.0476 13.62 12.0125 13.5352 11.95 13.4727L7.47137 8.99401C7.39263 8.89946 7.3445 8.78321 7.33337 8.66068V2.32734C7.33337 2.23894 7.29825 2.15415 7.23574 2.09164C7.17322 2.02913 7.08844 1.99401 7.00003 1.99401C5.14272 2.00179 3.36457 2.74707 2.05676 4.06588C0.748941 5.3847 0.018588 7.16903 0.0263677 9.02634C0.0341474 10.8837 0.779422 12.6618 2.09824 13.9696C3.41706 15.2774 5.20139 16.0078 7.0587 16Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.3333 7C15.4217 7 15.5065 6.96488 15.569 6.90237C15.6315 6.83986 15.6667 6.75507 15.6667 6.66667C15.6645 4.89921 14.9615 3.20474 13.7117 1.95496C12.4619 0.705176 10.7674 0.00211714 8.99999 0C8.91158 0 8.8268 0.035119 8.76429 0.0976311C8.70178 0.160143 8.66666 0.244928 8.66666 0.333333V6.35C8.66683 6.5224 8.73535 6.68769 8.85719 6.80966C8.97903 6.93163 9.14426 7.00031 9.31666 7.00067L15.3333 7Z\" />\n<path d=\"M15.6666 7.99997H9.33331C9.23506 7.98743 9.13541 8.00756 9.04973 8.05725C8.96405 8.10694 8.8971 8.18344 8.85919 8.27494C8.82128 8.36645 8.81453 8.46789 8.83997 8.56361C8.86542 8.65933 8.92164 8.74403 8.99998 8.80464L13.4786 13.2833C13.5412 13.3458 13.6259 13.3809 13.7143 13.3809C13.8027 13.3809 13.8875 13.3458 13.95 13.2833C14.602 12.6347 15.1188 11.8633 15.4707 11.0136C15.8226 10.164 16.0025 9.25295 16 8.33331C16 8.2449 15.9649 8.16012 15.9023 8.0976C15.8398 8.03509 15.7551 7.99997 15.6666 7.99997Z\" />\n<path d=\"M7.0587 16C7.9695 15.9981 8.87085 15.8153 9.7105 15.4624C10.5501 15.1095 11.3114 14.5934 11.95 13.944C12.0125 13.8815 12.0476 13.7967 12.0476 13.7083C12.0476 13.62 12.0125 13.5352 11.95 13.4727L7.47137 8.99401C7.39263 8.89946 7.3445 8.78321 7.33337 8.66068V2.32734C7.33337 2.23894 7.29825 2.15415 7.23574 2.09164C7.17322 2.02913 7.08844 1.99401 7.00003 1.99401C5.14272 2.00179 3.36457 2.74707 2.05676 4.06588C0.748941 5.3847 0.018588 7.16903 0.0263677 9.02634C0.0341474 10.8837 0.779422 12.6618 2.09824 13.9696C3.41706 15.2774 5.20139 16.0078 7.0587 16Z\" />\n</svg>\n";
export default src;
