/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import sanitize from '../../../components/sanitize'

let Quill
/* istanbul ignore else */
if (typeof document !== 'undefined') {
  Quill = require('react-quill')
}

export default function RichTextView ({ value = '', details }) {
  if (details?.isExternal) {
    return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
  }
  value = (value ?? '')
    .replace(/\n/g, '') // remove newline
    .replace(/[\t ]+(<(li|p))/g, '$1') // remove space before p and li tags
    .replace(/((li|p)>)[\t ]+/g, '$1') // remove white space after p and li tags
  return (
    <MyQuill
      theme='snow'
      modules={{ toolbar: false, clipboard: { matchVisual: false } }}
      value={sanitize(value)}
      readOnly
    />
  )
}

const MyQuill = styled(Quill)`
  white-space: pre-wrap;
`
