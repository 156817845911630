/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { cloneDeep, reject } from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'

import PopoverButton from '../../../components/data-table/popover-button'
import * as Icons from '../../../icons'
import * as Illustration from '../../../illustrations'
import { useAlerts } from '../../../ui/alerts'
import { useQueryContext } from './use-query-context'

export default ({
  gqlParams,
  myFilters,
  setFilter,
  reset,
  activeView,
  setActiveView
}) => {
  const buttonProps = activeView
    ? { transparent: true, activeFilter: true, small: true }
    : { transparent: true, small: true }
  return (
    <PopoverButton
      label={viewLabel}
      aria-labelledby='Views'
      aria-describedby='Views-list'
      buttonProps={buttonProps}
    >
      {close => (
        <div className='flex h-[400px] flex-col justify-between min-[501px]:w-72'>
          <div className='border-b border-light-gray-500 py-1 dark:hover:bg-light-gray-100'>
            <button
              onClick={() => {
                reset()
                close()
              }}
              className='break flex min-h-[32px] flex-1 cursor-pointer items-center text-left text-sm [word-break:break-word]'
            >
              <div
                className={cx(
                  'relative -right-2 w-8',
                  activeView === '' ? 'visible' : 'invisible'
                )}
              >
                <Icons.Check className='mr-2 fill-blue-500' />
              </div>
              <Trans
                id='pagesbuilder.doclist.views.default'
                message='Default View'
              />
            </button>
          </div>
          <div className='max-h-[335px] w-full flex-1 overflow-y-auto overflow-x-hidden'>
            <SavedViews
              myFilters={myFilters}
              setFilter={setFilter}
              close={close}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          </div>
          <SaveButton
            params={gqlParams}
            setActiveView={setActiveView}
            close={close}
          />
        </div>
      )}
    </PopoverButton>
  )
}

function SaveButton ({ close, params, setActiveView }) {
  const { appId, pageId, tableId } = useParams()
  const datasetId = tableId || pageId || appId
  const alerts = useAlerts()
  const [name, setName] = React.useState('')
  const saveFilter = useSaveFilterMutation(datasetId, name, params)
  const save = async e => {
    e.preventDefault()
    const result = await saveFilter()
    setActiveView(result.data.saveFilter.id)
    setName('')
    close()
    alerts.type3(
      i18n._({
        id: 'pagesbuilder.doclist.view.filter.saved',
        message: 'Filter Saved'
      }),
      'success'
    )
  }
  return (
    <form
      className='flex items-center gap-2 border-t border-light-gray-500 p-4'
      onSubmit={save}
    >
      <div className='sr-only'>Save your view</div>
      <input
        type='text'
        className='kp-input flex-1'
        placeholder={i18n._({
          id: 'pagesbuilder.doclist.view.name',
          message: 'Name current view'
        })}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <button className='kp-button-outline' type='submit'>
        <Trans id='save' message='Save' />
      </button>
    </form>
  )
}

const SavedSearch = ({ myFilter, setFilter, close, active, setActiveView }) => {
  const removeFilter = useRemoveFilterMutation(myFilter.id)
  return (
    <div className='flex items-center pb-1 pt-1 dark:hover:bg-light-gray-100'>
      <div
        className={cx(
          'relative -right-3 w-8',
          active === myFilter.id ? 'visible' : 'invisible'
        )}
      >
        <Icons.Check className='fill-blue-500' />
      </div>
      <button
        onClick={() => {
          setFilter(myFilter.connection, myFilter.id)
          close()
        }}
        className='break flex min-h-[32px] flex-1 cursor-pointer items-center text-left text-sm [word-break:break-word]'
      >
        {myFilter.name}
      </button>
      <div className='relative right-3'>
        <button
          className='kp-button-transparent kp-button-icon text-medium-gray-500'
          onClick={e => {
            e.stopPropagation()
            if (active === myFilter.id) setActiveView(false)
            removeFilter()
          }}
        >
          <div className='sr-only'>{`Remove View: ${myFilter.name}`}</div>
          <Icons.Delete />
        </button>
      </div>
    </div>
  )
}

function SavedViews ({
  myFilters,
  setFilter,
  close,
  activeView,
  setActiveView
}) {
  return (
    <>
      {myFilters.length ? (
        <div className='relative'>
          <div className='sr-only' id='Views-list'>
            <Trans
              id='pagesbuilder.doclist.views.saved.number'
              message='You have {length} saved Views.'
              values={{ length: myFilters.length }}
            />
          </div>
          {myFilters.map(myFilter => (
            <SavedSearch
              key={myFilter.node.id}
              myFilter={myFilter.node}
              setFilter={setFilter}
              close={close}
              active={activeView}
              setActiveView={setActiveView}
            />
          ))}
        </div>
      ) : (
        <div className='flex items-center justify-center'>
          <div className='block p-4 text-sm'>
            <div className='flex'>
              <div className='relative'>
                <div className='text-sm font-medium' id='Views'>
                  <Trans
                    id='pagesbuilder.doclist.views.what'
                    message='WHAT ARE VIEWS?'
                  />
                </div>
                <div id='Views-list' className='sr-only'>
                  <Trans
                    id='pagesbuilder.doclist.views.no.saved'
                    message='You have no saved Views.'
                  />
                </div>
                <div className='relative pb-4'>
                  <Trans
                    id='pagesbuilder.doclist.views.save.time'
                    message='Views save you time. Your column choices, sorting and searches can be saved for later as a custom view.'
                  />
                </div>
              </div>
              <Illustration.Peter aria-hidden className='ml-4' />
            </div>
            <div className='text-sm font-medium'>
              <Trans
                id='pagesbuilder.doclist.views.how'
                message='HOW TO USE THEM?'
              />
            </div>
            <ol className='list-decimal pl-4'>
              <li>
                <Trans
                  id='pagesbuilder.doclist.views.choose'
                  message='Choose columns, sort and search.'
                />
              </li>
              <li>
                <Trans
                  id='pagesbuilder.doclist.views.save.name'
                  message='Save the view with a name below.'
                />
              </li>
              <li>
                <Trans
                  id='pagesbuilder.doclist.views.appear'
                  message='Your new view will appear here for you to use later.'
                />
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  )
}

const viewLabel = (
  <>
    <Icons.Camera className='fill-blue-500' mr={2} />
    <Trans id='pagesbuilder.doclist.views' message='Views' />
  </>
)

const useRemoveFilterMutation = id => {
  const [removeFilter] = useMutation(deleteMutation)
  const query = useQueryContext()
  return () => removeFilter(getDeleteParams(id, query))
}

const deleteMutation = gql`
  mutation DeleteFilter($id: ID!) {
    deleteFilter(args: { id: $id })
  }
`

const getDeleteParams = (id, query) => ({
  variables: { id },
  optimisticResponse: {
    deleteFilter: 'Ok'
  },
  refetchQueries: [query],
  update: (store, { data: { deleteFilter } }) => {
    if (deleteFilter !== 'Ok') return
    const rawData = store.readQuery({ ...query })
    const data = cloneDeep(rawData)
    data.app.dataset.myFilters.edges = reject(
      data.app.dataset.myFilters.edges,
      e => e.node.id === id
    )
    store.writeQuery({ ...query, data })
  }
})

const useSaveFilterMutation = (appId, name, params) => {
  const [saveFilter] = useMutation(saveMutation)
  const query = useQueryContext()
  return () => saveFilter(getSaveParams(appId, name, query, params))
}

const saveMutation = gql`
  mutation SaveFilter(
    $appId: ID!
    $name: String!
    $sort: [String!]
    $fields: Operator
    $query: String
    $columns: [String!]!
  ) {
    saveFilter(
      args: {
        appId: $appId
        name: $name
        connection: {
          query: $query
          sort: $sort
          fields: $fields
          columns: $columns
        }
      }
    ) {
      id
      name
      connection {
        sort
        fields
        query
        columns
      }
    }
  }
`

const getSaveParams = (
  appId,
  name,
  listPageQuery,
  { query, sort, fields, columns }
) => ({
  variables: {
    appId,
    name:
      name ||
      `${i18n._({ id: 'pagesbuilder.doclist.untitled.view', message: 'Untitled View' })}`,
    query,
    sort,
    fields,
    columns
  },
  refetchQueries: [listPageQuery]
})
