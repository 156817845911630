/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import * as Icons from '../icons'

export const UL = styled.ul`
  ${space}
`
UL.defaultProps = { m: 0, p: 0 }

export const LI = styled.li`
  ${space}
`
LI.defaultProps = { py: 1 }

export const EmptyLI = styled.li`
  display: flex;
  list-style-type: none;
  ${space}
`
EmptyLI.defaultProps = { py: 1 }

export const CheckedLI = React.forwardRef(({ children, ...rest }, ref) => (
  <EmptyLI ref={ref} {...rest}>
    <Icons.CheckCircle fill='#bebebe' mr={1} />
    <span>{children}</span>
  </EmptyLI>
))
