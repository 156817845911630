/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import isEditable from './is-editable'

const fns = []

let currentMouseDown = null

// This is to fix a bug/feature of Chrome (starting with version 73) where if
// you hold your mouse down on one element and let go on a different one, the
// e.target is the element you let go of rather than what the rest of the
// browsers do, which is either ignore the event or trigger a click on the
// element you started the click on.
// Relevant issue: https://bugs.chromium.org/p/chromium/issues/detail?id=944263
window.addEventListener('mousedown', e => {
  currentMouseDown = e.target
})

window.addEventListener('click', e => {
  const mouseDownTarget = currentMouseDown
  currentMouseDown = null
  if (e.button !== 0) return
  if (mouseDownTarget !== e.target && isEditable(mouseDownTarget)) return
  fns.forEach(fn => {
    fn()
  })
})

export default function subscribe (fn) {
  fns.push(fn)
  return () => {
    const i = fns.findIndex(_fn => _fn === fn)
    fns.splice(i, 1)
  }
}

export const useOutsideClick = (ref, handler, when = true) => {
  React.useLayoutEffect(() => {
    if (when) {
      const datepicker = document.getElementById('datepicker-portal')
      const handle = e => {
        const mouseDownTarget = currentMouseDown
        if (e.button !== 0) return
        if (mouseDownTarget !== e.target && isEditable(mouseDownTarget)) return
        if (!ref?.current || ref.current.contains(e.target)) return
        if (datepicker?.contains?.(e.target)) return
        handler(e)
        e.preventDefault()
        e.stopPropagation()
      }
      document.addEventListener('click', handle, true)
      document.addEventListener('ontouchstart', handle, true)
      return () => {
        document.removeEventListener('click', handle, true)
        document.removeEventListener('ontouchstart', handle, true)
      }
    }
  }, [ref, handler, when])
}
