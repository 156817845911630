/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { find, get } from 'lodash'
import React from 'react'

export default function RulesView ({ value, fbRenderView }) {
  if (!value) return ''
  return (
    <div>
      {value.logic && value.children.length > 1 && (
        <p className='p-2 font-medium'>
          <Trans
            id='complete.logic.of.following'
            values={{ logic: value.logic }}
          />
        </p>
      )}
      {value.children.map((child, index) => {
        const Component = child.type === 'ruleGroup' ? RuleGroup : Rule
        return (
          <Component
            key={index}
            item={child}
            data={
              child.type === 'ruleGroup'
                ? value.data
                : find(value.data, { id: child.id })
            }
            fbRenderView={fbRenderView}
          />
        )
      })}
    </div>
  )
}

const RuleGroup = ({ item, data, fbRenderView }) => {
  return (
    <div className='m-4 border border-light-gray-300'>
      <p className='flex h-12 w-full items-center justify-start bg-light-gray-200 px-4'>
        {item.label}
      </p>
      {item.logic && item.children.length > 1 && (
        <p className='p-2 font-medium'>
          <Trans
            id='complete.logic.of.following'
            values={{ logic: item.logic }}
          />
        </p>
      )}
      <div className='children'>
        {item.children.map((child, index) => (
          <RulesView
            key={index}
            value={{ children: [child], data }}
            fbRenderView={fbRenderView}
          />
        ))}
      </div>
    </div>
  )
}

const Rule = ({ item, data }) => {
  const values = item.value?.label.split(/(%{.*?}%)/g).map(part => {
    if (part.startsWith('%{') && part.endsWith('}%')) {
      return getPart(part, data?.data)
    } else {
      return part
    }
  })

  const groupedValues = values.reduce(
    (acc, value) => {
      if (['string', 'number'].includes(typeof value)) {
        acc[acc.length - 1] += ` ${value.toString().trim()}`
      } else if (value?.label) {
        acc[acc.length - 1] += ` ${value.label.trim()}`
      } else if (value !== undefined) {
        acc.push(value, '')
      }
      return acc
    },
    ['']
  )

  return (
    <div className='flex flex-col gap-1 px-4 py-2'>
      {groupedValues.map((rulePart, i) =>
        Array.isArray(rulePart) ? (
          <RuleList key={i} multiSelectData={rulePart} />
        ) : (
          <span key={i}>{rulePart}</span>
        )
      )}
    </div>
  )
}

function getPart (part, data) {
  const sliced = part.slice(2, -2)
  const parts = sliced.split('$$')
  const dataValue = get(data, parts[0])
  return dataValue
}

const RuleList = ({ multiSelectData }) => {
  return (
    <div className='ml-6 flex flex-col gap-1'>
      {multiSelectData.map(item => (
        <div key={item.id}>
          {item.label}
          {item.data?.kuali_credits?.credits && (
            <span> ({item.data.kuali_credits.credits})</span>
          )}
        </div>
      ))}
    </div>
  )
}
