/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export const Checkbox = React.forwardRef(
  ({ className, rounded, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type='checkbox'
        className={cx(
          className,
          rounded ? 'h-5 w-5 rounded-full' : 'mt-0.5',
          'kp-checkbox mr-2'
        )}
        {...props}
      />
    )
  }
)

export default React.forwardRef(
  ({ label, onChange, disabled, ...rest }, ref) => (
    <label className='mt-2 flex cursor-pointer items-start disabled:cursor-default disabled:text-medium-gray-100'>
      <Checkbox
        ref={ref}
        onChange={
          onChange && !disabled ? e => onChange(e.target.checked, e) : undefined
        }
        disabled={disabled}
        {...rest}
      />

      {label && (
        <span
          className={cx(
            '[word-break:break-word]',
            disabled && 'text-medium-gray-100'
          )}
        >
          {label}
        </span>
      )}
    </label>
  )
)
