/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.9253 3.88801C15.9031 3.87855 15.8784 3.87597 15.8547 3.88061C15.8309 3.88524 15.8091 3.89687 15.792 3.91401L9.294 10.4107C8.95021 10.7543 8.48405 10.9473 7.998 10.9473C7.51195 10.9473 7.04579 10.7543 6.702 10.4107L0.206668 3.91401C0.189673 3.89672 0.167836 3.88497 0.144035 3.88033C0.120233 3.87569 0.0955836 3.87837 0.0733346 3.88801C0.0514725 3.89743 0.0328631 3.91307 0.0198236 3.93299C0.00678413 3.9529 -0.000109405 3.97621 1.31304e-06 4.00001V12C1.31304e-06 12.3536 0.140477 12.6928 0.390526 12.9428C0.640574 13.1929 0.979713 13.3333 1.33333 13.3333H14.6667C15.0203 13.3333 15.3594 13.1929 15.6095 12.9428C15.8595 12.6928 16 12.3536 16 12V4.00001C16.0001 3.97604 15.993 3.95258 15.9797 3.93264C15.9664 3.91269 15.9475 3.89715 15.9253 3.88801Z`}/><path d={`M7.41007 9.70399C7.56653 9.85999 7.77846 9.94759 7.9994 9.94759C8.22034 9.94759 8.43227 9.85999 8.58873 9.70399L15.0461 3.24666C15.0898 3.20294 15.1205 3.14793 15.1347 3.08777C15.149 3.02761 15.1462 2.96467 15.1267 2.90599C15.0534 2.68666 14.8494 2.66666 14.6667 2.66666H1.3334C1.15007 2.66666 0.944733 2.68666 0.872733 2.90599C0.853264 2.96467 0.850498 3.02761 0.864743 3.08777C0.878989 3.14793 0.909685 3.20294 0.9534 3.24666L7.41007 9.70399Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.9253 3.88801C15.9031 3.87855 15.8784 3.87597 15.8547 3.88061C15.8309 3.88524 15.8091 3.89687 15.792 3.91401L9.294 10.4107C8.95021 10.7543 8.48405 10.9473 7.998 10.9473C7.51195 10.9473 7.04579 10.7543 6.702 10.4107L0.206668 3.91401C0.189673 3.89672 0.167836 3.88497 0.144035 3.88033C0.120233 3.87569 0.0955836 3.87837 0.0733346 3.88801C0.0514725 3.89743 0.0328631 3.91307 0.0198236 3.93299C0.00678413 3.9529 -0.000109405 3.97621 1.31304e-06 4.00001V12C1.31304e-06 12.3536 0.140477 12.6928 0.390526 12.9428C0.640574 13.1929 0.979713 13.3333 1.33333 13.3333H14.6667C15.0203 13.3333 15.3594 13.1929 15.6095 12.9428C15.8595 12.6928 16 12.3536 16 12V4.00001C16.0001 3.97604 15.993 3.95258 15.9797 3.93264C15.9664 3.91269 15.9475 3.89715 15.9253 3.88801Z\" />\n<path d=\"M7.41007 9.70399C7.56653 9.85999 7.77846 9.94759 7.9994 9.94759C8.22034 9.94759 8.43227 9.85999 8.58873 9.70399L15.0461 3.24666C15.0898 3.20294 15.1205 3.14793 15.1347 3.08777C15.149 3.02761 15.1462 2.96467 15.1267 2.90599C15.0534 2.68666 14.8494 2.66666 14.6667 2.66666H1.3334C1.15007 2.66666 0.944733 2.68666 0.872733 2.90599C0.853264 2.96467 0.850498 3.02761 0.864743 3.08777C0.878989 3.14793 0.909685 3.20294 0.9534 3.24666L7.41007 9.70399Z\" />\n</svg>\n";
export default src;
