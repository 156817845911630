/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8.00002 0C6.58602 0.00158807 5.23039 0.564001 4.23054 1.56385C3.23069 2.5637 2.66828 3.91933 2.66669 5.33333C2.66669 7.67333 6.00002 13.35 7.43202 15.6827C7.4917 15.7797 7.57525 15.8598 7.67468 15.9153C7.77411 15.9709 7.88612 16 8.00002 16C8.11409 16.0005 8.22637 15.9715 8.3259 15.9158C8.42544 15.8601 8.50885 15.7795 8.56802 15.682C10 13.3507 13.3334 7.676 13.3334 5.33333C13.3318 3.91933 12.7694 2.5637 11.7695 1.56385C10.7697 0.564001 9.41402 0.00158807 8.00002 0V0ZM8.00002 7.66667C7.53853 7.66667 7.0874 7.52982 6.70369 7.27343C6.31997 7.01704 6.02091 6.65262 5.8443 6.22626C5.6677 5.7999 5.62149 5.33075 5.71152 4.87812C5.80155 4.4255 6.02378 4.00974 6.3501 3.68342C6.67643 3.3571 7.09219 3.13487 7.54481 3.04483C7.99743 2.9548 8.46659 3.00101 8.89295 3.17761C9.31931 3.35422 9.68373 3.65329 9.94012 4.037C10.1965 4.42072 10.3334 4.87184 10.3334 5.33333C10.3334 5.95217 10.0875 6.54566 9.64994 6.98325C9.21235 7.42083 8.61886 7.66667 8.00002 7.66667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8.00002 0C6.58602 0.00158807 5.23039 0.564001 4.23054 1.56385C3.23069 2.5637 2.66828 3.91933 2.66669 5.33333C2.66669 7.67333 6.00002 13.35 7.43202 15.6827C7.4917 15.7797 7.57525 15.8598 7.67468 15.9153C7.77411 15.9709 7.88612 16 8.00002 16C8.11409 16.0005 8.22637 15.9715 8.3259 15.9158C8.42544 15.8601 8.50885 15.7795 8.56802 15.682C10 13.3507 13.3334 7.676 13.3334 5.33333C13.3318 3.91933 12.7694 2.5637 11.7695 1.56385C10.7697 0.564001 9.41402 0.00158807 8.00002 0V0ZM8.00002 7.66667C7.53853 7.66667 7.0874 7.52982 6.70369 7.27343C6.31997 7.01704 6.02091 6.65262 5.8443 6.22626C5.6677 5.7999 5.62149 5.33075 5.71152 4.87812C5.80155 4.4255 6.02378 4.00974 6.3501 3.68342C6.67643 3.3571 7.09219 3.13487 7.54481 3.04483C7.99743 2.9548 8.46659 3.00101 8.89295 3.17761C9.31931 3.35422 9.68373 3.65329 9.94012 4.037C10.1965 4.42072 10.3334 4.87184 10.3334 5.33333C10.3334 5.95217 10.0875 6.54566 9.64994 6.98325C9.21235 7.42083 8.61886 7.66667 8.00002 7.66667Z\" />\n</svg>\n";
export default src;
