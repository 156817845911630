/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Spinner from '../components/spinner'
import { NoPermissions } from '../illustrations'
import { Blurb, InnerWrapper, Wrapper } from '../ui/errors'

export default function Forbidden ({
  fillHeight,
  redirecting,
  message = `${i18n._('pages.forbidden.sorry')}`
}) {
  return (
    <Wrapper height={fillHeight ? '100%' : '100vh'}>
      <InnerWrapper height='320px'>
        <LowNoPermissions />
        <Blurb>
          <Blurb.Heading>
            {redirecting ? (
              <Trans id='pages.forbidden.redirected' />
            ) : (
              <Trans id='pages.forbidden.no.access' />
            )}{' '}
            {redirecting && <Spinner inline size={20} />}
          </Blurb.Heading>

          {redirecting ? (
            <Blurb.Message>
              <Trans id='pages.forbidden.please.wait' />
            </Blurb.Message>
          ) : (
            <Blurb.Message>{message}</Blurb.Message>
          )}
        </Blurb>
      </InnerWrapper>
    </Wrapper>
  )
}

const LowNoPermissions = styled(NoPermissions)`
  align-self: flex-end;
  @media screen and (max-width: 450px) {
    align-self: center;
  }
`
