/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { subFields } from '../../integration-utils'
import { OptionalConfig, RequiredConfig } from './config.new'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import View from './view'

export default {
  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,
  RequiredConfig,
  OptionalConfig,
  defaultValue: null,
  Edit,
  sortSuffix: '.label',
  meta: { label: 'Data Lookup (Document)', hidden: true, Icon },
  subFields,
  subFieldsIgnoreValidation: true,
  sampleValue: null,
  progressiveDisclosure,
  validateShape: v => v.any(),
  View
}
