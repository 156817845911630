/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`32`} height={`32`} viewBox={`0 0 32 32`} fill={`none`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M28 4H4L4 28H28L16 16L28 4Z`} stroke={`#666666`} strokeWidth={`3`} strokeLinejoin={`round`}/></svg>
);

let src = "<svg width=\"32\" height=\"32\" viewBox=\"0 0 32 32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28 4H4L4 28H28L16 16L28 4Z\" stroke=\"#666666\" stroke-width=\"3\" stroke-linejoin=\"round\"/>\n</svg>\n";
export default src;
