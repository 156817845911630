/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isFunction } from 'lodash'
import React from 'react'

import Spinner from '../components/spinner'
import { OutOfDate } from '../illustrations'
import { useUpgradeDocumentMutation } from '../pages-runner/action/components/mutation.upgrade-document'
import { useAlerts } from '../ui/alerts'
import Button from '../ui/button'
import Popup from '../ui/pop-up'

export default function FormVersionBar ({ document, onRedirect }) {
  const formId = get(document, 'form.id')
  const formVersionId = get(document, 'dataset.formVersion.id')
  const isUpToDate = formId === formVersionId

  const docId = get(document, 'id')
  if (!isUpToDate) {
    return <FormOutOfDate docId={docId} onRedirect={onRedirect} />
  }
  return null
}

function FormOutOfDate ({ docId, onRedirect }) {
  const alerts = useAlerts()
  const [updating, setUpdating] = React.useState(false)

  const upgrade = useUpgradeDocumentMutation()
  const upgradeDocument = async () => {
    setUpdating(true)
    const {
      data: { upgradeDocument }
    } = await upgrade(docId)

    switch (upgradeDocument.__typename) {
      case 'Redirect': {
        if (isFunction(onRedirect)) {
          onRedirect(upgradeDocument)
        } else {
          const host = window.location.origin
          window.location.assign(`${host}${upgradeDocument.url}`)
        }
        break
      }
      case 'InvalidDocumentError': {
        alerts.type2(
          i18n._('document.requested.no.longer.exists.to.update'),
          'error'
        )
        break
      }
      case 'PermissionError': {
        alerts.type2(
          i18n._(
            'application.for.document.requested.not.accepting.submissions'
          ),
          'error'
        )
        break
      }
      default: {
        break
      }
    }
    setUpdating(false)
  }

  return (
    <Popup
      showModal
      title={i18n._('form.out.of.date...')}
      subtitle={i18n._('form.updated.since.last.save.update.get.new')}
      Img={OutOfDate}
      width='520'
    >
      <Button
        className='w-28'
        mt={3}
        outline
        onClick={upgradeDocument}
        disabled={updating}
        aria-label={updating ? 'Update in progress' : 'Update now'}
      >
        {updating ? <Spinner size={16} /> : <Trans id='update.now' />}
      </Button>
    </Popup>
  )
}
