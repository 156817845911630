/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM4.024 11.3853L4.768 9.524C4.78518 9.48223 4.81032 9.44419 4.842 9.412L8.77 5.484C8.83365 5.4237 8.91799 5.39009 9.00567 5.39009C9.09335 5.39009 9.17769 5.4237 9.24134 5.484L10.3587 6.6C10.4208 6.66284 10.4558 6.74761 10.456 6.836C10.456 6.92423 10.421 7.00886 10.3587 7.07133L6.43 11C6.39828 11.0323 6.36012 11.0575 6.318 11.074L4.45734 11.8187C4.39647 11.8416 4.33035 11.8469 4.26663 11.8338C4.20291 11.8207 4.14421 11.7898 4.09734 11.7447C4.05132 11.6985 4.01985 11.6399 4.00681 11.576C3.99378 11.5121 3.99975 11.4458 4.024 11.3853ZM11.8467 5.58533L11.288 6.14333C11.2255 6.20582 11.1407 6.24093 11.0523 6.24093C10.964 6.24093 10.8792 6.20582 10.8167 6.14333L9.7 5.02533C9.63751 4.96282 9.60241 4.87805 9.60241 4.78967C9.60241 4.70128 9.63751 4.61651 9.7 4.554L10.2587 3.99533C10.3628 3.89089 10.4866 3.80807 10.6228 3.75167C10.7591 3.69526 10.9052 3.66637 11.0527 3.66667C11.2747 3.66692 11.4917 3.73297 11.6762 3.85646C11.8607 3.97995 12.0045 4.15535 12.0894 4.36051C12.1742 4.56567 12.1964 4.79138 12.1531 5.00914C12.1098 5.2269 12.0029 5.42694 11.846 5.584L11.8467 5.58533Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM4.024 11.3853L4.768 9.524C4.78518 9.48223 4.81032 9.44419 4.842 9.412L8.77 5.484C8.83365 5.4237 8.91799 5.39009 9.00567 5.39009C9.09335 5.39009 9.17769 5.4237 9.24134 5.484L10.3587 6.6C10.4208 6.66284 10.4558 6.74761 10.456 6.836C10.456 6.92423 10.421 7.00886 10.3587 7.07133L6.43 11C6.39828 11.0323 6.36012 11.0575 6.318 11.074L4.45734 11.8187C4.39647 11.8416 4.33035 11.8469 4.26663 11.8338C4.20291 11.8207 4.14421 11.7898 4.09734 11.7447C4.05132 11.6985 4.01985 11.6399 4.00681 11.576C3.99378 11.5121 3.99975 11.4458 4.024 11.3853ZM11.8467 5.58533L11.288 6.14333C11.2255 6.20582 11.1407 6.24093 11.0523 6.24093C10.964 6.24093 10.8792 6.20582 10.8167 6.14333L9.7 5.02533C9.63751 4.96282 9.60241 4.87805 9.60241 4.78967C9.60241 4.70128 9.63751 4.61651 9.7 4.554L10.2587 3.99533C10.3628 3.89089 10.4866 3.80807 10.6228 3.75167C10.7591 3.69526 10.9052 3.66637 11.0527 3.66667C11.2747 3.66692 11.4917 3.73297 11.6762 3.85646C11.8607 3.97995 12.0045 4.15535 12.0894 4.36051C12.1742 4.56567 12.1964 4.79138 12.1531 5.00914C12.1098 5.2269 12.0029 5.42694 11.846 5.584L11.8467 5.58533Z\" />\n</svg>\n";
export default src;
