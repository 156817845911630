/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import {
  filter,
  find,
  forEach,
  get,
  includes,
  isEmpty,
  last,
  set
} from 'lodash'
import React from 'react'

import { useLocalStorageState } from '../../components/use-local-storage-state'
import { useSimulateActionMutation } from './components/mutation.simulate-workflow'

export const useSimulationEngine = (
  workflowId,
  formId,
  applicationId,
  currentUser
) => {
  const [simulatorPreferences] = useLocalStorageState('simulatorPreferences', {
    showBranchIntro: true
  })
  const [simulationState, setSimulationState] = React.useState({
    states: [],
    meta: {}
  })
  const [selected, setSelected] = React.useState()
  const simulateActionMutation = useSimulateActionMutation()

  const simulateAction = async (
    action,
    simulationState = {
      states: [],
      meta: {
        applicationId,
        document: {
          data: {},
          meta: {
            createdBy: { ...currentUser, label: currentUser.displayName },
            submittedBy: { ...currentUser, label: currentUser.displayName },
            serialNumber: '0000',
            versionNumber: 1
          }
        },
        formId,
        simulatorPreferences,
        workflowId
      }
    },
    additionalData = {}
  ) => {
    simulationState.processing = true
    const { meta, states } = simulationState
    const currentState = determineCurrentState(states)
    const status = get(currentState, 'status')
    let docData = buildDocumentProjection(states)
    let integrationData = buildDocumentProjection(states, 'integrationData')
    let newMeta = {
      ...meta,
      document: {
        ...simulationState.meta.document,
        data: docData,
        integration: integrationData
      }
    }
    let newStates
    let simulation
    const { completedBy } = additionalData
    if (completedBy) {
      const completingAssignee = find(currentState.assignees, {
        id: completedBy.id
      })
      set(completingAssignee, 'response', action)
    }
    if (action === 'start over') {
      newStates = []
    } else if (action === 'back') {
      newStates = determinePreviousState(states)
    } else if (action === 'next' && includes(['Withdrawn'], status)) {
      newStates = states.concat({ type: 'stopped' })
    } else if (action === 'vote') {
      const { index, vote } = additionalData
      currentState.assignees[index].response = vote
      newStates = states
    } else if (action === 'voteAll') {
      const { vote } = additionalData
      forEach(currentState.assignees, a => {
        a.response = vote
      })
      newStates = states
    } else {
      const result = await simulateActionMutation({
        variables: {
          id: workflowId,
          action,
          data: {
            ...simulationState,
            meta: newMeta
          }
        }
      })
      newMeta = get(result, 'data.simulateAction.meta')
      newStates = get(result, 'data.simulateAction.states')
      simulation = get(result, 'data.simulateAction.simulation')
    }
    docData = buildDocumentProjection(newStates)
    integrationData = buildDocumentProjection(states, 'integrationData')
    setSimulationState({
      processing: false,
      meta: {
        ...newMeta,
        document: {
          ...newMeta.document,
          data: docData,
          integration: integrationData
        }
      },
      simulation,
      states: newStates
    })
    setSelected(determineSelected(newStates, workflowId))
  }
  return [
    {
      simulationState,
      selected
    },
    simulateAction
  ]
}

const buildDocumentProjection = (
  states = [],
  fieldName = 'documentUpdates'
) => {
  let projection = {}
  states.forEach(s => {
    if (s[fieldName]) {
      projection = { ...projection, ...s[fieldName] }
    }
    if (s.subflows) {
      s.subflows.forEach(sf => {
        const sfProjection = buildDocumentProjection(sf.states, fieldName)
        projection = { ...projection, ...sfProjection }
      })
    }
    if (s.denialFlow) {
      const denialProjection = buildDocumentProjection(
        s.denialFlow.states,
        fieldName
      )
      projection = { ...projection, ...denialProjection }
    }
  })
  return projection
}

const determineCurrentState = states => {
  const latestState = last(states)
  if (!latestState) return
  if (latestState.denialFlow) {
    return determineCurrentState(latestState.denialFlow.states)
  }
  if (latestState.subflows) {
    const activeSubflow = find(latestState.subflows, { active: true })
    if (activeSubflow) {
      return determineCurrentState(activeSubflow.states)
    }
  }
  return latestState
}

const determinePreviousState = states => {
  const updatedStates = JSON.parse(JSON.stringify(states))
  const latest = updatedStates.pop()
  const { denialFlow, subflows } = latest
  if (denialFlow) {
    denialFlow.states = determinePreviousState(denialFlow.states)
    if (isEmpty(denialFlow.states)) {
      if (latest.shadowCopy) {
        return resetLatestState(updatedStates)
      }
      latest.status = 'In Progress'
      delete latest.denialFlow
      updatedStates.push(latest)
      return updatedStates
    }
    updatedStates.push(latest)
    return updatedStates
  }
  if (isEmpty(subflows)) {
    return resetLatestState(updatedStates)
  }
  const activeSubflow = find(subflows, { active: true }) || last(subflows)
  if (isEmpty(activeSubflow.states)) {
    return resetLatestState(updatedStates)
  }
  activeSubflow.active = true
  activeSubflow.states = determinePreviousState(activeSubflow.states)
  if (isEmpty(activeSubflow.states)) {
    delete activeSubflow.active
    delete activeSubflow.states
    const otherSubflows = filter(
      subflows,
      sf => sf.flowId !== activeSubflow.flowId && !isEmpty(sf.states)
    )
    if (isEmpty(otherSubflows)) {
      return resetLatestState(updatedStates)
    }
    const lastSubflow = last(otherSubflows)
    lastSubflow.active = true
  }
  updatedStates.push(latest)
  return updatedStates
}

const resetLatestState = states => {
  if (!isEmpty(states)) {
    const latestState = states.pop()
    if (
      includes(
        ['Cancelled', 'Submitted', 'Approved', 'Denied', 'Sent Back'],
        latestState.status
      )
    ) {
      latestState.status = latestState.denialFlow ? 'In Denial' : 'In Progress'
      latestState.completedAt = undefined
    }
    states.push(latestState)
  }
  return states
}

const determineSelected = (states, flowId) => {
  const latest = last(states)
  if (!latest) {
    return
  }
  const { denialFlow, subflows } = latest
  if (denialFlow) {
    return determineSelected(denialFlow.states, denialFlow.flowId)
  }
  if (isEmpty(subflows)) {
    if (latest.status === 'complete') {
      return { flowId }
    }
    return latest
  }
  const activeSubflow = find(subflows, { active: true })
  if (!activeSubflow) {
    return latest
  }
  const selected = determineSelected(activeSubflow.states, activeSubflow.flowId)
  if (latest.type === 'echo' && selected.flowId === activeSubflow.flowId) {
    return latest
  }
  return selected
}

export const forTesting = {
  determineCurrentState,
  determinePreviousState,
  determineSelected
}
