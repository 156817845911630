/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import _ from 'lodash'
import React from 'react'
import shortid from 'shortid'

import Checkbox from './checkbox'
import Tooltip from './tooltip'

export default function PortList ({
  value,
  onChange,
  label,
  ports,
  filter,
  required
}) {
  const filtered = _.filter(ports, (_, key) => !filter || filter.includes(key))
  React.useEffect(() => {
    const newValue = [
      ..._.filter(value, 'label'),
      { key: shortid(), label: '', type: filtered[0].name }
    ]
    if (newValue.length !== value.length) onChange(newValue)
  })
  return (
    <>
      {value.map(input => (
        <InputRow
          key={input.key}
          id={input.key}
          label={label}
          ports={filtered}
          required={required}
          value={input}
          onChange={toUpdate => {
            onChange(
              value.map(val => (val.key === toUpdate.key ? toUpdate : val))
            )
          }}
        />
      ))}
    </>
  )
}

function InputRow ({ id, label, ports, required, value, onChange }) {
  return (
    <div className='flex items-center justify-between gap-2 border-b border-dark-gray-300 py-1'>
      <input
        type='text'
        name='label'
        className='TextInput_input__1QHwS'
        style={{ minWidth: 0, width: 'initial', flex: 5 }}
        value={value.label}
        onChange={e => onChange({ ...value, label: e.target.value })}
        placeholder={label}
        onMouseDown={e => e.stopPropagation()}
      />
      <select
        name='type'
        className='Select_selectedWrapper__SUs4D'
        style={{ width: 'initial', minWidth: 0, flex: 3 }}
        value={value.type}
        onChange={e => onChange({ ...value, type: e.target.value })}
        onMouseDown={e => e.stopPropagation()}
      >
        {ports.map(port => (
          <option key={port.name} value={port.name}>
            {port.label}
          </option>
        ))}
      </select>
      {required && (
        <>
          <Tooltip value='Required?'>
            <Checkbox
              checked={value.required}
              onClick={() => onChange({ ...value, required: !value.required })}
              onMouseDown={e => e.stopPropagation()}
            />
          </Tooltip>
        </>
      )}
    </div>
  )
}
