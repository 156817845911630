/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const getActualName = additionalKey => user => {
  if (!user) return ''
  const { label, displayName, email, username } = user
  const name = label || displayName || ''
  if (additionalKey === 'email' && email) return `${name} (${email})`
  if (additionalKey === 'username' && username) return `${name} (${username})`
  return name
}
