/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.9293 0.368C15.8739 0.257404 15.7888 0.164409 15.6836 0.0994179C15.5783 0.0344271 15.457 4.15112e-06 15.3333 0L0.666667 0C0.542859 0 0.421497 0.0344764 0.31618 0.0995661C0.210862 0.164656 0.125751 0.257787 0.0703822 0.368524C0.0150136 0.479261 -0.00842454 0.603229 0.00269411 0.726537C0.0138128 0.849845 0.0590489 0.96762 0.133334 1.06667L6 8.94867V14.6667C6.00262 15.0195 6.14393 15.3571 6.39342 15.6066C6.6429 15.8561 6.98052 15.9974 7.33333 16C7.62188 16.0003 7.9027 15.9067 8.13333 15.7333L9.46667 14.7333C9.63199 14.6089 9.7662 14.4478 9.85875 14.2627C9.95129 14.0776 9.99964 13.8736 10 13.6667V8.94867L15.8667 1.06667C15.941 0.967538 15.9863 0.849654 15.9973 0.726242C16.0084 0.60283 15.9848 0.478775 15.9293 0.368ZM3.61133 1.46667L6.23733 4.96667C6.28061 5.02436 6.304 5.09454 6.304 5.16667V6C6.304 6.06996 6.28199 6.13815 6.24108 6.1949C6.20017 6.25166 6.14245 6.2941 6.07608 6.31623C6.00971 6.33835 5.93806 6.33903 5.87128 6.31817C5.8045 6.29731 5.74598 6.25597 5.704 6.2L2.41333 1.86667C2.37619 1.81714 2.35357 1.75826 2.34801 1.6966C2.34245 1.63495 2.35417 1.57296 2.38186 1.5176C2.40954 1.46223 2.4521 1.41566 2.50476 1.38312C2.55742 1.35057 2.6181 1.33333 2.68 1.33333H3.34667C3.39808 1.33364 3.44872 1.34584 3.49463 1.36897C3.54055 1.3921 3.58049 1.42554 3.61133 1.46667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.9293 0.368C15.8739 0.257404 15.7888 0.164409 15.6836 0.0994179C15.5783 0.0344271 15.457 4.15112e-06 15.3333 0L0.666667 0C0.542859 0 0.421497 0.0344764 0.31618 0.0995661C0.210862 0.164656 0.125751 0.257787 0.0703822 0.368524C0.0150136 0.479261 -0.00842454 0.603229 0.00269411 0.726537C0.0138128 0.849845 0.0590489 0.96762 0.133334 1.06667L6 8.94867V14.6667C6.00262 15.0195 6.14393 15.3571 6.39342 15.6066C6.6429 15.8561 6.98052 15.9974 7.33333 16C7.62188 16.0003 7.9027 15.9067 8.13333 15.7333L9.46667 14.7333C9.63199 14.6089 9.7662 14.4478 9.85875 14.2627C9.95129 14.0776 9.99964 13.8736 10 13.6667V8.94867L15.8667 1.06667C15.941 0.967538 15.9863 0.849654 15.9973 0.726242C16.0084 0.60283 15.9848 0.478775 15.9293 0.368ZM3.61133 1.46667L6.23733 4.96667C6.28061 5.02436 6.304 5.09454 6.304 5.16667V6C6.304 6.06996 6.28199 6.13815 6.24108 6.1949C6.20017 6.25166 6.14245 6.2941 6.07608 6.31623C6.00971 6.33835 5.93806 6.33903 5.87128 6.31817C5.8045 6.29731 5.74598 6.25597 5.704 6.2L2.41333 1.86667C2.37619 1.81714 2.35357 1.75826 2.34801 1.6966C2.34245 1.63495 2.35417 1.57296 2.38186 1.5176C2.40954 1.46223 2.4521 1.41566 2.50476 1.38312C2.55742 1.35057 2.6181 1.33333 2.68 1.33333H3.34667C3.39808 1.33364 3.44872 1.34584 3.49463 1.36897C3.54055 1.3921 3.58049 1.42554 3.61133 1.46667Z\" />\n</svg>\n";
export default src;
