/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6266 6.366C15.5331 6.2513 15.4151 6.15892 15.2813 6.0956C15.1475 6.03229 15.0013 5.99962 14.8533 6H6.94662C6.86967 6.00017 6.79502 5.97371 6.73535 5.9251C6.67569 5.87649 6.63468 5.80874 6.61929 5.73333L6.43396 4.80333C6.3885 4.57673 6.26597 4.37287 6.08718 4.22642C5.9084 4.07996 5.6844 3.99996 5.45329 4H1.14662C0.998263 3.99987 0.851733 4.03275 0.717655 4.09627C0.583578 4.15978 0.465312 4.25233 0.371431 4.36721C0.27755 4.48209 0.210406 4.61641 0.174867 4.76045C0.139328 4.90449 0.136284 5.05464 0.165956 5.2L1.89929 13.8667C1.94542 14.0927 2.06825 14.2957 2.24697 14.4415C2.42569 14.5873 2.64931 14.6669 2.87996 14.6667H13.5206C13.7512 14.6667 13.9746 14.5871 14.1532 14.4413C14.3318 14.2955 14.4545 14.0925 14.5006 13.8667L15.834 7.2C15.8636 7.05432 15.8603 6.90386 15.8244 6.75959C15.7886 6.61532 15.721 6.48086 15.6266 6.366Z`}/><path d={`M2.14665 3.33334C2.2983 3.33409 2.44568 3.28312 2.56446 3.18882C2.68324 3.09452 2.76631 2.96255 2.79998 2.81468C2.80612 2.77526 2.82551 2.73911 2.85497 2.7122C2.88442 2.68529 2.92217 2.66924 2.96198 2.66668H13.1466C13.2351 2.66668 13.3198 2.7018 13.3823 2.76431C13.4449 2.82682 13.48 2.9116 13.48 3.00001V4.66668C13.48 4.84349 13.5502 5.01306 13.6752 5.13808C13.8003 5.26311 13.9698 5.33334 14.1466 5.33334C14.3235 5.33334 14.493 5.26311 14.6181 5.13808C14.7431 5.01306 14.8133 4.84349 14.8133 4.66668V2.66668C14.8133 2.31305 14.6728 1.97392 14.4228 1.72387C14.1727 1.47382 13.8336 1.33334 13.48 1.33334H2.81331C2.45969 1.33334 2.12055 1.47382 1.8705 1.72387C1.62046 1.97392 1.47998 2.31305 1.47998 2.66668C1.47998 2.75422 1.49722 2.84092 1.53073 2.9218C1.56423 3.00268 1.61334 3.07618 1.67524 3.13808C1.73715 3.19999 1.81064 3.24909 1.89152 3.2826C1.97241 3.3161 2.0591 3.33334 2.14665 3.33334Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6266 6.366C15.5331 6.2513 15.4151 6.15892 15.2813 6.0956C15.1475 6.03229 15.0013 5.99962 14.8533 6H6.94662C6.86967 6.00017 6.79502 5.97371 6.73535 5.9251C6.67569 5.87649 6.63468 5.80874 6.61929 5.73333L6.43396 4.80333C6.3885 4.57673 6.26597 4.37287 6.08718 4.22642C5.9084 4.07996 5.6844 3.99996 5.45329 4H1.14662C0.998263 3.99987 0.851733 4.03275 0.717655 4.09627C0.583578 4.15978 0.465312 4.25233 0.371431 4.36721C0.27755 4.48209 0.210406 4.61641 0.174867 4.76045C0.139328 4.90449 0.136284 5.05464 0.165956 5.2L1.89929 13.8667C1.94542 14.0927 2.06825 14.2957 2.24697 14.4415C2.42569 14.5873 2.64931 14.6669 2.87996 14.6667H13.5206C13.7512 14.6667 13.9746 14.5871 14.1532 14.4413C14.3318 14.2955 14.4545 14.0925 14.5006 13.8667L15.834 7.2C15.8636 7.05432 15.8603 6.90386 15.8244 6.75959C15.7886 6.61532 15.721 6.48086 15.6266 6.366Z\" />\n<path d=\"M2.14665 3.33334C2.2983 3.33409 2.44568 3.28312 2.56446 3.18882C2.68324 3.09452 2.76631 2.96255 2.79998 2.81468C2.80612 2.77526 2.82551 2.73911 2.85497 2.7122C2.88442 2.68529 2.92217 2.66924 2.96198 2.66668H13.1466C13.2351 2.66668 13.3198 2.7018 13.3823 2.76431C13.4449 2.82682 13.48 2.9116 13.48 3.00001V4.66668C13.48 4.84349 13.5502 5.01306 13.6752 5.13808C13.8003 5.26311 13.9698 5.33334 14.1466 5.33334C14.3235 5.33334 14.493 5.26311 14.6181 5.13808C14.7431 5.01306 14.8133 4.84349 14.8133 4.66668V2.66668C14.8133 2.31305 14.6728 1.97392 14.4228 1.72387C14.1727 1.47382 13.8336 1.33334 13.48 1.33334H2.81331C2.45969 1.33334 2.12055 1.47382 1.8705 1.72387C1.62046 1.97392 1.47998 2.31305 1.47998 2.66668C1.47998 2.75422 1.49722 2.84092 1.53073 2.9218C1.56423 3.00268 1.61334 3.07618 1.67524 3.13808C1.73715 3.19999 1.81064 3.24909 1.89152 3.2826C1.97241 3.3161 2.0591 3.33334 2.14665 3.33334Z\" />\n</svg>\n";
export default src;
