/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { OptionalConfig, RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import { validateShape } from './validations'
import View from './view'

export default {
  View,
  Edit,
  OptionalConfig,
  RequiredConfig,

  validateShape,

  defaultValue: null,

  sampleValue: {
    retrievalId: '7daa9459-81df-4358-9031-150afe89f44d',
    filename: 'foo.txt',
    contentType: 'text/plain',
    filesize: 500
  },

  meta: {
    label: 'File Upload',
    Icon,
    category: 'Basic'
  }
}
