/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { RequiredConfig } from './config'
import { ReactComponent as Icon } from './icon.svg.jsx'

export default {
  RequiredConfig,
  Edit: () => <div />,
  meta: {
    category: 'Smart',
    Icon,
    label: 'Data Lookup (Multiselect)'
  },
  validateShape: v => v.never('This gadget is missing a data source'),
  View: () => <div />
}
