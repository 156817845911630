/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'

export default function EmailPreview ({ branding }) {
  return (
    <Wrapper className='border-t-2 border-t-light-gray-300 bg-light-gray-100 dark:bg-light-gray-200'>
      <Inner>
        <Logo
          src={branding.emailLogo || branding.logo}
          alt={branding.alt}
          $height={branding.height}
          $width={branding.width}
        />
        <div>
          <Trans id='hello.alice' />
        </div>
        <Space />
        <div>
          <Trans id='your.attention.required' />
        </div>
        <Space />
        <div>
          <Trans id='form.name.colon' />
          <span>
            <Trans id='telecommuting.agreement' />
          </span>
        </div>
        <div>
          <Trans id='step.name.colon' />
          <span>
            <Trans id='manager.approval' />
          </span>
        </div>
        <div>
          <Trans id='your.action.colon' />
          <span>
            <Trans id='approval.caps' />
          </span>
        </div>
        <Space />
        <Button large brandColor={branding.color}>
          <Trans id='begin.review' />
        </Button>
        <Space />
        <Space />
        <Footer>
          <Trans id='email.automatically.generated' />
        </Footer>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`

const Inner = styled.div`
  background: white;
  margin-top: 48px;
  padding: 32px;
  min-height: 500px;
  width: 600px;

  span {
    font-weight: bold;
  }
`

const Logo = styled.img`
  display: block;
  margin-bottom: 48px;
  max-width: 100%;
  height: ${p => p.$height}px;
  width: ${p => p.$width}px;
`

const Space = styled.div`
  height: 16px;
`

const Footer = styled.div`
  color: #555;
`
