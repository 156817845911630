/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M2.5 0H13.5C14.163 0 14.7989 0.263392 15.2678 0.732233C15.7366 1.20107 16 1.83696 16 2.5V13.5C15.9979 14.1624 15.7338 14.7971 15.2654 15.2654C14.7971 15.7338 14.1624 15.9979 13.5 16H2.5C1.83696 16 1.20107 15.7366 0.732233 15.2678C0.263392 14.7989 0 14.163 0 13.5V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0ZM3 11.75H13C13.1989 11.75 13.3897 11.671 13.5303 11.5303C13.671 11.3897 13.75 11.1989 13.75 11C13.75 10.8011 13.671 10.6103 13.5303 10.4697C13.3897 10.329 13.1989 10.25 13 10.25H12C11.9337 10.25 11.8701 10.2237 11.8232 10.1768C11.7763 10.1299 11.75 10.0663 11.75 10V4.5C11.75 4.30109 11.671 4.11032 11.5303 3.96967C11.3897 3.82902 11.1989 3.75 11 3.75C10.8011 3.75 10.6103 3.82902 10.4697 3.96967C10.329 4.11032 10.25 4.30109 10.25 4.5V10C10.25 10.0663 10.2237 10.1299 10.1768 10.1768C10.1299 10.2237 10.0663 10.25 10 10.25H9C8.9337 10.25 8.87011 10.2237 8.82322 10.1768C8.77634 10.1299 8.75 10.0663 8.75 10V6.125C8.75 5.92609 8.67098 5.73532 8.53033 5.59467C8.38968 5.45402 8.19891 5.375 8 5.375C7.80109 5.375 7.61032 5.45402 7.46967 5.59467C7.32902 5.73532 7.25 5.92609 7.25 6.125V10C7.25 10.0663 7.22366 10.1299 7.17678 10.1768C7.12989 10.2237 7.0663 10.25 7 10.25H6C5.9337 10.25 5.87011 10.2237 5.82322 10.1768C5.77634 10.1299 5.75 10.0663 5.75 10V7.75C5.75 7.55109 5.67098 7.36032 5.53033 7.21967C5.38968 7.07902 5.19891 7 5 7C4.80109 7 4.61032 7.07902 4.46967 7.21967C4.32902 7.36032 4.25 7.55109 4.25 7.75V10C4.25 10.0663 4.22366 10.1299 4.17678 10.1768C4.12989 10.2237 4.0663 10.25 4 10.25H3C2.80109 10.25 2.61032 10.329 2.46967 10.4697C2.32902 10.6103 2.25 10.8011 2.25 11C2.25 11.1989 2.32902 11.3897 2.46967 11.5303C2.61032 11.671 2.80109 11.75 3 11.75Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M2.5 0H13.5C14.163 0 14.7989 0.263392 15.2678 0.732233C15.7366 1.20107 16 1.83696 16 2.5V13.5C15.9979 14.1624 15.7338 14.7971 15.2654 15.2654C14.7971 15.7338 14.1624 15.9979 13.5 16H2.5C1.83696 16 1.20107 15.7366 0.732233 15.2678C0.263392 14.7989 0 14.163 0 13.5V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0ZM3 11.75H13C13.1989 11.75 13.3897 11.671 13.5303 11.5303C13.671 11.3897 13.75 11.1989 13.75 11C13.75 10.8011 13.671 10.6103 13.5303 10.4697C13.3897 10.329 13.1989 10.25 13 10.25H12C11.9337 10.25 11.8701 10.2237 11.8232 10.1768C11.7763 10.1299 11.75 10.0663 11.75 10V4.5C11.75 4.30109 11.671 4.11032 11.5303 3.96967C11.3897 3.82902 11.1989 3.75 11 3.75C10.8011 3.75 10.6103 3.82902 10.4697 3.96967C10.329 4.11032 10.25 4.30109 10.25 4.5V10C10.25 10.0663 10.2237 10.1299 10.1768 10.1768C10.1299 10.2237 10.0663 10.25 10 10.25H9C8.9337 10.25 8.87011 10.2237 8.82322 10.1768C8.77634 10.1299 8.75 10.0663 8.75 10V6.125C8.75 5.92609 8.67098 5.73532 8.53033 5.59467C8.38968 5.45402 8.19891 5.375 8 5.375C7.80109 5.375 7.61032 5.45402 7.46967 5.59467C7.32902 5.73532 7.25 5.92609 7.25 6.125V10C7.25 10.0663 7.22366 10.1299 7.17678 10.1768C7.12989 10.2237 7.0663 10.25 7 10.25H6C5.9337 10.25 5.87011 10.2237 5.82322 10.1768C5.77634 10.1299 5.75 10.0663 5.75 10V7.75C5.75 7.55109 5.67098 7.36032 5.53033 7.21967C5.38968 7.07902 5.19891 7 5 7C4.80109 7 4.61032 7.07902 4.46967 7.21967C4.32902 7.36032 4.25 7.55109 4.25 7.75V10C4.25 10.0663 4.22366 10.1299 4.17678 10.1768C4.12989 10.2237 4.0663 10.25 4 10.25H3C2.80109 10.25 2.61032 10.329 2.46967 10.4697C2.32902 10.6103 2.25 10.8011 2.25 11C2.25 11.1989 2.32902 11.3897 2.46967 11.5303C2.61032 11.671 2.80109 11.75 3 11.75Z\"/>\n</svg>\n";
export default src;
