/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { endsWith } from 'lodash'
import React from 'react'

import { uploadFile } from '../services/forms'

export class InvalidFileType extends Error {
  constructor (code) {
    super(code)
    this.code = code || 'INVALID_FILE_TYPE'
  }
}

const ACTION_TYPES = {
  START: 'START',
  PROGRESS: 'PROGRESS',
  ERROR: 'ERROR',
  FINISH: 'FINISH'
}

const actions = {
  start: () => ({ type: ACTION_TYPES.START }),
  progress: (loaded, total) => ({ type: ACTION_TYPES.PROGRESS, loaded, total }),
  error: error => ({ type: ACTION_TYPES.ERROR, error }),
  finish: () => ({ type: ACTION_TYPES.FINISH })
}

const initialState = { uploading: false, progress: 0, error: null }

function getType (name, type) {
  if (type) return type
  if (/\.csv$/.test(name)) return 'text/csv'
  if (/\.stl$/.test(name)) return 'model/stl'
  if (/\.heic$/.test(name)) return 'image/heic'
  if (/\.msg$/.test(name)) return 'application/vnd.ms-outlook'
  if (/\.dat$/.test(name)) return 'text/plain'
  if (/\.3mf$/.test(name)) return 'model/3mf'
  return type
}

function uploadReducer (state, action) {
  switch (action.type) {
    case ACTION_TYPES.START:
      return { uploading: true, progress: 0, error: null }
    case ACTION_TYPES.PROGRESS:
      return {
        ...state,
        progress: Math.round((action.loaded / action.total) * 100)
      }
    case ACTION_TYPES.ERROR:
      return { uploading: false, progress: 0, error: action.error }
    case ACTION_TYPES.FINISH:
      return { uploading: false, progress: 0, error: null }
    default:
      return state
  }
}

export function useUploadFile (
  onChange,
  accepts,
  uploadMutation,
  responseKey = 'signFile'
) {
  const [{ uploading, progress, error }, dispatch] = React.useReducer(
    uploadReducer,
    initialState
  )

  const fileTypeMatchers = React.useMemo(() => {
    if (!accepts) return [() => true]
    return accepts
      .split(',')
      .map(item => item.trim())
      .map(item => {
        if (item.indexOf('.') === 0) {
          return (filename, contentType) =>
            endsWith(filename.toLowerCase(), item)
        }
        const itemRegExp = item.replace(/\./g, '\\.').replace(/\*/g, '.+')
        const contentTypeRegExp = new RegExp(`^${itemRegExp}$`)
        return (filename, contentType) => contentTypeRegExp.test(contentType)
      })
  }, [accepts])

  const handleChange = React.useCallback(
    async (file, rawImage) => {
      const type = getType(file.name, file.type)
      const validFileType = Boolean(
        fileTypeMatchers.find(matcher => matcher(file.name, type))
      )
      if (!validFileType) {
        const code = !file.type ? 'UNKNOWN_FILE_TYPE' : undefined
        dispatch(actions.error(new InvalidFileType(code)))
        onChange(null)
        return
      }
      try {
        dispatch(actions.start())
        const resp = await uploadMutation(file.name, type)
        const { url, fields, confirmToken, keyId } = resp.data[responseKey]

        const formData = new FormData()
        fields.forEach(({ key, value }) => formData.append(key, value))
        formData.append('file', file)
        await uploadFile(url, formData, ({ loaded, total }) => {
          dispatch(actions.progress(loaded, total))
        })
        dispatch(actions.finish())
        const fileInfo = {
          confirmToken,
          keyId,
          filename: file.name,
          contentType: file.type,
          filesize: file.size,
          height: file.height,
          width: file.width,
          imageType: file.imageType
        }
        onChange(fileInfo, rawImage)
        return fileInfo
      } catch (err) {
        dispatch(actions.error(err))
        onChange(null)
      }
    },
    [fileTypeMatchers, onChange, uploadMutation]
  )

  const raiseError = err => {
    dispatch(actions.error(err))
    onChange(null)
  }

  const checkForBadFileType = type => {
    const badFileTypes = ['image/heic']
    if (!type) {
      throw new InvalidFileType('INVALID_FILE_TYPE')
    }
    if (badFileTypes.includes(type)) {
      throw new InvalidFileType('INVALID_FILE_TYPE')
    }
    return badFileTypes.includes(type)
  }

  return {
    uploading,
    progress,
    error,
    handleChange,
    raiseError,
    checkForBadFileType
  }
}
