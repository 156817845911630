/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get, isBoolean, isNil } from 'lodash'
import React from 'react'

import {
  useFormbotData,
  useIdFormkeyMap
} from '../../engine/formbot-react/hooks'

export default function DataLinkView ({ details, fbRenderView, fbValidate }) {
  const data = useFormbotData()
  const idMap = useIdFormkeyMap()
  let key = idMap[details?.parentId]
  if (key?.includes('.*.')) key = key.replace('.*.', `.${details.rowIndex}.`)
  const outputField = details?.selectedOutputField ?? {}
  const fullKey = `${key}.${outputField.path}`
  let linkedValue = get(data, fullKey)
  if (isNil(linkedValue)) {
    return <div />
  }
  if (outputField.type === 'Text' && isBoolean(linkedValue)) {
    linkedValue = '' + linkedValue
  }
  if (!fbValidate(outputField.type, linkedValue, outputField)) {
    if (details.isTest) {
      return (
        <div>
          <Trans id='invalid.data.shape' />
        </div>
      )
    }
    return <div />
  }

  const { isExternal } = details
  const gadgetDetails = Object.assign({}, outputField.details, { isExternal })
  return fbRenderView(
    outputField.type,
    linkedValue,
    gadgetDetails,
    outputField.childrenTemplate,
    fullKey.replace(/^data\./, '')
  )
}
