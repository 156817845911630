/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateDocumentTitlesMutation = (appId, pageId) => {
  const [updateDocumentTitles] = useMutation(mutation)
  return (titleField, titleType = 'FORM') =>
    updateDocumentTitles(getParams(appId, pageId, titleField, titleType))
}

const mutation = gql`
  mutation UpdateDocumentTitles(
    $appId: ID!
    $pageId: ID
    $titleField: String
    $titleType: TitleType
  ) {
    updateDocumentTitles(
      appId: $appId
      pageId: $pageId
      titleField: $titleField
      type: $titleType
    )
  }
`

const getParams = (appId, pageId, titleField, titleType) => {
  return { variables: { appId, pageId, titleField, titleType } }
}
