/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M10.8666 5.67465C11.1724 5.67498 11.4777 5.69838 11.78 5.74465C11.802 5.74783 11.8245 5.74658 11.8461 5.74096C11.8676 5.73534 11.8878 5.72548 11.9055 5.71194C11.9232 5.6984 11.9381 5.68147 11.9491 5.66213C11.9602 5.64279 11.9673 5.62143 11.97 5.59931C11.9884 5.45602 11.9984 5.31177 12 5.16731C12 2.50265 9.38331 0.333313 6.16665 0.333313C2.94998 0.333313 0.333313 2.50265 0.333313 5.16731C0.341899 5.78108 0.479671 6.38615 0.737657 6.94313C0.995644 7.50011 1.36806 7.9965 1.83065 8.39998L0.697313 10.3853C0.6628 10.4454 0.647866 10.5147 0.654586 10.5837C0.661307 10.6526 0.689349 10.7178 0.734813 10.7701C0.780276 10.8224 0.840902 10.8592 0.908259 10.8754C0.975617 10.8916 1.04636 10.8865 1.11065 10.8606L4.06665 9.67731C4.37676 9.77564 4.69359 9.8514 5.01465 9.90398C5.05611 9.91091 5.09866 9.90191 5.13377 9.87879C5.16888 9.85567 5.19396 9.82014 5.20398 9.77931C5.76998 7.46665 8.13331 5.67465 10.8666 5.67465Z`}/><path d={`M10.8666 6.67462C8.26659 6.67462 6.06659 8.57929 6.06659 10.834C6.08073 11.5286 6.2686 12.2086 6.61306 12.8119C6.95753 13.4152 7.44761 13.9227 8.03859 14.288C8.68774 14.711 9.43015 14.9696 10.2017 15.0414C10.9732 15.1132 11.7505 14.996 12.4666 14.7L14.8566 15.6446C14.9236 15.6647 14.995 15.6654 15.0625 15.6467C15.1299 15.628 15.1907 15.5906 15.2378 15.5388C15.2849 15.487 15.3165 15.423 15.3287 15.3541C15.341 15.2851 15.3336 15.2142 15.3073 15.1493L14.7513 13.182C15.3415 12.5427 15.6685 11.704 15.6666 10.834C15.6666 8.57929 13.4666 6.67462 10.8666 6.67462Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M10.8666 5.67465C11.1724 5.67498 11.4777 5.69838 11.78 5.74465C11.802 5.74783 11.8245 5.74658 11.8461 5.74096C11.8676 5.73534 11.8878 5.72548 11.9055 5.71194C11.9232 5.6984 11.9381 5.68147 11.9491 5.66213C11.9602 5.64279 11.9673 5.62143 11.97 5.59931C11.9884 5.45602 11.9984 5.31177 12 5.16731C12 2.50265 9.38331 0.333313 6.16665 0.333313C2.94998 0.333313 0.333313 2.50265 0.333313 5.16731C0.341899 5.78108 0.479671 6.38615 0.737657 6.94313C0.995644 7.50011 1.36806 7.9965 1.83065 8.39998L0.697313 10.3853C0.6628 10.4454 0.647866 10.5147 0.654586 10.5837C0.661307 10.6526 0.689349 10.7178 0.734813 10.7701C0.780276 10.8224 0.840902 10.8592 0.908259 10.8754C0.975617 10.8916 1.04636 10.8865 1.11065 10.8606L4.06665 9.67731C4.37676 9.77564 4.69359 9.8514 5.01465 9.90398C5.05611 9.91091 5.09866 9.90191 5.13377 9.87879C5.16888 9.85567 5.19396 9.82014 5.20398 9.77931C5.76998 7.46665 8.13331 5.67465 10.8666 5.67465Z\" />\n<path d=\"M10.8666 6.67462C8.26659 6.67462 6.06659 8.57929 6.06659 10.834C6.08073 11.5286 6.2686 12.2086 6.61306 12.8119C6.95753 13.4152 7.44761 13.9227 8.03859 14.288C8.68774 14.711 9.43015 14.9696 10.2017 15.0414C10.9732 15.1132 11.7505 14.996 12.4666 14.7L14.8566 15.6446C14.9236 15.6647 14.995 15.6654 15.0625 15.6467C15.1299 15.628 15.1907 15.5906 15.2378 15.5388C15.2849 15.487 15.3165 15.423 15.3287 15.3541C15.341 15.2851 15.3336 15.2142 15.3073 15.1493L14.7513 13.182C15.3415 12.5427 15.6685 11.704 15.6666 10.834C15.6666 8.57929 13.4666 6.67462 10.8666 6.67462Z\" />\n</svg>\n";
export default src;
