/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'

export default gql`
  query GetGroups(
    $query: String
    $skip: Int
    $limit: Int
    $sort: [String!]
    $categoryId: ID
  ) {
    groupsConnection(
      args: {
        limit: $limit
        query: $query
        sort: $sort
        skip: $skip
        categoryId: $categoryId
      }
    ) {
      totalCount
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        skip
        limit
      }
    }
  }
`
