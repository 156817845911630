/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import { Flex } from '../../../ui/layout'
import * as VoronoiDND from '../../../voronoi-dnd'

const Draggable = VoronoiDND.draggable(
  class _Gadget extends React.Component {
    render () {
      return this.props.connectDragSource(
        <div style={{ height: '100%' }} onClick={this.props.onClick}>
          {this.props.children(
            this.props.isDragging || this.props.isA11yDragging
          )}
        </div>
      )
    }
  }
)

export default function Selected ({
  context,
  gadget,
  children,
  rootSection,
  isA11yDragging,
  isCanonicalGadget,
  secondary
}) {
  return (
    <OverlayWrapper rootSection={rootSection}>
      <Draggable
        dragContext={{ id: gadget.id }}
        beginDrag={() => context.beginDrag(gadget)}
        endDrag={context.endDrag}
        onClick={e => {
          if ((e.metaKey || e.ctrlKey) && gadget.type !== 'Section') return
          if (secondary) return
          e.stopPropagation()
        }}
        isA11yDragging={isA11yDragging}
      >
        {isDragging => (
          <div style={{ height: '100%' }}>
            {secondary ? (
              <SecondaryWrapper>
                <SecondarySelectedItem isDragging={isDragging} />
              </SecondaryWrapper>
            ) : (
              <Wrapper className='bg-white' rootSection={rootSection}>
                <SelectedItem
                  isDragging={isDragging}
                  isCanonicalGadget={isCanonicalGadget}
                >
                  <Flex>
                    <Icons.Drag style={{ cursor: 'grab' }} />
                  </Flex>
                </SelectedItem>
              </Wrapper>
            )}
            <OverlayWrapper isDragging={isDragging}>{children}</OverlayWrapper>
          </div>
        )}
      </Draggable>
    </OverlayWrapper>
  )
}

const SelectedItem = styled.div`
  opacity: ${props => (props.isDragging ? 0.2 : 1)};
  background-color: ${p => (p.isCanonicalGadget ? '#e3f8f3' : '#f6f7f8')};
  html.dark & {
    // Outlier: cx({ 'dark:bg-[#535d5b]': isCanonicalGadget, 'dark:bg-light-gray-400': !isCanonicalGadget })
    background-color: ${p => (p.isCanonicalGadget ? '#535d5b' : '#444')};
  }
  border: solid 2px ${p => (p.isCanonicalGadget ? '#008461' : '#7da4d9')};
  box-shadow: 0 4px 18px 0 rgba(97, 97, 97, 0.18);
  display: flex;
  width: 100%;
  height: 100%;
`

const SecondarySelectedItem = styled(SelectedItem)`
  background-color: #f3f8ff;
  border-width: 1px;
  box-shadow: none;
`

const OverlayWrapper = styled.div`
  opacity: ${props => (props.isDragging ? 0.2 : 1)};
  position: relative;
  height: 100%;
  padding: ${props => (props.rootSection ? '12px 0' : 0)}px;
  & > .formbot-gadget {
    border-color: transparent !important;
  }
  & > .formbot-table-column {
    border-left: 1px solid #e7e7e7;
    html.dark & {
      // Outlier: dark:border-l-light-gray-300
      border-left: 1px solid #333;
    }
  }
  & > .formbot-section-wrapper {
    border-bottom: none;
    border-right: none;
  }
  & > .formbot-section-wrapper > .formbot-section.labelless {
    background: inherit;
  }
  &&&& > .formbot-section-wrapper > .formbot-section {
    margin: 0;
  }

  & > .formbot-gadget > .canonical-gadget-k > polygon,
  & > .formbot-table-column .canonical-gadget-k > polygon {
    fill: #50b189;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: ${props => (props.rootSection ? 5 : -5)}px;
  left: -15px;
  width: calc(100% + 20px);
  height: ${props =>
    props.rootSection ? 'calc(100% - 10px)' : 'calc(100% + 10px)'};
  z-index: 2;
  & + ${OverlayWrapper} {
    z-index: 3;
  }
`

const SecondaryWrapper = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  & + ${OverlayWrapper} {
    z-index: 2;
  }
`
