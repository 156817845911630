/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isArray, isNumber, isString } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { toDollars } from '../../../src/pages-builder/calculations/calculation-functions'

const NewFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & input {
    width: 90%;
  }
`

const A = styled.a`
  color: #96c1db;
`

export const minMaxNumbers = {
  key: 'minMaxNumbers',
  label: <Trans id='require.min.max.numbers' />,
  evaluate: (value, inputs) => {
    const { min, max } = inputs || {}
    const errors = []
    if (isNumber(value) && isNumber(min) && value < min) {
      errors.push(`${i18n._('must.be.at.least.min', { min })}`)
    }
    if (isNumber(value) && isNumber(max) && value > max) {
      errors.push(`${i18n._('must.be.no.more.max', { max })}`)
    }
    return errors.length ? errors : null
  },
  UI: ({ Gadgets }) => (
    <NewFlex>
      <Gadgets.Number configKey='min' label={i18n._('minimum.number')} />
      <Gadgets.Number configKey='max' label={i18n._('maximum.number')} />
    </NewFlex>
  )
}

export const integrationFields = {
  key: 'integrationFields',
  label: <Trans id='integration.fields' />,
  evaluate: (value, inputs) => {
    const errors = []
    if (isArray(value)) {
      const includesLabel = value.every(v => v.label !== null || v.id !== null)
      if (!includesLabel) {
        errors.push(`${i18n._('all.fields.id.and.label')}`)
      }
    } else {
      if (value.label == null || value.id == null) {
        const message =
          value.label == null && value.id == null ? (
            <Trans id='must.have.label.id' />
          ) : value.label == null ? (
            <Trans id='must.have.label' />
          ) : (
            <Trans id='must.have.id' />
          )
        errors.push(message)
      }
    }

    return errors.length ? errors : null
  },
  UI: ({ Gadgets }) => (
    <>
      <Gadgets.Text
        configKey='integration'
        label={i18n._('integration.error')}
      />
      <Gadgets.Text
        configKey='integrationMessage'
        label={i18n._('custom.error.message')}
      />
    </>
  )
}

export const minMaxCurrency = {
  key: 'minMaxCurrency',
  label: <Trans id='require.min.max.amount' />,
  evaluate: (value, inputs) => {
    const { min, max } = inputs || {}
    const errors = []
    if (isNumber(value) && isNumber(min) && value < min) {
      errors.push(
        `${i18n._('must.be.at.min.dollars', { min: toDollars(min) })}`
      )
    }
    if (isNumber(value) && isNumber(max) && value > max) {
      errors.push(
        `${i18n._('must.be.no.max.dollars', { max: toDollars(max) })}`
      )
    }
    return errors.length ? errors : null
  },
  UI: ({ Gadgets }) => (
    <NewFlex>
      <Gadgets.Currency configKey='min' label={i18n._('minimum.amount')} />
      <Gadgets.Currency configKey='max' label={i18n._('maximum.amount')} />
    </NewFlex>
  )
}

export const minMaxCharacters = {
  key: 'minMaxCharacters',
  label: <Trans id='require.min.max.characters' />,
  evaluate: (value, inputs) => {
    const { min, max } = inputs || {}
    const errors = []
    if (isString(value) && isNumber(min) && value.length < min) {
      errors.push(`${i18n._('must.be.at.min.characters', { min })}`)
    }
    if (isString(value) && isNumber(max) && value.length > max) {
      errors.push(`${i18n._('must.be.no.max.characters', { max })}`)
    }
    return errors.length ? errors : null
  },
  UI: ({ Gadgets }) => (
    <NewFlex>
      <Gadgets.Number configKey='min' label={i18n._('minimum.characters')} />
      <Gadgets.Number configKey='max' label={i18n._('maximum.characters')} />
    </NewFlex>
  )
}

export const regex = {
  key: 'regex',
  label: <Trans id='regular.expression' />,
  description: (
    <span>
      <Trans
        id='regular.expression.data'
        components={{
          link: (
            <A
              href='https://en.wikipedia.org/wiki/Regular_expressions'
              target='_blank'
              rel='noopener noreferrer'
            />
          )
        }}
      />
    </span>
  ),
  evaluate: (value, inputs) => {
    const { re, message } = inputs || {}
    if (!isString(value) || !isString(re)) return null
    try {
      if (new RegExp(re).test(value)) return null
      return [message || `${i18n._('invalid.value.entered')}`]
    } catch (err) {
      console.error(err)
      return [message || `${i18n._('invalid.value.entered')}`]
    }
  },
  UI: ({ Gadgets }) => (
    <>
      <Gadgets.Text configKey='re' label={i18n._('regular.expression')} />
      <Gadgets.Text
        configKey='message'
        label={i18n._('custom.error.message')}
      />
    </>
  )
}

export const custom = {
  key: 'custom',
  label: <Trans id='custom' />,
  evaluate: (value, callback) => {
    return callback(value)
  }
}

export const minMaxDate = {
  key: 'minMaxDate',
  label: <Trans id='require.min.max.date' />,
  evaluate: (value, inputs) => {
    const { min, max } = inputs || {}
    const errors = []
    if (isNumber(value) && isNumber(min) && value < min) {
      errors.push(`${i18n._('must.be.after.min', { min: toDateString(min) })}`)
    }
    if (isNumber(value) && isNumber(max) && value > max) {
      errors.push(`${i18n._('must.be.before.max', { max: toDateString(max) })}`)
    }
    return errors.length ? errors : null
  },
  UI: ({ Gadgets }) => (
    <NewFlex>
      <Gadgets.Date configKey='min' label={i18n._('minimum.date')} />
      <Gadgets.Date configKey='max' label={i18n._('maximum.date')} />
    </NewFlex>
  )
}

export const safeTextLength = {
  key: 'safeTextLength',
  label: <Trans id='maximum.length.for.server.storage' />,
  evaluate: (value, { max = 50000 } = {}) => {
    const errors = []
    if (isString(value) && isNumber(max) && value.length > max) {
      errors.push(`${i18n._('must.be.no.more.than.max', { max })}`)
    }
    return errors.length ? errors : null
  }
}

// --- Helpers ------------------

const getDateObject = value => {
  if (!value) return null
  if (typeof value !== 'number' && typeof value !== 'string') return null
  let date
  if (typeof value === 'number') {
    const tempDate = new Date(value)
    date = new Date(
      tempDate.getUTCFullYear(),
      tempDate.getUTCMonth(),
      tempDate.getUTCDate()
    )
  } else {
    date = new Date(value)
  }
  if (date.toString() === 'Invalid Date') return null
  return date
}

const toDateString = value => {
  const date = getDateObject(value)
  if (!date) return null
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })
}
