/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { Link } from 'react-router-dom'

import { columns } from './columns'

export default function ({ result, setFocus }) {
  const handleKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown':
        setFocus(i => i + 1)
        e.preventDefault()
        break
      case 'ArrowUp':
        setFocus(i => i - 1)
        e.preventDefault()
        break
      case ' ':
        e.target.click()
        break
      default:
        break
    }
  }

  return (
    <Link
      className='kc-table-row flex border-b border-b-light-gray-200 bg-light-gray-100 py-1 hover:bg-white focus:bg-blue-500 focus:text-white dark:border-b-light-gray-300 dark:bg-light-gray-400 dark:hover:bg-light-gray-300 dark:focus:text-black'
      to={result.id}
      onKeyDown={handleKeyDown}
      tabIndex='1'
      data-handler-url={result.handlerUrl}
    >
      {columns.map(({ key, getValue }) => (
        <div key={key} className='min-w-[140px] flex-1 overflow-ellipsis p-3'>
          {getValue(result)}
        </div>
      ))}
    </Link>
  )
}
