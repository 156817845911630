/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { includes, pick } from 'lodash'
import React from 'react'

import * as Icons from '../../icons'

export default ({ template: gadget, context }) => {
  if (gadget.details?.disableDND || includes(['Column', 'Row'], gadget.type)) {
    return {}
  }
  return {
    component: component => {
      const label =
        gadget.type === 'TableColumn'
          ? `Table Column ${gadget.details?.gadget?.label}`
          : `${gadget.type} ${gadget.label}`
      const canMove = gadget.id !== context.template?.id
      return (
        <div
          id={`gadget-wrapper-${gadget.id}`}
          className='relative'
          tabIndex={0}
          aria-label={label}
          aria-describedby='keyboard-movable-help'
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              if (e.target !== e.currentTarget) return
              e.preventDefault()
              e.stopPropagation()
              context.select(gadget.id)
            }
            if (e.key === 'Delete') {
              if (e.target !== e.currentTarget) return
              e.stopPropagation()
              context.remove(gadget)
            }
            if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
              e.stopPropagation()
              context.copy(gadget.id)
            }
          }}
        >
          {canMove && (
            <Icons.Move
              role='button'
              tabIndex={0}
              aria-label={`Move ${label}`}
              className='sr-only z-10 rounded-sm bg-black fill-white focus:not-sr-only focus:absolute focus:w-4 focus:p-0.5'
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  e.stopPropagation()
                  context.beginA11yDrag(
                    pick(gadget, ['id', 'children', 'childrenTemplate'])
                  )
                }
              }}
            />
          )}
          {component}
        </div>
      )
    }
  }
}
