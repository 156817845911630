/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13.0373 0.239334H2.96267C2.57495 0.240391 2.20343 0.394923 1.92934 0.669142C1.65524 0.94336 1.50088 1.31495 1.5 1.70267V2.962C1.5 3.18301 1.5878 3.39498 1.74408 3.55126C1.90036 3.70754 2.11232 3.79533 2.33333 3.79533C2.55435 3.79533 2.76631 3.70754 2.92259 3.55126C3.07887 3.39498 3.16667 3.18301 3.16667 2.962V2.07267C3.16667 2.02846 3.18423 1.98607 3.21548 1.95482C3.24674 1.92356 3.28913 1.906 3.33333 1.906H7C7.0442 1.906 7.0866 1.92356 7.11785 1.95482C7.14911 1.98607 7.16667 2.02846 7.16667 2.07267V13.9247C7.16667 13.9689 7.14911 14.0113 7.11785 14.0425C7.0866 14.0738 7.0442 14.0913 7 14.0913H5.796C5.57499 14.0913 5.36302 14.1791 5.20674 14.3354C5.05046 14.4917 4.96267 14.7037 4.96267 14.9247C4.96267 15.1457 5.05046 15.3576 5.20674 15.5139C5.36302 15.6702 5.57499 15.758 5.796 15.758H10.204C10.425 15.758 10.637 15.6702 10.7933 15.5139C10.9495 15.3576 11.0373 15.1457 11.0373 14.9247C11.0373 14.7037 10.9495 14.4917 10.7933 14.3354C10.637 14.1791 10.425 14.0913 10.204 14.0913H9C8.9558 14.0913 8.9134 14.0738 8.88215 14.0425C8.85089 14.0113 8.83333 13.9689 8.83333 13.9247V2.07267C8.83333 2.02846 8.85089 1.98607 8.88215 1.95482C8.9134 1.92356 8.9558 1.906 9 1.906H12.6667C12.7109 1.906 12.7533 1.92356 12.7845 1.95482C12.8158 1.98607 12.8333 2.02846 12.8333 2.07267V2.962C12.8333 3.18301 12.9211 3.39498 13.0774 3.55126C13.2337 3.70754 13.4457 3.79533 13.6667 3.79533C13.8877 3.79533 14.0996 3.70754 14.2559 3.55126C14.4122 3.39498 14.5 3.18301 14.5 2.962V1.70267C14.4991 1.31495 14.3448 0.94336 14.0707 0.669142C13.7966 0.394923 13.425 0.240391 13.0373 0.239334Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.0373 0.239334H2.96267C2.57495 0.240391 2.20343 0.394923 1.92934 0.669142C1.65524 0.94336 1.50088 1.31495 1.5 1.70267V2.962C1.5 3.18301 1.5878 3.39498 1.74408 3.55126C1.90036 3.70754 2.11232 3.79533 2.33333 3.79533C2.55435 3.79533 2.76631 3.70754 2.92259 3.55126C3.07887 3.39498 3.16667 3.18301 3.16667 2.962V2.07267C3.16667 2.02846 3.18423 1.98607 3.21548 1.95482C3.24674 1.92356 3.28913 1.906 3.33333 1.906H7C7.0442 1.906 7.0866 1.92356 7.11785 1.95482C7.14911 1.98607 7.16667 2.02846 7.16667 2.07267V13.9247C7.16667 13.9689 7.14911 14.0113 7.11785 14.0425C7.0866 14.0738 7.0442 14.0913 7 14.0913H5.796C5.57499 14.0913 5.36302 14.1791 5.20674 14.3354C5.05046 14.4917 4.96267 14.7037 4.96267 14.9247C4.96267 15.1457 5.05046 15.3576 5.20674 15.5139C5.36302 15.6702 5.57499 15.758 5.796 15.758H10.204C10.425 15.758 10.637 15.6702 10.7933 15.5139C10.9495 15.3576 11.0373 15.1457 11.0373 14.9247C11.0373 14.7037 10.9495 14.4917 10.7933 14.3354C10.637 14.1791 10.425 14.0913 10.204 14.0913H9C8.9558 14.0913 8.9134 14.0738 8.88215 14.0425C8.85089 14.0113 8.83333 13.9689 8.83333 13.9247V2.07267C8.83333 2.02846 8.85089 1.98607 8.88215 1.95482C8.9134 1.92356 8.9558 1.906 9 1.906H12.6667C12.7109 1.906 12.7533 1.92356 12.7845 1.95482C12.8158 1.98607 12.8333 2.02846 12.8333 2.07267V2.962C12.8333 3.18301 12.9211 3.39498 13.0774 3.55126C13.2337 3.70754 13.4457 3.79533 13.6667 3.79533C13.8877 3.79533 14.0996 3.70754 14.2559 3.55126C14.4122 3.39498 14.5 3.18301 14.5 2.962V1.70267C14.4991 1.31495 14.3448 0.94336 14.0707 0.669142C13.7966 0.394923 13.425 0.240391 13.0373 0.239334Z\"/>\n</svg>\n";
export default src;
