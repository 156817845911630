/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import * as Illustrations from '../../illustrations'
import InfoBox from '../../ui/info-box'

export const Empty = ({ formUrl }) => {
  return (
    <div className='flex flex-1 items-start justify-center pt-16'>
      <Illustrations.Craig className='relative left-9 mt-40 shrink-0' />
      <InfoBox
        title={i18n._('pagesbuilder.maintenance.no.tables')}
        subtitle={i18n._('pagesbuilder.maintenance.tables.be.here')}
      />
    </div>
  )
}
