/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ModalPage } from '../components/modal-page'
import Button from './button'
import Editor from './editor'

export default function FlumeLoop ({ ports, ...props }) {
  const [showModal, setShowModal] = React.useState(false)
  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        <Trans id='configure.loop' />
      </Button>
      <TransitionGroup>
        <CSSTransition key={showModal} timeout={450}>
          {showModal ? (
            <ModalPage
              title={i18n._('advanced.integration.loop')}
              onClose={() => setShowModal(false)}
              onMouseDown={e => e.stopPropagation()}
              onContextMenu={e => e.stopPropagation()}
            >
              <Editor {...props} ports={ports} />
            </ModalPage>
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
    </>
  )
}
