/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6093 13.7266L12.8 10.9172C13.7751 9.47534 14.178 7.72186 13.9301 5.99894C13.6822 4.27601 12.8013 2.70725 11.4592 1.59876C10.1171 0.490271 8.41019 -0.0784247 6.67146 0.00363913C4.93272 0.085703 3.28694 0.812639 2.05523 2.04261C0.823518 3.27257 0.0942536 4.91733 0.00972876 6.65594C-0.074796 8.39456 0.491483 10.1023 1.59807 11.4459C2.70466 12.7896 4.27217 13.6727 5.99475 13.9231C7.71732 14.1734 9.47137 13.773 10.9146 12.7999L13.724 15.6086C13.976 15.8548 14.3143 15.9927 14.6666 15.9927C15.019 15.9927 15.3573 15.8548 15.6093 15.6086C15.8583 15.3587 15.998 15.0203 15.998 14.6676C15.998 14.3148 15.8583 13.9765 15.6093 13.7266ZM6.99998 1.33324C8.12074 1.33324 9.21633 1.66559 10.1482 2.28825C11.0801 2.91091 11.8064 3.79592 12.2353 4.83137C12.6642 5.86682 12.7764 7.0062 12.5578 8.10542C12.3391 9.20465 11.7994 10.2144 11.0069 11.0068C10.2144 11.7993 9.20472 12.339 8.10549 12.5577C7.00627 12.7763 5.86689 12.6641 4.83144 12.2352C3.79599 11.8063 2.91098 11.08 2.28832 10.1481C1.66566 9.21626 1.33331 8.12067 1.33331 6.99991C1.33508 5.49756 1.93267 4.05724 2.99499 2.99492C4.05731 1.9326 5.49763 1.33501 6.99998 1.33324Z`}/><path d={`M4.66667 7.66666H9.33333C9.51014 7.66666 9.67971 7.59642 9.80474 7.4714C9.92976 7.34637 10 7.17681 10 6.99999C10 6.82318 9.92976 6.65361 9.80474 6.52859C9.67971 6.40357 9.51014 6.33333 9.33333 6.33333H4.66667C4.48986 6.33333 4.32029 6.40357 4.19526 6.52859C4.07024 6.65361 4 6.82318 4 6.99999C4 7.17681 4.07024 7.34637 4.19526 7.4714C4.32029 7.59642 4.48986 7.66666 4.66667 7.66666Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6093 13.7266L12.8 10.9172C13.7751 9.47534 14.178 7.72186 13.9301 5.99894C13.6822 4.27601 12.8013 2.70725 11.4592 1.59876C10.1171 0.490271 8.41019 -0.0784247 6.67146 0.00363913C4.93272 0.085703 3.28694 0.812639 2.05523 2.04261C0.823518 3.27257 0.0942536 4.91733 0.00972876 6.65594C-0.074796 8.39456 0.491483 10.1023 1.59807 11.4459C2.70466 12.7896 4.27217 13.6727 5.99475 13.9231C7.71732 14.1734 9.47137 13.773 10.9146 12.7999L13.724 15.6086C13.976 15.8548 14.3143 15.9927 14.6666 15.9927C15.019 15.9927 15.3573 15.8548 15.6093 15.6086C15.8583 15.3587 15.998 15.0203 15.998 14.6676C15.998 14.3148 15.8583 13.9765 15.6093 13.7266ZM6.99998 1.33324C8.12074 1.33324 9.21633 1.66559 10.1482 2.28825C11.0801 2.91091 11.8064 3.79592 12.2353 4.83137C12.6642 5.86682 12.7764 7.0062 12.5578 8.10542C12.3391 9.20465 11.7994 10.2144 11.0069 11.0068C10.2144 11.7993 9.20472 12.339 8.10549 12.5577C7.00627 12.7763 5.86689 12.6641 4.83144 12.2352C3.79599 11.8063 2.91098 11.08 2.28832 10.1481C1.66566 9.21626 1.33331 8.12067 1.33331 6.99991C1.33508 5.49756 1.93267 4.05724 2.99499 2.99492C4.05731 1.9326 5.49763 1.33501 6.99998 1.33324Z\" />\n<path d=\"M4.66667 7.66666H9.33333C9.51014 7.66666 9.67971 7.59642 9.80474 7.4714C9.92976 7.34637 10 7.17681 10 6.99999C10 6.82318 9.92976 6.65361 9.80474 6.52859C9.67971 6.40357 9.51014 6.33333 9.33333 6.33333H4.66667C4.48986 6.33333 4.32029 6.40357 4.19526 6.52859C4.07024 6.65361 4 6.82318 4 6.99999C4 7.17681 4.07024 7.34637 4.19526 7.4714C4.32029 7.59642 4.48986 7.66666 4.66667 7.66666Z\" />\n</svg>\n";
export default src;
