/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUploadMutation = () => {
  const [signUpload] = useMutation(mutation)
  return async file => {
    const { url, fields } = await signUpload(
      getParams(file.name, file.type)
    ).then(resp => resp.data.signBrandingUploadUrl)
    return uploadFile(url, fields, file)
  }
}

const mutation = gql`
  mutation SignUpload($filename: String!, $filetype: String!) {
    signBrandingUploadUrl(filename: $filename, filetype: $filetype) {
      url
      fields
    }
  }
`

const getParams = (filename, filetype) => ({
  variables: { filename, filetype }
})

async function uploadFile (url, fields, file) {
  const formData = new FormData()
  fields.forEach(({ key, value }) => formData.append(key, value))
  formData.append('file', file)
  const request = new XMLHttpRequest()
  return new Promise((resolve, reject) => {
    request.open('POST', url)
    request.addEventListener('load', () =>
      resolve({ location: request.getResponseHeader('Location') })
    )
    request.addEventListener('error', reject)
    request.addEventListener('abort', resolve)
    request.send(formData)
  })
}
