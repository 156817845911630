/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8.00001 4.33333C9.19663 4.33333 10.1667 3.36328 10.1667 2.16667C10.1667 0.97005 9.19663 0 8.00001 0C6.80339 0 5.83334 0.97005 5.83334 2.16667C5.83334 3.36328 6.80339 4.33333 8.00001 4.33333Z`}/><path d={`M8.00001 10.1667C9.19663 10.1667 10.1667 9.19661 10.1667 7.99999C10.1667 6.80338 9.19663 5.83333 8.00001 5.83333C6.80339 5.83333 5.83334 6.80338 5.83334 7.99999C5.83334 9.19661 6.80339 10.1667 8.00001 10.1667Z`}/><path d={`M8.00001 16C9.19663 16 10.1667 15.03 10.1667 13.8333C10.1667 12.6367 9.19663 11.6667 8.00001 11.6667C6.80339 11.6667 5.83334 12.6367 5.83334 13.8333C5.83334 15.03 6.80339 16 8.00001 16Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8.00001 4.33333C9.19663 4.33333 10.1667 3.36328 10.1667 2.16667C10.1667 0.97005 9.19663 0 8.00001 0C6.80339 0 5.83334 0.97005 5.83334 2.16667C5.83334 3.36328 6.80339 4.33333 8.00001 4.33333Z\" />\n<path d=\"M8.00001 10.1667C9.19663 10.1667 10.1667 9.19661 10.1667 7.99999C10.1667 6.80338 9.19663 5.83333 8.00001 5.83333C6.80339 5.83333 5.83334 6.80338 5.83334 7.99999C5.83334 9.19661 6.80339 10.1667 8.00001 10.1667Z\" />\n<path d=\"M8.00001 16C9.19663 16 10.1667 15.03 10.1667 13.8333C10.1667 12.6367 9.19663 11.6667 8.00001 11.6667C6.80339 11.6667 5.83334 12.6367 5.83334 13.8333C5.83334 15.03 6.80339 16 8.00001 16Z\" />\n</svg>\n";
export default src;
