/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div.attrs({
  'data-testid': 'spinner-component'
})`
  ${props =>
    props.inline
      ? css`
          display: inline-block;
        `
      : null}
  flex-shrink: 0;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  animation: 2s ${spin} linear infinite;
  border-radius: 100%;
  border-style: solid;
  border-width: ${props => (props.size < 30 ? 3 : 4)}px;
  border-color: #dddddd;
  border-top-color: #00b586;
`

export default Spinner
