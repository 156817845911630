/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import {
  cloneDeep,
  filter,
  find,
  get,
  isNumber,
  isPlainObject,
  isString,
  map,
  set,
  throttle,
  trim
} from 'lodash'
import React from 'react'

const getColumn = template => {
  const column = get(template, 'children.0', {})
  return column.type !== 'Column' ? template : column
}

export function setURLInputs (url, urlInputs) {
  const params = url.match(/{{([^}]*)}}/g) || []
  const keys = new Set(
    params.map(key => key.replace(/^{{([^}]*)}}$/g, '$1').trim())
  )
  const existingInputs = urlInputs.filter(input => {
    if (keys.has(input.__dataPath)) {
      keys.delete(input.__dataPath)
      return true
    }
    return false
  })
  const newInputs = [...keys].map(key => ({ __dataPath: key, __label: '' }))
  return existingInputs.concat(newInputs)
}

export function updateTemplate (template, value) {
  template = cloneDeep(template)
  template.children = template.children?.filter(
    child => child.formKey !== '__spaceId'
  )
  template = setURLInputsConfig(template, value)
  return setSearchParameterConfig(template, value)
}

function setURLInputsConfig (template, value) {
  const column = getColumn(template)
  const urlInputs = find(column.children, { formKey: '__urlInputs' })
  const numRows = get(value, '__urlInputs.length', 0)
  set(urlInputs, 'details.minRows', numRows)
  set(urlInputs, 'details.maxRows', numRows)
  return template
}

function setSearchParameterConfig (template, value) {
  const column = getColumn(template)
  const gadget = find(column.children, { formKey: '__searchParameter' })
  let options = [
    ...map(value?.__urlInputs, inp => ({ ...inp, type: 'URL' })),
    ...map(value?.__queryParameterInputs, inp => ({ ...inp, type: 'Query' }))
  ]
  options = filter(options, input => input.__label && input.__dataPath)
  options = map(options, input => ({
    key: `${input.type.toLowerCase()}:${input.__dataPath}`,
    lbl: `${input.type} Param: ${input.__label}`
  }))
  set(gadget, 'details.options', options)
  return template
}

export function findArray (obj) {
  if (isPlainObject(obj)) {
    return Object.keys(obj).find(key => {
      return Array.isArray(obj[key])
    })
  }
}

function validResultValue (value) {
  return (
    (isString(value) || isNumber(value)) && value != null && trim(value) !== ''
  )
}

export function findResultKeys (obj) {
  if (isPlainObject(obj)) {
    return Object.keys(obj).filter(validResultValue).join(', ')
  }
}

export function useWindowWidth () {
  const [width, setWidth] = React.useState(window.innerWidth)
  React.useEffect(() => {
    const handler = throttle(() => setWidth(window.innerWidth), 100)
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])
  return width
}
