/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import { Flex } from '../../../ui/layout'
import { Option, Select } from '../../../ui/select'
import { formatUsers } from '../user-typeahead'

export function SimulateWrapper ({
  buttons,
  children,
  headless,
  simulateAction,
  simulationState
}) {
  const actionButtons = buttons || (
    <DefaultButtons
      simulateAction={simulateAction}
      simulationState={simulationState}
    />
  )

  if (headless) {
    return (
      <>
        {children}
        <div className='bg-light-gray-200'>{actionButtons}</div>
      </>
    )
  }
  return (
    <Wrapper
      className='bg-white dark:bg-light-gray-300'
      waiting={simulationState?.processing}
    >
      {children}
      <div className='bg-light-gray-200'>{actionButtons}</div>
    </Wrapper>
  )
}

export function SimulateHeader ({
  children,
  currentUser,
  mode,
  onCurrentUserChange,
  selectedUser,
  userOptions = [selectedUser]
}) {
  return (
    <SimulateHeaderDiv>
      <CurrentUser>
        <Prefix id='current-user'>
          <Trans id='current.user.colon' />
        </Prefix>
        {mode === 'submitter' && (
          <SubmitterPicker
            currentUser={currentUser}
            onCurrentUserChange={onCurrentUserChange}
            selectedUser={selectedUser}
          />
        )}
        {mode !== 'submitter' && (
          <RegularPicker
            selectedUser={selectedUser}
            onCurrentUserChange={onCurrentUserChange}
            userOptions={formatUsers(userOptions)}
          />
        )}
      </CurrentUser>
      {children}
    </SimulateHeaderDiv>
  )
}

function DefaultButtons ({ simulateAction, simulationState }) {
  return (
    <SimulateButtons>
      <Button
        disabled={simulationState.processing}
        id='back-btn'
        onClick={() => simulateAction('back', simulationState)}
        outline
      >
        <Trans id='back' />
      </Button>
      <Button
        disabled={simulationState.processing}
        id='default-btn'
        onClick={() => simulateAction('next', simulationState)}
      >
        <Trans id='next' />
      </Button>
    </SimulateButtons>
  )
}

function SubmitterPicker ({ currentUser, onCurrentUserChange, selectedUser }) {
  const fixedOptions = [
    {
      id: currentUser.id,
      label: `${currentUser.displayName} (you)`,
      user: { ...currentUser, label: currentUser.displayName }
    },
    {
      id: 'anonymous',
      label: 'Anonymous Person'
    },
    {
      id: 'generic',
      label: i18n._('generic.loggedin.person'),
      user: {
        id: 'generic',
        label: i18n._('generic.loggedin.person'),
        displayName: i18n._('generic.loggedin.person'),
        email: 'generic@person.com',
        username: 'genericperson'
      }
    }
  ]
  const _selectedUser =
    selectedUser?.displayName?.indexOf('Anonymous') > -1
      ? fixedOptions[1]
      : selectedUser
  return (
    <RegularPicker
      userOptions={fixedOptions}
      selectedUser={_selectedUser || fixedOptions[0]}
      onCurrentUserChange={onCurrentUserChange}
    />
  )
}

function RegularPicker ({ userOptions, selectedUser, onCurrentUserChange }) {
  return (
    <PickerWrapper>
      <Select
        aria-labelledby='current-user'
        id='current-user-picker'
        onChange={val => {
          const user = find(userOptions, { id: val })
          onCurrentUserChange(user)
        }}
        value={selectedUser?.id}
      >
        {map(userOptions, ({ label, id }) => (
          <Option key={id} value={id}>
            {label}
          </Option>
        ))}
      </Select>
    </PickerWrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${p => p.waiting && 'cursor: wait;'}
`

const SimulateHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  padding: 10px;
  color: var(--black);
`

const CurrentUser = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const PickerWrapper = styled.div`
  flex-grow: 1;
  max-width: 240px;
`

const Prefix = styled.label`
  margin-right: 4px;
  font-size: 16px;
`

export const UserName = styled.span`
  font-weight: 500;
  margin-left: 10px;
`

export const SimulateButtons = styled.div`
  background: '#eeeeee';
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
`

export const InfoPage = styled(Flex)`
  height: 100%;
  min-height: 340px;
  background: var(--white);
  overflow: auto;
`

export const InfoTitle = styled.h1`
  color: var(--black);
  font-size: 34px;
  font-weight: normal;
  line-height: 35px;
  margin: 20px;
  text-align: center;
`
