/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Body2 } from '../../../ui/typography'

export default ({
  label,
  config,
  desc,
  displayDescInline,
  gadget,
  template,
  className,
  readOnly,
  gridded
}) => {
  const displayLabel = !template.hideLabel
  const depth = get(template, 'sectionDepth', 1)
  const tag = `h${Math.min(6, Math.max(1, depth) + 1)}`
  return (
    <div
      id={`gadget-${template.id}`}
      className={cx(
        'formbot-section-wrapper flex-1',
        {
          'break-inside-avoid': depth === 1,
          'border-b border-r border-light-gray-300 p-4':
            gridded && (tag !== 'h2' || !template.root)
        },
        className
      )}
    >
      <Section
        nested={tag !== 'h2' || !template.root}
        className={cx('formbot-section bg-white', {
          labelless: !displayLabel,
          'border-l border-t border-light-gray-300': displayLabel
        })}
      >
        {!displayLabel && config && (
          <Indicator className='bg-blue-500'>
            {template.label || 'Section'}
          </Indicator>
        )}
        {displayLabel ? (
          <Heading
            className='border-b border-r border-light-gray-300 bg-light-gray-100 text-dark-gray-500 dark:bg-light-gray-300'
            inline={displayDescInline}
            as={tag}
            readOnly={readOnly}
          >
            {label}
            {desc}
          </Heading>
        ) : null}
        {!displayLabel && desc && <LabelArea>{desc}</LabelArea>}
        {gadget}
      </Section>
    </div>
  )
}

const LabelArea = styled.div`
  padding: 8px 16px;
  min-height: 0 !important;
`

const Section = styled.div`
  box-shadow: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${p => (p.nested ? 0 : '12px 0')};
  position: relative;
`

const Heading = styled.h2`
  display: flex;
  align-items: ${p => (p.inline ? 'center' : 'flex-start')};
  flex-direction: ${p => (p.inline ? 'row' : 'column')};
  padding: 16px;
  line-height: inherit;
  margin: 0;
  > span {
    display: block;
  }
`

const Indicator = styled(Body2)`
  align-self: flex-start;
  color: var(--white);
  display: inline-block;
  text-transform: uppercase;
  padding: 4px 8px;
`
