/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateUserMutation = userId => {
  const [updateUser] = useMutation(mutation)
  return data => updateUser(getParams(userId, data))
}

const mutation = gql`
  mutation UpdateUser($userId: ID!, $data: JSON!) {
    updateUser(userId: $userId, data: $data) {
      ... on User {
        id
        firstName
        lastName
        displayName
        name
        username
        email
        role
        schoolId
        approved
        active
      }
      ... on InvalidFieldErrors {
        errors {
          field
          reason
        }
      }
    }
  }
`

const getParams = (userId, rawData) => {
  const data = { ...rawData }
  if (data.role?.id) data.role = data.role.id
  return { variables: { userId, data } }
}
