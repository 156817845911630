/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M9.90866 15.514H0.99999C0.778976 15.514 0.567014 15.4262 0.410734 15.2699C0.254454 15.1136 0.166656 14.9017 0.166656 14.6807C0.166656 14.4597 0.254454 14.2477 0.410734 14.0914C0.567014 13.9351 0.778976 13.8473 0.99999 13.8473H9.90866C10.1297 13.8473 10.3416 13.9351 10.4979 14.0914C10.6542 14.2477 10.742 14.4597 10.742 14.6807C10.742 14.9017 10.6542 15.1136 10.4979 15.2699C10.3416 15.4262 10.1297 15.514 9.90866 15.514Z`}/><path d={`M15 2.15H0.99999C0.778976 2.15 0.567014 2.06221 0.410734 1.90593C0.254454 1.74965 0.166656 1.53768 0.166656 1.31667C0.166656 1.09566 0.254454 0.883695 0.410734 0.727415C0.567014 0.571135 0.778976 0.483337 0.99999 0.483337H15C15.221 0.483337 15.433 0.571135 15.5892 0.727415C15.7455 0.883695 15.8333 1.09566 15.8333 1.31667C15.8333 1.53768 15.7455 1.74965 15.5892 1.90593C15.433 2.06221 15.221 2.15 15 2.15Z`}/><path d={`M15 6.60467H0.99999C0.778976 6.60467 0.567014 6.51687 0.410734 6.36059C0.254454 6.20431 0.166656 5.99235 0.166656 5.77134C0.166656 5.55032 0.254454 5.33836 0.410734 5.18208C0.567014 5.0258 0.778976 4.938 0.99999 4.938H15C15.221 4.938 15.433 5.0258 15.5892 5.18208C15.7455 5.33836 15.8333 5.55032 15.8333 5.77134C15.8333 5.99235 15.7455 6.20431 15.5892 6.36059C15.433 6.51687 15.221 6.60467 15 6.60467Z`}/><path d={`M15 11.0587H0.99999C0.778976 11.0587 0.567014 10.9709 0.410734 10.8146C0.254454 10.6583 0.166656 10.4463 0.166656 10.2253C0.166656 10.0043 0.254454 9.79236 0.410734 9.63608C0.567014 9.4798 0.778976 9.392 0.99999 9.392H15C15.221 9.392 15.433 9.4798 15.5892 9.63608C15.7455 9.79236 15.8333 10.0043 15.8333 10.2253C15.8333 10.4463 15.7455 10.6583 15.5892 10.8146C15.433 10.9709 15.221 11.0587 15 11.0587Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M9.90866 15.514H0.99999C0.778976 15.514 0.567014 15.4262 0.410734 15.2699C0.254454 15.1136 0.166656 14.9017 0.166656 14.6807C0.166656 14.4597 0.254454 14.2477 0.410734 14.0914C0.567014 13.9351 0.778976 13.8473 0.99999 13.8473H9.90866C10.1297 13.8473 10.3416 13.9351 10.4979 14.0914C10.6542 14.2477 10.742 14.4597 10.742 14.6807C10.742 14.9017 10.6542 15.1136 10.4979 15.2699C10.3416 15.4262 10.1297 15.514 9.90866 15.514Z\"/>\n<path d=\"M15 2.15H0.99999C0.778976 2.15 0.567014 2.06221 0.410734 1.90593C0.254454 1.74965 0.166656 1.53768 0.166656 1.31667C0.166656 1.09566 0.254454 0.883695 0.410734 0.727415C0.567014 0.571135 0.778976 0.483337 0.99999 0.483337H15C15.221 0.483337 15.433 0.571135 15.5892 0.727415C15.7455 0.883695 15.8333 1.09566 15.8333 1.31667C15.8333 1.53768 15.7455 1.74965 15.5892 1.90593C15.433 2.06221 15.221 2.15 15 2.15Z\"/>\n<path d=\"M15 6.60467H0.99999C0.778976 6.60467 0.567014 6.51687 0.410734 6.36059C0.254454 6.20431 0.166656 5.99235 0.166656 5.77134C0.166656 5.55032 0.254454 5.33836 0.410734 5.18208C0.567014 5.0258 0.778976 4.938 0.99999 4.938H15C15.221 4.938 15.433 5.0258 15.5892 5.18208C15.7455 5.33836 15.8333 5.55032 15.8333 5.77134C15.8333 5.99235 15.7455 6.20431 15.5892 6.36059C15.433 6.51687 15.221 6.60467 15 6.60467Z\"/>\n<path d=\"M15 11.0587H0.99999C0.778976 11.0587 0.567014 10.9709 0.410734 10.8146C0.254454 10.6583 0.166656 10.4463 0.166656 10.2253C0.166656 10.0043 0.254454 9.79236 0.410734 9.63608C0.567014 9.4798 0.778976 9.392 0.99999 9.392H15C15.221 9.392 15.433 9.4798 15.5892 9.63608C15.7455 9.79236 15.8333 10.0043 15.8333 10.2253C15.8333 10.4463 15.7455 10.6583 15.5892 10.8146C15.433 10.9709 15.221 11.0587 15 11.0587Z\"/>\n</svg>\n";
export default src;
