/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { pick } from 'lodash'
import React from 'react'
import {
  Link,
  unstable_usePrompt as usePrompt,
  useSearchParams
} from 'react-router-dom'

import { LoadingPage } from '../../components/loading'
import { GLaDOS, PortalBlue } from '../../components/portals'
import { GraphQLError as Error } from '../../components/system-error'
import { useTileTransition } from '../../components/tile-transition'
import { useDocumentTitle } from '../../components/use-document-title'
import { useIds } from '../../components/use-ids'
import { formbot } from '../../formbot'
import { utils as fb } from '../../formbot/engine/formbot'
import * as Icons from '../../icons'
import Toggle from '../../ui/toggle'
import AnimatedOutlet from '../app-modals-outlet'
import FormDND from './dnd'
import FormPreview from './form-preview'
import ShareButton from './share-button'
import { useManageTemplate } from './use-manage-template'

const getReferencesQuery = (id, formKey) => ({
  variables: { id, formKey },
  query: gql`
    query GetWorkflowReferences($id: ID!, $formKey: String!) {
      workflowReferences(args: { definitionId: $id, formKey: $formKey })
    }
  `
})
const useFindReferences = workflowId => {
  const client = useApolloClient()
  return async formKey =>
    client
      .query(getReferencesQuery(workflowId, formKey))
      .then(resp => resp.data.workflowReferences)
}

export default function Forms ({ isProduct, isTable }) {
  useDocumentTitle('Form')
  const { appId, datasetId } = useIds()
  const { finish } = useTileTransition()
  const q = getFormPageQuery(appId, datasetId)
  const { loading, error, data, refetch: refetchForm } = useQuery(q.query, q)
  const docAccess = data?.app?.dataset?.conditionalPermissions ?? []
  const dataset = data?.app?.dataset
  const showDraft = data?.app?.dataset?.showDraft ?? false
  const schema = data?.app?.dataset?.form?.schema ?? []
  const gadgetIndexTypes = data?.app?.dataset?.formContainer?.gadgetIndexTypes
  React.useEffect(() => {
    if (!loading) finish()
  }, [loading])

  return (
    <>
      <AnimatedOutlet
        context={{
          dataset,
          showDraft,
          docAccess,
          schema,
          gadgetIndexTypes,
          appId,
          datasetId,
          appType: data?.app?.type,
          refetchForm
        }}
      />
      {error ? (
        <Error error={error} />
      ) : !data?.app ? (
        <LoadingPage />
      ) : (
        <FormsInner
          key={data.app.dataset.id}
          data={data}
          q={q}
          isProduct={isProduct}
          isTable={isTable}
        />
      )}
    </>
  )
}

function FormsInner ({ data, q, isProduct, isTable }) {
  const { appId, datasetId } = useIds()
  const [searchParams] = useSearchParams()
  const original = data?.app?.dataset?.form?.template
  const [preview, setPreview] = React.useState(false)
  const [{ template, loading, unsavedChanges }, update] = useManageTemplate(
    q,
    appId,
    datasetId,
    original
  )

  usePrompt({
    when: loading || unsavedChanges,
    message: i18n._('pagesbuilder.form.sure')
  })

  const findReferences = useFindReferences(
    data?.app?.dataset?.draft?.workflowId ?? data?.app?.dataset?.workflowId
  )
  const metaFields = data?.app?.dataset?.formContainer?.metaFields ?? []
  const integrationFields =
    data?.app?.dataset?.formContainer?.integrationFields ?? []
  const gadgetIndexTypes =
    data?.app?.dataset?.formContainer?.gadgetIndexTypes ?? {}
  const user = pick(data?.viewer?.user || {}, [
    'id',
    'username',
    'email',
    'schoolId',
    'displayName',
    'label'
  ])

  const showGadget = searchParams.get('showGadget')
  React.useEffect(() => {
    if (showGadget) setPreview(false)
  }, [showGadget])

  return (
    <GLaDOS>
      <div className='flex h-16 shrink-0 items-center justify-between border-b border-light-gray-300 print:hidden'>
        <div className='flex w-[350px] items-center px-3 max-[768px]:basis-[5%]'>
          <PortalBlue />
        </div>
        <Toggle
          className='!bg-blue-500 dark:!bg-blue-200'
          value={preview}
          onChange={() => setPreview(a => !a)}
          off='Design'
          on='Preview'
        />
        <div className='flex items-center gap-3 px-4'>
          <ShareButton
            transparent
            small
            query={q}
            isPublished={data?.app?.dataset?.isPublished}
            canAnonymousCreate={data?.app?.canAnonymousCreate}
            getSchema={() => {
              const schema = fb.structureToSchema(
                { metaFields, integrationFields: [], template },
                formbot
              )
              return fb.expandTableColumns(schema, formbot)
            }}
          />
          <Link className='kp-button-transparent kp-button-sm' to='settings'>
            <Icons.Settings className='mr-2 fill-blue-500' />
            <span>
              <Trans id='pagesbuilder.form.settings' />
            </span>
          </Link>
        </div>
      </div>
      {preview ? (
        <FormPreview
          user={user}
          template={template}
          metaFields={metaFields}
          integrationFields={integrationFields}
          helpLink={data?.app?.helpLink}
          labelSize={data?.app?.dataset?.labelSize}
          paginate={data?.app?.dataset?.paginated}
          branding={data?.app?.branding}
        />
      ) : (
        <FormDND
          isProduct={isProduct}
          isTable={isTable}
          canAnonymousCreate={data?.app?.canAnonymousCreate}
          gadgetIndexTypes={gadgetIndexTypes}
          user={user}
          template={template}
          metaFields={metaFields}
          integrationFields={integrationFields}
          labelSize={data?.app?.dataset?.labelSize}
          update={update}
          findReferences={findReferences}
          preview={preview}
        />
      )}
    </GLaDOS>
  )
}

const getFormPageQuery = (appId, pageId) => ({
  variables: { appId, pageId },
  fetchPolicy: 'cache-and-network',
  query: gql`
    query FormPageQuery($appId: ID!, $pageId: ID) {
      viewer {
        id
        user {
          id
          schoolId
          username
          email
          displayName
          label: displayName
          canManageSettings
        }
      }
      app(id: $appId, isConfiguration: true) {
        id
        type
        hasDraft
        canAnonymousCreate
        helpLink
        branding {
          id
          color
          logo
          maxHeight
          alt
        }
        dataset(id: $pageId) {
          id
          labelSize
          isPublished
          paginated
          showDraft
          allowNewVersions
          conditionalPermissionJobStatus {
            id
            status
          }
          conditionalPermissions {
            id
            formKey
            description
            allowVersionCreation
            identity {
              type
              roles {
                roleId
                categoryId
                label
              }
            }
            access {
              type
              states {
                draft
                inprogress
                complete
              }
            }
          }
          formContainer {
            id
            gadgetIndexTypes(includeTableColumns: true)
            metaFields {
              id
              formKey
              type
              label
              details
            }
            integrationFields {
              id
              formKey
              type
              label
              details
            }
          }
          form: formVersion {
            id
            template
            schema {
              id
              formKey
              label
              type
              details
              customFormKey {
                enabled
                value
              }
              customName {
                enabled
                value
              }

              childrenTemplate {
                id
                formKey
                label
                type
                details
                customFormKey {
                  enabled
                  value
                }
                customName {
                  enabled
                  value
                }
              }
            }
          }
          workflowId
          draft {
            workflowId
          }
        }
      }
    }
  `
})
