/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { reject, toPairs } from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import ColumnsButton from '../../components/data-table/columns-button'
import * as Empty from '../../components/data-table/empty'
import Header from '../../components/data-table/header'
import { WithoutTotalPagination } from '../../components/data-table/pagination'
import Pills from '../../components/data-table/pills'
import Row from '../../components/data-table/row'
import SearchBar from '../../components/data-table/search-bar'
import SortButton from '../../components/data-table/sort-button'
import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import Spinner from '../../components/spinner'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import { Announcer } from '../../ui/a11y'
import Button from '../../ui/button'
import { Table, TableBody } from '../../ui/table'
import LogDialog from './components/log-dialog'
import { COMPONENTS, EVENT_TYPES } from './consts'

export default function AuditLogs () {
  return (
    <ModalPage title={i18n._('history')}>
      <AuditLogsInner />
    </ModalPage>
  )
}

function AuditLogsInner () {
  const [selectedLog, setSelectedLog] = React.useState(null)
  const [params, updateParams] = useImmer(defaultParams)
  const reset = React.useCallback(() => {
    updateParams(() => defaultParams)
  }, [updateParams])
  const { logs, loading, error, queryInput, setQueryInput, pageInfo } =
    useAuditLogs(params)
  if (!logs || error) {
    return loading ? <Loading /> : <Error error={error} />
  }
  const visibleColumns = params.columns.filter(column => column.visible)
  return (
    <>
      <TransitionGroup>
        <CSSTransition key={selectedLog} timeout={450}>
          {selectedLog ? (
            <LogDialog log={selectedLog} onClose={() => setSelectedLog(null)} />
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
      <Wrapper className='text-sm'>
        <TopSection filtersExist>
          <SearchBar
            collapse={700}
            value={queryInput}
            onChange={setQueryInput}
            reset={() => {
              setQueryInput('')
              updateParams(draft => {
                draft.skip = 0
                draft.q = null
              })
            }}
            onBlur={() => {
              updateParams(draft => {
                draft.skip = 0
                draft.q = queryInput
              })
            }}
            onEnter={() => {
              updateParams(draft => {
                draft.skip = 0
                draft.q = queryInput
              })
            }}
          />
          <Buttons>
            <ButtonGrouping>
              <ColumnsButton
                value={params.columns}
                update={updateParams}
                height={280}
              />
              <SortButton
                columns={params.columns}
                value={params.sorts}
                update={updateParams}
              />
              <Button
                transparent
                small
                onClick={() => {
                  setQueryInput('')
                  reset()
                }}
              >
                <Trans id='reset' />
              </Button>
              {loading && <Spinner size={30} />}
            </ButtonGrouping>
          </Buttons>
        </TopSection>
        <Pills
          columns={params.columns}
          value={params.filters}
          remove={field => {
            updateParams(draft => {
              draft.filters = reject(draft.filters, { field })
            })
          }}
        />
        <TableWrapper>
          <Table>
            <Header
              params={params}
              updateParams={updateParams}
              columns={visibleColumns}
            />
            <TableBody>
              {logs.map(log => (
                <Row
                  key={log.id}
                  document={log}
                  columns={visibleColumns}
                  data-testid={log.id}
                  onRowClick={setSelectedLog}
                />
              ))}
            </TableBody>
            {logs.length !== 0 && (
              <WithoutTotalPagination
                onUpdate={({ skip, limit }) => {
                  updateParams(draft => {
                    draft.skip = skip
                    draft.limit = limit
                  })
                }}
                hasNextPage={pageInfo.hasNextPage}
                hasPreviousPage={pageInfo.hasPreviousPage}
                rangeDisplay={i18n._('pagination.range.display', {
                  from: params.skip + 1,
                  to: params.skip + (loading ? params.limit : logs.length)
                })}
                length={loading ? params.limit : logs.length}
                skip={params.skip}
                limit={params.limit}
              />
            )}
            <Announcer id='document-list-pagination'>
              {getPaginationString(params.skip, logs.length)}
            </Announcer>
          </Table>
          {logs.length === 0 &&
            (params.q || params.filters.length ? (
              <Empty.NoMatching
                reset={() => {
                  setQueryInput('')
                  updateParams(draft => {
                    draft.q = null
                    draft.filters = []
                  })
                }}
              />
            ) : (
              <Empty.NoResults />
            ))}
        </TableWrapper>
      </Wrapper>
    </>
  )
}

const auditLogsQuery = gql`
  query SystemAuditLogs(
    $q: String
    $skip: Int!
    $limit: Int!
    $sort: [String!]
    $filter: AuditLogFilter
    $newSearch: Boolean
  ) {
    auditLogsConnection(
      args: { q: $q, skip: $skip, limit: $limit, sort: $sort, filter: $filter }
      newSearch: $newSearch
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        skip
        limit
      }
      edges {
        node {
          id
          timestamp
          user {
            id
            displayName
            label
            email
            username
          }
          component
          componentId
          contextualComponents {
            component
            componentId
          }
          eventType
        }
      }
    }
  }
`

const Wrapper = styled.div`
  padding: 16px;
`

const TableWrapper = styled.div`
  min-height: calc(100vh - 180px);
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
`

const TopSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  @media (max-width: 700px) {
    display: block;
  }
`

const Buttons = styled.div`
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const ButtonGrouping = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`

const getPaginationString = (start, length) => {
  return i18n._('pagesbuilder.doclist.count.documents', {
    start: start + 1,
    end: start + length
  })
}

const defaultColumns = [
  {
    type: 'Timestamp',
    formKey: 'timestamp',
    label: <Trans id='date.and.time' />,
    details: {},
    visible: true
  },
  {
    type: 'UserTypeahead',
    formKey: 'user',
    label: <Trans id='user' />,
    details: {},
    unsortable: true,
    visible: true
  },
  {
    type: 'App',
    formKey: 'application',
    label: <Trans id='document.type' />,
    details: {},
    unsortable: true,
    visible: true
  },
  {
    type: 'Dropdown',
    formKey: 'component',
    label: <Trans id='component' />,
    details: {
      options: toPairs(COMPONENTS).map(([key, lbl]) => ({ key, lbl }))
    },
    visible: true
  },
  {
    type: 'Dropdown',
    formKey: 'eventType',
    label: <Trans id='event.type' />,
    details: {
      options: toPairs(EVENT_TYPES).map(([key, lbl]) => ({ key, lbl }))
    },
    visible: true
  }
]

const defaultParams = {
  skip: 0,
  limit: 25,
  q: undefined,
  filters: [],
  sorts: [{ ascending: true, field: 'timestamp', id: 'a1' }], // TODO this ascending is backwards because of the header component
  columns: defaultColumns
}

function useAuditLogs (params) {
  const graphqlVariables = React.useMemo(() => {
    const filter = params.filters.reduce((filter, field) => {
      if (field.field === 'timestamp') {
        filter.timestamp = { min: field.min, max: field.max }
      } else if (field.field === 'application') {
        filter.contextualComponents = (
          filter.contextualComponents || []
        ).concat(field.values.map(app => app.id))
      } else {
        filter[field.field] = (filter[field.field] || []).concat(field.values)
      }
      return filter
    }, {})
    const sort = params.sorts
      .filter(({ field }) => field)
      .map(({ ascending, field }) => {
        const prefix = ascending ? '-' : '' // TODO this ascending is backwards because of the header component
        return `${prefix}${field}`
      })
    return {
      limit: params.limit,
      skip: params.skip,
      filter,
      sort,
      q: params.q
    }
  }, [params])
  const [queryInput, setQueryInput] = React.useState()
  const { data, loading, error } = useQuery({
    query: auditLogsQuery,
    variables: graphqlVariables,
    fetchPolicy: 'network-only'
  })
  const logs = React.useMemo(() => {
    if (!data || !data.auditLogsConnection) return
    return data.auditLogsConnection.edges.map(({ node }) => mapLog(node))
  }, [data])
  return {
    logs,
    pageInfo: data?.auditLogsConnection?.pageInfo ?? {},
    loading,
    error,
    queryInput,
    setQueryInput
  }
}

function mapLog (log) {
  const appComponent = log.contextualComponents.find(
    ({ component }) => component === 'APPS'
  )
  return {
    ...log,
    application: appComponent && {
      id: appComponent.componentId
    }
  }
}
