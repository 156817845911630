/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { Tooltip, TooltipTrigger } from '../../components/new-tooltip'
import * as Icons from '../../icons'

export function Help ({ children }) {
  return (
    <TooltipTrigger className='relative'>
      <Icons.AlertHelp />
      <Tooltip className='w-60' position='right'>
        {children}
      </Tooltip>
    </TooltipTrigger>
  )
}

export function Label ({ children }) {
  return (
    <label className='flex items-center gap-1 text-sm font-medium text-dark-gray-300'>
      {children}
    </label>
  )
}

export function GadgetDropdown ({ gadgets, className, value, onChange }) {
  return (
    <select
      required
      className={cx('kp-select', className)}
      disabled={!gadgets.length}
      value={value}
      onChange={e => onChange(e.target.value)}
    >
      <option value=''>
        {!gadgets.length && i18n._('pagesbuilder.dashboard.select.dataset')}
      </option>

      <optgroup label={i18n._('pagesbuilder.dashboard.meta.gadgets')}>
        {_.filter(gadgets, g => g.formKey.startsWith('meta.')).map(g => (
          <option key={g.formKey} value={g.formKey}>
            {g.label}
          </option>
        ))}
      </optgroup>
      <optgroup label={i18n._('pagesbuilder.dashboard.data.gadgets')}>
        {_.filter(gadgets, g => g.formKey.startsWith('data.')).map(g => (
          <option key={g.formKey} value={g.formKey}>
            {g.label}
          </option>
        ))}
      </optgroup>
    </select>
  )
}

export function AggregationDropdown ({ value, onChange }) {
  return (
    <div>
      <Label>
        <Trans id='pagesbuilder.dashboard.aggregation' />
      </Label>
      <select
        className='kp-select'
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        <option value='count'>
          <Trans id='pagesbuilder.dashboard.count' />
        </option>
        <option value='min'>
          <Trans id='pagesbuilder.dashboard.min' />
        </option>
        <option value='max'>
          <Trans id='pagesbuilder.dashboard.max' />
        </option>
        <option value='avg'>
          <Trans id='pagesbuilder.dashboard.average' />
        </option>
        <option value='sum'>
          <Trans id='pagesbuilder.dashboard.sum' />
        </option>
      </select>
    </div>
  )
}
