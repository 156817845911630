/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateAppFavoriteMutation = spaceId => {
  const [updateAppFavorite] = useMutation(mutation)
  return (applicationId, isFavorite) => {
    return updateAppFavorite(getParams(applicationId, isFavorite, spaceId))
  }
}

const mutation = gql`
  mutation UpdateAppFavorite($applicationId: ID!, $isFavorite: Boolean!) {
    updateAppFavorite(applicationId: $applicationId, isFavorite: $isFavorite) {
      id
      isFavorite
    }
  }
`

const getParams = (applicationId, isFavorite, spaceId) => ({
  variables: { applicationId, isFavorite },
  update: (cache, { data: { data } }) => {
    if (spaceId !== 'favorites' || isFavorite) return
    cache.modify({
      id: 'SpacePortal:favorites',
      fields: {
        apps (apps = []) {
          return [...apps].filter(a => a.__ref !== `App:${applicationId}`)
        }
      }
    })
  }
})
