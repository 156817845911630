/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const usePublishAppMutation = (appId, pageId) => {
  const [publishApp] = useMutation(mutation)
  return showDraft => publishApp(getParams(appId, pageId, showDraft))
}

const mutation = gql`
  mutation PublishApp($id: ID!, $pageId: ID, $showDraft: Boolean) {
    publishApp(args: { id: $id, pageId: $pageId, showDraft: $showDraft }) {
      id
      hasDraft
      dataset(id: $pageId) {
        id
        hasDraft
        showDraft
        isPublished
        formVersion {
          id
          template
        }
        draft {
          changes {
            id
            action
            type
            descriptor
            descriptorId
          }
        }
      }
    }
  }
`

const getParams = (id, pageId, showDraft) => ({
  variables: { id, pageId, showDraft }
})
