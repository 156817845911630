/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { find, get, includes, invert, isEmpty, map } from 'lodash'
import React from 'react'

import { convertTruthyKeys } from './utils'

export function check (data = [], config) {
  const correctedData = convertTruthyKeys(data)
  switch (config.type) {
    case 'isChecked':
      return !!find(correctedData, val => val.id === config.value)
    case 'isNotChecked':
      return !find(correctedData, val => val.id === config.value)
    case 'numChecked':
      return correctedData.length >= config.value
    case 'numNotChecked':
      return correctedData.length < config.value
    case 'isEmpty':
      return isEmpty(correctedData)
    case 'isNotEmpty':
      return !isEmpty(correctedData)
    default:
      return true
  }
}

export function configIsValid (config) {
  if (includes(['isEmpty', 'isNotEmpty'], config.type)) return true
  return config.type && config.value
}

export function Suffix ({ details, type, onChange, value }) {
  if (!type) return null
  if (includes(['isChecked', 'isNotChecked'], type)) {
    const options = get(details, 'options', [])
    return (
      <select
        className='kp-select flex-1'
        data-testid='pd-operand'
        onChange={e => onChange({ ...value, value: e.target.value })}
        value={get(value, 'value', '')}
        aria-label='operand'
      >
        <option value=''>- - -</option>
        {map(options, ({ key, lbl }) => (
          <option key={key} value={key}>
            {lbl}
          </option>
        ))}
      </select>
    )
  }
  if (includes(['numChecked', 'numNotChecked'], type)) {
    return (
      <input
        className='kp-input flex-1'
        data-testid='pd-operand'
        onChange={e => onChange({ ...value, value: e.target.value })}
        type='number'
        value={value.value || ''}
      />
    )
  }
  return null
}

export function component (props) {
  const type = get(props.value, 'type', '')
  return (
    <div className='flex items-center gap-2'>
      <select
        className='kp-select flex-1'
        data-testid='pd-operator'
        aria-label='operator'
        onChange={e => props.onChange({ ...props.value, type: e.target.value })}
        value={type}
      >
        <option value=''>- - -</option>
        <option value='isChecked'>
          <Trans id='has.checked' />
        </option>
        <option value='isNotChecked'>
          <Trans id='has.not.checked' />
        </option>
        <option value='numChecked'>
          <Trans id='has.checked.at.least' />
        </option>
        <option value='numNotChecked'>
          <Trans id='has.checked.less.than' />
        </option>
        <option value='isEmpty'>
          <Trans id='is.empty' />
        </option>
        <option value='isNotEmpty'>
          <Trans id='is.not.empty' />
        </option>
      </select>
      <Suffix {...props} type={type} />
    </div>
  )
}

const hasValue = type => type && !includes(['isEmpty', 'isNotEmpty'], type)

const uiToWf = {
  isChecked: 'contains',
  isNotChecked: 'does not contain',
  numChecked: 'has checked at least',
  numNotChecked: 'has checked less than',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  const newExpr = {
    left: {
      dataType: 'option-list',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
  if (hasValue(expr.data.type)) {
    if (includes(['numChecked', 'numNotChecked'], expr.data.type)) {
      newExpr.right = {
        dataType: 'number',
        value: expr.data.value
      }
    } else {
      const options = get(gadget, 'details.options', [])
      const option = find(options, { key: expr.data.value }) || {}
      const label = option.lbl || ''
      newExpr.right = {
        dataType: 'option',
        value: { id: expr.data.value, label }
      }
    }
  }
  return newExpr
}

export function fromWorkflowFormat (expr) {
  const newExpr = {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
  if (hasValue(newExpr.data.type)) {
    newExpr.data.value = get(expr.right.value, 'id', expr.right.value)
  }
  return newExpr
}
