/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useRefetchQueries } from './use-refetch-queries'

export const useWithdrawMutation = query => {
  const [withdraw] = useMutation(mutation)
  const refetchQueries = useRefetchQueries(['ActionPage'])
  return (documentId, timezone) =>
    withdraw({ variables: { id: documentId, timezone }, refetchQueries })
}

const mutation = gql`
  mutation withDrawSubmission($id: ID!, $timezone: String!) {
    withdrawSubmission(args: { id: $id, timezone: $timezone }) {
      __typename
      ... on Success {
        success
      }
      ... on Error {
        reason
      }
    }
  }
`
