/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isEmpty, set } from 'lodash'
import React from 'react'
import { Link, Navigate, useOutletContext } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { ModalPage } from '../../components/modal-page'
import Formbot, { validate } from '../../formbot'
import { Dashboard } from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Button from '../../ui/button'
import { Flex, Space } from '../../ui/layout'
import { FORM_STATUSES } from './components/constants'
import { useCreateIntegrationMutation } from './components/mutation.create-integration'
import SaveButton from './components/save-button'
import TestResult from './components/test-result'
import {
  setURLInputs,
  updateTemplate,
  useWindowWidth
} from './components/utils'

export default function IntegrationNew () {
  const { spaceId } = useOutletContext()
  return (
    <ModalPage title={i18n._('new.integration')}>
      <IntegrationNewInner spaceId={spaceId} />
    </ModalPage>
  )
}

function IntegrationNewInner ({ spaceId }) {
  const alerts = useAlerts()
  const width = useWindowWidth()
  const createIntegration = useCreateIntegrationMutation(spaceId)
  const [status, setStatus] = React.useState('')
  const [showTestResult, setShowTestResult] = React.useState(false)
  const [data, updateData] = useImmer({})
  const [shouldValidate, setShouldValidate] = React.useState(false)
  const { data: resp, error } = useQuery(templateQuery)
  const rawTemplate = resp?.integrationTemplate?.template ?? {}
  const structure = React.useMemo(() => {
    if (!rawTemplate || !data) return null
    return {
      template: updateTemplate(rawTemplate, data),
      metaFields: [],
      integrationFields: [],
      trashed: []
    }
  }, [data, rawTemplate])
  if (error) {
    console.error(error)
    return <div>Unexpected error trying to create integration</div>
  }
  if (isEmpty(structure?.template)) return <p>Loading...</p>
  if (status === FORM_STATUSES.SAVED) {
    return <Navigate to='..' />
  }

  const update = (path, value) => {
    updateData(draft => {
      if (path === '__url') {
        draft.__urlInputs = setURLInputs(value, draft.__urlInputs || [])
      }
      set(draft, path, value)
    })
    setStatus(FORM_STATUSES.UNTESTED) // This needs to come after updateData
  }

  const save = () => {
    setShouldValidate(true)
    const validations = validate({ data }, structure)
    if (!isEmpty(validations)) return
    setStatus(FORM_STATUSES.SAVING)
    createIntegration(data).then(() => {
      alerts.type3(i18n._('integration.saved'), 'success')
      setStatus(FORM_STATUSES.SAVED)
    })
  }

  const type = data?.__type?.id
  const canTest =
    (type === 'fetch' || type === 'fetchOne') &&
    data?.__method?.id &&
    data?.__url

  const validations = shouldValidate ? validate({ data }, structure) : {}
  return (
    <IntegrationWrapper className='text-sm'>
      <div />
      <FormbotWrapper>
        <Formbot.Edit
          document={{ data }}
          structure={structure}
          onChange={update}
          context={{
            validations,
            warnings: [
              {
                shouldShow: (template, value) => {
                  if (template.type !== 'Text') return
                  if (template.formKey === '__name') return
                  if (template.formKey.endsWith('.__label')) return
                  const val = value ?? data[template.formKey] ?? ''
                  return val !== val.trim()
                },
                message: <Trans id='field.leading.trailing.whitespace' />
              }
            ]
          }}
          noGrid
        />
        <br />
        <Flex>
          {canTest && (
            <Button outline onClick={() => setShowTestResult(a => !a)}>
              <Dashboard className='fill-blue-500' mr={2} />
              <span>
                {showTestResult ? (
                  <Trans id='stop.test' />
                ) : (
                  <Trans id='run.test' />
                )}
              </span>
            </Button>
          )}
          <Space expand />
          <Button outline as={Link} to='..' mr={2}>
            <Trans id='cancel' />
          </Button>
          <SaveButton
            save={save}
            status={status}
            validations={validations}
            canTest={canTest}
          />
        </Flex>
      </FormbotWrapper>
      <TransitionGroup>
        <CSSTransition key={showTestResult} timeout={450}>
          {showTestResult ? (
            <TestResult
              integration={data}
              onClose={() => setShowTestResult(false)}
              width={width}
              updateTestResult={passed => {
                setStatus(
                  passed ? FORM_STATUSES.TESTED : FORM_STATUSES.UNTESTED
                )
              }}
            />
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
      {showTestResult && width >= 1100 && <div />}
    </IntegrationWrapper>
  )
}

const templateQuery = gql`
  query LoadIntegrationTemplate {
    integrationTemplate {
      id
      template
    }
  }
`

const FormbotWrapper = styled.div`
  max-width: 540px !important;
  min-width: 320px;
  padding: 16px;
  height: 100%;
  overflow: auto;
  & .formbot-gadget {
    padding-bottom: 40px;
  }
  & .formbot-gadget .formbot-gadget {
    padding: 0 16px;
  }
`

const IntegrationWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
`
