/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default React.forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      type='checkbox'
      className={cx(
        'kp-checkbox border-light-gray-500 checked:bg-blue-400',
        className
      )}
      {...props}
    />
  )
})
