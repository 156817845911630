/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { cloneDeep, set } from 'lodash'
import React from 'react'

const refreshMutation = gql`
  mutation refreshSimulation($appId: ID!, $documentId: ID!) {
    refreshSimulation(appId: $appId, documentId: $documentId)
  }
`
export function useRefreshSimulation (DocumentQueryContext) {
  const { query } = React.useContext(DocumentQueryContext)
  const [refreshSimulation, { error }] = useMutation(refreshMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.refreshSimulation)
    }
  })
  const refresh = async (appId, documentId, updateOverview) => {
    const variables = { appId, documentId }
    const resp = await refreshSimulation({ variables })
    updateOverview({ simulation: resp.data.refreshSimulation })
  }
  return [refresh, error]
}

export function updateSimulationInCache (cache, query, simulation) {
  if (!query || !simulation) return
  const rawData = cache.readQuery(query)
  const documentResults = cloneDeep(rawData)
  const cacheKey = documentResults?.action
    ? 'action.document.workflow.simulation'
    : 'document.workflow.simulation'
  set(documentResults, cacheKey, simulation)
  cache.writeQuery({ ...query, data: documentResults })
}
