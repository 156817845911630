import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import Spinner from '../../../components/spinner'
import { useSingleAlert } from '../../../components/use-single-alert'
import * as Icons from '../../../icons'
import { Popover2 } from '../../../ui/popover'
import ConditionForm from './condition-form'
import { useRemoveAppConditionalPermissionMutation } from './mutation.remove-app-conditional-permission'
import { useUpdateAppConditionalPermissionMutation } from './mutation.update-app-conditional-permission'

const ACCESS_TYPE_LABELS = {
  VIEW: 'View',
  EDIT: 'Edit'
}

export const DOC_ACCESS_LIMIT = 10

export default function TableComponent ({
  docAccess,
  schema,
  gadgetByFormKey,
  appId,
  datasetId,
  showDraft,
  addLoading,
  datasetAllowsVersions
}) {
  const [removeAppConditionalPermission] =
    useRemoveAppConditionalPermissionMutation(appId, datasetId)
  const closeAlert = useSingleAlert('type1')
  if (addLoading && docAccess.length < 1) {
    return (
      <div className='flex items-center justify-center'>
        <Spinner size={200} />
      </div>
    )
  }
  if (docAccess.length === 0) return null
  return (
    <>
      <table className='kp-table mt-2 text-sm'>
        <thead>
          <tr>
            <th>
              <Trans id='pagesbuilder.form.perm.table.form.field' />
            </th>
            <th>
              <Trans id='pagesbuilder.form.perm.table.who' />
            </th>
            <th>
              <Trans id='permissions' />
            </th>
            <th>
              <Trans id='pagesbuilder.form.perm.table.description' />
            </th>
            <th>
              <Trans id='pagesbuilder.form.perm.table.actions' />
            </th>
          </tr>
        </thead>
        <tbody>
          {docAccess.map(permission => {
            return (
              <PermissionRow
                key={permission?.id}
                permission={permission}
                schema={schema}
                gadgetByFormKey={gadgetByFormKey}
                appId={appId}
                datasetId={datasetId}
                remove={removeAppConditionalPermission}
                showDraft={showDraft}
                closeAlert={closeAlert}
                datasetAllowsVersions={datasetAllowsVersions}
              />
            )
          })}
        </tbody>
      </table>

      <div className='flex w-full items-end justify-end'>
        {docAccess.length}/{DOC_ACCESS_LIMIT}{' '}
        <Trans id='pagesbuilder.form.perm.table.conditions.used' />
      </div>
    </>
  )
}

function PermissionRow ({
  permission,
  gadgetByFormKey,
  appId,
  datasetId,
  schema,
  showDraft,
  remove,
  closeAlert,
  datasetAllowsVersions
}) {
  const [updateAppConditionalPermission, { loading }] =
    useUpdateAppConditionalPermissionMutation(appId, datasetId, permission.id)

  const deleteButton = close => {
    return (
      <button
        className='kp-button-solid'
        onClick={() => {
          remove(permission.id)
          close()
        }}
      >
        <Trans id='pagesbuilder.form.perm.table.continue' />
      </button>
    )
  }

  const { states } = permission.access
  return (
    <tr>
      <td>
        {gadgetByFormKey[permission.formKey]?.label ?? (
          <div className='kp-badge'>
            <Trans id='pagesbuilder.form.perm.table.gadget.not' />
          </div>
        )}
      </td>
      <td>
        {permission.identity.type === 'PERSON'
          ? 'Selected person'
          : permission.identity.type === 'ROLE'
            ? permission.identity.roles.map(role => (
                <p key={role.categoryId + role.roleId}>{role.label}</p>
              ))
            : ''}
      </td>
      <td>
        <div className='flex items-center justify-start gap-2'>
          <span className='w-10 font-bold'>
            {ACCESS_TYPE_LABELS[permission.access.type]}:
          </span>
          {showDraft && states.draft && (
            <span className='kp-badge bg-orange-200 px-2 py-0.5 text-dark-gray-500 dark:text-light-gray-100'>
              <Trans id='pagesbuilder.form.perm.table.draft' />
            </span>
          )}
          {states.inprogress && (
            <span className='kp-badge bg-yellow-200 px-2 py-0.5 text-dark-gray-500 dark:text-light-gray-100'>
              <Trans id='pagesbuilder.form.perm.table.progress' />
            </span>
          )}
          {states.complete && (
            <span className='kp-badge bg-green-200 px-2 py-0.5 text-dark-gray-500 dark:text-light-gray-100'>
              <Trans id='pagesbuilder.form.perm.table.complete' />
            </span>
          )}
        </div>
      </td>
      <td>
        <p
          className='max-w-xs overflow-hidden overflow-ellipsis'
          title={permission.description}
        >
          {permission.description}
        </p>
      </td>
      <td>
        <div className='flex gap-1'>
          <Popover2
            trigger={
              <button className='kp-button-transparent kp-button-icon kp-button-sm'>
                <Icons.Edit />
              </button>
            }
            className={cx('relative', 'self-center')}
          >
            {hide => (
              <ConditionForm
                schema={schema}
                gadgetByFormKey={gadgetByFormKey}
                onCancel={hide}
                onSave={updateAppConditionalPermission}
                saving={loading}
                showDraft={showDraft}
                close={hide}
                value={permission}
                datasetAllowsVersions={datasetAllowsVersions}
              />
            )}
          </Popover2>
          <button
            className='kp-button-transparent kp-button-sm kp-button-icon'
            onClick={() => {
              closeAlert(
                i18n._('pagesbuilder.form.perm.table.revoke'),
                i18n._('pagesbuilder.form.perm.table.revoke.data'),
                'error',
                deleteButton
              )
            }}
          >
            <Icons.Delete />
          </button>
        </div>
      </td>
    </tr>
  )
}
