/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { find, get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { gadgets } from '../../formbot'
import * as Icons from '../../icons'
import { VisuallyHidden } from '../../ui/a11y'
import Button from '../../ui/button'

const PillWrapper = styled(Button)`
  border-radius: 100px;
  [data-whatinput='keyboard'] &:focus::after {
    border-radius: 100px !important;
  }
`

const PillInner = ({ label, filter, gadget }) => {
  if (!gadget) return <span>DELETED</span>
  const str = map(filter.values, entry => JSON.stringify(entry)).join(', ')
  return (
    <span>
      {label}: {str}
    </span>
  )
}

const Pill = ({ columns, filter, remove }) => {
  const column = find(columns, { formKey: filter.field })
  const InnerPill =
    get(gadgets, [get(column, 'type'), 'filters', 'Pill']) || PillInner
  return (
    <PillWrapper outline data-active pl={3} mr={2} mb={2} onClick={remove}>
      <VisuallyHidden>Remove Filter: </VisuallyHidden>
      <InnerPill label={get(column, 'label')} filter={filter} gadget={column} />
      <Icons.Close ml={2} width={8} height={8} className='fill-blue-500' />
    </PillWrapper>
  )
}

const PillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
`

export default ({ columns, value, remove }) => (
  <PillsWrapper>
    {map(value, filter => (
      <Pill
        key={filter.field}
        columns={columns}
        filter={filter}
        remove={() => remove(filter.field)}
      />
    ))}
  </PillsWrapper>
)
