/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M5.41421 9C4.52331 9 4.07714 10.0771 4.70711 10.7071L7.29289 13.2929C7.68342 13.6834 8.31658 13.6834 8.70711 13.2929L11.2929 10.7071C11.9229 10.0771 11.4767 9 10.5858 9H5.41421Z`}/><path d={`M10.5858 7C11.4767 7 11.9229 5.92286 11.2929 5.29289L8.70711 2.70711C8.31658 2.31658 7.68342 2.31658 7.29289 2.70711L4.7071 5.2929C4.07714 5.92286 4.52331 7 5.41421 7L10.5858 7Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M5.41421 9C4.52331 9 4.07714 10.0771 4.70711 10.7071L7.29289 13.2929C7.68342 13.6834 8.31658 13.6834 8.70711 13.2929L11.2929 10.7071C11.9229 10.0771 11.4767 9 10.5858 9H5.41421Z\" />\n<path d=\"M10.5858 7C11.4767 7 11.9229 5.92286 11.2929 5.29289L8.70711 2.70711C8.31658 2.31658 7.68342 2.31658 7.29289 2.70711L4.7071 5.2929C4.07714 5.92286 4.52331 7 5.41421 7L10.5858 7Z\" />\n</svg>\n";
export default src;
