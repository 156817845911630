/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { debounce } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'

export default function ({ value, loading, onChange, setFocus, className }) {
  const debouncedChange = React.useMemo(
    () => debounce(onChange, 500),
    [onChange]
  )
  const handleKeyDown = e => {
    // Down
    if (e.keyCode === 40) {
      e.preventDefault()
      setFocus(0)
    }
  }
  return (
    <Wrapper className={className}>
      <SearchIconHolder className='dark:bg-transparent'>
        <Icons.Search />
      </SearchIconHolder>
      <Input
        className='text-dark-gray-100 dark:bg-light-gray-300 dark:text-black'
        autoFocus
        id='search-box'
        onChange={e => debouncedChange(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={i18n._('pages.old.search.search')}
        type='text'
      />
      {loading && <SearchSpinner size={20} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`

const SearchIconHolder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0.4rem 0 0 0.4rem;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-right: 0;
`

const Input = styled.input`
  flex: 1;
  border-radius: 0 0.4rem 0.4rem 0;
  padding: 6px 12px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`

const SearchSpinner = styled(Spinner)`
  position: absolute;
  right: 1rem;
`
