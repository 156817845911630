/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { findIndex } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as icons from '../../../components/icon-names'
import IconPicker from '../../../components/icon-picker'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import Input from '../../../ui/input'
import { Popover2 } from '../../../ui/popover'
import { Body1 } from '../../../ui/typography'
import * as appOptions from './tile-options'
import { useRover } from './use-rover'

export function TileEditor ({
  isProduct,
  label,
  value,
  onChange,
  onSave,
  onCopy,
  hasError,
  appId,
  appCreationDisabled,
  ...rest
}) {
  return (
    <MyPopover
      role='dialog'
      trigger={
        <MyButton
          icon
          transparent
          large
          aria-label={label}
          className='opacity-0 focus:opacity-100 group-hover:opacity-100 dark:hover:!bg-transparent'
        >
          <EditAppIcon />
        </MyButton>
      }
      {...rest}
    >
      {hide => (
        <TileEditorInner
          {...{
            isProduct,
            label,
            value,
            onChange,
            onCopy,
            onSave,
            hasError,
            hide,
            appId,
            appCreationDisabled
          }}
        />
      )}
    </MyPopover>
  )
}

const EditAppIcon = styled(Icons.EditApp)`
  width: 24px;
  height: 24px;
  fill: white;
  opacity: 0.6;
`

function TileEditorInner ({
  isProduct,
  label,
  value,
  onChange,
  onCopy,
  onSave,
  hasError,
  hide,
  appCreationDisabled
}) {
  const [saving, setSaving] = React.useState(false)
  const [copying, setCopying] = React.useState(false)
  const alerts = useAlerts()
  const [colorRef, colorProps] = useRover({
    width: 9,
    max: appOptions.colors.length,
    initial: () =>
      findIndex(appOptions.colors, color => color === value.backgroundColor),
    onClick: color => onChange(data => ({ ...data, backgroundColor: color }))
  })
  const name = isProduct ? <Trans id='product' /> : <Trans id='app' />
  const save = hide => async () => {
    if (saving) return
    setSaving(true)
    try {
      await onSave(hide)
    } catch (err) {}
    setSaving(false)
  }
  const copy = async () => {
    setCopying(true)
    try {
      await onCopy()
      hide()
    } catch (e) {
      const msg = <Trans id='error.duplicating.app' />
      alerts.type3(msg, 'error')
      console.error(msg, e)
    } finally {
      setCopying(false)
    }
  }

  return (
    <>
      <Wrapper>
        <Label id='app-name'>
          {name} <Trans id='name' />
        </Label>
        <AppNameInput
          aria-labelledby='app-name'
          error={hasError}
          value={value.name || ''}
          onChange={name => onChange(data => ({ ...data, name }))}
          onEnter={save(hide)}
        />
        {hasError && (
          <Error role='alert'>
            <Trans id='names.must.unique' values={name} />
          </Error>
        )}
        <Label>
          {isProduct ? (
            <Trans id='product.icon.svg.80' />
          ) : (
            <Trans id='app.icon' />
          )}
        </Label>
        {isProduct ? (
          <IconPicker
            icons={icons.product}
            value={value.iconName}
            onChange={iconName => onChange(data => ({ ...data, iconName }))}
            getSrc={name =>
              `${process.env.PUBLIC_URL}/icons-product/${name}.svg`
            }
          />
        ) : (
          <IconPicker
            icons={appOptions.icons}
            value={value.iconName}
            onChange={iconName => onChange(data => ({ ...data, iconName }))}
            getSrc={name => `${process.env.PUBLIC_URL}/icons/${name}`}
          />
        )}
        <Label id='app-color'>
          {name} <Trans id='color' />
        </Label>
        <ColorBox ref={colorRef}>
          {appOptions.colors.map((backgroundColor, i) => (
            <Color
              {...colorProps(i, backgroundColor)}
              key={backgroundColor}
              color={backgroundColor}
              aria-label={`hex color: ${backgroundColor}`}
            />
          ))}
        </ColorBox>
        <ColorInput
          aria-labelledby='app-color'
          value={value.backgroundColor || ''}
          onChange={backgroundColor =>
            onChange(data => ({ ...data, backgroundColor }))
          }
        />
      </Wrapper>
      <Buttons className='border-t border-light-gray-200 dark:border-light-gray-300'>
        <LeftButtons>
          {onCopy && !isProduct && !appCreationDisabled && (
            <Button small transparent onClick={copy} disabled={copying}>
              <Icons.Duplicate mr={2} className='fill-blue-500' />
              <Trans id='duplicate' message='Duplicate' />
            </Button>
          )}
        </LeftButtons>
        <RightButtons>
          <Button small transparent onClick={hide} disabled={saving}>
            <Trans id='cancel' />
          </Button>
          <Button disabled={!value.name || saving} small onClick={save(hide)}>
            {!label ? <Trans id='create' /> : <Trans id='save' />}
          </Button>
        </RightButtons>
      </Buttons>
    </>
  )
}

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`

const LeftButtons = styled.div``
const RightButtons = styled.div`
  > :first-child {
    margin-right: 8px;
  }
`

const MyPopover = styled(Popover2)`
  position: absolute;
  top: 80px;
  right: 5px;
`

const MyButton = styled(Button)`
  &:hover {
    background: none;
  }
  &:active {
    border-color: transparent;
  }
`

export const EditIcon = MyButton

const Wrapper = styled.div`
  width: 285px;
  padding: 16px;
  padding-top: 0;
`

const Label = styled(Body1)`
  display: block;
  padding-top: 16px;
`
Label.defaultProps = { as: 'label' }

const AppNameInput = styled(Input)`
  width: 100%;
`

const ColorBox = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 4px;
`

const Color = styled.button`
  background: ${p => p.color};
  border-radius: 2px;
  border: none;
  cursor: pointer;
  height: 24.5px;
  margin: 0;
`

const ColorInput = styled(Input)`
  border-radius: 2px;
  background: #eee;
  border: none;
  height: 24px;
  margin: 0;
  position: relative;
  bottom: 24px;
  right: -85px;
  width: 83px;
`

const Error = styled.div`
  color: #d22e2f;
`
