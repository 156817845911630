/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import Flume from '../../flume-editor'
import Button from '../../ui/button'
import Input from '../../ui/input'
import { Space } from '../../ui/layout'
import { useCreateAdvancedIntegrationMutation } from './components/mutation.create-advanced-integration'
import { useUpdateAdvancedIntegrationMutation } from './components/mutation.update-advanced-integration'
import TestResult from './components/test-result'

export function New () {
  const { spaceId } = useOutletContext()
  const [showTestResult, setShowTestResult] = React.useState(false)
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const navigate = useNavigate()
  const [nodes, setNodes] = React.useState({
    start: {
      id: 'start',
      x: -500,
      y: 0,
      type: 'inputs',
      width: 200,
      connections: { inputs: {}, outputs: {} },
      inputData: { inputs: { inputs: [] } }
    },
    end: {
      id: 'end',
      x: 0,
      y: 0,
      type: 'outputs',
      width: 200,
      connections: { inputs: {}, outputs: {} },
      inputData: { integrationType: { integrationType: '' } }
    }
  })
  const [comments, setComments] = React.useState({})
  const [createIntegration, { loading, error }] =
    useCreateAdvancedIntegrationMutation(spaceId)
  return (
    <ModalPage title={i18n._('new.advanced.integration')}>
      <Wrapper>
        <InTopBar>
          <Input value={name} onChange={setName} placeholder={i18n._('name')} />
          <Space w='16px' />
          <Input
            value={description}
            onChange={setDescription}
            placeholder={i18n._('description')}
          />
          <Space w='16px' />
          <Button onClick={() => setShowTestResult(true)}>Test</Button>
          <Space w='16px' />
          <Button
            disabled={loading}
            onClick={() =>
              createIntegration({
                name,
                description,
                definition: { nodes, comments }
              }).then(() => navigate('..'))
            }
          >
            <Trans id='save.and.close' />
          </Button>
        </InTopBar>
        {error && <Error error={error} />}
        <Flume.Editor
          portTypes={Flume.config.portTypes}
          nodeTypes={Flume.config.nodeTypes}
          nodes={nodes}
          onChange={setNodes}
          comments={comments}
          onCommentsChange={setComments}
          context={{ nodes }}
          ports={Flume.ports}
        />
        <TransitionGroup>
          <CSSTransition key={showTestResult} timeout={450}>
            {showTestResult ? (
              <TestResult
                width={0}
                id='ADVANCED:'
                integration={Flume.transform('', name, description, nodes)}
                onClose={() => setShowTestResult(false)}
                updateTestResult={() => null}
              />
            ) : (
              <span />
            )}
          </CSSTransition>
        </TransitionGroup>
      </Wrapper>
    </ModalPage>
  )
}

export function Edit () {
  const { id } = useOutletContext()
  const [showTestResult, setShowTestResult] = React.useState(false)
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [nodes, setNodes] = React.useState(null)
  const [comments, setComments] = React.useState({})
  const { loading, error } = useQuery(fetchAdvancedIntegrationQuery, {
    variables: { id },
    onCompleted: data => {
      setNodes(data.advancedIntegration.definition.nodes || {})
      setComments(data.advancedIntegration.definition.comments || {})
      setName(data.advancedIntegration.name)
      setDescription(data.advancedIntegration.description)
    }
  })
  const [updateIntegration, updateResult] =
    useUpdateAdvancedIntegrationMutation()
  return (
    <>
      <LeftTopBar>
        <Input value={name} onChange={setName} placeholder={i18n._('name')} />
        <Space w='16px' />
        <Input
          value={description}
          onChange={setDescription}
          placeholder={i18n._('description')}
        />
      </LeftTopBar>
      <RightTopBar>
        <Button onClick={() => setShowTestResult(true)}>Test</Button>
        <Space w='16px' />
        <Button
          disabled={updateResult.loading}
          onClick={() => {
            updateIntegration(id, {
              name,
              description,
              definition: { nodes, comments }
            })
          }}
        >
          <Trans id='save' />
        </Button>
      </RightTopBar>

      {loading || !nodes ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <>
          <Flume.Editor
            portTypes={Flume.config.portTypes}
            nodeTypes={Flume.config.nodeTypes}
            nodes={nodes}
            onChange={setNodes}
            comments={comments}
            onCommentsChange={setComments}
            context={{ nodes }}
            ports={Flume.ports}
          />
        </>
      )}
      <TransitionGroup>
        <CSSTransition key={showTestResult} timeout={450}>
          {showTestResult ? (
            <TestResult
              width={0}
              id={`ADVANCED:${id}`}
              integration={Flume.transform(id, name, description, nodes)}
              onClose={() => setShowTestResult(false)}
              updateTestResult={() => null}
            />
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
    </>
  )
}

const InTopBar = styled.div`
  position: absolute;
  top: 16px;
  right: 150px;
`

const LeftTopBar = styled.div`
  position: absolute;
  top: 16px;
  left: 50px;
`

const RightTopBar = styled.div`
  position: absolute;
  top: 16px;
  right: 150px;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const fetchAdvancedIntegrationQuery = gql`
  query FetchAdvandedIntegration($id: ID!) {
    advancedIntegration(id: $id) {
      id
      name
      description
      definition
    }
  }
`
