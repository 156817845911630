/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import _ from 'lodash'

import * as config from './config'

export function transform (id, name, description, nodes) {
  return {
    nodes,
    trueId: id,
    advanced: true,
    __name: name,
    __description: description,
    __type: { id: getIntegrationType(nodes), label: '' },
    __outputs: getOutputs(nodes),
    __urlInputs: [],
    __queryParameterInputs: getInputs(nodes)
  }
}

function getIntegrationType (nodes) {
  const outputNode = getNode(nodes, 'outputs')
  return outputNode.inputData?.integrationType?.integrationType ?? ''
}

function getInputs (nodes) {
  const inputNode = getNode(nodes, 'inputs')
  const inputs = inputNode.inputData?.inputs?.inputs ?? []
  return _.map(inputs, input => ({
    __dataPath: `${input.type}${input.key}`,
    __label: input.label,
    __type: input.type,
    __required: input.required ? [{ id: 'yes' }] : null
  }))
}

function getOutputs (nodes) {
  const outputs = deriveOutputs(nodes)
  const loopNodes = _.filter(nodes, node => node.type.startsWith('loop'))
  const loopOutputs = _.flatMap(loopNodes, node => {
    const innerNodes = node.inputData?.graph?.flume?.nodes ?? {}
    return deriveOutputs(innerNodes)
  })
  return [...outputs, ...loopOutputs]
}

function deriveOutputs (nodes) {
  const gadgetNodes = _.filter(nodes, node => config.nodes[node.type].gadget)
  return _.map(gadgetNodes, node => ({
    __label: node.inputData?.outputLabel?.string,
    __dataPath: node.id,
    __gadget: { type: { id: config.nodes[node.type].gadget }, details: {} }
  }))
}

function getNode (nodes, type) {
  const filtered = _.filter(nodes, { type })
  if (filtered.length !== 1) {
    throw new Error(i18n._('should.have.type.node', { type }))
  }
  return filtered[0]
}
