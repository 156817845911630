/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import DisplayFile from './parts/display-file'
import { getAccepts } from './parts/file-type-picker'
import { useSignFileUploadMutation } from './parts/mutation.sign-file-upload'
import { InvalidFileType, useUploadFile } from './parts/upload-file'

export default function FileUploadEdit ({
  details,
  value,
  formKey,
  id,
  onChange
}) {
  const accepts = details.customFileType || getAccepts(details.fileType) || '*'
  const uploadMutation = useSignFileUploadMutation()
  const { uploading, progress, error, handleChange } = useUploadFile(
    onChange,
    accepts,
    uploadMutation
  )
  const handleRemove = React.useCallback(() => {
    if (typeof onChange !== 'function') return
    onChange(null)
  }, [onChange])

  const placeholder =
    details?.placeholder?.enabled && details?.placeholder?.value

  if (value) {
    return <DisplayFile value={value} onRemove={handleRemove} />
  }
  return (
    <div
      onKeyUp={e => {
        if (e.key === 'Escape') e.stopPropagation()
      }}
    >
      <FileInput
        id={id}
        onChange={handleChange}
        label={placeholder || 'Select a File'}
        accept={accepts}
      />
      <UploadError error={error} accepts={accepts} />
      {uploading && <LinearProgress value={progress} />}
    </div>
  )
}

function UploadError ({ error, accepts }) {
  if (!error) return null
  if (error instanceof InvalidFileType) {
    if (error.code === 'UNKNOWN_FILE_TYPE') {
      return (
        <p data-test='upload-error'>
          <Trans id='not.recongnized.file.type' />
        </p>
      )
    }
    return (
      <p data-test='upload-error'>
        <Trans id='invalid.file.type.please' /> <code>{accepts}</code>
      </p>
    )
  }
  return (
    <p data-test='upload-error'>
      <Trans id='error.occured.uploading.file' />
    </p>
  )
}

const FileInput = ({ id, onChange, label, accept }) => {
  const ref = React.useRef()

  // The aria-describedby causes screen readers to read the
  // label of the *button* in addition to the label of the
  // *gadget*. Even though the description span is inside the
  // button, it seems to work.
  return (
    <FileInputWrapper>
      <Button
        outline
        id={id}
        aria-describedby={`${id}-button-label`}
        onClick={() => ref.current.click()}
      >
        <span id={`${id}-button-label`}>{label}</span>
      </Button>
      <input
        ref={ref}
        accept={accept}
        type='file'
        onChange={e => onChange(e.target.files[0] || null)}
        aria-hidden='true'
        tabIndex={-1}
      />
    </FileInputWrapper>
  )
}

const FileInputWrapper = styled.div`
  input {
    display: none;
  }
`

const LinearProgress = styled.div.attrs(props => ({
  role: 'progressbar',
  'aria-valuemin': 0,
  'aria-valuemax': 100,
  'aria-valuenow': props.value
}))`
  background: #e3e7ea;
  position: relative;
  height: 3px;
  width: 100%;
  margin: 16px 0;

  &:after {
    content: '';
    position: absolute;
    background: #468dcb;
    width: ${props => props.value}%;
    height: 100%;
  }
`
