/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { compact } from 'lodash'
import React from 'react'

export function useDocumentTitle (name) {
  React.useEffect(() => {
    const title = compact([name, 'Kuali']).join(' - ')
    const originalTitle = document.title
    document.title = title
    return () => (document.title = originalTitle)
  }, [name])
}
