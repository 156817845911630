/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import Pager from './pager'
import ResultsList from './results-list'
import SearchBox from './search-box'

export default function ({
  loading,
  setFocus,
  params,
  response,
  onParamsChange
}) {
  const handleSearchChange = React.useCallback(
    query => {
      onParamsChange(params => ({ ...params, page: 1, query }))
    },
    [onParamsChange]
  )
  return (
    <Wrapper>
      <MySearchBox
        loading={loading}
        setFocus={setFocus}
        value={params.query}
        onChange={handleSearchChange}
      />
      <ResultsList results={response.results} />
      <Pager
        onChange={page => onParamsChange({ ...params, page })}
        page={params.page}
        total={response.total}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0.6em;
`

const MySearchBox = styled(SearchBox)`
  margin-bottom: 10px;
`
