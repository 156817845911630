/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`31`} height={`44`} viewBox={`0 0 31 44`} fill={`none`}{...props}><path d={`M30.5051 17.1601C30.4301 17.0154 30.3169 16.8941 30.1776 16.8094C30.0384 16.7248 29.8786 16.68 29.7157 16.68H16.6018L19.9158 1.75619C19.9609 1.55591 19.9353 1.34618 19.8434 1.16263C19.7514 0.979076 19.5987 0.833013 19.4113 0.749243C19.2239 0.665473 19.0132 0.649162 18.8151 0.703079C18.617 0.756996 18.4437 0.877818 18.3246 1.04503L0.545412 25.9359C0.44877 26.0688 0.390873 26.2259 0.378154 26.3898C0.365436 26.5536 0.398393 26.7178 0.473364 26.8641C0.548335 27.0103 0.662385 27.1329 0.802844 27.2183C0.943303 27.3036 1.10467 27.3484 1.26902 27.3475H14.3829L11.0689 42.2642C11.0238 42.4645 11.0494 42.6742 11.1413 42.8578C11.2333 43.0414 11.386 43.1874 11.5734 43.2712C11.7608 43.355 11.9715 43.3713 12.1696 43.3174C12.3677 43.2634 12.541 43.1426 12.6601 42.9754L30.4393 18.0846C30.5342 17.9514 30.5906 17.7947 30.6022 17.6316C30.6138 17.4685 30.5802 17.3053 30.5051 17.1601Z`} fill={`white`}/></svg>
);

let src = "<svg width=\"31\" height=\"44\" viewBox=\"0 0 31 44\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M30.5051 17.1601C30.4301 17.0154 30.3169 16.8941 30.1776 16.8094C30.0384 16.7248 29.8786 16.68 29.7157 16.68H16.6018L19.9158 1.75619C19.9609 1.55591 19.9353 1.34618 19.8434 1.16263C19.7514 0.979076 19.5987 0.833013 19.4113 0.749243C19.2239 0.665473 19.0132 0.649162 18.8151 0.703079C18.617 0.756996 18.4437 0.877818 18.3246 1.04503L0.545412 25.9359C0.44877 26.0688 0.390873 26.2259 0.378154 26.3898C0.365436 26.5536 0.398393 26.7178 0.473364 26.8641C0.548335 27.0103 0.662385 27.1329 0.802844 27.2183C0.943303 27.3036 1.10467 27.3484 1.26902 27.3475H14.3829L11.0689 42.2642C11.0238 42.4645 11.0494 42.6742 11.1413 42.8578C11.2333 43.0414 11.386 43.1874 11.5734 43.2712C11.7608 43.355 11.9715 43.3713 12.1696 43.3174C12.3677 43.2634 12.541 43.1426 12.6601 42.9754L30.4393 18.0846C30.5342 17.9514 30.5906 17.7947 30.6022 17.6316C30.6138 17.4685 30.5802 17.3053 30.5051 17.1601Z\" fill=\"white\"/>\n</svg>\n";
export default src;
