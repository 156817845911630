/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import * as d3 from 'd3'
import { lowerCase, replace } from 'lodash'
import React from 'react'

import { docListRedirect, formatNumber } from '../../utils'

const radius = 100

const pie = d3
  .pie()
  .value(a => a.value)
  .startAngle(Math.PI / 2)
  .endAngle(Math.PI / 2 - 2 * Math.PI)
  .padAngle(0.04)

const colors = [
  ['fill-orange-300', 'bg-orange-300'],
  ['fill-wintergreen-300', 'bg-wintergreen-300'],
  ['fill-purple-300', 'bg-purple-300'],
  ['fill-red-300', 'bg-red-300'],
  ['fill-aqua-300', 'bg-aqua-300'],
  ['fill-blue-300', 'bg-blue-300'],
  ['fill-magma-300', 'bg-magma-300'],
  ['fill-green-300', 'bg-green-300'],
  ['fill-yellow-300', 'bg-yellow-300']
]

export default function Chart ({ value, insight }) {
  const [mouseOver, setMouseOver] = React.useState(null)
  const [tooltip, setTooltip] = React.useState(null)

  const arc = d3
    .arc()
    .innerRadius(radius - 5)
    .outerRadius(insight.details.showCenter ? radius - 33 : 3)

  const arc2 = d3
    .arc()
    .innerRadius(radius)
    .outerRadius(insight.details.showCenter ? radius - 38 : 3)

  return (
    <div className='flex flex-col items-center gap-2'>
      {tooltip != null && mouseOver != null && (
        <div
          style={{ left: tooltip[0], top: tooltip[1] - 30 }}
          className='pointer-events-none fixed z-50 rounded bg-black px-2 py-1 text-xs text-white'
        >
          {label(value.buckets[mouseOver]?.item)}:{' '}
          {formatNumber(value.buckets[mouseOver]?.value)}
        </div>
      )}
      <svg style={{ width: radius * 2, height: radius * 2 }}>
        <g transform={`translate(${radius} ${radius})`}>
          {pie(value.buckets).map((angles, i) => (
            <path
              key={i}
              onMouseOver={() => setMouseOver(i)}
              onMouseMove={e => setTooltip([e.pageX, e.pageY])}
              onMouseOut={() => setMouseOver(null)}
              className={cx(
                getStatusColor(label(angles.data.item), 0) ||
                  colors[i % colors.length][0],
                { 'cursor-pointer': angles.data.item },
                'transition-all'
              )}
              onClick={() =>
                angles.data.item && docListRedirect(insight, angles.data.item)
              }
              d={mouseOver === i ? arc2(angles) : arc(angles)}
            />
          ))}
          {!!insight.details.showCenter && (
            <>
              <text
                textAnchor='middle'
                className='fill-medium-gray-500 text-4xl font-bold'
              >
                {formatNumber(value.total)}
              </text>
              <text
                textAnchor='middle'
                className='fill-medium-gray-500 text-sm font-medium'
                y={28}
              >
                {insight.details.facetLabel}
              </text>
            </>
          )}
        </g>
      </svg>
      <div className='flex flex-wrap gap-2'>
        {value.buckets.map((v, i) => (
          <div
            key={i}
            className={cx(
              'rounded-sm px-2 py-0.5 text-[9px] font-medium uppercase leading-3 text-white',
              getStatusColor(label(v.item), 1) || colors[i % colors.length][1]
            )}
          >
            {label(v.item)}
          </div>
        ))}
      </div>
    </div>
  )
}

function label (item) {
  return item?.label || item
}

const statusColors = {
  'in-progress': ['fill-yellow-200', 'bg-yellow-200 !text-black'],
  denied: ['fill-yellow-400', 'bg-yellow-400 !text-black'],
  rejected: ['fill-yellow-400', 'bg-yellow-400 !text-black'],
  error: ['fill-red-400', 'bg-red-400 !text-white'],
  complete: ['fill-green-200', 'bg-green-200 !text-black'],
  draft: ['fill-yellow-400', 'bg-yellow-400 !text-black'],
  withdrawn: ['fill-pink', 'bg-pink !text-black']
}

function getStatusColor (status, fillOrBg) {
  const transformedStatus = replace(lowerCase(status), / /g, '-')
  return statusColors[transformedStatus]?.[fillOrBg]
}
