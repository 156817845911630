/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get, reduce, set } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'
import Button, { ButtonGroup } from '../../ui/button'
import ConfigBox from '../../ui/config-box'
import { Space, Wrapper } from '../../ui/layout'

export const Config = ({
  formSections = [],
  value,
  updateValue,
  appId,
  defaultFormToViewOnly
}) => {
  const [enabled, setEnabled] = React.useState(
    value.sectionSettings?.enabled || false
  )
  const disabledSections = determineDisabledSections(
    formSections,
    value.sectionSettings?.value
  )
  const updateSetting = (sectionId, value, index) => {
    updateValue(draft => {
      draft.sectionSettings.value = draft.sectionSettings?.value || {}
      draft.sectionSettings.value[sectionId] = value
      const subsections = determineSubsections(formSections, index)
      subsections.forEach(ss => {
        draft.sectionSettings.value[ss.id] = value
      })
    })
  }
  const updateAllSettings = value => {
    updateValue(draft => {
      draft.sectionSettings.value = draft.sectionSettings?.value || {}
      formSections.forEach(section => {
        draft.sectionSettings.value[section.id] = value
      })
    })
  }
  return (
    <ConfigBox
      configKey='sectionSettings'
      description={
        <div>
          <Trans id='setting.enables.override.global' />
        </div>
      }
      enabled={enabled}
      label={`Make individual form sections at this step hidden or ${
        defaultFormToViewOnly ? 'editable' : 'view only'
      }`}
      save={() => () => {
        const visible = !enabled
        setEnabled(visible)
        updateValue(draft => {
          if (!visible) {
            delete draft.sectionSettings.value
          }
          set(draft, 'sectionSettings.enabled', visible)
        })
      }}
    >
      <Button
        onClick={() =>
          updateValue(draft => {
            set(draft, 'sectionSettings', { enabled: true, value: {} })
          })
        }
        mb={3}
        transparent
      >
        <Undo className='fill-blue-500' />
        <Trans id='reset.to.defaults' />
      </Button>
      <Wrapper>
        <Header className='bg-[#e5f2f9] dark:bg-light-gray-300'>
          <div>
            <Trans id='section' />
          </div>
          <IconsHeader>
            <Button
              icon
              transparent
              aria-label={i18n._('hide.all.sections')}
              onClick={() => updateAllSettings('hide')}
              width='46px'
            >
              <Icons.Hidden />
            </Button>
            <Button
              icon
              transparent
              aria-label={i18n._('all.sections.view.only')}
              onClick={() => updateAllSettings('view')}
              width='46px'
            >
              <Icons.Visible />
            </Button>
            <Button
              icon
              transparent
              aria-label={i18n._('make.all.sections.editable')}
              onClick={() => updateAllSettings('edit')}
              width='46px'
            >
              <Icons.Edit />
            </Button>
          </IconsHeader>
        </Header>
        {formSections.map((section, index) => {
          const matchingSetting =
            get(value, 'sectionSettings.value', {})[section.id] ||
            (defaultFormToViewOnly ? 'view' : 'edit')
          const isDisabled = disabledSections.includes(section.id)
          return (
            <Row key={section.id}>
              <SectionName>
                {section.level > 1 && (
                  <Indentation level={section.level}>
                    <SubsectionIcon />
                  </Indentation>
                )}
                {section.label || '[ Unlabeled Section ]'}
              </SectionName>
              <ButtonGroup>
                <button
                  onClick={() => updateSetting(section.id, 'hide', index)}
                  className={cx('kp-button-sm shadow-none', {
                    'kp-button-outline': matchingSetting !== 'hide',
                    'kp-button-solid': matchingSetting === 'hide'
                  })}
                  disabled={isDisabled}
                  width='46px'
                >
                  <Trans id='hide' />
                </button>
                <button
                  onClick={() => updateSetting(section.id, 'view', index)}
                  className={cx('kp-button-sm shadow-none', {
                    'kp-button-outline': matchingSetting !== 'view',
                    'kp-button-solid': matchingSetting === 'view'
                  })}
                  disabled={isDisabled}
                  width='46px'
                >
                  <Trans id='view' />
                </button>
                <button
                  onClick={() => updateSetting(section.id, 'edit', index)}
                  className={cx('kp-button-sm shadow-none', {
                    'kp-button-outline': matchingSetting !== 'edit',
                    'kp-button-solid': matchingSetting === 'edit'
                  })}
                  disabled={isDisabled}
                  width='46px'
                >
                  <Trans id='edit' />
                </button>
              </ButtonGroup>
            </Row>
          )
        })}
      </Wrapper>
      <Space h='40px' />
    </ConfigBox>
  )
}

const determineDisabledSections = (sections, sectionSettings = {}) => {
  return reduce(
    sections,
    (disabledSections, section, index) => {
      if (section.level > 1) {
        const parentSection = sections
          .slice(0, index)
          .findLast(s => s.level < section.level)
        if (
          disabledSections.includes(parentSection.id) ||
          sectionSettings[parentSection.id] === 'hide'
        ) {
          disabledSections.push(section.id)
        }
      }
      return disabledSections
    },
    []
  )
}

const determineSubsections = (sections, index) => {
  const currentSection = sections[index]
  const subsections = []
  let offset = 1
  let nextSection = sections[index + offset]
  while (nextSection && nextSection.level > currentSection.level) {
    subsections.push(nextSection)
    offset++
    nextSection = sections[index + offset]
  }
  return subsections
}

const Undo = styled(Icons.Undo)`
  padding-right: 4px;
`
const Header = styled.div`
  display: flex;
  padding: 16px 0 16px 8px;
  justify-content: space-between;
  align-items: center;
`
const IconsHeader = styled.div`
  display: flex;
  padding-right: 4px;
`
const Row = styled.div`
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 4px;
  border-bottom: 1px solid var(--light-gray-300);
`
const SectionName = styled.div`
  min-width: 150px;
  display: flex;
  padding-right: 10px;
`
const Indentation = styled.div`
  padding-left: ${p => Math.min(3, p.level - 2) * 20}px;
  padding-right: 4px;
`
const SubsectionIcon = styled.div`
  width: 8px;
  height: 13px;
  border-left: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
`

export const forTesting = {
  determineDisabledSections,
  determineSubsections
}
