/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'

import Chart from './chart'
import Config from './config'

export default {
  getName: () => i18n._('pagesbuilder.dashboard.donut'),
  details: () => ({
    formKey: '',
    facetKey: '',
    facetLabel: '',
    aggregation: 'count',
    showCenter: false
  }),
  Chart,
  Config
}
