/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useCreateAppTileMutation = () => {
  const [createAppTile] = useMutation(createAppMutation)
  return ({ name, backgroundColor, iconName, spaceId }, type) =>
    createAppTile(getParams({ name, type, backgroundColor, iconName, spaceId }))
}

export const useCopyAppMutation = () => {
  const [copyApp] = useMutation(copyAppMutation)
  return (sourceId, { name, backgroundColor, iconName, spaceId }) =>
    copyApp(getParams({ sourceId, name, backgroundColor, iconName, spaceId }))
}

const fragment = gql`
  fragment NewAppFields on App {
    id
    type
    isFavorite
    firstPageId
    name
    createdById
    spaceId
    tileOptions {
      backgroundColor
      iconName
    }
    viewer {
      canViewDocuments
      canSubmitDocuments
      canManage
    }
  }
`

const createAppMutation = gql`
  ${fragment}
  mutation CreateAppTile($args: CreateAppTileInput!) {
    data: createAppTile(args: $args) {
      id
      ...NewAppFields
    }
  }
`

const copyAppMutation = gql`
  ${fragment}
  mutation CopyApp($args: CopyAppInput!) {
    data: copyApp(args: $args) {
      id
      ...NewAppFields
    }
  }
`

const getParams = args => ({
  variables: { args },
  update: (cache, { data: { data } }) => {
    if (data.spaceId) {
      cache.modify({
        id: `SpacePortal:${data.spaceId}`,
        fields: {
          apps (apps = []) {
            return [...apps, cache.writeFragment({ data, fragment })]
          }
        }
      })
    }
    cache.modify({
      fields: {
        apps (apps = []) {
          return [...apps, cache.writeFragment({ data, fragment })]
        }
      }
    })
  }
})
