/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { order, width } from 'styled-system'

import { Flex } from './layout'
import { Subtitle1 } from './typography'

export const Tabs = styled(Flex)`
  justify-content: center;
  ${order}
  ${width}
  > :not(:last-child) {
    margin-right: 8px;
  }
`
Tabs.defaultProps = { as: 'nav' }

export const TabInner = styled.div`
  font-weight: normal;
  .active > & {
    font-weight: 500;
    border-bottom: 2px solid #3369a3;
  }
  html.dark & {
    // Outlier: dark:text-black
    color: #fff;
  }
`
export const TabLabel = styled.div`
  padding: 4px 0;
  border-bottom: ${p => (p.selected ? 'solid 2px var(--blue-500)' : 'none')};
  color: #1a1a1a;
  html.dark & {
    // Outlier: dark:text-black
    color: #fff;
  }
  ${p => p.selected && 'font-weight: 500;'}
`

export const Tab = styled(Subtitle1)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 4px 16px;
  @media (max-width: 500px) {
    padding: 4px;
  }
  background: none;
  border: none;
  cursor: pointer;
`
Tab.defaultProps = { as: NavLink }
