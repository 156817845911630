/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useCreateLinkMutation = spaceId => {
  const [createLink, opts] = useMutation(createLinkMutation)
  return [args => createLink(getParams(spaceId, args)), opts]
}

const createLinkMutation = gql`
  mutation CreateLink($spaceId: ID!, $args: CreateLinkInput!) {
    createLink(spaceId: $spaceId, args: $args) {
      result {
        id
        title
        description
        url
        imageUrl
        createdAt
      }
      query {
        space(id: $spaceId) {
          id
          links {
            id
          }
        }
      }
    }
  }
`

const getParams = (spaceId, args) => ({
  variables: { spaceId, args }
})
