/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

/**
 * @param {React.HTMLAttributes<HTMLSpanElement> & { position?: 'top'|'bottom'|'left'|'right'}} props
 */
export function Tooltip ({ position = 'top', className, ...props }) {
  return (
    <span
      role='tooltip'
      className={cx(
        'bg-blend absolute z-10 hidden rounded bg-dark-gray-300 bg-opacity-90 px-5 py-2 text-sm text-white backdrop-blur-sm after:absolute after:border-8 after:content-[""] group-focus-within:inline-block group-hover:inline-block group-focus:inline-block dark:bg-light-gray-200 dark:text-black',
        {
          'bottom-[calc(100%+10px)] left-1/2 -translate-x-1/2 border-opacity-90 after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-x-transparent after:border-b-transparent after:border-t-dark-gray-300 dark:after:border-t-light-gray-200':
            position === 'top',
          'left-1/2 top-[calc(100%+10px)] -translate-x-1/2 border-opacity-90 after:bottom-[100%] after:left-1/2 after:-translate-x-1/2 after:border-x-transparent after:border-b-dark-gray-300 after:border-t-transparent dark:after:border-b-light-gray-200':
            position === 'bottom',
          'right-[calc(100%+10px)] top-1/2 -translate-y-1/2 border-opacity-90 after:left-[100%] after:top-1/2 after:-translate-y-1/2 after:border-y-transparent after:border-l-dark-gray-300 after:border-r-transparent dark:after:border-l-light-gray-200':
            position === 'left',
          'left-[calc(100%+10px)] top-1/2 -translate-y-1/2 border-opacity-90 after:right-[100%] after:top-1/2 after:-translate-y-1/2 after:border-y-transparent after:border-l-transparent after:border-r-dark-gray-300 dark:after:border-r-light-gray-200':
            position === 'right'
        },
        className
      )}
      {...props}
    />
  )
}

export function TooltipTrigger ({ as = 'div', className, ...props }) {
  return React.createElement(as, {
    className: cx('group', className),
    tabIndex: 0,
    ...props
  })
}
