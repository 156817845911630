/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, set } from 'lodash'
import React from 'react'

import useDebouncedCallback from '../../components/use-debounced-callback'
import ConfigBox from '../../ui/config-box'
import Input from '../../ui/input'
import { Flex, Space } from '../../ui/layout'
import { Option, Select } from '../../ui/select'
import { Body1 } from '../../ui/typography'

const getInitialSettings = () => ({
  frequencyType: 'day',
  frequency: 1,
  numberOfOccurences: 1
})

export const Config = ({
  value,
  updateValue,
  description,
  borderless = false
}) => {
  const [frequency, setFrequency] = React.useState(
    value.reminderSettings?.value?.frequency ?? 1
  )
  const updateFrequency = useDebouncedCallback(
    React.useCallback(
      value => {
        const parsedValue = Number.parseInt(value, 10)
        if (!isNaN(parsedValue) && parsedValue >= 1) {
          updateValue(draft => {
            set(draft, 'reminderSettings.value.frequency', parsedValue)
          })
        }
      },
      [updateValue]
    )
  )

  const frequencyTypeValue =
    value?.reminderSettings?.value?.numberOfOccurences === -1
      ? 'every'
      : 'onetime'

  const errors = []
  const parsedFrequency = Number.parseInt(frequency, 10)
  if (isNaN(parsedFrequency) || parsedFrequency < 1) {
    errors.push('Value must be a whole number greater than 0')
  }

  return (
    <ConfigBox
      borderless={borderless}
      configKey='reminderSettings'
      description={description}
      enabled={value.reminderSettings?.enabled}
      label={i18n._('send.reminder.email.notifications')}
      save={() => () => {
        updateValue(draft => {
          set(draft, 'reminderSettings', {
            enabled: !get(draft, 'reminderSettings.enabled'),
            value: get(draft, 'reminderSettings.value', getInitialSettings())
          })
        })
      }}
    >
      <Body1 className='py-1'>
        <Trans id='frequency' />
      </Body1>
      <Flex className='gap-2'>
        <Select
          value={frequencyTypeValue}
          darkerModeDarkerBg
          onChange={val =>
            updateValue(draft => {
              if (val === 'onetime') {
                set(draft, 'reminderSettings.value.numberOfOccurences', 1)
              } else {
                set(draft, 'reminderSettings.value.numberOfOccurences', -1)
              }
            })
          }
        >
          <Option value='onetime'>
            <Trans id='one.time' />
          </Option>
          <Option value='every'>
            <Trans id='every' />
          </Option>
        </Select>
        <Input
          className='w-14'
          type='number'
          value={frequency}
          min={1}
          step={1}
          placeholder='#'
          onChange={val => {
            setFrequency(val)
            updateFrequency(val)
          }}
        />
        <span>
          <Trans id='days.after.notification' />
        </span>
      </Flex>
      {errors.map(error => (
        <Body1 className='py-1 !text-red-400' key={error}>
          {error}
        </Body1>
      ))}
      <Space h='16px' />
    </ConfigBox>
  )
}
