/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { formatDistanceToNow } from 'date-fns'
import { compact, filter, flow, get, map } from 'lodash'

import { gadgets } from '../../../formbot'
import { getFormatLocale } from '../../../i18n'

const combine = (operators, type) => {
  if (operators.length === 0) return null
  if (operators.length === 1) return operators[0]
  return { type, operators }
}

export function switchTypenames (resp, typeNameMap) {
  if (!resp?.__typename) {
    throw new Error(i18n._('pages.submitter.util.error') + ' __typename')
  }
  if (!typeNameMap?.[resp.__typename]) {
    throw new Error(
      i18n._('pages.submitter.util.error.function') +
        ` __typename ${resp.__typename}`
    )
  }
  return typeNameMap[resp.__typename](resp)
}

export const paramsToGql = ({ skip, limit, ...params }) => {
  const query = { skip, limit, query: params.query || '' }
  query.sort = flow(
    a => filter(a, sort => !!sort.field),
    a =>
      map(a, sort => {
        let sortStr = sort.field
        if (sort.ascending) sortStr = `-${sortStr}`
        sortStr += get(gadgets, [sort.type, 'sortSuffix'], '')
        return sortStr
      })
  )(params.sorts)
  const operators = compact(
    map(params.filters, myFilter => {
      const format = get(gadgets, [myFilter.type, 'filters', 'toGraphQL'])
      if (!format) return null
      const operators = format(myFilter)
      return combine(operators, 'OR')
    })
  )
  const fields = combine(operators, 'AND')
  if (fields) query.fields = fields
  query.columns = flow(
    a => filter(a, 'visible'),
    a => map(a, 'formKey')
  )(params.columns)
  return query
}

export const formatFromNow = date => {
  return formatDistanceToNow(date, {
    locale: getFormatLocale(i18n.locale)
  })
}
