/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { keyBy } from 'lodash'
import React from 'react'

import { Flex } from '../ui/layout'
import { AppIcon, AppSquare } from './app-icon'
import escapeStringRegExp from './escape-string-regexp'
import * as Lookups from './lookup-controls'

export default function AppTypeahead ({ onChange, value, className }) {
  const [text, setText] = React.useState('')
  const { data, loading } = useQuery(getAppsQuery)
  const ids = React.useMemo(() => keyBy(value, 'id'), [value])
  const options = React.useMemo(() => {
    if (!data || !data.apps || !text) return []
    try {
      const textRegex = new RegExp(escapeStringRegExp(text), 'i')
      return data.apps
        .filter(app => textRegex.test(app.name) && !ids[app.id])
        .slice(0, 15)
    } catch (e) {
      return []
    }
  }, [data, ids, text])
  return (
    <Lookups.Multiselect
      className={className}
      chipStyle={{ paddingLeft: 0 }}
      id='app-filter'
      options={options}
      format={app => (
        <Flex>
          <AppSquare
            backgroundColor={app.tileOptions?.backgroundColor}
            className='mr-2 w-8 !rounded-full'
          >
            <AppIcon
              iconName={app.tileOptions?.iconName}
              className='!w-1/2'
              isProduct={app.type === 'product'}
            />
          </AppSquare>
          <div>{app.name}</div>
        </Flex>
      )}
      onChange={onChange}
      value={value}
      query={text}
      setQuery={setText}
      loading={loading}
    />
  )
}

const getAppsQuery = gql`
  query FetchApps {
    apps {
      id
      name
      type
      tileOptions {
        backgroundColor
        iconName
      }
    }
  }
`
