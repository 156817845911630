/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import Infobox from './info-box'

export default function Popup (props) {
  const { showModal, title, subtitle, Img, width, children } = props
  return (
    <div>
      {showModal && (
        <BoxBackground>
          <StyledInfobox
            width={width}
            title={title}
            subtitle={subtitle}
            Img={Img}
          >
            {children}
          </StyledInfobox>
        </BoxBackground>
      )}
    </div>
  )
}

const BoxBackground = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledInfobox = styled(Infobox)`
  position: relative;
  display: flex;
  text-align: start;
  align-items: center;
  box-shadow:
    rgb(15 15 15 / 5%) 0px 0px 0px 1px,
    rgb(15 15 15 / 10%) 0px 5px 10px,
    rgb(15 15 15 / 20%) 0px 15px 40px;
`
