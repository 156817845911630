/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { groupBy, map } from 'lodash'
import React from 'react'

import { productBuilder } from '../../components/feature-flags'
import { useTenantFeaturesContext } from '../../components/tenant-features-context.jsx'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import * as Icons from '../../icons'
import * as VoronoiDND from '../../voronoi-dnd'
import * as UI from './ui'

export const GadgetListItem = VoronoiDND.draggable(
  class _GadgetListItem extends React.Component {
    render () {
      const { Icon } = this.props

      return (
        <>
          {this.props.connectDragSource(
            <div
              className='flex-1'
              tabIndex={0}
              role='button'
              aria-label={i18n._('pagesbuilder.form.gadget.add.new', {
                text: this.props.text
              })}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.props.beginA11yDrag(this.props.dragContext)
                }
              }}
            >
              <div
                className={cx(
                  'flex items-center overflow-hidden px-6 py-2 text-dark-gray-500',
                  this.props.disabled ? 'cursor-default' : 'cursor-grab',
                  {
                    'opacity-20':
                      this.props.isDragging || this.props.a11yDragging
                  },
                  this.props.className
                )}
                data-testid={`draggable-gadget-${this.props.id
                  .toString()
                  .toLowerCase()}`}
              >
                <Icon
                  className={cx(
                    'mr-2 h-4 w-4 shrink-0',
                    this.props.dragContext.canonicalGadget
                      ? 'fill-wintergreen-300'
                      : this.props.disabled
                        ? 'fill-light-gray-400 dark:fill-light-gray-300'
                        : 'fill-medium-gray-500 dark:fill-medium-gray-300'
                  )}
                  aria-hidden
                />
                <span
                  disabled={this.props.disabled}
                  className={cx('flex-1', {
                    'text-light-gray-400': this.props.disabled
                  })}
                >
                  {this.props.text}
                </span>
                {this.props.help && (
                  <TooltipTrigger
                    label={i18n._({
                      id: 'pagesbuilder.form.gadget.help',
                      values: { text: this.props.text }
                    })}
                    tooltipId={`help-for-gadget-${this.props.id}`}
                  >
                    <Icons.AlertHelp className='ml-1' />
                  </TooltipTrigger>
                )}
                {this.props.deprecated && (
                  <TooltipTrigger
                    label={i18n._({
                      id: 'pagesbuilder.form.gadget.warning',
                      values: { text: this.props.text }
                    })}
                    tooltipId={`warning-for-gadget-${this.props.id}`}
                  >
                    <Icons.AlertWarning className='ml-1 fill-[#C54700]' />
                  </TooltipTrigger>
                )}
                {this.props.disabled && (
                  <TooltipTrigger
                    label={i18n._({
                      id: 'pagesbuilder.form.gadget.disabled',
                      values: { text: this.props.text }
                    })}
                    tooltipId={`disabled-for-gadget-${this.props.id}`}
                  >
                    <Icons.AlertError className='ml-1 fill-orange-400' />
                  </TooltipTrigger>
                )}
                {this.props.children}
              </div>
            </div>
          )}
          {this.props.help && (
            <Tooltip id={`help-for-gadget-${this.props.id}`} place='bottom'>
              <div className='w-36'>{this.props.help}</div>
            </Tooltip>
          )}
          {this.props.deprecated && (
            <Tooltip id={`warning-for-gadget-${this.props.id}`} place='bottom'>
              <div className='w-36'>{this.props.deprecated}</div>
            </Tooltip>
          )}
          {this.props.disabled && (
            <Tooltip id={`disabled-for-gadget-${this.props.id}`} place='bottom'>
              <div className='w-44'>{this.props.disabled}</div>
            </Tooltip>
          )}
        </>
      )
    }
  }
)

const categoryOrder = ['Layout', 'Basic', 'Special', 'Smart', 'Legacy']

const dataGadgets = ['DataFill', 'DataLookup', 'DataMultiselect', 'Terms']

const GadgetList = React.forwardRef((props, ref) => {
  const tenantFeatures = useTenantFeaturesContext()
  const nameMap = {
    Layout: { label: `${i18n._('pagesbuilder.form.gadget.layout')}` },
    Basic: { label: `${i18n._('pagesbuilder.form.gadget.basic')}` },
    Special: { label: `${i18n._('pagesbuilder.form.gadget.special')}` },
    Smart: {
      label: `${i18n._('pagesbuilder.form.gadget.advanced')}`,
      help: `${i18n._('pagesbuilder.form.gadget.advanced.data')}`,
      collapsed: true
    },
    Legacy: {
      label: `${i18n._('pagesbuilder.form.gadget.legacy')}`,
      help: `${i18n._('pagesbuilder.form.gadget.legacy.data')}`,
      collapsed: true
    }
  }
  const {
    gadgets,
    canonicalGadgets,
    isAnonymous,
    gadgetMapById,
    gadgetIndexTypesMap
  } = props
  const termsAlreadyOnForm =
    Object.values(gadgetMapById).some(gad => gad.type === 'Terms') ||
    Object.values(gadgetIndexTypesMap).some(gad => gad.gadgetType === 'Terms')
  const mapped = map(gadgets, (gadget, key) => ({ ...gadget.meta, key }))
  const filtered = mapped.filter(gadget => {
    // 1: Filter out gadgets with meta.hidden
    if (gadget.hidden) return false

    // 2. Filter out gadgets that require a tenant flag
    if (!tenantFeatures?.rulesGadget && gadget.key === 'Rules') return false

    // 3: Filter out gadgets that are already on the form (where applicable)
    if (termsAlreadyOnForm && gadget.key === 'Terms') return false

    return true
  })

  const byCategory = groupBy(filtered, 'category')
  const [selected, setSelected] = React.useState('general')
  return (
    <div ref={ref}>
      {!!canonicalGadgets && (
        <div className='border-b border-b-light-gray-300 px-4 py-3'>
          <button
            className={cx('kp-button-transparent kp-button-sm w-1/2', {
              'text-black': selected === 'general',
              'text-medium-gray-300': selected !== 'general'
            })}
            onClick={() => setSelected('general')}
            data-active={selected === 'general'}
            aria-pressed={selected === 'general'}
            aria-label={i18n._('pagesbuilder.form.gadget.show.general')}
          >
            <Trans id='pagesbuilder.form.gadget.general' />
          </button>
          <button
            className={cx('kp-button-transparent kp-button-sm w-1/2', {
              'text-black': selected === 'product',
              'text-medium-gray-300': selected !== 'product'
            })}
            onClick={() => setSelected('product')}
            data-active={selected === 'product'}
            aria-pressed={selected === 'product'}
            aria-label={i18n._('pagesbuilder.form.gadget.show.product')}
          >
            {productBuilder ? (
              <Trans id='pagesbuilder.form.gadget.archived' />
            ) : (
              <Trans id='pagesbuilder.form.gadget.product' />
            )}
          </button>
        </div>
      )}

      {selected === 'general' ? (
        <>
          {map(categoryOrder, title => {
            if (!byCategory[title]) return null
            return (
              <UI.Collapsible
                key={title}
                id={title}
                title={nameMap[title]?.label || title}
                help={nameMap[title]?.help}
                collapsed={nameMap[title]?.collapsed}
              >
                {map(byCategory[title], gadget => {
                  return (
                    <GadgetListItem
                      key={gadget.key}
                      id={gadget.key}
                      beginDrag={props.beginDrag}
                      beginDragProp={{ type: gadget.key }}
                      beginA11yDrag={props.beginA11yDrag}
                      endDrag={props.endDrag}
                      a11yDragging={
                        props.a11yDragItem &&
                        props.a11yDragItem.type === gadget.key
                      }
                      Icon={gadget.Icon}
                      text={gadget.label}
                      help={gadget.help}
                      deprecated={gadget.deprecated}
                      disabled={
                        isAnonymous &&
                        dataGadgets.includes(gadget.key) &&
                        i18n._('pagesbuilder.form.gadget.lookup')
                      }
                      dragContext={{ type: gadget.key }}
                    />
                  )
                })}
              </UI.Collapsible>
            )
          })}
        </>
      ) : (
        <>
          {map(canonicalGadgets, gadget => (
            <GadgetListItem
              key={gadget.id}
              id={gadget.id}
              beginDrag={props.beginDrag}
              beginDragProp={{ canonicalGadget: gadget }}
              beginA11yDrag={props.beginA11yDrag}
              endDrag={props.endDrag}
              Icon={gadgets[gadget.type].meta.Icon}
              text={gadget.label}
              dragContext={{ canonicalGadget: gadget }}
            />
          ))}
        </>
      )}
    </div>
  )
})

export default GadgetList
