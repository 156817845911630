/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg aria-label={`Kuali`} role={`img`} viewBox={`0 0 20 20`}{...props}><polygon fill={`#CCCCCC`} points={`0 0 0 20 20 20 10 10 20 0`}/></svg>
);

let src = "<svg aria-label=\"Kuali\" role=\"img\" viewBox=\"0 0 20 20\" xmlns=\"http://www.w3.org/2000/svg\">\n  <polygon fill=\"#CCCCCC\" points=\"0 0 0 20 20 20 10 10 20 0\"></polygon>\n</svg>\n";
export default src;
