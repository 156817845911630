/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.5333 3.38402C15.2198 2.77352 14.7675 2.24506 14.2127 1.8411C13.6579 1.43715 13.016 1.16896 12.3388 1.05809C11.6615 0.947224 10.9676 0.996762 10.313 1.20273C9.65832 1.40869 9.0611 1.76534 8.56931 2.24402L7.99998 2.76469L7.44864 2.26269C6.95799 1.77473 6.35796 1.41094 5.69843 1.20153C5.0389 0.992118 4.33891 0.943148 3.65664 1.05869C2.97833 1.16445 2.33504 1.43054 1.78023 1.83485C1.22541 2.23917 0.775076 2.77003 0.466642 3.38335C0.0543584 4.1913 -0.0897121 5.10961 0.0553426 6.00499C0.200397 6.90038 0.627028 7.72623 1.27331 8.36269L7.52198 14.8C7.58411 14.8639 7.65842 14.9147 7.74052 14.9494C7.82262 14.9841 7.91085 15.002 7.99998 15.002C8.0891 15.002 8.17733 14.9841 8.25943 14.9494C8.34153 14.9147 8.41585 14.8639 8.47798 14.8L14.718 8.37469C15.3669 7.73762 15.7958 6.91027 15.9424 6.0128C16.0891 5.11533 15.9457 4.19448 15.5333 3.38402Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.5333 3.38402C15.2198 2.77352 14.7675 2.24506 14.2127 1.8411C13.6579 1.43715 13.016 1.16896 12.3388 1.05809C11.6615 0.947224 10.9676 0.996762 10.313 1.20273C9.65832 1.40869 9.0611 1.76534 8.56931 2.24402L7.99998 2.76469L7.44864 2.26269C6.95799 1.77473 6.35796 1.41094 5.69843 1.20153C5.0389 0.992118 4.33891 0.943148 3.65664 1.05869C2.97833 1.16445 2.33504 1.43054 1.78023 1.83485C1.22541 2.23917 0.775076 2.77003 0.466642 3.38335C0.0543584 4.1913 -0.0897121 5.10961 0.0553426 6.00499C0.200397 6.90038 0.627028 7.72623 1.27331 8.36269L7.52198 14.8C7.58411 14.8639 7.65842 14.9147 7.74052 14.9494C7.82262 14.9841 7.91085 15.002 7.99998 15.002C8.0891 15.002 8.17733 14.9841 8.25943 14.9494C8.34153 14.9147 8.41585 14.8639 8.47798 14.8L14.718 8.37469C15.3669 7.73762 15.7958 6.91027 15.9424 6.0128C16.0891 5.11533 15.9457 4.19448 15.5333 3.38402Z\" />\n</svg>\n";
export default src;
