/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { compact, get, map } from 'lodash'
import React from 'react'

import * as Icons from '../../icons'
import Button from '../../ui/button'
import * as Lookup from '../../ui/lookup'

export default function UserTypeahead ({ value, ...props }) {
  if (!value) return <UserLookup id='user-typeahead' {...props} />
  return (
    <Lookup.Chip>
      <Lookup.ChipLabel>{formatUser(value)}</Lookup.ChipLabel>
      <Button
        transparent
        onClick={() => {
          // TODO:: set focus to the textbox that is about to appear
          props.onChange(null)
        }}
      >
        <Icons.Close width='8px' height='8px' />
      </Button>
    </Lookup.Chip>
  )
}

const UserLookup = ({ id, onChange, darkModeDarkerBg, ...props }) => {
  // TODO:: Where should we splat props?
  const [query, setQuery] = React.useState('')
  const options = useUserSearch(query)
  const aria = props['aria-labelledby']
  const lookup = Lookup.useLookup({
    id,
    options,
    onChange,
    query,
    setQuery,
    aria
  })
  return <Lookup.Control state={lookup} darkModeDarkerBg={darkModeDarkerBg} />
}

const formatUser = user =>
  `${user.displayName}${user.username ? ` (${user.username})` : ''}`

const useUserSearch = query => {
  const { data } = useQuery(userSearchQuery, { variables: { query } })
  return formatUsers(get(data, 'usersConnection.edges', []))
}

const userSearchQuery = gql`
  query UserSearch($query: String!) {
    usersConnection(args: { limit: 15, query: $query }) {
      edges {
        node {
          id
          displayName
          username
          email
          firstName
          lastName
          schoolId
        }
      }
    }
  }
`

export const formatUsers = users => {
  return map(compact(users), user => {
    const node = user.node || user
    const { id, displayName, username, email, firstName, lastName, schoolId } =
      node
    const label = username ? `${displayName} (${username})` : displayName
    return {
      id,
      label,
      user: {
        id,
        displayName,
        username,
        email,
        label,
        firstName,
        lastName,
        schoolId
      }
    }
  })
}
