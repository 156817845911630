/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M1 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13V3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3L0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14ZM12 8C12 7.82319 12.0702 7.65362 12.1953 7.5286C12.3203 7.40357 12.4899 7.33333 12.6667 7.33333C12.8435 7.33333 13.013 7.40357 13.1381 7.5286C13.2631 7.65362 13.3333 7.82319 13.3333 8V11C13.3333 11.1768 13.2631 11.3464 13.1381 11.4714C13.013 11.5964 12.8435 11.6667 12.6667 11.6667C12.4899 11.6667 12.3203 11.5964 12.1953 11.4714C12.0702 11.3464 12 11.1768 12 11V8ZM8.66667 5C8.66667 4.82319 8.73691 4.65362 8.86193 4.5286C8.98695 4.40357 9.15652 4.33333 9.33333 4.33333C9.51014 4.33333 9.67971 4.40357 9.80474 4.5286C9.92976 4.65362 10 4.82319 10 5V11C10 11.1768 9.92976 11.3464 9.80474 11.4714C9.67971 11.5964 9.51014 11.6667 9.33333 11.6667C9.15652 11.6667 8.98695 11.5964 8.86193 11.4714C8.73691 11.3464 8.66667 11.1768 8.66667 11V5ZM5.66667 9C5.66667 8.82319 5.73691 8.65362 5.86193 8.5286C5.98695 8.40357 6.15652 8.33333 6.33333 8.33333C6.51014 8.33333 6.67971 8.40357 6.80474 8.5286C6.92976 8.65362 7 8.82319 7 9V11C7 11.1768 6.92976 11.3464 6.80474 11.4714C6.67971 11.5964 6.51014 11.6667 6.33333 11.6667C6.15652 11.6667 5.98695 11.5964 5.86193 11.4714C5.73691 11.3464 5.66667 11.1768 5.66667 11V9ZM2.66667 7.66667C2.66667 7.48986 2.7369 7.32029 2.86193 7.19526C2.98695 7.07024 3.15652 7 3.33333 7C3.51014 7 3.67971 7.07024 3.80474 7.19526C3.92976 7.32029 4 7.48986 4 7.66667V11C4 11.1768 3.92976 11.3464 3.80474 11.4714C3.67971 11.5964 3.51014 11.6667 3.33333 11.6667C3.15652 11.6667 2.98695 11.5964 2.86193 11.4714C2.7369 11.3464 2.66667 11.1768 2.66667 11V7.66667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M1 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13V3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3L0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14ZM12 8C12 7.82319 12.0702 7.65362 12.1953 7.5286C12.3203 7.40357 12.4899 7.33333 12.6667 7.33333C12.8435 7.33333 13.013 7.40357 13.1381 7.5286C13.2631 7.65362 13.3333 7.82319 13.3333 8V11C13.3333 11.1768 13.2631 11.3464 13.1381 11.4714C13.013 11.5964 12.8435 11.6667 12.6667 11.6667C12.4899 11.6667 12.3203 11.5964 12.1953 11.4714C12.0702 11.3464 12 11.1768 12 11V8ZM8.66667 5C8.66667 4.82319 8.73691 4.65362 8.86193 4.5286C8.98695 4.40357 9.15652 4.33333 9.33333 4.33333C9.51014 4.33333 9.67971 4.40357 9.80474 4.5286C9.92976 4.65362 10 4.82319 10 5V11C10 11.1768 9.92976 11.3464 9.80474 11.4714C9.67971 11.5964 9.51014 11.6667 9.33333 11.6667C9.15652 11.6667 8.98695 11.5964 8.86193 11.4714C8.73691 11.3464 8.66667 11.1768 8.66667 11V5ZM5.66667 9C5.66667 8.82319 5.73691 8.65362 5.86193 8.5286C5.98695 8.40357 6.15652 8.33333 6.33333 8.33333C6.51014 8.33333 6.67971 8.40357 6.80474 8.5286C6.92976 8.65362 7 8.82319 7 9V11C7 11.1768 6.92976 11.3464 6.80474 11.4714C6.67971 11.5964 6.51014 11.6667 6.33333 11.6667C6.15652 11.6667 5.98695 11.5964 5.86193 11.4714C5.73691 11.3464 5.66667 11.1768 5.66667 11V9ZM2.66667 7.66667C2.66667 7.48986 2.7369 7.32029 2.86193 7.19526C2.98695 7.07024 3.15652 7 3.33333 7C3.51014 7 3.67971 7.07024 3.80474 7.19526C3.92976 7.32029 4 7.48986 4 7.66667V11C4 11.1768 3.92976 11.3464 3.80474 11.4714C3.67971 11.5964 3.51014 11.6667 3.33333 11.6667C3.15652 11.6667 2.98695 11.5964 2.86193 11.4714C2.7369 11.3464 2.66667 11.1768 2.66667 11V7.66667Z\" />\n</svg>\n";
export default src;
