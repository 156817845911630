/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346633 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM10.8571 9.14286H5.14286C4.83976 9.14286 4.54907 9.02245 4.33474 8.80812C4.12041 8.59379 4 8.30311 4 8C4 7.6969 4.12041 7.40621 4.33474 7.19188C4.54907 6.97755 4.83976 6.85714 5.14286 6.85714H10.8571C11.1602 6.85714 11.4509 6.97755 11.6653 7.19188C11.8796 7.40621 12 7.6969 12 8C12 8.30311 11.8796 8.59379 11.6653 8.80812C11.4509 9.02245 11.1602 9.14286 10.8571 9.14286Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346633 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM10.8571 9.14286H5.14286C4.83976 9.14286 4.54907 9.02245 4.33474 8.80812C4.12041 8.59379 4 8.30311 4 8C4 7.6969 4.12041 7.40621 4.33474 7.19188C4.54907 6.97755 4.83976 6.85714 5.14286 6.85714H10.8571C11.1602 6.85714 11.4509 6.97755 11.6653 7.19188C11.8796 7.40621 12 7.6969 12 8C12 8.30311 11.8796 8.59379 11.6653 8.80812C11.4509 9.02245 11.1602 9.14286 10.8571 9.14286Z\" />\n</svg>\n";
export default src;
