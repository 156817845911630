/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { OptionalConfig, RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validations'
import View from './view'

export default {
  OptionalConfig,
  RequiredConfig,
  defaultValue: null,
  Edit,
  meta: {
    category: 'Special',
    Icon,
    label: 'Option Range',
    initialTemplate: () => ({ details: { from: 1, to: 5 } })
  },
  progressiveDisclosure,
  sampleValue: 5,
  validateShape,
  View
}
