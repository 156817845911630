/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'

const Check = styled(Icons.CheckCircle)`
  width: 24px;
  height: 24px;
`

const Empty = styled(Check)`
  fill: transparent;
  border: 2px solid #999;
  border-radius: 50%;
`

export default function CheckedIcon ({ format, color }) {
  if (format === 'checked') return <Check fill='#ccc' />
  if (format === 'selected') return <Check fill={color} />
  return <Empty />
}
