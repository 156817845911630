/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import { Problem } from '../illustrations'
import { Blurb, InnerWrapper, Wrapper } from '../ui/errors'

export default function NeedsAuth () {
  const authUrl = `/auth/?return_to=${encodeURIComponent(window.location.href)}`
  return (
    <Wrapper>
      <InnerWrapper>
        <Problem />
        <Blurb>
          <Blurb.Message>
            <Trans id='pages.needs.redirecting' />
          </Blurb.Message>
          <Blurb.Action outline href={authUrl} as='a'>
            <Trans id='pages.needs.redirect.click' />
          </Blurb.Action>
        </Blurb>
      </InnerWrapper>
    </Wrapper>
  )
}
