/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`7`} height={`14`} fill={`none`} viewBox={`0 0 7 14`}{...props}><path fill={`#6C6C6C`} fillRule={`evenodd`} d={`M7 7l-7 7V0l7 7z`} clipRule={`evenodd`}/></svg>
);

let src = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"7\" height=\"14\" fill=\"none\" viewBox=\"0 0 7 14\">\n    <path fill=\"#6C6C6C\" fill-rule=\"evenodd\" d=\"M7 7l-7 7V0l7 7z\" clip-rule=\"evenodd\"/>\n</svg>\n";
export default src;
