/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useAcknowledgeDanger = () => {
  const [acknowledge] = useMutation(mutation)
  return appId => acknowledge(getParams(appId))
}

const mutation = gql`
  mutation AcknowledgedDanger($appId: ID!) {
    acknowledgedDanger(appId: $appId) {
      id
      acknowledgedDanger {
        dateDismissed
      }
    }
  }
`

function getParams (appId) {
  return {
    variables: { appId },
    optimisticResponse: {
      acknowledgedDanger: {
        __typename: 'App',
        id: appId,
        acknowledgedDanger: {
          __typename: 'Acknowledger',
          dateDismissed: new Date()
        }
      }
    }
  }
}
