/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.7335 13.6868C12.1277 13.6917 12.5155 13.5873 12.8539 13.3851C13.1924 13.1829 13.4682 12.8909 13.6507 12.5415C13.8333 12.1921 13.9155 11.799 13.8881 11.4057C13.8608 11.0125 13.725 10.6345 13.4959 10.3137C13.2667 9.99287 12.9532 9.74187 12.5901 9.58847C12.2269 9.43507 11.8283 9.38527 11.4387 9.44467C11.0489 9.504 10.6833 9.6702 10.3823 9.9248C10.0813 10.1793 9.85672 10.5123 9.73345 10.8868H9.60012C9.02312 10.8868 8.46965 10.6581 8.06098 10.2507C7.65238 9.84327 7.42192 9.29047 7.42012 8.71347V6.58015C7.42345 6.06206 7.28012 5.5536 7.00678 5.11348H11.8401C11.9987 5.59536 12.3237 6.00497 12.757 6.26884C13.1902 6.5327 13.7033 6.63354 14.2042 6.55327C14.7051 6.47299 15.161 6.21686 15.4901 5.83082C15.8192 5.44477 16 4.95409 16 4.44681C16 3.93953 15.8192 3.44885 15.4901 3.06281C15.161 2.67677 14.7051 2.42063 14.2042 2.34036C13.7033 2.26009 13.1902 2.36093 12.757 2.62479C12.3237 2.88865 11.9987 3.29827 11.8401 3.78015H4.16013C4.0016 3.29827 3.67656 2.88865 3.2433 2.62479C2.81004 2.36093 2.29694 2.26009 1.79605 2.34036C1.29516 2.42063 0.83927 2.67677 0.51017 3.06281C0.181071 3.44885 0.000305198 3.93953 0.000305176 4.44681C0.000305153 4.95409 0.18107 5.44477 0.51017 5.83082C0.83927 6.21686 1.29516 6.47299 1.79605 6.55327C2.29694 6.63354 2.81004 6.5327 3.2433 6.26883C3.67656 6.00497 4.0016 5.59535 4.16013 5.11348H4.6268C5.01462 5.11525 5.38596 5.27055 5.65958 5.54541C5.93319 5.82027 6.0868 6.19231 6.0868 6.58015V8.71347C6.08856 9.64413 6.4595 10.5361 7.11818 11.1935C7.77685 11.8509 8.66952 12.2201 9.60012 12.2201H9.70678C9.84718 12.6469 10.1187 13.0184 10.4826 13.2817C10.8465 13.5451 11.2843 13.6869 11.7335 13.6868Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path\n    d=\"M11.7335 13.6868C12.1277 13.6917 12.5155 13.5873 12.8539 13.3851C13.1924 13.1829 13.4682 12.8909 13.6507 12.5415C13.8333 12.1921 13.9155 11.799 13.8881 11.4057C13.8608 11.0125 13.725 10.6345 13.4959 10.3137C13.2667 9.99287 12.9532 9.74187 12.5901 9.58847C12.2269 9.43507 11.8283 9.38527 11.4387 9.44467C11.0489 9.504 10.6833 9.6702 10.3823 9.9248C10.0813 10.1793 9.85672 10.5123 9.73345 10.8868H9.60012C9.02312 10.8868 8.46965 10.6581 8.06098 10.2507C7.65238 9.84327 7.42192 9.29047 7.42012 8.71347V6.58015C7.42345 6.06206 7.28012 5.5536 7.00678 5.11348H11.8401C11.9987 5.59536 12.3237 6.00497 12.757 6.26884C13.1902 6.5327 13.7033 6.63354 14.2042 6.55327C14.7051 6.47299 15.161 6.21686 15.4901 5.83082C15.8192 5.44477 16 4.95409 16 4.44681C16 3.93953 15.8192 3.44885 15.4901 3.06281C15.161 2.67677 14.7051 2.42063 14.2042 2.34036C13.7033 2.26009 13.1902 2.36093 12.757 2.62479C12.3237 2.88865 11.9987 3.29827 11.8401 3.78015H4.16013C4.0016 3.29827 3.67656 2.88865 3.2433 2.62479C2.81004 2.36093 2.29694 2.26009 1.79605 2.34036C1.29516 2.42063 0.83927 2.67677 0.51017 3.06281C0.181071 3.44885 0.000305198 3.93953 0.000305176 4.44681C0.000305153 4.95409 0.18107 5.44477 0.51017 5.83082C0.83927 6.21686 1.29516 6.47299 1.79605 6.55327C2.29694 6.63354 2.81004 6.5327 3.2433 6.26883C3.67656 6.00497 4.0016 5.59535 4.16013 5.11348H4.6268C5.01462 5.11525 5.38596 5.27055 5.65958 5.54541C5.93319 5.82027 6.0868 6.19231 6.0868 6.58015V8.71347C6.08856 9.64413 6.4595 10.5361 7.11818 11.1935C7.77685 11.8509 8.66952 12.2201 9.60012 12.2201H9.70678C9.84718 12.6469 10.1187 13.0184 10.4826 13.2817C10.8465 13.5451 11.2843 13.6869 11.7335 13.6868Z\" />\n</svg>\n";
export default src;
