/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get, includes, invert } from 'lodash'
import React from 'react'

export function check (data, config) {
  const value = parseFloat(data, 10)
  switch (config.type) {
    case 'equalTo':
      return !isNaN(value) && value === config.value
    case 'greaterThan':
      return value && value > config.value
    case 'lessThan':
      return value && value < config.value
    case 'greaterThanOrEqualTo':
      return value && value >= config.value
    case 'lessThanOrEqualTo':
      return !isNaN(value) && value <= config.value
    case 'notEqualTo':
      return value && value !== config.value
    case 'isEmpty':
      return isNaN(value)
    case 'isNotEmpty':
      return !isNaN(value)
    default:
      return true
  }
}

export function configIsValid (configData) {
  if (includes(['isEmpty', 'isNotEmpty'], configData.type)) return true
  return configData.type && configData.value
}

export function component ({ onChange, value }) {
  return (
    <div className='flex items-center gap-2'>
      <select
        className='kp-select flex-1'
        onChange={e => onChange({ ...value, type: e.target.value })}
        value={value.type || ''}
        aria-label='operand'
      >
        <option value=''>- - -</option>
        <option value='equalTo'>
          <Trans id='is.equal.to' />
        </option>
        <option value='notEqualTo'>
          <Trans id='is.not.equal.to' />
        </option>
        <option value='greaterThan'>
          <Trans id='is.greater.than' />
        </option>
        <option value='lessThan'>
          <Trans id='is.less.than' />
        </option>
        <option value='greaterThanOrEqualTo'>
          <Trans id='is.greater.than.or.equal' />
        </option>
        <option value='lessThanOrEqualTo'>
          <Trans id='is.less.than.or.equal' />
        </option>
        <option value='isEmpty'>
          <Trans id='is.empty' />
        </option>
        <option value='isNotEmpty'>
          <Trans id='is.not.empty' />
        </option>
      </select>
      {hasValue(value.type) && (
        <input
          className='kp-input flex-1'
          type='number'
          step='any'
          onChange={e => {
            const val = e.target.value
            if (val === '') return onChange({ ...value, value: '' })
            const number = parseFloat(val, 10)
            if (!isNaN(number)) return onChange({ ...value, value: number })
          }}
          value={get(value, 'value', '')}
        />
      )}
    </div>
  )
}

const hasValue = type => type && !includes(['isEmpty', 'isNotEmpty'], type)

const uiToWf = {
  equalTo: 'is',
  notEqualTo: 'is not',
  greaterThan: 'is greater than',
  lessThan: 'is less than',
  greaterThanOrEqualTo: 'is greater than or equal to',
  lessThanOrEqualTo: 'is less than or equal to',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  const newExpr = {
    left: {
      dataType: 'number',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
  if (hasValue(expr.data.type)) {
    newExpr.right = {
      dataType: 'number',
      value: expr.data.value
    }
  }
  return newExpr
}

export function fromWorkflowFormat (expr) {
  const newExpr = {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
  if (hasValue(newExpr.data.type)) {
    newExpr.data.value = expr.right.value
  }
  return newExpr
}
