/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { useAlerts } from '../ui/alerts'

export function useSingleAlert (type) {
  const alerts = useAlerts()
  const closeAlertsRef = React.useRef()
  React.useEffect(() => () => closeAlertsRef.current?.(), [])
  return React.useCallback(
    (...args) => {
      closeAlertsRef.current?.()
      closeAlertsRef.current = alerts[type](...args)
      return closeAlertsRef.current
    },
    [alerts[type]]
  )
}
