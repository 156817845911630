/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import { IntegrationsInner } from '../../api-integrations'

export default function IntegrationsPane () {
  const { space } = useOutletContext()
  return (
    <Wrapper>
      <IntegrationsInner id={space.id} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 48px 0;
  width: 858px;
  margin: 0 auto;

  & > .integrations {
    padding: 48px 0 0;
    > :nth-child(2) {
      margin-top: 0;
    }

    td:last-child {
      padding: 0;
    }
  }

  th,
  td {
    border: 1px solid var(--light-gray-300);
  }

  th {
    color: #666;
  }
`
