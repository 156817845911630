/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import * as VoronoiDND from '../voronoi-dnd'
import { getAriaLabel } from './utils'

export default props => {
  if (props.childrenTemplate?.length) return {}
  return {
    gadget: () => (
      <div className='p-8'>
        <VoronoiDND.Item
          id={`inner-${props.template.id}`}
          ariaLabel={getAriaLabel(props.template)}
          inside
        >
          <div className='flex h-[300px] items-center justify-center border border-dashed border-light-gray-400 bg-light-gray-100 text-center after:text-base after:text-[#767676] after:opacity-50 after:content-["Drag_Gadgets_Here_to_Add_Columns"] dark:bg-light-gray-300 dark:after:text-black' />
        </VoronoiDND.Item>
      </div>
    )
  }
}
