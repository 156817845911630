/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, map, pick } from 'lodash'
import React from 'react'

import * as Lookups from '../../../components/lookup-controls'
import useDebouncedValue from '../../../components/use-debounced-value'
import { useIntegrations } from '../form-typeahead/edit'

export default function Terms ({
  value,
  onChange,
  errors,
  details,
  className,
  ...props
}) {
  const [startQuery, setStartQuery] = React.useState('')
  const debouncedStartQuery = useDebouncedValue(startQuery, 300)

  const [endQuery, setEndQuery] = React.useState('')
  const debouncedEndQuery = useDebouncedValue(endQuery, 300)

  const { options: startTermOptions, loading: starTermLoading } =
    useIntegrations(details, debouncedStartQuery)
  const { options: endTermOptions, loading: endTermLoading } = useIntegrations(
    details,
    debouncedEndQuery
  )
  return (
    <div className='flex'>
      <div className='flex-1'>
        <div id={`${props.id}startTerm`} className='pl-4'>
          <Trans id='start.term' />
        </div>
        <Lookups.Typeahead
          id={props.id}
          className={className}
          options={startTermOptions}
          onChange={data => {
            if (!data) {
              onChange({
                ...value,
                label: `- ${value?.endTerm?.label ?? ''}`,
                startTerm: data
              })
              return setStartQuery('')
            }
            const pickedData = pick(data, [
              'id',
              'documentSetId',
              'label',
              ...map(details?.outputFields, 'path')
            ])
            onChange({
              ...value,
              label: `${data.label} - ${value?.endTerm?.label ?? ''}`,
              startTerm: {
                startDate: get(data, details.startDate.path),
                endDate: get(data, details.endDate.path),
                previousTermId: get(data, `${details.previousTerm.path}.id`),
                ...pickedData
              }
            })
          }}
          value={value?.startTerm}
          query={startQuery}
          setQuery={setStartQuery}
          aria={{
            labelledby: `${props['aria-labelledby']} ${props.id}startTerm`,
            describedby: props['aria-describedby']
          }}
          gridded={props.gridded}
          loading={starTermLoading}
        />
      </div>
      <div className='flex-1'>
        <div id={`${props.id}endTerm`} className='pl-4'>
          <Trans id='end.term' />
        </div>
        <Lookups.Typeahead
          id={props.id}
          className={className}
          options={[
            {
              label: i18n._('indefinite'),
              id: i18n._('indefinite'),
              indefinite: true
            },
            ...endTermOptions
          ]}
          onChange={data => {
            if (!data) {
              return onChange({
                ...value,
                label: `${value?.startTerm?.label ?? ''} - ${i18n._(
                  'indefinite'
                )}`,
                endTerm: data
              })
            }
            const pickedData = pick(data, [
              'id',
              'documentSetId',
              'label',
              'indefinite',
              ...map(details?.outputFields, 'path')
            ])
            onChange({
              ...value,
              label: `${value?.startTerm?.label ?? ''} - ${pickedData.label}`,
              endTerm: {
                startDate: get(data, details.startDate.path),
                endDate: get(data, details.endDate.path),
                ...pickedData
              }
            })
          }}
          value={value?.endTerm}
          query={endQuery}
          setQuery={setEndQuery}
          aria={{
            labelledby: `${props['aria-labelledby']} ${props.id}endTerm`,
            describedby: props['aria-describedby']
          }}
          gridded={props.gridded}
          loading={endTermLoading}
        />
      </div>
      {props.gridded && errors}
    </div>
  )
}
