/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { chain } from 'lodash'
import React from 'react'

import * as Lookups from '../lookup-controls'

const AFFILIATIONS_PREFIX = 'affiliations'

function generateAffiliationValue ({ affiliation, orgDN }) {
  return orgDN
    ? {
        id: `${AFFILIATIONS_PREFIX}:${affiliation}:${orgDN}`,
        label: `${affiliation} (${orgDN})`
      }
    : {
        id: `${AFFILIATIONS_PREFIX}:${affiliation}`,
        label: affiliation
      }
}

function generateAffiliationWildcardValue ({ affiliation }) {
  return {
    id: `${AFFILIATIONS_PREFIX}:${affiliation}:*`,
    label: `${affiliation} (*all*)`,
    wildcard: true
  }
}

export const Multiselect = ({
  id,
  standalone,
  hideResults,
  affiliations,
  placeholder,
  onAdd,
  onRemove,
  onChange,
  value,
  noDarkerModeDarkerBg,
  ...props
}) => {
  const [query, setQuery] = React.useState('')
  const options = React.useMemo(() => {
    const normalizedText = query.trim().toLowerCase()
    return chain(affiliations)
      .flatMap(affiliation => [
        generateAffiliationWildcardValue(affiliation),
        generateAffiliationValue(affiliation)
      ])
      .uniqBy(({ id }) => id)
      .sort((a, b) => (b.wildcard ? 1 : 0))
      .filter(value => value.label.toLowerCase().includes(normalizedText))
      .value()
  }, [query, affiliations])
  return (
    <Lookups.Multiselect
      id={id}
      standalone={standalone}
      hideResults={hideResults}
      placeholder={placeholder}
      options={options}
      onAdd={onAdd}
      onRemove={onRemove}
      onChange={onChange}
      value={value}
      query={query}
      setQuery={setQuery}
      aria={props['aria-labelledby']}
      gridded={props.gridded}
      noDarkerModeDarkerBg={noDarkerModeDarkerBg}
    />
  )
}
