/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import Assembler from './assembler'
import { OptionalConfig } from './config'
import { ReactComponent as Icon } from './icon.svg.jsx'

export default {
  layout: true,
  View: ({ children }) => <Wrapper>{children}</Wrapper>,
  Edit: ({ children }) => <Wrapper>{children}</Wrapper>,
  OptionalConfig,
  Assembler,
  meta: {
    label: 'Section',
    Icon,
    help: 'Sections allow you to create groups of fields with a header above each one. You can also configure your form to use the top-level sections to break up your form into multiple pages.',
    category: 'Layout'
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1;
  }
  overflow-wrap: anywhere;
`
