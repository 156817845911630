/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useWithdrawMutationContext = () => {
  const [withdraw] = useMutation(mutation)
  const query = useQueryContext()
  return (documentId, timezone) =>
    withdraw(getParams(documentId, timezone, query))
}

export const useWithdrawMutation = query => {
  const [withdraw] = useMutation(mutation)
  return (documentId, timezone) =>
    withdraw(getParams(documentId, timezone, query))
}

const mutation = gql`
  mutation WithdrawSubmission($id: ID!, $timezone: String!) {
    withdrawSubmission(args: { id: $id, timezone: $timezone }) {
      __typename
      ... on Success {
        success
      }

      ... on Error {
        reason
      }
    }
  }
`

const getParams = (documentId, timezone, query) => ({
  variables: {
    id: documentId,
    timezone
  },
  refetchQueries: [query]
})
