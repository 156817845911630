/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useRefetchQueries } from './use-refetch-queries'

export const useSendbackMutation = query => {
  const [sendback] = useMutation(mutation)
  const refetchQueries = useRefetchQueries(['ActionPage'])
  return (documentId, data, { stepId }, body) =>
    sendback({ variables: { documentId, data, stepId, body }, refetchQueries })
}

const mutation = gql`
  mutation sendbackDocument(
    $documentId: ID!
    $data: JSON
    $stepId: ID!
    $body: JSON
  ) {
    sendbackDocument(id: $documentId, data: $data, stepId: $stepId, body: $body)
  }
`
