/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.8333 7.68065C15.8333 7.45964 15.7455 7.24768 15.5893 7.0914C15.433 6.93511 15.221 6.84732 15 6.84732H7.54533C7.50113 6.84732 7.45873 6.82976 7.42748 6.7985C7.39622 6.76725 7.37866 6.72485 7.37866 6.68065V5.13332C7.37852 5.0675 7.35889 5.00319 7.32225 4.94851C7.28562 4.89383 7.23361 4.85122 7.17279 4.82605C7.11197 4.80088 7.04507 4.79428 6.9805 4.80709C6.91594 4.81989 6.85661 4.85151 6.81 4.89798L4.26666 7.44532C4.20417 7.50783 4.16907 7.5926 4.16907 7.68098C4.16907 7.76937 4.20417 7.85414 4.26666 7.91665L6.81266 10.462C6.87502 10.5245 6.95967 10.5598 7.048 10.56C7.09189 10.5598 7.13535 10.5512 7.176 10.5347C7.23683 10.5094 7.2888 10.4666 7.32535 10.4118C7.36189 10.357 7.38137 10.2925 7.38133 10.2267V8.68065C7.38133 8.63645 7.39889 8.59406 7.43015 8.5628C7.4614 8.53154 7.50379 8.51398 7.548 8.51398H15C15.221 8.51398 15.433 8.42619 15.5893 8.26991C15.7455 8.11363 15.8333 7.90166 15.8333 7.68065Z`}/><path d={`M1.00002 15.8327C1.22092 15.8327 1.43278 15.745 1.58904 15.5888C1.7453 15.4327 1.83318 15.2209 1.83335 15V1.00001C1.83335 0.778991 1.74556 0.56703 1.58928 0.410749C1.433 0.254469 1.22103 0.166672 1.00002 0.166672C0.779007 0.166672 0.567045 0.254469 0.410765 0.410749C0.254484 0.56703 0.166687 0.778991 0.166687 1.00001V15C0.166864 15.2209 0.254739 15.4327 0.411 15.5888C0.567261 15.745 0.779122 15.8327 1.00002 15.8327Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.8333 7.68065C15.8333 7.45964 15.7455 7.24768 15.5893 7.0914C15.433 6.93511 15.221 6.84732 15 6.84732H7.54533C7.50113 6.84732 7.45873 6.82976 7.42748 6.7985C7.39622 6.76725 7.37866 6.72485 7.37866 6.68065V5.13332C7.37852 5.0675 7.35889 5.00319 7.32225 4.94851C7.28562 4.89383 7.23361 4.85122 7.17279 4.82605C7.11197 4.80088 7.04507 4.79428 6.9805 4.80709C6.91594 4.81989 6.85661 4.85151 6.81 4.89798L4.26666 7.44532C4.20417 7.50783 4.16907 7.5926 4.16907 7.68098C4.16907 7.76937 4.20417 7.85414 4.26666 7.91665L6.81266 10.462C6.87502 10.5245 6.95967 10.5598 7.048 10.56C7.09189 10.5598 7.13535 10.5512 7.176 10.5347C7.23683 10.5094 7.2888 10.4666 7.32535 10.4118C7.36189 10.357 7.38137 10.2925 7.38133 10.2267V8.68065C7.38133 8.63645 7.39889 8.59406 7.43015 8.5628C7.4614 8.53154 7.50379 8.51398 7.548 8.51398H15C15.221 8.51398 15.433 8.42619 15.5893 8.26991C15.7455 8.11363 15.8333 7.90166 15.8333 7.68065Z\" />\n<path d=\"M1.00002 15.8327C1.22092 15.8327 1.43278 15.745 1.58904 15.5888C1.7453 15.4327 1.83318 15.2209 1.83335 15V1.00001C1.83335 0.778991 1.74556 0.56703 1.58928 0.410749C1.433 0.254469 1.22103 0.166672 1.00002 0.166672C0.779007 0.166672 0.567045 0.254469 0.410765 0.410749C0.254484 0.56703 0.166687 0.778991 0.166687 1.00001V15C0.166864 15.2209 0.254739 15.4327 0.411 15.5888C0.567261 15.745 0.779122 15.8327 1.00002 15.8327Z\" />\n</svg>\n";
export default src;
