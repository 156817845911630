/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

const resolve = (maybeFn, ...args) => {
  if (maybeFn instanceof Function) return maybeFn(...args)
  return maybeFn
}

export const useLocalStorageState = (
  key,
  initialValue,
  { jsonify = true } = {}
) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    const item = window.localStorage.getItem(key)
    return item ? parse(item, jsonify) : resolve(initialValue)
  })

  const setValue = value => {
    const valueToStore = resolve(value, storedValue)
    setStoredValue(valueToStore)
    window.localStorage.setItem(key, stringify(valueToStore, jsonify))
  }

  React.useEffect(() => {
    const handleChange = e => {
      if (e.key === key) setStoredValue(parse(e.newValue, jsonify))
    }
    window.addEventListener('storage', handleChange)

    return () => window.removeEventListener('change', handleChange)
  }, [storedValue])

  return [storedValue, setValue]
}

function parse (input, jsonify) {
  return jsonify ? JSON.parse(input) : input
}

function stringify (input, jsonify) {
  return jsonify ? JSON.stringify(input) : input
}
