/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './query-context'

export const useCreateUserMutation = () => {
  const q = useQueryContext()
  const [createUser] = useMutation(mutation, {
    refetchQueries: [q]
  })
  return data => createUser(getParams(data))
}

const mutation = gql`
  mutation CreateUser($data: JSON!) {
    createUser(data: $data) {
      ... on User {
        id
        firstName
        lastName
        displayName
        name
        username
        email
        role
        schoolId
        approved
        active
      }
      ... on InvalidFieldErrors {
        errors {
          field
          reason
        }
      }
    }
  }
`

const getParams = rawData => {
  const data = { ...rawData }
  if (data.role?.id) data.role = data.role.id
  return { variables: { data } }
}
