/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useDeleteLinkMutation = spaceId => {
  const [deleteLink] = useMutation(deleteLinkMutation)
  return id => deleteLink(getParams(spaceId, id))
}

const deleteLinkMutation = gql`
  mutation DeleteLink($spaceId: ID!, $id: ID!) {
    deleteLink(spaceId: $spaceId, id: $id) {
      query {
        space(id: $spaceId) {
          id
          links {
            id
          }
        }
      }
    }
  }
`

const getParams = (spaceId, id) => ({
  variables: { spaceId, id }
})
