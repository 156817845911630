/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

export default function SurveyGridView ({ details, id, value }) {
  const labels = get(details, 'labels', [])
  const questions = get(details, 'questions', [])
  return (
    <Wrapper>
      <table>
        <thead>
          <TR>
            <th />
            {map(labels, label => (
              <TH key={label.key}>{label.lbl}</TH>
            ))}
          </TR>
        </thead>
        <tbody>
          {map(questions, (question, i) => (
            <TR key={question.key} odd={i % 2 === 0}>
              <QuestionCell>{question.lbl}</QuestionCell>
              {map(labels, label => (
                <CheckboxCell key={label.key} odd={i % 2 === 0}>
                  <input
                    checked={value[question.key] === label.key}
                    disabled={value[question.key] !== label.key}
                    name={`${id}-${question.key}-view`}
                    readOnly
                    type='radio'
                  />
                </CheckboxCell>
              ))}
            </TR>
          ))}
        </tbody>
      </table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #555;
`

const TH = styled.th`
  padding: 0 20px;
`

const TR = styled.tr`
  height: 50px;
  background-color: ${p => (p.odd ? '#ccc' : 'inherit')};
`

const QuestionCell = styled.td`
  padding: 0 20px;
`

const CheckboxCell = styled.td`
  border-color: ${p => (p.odd ? 'white' : 'inherit')};
  border-left: 1px solid #ccc;
  text-align: center;
`
