/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M3 0C1.34315 0 0 1.34315 0 3V8C0 9.65685 1.34315 11 3 11H8C9.65685 11 11 9.65685 11 8V3C11 1.34315 9.65685 0 8 0H3ZM5 13V12.5H9C10.933 12.5 12.5 10.933 12.5 9V5H13C14.6569 5 16 6.34314 16 8V13C16 14.6569 14.6569 16 13 16H8C6.34315 16 5 14.6569 5 13Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 0C1.34315 0 0 1.34315 0 3V8C0 9.65685 1.34315 11 3 11H8C9.65685 11 11 9.65685 11 8V3C11 1.34315 9.65685 0 8 0H3ZM5 13V12.5H9C10.933 12.5 12.5 10.933 12.5 9V5H13C14.6569 5 16 6.34314 16 8V13C16 14.6569 14.6569 16 13 16H8C6.34315 16 5 14.6569 5 13Z\" />\n</svg>\n";
export default src;
