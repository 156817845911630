/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import { useTenantFeaturesContext } from './tenant-features-context'

export default function AppsOrProducts ({ lowercase = false }) {
  const { appCreationDisabled, hasBuild, hasNonBuildSuites } =
    useTenantFeaturesContext()

  if (hasNonBuildSuites) {
    if (hasBuild || !appCreationDisabled) {
      // Why not use CSS to lowercase, you ask? Because not all languages use
      // the same capitalization rules as English, so I thought it best
      // not to assume.
      return lowercase ? (
        <Trans id='apps.and.products.lowercase' />
      ) : (
        <Trans id='apps.and.products' />
      )
    }

    return lowercase ? (
      <Trans id='products.lowercase' />
    ) : (
      <Trans id='products' />
    )
  }

  return lowercase ? <Trans id='apps.lowercase' /> : <Trans id='apps' />
}
