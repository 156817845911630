/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default ({ children, className, date }) => (
  <div className='relative my-2.5 border border-light-gray-300 p-3 pb-0'>
    <div className='absolute left-[50px] top-[-7px] h-3 w-3 -rotate-45 border-r border-t border-light-gray-300 bg-white' />
    <div className={cx('whitespace-pre-wrap break-words', className)}>
      {children}
    </div>
    {date && <div className='text-right text-xs'>{date}</div>}
  </div>
)
