/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery } from '@apollo/client'
import { filter, flow, map } from 'lodash'
import React from 'react'

import * as Lookups from '../lookup-controls'
import useDebouncedValue from '../use-debounced-value'

const defaultFilter = role => {
  if (role.id2 === 'members') return false
  return true
}

const defaultMap = ({ id2, label, group }) => ({
  id: `${group.id}:${id2}`,
  label: `${group.label} - ${label}`
})

export const Multiselect = ({
  id,
  standalone,
  hideResults,
  placeholder,
  onAdd,
  onRemove,
  onChange,
  value,
  filterRoles = defaultFilter,
  mapRoles = defaultMap,
  noDarkerModeDarkerBg,
  ...props
}) => {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const [fetch, { data, loading }] = useLazyQuery(ROLES_QUERY)
  React.useEffect(() => {
    fetch({ variables: { query: debouncedQuery } })
  }, [debouncedQuery, fetch])
  const options = flow(
    data => data?.roles ?? [],
    roles => filter(roles, filterRoles),
    roles => map(roles, mapRoles)
  )(data)
  return (
    <Lookups.Multiselect
      id={id}
      standalone={standalone}
      hideResults={hideResults}
      placeholder={placeholder}
      options={options}
      onAdd={onAdd}
      onRemove={onRemove}
      onChange={onChange}
      value={value}
      query={query}
      setQuery={setQuery}
      aria={props['aria-labelledby']}
      gridded={props.gridded}
      loading={loading}
      noDarkerModeDarkerBg={noDarkerModeDarkerBg}
    />
  )
}

const ROLES_QUERY = gql`
  query RoleSearch($query: String!) {
    roles(args: { excludeAppBuilderGroups: true, query: $query }) {
      id
      id2: roleId
      label: name
      memberIds
      group {
        id
        label: name
      }
    }
  }
`
