/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export function validateShape (v) {
  return v.arrayOf(
    v.shape({
      id: v.string(),
      label: v.optional(v.string()),
      displayName: v.optional(v.string()),
      username: v.optional(v.string()),
      email: v.optional(v.string()),
      firstName: v.optional(v.string()),
      lastName: v.optional(v.string()),
      schoolId: v.optional(v.string())
    })
  )
}
