/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const uploadFile = async (url, formData, onProgress) => {
  const request = new XMLHttpRequest()
  await new Promise((resolve, reject) => {
    request.open('POST', url)
    request.upload.addEventListener('progress', onProgress)
    request.addEventListener('load', resolve)
    request.addEventListener('error', reject)
    request.addEventListener('abort', resolve)
    request.send(formData)
  })
}
