/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, flatten, map } from 'lodash'
import React from 'react'

import * as Lookups from '../../../components/lookup-controls'

function filterOptions (query, options) {
  if ('abbr' in options[0]) {
    const filteredOptions = filter(
      options,
      option =>
        option.lbl.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        option.abbr.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
    return map(filteredOptions, option => ({
      id: option.key,
      label: option.lbl,
      abbr: option.abbr
    }))
  } else {
    const filteredOptions = filter(
      options,
      option => option.lbl.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
    return map(filteredOptions, option => ({
      id: option.key,
      label: option.lbl
    }))
  }
}

export default function createEdit (_options, hasMultiple) {
  return function LocationEdit ({
    id,
    onChange: _onChange,
    errors,
    value,
    ...props
  }) {
    const [query, setQuery] = React.useState('')
    const options = filterOptions(
      query,
      hasMultiple ? flatten(map(_options, 'options')) : _options
    )
    return (
      <>
        <Lookups.Typeahead
          id={id}
          options={options}
          onChange={val => _onChange(val?.id ?? null)}
          format={val => val.id || val}
          value={value}
          query={query}
          setQuery={setQuery}
          aria={{
            labelledby: props['aria-labelledby'],
            describedby: props['aria-describedby']
          }}
          gridded={props.gridded}
        />
        {props.gridded && errors}
      </>
    )
  }
}
