/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M7.98812 -0.000899923C5.85052 0.0340949 3.81256 0.908909 2.31479 2.43443C1.56537 3.18055 0.974081 4.07011 0.576263 5.04993C0.178446 6.02976 -0.0177335 7.07974 -0.000547985 8.1371C-0.00177763 9.17029 0.200895 10.1936 0.595853 11.1483C0.990812 12.103 1.57029 12.9704 2.30109 13.7008C3.03188 14.4311 3.89962 15.0101 4.85457 15.4045C5.80953 15.7989 6.83293 16.0009 7.86612 15.9991H8.00878C10.147 15.9771 12.1892 15.1078 13.6873 13.5818C15.1853 12.0559 16.0169 9.99806 15.9995 7.85977C16.0019 6.81404 15.7952 5.77838 15.3916 4.81369C14.988 3.849 14.3955 2.97476 13.6491 2.24237C12.9027 1.50998 12.0174 0.934238 11.0452 0.548995C10.073 0.163752 9.03361 -0.0232109 7.98812 -0.000899923ZM6.99945 11.0271C6.99465 10.8964 7.01595 10.766 7.06212 10.6436C7.10829 10.5212 7.17839 10.4093 7.26834 10.3143C7.35828 10.2194 7.46626 10.1433 7.58596 10.0905C7.70565 10.0378 7.83467 10.0094 7.96545 10.0071H7.98345C8.24652 10.0076 8.49919 10.1099 8.68847 10.2926C8.87776 10.4753 8.98897 10.7242 8.99878 10.9871C9.00368 11.1178 8.98244 11.2482 8.93631 11.3707C8.89017 11.4931 8.82007 11.6051 8.7301 11.7001C8.64014 11.7951 8.53213 11.8711 8.41239 11.9239C8.29265 11.9766 8.1636 12.0049 8.03278 12.0071H8.01478C7.75181 12.0063 7.49934 11.9038 7.31012 11.7212C7.12091 11.5386 7.0096 11.2899 6.99945 11.0271ZM7.33278 8.33243V4.33243C7.33278 4.15562 7.40302 3.98605 7.52805 3.86103C7.65307 3.736 7.82264 3.66577 7.99945 3.66577C8.17626 3.66577 8.34583 3.736 8.47086 3.86103C8.59588 3.98605 8.66612 4.15562 8.66612 4.33243V8.33243C8.66612 8.50924 8.59588 8.67881 8.47086 8.80384C8.34583 8.92886 8.17626 8.9991 7.99945 8.9991C7.82264 8.9991 7.65307 8.92886 7.52805 8.80384C7.40302 8.67881 7.33278 8.50924 7.33278 8.33243Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M7.98812 -0.000899923C5.85052 0.0340949 3.81256 0.908909 2.31479 2.43443C1.56537 3.18055 0.974081 4.07011 0.576263 5.04993C0.178446 6.02976 -0.0177335 7.07974 -0.000547985 8.1371C-0.00177763 9.17029 0.200895 10.1936 0.595853 11.1483C0.990812 12.103 1.57029 12.9704 2.30109 13.7008C3.03188 14.4311 3.89962 15.0101 4.85457 15.4045C5.80953 15.7989 6.83293 16.0009 7.86612 15.9991H8.00878C10.147 15.9771 12.1892 15.1078 13.6873 13.5818C15.1853 12.0559 16.0169 9.99806 15.9995 7.85977C16.0019 6.81404 15.7952 5.77838 15.3916 4.81369C14.988 3.849 14.3955 2.97476 13.6491 2.24237C12.9027 1.50998 12.0174 0.934238 11.0452 0.548995C10.073 0.163752 9.03361 -0.0232109 7.98812 -0.000899923ZM6.99945 11.0271C6.99465 10.8964 7.01595 10.766 7.06212 10.6436C7.10829 10.5212 7.17839 10.4093 7.26834 10.3143C7.35828 10.2194 7.46626 10.1433 7.58596 10.0905C7.70565 10.0378 7.83467 10.0094 7.96545 10.0071H7.98345C8.24652 10.0076 8.49919 10.1099 8.68847 10.2926C8.87776 10.4753 8.98897 10.7242 8.99878 10.9871C9.00368 11.1178 8.98244 11.2482 8.93631 11.3707C8.89017 11.4931 8.82007 11.6051 8.7301 11.7001C8.64014 11.7951 8.53213 11.8711 8.41239 11.9239C8.29265 11.9766 8.1636 12.0049 8.03278 12.0071H8.01478C7.75181 12.0063 7.49934 11.9038 7.31012 11.7212C7.12091 11.5386 7.0096 11.2899 6.99945 11.0271ZM7.33278 8.33243V4.33243C7.33278 4.15562 7.40302 3.98605 7.52805 3.86103C7.65307 3.736 7.82264 3.66577 7.99945 3.66577C8.17626 3.66577 8.34583 3.736 8.47086 3.86103C8.59588 3.98605 8.66612 4.15562 8.66612 4.33243V8.33243C8.66612 8.50924 8.59588 8.67881 8.47086 8.80384C8.34583 8.92886 8.17626 8.9991 7.99945 8.9991C7.82264 8.9991 7.65307 8.92886 7.52805 8.80384C7.40302 8.67881 7.33278 8.50924 7.33278 8.33243Z\" />\n</svg>\n";
export default src;
