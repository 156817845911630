/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { useRover } from '../pages/home/components/use-rover'

const getAlt = name => `${_.lowerCase(name.replace('.svg', ''))} icon`

export default function IconPicker ({ icons, value, onChange, getSrc }) {
  const [iconRef, iconProps] = useRover({
    width: 7,
    max: icons.length,
    initial: () => _.findIndex(icons, icon => icon === value),
    onClick: onChange
  })

  return (
    <div className='max-h-36 overflow-auto'>
      <div
        ref={iconRef}
        className='grid w-56 grid-cols-7 rounded bg-light-gray-200 p-2 dark:bg-light-gray-300'
      >
        {icons.map((iconName, i) => (
          <button
            className={cx('grid h-7 w-7 cursor-pointer p-1', {
              'bg-black': value === iconName
            })}
            {...iconProps(i, iconName)}
            data-testid={i === 0 ? 'first-icon' : ''}
            key={iconName}
          >
            <img
              src={getSrc(iconName)}
              alt={getAlt(iconName)}
              className={cx({ invert: value === iconName }, 'dark:invert')}
            />
          </button>
        ))}
      </div>
    </div>
  )
}
