/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { cloneDeep, filter, keyBy, mapValues } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

import { Tooltip, TooltipTrigger } from '../../../components/new-tooltip'
import sanitize from '../../../components/sanitize'
import { AlertHelp } from '../../../icons'
import * as fb from '../../engine/formbot/utils'

export const newRow = (gadgets, formbot, isFooter) => ({
  ...mapValues(keyBy(filter(gadgets, 'formKey'), 'formKey'), ({ type }) =>
    cloneDeep(formbot.getGadget(type).defaultValue ?? null)
  ),
  _rowId: shortid.generate(),
  _isFooter: isFooter
})

export function removeRowBasedProgDisc (g) {
  const { conditionalVisibility, ...rest } = g
  const parts = conditionalVisibility?.value?.parts || []
  return parts.find(part => part.formKey.includes('.*.')) ? rest : g
}

export function finalizeRowBasedProgDisc (g, i) {
  const parts = g.conditionalVisibility?.value?.parts || []
  if (!parts.length) return g
  const gg = cloneDeep(g)
  gg.conditionalVisibility.value.parts = parts.map(part => ({
    ...part,
    formKey: part.formKey.replace('.*.', `.${i}.`)
  }))
  return gg
}

export function getCellGadgets (childrenTemplate, formKey, i, formbot) {
  const extraGadgets = childrenTemplate
    .filter(gadget => gadget.formKey)
    .map(g => ({ ...g, formKey: `data.${formKey}.${i}.${g.formKey}` }))
  return fb
    .gatherAllSubGadgets(extraGadgets, formbot)
    .map(g => finalizeRowBasedProgDisc(g, i))
}

export const HiddenGadget = styled.div`
  background: #eee;
  html.dark & {
    // Outlier: dark:bg-light-gray-500
    background: #555;
  }
  height: 100%;
`

export const Description = ({ description, id, labelSize }) => {
  if (!description?.enabled) return null
  return (
    <TooltipTrigger id={`gadget-desc-${id}`} className='relative inline-block'>
      <AlertHelp
        className='fill-blue-500 dark:fill-medium-gray-300'
        label={i18n._('column.information')}
        ml={1}
        role='img'
        alt='Column Information'
      />
      <Tooltip
        id={`gadget-desc-${id}`}
        position='bottom'
        className='z-10 min-w-max'
      >
        <DescTooltip
          helpSize={getHelpSize(labelSize)}
          className='ql-editor ql-snow'
          dangerouslySetInnerHTML={{
            __html: sanitize(description.value || '')
          }}
        />
      </Tooltip>
    </TooltipTrigger>
  )
}

function getHelpSize (labelSize) {
  const size = labelSize ?? 'medium'
  if (size === 'huge') return 20
  if (size === 'extra-large') return 18
  if (size === 'large') return 16
  return 14
}

const DescTooltip = styled.p`
  color: white;
  font-size: ${p => p.helpSize}px;
  margin-bottom: 0;
  max-width: 300px;
  white-space: normal;
  padding: 0 !important;
  height: initial;
  min-height: initial !important;
  border: 0;
  & > p {
    color: white;
  }
  & p {
    line-height: 1.42;
    color: #fff;
    font-size: ${p => p.helpSize}px;
  }
  & a {
    color: #9bcceb;
  }
`
