/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'

const sharedPageQuery = gql`
  query SharedPageAppQuery($appId: ID!, $sharedAppId: ID!) {
    app(id: $appId, isConfiguration: true) {
      id
      sharedWithMe {
        app(id: $sharedAppId) {
          id
          name
          type
          tileOptions {
            backgroundColor
            iconName
          }
          datasets {
            id
            label
            icon
            allowNewVersions
          }
        }
      }
    }
  }
`

export function useLatestDatasetInfo (
  appId,
  sharedAppId,
  sharedPageId = sharedAppId
) {
  const { data } = useQuery(sharedPageQuery, {
    fetchPolicy: 'network-only',
    variables: {
      appId,
      sharedAppId
    },
    skip: !sharedAppId
  })
  return getDatasetInfo(data?.app?.sharedWithMe?.app, sharedPageId)
}

export function getDatasetInfo (sharedApp, sharedPageId = sharedApp?.id) {
  const appName = sharedApp?.name
  const dataset = sharedApp?.datasets.find(({ id }) => id === sharedPageId)
  const tileOptions = { ...sharedApp?.tileOptions, datasetIcon: dataset?.icon }
  return dataset
    ? {
        label:
          sharedApp.type === 'app' ? appName : `${appName} - ${dataset.label}`,
        tileOptions,
        allowNewVersions: dataset.allowNewVersions
      }
    : null
}
