/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { debounce, isFunction, partition } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import * as Icons from '../icons'
import Button from './button'
import { Flex, Wrapper } from './layout'

const Context = React.createContext({
  type1: (title, content, level, actionButton, skipCancel) => null,
  type2: (content, level, actionButton, skipCancel, cancelContent) => null,
  type3: (content, level, timeout) => null,
  debouncedType3: (content, level) => null
})

export const useAlerts = () => React.useContext(Context)

export const useAlertTracker = () => {
  const [closers, setClosers] = React.useState([])
  const trackAlert = closeAlert => {
    setClosers(closers => [closeAlert, ...closers])
    return closeAlert
  }
  const closeTrackedAlerts = () => {
    closers.forEach(close => close())
  }
  return [trackAlert, closeTrackedAlerts]
}

export const AlertsProvider = ({ children }) => {
  const [alerts, updateAlerts] = useImmer([])

  const remove = React.useCallback(
    id => {
      updateAlerts(draft => {
        const alert = draft.find(alert => alert.id === id)
        if (!alert) return
        alert.closing = true
      })
      setTimeout(() => {
        updateAlerts(draft => {
          const i = draft.findIndex(alert => alert.id === id)
          if (i === -1) return
          draft.splice(i, 1)
        })
      }, 1000)
    },
    [updateAlerts]
  )

  const value = React.useMemo(() => {
    const create = (
      type,
      title,
      content,
      level,
      actionButton,
      skipCancel,
      timeout,
      cancelContent
    ) => {
      const id = shortid.generate()
      updateAlerts(draft => {
        draft.unshift({
          id,
          type,
          title,
          content,
          level,
          actionButton,
          skipCancel,
          timeout,
          cancelContent
        })
      })
      return () => remove(id)
    }
    const type1 = (title, content, level, actionButton, skipCancel) =>
      create(1, title, content, level, actionButton, skipCancel)
    const type2 = (content, level, actionButton, skipCancel, cancelContent) =>
      create(
        2,
        null,
        content,
        level,
        actionButton,
        skipCancel,
        undefined,
        cancelContent
      )

    const type3 = (content, level, timeout) =>
      create(3, null, content, level, null, null, timeout)
    const debouncedType3 = debounce(type3, 2000)
    return { type1, type2, type3, debouncedType3 }
  }, [updateAlerts, remove])

  const [topAlerts, bottomAlerts] = partition(alerts, a => a.type !== 3)

  return (
    <Context.Provider value={value}>
      <AlertsWrapper top id='alerts-container'>
        {topAlerts.map(props => (
          <Alert key={props.id} top remove={remove} {...props} />
        ))}
      </AlertsWrapper>
      <AlertsWrapper id='bottom-alerts-container'>
        {bottomAlerts.map(props => (
          <Alert key={props.id} remove={remove} {...props} />
        ))}
      </AlertsWrapper>
      {children}
    </Context.Provider>
  )
}

function Alert ({
  id,
  top,
  type,
  title,
  content,
  level,
  remove,
  closing,
  actionButton,
  skipCancel = false,
  timeout = 4000,
  cancelContent
}) {
  const [open, setOpen] = React.useState(false)
  const close = React.useCallback(() => remove(id), [remove, id])
  React.useEffect(() => {
    const timeoutId = setTimeout(() => setOpen(true))
    return () => clearTimeout(timeoutId)
  }, [])
  React.useEffect(() => {
    if (type !== 3) return
    const timeoutId = setTimeout(close, timeout)
    return () => clearTimeout(timeoutId)
  }, [type, close])
  const [color, icon] = levels[level] || levels.info
  const state = closing ? 'closed' : open ? 'open' : 'init'

  return (
    <AlertWrapper state={state}>
      <AlertInner
        role='alert'
        top={top}
        state={state}
        type={type}
        className='bg-white text-dark-gray-500 dark:bg-light-gray-300'
      >
        <Color color={color} width={type === 3 ? '16px' : '48px'}>
          {type === 3 ? null : icon}
        </Color>
        <Wrapper
          px={type === 1 ? '24px' : '16px'}
          py={type === 3 ? '8px' : '16px'}
        >
          {title && <Title>{title}</Title>}
          {isFunction(content) ? content(close) : content}
          {type === 1 && (
            <ButtonPair>
              {!skipCancel && (
                <Button
                  transparent
                  mr={2}
                  onClick={close}
                  data-testid='confirm-cancel'
                >
                  <Trans id='cancel' />
                </Button>
              )}
              {isFunction(actionButton) ? actionButton(close) : actionButton}
            </ButtonPair>
          )}
          {type === 2 && !skipCancel ? (
            <Button transparent small ml={5} onClick={close}>
              {actionButton ? (
                getCancelContent(cancelContent, close)
              ) : (
                <Trans id='dismiss' />
              )}
            </Button>
          ) : null}
          {type === 2 &&
            actionButton &&
            (isFunction(actionButton) ? actionButton(close) : actionButton)}
        </Wrapper>
      </AlertInner>
    </AlertWrapper>
  )
}

function getCancelContent (cancelContent, close) {
  return isFunction(cancelContent) ? (
    cancelContent(close)
  ) : (
    <Trans id='dismiss' />
  )
}

/* eslint-disable react/jsx-key */
const levels = {
  info: ['#3369A3', <Icons.AlertInfo fill='#fff' />],
  warning: ['#EF6C05', <Icons.AlertWarning fill='#fff' />],
  error: [
    'var(--red-400)',
    <Icons.AlertError fill='#fff' width={22} height={22} ml={2} mr={2} />
  ],
  success: ['#49AA30 ', <Icons.AlertInfo fill='#fff' />],
  confirm: ['#EF6C05', <Icons.AlertError fill='#fff' width={22} height={22} />]
}
/* eslint-enable react/jsx-key */

const AlertsWrapper = styled(Flex)`
  position: fixed;
  z-index: 51;
  width: 100vw;
  height: 0;
  flex-direction: ${p => (p.top ? 'column' : 'column-reverse')};
  ${p => (p.top ? 'top' : 'bottom')}: 12px;
`

const AlertWrapper = styled.div`
  height: ${p => (p.state === 'init' ? 0 : 200)}px;
  padding: ${p => (p.state === 'init' ? 0 : '12px 0')}px;
  transition: all 400ms;
`

const AlertInner = styled.div`
  position: relative;
  box-shadow:
    0px 8px 10px rgba(0, 0, 0, 0.14),
    0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: ${p => (p.type === 1 ? '16' : '14')}px;
  ${p => p.type === 1 && 'line-height: 24px;'}
  display: flex;
  ${p => (p.top ? 'top' : 'bottom')}: ${p =>
    p.state === 'init' ? -200 : p.state === 'open' ? 0 : 50}px;
  opacity: ${p => (p.state === 'closed' ? 0 : 1)};
  transition: all 400ms;
`

const Color = styled(Wrapper)`
  background: ${p => p.color};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Wrapper)`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 16px;
  line-height: 28px;
`

const ButtonPair = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
`
