/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.5366 3.38338C15.2229 2.77296 14.7705 2.24463 14.2155 1.84081C13.6606 1.43699 13.0187 1.16895 12.3414 1.05821C11.6641 0.947478 10.9702 0.997136 10.3156 1.2032C9.66093 1.40926 9.06374 1.76598 8.57198 2.24471L7.99998 2.76538L7.44798 2.26271C6.95737 1.77488 6.35747 1.41111 5.69809 1.20159C5.0387 0.992078 4.33886 0.942868 3.65664 1.05805C2.97834 1.16408 2.3351 1.43036 1.78031 1.83477C1.22552 2.23918 0.775172 2.77007 0.466642 3.38338C0.0543584 4.19132 -0.0897121 5.10964 0.0553426 6.00502C0.200397 6.9004 0.627028 7.72626 1.27331 8.36271L7.52198 14.8C7.58411 14.864 7.65842 14.9148 7.74052 14.9494C7.82262 14.9841 7.91085 15.002 7.99998 15.002C8.08911 15.002 8.17733 14.9841 8.25943 14.9494C8.34153 14.9148 8.41585 14.864 8.47798 14.8L14.7173 8.37471C15.3668 7.73796 15.7965 6.91071 15.9438 6.01313C16.0912 5.11555 15.9485 4.19437 15.5366 3.38338ZM13.7713 7.43405L8.23798 13.1307C8.20691 13.1627 8.16975 13.1881 8.1287 13.2054C8.08765 13.2228 8.04354 13.2317 7.99898 13.2317C7.95441 13.2317 7.9103 13.2228 7.86925 13.2054C7.8282 13.1881 7.79104 13.1627 7.75998 13.1307L2.21798 7.42405C1.77064 6.98556 1.47472 6.41589 1.37318 5.79778C1.27164 5.17966 1.36977 4.54527 1.65331 3.98671C1.86796 3.56097 2.18113 3.1926 2.56679 2.91223C2.95244 2.63186 3.39944 2.44758 3.87064 2.37471C4.35052 2.29397 4.84276 2.3295 5.30607 2.47833C5.76938 2.62716 6.19023 2.88494 6.53331 3.23005L7.55331 4.16338C7.67609 4.27525 7.83621 4.33725 8.00231 4.33725C8.16841 4.33725 8.32853 4.27525 8.45131 4.16338L9.49198 3.21471C9.83371 2.87473 10.2516 2.62116 10.711 2.47507C11.1704 2.32898 11.658 2.29457 12.1333 2.37471C12.6039 2.44797 13.0503 2.63244 13.4354 2.9128C13.8204 3.19315 14.1331 3.56131 14.3473 3.98671C14.631 4.54776 14.7281 5.18473 14.6245 5.80481C14.5209 6.4249 14.222 6.9957 13.7713 7.43405Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.5366 3.38338C15.2229 2.77296 14.7705 2.24463 14.2155 1.84081C13.6606 1.43699 13.0187 1.16895 12.3414 1.05821C11.6641 0.947478 10.9702 0.997136 10.3156 1.2032C9.66093 1.40926 9.06374 1.76598 8.57198 2.24471L7.99998 2.76538L7.44798 2.26271C6.95737 1.77488 6.35747 1.41111 5.69809 1.20159C5.0387 0.992078 4.33886 0.942868 3.65664 1.05805C2.97834 1.16408 2.3351 1.43036 1.78031 1.83477C1.22552 2.23918 0.775172 2.77007 0.466642 3.38338C0.0543584 4.19132 -0.0897121 5.10964 0.0553426 6.00502C0.200397 6.9004 0.627028 7.72626 1.27331 8.36271L7.52198 14.8C7.58411 14.864 7.65842 14.9148 7.74052 14.9494C7.82262 14.9841 7.91085 15.002 7.99998 15.002C8.08911 15.002 8.17733 14.9841 8.25943 14.9494C8.34153 14.9148 8.41585 14.864 8.47798 14.8L14.7173 8.37471C15.3668 7.73796 15.7965 6.91071 15.9438 6.01313C16.0912 5.11555 15.9485 4.19437 15.5366 3.38338ZM13.7713 7.43405L8.23798 13.1307C8.20691 13.1627 8.16975 13.1881 8.1287 13.2054C8.08765 13.2228 8.04354 13.2317 7.99898 13.2317C7.95441 13.2317 7.9103 13.2228 7.86925 13.2054C7.8282 13.1881 7.79104 13.1627 7.75998 13.1307L2.21798 7.42405C1.77064 6.98556 1.47472 6.41589 1.37318 5.79778C1.27164 5.17966 1.36977 4.54527 1.65331 3.98671C1.86796 3.56097 2.18113 3.1926 2.56679 2.91223C2.95244 2.63186 3.39944 2.44758 3.87064 2.37471C4.35052 2.29397 4.84276 2.3295 5.30607 2.47833C5.76938 2.62716 6.19023 2.88494 6.53331 3.23005L7.55331 4.16338C7.67609 4.27525 7.83621 4.33725 8.00231 4.33725C8.16841 4.33725 8.32853 4.27525 8.45131 4.16338L9.49198 3.21471C9.83371 2.87473 10.2516 2.62116 10.711 2.47507C11.1704 2.32898 11.658 2.29457 12.1333 2.37471C12.6039 2.44797 13.0503 2.63244 13.4354 2.9128C13.8204 3.19315 14.1331 3.56131 14.3473 3.98671C14.631 4.54776 14.7281 5.18473 14.6245 5.80481C14.5209 6.4249 14.222 6.9957 13.7713 7.43405Z\" />\n</svg>\n";
export default src;
