/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M2.16667 10.1667C3.36328 10.1667 4.33333 9.19661 4.33333 7.99999C4.33333 6.80338 3.36328 5.83333 2.16667 5.83333C0.97005 5.83333 0 6.80338 0 7.99999C0 9.19661 0.97005 10.1667 2.16667 10.1667Z`}/><path d={`M8.00001 10.1667C9.19663 10.1667 10.1667 9.19661 10.1667 7.99999C10.1667 6.80338 9.19663 5.83333 8.00001 5.83333C6.80339 5.83333 5.83334 6.80338 5.83334 7.99999C5.83334 9.19661 6.80339 10.1667 8.00001 10.1667Z`}/><path d={`M13.8333 10.1667C15.0299 10.1667 16 9.19661 16 7.99999C16 6.80338 15.0299 5.83333 13.8333 5.83333C12.6367 5.83333 11.6667 6.80338 11.6667 7.99999C11.6667 9.19661 12.6367 10.1667 13.8333 10.1667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M2.16667 10.1667C3.36328 10.1667 4.33333 9.19661 4.33333 7.99999C4.33333 6.80338 3.36328 5.83333 2.16667 5.83333C0.97005 5.83333 0 6.80338 0 7.99999C0 9.19661 0.97005 10.1667 2.16667 10.1667Z\" />\n<path d=\"M8.00001 10.1667C9.19663 10.1667 10.1667 9.19661 10.1667 7.99999C10.1667 6.80338 9.19663 5.83333 8.00001 5.83333C6.80339 5.83333 5.83334 6.80338 5.83334 7.99999C5.83334 9.19661 6.80339 10.1667 8.00001 10.1667Z\" />\n<path d=\"M13.8333 10.1667C15.0299 10.1667 16 9.19661 16 7.99999C16 6.80338 15.0299 5.83333 13.8333 5.83333C12.6367 5.83333 11.6667 6.80338 11.6667 7.99999C11.6667 9.19661 12.6367 10.1667 13.8333 10.1667Z\" />\n</svg>\n";
export default src;
