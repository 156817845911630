/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default function Picker ({ name, legend, options, className }) {
  return (
    <fieldset className={cx('flex flex-col gap-2', className)}>
      <legend className='sr-only'>{legend}</legend>
      {options.map(option => (
        <NosePicker name={name} key={option.label} option={option} />
      ))}
    </fieldset>
  )
}

function NosePicker ({ name, option }) {
  const { checked, onChange, value, label, optionalLabel } = option
  return (
    <label>
      <input
        type='radio'
        className='kp-radio pointer-events-none fixed opacity-0'
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
      />
      <span
        className={cx('rounded border p-3', {
          'border-blue-500 bg-blue-100': checked,
          'border-light-gray-300 bg-white hover:border-light-gray-500': !checked
        })}
      >
        {label}
        {optionalLabel && (
          <small className='block text-xs text-dark-gray-200'>
            {optionalLabel}
          </small>
        )}
      </span>
    </label>
  )
}
