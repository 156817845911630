/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608968 4.93853C0.00346613 6.40035 -0.15496 8.00888 0.153721 9.56072C0.462402 11.1126 1.22433 12.538 2.34315 13.6568C3.46197 14.7757 4.88744 15.5376 6.43928 15.8463C7.99113 16.1549 9.59967 15.9965 11.0615 15.3911C12.5233 14.7856 13.7727 13.7601 14.6517 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84344 13.6568 2.34315C12.1566 0.842855 10.1217 0 8 0ZM3.52667 3.52667C4.59613 2.48479 5.99529 1.84824 7.4834 1.72657C8.97151 1.60489 10.4555 2.00569 11.68 2.86C11.6996 2.87627 11.7153 2.89664 11.7262 2.91969C11.737 2.94273 11.7426 2.96788 11.7426 2.99333C11.7426 3.01879 11.737 3.04393 11.7262 3.06697C11.7153 3.09003 11.6996 3.1104 11.68 3.12667L3.11333 11.7067C3.09647 11.7253 3.07588 11.7401 3.05292 11.7503C3.02996 11.7605 3.00512 11.7657 2.98 11.7657C2.95489 11.7657 2.93005 11.7605 2.90708 11.7503C2.88412 11.7401 2.86355 11.7253 2.84667 11.7067C1.97275 10.4881 1.56004 8.99883 1.68215 7.50429C1.80424 6.00976 2.45321 4.60717 3.51333 3.54667L3.52667 3.52667ZM12.5 12.5067C11.4372 13.5603 10.0365 14.2049 8.54491 14.3268C7.05332 14.4488 5.56655 14.0403 4.34667 13.1733C4.32709 13.1571 4.31133 13.1367 4.30052 13.1136C4.28969 13.0906 4.28409 13.0655 4.28409 13.04C4.28409 13.0145 4.28969 12.9894 4.30052 12.9664C4.31133 12.9433 4.32709 12.9229 4.34667 12.9067L12.9133 4.32667C12.9296 4.30708 12.95 4.29132 12.973 4.28051C12.9961 4.26969 13.0212 4.26409 13.0467 4.26409C13.0721 4.26409 13.0973 4.26969 13.1203 4.28051C13.1434 4.29132 13.1637 4.30708 13.18 4.32667C14.0539 5.54519 14.4667 7.03451 14.3445 8.52904C14.2224 10.0236 13.5735 11.4261 12.5133 12.4867L12.5 12.5067Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608968 4.93853C0.00346613 6.40035 -0.15496 8.00888 0.153721 9.56072C0.462402 11.1126 1.22433 12.538 2.34315 13.6568C3.46197 14.7757 4.88744 15.5376 6.43928 15.8463C7.99113 16.1549 9.59967 15.9965 11.0615 15.3911C12.5233 14.7856 13.7727 13.7601 14.6517 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84344 13.6568 2.34315C12.1566 0.842855 10.1217 0 8 0ZM3.52667 3.52667C4.59613 2.48479 5.99529 1.84824 7.4834 1.72657C8.97151 1.60489 10.4555 2.00569 11.68 2.86C11.6996 2.87627 11.7153 2.89664 11.7262 2.91969C11.737 2.94273 11.7426 2.96788 11.7426 2.99333C11.7426 3.01879 11.737 3.04393 11.7262 3.06697C11.7153 3.09003 11.6996 3.1104 11.68 3.12667L3.11333 11.7067C3.09647 11.7253 3.07588 11.7401 3.05292 11.7503C3.02996 11.7605 3.00512 11.7657 2.98 11.7657C2.95489 11.7657 2.93005 11.7605 2.90708 11.7503C2.88412 11.7401 2.86355 11.7253 2.84667 11.7067C1.97275 10.4881 1.56004 8.99883 1.68215 7.50429C1.80424 6.00976 2.45321 4.60717 3.51333 3.54667L3.52667 3.52667ZM12.5 12.5067C11.4372 13.5603 10.0365 14.2049 8.54491 14.3268C7.05332 14.4488 5.56655 14.0403 4.34667 13.1733C4.32709 13.1571 4.31133 13.1367 4.30052 13.1136C4.28969 13.0906 4.28409 13.0655 4.28409 13.04C4.28409 13.0145 4.28969 12.9894 4.30052 12.9664C4.31133 12.9433 4.32709 12.9229 4.34667 12.9067L12.9133 4.32667C12.9296 4.30708 12.95 4.29132 12.973 4.28051C12.9961 4.26969 13.0212 4.26409 13.0467 4.26409C13.0721 4.26409 13.0973 4.26969 13.1203 4.28051C13.1434 4.29132 13.1637 4.30708 13.18 4.32667C14.0539 5.54519 14.4667 7.03451 14.3445 8.52904C14.2224 10.0236 13.5735 11.4261 12.5133 12.4867L12.5 12.5067Z\" />\n</svg>\n";
export default src;
