/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export default function build (content, variableFill = 'rgb(47, 132, 187)') {
  const variableTextColor = '#fff'
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const font = '13px sans-serif'

  ctx.font = font // set font to measure text width
  const width = +ctx.measureText(content).width.toFixed(0)
  const height = 23

  canvas.width = width + 24
  canvas.height = height

  ctx.fillStyle = variableFill
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = variableTextColor
  ctx.font = font
  ctx.fillText(content, canvas.width / 2, canvas.height / 2)

  return canvas.toDataURL('image/jpeg', 1.0)
}
