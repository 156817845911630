/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import Assembler from './assembler'
import { ReactComponent as Icon } from './icon.svg.jsx'

export default {
  layout: true,

  Assembler,

  View: () => <div />,

  Edit: () => <div />,

  meta: {
    initialTemplate: () => ({
      label: '',
      description: { enabled: true }
    }),
    label: 'Read-Only Text',
    Icon,
    category: 'Layout'
  }
}
