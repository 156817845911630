/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8.03811 2.97949C8.25617 2.97922 8.47178 3.02546 8.67055 3.11513C8.86932 3.2048 9.04667 3.33584 9.19078 3.49949L15.7314 10.9342C15.9382 11.1805 16.0403 11.498 16.016 11.8187C15.9917 12.1394 15.8429 12.4378 15.6014 12.6502C15.3599 12.8626 15.0449 12.9721 14.7238 12.9553C14.4026 12.9385 14.1007 12.7966 13.8828 12.5602L8.16345 6.05882C8.1478 6.04098 8.12853 6.02668 8.10692 6.01688C8.0853 6.00709 8.06185 6.00202 8.03811 6.00202C8.01438 6.00202 7.99093 6.00709 7.96931 6.01688C7.9477 6.02668 7.92842 6.04098 7.91278 6.05882L2.19345 12.5602C2.08796 12.6859 1.9584 12.7892 1.81239 12.8642C1.66639 12.9391 1.50687 12.9841 1.34323 12.9965C1.17959 13.0089 1.01512 12.9884 0.859486 12.9364C0.703855 12.8843 0.560203 12.8016 0.436974 12.6933C0.313746 12.5849 0.213428 12.4529 0.141915 12.3052C0.0704024 12.1575 0.0291385 11.997 0.0205492 11.8331C0.0119599 11.6692 0.0362189 11.5053 0.0918993 11.3509C0.14758 11.1965 0.233557 11.0548 0.344781 10.9342L6.88345 3.50216C7.02781 3.3381 7.20543 3.20664 7.40452 3.11653C7.6036 3.02641 7.81958 2.9797 8.03811 2.97949V2.97949Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8.03811 2.97949C8.25617 2.97922 8.47178 3.02546 8.67055 3.11513C8.86932 3.2048 9.04667 3.33584 9.19078 3.49949L15.7314 10.9342C15.9382 11.1805 16.0403 11.498 16.016 11.8187C15.9917 12.1394 15.8429 12.4378 15.6014 12.6502C15.3599 12.8626 15.0449 12.9721 14.7238 12.9553C14.4026 12.9385 14.1007 12.7966 13.8828 12.5602L8.16345 6.05882C8.1478 6.04098 8.12853 6.02668 8.10692 6.01688C8.0853 6.00709 8.06185 6.00202 8.03811 6.00202C8.01438 6.00202 7.99093 6.00709 7.96931 6.01688C7.9477 6.02668 7.92842 6.04098 7.91278 6.05882L2.19345 12.5602C2.08796 12.6859 1.9584 12.7892 1.81239 12.8642C1.66639 12.9391 1.50687 12.9841 1.34323 12.9965C1.17959 13.0089 1.01512 12.9884 0.859486 12.9364C0.703855 12.8843 0.560203 12.8016 0.436974 12.6933C0.313746 12.5849 0.213428 12.4529 0.141915 12.3052C0.0704024 12.1575 0.0291385 11.997 0.0205492 11.8331C0.0119599 11.6692 0.0362189 11.5053 0.0918993 11.3509C0.14758 11.1965 0.233557 11.0548 0.344781 10.9342L6.88345 3.50216C7.02781 3.3381 7.20543 3.20664 7.40452 3.11653C7.6036 3.02641 7.81958 2.9797 8.03811 2.97949V2.97949Z\" />\n</svg>\n";
export default src;
