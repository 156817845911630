/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import { get, map, xor } from 'lodash'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled, { css } from 'styled-components'

import PopoverButton from '../../components/data-table/popover-button'
import { winnowList } from '../../components/escape-string-regexp'
import { flumeIntegrations } from '../../components/feature-flags'
import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import SearchBar from '../../components/search-bar'
import { StaticOutlet } from '../../components/static-outlet'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Button from '../../ui/button'
import Checkbox from '../../ui/indeterminate-checkbox'
import InfoBox from '../../ui/info-box'
import SpaceFinder from '../spaces/components/space-finder'
import { useMoveIntegrationsToSpaceMutation } from './components/mutation.move-integrations-to-space'
import { useRemoveIntegrationMutation } from './components/mutation.remove-integration'
import { QueryContextProvider } from './components/use-query-context'

export default function Integrations () {
  return (
    <ModalPage title={i18n._('system.api.integrations')}>
      <IntegrationsInner />
    </ModalPage>
  )
}

function useSort () {
  const [sortState, setSortState] = React.useState({})
  const toggleSort = React.useCallback(field => {
    setSortState(sort => ({
      [field]: sort[field] === 1 ? -1 : 1
    }))
  }, [])
  return [sortState, toggleSort]
}

export function IntegrationsInner ({ id }) {
  const [sort, toggleSort] = useSort()
  const sortValue = React.useMemo(() => {
    return (
      Object.entries(sort)
        .map(([key, dir]) => (dir === 1 ? key : `-${key}`))
        .join(',') || null
    )
  }, [sort])
  const q = getIntegrationsQuery({
    id,
    sort: sortValue
  })
  const { loading, error, data } = useQuery(q)
  const integrations = map(get(data, 'integrationsConnection.edges'), 'node')
  return (
    <QueryContextProvider query={q}>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <SystemSettings
          spaceId={id}
          integrations={integrations}
          sort={sort}
          sortValue={sortValue}
          toggleSort={toggleSort}
        />
      )}
    </QueryContextProvider>
  )
}

function SystemSettings ({
  spaceId,
  integrations,
  sort,
  sortValue,
  toggleSort
}) {
  const removeIntegration = useRemoveIntegrationMutation()
  const location = useLocation()
  return (
    <Wrapper className='integrations'>
      <TransitionGroup>
        <CSSTransition
          key={location.pathname.endsWith('integrations')}
          timeout={450}
        >
          <StaticOutlet context={{ spaceId }} />
        </CSSTransition>
      </TransitionGroup>
      {integrations.length === 0 ? (
        <NoIntegrations />
      ) : (
        <IntegrationsTable
          integrations={integrations}
          removeIntegration={removeIntegration}
          sort={sort}
          sortValue={sortValue}
          spaceId={spaceId}
          toggleSort={toggleSort}
        />
      )}
    </Wrapper>
  )
}

function NoIntegrations () {
  return (
    <div className='flex items-start justify-center p-6'>
      <InfoBox
        title={i18n._('no.custom.integrations')}
        subtitle={i18n._('no.custom.integrations.subtitle')}
      >
        <div className='flex flex-col'>
          <Button
            as={Link}
            to='new'
            mt={2}
            width='100%'
            data-testid='integration-button'
          >
            <Icons.Add fill='#fff' mr={2} />
            <span>
              <Trans id='add.api.integration' />
            </span>
          </Button>
          {flumeIntegrations && (
            <Button
              as={Link}
              to='advanced/new'
              mt={2}
              width='100%'
              data-testid='advanced-integration-button'
            >
              <Icons.Add fill='#fff' mr={2} />
              <span>
                <Trans id='add.advanced.integration' />
              </span>
            </Button>
          )}
        </div>
      </InfoBox>
    </div>
  )
}

const ColumnSortButton = styled.button`
  cursor: pointer;
  border: none;
  background: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
`

const Sort = styled(Icons.KeyboardArrowDown)`
  position: relative;
  top: 3px;
  ${p =>
    p.sort === 1
      ? css`
          transform: rotate(-180deg);
        `
      : ''}
  ${p =>
    !p.sort
      ? css`
          display: none;
        `
      : ''}
`

function IntegrationsTable ({
  integrations,
  removeIntegration,
  sort,
  sortValue,
  spaceId,
  toggleSort
}) {
  const alerts = useAlerts()
  const [search, setSearch] = React.useState('')
  const [selectedIntegrationIds, setSelectedIntegrationIds] = React.useState([])
  const moveIntegrations = useMoveIntegrationsToSpaceMutation()
  const handleCheckAll = () => {
    setSelectedIntegrationIds(s =>
      s.length < integrations.length ? map(integrations, 'id') : []
    )
  }

  const handleCheckIntegration = id => {
    setSelectedIntegrationIds(selected => xor(selected, [id]))
  }

  const moveIntegrationsIntoSpace = hide => newSpaceId => {
    moveIntegrations(selectedIntegrationIds, newSpaceId, spaceId, sortValue)
      .then(() => {
        setSelectedIntegrationIds([])
        hide()
        alerts.type3(i18n._('move.complete'), 'success')
      })
      .catch(err => {
        alerts.type2(i18n._('server.responded.error.again'), 'error')
        Sentry.captureException(err)
      })
  }
  return (
    <>
      <div className='text-center'>
        <Trans id='editing.integration.impact.versions.using' />
      </div>
      <div
        className={cx('mb-3 mt-4 flex items-center gap-2', {
          'justify-center': spaceId,
          'justify-end': !spaceId
        })}
      >
        <SearchBar value={search} onChange={setSearch} className='w-48' />
        {spaceId && integrations.length && (
          <PopoverButton
            label={i18n._('move.selected...')}
            buttonProps={{ disabled: !selectedIntegrationIds.length }}
          >
            {hide => (
              <SpaceFinder
                onSelect={moveIntegrationsIntoSpace(hide)}
                spaceId={spaceId}
                filterIntegrationDisabled
              />
            )}
          </PopoverButton>
        )}
        {flumeIntegrations && (
          <Button as={Link} to='advanced/new'>
            <Icons.Add fill='#fff' mr={2} />
            <span>
              <Trans id='add.advanced' />
            </span>
          </Button>
        )}
        <Button as={Link} to='new'>
          <Icons.Add fill='#fff' mr={2} />
          <span>
            <Trans id='add.api.integration' />
          </span>
        </Button>
      </div>
      <table className='kp-table'>
        <thead>
          <tr>
            {spaceId && (
              <th>
                <Checkbox
                  className='mt-1'
                  checked={
                    selectedIntegrationIds.length === integrations.length
                  }
                  indeterminate={
                    selectedIntegrationIds.length > 0 &&
                    selectedIntegrationIds.length !== integrations.length
                  }
                  onChange={handleCheckAll}
                  aria-label={i18n._('select.all')}
                />
              </th>
            )}
            <th>
              <ColumnSortButton onClick={() => toggleSort('data.__name')}>
                Name
                <Sort sort={sort['data.__name']} />
              </ColumnSortButton>
            </th>
            <th>
              <Trans id='description' />
            </th>
            <th>
              <Trans id='type' />
            </th>
            <th>
              <Trans id='apps' />
            </th>
            <th>
              <Trans id='actions' />
            </th>
          </tr>
        </thead>
        <tbody>
          {map(winnowList(integrations, search, 'data.__name'), result => (
            <tr
              key={result.id}
              className='hover:bg-light-gray-100 dark:hover:bg-light-gray-300'
            >
              {spaceId && (
                <td>
                  <input
                    type='checkbox'
                    className='kp-checkbox mt-1'
                    checked={selectedIntegrationIds.includes(result.id)}
                    onClick={e => e.stopPropagation()}
                    onChange={() => handleCheckIntegration(result.id)}
                    aria-label={`Select ${result.data.__name}`}
                  />
                </td>
              )}
              <td>
                <Truncate width={180}>{result.data.__name}</Truncate>
              </td>
              <td>
                <Truncate width={320}>{result.data.__description}</Truncate>
              </td>
              <td>
                {result.data.advanced ? (
                  <Trans id='advanced' />
                ) : (
                  <Trans id='simple' />
                )}
              </td>
              <td>
                <div className='flex justify-center'>
                  {result.data.advanced ? (
                    result.appsUsing.length
                  ) : (
                    <Link
                      className='text-text-link underline'
                      to={`${result.id}/used-by`}
                    >
                      {result.appsUsing.length}
                    </Link>
                  )}
                </div>
              </td>
              <td>
                <div className='flex justify-center'>
                  <Button
                    aria-label={i18n._('see.integration.details')}
                    icon
                    transparent
                    as={Link}
                    to={
                      result.data.advanced
                        ? `advanced/${result.id}/edit`
                        : `${result.id}/edit`
                    }
                  >
                    <Icons.Edit />
                  </Button>
                  <Button
                    aria-label={i18n._('delete.integration')}
                    icon
                    transparent
                    onClick={() => {
                      const shouldRemove = window.confirm(
                        <Trans id='are.you.sure.delete.integration' />
                      )
                      if (shouldRemove) removeIntegration(result.id)
                    }}
                  >
                    <Icons.Delete />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const getIntegrationsQuery = ({ id, sort }) => ({
  fetchPolicy: 'cache-and-network',
  query: gql`
    query IntegrationsQuery($id: ID, $sort: String) {
      integrationsConnection(spaceId: $id, args: { sort: $sort }) {
        edges {
          node {
            id
            data
            filledSecrets
            invokeUrl
            appsUsing {
              id
            }
          }
        }
      }
    }
  `,
  variables: {
    id,
    sort
  }
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 858px;
  margin: 0 auto;
  padding: 30px;
  > * {
    width: 100%;
  }
`

const Truncate = styled.div.attrs(p => ({ title: p.children }))`
  width: ${p => p.width}px;
  overflow: hidden;
  text-overflow: ellipsis;
`
