/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'

export default function localizeMetaLabel (id, defaultLabel) {
  switch (id) {
    case 'meta.serialNumber':
      return i18n._({ id: 'meta.serial.number', message: 'Number' })
    case 'meta.submittedAt':
      return i18n._({ id: 'meta.submitted.at', message: 'Submitted At' })
    case 'meta.submittedBy':
      return i18n._({ id: 'meta.submitted.by', message: 'Submitter' })
    case 'meta.submittedBy.id':
      return i18n._({ id: 'meta.submitted.by.id', message: 'Submitter - ID' })
    case 'meta.submittedBy.displayName':
      return i18n._({
        id: 'meta.submitted.by.display.name',
        message: 'Submitter - Display Name'
      })
    case 'meta.submittedBy.schoolId':
      return i18n._({
        id: 'meta.submitted.by.school.id',
        message: 'Submitter - School ID'
      })
    case 'meta.submittedBy.email':
      return i18n._({
        id: 'meta.submitted.by.email',
        message: 'Submitter - Email'
      })
    case 'meta.submittedBy.username':
      return i18n._({
        id: 'meta.submitted.by.username',
        message: 'Submitter - Username'
      })
    case 'meta.createdBy':
      return i18n._({ id: 'meta.created.by', message: 'Created By' })
    case 'meta.createdBy.id':
      return i18n._({ id: 'meta.created.by.id', message: 'Created By - ID' })
    case 'meta.createdBy.displayName':
      return i18n._({
        id: 'meta.created.by.display.name',
        message: 'Created By - Display Name'
      })
    case 'meta.createdBy.schoolId':
      return i18n._({
        id: 'meta.created.by.school.id',
        message: 'Created By - School ID'
      })
    case 'meta.createdBy.email':
      return i18n._({
        id: 'meta.created.by.email',
        message: 'Created By - Email'
      })
    case 'meta.createdBy.username':
      return i18n._({
        id: 'meta.created.by.username',
        message: 'Created By - Username'
      })
    case 'meta.createdAt':
      return i18n._({ id: 'meta.created.at', message: 'Created At' })
    case 'meta.link':
      return i18n._({ id: 'meta.link', message: 'Link to Document' })
    case 'meta.workflowTotalRuntime':
      return i18n._({
        id: 'meta.workflow.total.runtime',
        message: 'Workflow Total Run Time'
      })
    case 'meta.workflowStatus':
      return i18n._({
        id: 'meta.workflow.status',
        message: 'Workflow Status'
      })
    case 'meta.currentWorkflowSteps':
      return i18n._({
        id: 'meta.current.workflow.steps',
        message: 'Time on Current Step'
      })
    default:
      return defaultLabel
  }
}
