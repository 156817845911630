/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8.00001 0.891357C3.77268 0.891357 0.333344 3.72469 0.333344 7.20736C0.346378 8.04457 0.542634 8.86877 0.908315 9.62202C1.274 10.3753 1.80022 11.0393 2.45001 11.5674L0.916677 14.6254C0.885614 14.6873 0.874613 14.7573 0.885202 14.8258C0.895791 14.8942 0.927444 14.9577 0.975762 15.0073C1.02408 15.057 1.08666 15.0903 1.15481 15.1028C1.22295 15.1152 1.29328 15.1061 1.35601 15.0767L5.45534 13.1687C6.28262 13.4063 7.13929 13.5259 8.00001 13.524C12.2273 13.524 15.6667 10.6907 15.6667 7.20736C15.6667 3.72402 12.2273 0.891357 8.00001 0.891357Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8.00001 0.891357C3.77268 0.891357 0.333344 3.72469 0.333344 7.20736C0.346378 8.04457 0.542634 8.86877 0.908315 9.62202C1.274 10.3753 1.80022 11.0393 2.45001 11.5674L0.916677 14.6254C0.885614 14.6873 0.874613 14.7573 0.885202 14.8258C0.895791 14.8942 0.927444 14.9577 0.975762 15.0073C1.02408 15.057 1.08666 15.0903 1.15481 15.1028C1.22295 15.1152 1.29328 15.1061 1.35601 15.0767L5.45534 13.1687C6.28262 13.4063 7.13929 13.5259 8.00001 13.524C12.2273 13.524 15.6667 10.6907 15.6667 7.20736C15.6667 3.72402 12.2273 0.891357 8.00001 0.891357Z\" />\n</svg>\n";
export default src;
