/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useSignFileUploadMutation = () => {
  const [signFileUpload] = useMutation(mutation)
  return (filename, contentType) =>
    signFileUpload(getParams(filename, contentType))
}

const mutation = gql`
  mutation SignFileUpload($filename: String!, $contentType: String!) {
    signFile(args: { filename: $filename, contentType: $contentType }) {
      url
      fields
      confirmToken
    }
  }
`

const getParams = (filename, contentType) => ({
  variables: { filename, contentType }
})
