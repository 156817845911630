/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useRemoveIdentityMutation = roleId => {
  const [removeIdentity] = useMutation(mutation)
  const query = useQueryContext()
  return identityId => removeIdentity(getParams(roleId, identityId, query))
}

const mutation = gql`
  mutation RemoveIdentityFromRole($roleId: String!, $identityId: String!) {
    removeIdentityFromRole(args: { roleId: $roleId, identityId: $identityId })
  }
`

const getParams = (roleId, identityId, query) => ({
  variables: { roleId, identityId },
  refetchQueries: [query]
})
