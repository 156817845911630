/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'

export default ({ sortBy, field, sortDir, onSort, children }) => {
  const next = sortBy !== field || sortDir === 'desc' ? 'asc' : 'desc'
  return (
    <TableRowItem className='text-dark-gray-100'>
      <Link aria-label={children} onClick={() => onSort(field, next)}>
        <span>{children} </span>
        {sortBy === field && (sortDir === 'desc' ? <Down /> : <Up />)}
      </Link>
    </TableRowItem>
  )
}

const Down = styled(Icons.KeyboardArrowDown)`
  margin-left: 4px;
`

const Up = styled(Down)`
  transform: rotate(180deg);
`

const Link = styled.button`
  font-weight: 700;
  text-transform: capitalize;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

const TableRowItem = styled.div`
  padding: 0.75em;
  flex: 1;
  flex-grow: 1;
  text-overflow: ellipsis;
  min-width: 140px;
`
