/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery } from '@apollo/client'
import { map, pick } from 'lodash'
import React from 'react'

import * as Lookups from '../../../components/lookup-controls'
import useDebouncedValue from '../../../components/use-debounced-value'
import { getActualName } from '../user-typeahead/utils'

export default function Edit ({
  id,
  value,
  onChange,
  errors,
  details,
  ...props
}) {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const additionalKey =
    details?.additionalKey?.enabled && (details?.additionalKey?.value ?? '')

  const THE_QUERY =
    details?.roleIds?.length > 0 ? USERS_FROM_ROLES_QUERY : USERS_QUERY

  const [fetch, { data, loading }] = useLazyQuery(THE_QUERY)

  React.useEffect(() => {
    const variables = { query: debouncedQuery }
    if (details?.roleIds?.length > 0) {
      variables.roleIds = details.roleIds.map(({ id }) => id)
    }
    fetch({ variables })
  }, [details, debouncedQuery, fetch])

  const options = map(data?.users?.edges, ({ node: user }) => ({
    ...pick(user, ['id', 'displayName', 'email', 'username', 'schoolId']),
    label: user.displayName
  }))
  return (
    <>
      <Lookups.Multiselect
        id={id}
        placeholder={placeholder}
        format={getActualName(additionalKey)}
        options={options}
        onChange={onChange}
        value={value}
        query={query}
        setQuery={setQuery}
        aria={{
          labelledby: props['aria-labelledby'],
          describedby: props['aria-describedby']
        }}
        gridded={props.gridded}
        loading={loading}
      />
      {props.gridded && errors}
    </>
  )
}

const USERS_QUERY = gql`
  query GetUsers($query: String) {
    users: usersConnection(args: { limit: 20, query: $query }) {
      edges {
        node {
          id
          displayName
          schoolId
          username
          email
        }
      }
    }
  }
`

const USERS_FROM_ROLES_QUERY = gql`
  query GetUsersFromRoles($query: String, $roleIds: [ID!]!) {
    users: usersByRoleIds(
      args: { limit: 20, query: $query, roleIds: $roleIds }
    ) {
      edges {
        node {
          id
          displayName
          schoolId
          username
          email
        }
      }
    }
  }
`
