/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { OptionalConfig, RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import View from './view'

export default {
  Edit,
  View,
  RequiredConfig,
  OptionalConfig,
  meta: {
    category: 'Special',
    Icon,
    initialTemplate: () => ({
      childrenTemplate: []
    }),
    label: 'Repeater'
  },
  unsortable: true,
  validateShape: v => v.any()
}
