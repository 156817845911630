/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { filter, isEmpty, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import Button, { ButtonGroup } from '../../../ui/button'
import Checkbox from '../../../ui/checkbox'
import Toggle from '../../../ui/toggle'
import { InfoPage, SimulateButtons, SimulateWrapper } from './'
import { ReactComponent as ApproveBubble } from './approve-bubble.svg.jsx'
import { ReactComponent as DenyBubble } from './deny-bubble.svg.jsx'
import SimulationFormEditor from './form-editor'
import { ReactComponent as LeftPerson } from './left-person.svg.jsx'
import { ReactComponent as RightPerson } from './right-person.svg.jsx'
import SendbackButton from './sendback-button'

export default function GroupResponse ({
  formProps,
  headless,
  simulateAction,
  simulationState,
  validations,
  value
}) {
  const { assignees = [], voting } = value
  const [viewForm, setViewForm] = React.useState(false)
  const [approveAll, setApproveAll] = React.useState(false)
  const [approvalState, setApprovalState] = React.useState(
    calculateApprovalState(assignees, voting)
  )
  const updateResponse = (index, resp) => {
    simulateAction('vote', simulationState, {
      index,
      vote: resp
    })
    const newState = calculateApprovalState(assignees, voting)
    setApprovalState(newState)
  }
  const toggleApprovalAll = () => {
    const newApproveAll = !approveAll
    simulateAction('voteAll', simulationState, {
      vote: newApproveAll ? 'approve' : null
    })
    const newState = calculateApprovalState(assignees, voting)
    setApprovalState(newState)
    setApproveAll(newApproveAll)
  }
  const { approvePct, responsePct, status } = approvalState
  const hasValidationErrors = !isEmpty(validations)

  return (
    <SimulateWrapper
      headless={headless}
      buttons={
        <SimulateButtons>
          <Button
            disabled={simulationState.processing}
            id='back-btn'
            onClick={() => simulateAction('back', simulationState)}
            outline
          >
            <Trans id='back' />
          </Button>
          <MainButtons>
            <SendbackButton
              simulateAction={simulateAction}
              simulationState={simulationState}
              validations={validations}
              value={value}
            />
            <Button
              disabled={
                simulationState.processing ||
                status === 'Awaiting Responses' ||
                hasValidationErrors
              }
              id='default-btn'
              onClick={() => simulateAction(status, simulationState)}
            >
              {status.toUpperCase()}
            </Button>
          </MainButtons>
        </SimulateButtons>
      }
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <ToggleHeader>
        {hasValidationErrors && (
          <StyledTooltip id='validationError' place='top'>
            <div>
              <Trans id='validation.errors.must.be.fixed' />
            </div>
          </StyledTooltip>
        )}
        <MyToggle
          value={viewForm}
          onChange={() => setViewForm(a => !a)}
          off='Choose Responses'
          on='Form View'
        />
        {hasValidationErrors && (
          <TooltipTrigger
            as={RedStar}
            label={i18n._('validation.errors')}
            tooltipId='validationError'
          />
        )}
      </ToggleHeader>
      {viewForm ? (
        <SimulationFormEditor {...formProps} validations={validations} />
      ) : (
        <InfoPage justifyContent='flex-start' flexDirection='column'>
          <Main>
            <PageHeader>
              <HeaderLeft>
                <HeaderIcons>
                  <ApproveBubble />
                  <DenyBubble />
                  <LeftPerson />
                  <RightPerson />
                </HeaderIcons>
                <HeaderText>
                  <HeaderTitle>
                    <Trans id='group.response.options' />
                  </HeaderTitle>
                  <HeaderContent>
                    <Trans id='step.requires.colon' />
                    <br />
                    <strong>
                      {`${responsePct > 0 ? `${responsePct}% / ` : ''}${
                        voting.responses
                      }% Responses, ${
                        approvePct > 0 ? `${approvePct}% / ` : ''
                      }${voting.value}% Approvals`}
                    </strong>
                  </HeaderContent>
                </HeaderText>
              </HeaderLeft>
              <ApproveAll>
                <Checkbox
                  id='toggle-approve-all'
                  checked={approveAll}
                  onChange={toggleApprovalAll}
                  label={i18n._('set.all.to.approve')}
                />
              </ApproveAll>
            </PageHeader>
            <Responses>
              <ResponseRow header>
                <ResponseName>
                  <Trans id='name' />
                </ResponseName>
                <ResponseButtons>
                  <Trans id='approval' />
                </ResponseButtons>
              </ResponseRow>
              {map(assignees, (a, index) => (
                <ResponseRow key={`response-${index}`}>
                  <ResponseName>{a.displayName}</ResponseName>
                  <ResponseButtons>
                    <ButtonGroup>
                      <Button
                        onClick={() => updateResponse(index)}
                        outline={!!a.response}
                      >
                        <Trans id='no.reply' />
                      </Button>
                      <Button
                        onClick={() => updateResponse(index, 'deny')}
                        outline={a.response !== 'deny'}
                      >
                        <Trans id='deny' />
                      </Button>
                      <Button
                        onClick={() => updateResponse(index, 'approve')}
                        outline={a.response !== 'approve'}
                      >
                        <Trans id='approve' />
                      </Button>
                    </ButtonGroup>
                  </ResponseButtons>
                </ResponseRow>
              ))}
            </Responses>
          </Main>
        </InfoPage>
      )}
    </SimulateWrapper>
  )
}

const calculateApprovalState = (responses, voting) => {
  const awaitingResp = 'Awaiting Responses'
  if (!responses.length) {
    return { status: awaitingResp }
  }
  const responded = filter(responses, r => !isEmpty(r.response)).length
  const responsePct = Math.floor((responded / responses.length) * 100)
  const responseMet = responsePct >= voting?.responses

  const approvals = filter(responses, r => r.response === 'approve').length
  const approvePct = Math.floor(
    responded === 0 ? 0 : (approvals / responded) * 100
  )
  const approvalMet = approvePct >= voting?.value

  if (!responseMet) {
    return { status: awaitingResp, responsePct, approvePct }
  }
  return {
    status: approvalMet ? 'approve' : 'deny',
    responsePct,
    approvePct
  }
}

const ToggleHeader = styled.div`
  display: flex;
  justify-content: center;
  background: var(--light-gray-200);
  width: 100%;
  padding: 15px;
`
const MyToggle = styled(Toggle)`
  background: #3369a3;
`
const RedStar = styled.span`
  ::after {
    content: ' *';
    color: var(--red-400);
  }
`

const Main = styled.div`
  padding: 20px;
  width: 100%;
`
const PageHeader = styled.div`
  display: flex;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
`
const HeaderLeft = styled.div`
  display: flex;
`

const HeaderIcons = styled.div`
  display: grid;
  grid-template-columns: 40px 40px;
  justify-items: center;
  padding: 5px 15px 0 0;
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 365px;
`
const HeaderTitle = styled.div`
  white-space: nowrap;
  font-size: 26px;
  line-height: 35px;
`
const HeaderContent = styled.div`
  font-size: 16px;
  line-height: 19px;
`

const ApproveAll = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 10px;
  flex-grow: 1;
`

const MainButtons = styled.div`
  display: flex;
`

const Responses = styled.div`
  display: grid;
`
const ResponseRow = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  ${p =>
    p.header
      ? 'background: var(--light-gray-200);'
      : 'border-bottom: 1px solid var(--light-gray-300);'}
`
const ResponseName = styled.div`
  min-width: 200px;
`
const ResponseButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 240px;
`

const StyledTooltip = styled(Tooltip)`
  width: 200px;
`

export const forTesting = {
  calculateApprovalState
}
