/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346633 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM12.1143 6.74286L7.54286 11.3143C7.33185 11.5296 7.04431 11.6528 6.74286 11.6571C6.59165 11.6565 6.44207 11.6259 6.30279 11.567C6.1635 11.5082 6.03728 11.4223 5.93143 11.3143L3.88572 9.22286C3.67051 9.00765 3.54961 8.71577 3.54961 8.41143C3.54961 8.26073 3.57929 8.11151 3.63696 7.97229C3.69463 7.83306 3.77916 7.70656 3.88572 7.6C3.99228 7.49344 4.11878 7.40892 4.258 7.35125C4.39723 7.29358 4.54645 7.2639 4.69715 7.2639C5.00149 7.2639 5.29337 7.3848 5.50857 7.6L6.56 8.66286C6.61742 8.71224 6.69002 8.74048 6.76572 8.74286C6.83806 8.74074 6.90715 8.7123 6.96 8.66286L10.4914 5.13143C10.5977 5.02431 10.7241 4.93929 10.8633 4.88127C11.0026 4.82325 11.152 4.79337 11.3029 4.79337C11.4537 4.79337 11.6031 4.82325 11.7424 4.88127C11.8816 4.93929 12.008 5.02431 12.1143 5.13143C12.3271 5.34556 12.4466 5.63522 12.4466 5.93714C12.4466 6.23907 12.3271 6.52873 12.1143 6.74286Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346633 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM12.1143 6.74286L7.54286 11.3143C7.33185 11.5296 7.04431 11.6528 6.74286 11.6571C6.59165 11.6565 6.44207 11.6259 6.30279 11.567C6.1635 11.5082 6.03728 11.4223 5.93143 11.3143L3.88572 9.22286C3.67051 9.00765 3.54961 8.71577 3.54961 8.41143C3.54961 8.26073 3.57929 8.11151 3.63696 7.97229C3.69463 7.83306 3.77916 7.70656 3.88572 7.6C3.99228 7.49344 4.11878 7.40892 4.258 7.35125C4.39723 7.29358 4.54645 7.2639 4.69715 7.2639C5.00149 7.2639 5.29337 7.3848 5.50857 7.6L6.56 8.66286C6.61742 8.71224 6.69002 8.74048 6.76572 8.74286C6.83806 8.74074 6.90715 8.7123 6.96 8.66286L10.4914 5.13143C10.5977 5.02431 10.7241 4.93929 10.8633 4.88127C11.0026 4.82325 11.152 4.79337 11.3029 4.79337C11.4537 4.79337 11.6031 4.82325 11.7424 4.88127C11.8816 4.93929 12.008 5.02431 12.1143 5.13143C12.3271 5.34556 12.4466 5.63522 12.4466 5.93714C12.4466 6.23907 12.3271 6.52873 12.1143 6.74286Z\" />\n</svg>\n";
export default src;
