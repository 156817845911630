/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.3334 0.0226898H4.66669C4.13625 0.0226898 3.62755 0.233404 3.25247 0.608476C2.8774 0.983549 2.66669 1.49226 2.66669 2.02269V13.9774C2.66669 14.5078 2.8774 15.0165 3.25247 15.3916C3.62755 15.7666 4.13625 15.9774 4.66669 15.9774H11.3334C11.8638 15.9774 12.3725 15.7666 12.7476 15.3916C13.1226 15.0165 13.3334 14.5078 13.3334 13.9774V2.02269C13.3334 1.49226 13.1226 0.983549 12.7476 0.608476C12.3725 0.233404 11.8638 0.0226898 11.3334 0.0226898ZM8.00002 14.9774C7.86817 14.9774 7.73927 14.9383 7.62964 14.865C7.52001 14.7917 7.43456 14.6876 7.3841 14.5658C7.33364 14.444 7.32044 14.31 7.34616 14.1806C7.37189 14.0513 7.43538 13.9325 7.52862 13.8393C7.62185 13.7461 7.74064 13.6826 7.86996 13.6568C7.99928 13.6311 8.13333 13.6443 8.25514 13.6948C8.37696 13.7452 8.48108 13.8307 8.55433 13.9403C8.62759 14.0499 8.66669 14.1788 8.66669 14.3107C8.66669 14.4875 8.59645 14.6571 8.47142 14.7821C8.3464 14.9071 8.17683 14.9774 8.00002 14.9774ZM12 11.9774C12 12.1542 11.9298 12.3237 11.8048 12.4488C11.6797 12.5738 11.5102 12.644 11.3334 12.644H4.66669C4.48988 12.644 4.32031 12.5738 4.19528 12.4488C4.07026 12.3237 4.00002 12.1542 4.00002 11.9774V2.35602C4.00002 2.17921 4.07026 2.00964 4.19528 1.88462C4.32031 1.75959 4.48988 1.68936 4.66669 1.68936H11.3334C11.5102 1.68936 11.6797 1.75959 11.8048 1.88462C11.9298 2.00964 12 2.17921 12 2.35602V11.9774Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11.3334 0.0226898H4.66669C4.13625 0.0226898 3.62755 0.233404 3.25247 0.608476C2.8774 0.983549 2.66669 1.49226 2.66669 2.02269V13.9774C2.66669 14.5078 2.8774 15.0165 3.25247 15.3916C3.62755 15.7666 4.13625 15.9774 4.66669 15.9774H11.3334C11.8638 15.9774 12.3725 15.7666 12.7476 15.3916C13.1226 15.0165 13.3334 14.5078 13.3334 13.9774V2.02269C13.3334 1.49226 13.1226 0.983549 12.7476 0.608476C12.3725 0.233404 11.8638 0.0226898 11.3334 0.0226898ZM8.00002 14.9774C7.86817 14.9774 7.73927 14.9383 7.62964 14.865C7.52001 14.7917 7.43456 14.6876 7.3841 14.5658C7.33364 14.444 7.32044 14.31 7.34616 14.1806C7.37189 14.0513 7.43538 13.9325 7.52862 13.8393C7.62185 13.7461 7.74064 13.6826 7.86996 13.6568C7.99928 13.6311 8.13333 13.6443 8.25514 13.6948C8.37696 13.7452 8.48108 13.8307 8.55433 13.9403C8.62759 14.0499 8.66669 14.1788 8.66669 14.3107C8.66669 14.4875 8.59645 14.6571 8.47142 14.7821C8.3464 14.9071 8.17683 14.9774 8.00002 14.9774ZM12 11.9774C12 12.1542 11.9298 12.3237 11.8048 12.4488C11.6797 12.5738 11.5102 12.644 11.3334 12.644H4.66669C4.48988 12.644 4.32031 12.5738 4.19528 12.4488C4.07026 12.3237 4.00002 12.1542 4.00002 11.9774V2.35602C4.00002 2.17921 4.07026 2.00964 4.19528 1.88462C4.32031 1.75959 4.48988 1.68936 4.66669 1.68936H11.3334C11.5102 1.68936 11.6797 1.75959 11.8048 1.88462C11.9298 2.00964 12 2.17921 12 2.35602V11.9774Z\" />\n</svg>\n";
export default src;
