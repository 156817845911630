/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../icons'
import Button from '../../ui/button'
import { Option, Select } from '../../ui/select'

export default function Pagination ({ total, skip, limit, onUpdate }) {
  const from = skip + 1
  const to = skip + limit > total ? total : skip + limit
  return (
    <WithoutTotalPagination
      skip={skip}
      limit={limit}
      hasNextPage={skip + limit < total}
      hasPreviousPage={skip > 0}
      onUpdate={onUpdate}
      rangeDisplay={i18n._('pagination.range.total.display', {
        from,
        to,
        total
      })}
    />
  )
}

export function WithoutTotalPagination ({
  skip,
  limit,
  rangeDisplay,
  hasNextPage,
  hasPreviousPage,
  onUpdate
}) {
  return (
    <tfoot data-testid='pagination' className='text-sm dark:bg-light-gray-300'>
      <tr>
        <RowWrapper colSpan='100%' classname='dark:bg-light-gray-300'>
          <Wrapper>
            <Label>
              <span>
                <Trans id='rows.per.page' message='Rows per page:' />
              </span>
              <PageSelect
                minimal
                value={limit}
                onChange={newLimit =>
                  onUpdate({ skip: 0, limit: parseInt(newLimit, 10) })
                }
              >
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
              </PageSelect>
            </Label>
            <span>{rangeDisplay}</span>
            <Button
              aria-label={i18n._({
                id: 'previous.page',
                message: 'previous page'
              })}
              transparent
              icon
              disabled={!hasPreviousPage}
              onClick={() =>
                onUpdate({
                  skip: skip - limit > 0 ? skip - limit : 0,
                  limit
                })
              }
            >
              <PreviousIcon className='fill-medium-gray-500 dark:fill-medium-gray-300' />
            </Button>
            <Button
              aria-label={i18n._({ id: 'next.page', message: 'next page' })}
              transparent
              icon
              disabled={!hasNextPage}
              onClick={() => onUpdate({ skip: skip + limit, limit })}
            >
              <NextIcon className='fill-medium-gray-500 dark:fill-medium-gray-300' />
            </Button>
          </Wrapper>
        </RowWrapper>
      </tr>
    </tfoot>
  )
}

const RowWrapper = styled.td`
  text-align: right;
`

const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  white-space: nowrap;
  margin-right: 56px;
`

const PreviousIcon = styled(Icons.SelectDownArrow)`
  transform: rotate(90deg);
  width: 8px;
`

const NextIcon = styled(Icons.SelectDownArrow)`
  transform: rotate(270deg);
  width: 8px;
`

const PageSelect = styled(Select)`
  margin: 0 16px 0 8px;
  font-size: 14px;
`

const Label = styled.label`
  display: flex;
  align-items: center;
`
