/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'

export default gql`
  mutation CreateCategoryField(
    $name: String!
    $type: String!
    $categoryId: ID!
  ) {
    createCategoryField(
      args: { name: $name, type: $type, categoryId: $categoryId }
    ) {
      category {
        id
        fieldSchemas {
          id
          name
          type
        }
        historyConnection(args: { skip: 0, limit: 10 }) {
          id
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`
