/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { find, keyBy } from 'lodash'
import React from 'react'

import { Wrapper } from '../../ui/layout'
import { Option, Select } from '../../ui/select'
import { Body1 } from '../../ui/typography'
import * as Errors from './errors'

export const Config = ({ value, updateValue, errors, fieldsIterable }) => (
  <Wrapper px={3}>
    <Body1 py={2}>
      <Trans id='echo.configuration' />
    </Body1>
    <Body1 py={2} htmlFor='which-form-field'>
      <Trans id='choose.list.gadget.used.echo.step' />
    </Body1>
    <>
      <GadgetSelect
        id='which-form-field'
        gadgets={fieldsIterable}
        value={value}
        updateValue={updateValue}
      />
      <Errors.Config errors={errors} />
    </>
  </Wrapper>
)

export const validate = (value, gadgets) => {
  const gadgetsByFormKey = keyBy(gadgets, 'formKey')
  if (!value?.echoField || !gadgetsByFormKey[value.echoField.formKey]) {
    return [
      {
        key: 'echoConfig',
        message: 'Configuration is incomplete'
      }
    ]
  }
  return []
}

const GadgetSelect = ({ id, gadgets, value, updateValue }) => (
  <Select
    id={id}
    value={value?.echoField?.formKey}
    onChange={formKey => {
      updateValue(draft => {
        const gadget = find(gadgets, { formKey })
        if (!gadget) {
          draft.echoField = null
        } else {
          const { formKey, label } = gadget
          draft.echoField = { formKey, label }
        }
      })
    }}
  >
    <Option value='' />
    {gadgets.map(item => (
      <Option key={item.formKey} value={item.formKey}>
        {item.label}
      </Option>
    ))}
  </Select>
)
