/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

export default function DisplaySignature ({ imagePreview, value }) {
  if (value?.signatureType === 'type' && value?.signedName) {
    return <Signature>{value.signedName}</Signature>
  }
  if (value?.signatureType === 'upload' || value?.signatureType === 'draw') {
    return (
      <SignatureImage
        src={value.temporaryUrl || imagePreview}
        alt='signature'
      />
    )
  }
  return null
}

const Signature = styled.div`
  font-size: 32px;
  font-family: 'Yellowtail', cursive;
  font-style: italic;
`
const SignatureImage = styled.img`
  max-width: 100%;
  max-height: 200px;
`
