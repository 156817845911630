/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

const sizeFromNumber = () => {
  if (window.innerWidth <= 768) return 'small'
  if (window.innerWidth <= 992) return 'medium'
  if (window.innerWidth <= 1200) return 'large'
  return 'huge'
}

export const useBreakpoints = () => {
  const [size, setSize] = React.useState(sizeFromNumber())
  React.useEffect(() => {
    const onResize = () => setSize(sizeFromNumber())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
  return size
}
