/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

const hasFocus = ref => ref.contains(document.activeElement)

export const useRover = ({ width, max, initial, onClick }) => {
  const [containerRef, setContainerRef] = React.useState(null)
  const [focusIndex, setFocusIndex] = React.useState(initial)
  const keyMap = React.useMemo(
    () => ({
      ArrowLeft: i => (i % width === 0 ? i : i - 1),
      ArrowRight: i => ((i + 1) % width === 0 || i + 1 > max - 1 ? i : i + 1),
      ArrowUp: i => (i - width < 0 ? i : i - width),
      ArrowDown: i => (i + width > max - 1 ? i : i + width),
      PageUp: () => 0,
      PageDown: () => max - 1,
      Home: () => 0,
      End: () => max - 1
    }),
    [width, max]
  )
  React.useEffect(() => {
    if (!containerRef) return
    const handleKeyDown = e => {
      const fn = keyMap[e.key]
      if (fn) {
        setFocusIndex(fn)
        e.preventDefault()
      }
    }
    containerRef.addEventListener('keydown', handleKeyDown)
    return () => containerRef.removeEventListener('keydown', handleKeyDown)
  }, [keyMap, containerRef])
  const itemProps = (i, clickArg) => ({
    tabIndex: i === focusIndex ? 0 : -1,
    onClick: () => {
      setFocusIndex(i)
      if (onClick) onClick(clickArg)
    },
    ref: ref =>
      ref && containerRef && hasFocus(containerRef) && i === focusIndex
        ? ref.focus()
        : null
  })
  return [setContainerRef, itemProps]
}
