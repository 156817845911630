/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import * as Sentry from '@sentry/browser'
import { cloneDeep } from 'lodash'
import React from 'react'

import { pDebounce } from '../../components/p-debounce'
import { useAlerts } from '../../ui/alerts'
import { utils } from '../../voronoi-dnd-formbot'
import { useUpdateFormMutation } from './mutation.update-form'

export function useManageTemplate (q, appId, pageId, original) {
  const alerts = useAlerts()
  const [template, setTemplate] = React.useState(cloneDeep(original))
  const [updateForm, mutationResult] = useUpdateFormMutation(q)
  const [unsavedChanges, setUnsavedChanges] = React.useState(false)

  const debouncedUpdate = React.useCallback(
    pDebounce(_next => {
      const next = cloneDeep(_next)
      utils.traverseForm(next, gadget => {
        delete gadget.sectionDepth
        delete gadget.root
        if (gadget.type === 'StaticImage') {
          delete gadget?.details?.tempImg
          delete gadget?.details?.url
        }
      })
      return updateForm(appId, pageId, next)
        .then(result => {
          setUnsavedChanges(false)
          return result
        })
        .catch(error => {
          alerts.type3(i18n._('pagesbuilder.form.error.saving'), 'error')
          Sentry.captureException(error)
        })
    }, 700),
    [appId, pageId]
  )
  const update = React.useCallback(
    next => {
      setUnsavedChanges(true)
      setTemplate(next)
      return debouncedUpdate(next)
    },
    [debouncedUpdate]
  )

  return [{ ...mutationResult, template, unsavedChanges }, update]
}
