/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'

export const createGroupWithCategoryIdMutation = gql`
  mutation CreateGroupWithCategoryId(
    $categoryId: ID!
    $name: String
    $parentId: String
  ) {
    createGroup(
      args: { categoryId: $categoryId, name: $name, parentId: $parentId }
    ) {
      group {
        id
        name
        parentId
        categoryId
      }

      query {
        category(id: $categoryId) {
          id
          groupCount
          historyConnection(args: { skip: 0, limit: 10 }) {
            id
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const createGroupMutation = gql`
  mutation CreateGroup($name: String, $parentId: String) {
    createGroup(args: { name: $name, parentId: $parentId }) {
      group {
        id
        name
        parentId
        categoryId
      }
    }
  }
`
