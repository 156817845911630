/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

const OFF_OPTION = {
  id: 'false',
  isChecked: v => !v || !v?.enabled,
  label: i18n._({ id: 'never', message: 'Never' }),
  value: { enabled: false }
}

const NON_VERSION_OPTIONS = [
  OFF_OPTION,
  {
    id: 'true',
    isChecked: v => v?.enabled,
    label: i18n._({
      id: 'document.updating.saved',
      message: 'When the document is saved'
    }),
    value: { enabled: true }
  }
]

const VERSION_OPTIONS = [
  OFF_OPTION,
  {
    id: 'completed',
    isChecked: v => v?.enabled && v.updateOn === 'completed',
    label: i18n._({
      id: 'document.updating.completed',
      message: 'When a new version is completed'
    }),
    value: { enabled: true, updateOn: 'completed' }
  },
  {
    id: 'submitted',
    isChecked: v => v?.enabled && v.updateOn === 'submitted',
    label: i18n._({
      id: 'document.updating.submitted',
      message: 'When a new version is submitted'
    }),
    value: { enabled: true, updateOn: 'submitted' }
  },
  {
    id: 'filter',
    isChecked: v => v?.enabled && v.updateOn === 'filter',
    label: i18n._({
      id: 'document.updating.filter',
      message: 'When a new version meets filtering criteria'
    }),
    value: { enabled: true, updateOn: 'filter' }
  }
]

export default function AutoUpdateConfig ({
  id,
  Gadgets,
  className,
  hasVersions
}) {
  const configKey = 'autoUpdate'
  const options = hasVersions ? VERSION_OPTIONS : NON_VERSION_OPTIONS

  return (
    <Gadgets.Custom
      configKey={configKey}
      group
      className={cx('space-y-1', className)}
    >
      {({ onChange, value }) => (
        <>
          <legend>
            <Trans
              id='document.updating.when'
              message='When should it update?'
            />
          </legend>
          {options.map(option => {
            const optionId = `${id}-${configKey}-${option.id}`
            return (
              <label key={option.id}>
                <input
                  id={optionId}
                  type='radio'
                  className='kp-radio'
                  name={configKey}
                  value={option.id}
                  checked={option.isChecked(value)}
                  onChange={() => onChange(option.value)}
                />
                <span>{option.label}</span>
              </label>
            )
          })}
        </>
      )}
    </Gadgets.Custom>
  )
}
