/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useCreateTokenMutation = userId => {
  const [createToken] = useMutation(mutation)
  return (name, expiresAt) => createToken(getParams(userId, name, expiresAt))
}

const mutation = gql`
  mutation CreateToken($userId: ID!, $name: String!, $expiresAt: Float) {
    createApiKey(userId: $userId, name: $name, expiresAt: $expiresAt)
  }
`

const getParams = (userId, name, expiresAt) => ({
  variables: { userId, name, expiresAt }
})
