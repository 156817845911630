/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import { ModalPage } from '../../../components/modal-page'
import SubmitterDocument from './document'

export default function DocSubmission () {
  return (
    <ModalPage title={i18n._('submission')}>
      <SubmitterDocument />
    </ModalPage>
  )
}
