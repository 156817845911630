/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import { toDollars } from '../../../../src/pages-builder/calculations/calculation-functions'
import { Flex } from '../../../ui/layout'

export default function PaymentOptionsView ({ value, details }) {
  if (!value) return <div />
  return <StyledAmountLine {...value} />
}

export const StyledAmountLine = ({ amount, label }) => (
  <Flex>
    <Amount>{toDollars(amount)}</Amount>
    <div>{label}</div>
  </Flex>
)

const Amount = styled.div`
  font-weight: bold;
  margin-right: 8px;
`
