/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useRefetchQueries } from './use-refetch-queries'

export const useDiscardDocumentMutation = query => {
  const [discard] = useMutation(mutation)
  const refetchQueries = useRefetchQueries(['ActionPage'])
  return documentId => discard({ variables: { documentId }, refetchQueries })
}
const mutation = gql`
  mutation DiscardDocument($documentId: ID!) {
    discardDocument(args: { id: $documentId })
  }
`
