/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`64`} height={`64`} viewBox={`0 0 64 64`} fill={`none`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M18.7461 0H45.2565L64 18.7461V45.2565L45.2565 64H18.7461L0 45.2565V18.7461L18.7461 0ZM28.7206 35.7192V15.6523C28.7206 14.7652 29.0663 13.9146 29.6816 13.2874C30.2969 12.6601 31.1314 12.3078 32.0016 12.3078C32.8717 12.3078 33.7062 12.6601 34.3215 13.2874C34.9368 13.9146 35.2825 14.7652 35.2825 15.6523V35.7192C35.2825 36.6062 34.9368 37.4568 34.3215 38.0841C33.7062 38.7113 32.8717 39.0636 32.0016 39.0636C31.1314 39.0636 30.2969 38.7113 29.6816 38.0841C29.0663 37.4568 28.7206 36.6062 28.7206 35.7192ZM35.6922 48.0001C35.6922 50.0393 34.0391 51.6924 31.9999 51.6924C29.9607 51.6924 28.3076 50.0393 28.3076 48.0001C28.3076 45.9609 29.9607 44.3078 31.9999 44.3078C34.0391 44.3078 35.6922 45.9609 35.6922 48.0001Z`} fill={`#D22E2F`}/></svg>
);

let src = "<svg width=\"64\" height=\"64\" viewBox=\"0 0 64 64\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.7461 0H45.2565L64 18.7461V45.2565L45.2565 64H18.7461L0 45.2565V18.7461L18.7461 0ZM28.7206 35.7192V15.6523C28.7206 14.7652 29.0663 13.9146 29.6816 13.2874C30.2969 12.6601 31.1314 12.3078 32.0016 12.3078C32.8717 12.3078 33.7062 12.6601 34.3215 13.2874C34.9368 13.9146 35.2825 14.7652 35.2825 15.6523V35.7192C35.2825 36.6062 34.9368 37.4568 34.3215 38.0841C33.7062 38.7113 32.8717 39.0636 32.0016 39.0636C31.1314 39.0636 30.2969 38.7113 29.6816 38.0841C29.0663 37.4568 28.7206 36.6062 28.7206 35.7192ZM35.6922 48.0001C35.6922 50.0393 34.0391 51.6924 31.9999 51.6924C29.9607 51.6924 28.3076 50.0393 28.3076 48.0001C28.3076 45.9609 29.9607 44.3078 31.9999 44.3078C34.0391 44.3078 35.6922 45.9609 35.6922 48.0001Z\" fill=\"#D22E2F\"/>\n</svg>\n";
export default src;
