/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { reduce } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Duration } from '../duration/view'

const StepLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
`

const getOldestStep = value => {
  const oldest = reduce(value, (oldest, currentStep) => {
    if (currentStep.startDate < oldest.startDate) return currentStep
    return oldest
  })
  if (!oldest) return oldest
  return {
    ...oldest,
    runTime: Date.now() - oldest.startDate
  }
}

function WorkflowCurrentSteps ({ value }) {
  const oldestStep = React.useMemo(() => getOldestStep(value), [value])
  if (!oldestStep) return null
  return (
    <div>
      <Duration
        milliseconds={oldestStep.runTime}
        startDate={oldestStep.startDate}
      />
      <StepLabel>{localizeLabel(oldestStep.label)}</StepLabel>
    </div>
  )
}

export default function WorkflowCurrentStepsView (props) {
  return <WorkflowCurrentSteps {...props} />
}

const localizeLabel = label => {
  switch (label) {
    case 'Approval':
      return i18n._({ id: 'approval', message: 'Approval' })
    case 'Task':
      return i18n._({ id: 'task', message: 'Task' })
    default:
      return label
  }
}
