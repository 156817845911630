/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
export const STATUS_ENUM = {
  COMPLETE: {
    value: 'Complete',
    additionalValues: [],
    label: 'Complete',
    class: 'complete'
  },
  REJECTED: {
    value: 'Rejected',
    additionalValues: ['Denied'],
    label: 'Denied',
    class: 'denied'
  },
  WITHDRAWN: {
    value: 'Withdrawn',
    additionalValues: [],
    label: 'Withdrawn',
    class: 'withdrawn'
  },
  IN_PROGRESS: {
    value: 'In Progress',
    additionalValues: [],
    label: 'In Progress',
    class: 'in-progress'
  },
  DRAFT: {
    value: 'Draft',
    additionalValues: [],
    label: 'Draft',
    class: 'draft'
  },
  ERROR: {
    value: 'Error',
    additionalValues: [],
    label: 'Error',
    class: 'error'
  }
}

export const STATUSES = Object.values(STATUS_ENUM)

export const STATUS_CLASS_MAP = STATUSES.reduce((classMap, status) => {
  classMap[status.value] = status.class
  if (status.additionalValues) {
    status.additionalValues.forEach(value => {
      classMap[value] = status.class
    })
  }
  return classMap
}, {})

export const STATUS_LABEL_MAP = STATUSES.reduce((labelMap, status) => {
  labelMap[status.value] = status.label
  if (status.additionalValues) {
    status.additionalValues.forEach(value => {
      labelMap[value] = status.label
    })
  }
  return labelMap
}, {})
