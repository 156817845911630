/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get, includes } from 'lodash'
import React from 'react'

import * as VoronoiDND from '../voronoi-dnd'
import { getAriaLabel } from './utils'

const DraggableGadget = VoronoiDND.draggable(
  class _Gadget extends React.Component {
    render () {
      const style = { height: '100%' }
      if (this.props.isA11yDragging || this.props.isDragging) {
        style.opacity = 0.2
      }
      return this.props.connectDragSource(
        <div style={style} onClick={this.props.onClick}>
          {this.props.children}
        </div>
      )
    }
  }
)

export const draggable = ({ template: gadget, context }) => {
  const selected = gadget.id && get(context, 'selected.id') === gadget.id
  if (gadget.details?.disableDND) return {}
  return {
    component: component => {
      if (!selected && !includes(['Column', 'Row'], gadget.type)) {
        component = (
          <DraggableGadget
            isA11yDragging={context.a11yDragItem?.id === gadget.id}
            dragContext={{ id: gadget.id }}
            beginDrag={() => context.beginDrag(gadget)}
            endDrag={didDrop => context.endDrag(didDrop, gadget)}
            onClick={e => {
              if ((e.metaKey || e.ctrlKey) && gadget.type !== 'Section') return
              e.stopPropagation()
              context.select(gadget.id)
            }}
          >
            {component}
          </DraggableGadget>
        )
      }
      let padding = 10
      if (includes(['Column', 'Row'], gadget.type)) padding = 5
      if (gadget.type === 'Section') padding = 0

      // If the gadget is inside a repeater, we want to use the full
      // composite id so it creates a unique Voronoi cell
      const id = gadget.fullId ?? gadget.id
      return (
        <VoronoiDND.Item
          key={gadget.id}
          id={id}
          data={gadget.type}
          padding={padding}
          top={!['Column', 'TableColumn'].includes(gadget.type)}
          bottom={!['Column', 'TableColumn'].includes(gadget.type)}
          left={gadget.type !== 'Row'}
          right={gadget.type !== 'Row'}
          ariaLabel={getAriaLabel(gadget)}
        >
          {component}
        </VoronoiDND.Item>
      )
    }
  }
}

export const runtime = ({ template: gadget }) => {
  return {
    component: component => {
      if (!includes(['Column', 'Row'], gadget.type)) {
        component = <div style={{ height: '100%' }}>{component}</div>
      }
      if (gadget.details?.headless) {
        return (
          <div key={gadget.id} data-headless>
            {component}
          </div>
        )
      }
      return <div key={gadget.id}>{component}</div>
    }
  }
}
