/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import {
  get,
  inRange,
  includes,
  invert,
  isNumber,
  isObject,
  max,
  min
} from 'lodash'
import React from 'react'

import CreditsInput from './credits-input'

export function check (value, config) {
  const range = getMinMax(value)

  switch (config.type) {
    case 'equalTo':
      return creditsInclude(value, config.value)
    case 'greaterThan':
      return isNumber(range.max) && range.max > config.value
    case 'lessThan':
      return isNumber(range.min) && range.min < config.value
    case 'greaterThanOrEqualTo':
      return isNumber(range.max) && range.max >= config.value
    case 'lessThanOrEqualTo':
      return isNumber(range.min) && range.min <= config.value
    case 'notEqualTo':
      return !creditsInclude(value, config.value)
    case 'isEmpty':
      return !isNumber(range.min) && !isNumber(range.max)
    case 'isNotEmpty':
      return isNumber(range.min) && isNumber(range.max)
    default:
      return true
  }
}

export function creditsInclude (value, configValue) {
  switch (value && value.type) {
    case 'single':
      return value.credits === configValue
    case 'multiple':
      return includes(value.credits || [], configValue)
    case 'range':
      return (
        isNumber(value.credits.min) &&
        isNumber(value.credits.max) &&
        inRange(configValue, value.credits.min, value.credits.max)
      )
    default:
      return false
  }
}

export function getMinMax (value) {
  switch (value && value.type) {
    case 'single':
      return { min: value.credits, max: value.credits }
    case 'multiple':
      return { min: min(value.credits), max: max(value.credits) }
    case 'range':
      return value.credits
    default:
      return { min: null, max: null }
  }
}

export function configIsValid (configData) {
  if (!isObject(configData)) {
    return false
  }
  if (includes(['isEmpty', 'isNotEmpty'], configData.type)) return true

  return (
    includes(
      [
        'equalTo',
        'greaterThan',
        'lessThan',
        'greaterThanOrEqualTo',
        'lessThanOrEqualTo',
        'notEqualTo'
      ],
      configData.type
    ) && isNumber(configData.value)
  )
}

export function component ({ onChange, value }) {
  return (
    <div className='flex items-center gap-2'>
      <select
        className='kp-select flex-1'
        onChange={e => onChange({ ...value, type: e.target.value })}
        value={value.type || ''}
        aria-label='operand'
      >
        <option value=''>- - -</option>
        <option value='equalTo'>
          <Trans id='is.equal.to' />
        </option>
        <option value='notEqualTo'>
          <Trans id='is.not.equal.to' />
        </option>
        <option value='greaterThan'>
          <Trans id='is.greater.than' />
        </option>
        <option value='lessThan'>
          <Trans id='is.less.than' />
        </option>
        <option value='greaterThanOrEqualTo'>
          <Trans id='is.greater.than.or.equal' />
        </option>
        <option value='lessThanOrEqualTo'>
          <Trans id='is.less.than.or.equal' />
        </option>
        <option value='isEmpty'>
          <Trans id='is.empty' />
        </option>
        <option value='isNotEmpty'>
          <Trans id='is.not.empty' />
        </option>
      </select>
      {hasValue(value.type) && (
        <CreditsInput
          className='flex-1'
          onChange={val => {
            if (val === '') return onChange({ ...value, value: '' })
            const number = parseFloat(val, 10)
            if (isFinite(number)) return onChange({ ...value, value: number })
          }}
          value={get(value, 'value', '')}
        />
      )}
    </div>
  )
}

const hasValue = type => type && !includes(['isEmpty', 'isNotEmpty'], type)

const uiToWf = {
  equalTo: 'is',
  notEqualTo: 'is not',
  greaterThan: 'is greater than',
  lessThan: 'is less than',
  greaterThanOrEqualTo: 'is greater than or equal to',
  lessThanOrEqualTo: 'is less than or equal to',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  const newExpr = {
    left: {
      dataType: 'credits',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
  if (hasValue(expr.data.type)) {
    newExpr.right = {
      dataType: 'number',
      value: expr.data.value
    }
  }
  return newExpr
}

export function fromWorkflowFormat (expr) {
  const newExpr = {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
  if (hasValue(newExpr.data.type)) {
    newExpr.data.value = expr.right.value
  }
  return newExpr
}
