/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useRefetchQueries } from './use-refetch-queries'

export const useUpgradeDocumentMutation = () => {
  const [upgrade] = useMutation(mutation)
  const refetchQueries = useRefetchQueries(['ListPageQuery'])
  return documentId =>
    upgrade({
      refetchQueries,
      variables: { id: documentId }
    })
}

const mutation = gql`
  mutation Upgrade($id: ID!) {
    upgradeDocument(id: $id) {
      __typename
      ... on Redirect {
        url
        newDocumentId
        newActionId
      }

      ... on Error {
        reason
      }
    }
  }
`
