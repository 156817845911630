/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M12.216 0.832659C12.216 0.655848 12.1458 0.486279 12.0207 0.361254C11.8957 0.23623 11.7262 0.165992 11.5493 0.165992C11.3437 0.167236 11.1434 0.231319 10.9753 0.349645C10.8072 0.467972 10.6792 0.634884 10.6087 0.827992L9.15667 4.94399C9.13364 5.009 9.091 5.06527 9.03464 5.10503C8.97828 5.14479 8.91098 5.16609 8.84201 5.16599H4.78267C4.57722 5.16584 4.3767 5.22897 4.2084 5.3468C4.04009 5.46464 3.91216 5.63145 3.84201 5.82456C3.77187 6.01767 3.7629 6.2277 3.81634 6.42608C3.86977 6.62447 3.98302 6.80158 4.14067 6.93333L7.60001 9.79999C7.6517 9.84289 7.68923 9.90041 7.70767 9.96501C7.72611 10.0296 7.7246 10.0983 7.70334 10.162L6.24867 14.5153C6.18153 14.7166 6.18014 14.934 6.2447 15.1361C6.30926 15.3382 6.43642 15.5145 6.6078 15.6396C6.77918 15.7646 6.98589 15.832 7.19805 15.8318C7.41021 15.8316 7.61682 15.764 7.78801 15.6387L11.9433 12.592C12.0278 12.53 12.0964 12.449 12.1436 12.3555C12.1909 12.262 12.2155 12.1587 12.2153 12.054L12.216 0.832659Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M12.216 0.832659C12.216 0.655848 12.1458 0.486279 12.0207 0.361254C11.8957 0.23623 11.7262 0.165992 11.5493 0.165992C11.3437 0.167236 11.1434 0.231319 10.9753 0.349645C10.8072 0.467972 10.6792 0.634884 10.6087 0.827992L9.15667 4.94399C9.13364 5.009 9.091 5.06527 9.03464 5.10503C8.97828 5.14479 8.91098 5.16609 8.84201 5.16599H4.78267C4.57722 5.16584 4.3767 5.22897 4.2084 5.3468C4.04009 5.46464 3.91216 5.63145 3.84201 5.82456C3.77187 6.01767 3.7629 6.2277 3.81634 6.42608C3.86977 6.62447 3.98302 6.80158 4.14067 6.93333L7.60001 9.79999C7.6517 9.84289 7.68923 9.90041 7.70767 9.96501C7.72611 10.0296 7.7246 10.0983 7.70334 10.162L6.24867 14.5153C6.18153 14.7166 6.18014 14.934 6.2447 15.1361C6.30926 15.3382 6.43642 15.5145 6.6078 15.6396C6.77918 15.7646 6.98589 15.832 7.19805 15.8318C7.41021 15.8316 7.61682 15.764 7.78801 15.6387L11.9433 12.592C12.0278 12.53 12.0964 12.449 12.1436 12.3555C12.1909 12.262 12.2155 12.1587 12.2153 12.054L12.216 0.832659Z\" />\n</svg>\n";
export default src;
