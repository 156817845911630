/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { keyBy } from 'lodash'

export const columns = [
  {
    key: 'documentType',
    field: 'display.id',
    getValue: result => result?.display?.id ?? '--'
  },
  {
    key: 'serialNumber',
    field: 'details.serialNumber',
    getValue: result => result?.details?.serialNumber
  },
  {
    key: 'submitter',
    field: 'details.createdBy.label',
    getValue: result => result?.details?.createdBy?.label
  },
  {
    key: 'step',
    field: 'display.title',
    getValue: result => {
      if (result?.display?.title) {
        return getLabel(result.display.title)
      } else {
        return '--'
      }
    }
  },
  {
    key: 'createdAt',
    field: 'createdAt',
    getValue: result => {
      if (!result?.createdAt) return '--'
      return i18n.date(new Date(result.createdAt), { dateStyle: 'medium' })
    }
  },
  {
    key: 'action',
    field: 'type',
    getValue: result => {
      if (result?.type) {
        return getLabel(result.type)
      } else {
        return '--'
      }
    }
  }
]

const getLabel = type => {
  switch (type) {
    case 'Approval':
      return i18n._({ id: 'approval', message: 'Approval' })
    case 'Task':
      return i18n._({ id: 'task', message: 'Task' })
    case 'Acknowledge':
      return i18n._({ id: 'acknowledge', message: 'Acknowledge' })
    default:
      return type
  }
}

const columnsObj = keyBy(columns, 'key')
export const render = (result, key) => columnsObj[key].getValue(result)
