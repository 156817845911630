/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'

import { useIds } from '../use-ids'

export default function PageWrapper ({ children, className }) {
  const ids = useIds()
  return (
    <PageContainer
      isProduct={!!ids.pageId}
      className={cx(className, 'bg-light-gray-100 dark:bg-light-gray-200')}
    >
      <div className='h-6' />
      {children}
      <div className='h-12 max-sm:h-36' />
    </PageContainer>
  )
}

const PageContainer = styled.div`
  min-height: ${p => (p.isProduct ? '100%' : '100vh')};
  display: flex;
  flex-direction: column;
`
