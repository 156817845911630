/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { StaticOutlet2 } from '../components/static-outlet'

export default function ({ context }) {
  const location = useLocation()
  const count = location.pathname.includes('/actions') ? 3 : 4
  const root = location.pathname.split('/').slice(0, count).join('/')
  return (
    <TransitionGroup key={root}>
      <CSSTransition key={getTransitionKey(location.pathname)} timeout={450}>
        <StaticOutlet2 context={context} />
      </CSSTransition>
    </TransitionGroup>
  )
}

function getTransitionKey (pathname) {
  if (pathname.includes('actions')) return pathname.split('/versions').at(0)
  const pathsWithSubpaths = ['api-integrations', 'identity', 'account', 'usage']
  const foundPath = pathsWithSubpaths.find(path => pathname.includes(path))
  return foundPath ?? pathname.replace(/(\/\d+)?$/, '')
}
