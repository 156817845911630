/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default function Checkbox ({
  indeterminate,
  checked,
  className,
  ...props
}) {
  const ref = React.useRef()
  React.useEffect(() => {
    if (checked) {
      ref.current.checked = true
      ref.current.indeterminate = false
    } else if (indeterminate) {
      ref.current.checked = false
      ref.current.indeterminate = true
    } else {
      ref.current.checked = false
      ref.current.indeterminate = false
    }
  }, [indeterminate, checked])
  return (
    <input
      ref={ref}
      type='checkbox'
      className={cx('kp-checkbox', className)}
      {...props}
    />
  )
}
