/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const product = [
  'animal-monitor',
  'biosafety',
  'budget',
  'cm',
  'conflict',
  'export-control',
  'human-subjects',
  'internal-grant',
  'lab-management',
  'laptop',
  'performance-graph',
  'proposal',
  'protocols',
  'publications',
  'pylon',
  'radioactive',
  'ready',
  'settings-slider',
  'sp'
]

export const suite = [
  'build',
  'financials',
  'humans',
  'product-builder',
  'ready',
  'research',
  'student'
]
