/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M14.3333 1.66669H1.66665C1.31302 1.66669 0.973886 1.80716 0.723837 2.05721C0.473789 2.30726 0.333313 2.6464 0.333313 3.00002V5.00002C0.333313 5.17683 0.403551 5.3464 0.528575 5.47142C0.653599 5.59645 0.823169 5.66669 0.99998 5.66669H15C15.1768 5.66669 15.3464 5.59645 15.4714 5.47142C15.5964 5.3464 15.6666 5.17683 15.6666 5.00002V3.00002C15.6666 2.6464 15.5262 2.30726 15.2761 2.05721C15.0261 1.80716 14.6869 1.66669 14.3333 1.66669Z`}/><path d={`M14.3333 6.66669H1.66667C1.48986 6.66669 1.32029 6.73693 1.19526 6.86195C1.07024 6.98697 1 7.15654 1 7.33335V13C1 13.3536 1.14048 13.6928 1.39052 13.9428C1.64057 14.1929 1.97971 14.3334 2.33333 14.3334H13.6667C14.0203 14.3334 14.3594 14.1929 14.6095 13.9428C14.8595 13.6928 15 13.3536 15 13V7.33335C15 7.15654 14.9298 6.98697 14.8047 6.86195C14.6797 6.73693 14.5101 6.66669 14.3333 6.66669ZM10.1667 9.00002C10.1663 9.22093 10.0784 9.43268 9.9222 9.58889C9.766 9.74509 9.55424 9.833 9.33333 9.83335H6.66667C6.44565 9.83335 6.23369 9.74556 6.07741 9.58928C5.92113 9.433 5.83333 9.22103 5.83333 9.00002C5.83333 8.77901 5.92113 8.56704 6.07741 8.41076C6.23369 8.25448 6.44565 8.16669 6.66667 8.16669H9.33333C9.55424 8.16704 9.766 8.25495 9.9222 8.41115C10.0784 8.56736 10.1663 8.77911 10.1667 9.00002Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M14.3333 1.66669H1.66665C1.31302 1.66669 0.973886 1.80716 0.723837 2.05721C0.473789 2.30726 0.333313 2.6464 0.333313 3.00002V5.00002C0.333313 5.17683 0.403551 5.3464 0.528575 5.47142C0.653599 5.59645 0.823169 5.66669 0.99998 5.66669H15C15.1768 5.66669 15.3464 5.59645 15.4714 5.47142C15.5964 5.3464 15.6666 5.17683 15.6666 5.00002V3.00002C15.6666 2.6464 15.5262 2.30726 15.2761 2.05721C15.0261 1.80716 14.6869 1.66669 14.3333 1.66669Z\" />\n<path d=\"M14.3333 6.66669H1.66667C1.48986 6.66669 1.32029 6.73693 1.19526 6.86195C1.07024 6.98697 1 7.15654 1 7.33335V13C1 13.3536 1.14048 13.6928 1.39052 13.9428C1.64057 14.1929 1.97971 14.3334 2.33333 14.3334H13.6667C14.0203 14.3334 14.3594 14.1929 14.6095 13.9428C14.8595 13.6928 15 13.3536 15 13V7.33335C15 7.15654 14.9298 6.98697 14.8047 6.86195C14.6797 6.73693 14.5101 6.66669 14.3333 6.66669ZM10.1667 9.00002C10.1663 9.22093 10.0784 9.43268 9.9222 9.58889C9.766 9.74509 9.55424 9.833 9.33333 9.83335H6.66667C6.44565 9.83335 6.23369 9.74556 6.07741 9.58928C5.92113 9.433 5.83333 9.22103 5.83333 9.00002C5.83333 8.77901 5.92113 8.56704 6.07741 8.41076C6.23369 8.25448 6.44565 8.16669 6.66667 8.16669H9.33333C9.55424 8.16704 9.766 8.25495 9.9222 8.41115C10.0784 8.56736 10.1663 8.77911 10.1667 9.00002Z\" />\n</svg>\n";
export default src;
