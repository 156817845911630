/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import { VisuallyHidden } from './a11y'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.inline ? 'row' : 'column')};
  ${space}
`

const Radio = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0;
  margin-top: 4px;
  margin-right: 8px;
  position: relative;
  top: -2px;
  flex-shrink: 0;

  &:checked {
    border-color: var(--blue-500);
  }

  &:checked::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: var(--blue-500);
    border-radius: 100px;
    position: relative;
    top: 2px;
    left: 2px;
  }

  &:disabled {
    cursor: default;
    border-color: #ccc;
  }
  &:disabled::after {
    background: #ccc;
  }
`

const Label = styled.label`
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  color: ${p => (p.disabled ? '#aaa' : 'inherit')};
  white-space: ${p => p.nowrap};
  display: inline-flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-right: ${p => (p.inline ? 16 : 0)}px;
    margin-bottom: ${p => (p.inline ? 0 : 8)}px;
  }
`
const Span = styled.span`
  word-break: break-word;
  width: 100%;
`

export default React.forwardRef(
  (
    { options, value, onChange, nowrap, name, disabled, autoFocus, ...rest },
    ref
  ) => (
    <Wrapper ref={ref} role='group' {...rest}>
      {options.map((option, i) => (
        <React.Fragment key={option.id}>
          <Label
            disabled={disabled}
            data-testid={option.id}
            htmlFor={option.htmlId || `option-${option.id}`}
            nowrap={nowrap ? 'nowrap' : 'normal'}
            inline={rest.inline}
          >
            <Radio
              className='border-2 border-medium-gray-500 dark:border-black'
              id={option.htmlId || `option-${option.id}`}
              name={name}
              disabled={disabled}
              checked={option.id === value}
              autoFocus={autoFocus && i === 0}
              onChange={
                onChange && !disabled ? () => onChange(option.id) : undefined
              }
            />
            <Span aria-hidden>{option.label}</Span>
            <VisuallyHidden>{option.ariaLabel || option.label}</VisuallyHidden>
          </Label>
          {option.id === value && option.children && (
            <div className={cx(option.className, 'mb-2 pl-6')}>
              {option.children}
            </div>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  )
)
