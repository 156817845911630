/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { DragSource } from 'react-dnd'

export default DragSource(
  'VORONOI',
  {
    beginDrag: props => {
      if (props.beginDrag) props.beginDrag(props.beginDragProp)
      return props.dragContext
    },
    endDrag: (props, monitor) => {
      if (props.endDrag) props.endDrag(monitor.didDrop())
    },
    canDrag: props => !props.disabled
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)
