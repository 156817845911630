/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { minMaxCurrency } from '../../validations'
import { OptionalConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validations'
import View from './view'

export default {
  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,
  OptionalConfig,
  defaultValue: null,
  Edit,
  meta: {
    category: 'Basic',
    Icon,
    label: 'Currency',
    settingsHelp: (
      <>
        In order to maintain data integrity, values entered in this gadget will
        be stored as “cents” (or the smallest denomination available). People
        will still be able to input amounts in traditional notation, but it will
        be converted on the back end to cents (or their equivalent). Example:
        $3.45 will show up as 345.
      </>
    )
  },
  validationOptions: [minMaxCurrency],
  progressiveDisclosure,
  sampleValue: 1234,
  validateShape,
  View
}
