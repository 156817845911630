/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import { TextView } from '../../gadget-helpers'

export default function ({ value, gridded, condensed }) {
  return (
    <div className='flex'>
      <div className='flex-1'>
        <div className='pl-4'>
          <Trans id='start.term' />
        </div>
        <TextView {...{ condensed, gridded }}>
          {value?.startTerm?.label}
        </TextView>
      </div>
      <div className='flex-1'>
        <div className='pl-4'>
          <Trans id='end.term' />
        </div>
        <TextView {...{ condensed, gridded }}>{value?.endTerm?.label}</TextView>
      </div>
    </div>
  )
}
