/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import {
  compact,
  every,
  flatten,
  includes,
  isArray,
  isBoolean,
  isFinite,
  isNil,
  isPlainObject,
  isString,
  map,
  reduce
} from 'lodash'

export function validate (schema, value) {
  return schema(value, '')
}
const v = {}

v.any = any
function any () {
  return (val, lbl) => (!isNil(val) ? null : `${lbl} must be present`)
}

v.string = string
function string () {
  return (val, lbl) => (isString(val) ? null : `${lbl} must be a string`)
}

v.number = number
function number () {
  return (val, lbl) =>
    isFinite(parseInt(val, 10)) ? null : `${lbl} must be a number`
}

v.bool = bool
function bool () {
  return (val, lbl) => (isBoolean(val) ? null : `${lbl} must be a bool`)
}

v.array = array
function array () {
  return (val, lbl) => (isArray(val) ? null : `${lbl} must be an array`)
}

v.object = object
function object () {
  return (val, lbl) => (isPlainObject(val) ? null : `${lbl} must be an object`)
}

v.oneOf = oneOf
function oneOf (choices) {
  return (val, lbl) =>
    includes(choices, val)
      ? null
      : `${lbl} must be one of: ${JSON.stringify(choices)}`
}

v.optional = optional
function optional (schema) {
  return (val, lbl) => {
    if (isNil(val)) return null
    return schema(val, lbl)
  }
}

v.arrayOf = arrayOf
function arrayOf (schema) {
  return (val, lbl) => {
    if (!isArray(val)) return `${lbl} must be an array`
    const errors = compact(map(val, (v, i) => schema(v, `${lbl}[${i}]`)))
    return errors.length ? errors : null
  }
}

v.oneOfType = oneOfType
function oneOfType (schema) {
  return (val, lbl) => {
    const errArray = map(schema, s => s(val, lbl))
    return every(errArray)
      ? [`${lbl} did not match union type`, ...flatten(errArray)]
      : null
  }
}

v.shape = shape
function shape (schema) {
  return (val, lbl) => {
    if (!isPlainObject(val)) return `${lbl} must be an object`
    let errors = map(schema, (validator, key) =>
      validator(val[key], `${lbl}.${key}`)
    )
    errors = reduce(
      val,
      (memo, _val, key) => {
        if (!(key in schema)) memo.push(`${lbl}.${key} should not exist`)
        return memo
      },
      errors
    )
    errors = compact(flatten(errors))
    return errors.length ? errors : null
  }
}

export default v
