/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import * as Icons from '../../icons'
import * as wfUtils from './workflow-tracker/utils'

export default ({ simulation, onClose }) => {
  if (!simulation) return null
  const data = wfUtils.getSendBackData(simulation)
  if (!data) return null
  const date = new Intl.DateTimeFormat(window.navigator.language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(+new Date(data.date))
  return (
    <div
      className='mb-6 border-t-[10px] border-t-yellow-400 bg-white p-6 pt-[15px] text-dark-gray-300 shadow-md'
      data-testid='send-back-message'
    >
      <div className='relative'>
        <div className='pr-[35px]'>
          <span className='mr-[17px] text-base font-medium'>
            <Trans id='pagesrunner.action.sent.back' />
          </span>{' '}
          <Trans id='pagesrunner.action.sent.back.data' /> <b>{data.from}</b>{' '}
          <span className='px-[5px] after:block after:content-["|"]' /> {date}
        </div>
        <button
          className='kp-button-transparent kp-button-icon absolute -right-2.5 -top-2.5'
          onClick={onClose}
        >
          <Icons.Close />
        </button>
      </div>
      <div className='ml-[34px] h-0 w-0 border-[6px] border-b-[9px] border-t-0 border-transparent border-b-light-gray-100' />
      <div className='min-h-[64px] bg-light-gray-100 p-4 text-dark-gray-500'>
        {data.comment}
      </div>
    </div>
  )
}
