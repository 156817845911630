/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import cx from 'clsx'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { useLocalStorageState } from '../../components/use-local-storage-state'
import * as Icons from '../../icons'
import { ReactComponent as ExpandIcon } from './expand.svg.jsx'

export const Scrollable = React.forwardRef(
  ({ children, className, isTable, onClick }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className={cx(
        className,
        'overflow-auto',
        isTable ? 'h-[calc(100vh-248px)]' : 'h-[calc(100vh-184px)]'
      )}
    >
      {children}
    </div>
  )
)

export const Collapsible = ({ id, title, help, collapsed, children }) => {
  const key = `kbld:collapsed:${id}`
  const [isCollapsed, setIsCollapsed] = useLocalStorageState(key, collapsed)
  return (
    <div className='border-b border-light-gray-300'>
      <div className='relative'>
        <button
          className={cx(
            'w-full px-6 py-2.5 text-left text-sm font-medium uppercase text-medium-gray-500',
            !!help && 'pr-8'
          )}
          onClick={() => setIsCollapsed(a => !a)}
        >
          {title}
        </button>
        {help && (
          <TooltipTrigger
            label={`${title} ${i18n._('pagesbuilder.form.ui.information')}`}
            tooltipId={`section-help-for-${id}`}
          >
            <Icons.AlertHelp className='absolute right-8 top-2.5 ml-1' />
          </TooltipTrigger>
        )}
        <ExpandIcon
          className={cx(
            'pointer-events-none absolute right-4 top-3 transition-transform',
            !isCollapsed && 'rotate-90'
          )}
          alt=''
        />
      </div>
      {help && (
        <Tooltip id={`section-help-for-${id}`}>
          <div className='w-64'>{help}</div>
        </Tooltip>
      )}
      {!isCollapsed && children}
    </div>
  )
}
