/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useAddIdentityMutation = roleId => {
  const [addIdentity] = useMutation(mutation)
  const query = useQueryContext()
  return identity => addIdentity(getParams(roleId, identity, query))
}

const mutation = gql`
  mutation AddIdentityToRole(
    $roleId: String!
    $identity: PolicyGroupIdentityInput!
  ) {
    attachIdentityToRole(args: { roleId: $roleId, identity: $identity })
  }
`

const getParams = (roleId, identity, query) => ({
  variables: { roleId, identity },
  refetchQueries: [query]
})
