/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.7033 5.81934C15.6321 5.62801 15.5042 5.46296 15.3367 5.34622C15.1693 5.22948 14.9701 5.16661 14.766 5.16601H10.708C10.6389 5.16606 10.5716 5.14466 10.5152 5.10477C10.4588 5.06488 10.4163 5.00848 10.3933 4.94334L8.93666 0.814673C8.86531 0.623847 8.73738 0.459367 8.56998 0.343244C8.40259 0.22712 8.20372 0.164895 7.99999 0.164895C7.79627 0.164895 7.5974 0.22712 7.43 0.343244C7.26261 0.459367 7.13468 0.623847 7.06333 0.814673L7.05999 0.82534L5.60666 4.94334C5.58377 5.00837 5.54128 5.0647 5.48505 5.10458C5.42881 5.14446 5.3616 5.16592 5.29266 5.16601H1.23333C1.02787 5.16585 0.827355 5.22898 0.659049 5.34682C0.490743 5.46465 0.362816 5.63147 0.292667 5.82458C0.222519 6.01768 0.213554 6.22771 0.266991 6.4261C0.320428 6.62448 0.433674 6.80159 0.591327 6.93334L4.04733 9.80001C4.09902 9.84291 4.13655 9.90042 4.15499 9.96502C4.17343 10.0296 4.17192 10.0983 4.15066 10.162L2.69866 14.516C2.63156 14.7173 2.63023 14.9348 2.69488 15.1369C2.75952 15.339 2.88678 15.5153 3.05826 15.6403C3.22973 15.7653 3.43652 15.8325 3.64872 15.8322C3.86093 15.8319 4.06753 15.7642 4.23866 15.6387L7.80266 13.0253C7.85981 12.9835 7.92881 12.9609 7.99966 12.9609C8.07051 12.9609 8.13951 12.9835 8.19666 13.0253L11.7593 15.638C11.9304 15.7639 12.1371 15.8321 12.3495 15.8326C12.5619 15.8332 12.7689 15.7661 12.9406 15.6411C13.1124 15.5161 13.2398 15.3397 13.3045 15.1374C13.3693 14.9351 13.3679 14.7175 13.3007 14.516L11.8487 10.1593C11.8274 10.0956 11.8259 10.027 11.8443 9.96236C11.8628 9.89776 11.9003 9.84024 11.952 9.79734L15.4147 6.92667C15.5703 6.79381 15.6814 6.61639 15.733 6.41838C15.7847 6.22036 15.7743 6.01128 15.7033 5.81934Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.7033 5.81934C15.6321 5.62801 15.5042 5.46296 15.3367 5.34622C15.1693 5.22948 14.9701 5.16661 14.766 5.16601H10.708C10.6389 5.16606 10.5716 5.14466 10.5152 5.10477C10.4588 5.06488 10.4163 5.00848 10.3933 4.94334L8.93666 0.814673C8.86531 0.623847 8.73738 0.459367 8.56998 0.343244C8.40259 0.22712 8.20372 0.164895 7.99999 0.164895C7.79627 0.164895 7.5974 0.22712 7.43 0.343244C7.26261 0.459367 7.13468 0.623847 7.06333 0.814673L7.05999 0.82534L5.60666 4.94334C5.58377 5.00837 5.54128 5.0647 5.48505 5.10458C5.42881 5.14446 5.3616 5.16592 5.29266 5.16601H1.23333C1.02787 5.16585 0.827355 5.22898 0.659049 5.34682C0.490743 5.46465 0.362816 5.63147 0.292667 5.82458C0.222519 6.01768 0.213554 6.22771 0.266991 6.4261C0.320428 6.62448 0.433674 6.80159 0.591327 6.93334L4.04733 9.80001C4.09902 9.84291 4.13655 9.90042 4.15499 9.96502C4.17343 10.0296 4.17192 10.0983 4.15066 10.162L2.69866 14.516C2.63156 14.7173 2.63023 14.9348 2.69488 15.1369C2.75952 15.339 2.88678 15.5153 3.05826 15.6403C3.22973 15.7653 3.43652 15.8325 3.64872 15.8322C3.86093 15.8319 4.06753 15.7642 4.23866 15.6387L7.80266 13.0253C7.85981 12.9835 7.92881 12.9609 7.99966 12.9609C8.07051 12.9609 8.13951 12.9835 8.19666 13.0253L11.7593 15.638C11.9304 15.7639 12.1371 15.8321 12.3495 15.8326C12.5619 15.8332 12.7689 15.7661 12.9406 15.6411C13.1124 15.5161 13.2398 15.3397 13.3045 15.1374C13.3693 14.9351 13.3679 14.7175 13.3007 14.516L11.8487 10.1593C11.8274 10.0956 11.8259 10.027 11.8443 9.96236C11.8628 9.89776 11.9003 9.84024 11.952 9.79734L15.4147 6.92667C15.5703 6.79381 15.6814 6.61639 15.733 6.41838C15.7847 6.22036 15.7743 6.01128 15.7033 5.81934Z\" />\n</svg>\n";
export default src;
