/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useDeleteSpaceMutation = () => {
  const [deleteSpace] = useMutation(deleteSpaceMutation)
  return id => deleteSpace(getParams(id))
}

const deleteSpaceMutation = gql`
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id) {
      result {
        id
        deleted
      }
      query {
        viewer {
          id
          spaces(type: ADMIN_ROOT) {
            id
            name
          }
        }
      }
    }
  }
`

const getParams = id => ({
  variables: { id }
})
