/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`44`} height={`44`} viewBox={`0 0 44 44`} fill={`none`}{...props}><path d={`M37.125 20.625H6.875C3.83743 20.625 1.375 23.0874 1.375 26.125V37.125C1.375 40.1626 3.83743 42.625 6.875 42.625H37.125C40.1626 42.625 42.625 40.1626 42.625 37.125V26.125C42.625 23.0874 40.1626 20.625 37.125 20.625Z`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M12.375 26.125H15.125`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M28.875 26.125H31.625`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M20.625 26.125H23.375`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M8.25 31.625H11`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M16.5 31.625H19.25`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M24.75 31.625H27.5`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M33 31.625H35.75`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M12.375 37.125H31.625`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M12.375 20.625V17.875C12.375 15.687 13.2442 13.5885 14.7914 12.0414C16.3385 10.4942 18.437 9.625 20.625 9.625H27.5C29.688 9.625 31.7865 8.75581 33.3336 7.20863C34.8808 5.66146 35.75 3.56304 35.75 1.375V1.375`} stroke={`#3369A3`} strokeWidth={`2.5`} strokeLinecap={`round`} strokeLinejoin={`round`}/></svg>
);

let src = "<svg width=\"44\" height=\"44\" viewBox=\"0 0 44 44\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M37.125 20.625H6.875C3.83743 20.625 1.375 23.0874 1.375 26.125V37.125C1.375 40.1626 3.83743 42.625 6.875 42.625H37.125C40.1626 42.625 42.625 40.1626 42.625 37.125V26.125C42.625 23.0874 40.1626 20.625 37.125 20.625Z\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M12.375 26.125H15.125\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M28.875 26.125H31.625\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M20.625 26.125H23.375\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M8.25 31.625H11\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M16.5 31.625H19.25\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M24.75 31.625H27.5\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M33 31.625H35.75\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M12.375 37.125H31.625\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M12.375 20.625V17.875C12.375 15.687 13.2442 13.5885 14.7914 12.0414C16.3385 10.4942 18.437 9.625 20.625 9.625H27.5C29.688 9.625 31.7865 8.75581 33.3336 7.20863C34.8808 5.66146 35.75 3.56304 35.75 1.375V1.375\" stroke=\"#3369A3\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n";
export default src;
