/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation, useSubscription } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { without, xor } from 'lodash'
import React from 'react'

import { useAlerts } from '../../../ui/alerts'
import Checkbox from '../../../ui/checkbox'

export default function ExportOptions ({ documentId, onCancel }) {
  const [value, setValue] = React.useState(['document'])
  const options = [
    {
      label: `${i18n._('pagesrunner.viewform.export.document')}`,
      id: 'document'
    },
    {
      label: `${i18n._('pagesrunner.viewform.export.workflow.history')}`,
      id: 'history'
    },
    {
      label: `${i18n._('pagesrunner.viewform.export.workflow.comments')}`,
      id: 'comments'
    }
  ]

  const handleChange = (id, checked) => {
    let newValue = xor(value, [id])
    if (newValue.includes('comments') && !newValue.includes('history')) {
      if (checked) newValue.push('history')
      else newValue = without(newValue, 'comments')
    }
    setValue(newValue)
  }

  const alerts = useAlerts()
  const [exportDocument, { data: exportData }] = useMutation(
    exportDocumentMutation
  )
  const {
    data,
    loading,
    error: networkError
  } = useSubscription(subscribeForDocumentExport, {
    variables: { jobId: exportData?.exportDocument },
    skip: !exportData?.exportDocument
  })

  if (networkError) {
    console.log("TODO: don't ignore this")
  }

  const url = data?.documentExported?.url
  const error = data?.documentExported?.error

  React.useEffect(() => {
    if (url) {
      onCancel()
      alerts.type2(
        <span>
          <Trans id='pagesrunner.viewform.export.pdf.found' />
        </span>,
        'info',
        <a href={url} download className='kp-button-solid kp-button-sm'>
          <Trans id='pagesrunner.viewform.export.download' />
        </a>
      )
    }
  }, [alerts, onCancel, url])

  React.useEffect(() => {
    if (error) {
      alerts.type3(
        i18n._('pagesrunner.viewform.export.error.exporting') + `${error}`,
        'error'
      )
    }
  }, [alerts, error])
  return (
    <div className='text-sm'>
      <div className='block bg-light-gray-100 px-4 py-2 text-xs uppercase text-medium-gray-500'>
        <Trans id='pagesrunner.viewform.export.export' />
      </div>
      <div className='p-4' data-testid='popover-export'>
        <div className='block text-xs text-medium-gray-500'>
          <Trans id='pagesrunner.viewform.export.include' />
        </div>
        {options.map(option => (
          <Checkbox
            key={option.id}
            checked={value.includes(option.id)}
            label={option.label}
            onChange={checked => handleChange(option.id, checked)}
          />
        ))}
        <div className='mt-4 flex gap-2'>
          <button className='kp-button-outline' onClick={onCancel}>
            <Trans id='cancel' />
          </button>
          <button
            className='kp-button-solid'
            disabled={!value.length || loading}
            title={
              value.length
                ? undefined
                : i18n._('pagesrunner.viewform.export.choose.one')
            }
            onClick={() => {
              exportDocument({
                variables: {
                  documentId,
                  options: value,
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  callbackUrl: `${window.location.origin}/app/api/v1/documentExported`
                }
              }).catch(() => {
                alerts.type2(
                  i18n._('pagesrunner.viewform.export.server.error'),
                  'error'
                )
              })
            }}
          >
            {loading
              ? i18n._('pagesrunner.viewform.export.exporting')
              : i18n._('pagesrunner.viewform.export.export')}
          </button>
        </div>
      </div>
    </div>
  )
}

const exportDocumentMutation = gql`
  mutation ExportDocument(
    $documentId: ID!
    $callbackUrl: String!
    $options: [String!]!
    $timeZone: String
  ) {
    exportDocument(
      id: $documentId
      callbackUrl: $callbackUrl
      options: $options
      timeZone: $timeZone
    )
  }
`

const subscribeForDocumentExport = gql`
  subscription DocumentExported($jobId: ID!) {
    documentExported(jobId: $jobId)
  }
`
