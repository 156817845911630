/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13.3334 4.33333H7.00002C6.95533 4.33325 6.91111 4.32417 6.86999 4.30665C6.82888 4.28912 6.7917 4.26351 6.76069 4.23133L5.86669 3.306C5.77311 3.20924 5.66102 3.13229 5.5371 3.07973C5.41319 3.02716 5.27996 3.00005 5.14535 3H2.85735C2.54179 3.00035 2.23925 3.12582 2.01605 3.34889C1.79285 3.57196 1.66722 3.87444 1.66669 4.19V11.81C1.66722 12.1256 1.79285 12.428 2.01605 12.6511C2.23925 12.8742 2.54179 12.9996 2.85735 13H13.1427C13.4582 12.9996 13.7608 12.8742 13.984 12.6511C14.2072 12.428 14.3328 12.1256 14.3334 11.81V5.33333C14.3334 5.06812 14.228 4.81376 14.0405 4.62623C13.8529 4.43869 13.5986 4.33333 13.3334 4.33333Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.3334 4.33333H7.00002C6.95533 4.33325 6.91111 4.32417 6.86999 4.30665C6.82888 4.28912 6.7917 4.26351 6.76069 4.23133L5.86669 3.306C5.77311 3.20924 5.66102 3.13229 5.5371 3.07973C5.41319 3.02716 5.27996 3.00005 5.14535 3H2.85735C2.54179 3.00035 2.23925 3.12582 2.01605 3.34889C1.79285 3.57196 1.66722 3.87444 1.66669 4.19V11.81C1.66722 12.1256 1.79285 12.428 2.01605 12.6511C2.23925 12.8742 2.54179 12.9996 2.85735 13H13.1427C13.4582 12.9996 13.7608 12.8742 13.984 12.6511C14.2072 12.428 14.3328 12.1256 14.3334 11.81V5.33333C14.3334 5.06812 14.228 4.81376 14.0405 4.62623C13.8529 4.43869 13.5986 4.33333 13.3334 4.33333Z\" />\n</svg>\n";
export default src;
