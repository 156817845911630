/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default ({
  icon,
  title,
  details,
  backGroundColor,
  isStacked,
  className
}) => {
  if (!title && !details) return null
  return (
    <div
      className={cx('flex pt-3', className, {
        '-mb-3 border-b border-light-gray-300': isStacked,
        'pb-3': !isStacked
      })}
    >
      <div
        className='flex w-10 items-center justify-center self-stretch p-6'
        style={{ backgroundColor: backGroundColor || '#F6A411' }}
      >
        {icon}
      </div>
      <div
        className='flex h-full w-full bg-white p-6 text-dark-gray-300 dark:bg-light-gray-300'
        data-testid='info-box'
      >
        <div className='relative'>
          <div className='flex flex-col items-start'>
            <div className='mb-2 mr-4 text-base font-medium text-black'>
              {title}
            </div>
            <div className='mr-4 text-dark-gray-100 dark:bg-light-gray-300'>
              {details}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
