/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

export function OptionalConfig ({ Gadgets }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' />
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='customLinkText.enabled'
        label={i18n._('link.text')}
        description={i18n._('link.text.data')}
      >
        <Gadgets.Text
          configKey='customLinkText.value'
          placeholder={{
            enabled: true,
            value: <Trans id='click.to.visit' message='Click to visit' />
          }}
        />
      </Gadgets.ConfigBox>
    </>
  )
}
