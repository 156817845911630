/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'

export default gql`
  query BlueprintsHistory(
    $id: ID!
    $q: String
    $skip: Int!
    $limit: Int!
    $sort: [String!]
  ) {
    category(id: $id) {
      id
      historyConnection(
        args: { query: $q, skip: $skip, limit: $limit, sort: $sort }
      ) {
        id
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          skip
          limit
        }
        edges {
          node {
            id
            createdAt
            updatedBy {
              id
              displayName
              email
            }
            operation
            description
          }
        }
      }
    }
  }
`
