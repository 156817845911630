/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import { ModalPage } from '../../../../components/modal-page'
import Button from '../../../../ui/button'
import Input from '../../../../ui/input'
import { Tab, TabLabel, Tabs } from '../../../../ui/tabs'
import { useSignFileUploadMutation } from '../../file-upload/parts/mutation.sign-file-upload'
import { useUploadFile } from '../../file-upload/parts/upload-file'
import DrawSignature from './draw-signature'
import { ReactComponent as Draw } from './draw.svg.jsx'
import { ReactComponent as Upload } from './image-upload.svg.jsx'
import { ReactComponent as Keyboard } from './keyboard.svg.jsx'

export default function SignatureModal ({
  actionId,
  currentUser,
  formKey,
  imagePreview,
  updateImagePreview,
  onChange,
  showModal,
  setShowModal
}) {
  const [selectedOption, setSelectedOption] = React.useState('type')
  const [signedName, setSignedName] = React.useState('')
  const [file, setFile] = React.useState()
  const accepts = 'image/*'
  const uploadMutation = useSignFileUploadMutation()
  const {
    uploading,
    progress,
    error,
    handleChange: handleFileUpload
  } = useUploadFile(setFile, accepts, uploadMutation)
  let signaturePadRef
  const labels = {
    type: i18n._('type.your.name'),
    draw: i18n._('draw.your.name.with.mouse'),
    upload: i18n._('upload.image.of.signature')
  }
  return (
    <TransitionGroup>
      <CSSTransition key={showModal} timeout={450}>
        {showModal ? (
          <ModalPage
            title={i18n._('add.signature')}
            onClose={() => setShowModal(false)}
            closeAriaLabel={i18n._('close.signature.modal')}
          >
            <Wrapper>
              <Tabs>
                <Tab
                  as='button'
                  onClick={() => {
                    setSelectedOption('type')
                  }}
                >
                  <Option>
                    <KeyboardIcon selected={selectedOption === 'type'} />
                    <TabLabel selected={selectedOption === 'type'}>
                      <Trans id='type' />
                    </TabLabel>
                  </Option>
                </Tab>
                <Tab
                  as='button'
                  onClick={() => {
                    setSelectedOption('draw')
                  }}
                >
                  <Option>
                    <DrawIcon selected={selectedOption === 'draw'} />
                    <TabLabel selected={selectedOption === 'draw'}>
                      <Trans id='draw' />
                    </TabLabel>
                  </Option>
                </Tab>
                <Tab
                  as='button'
                  onClick={() => {
                    setSelectedOption('upload')
                  }}
                >
                  <Option>
                    <UploadIcon selected={selectedOption === 'upload'} />
                    <TabLabel selected={selectedOption === 'upload'}>
                      <Trans id='image' />
                    </TabLabel>
                  </Option>
                </Tab>
              </Tabs>
              <AddSignature>
                <Instructions>
                  <InputLabel
                    id='signature-instructions'
                    className='text-medium-gray-500'
                  >
                    {labels[selectedOption]}
                  </InputLabel>
                  {selectedOption === 'draw' && (
                    <Button
                      small
                      transparent
                      onClick={() => signaturePadRef.clear()}
                    >
                      <Trans id='clear' />
                    </Button>
                  )}
                </Instructions>
                {selectedOption === 'type' && (
                  <SignatureInput
                    aria-labelledby='signature-instructions'
                    autoFocus
                    onChange={txt => setSignedName(txt)}
                    value={signedName}
                  />
                )}
                {selectedOption === 'draw' && (
                  <DrawSignature
                    formKey={formKey}
                    onLoad={signaturePad => (signaturePadRef = signaturePad)}
                  />
                )}
                {selectedOption === 'upload' && (
                  <div>
                    {file && (
                      <PreviewWrapper>
                        <PreviewImage src={imagePreview} alt='signature' />
                      </PreviewWrapper>
                    )}
                    {!file && (
                      <FileUpload
                        className='after:border after:border-dashed after:border-medium-gray-100 after:bg-light-gray-200 after:text-medium-gray-500'
                        accept={accepts}
                        aria-labelledby='signature-instructions'
                        autoFocus
                        id={formKey}
                        onChange={e => {
                          const f = e.target.files[0]
                          const reader = new FileReader()
                          reader.onload = e2 => {
                            updateImagePreview(e2.target.result)
                          }
                          reader.readAsDataURL(f)
                          handleFileUpload(f)
                        }}
                      />
                    )}
                    {error && (
                      <div className='text-red-500'>
                        Error uploading: {error.message}
                      </div>
                    )}
                    {uploading && <div>Uploading... {progress}%</div>}
                  </div>
                )}
                <TypeButtons>
                  <Button
                    outline
                    mr={3}
                    onClick={() => {
                      setSignedName('')
                      setFile()
                      updateImagePreview()
                      setShowModal(false)
                    }}
                  >
                    <Trans id='cancel' />
                  </Button>
                  <button
                    className='kp-button-solid'
                    onClick={() => {
                      const updates = {
                        displayName: currentUser
                          ? `${currentUser.displayName} (${currentUser.email})`
                          : 'Anonymous',
                        signedName,
                        date: new Date(),
                        signatureType: selectedOption,
                        actionId,
                        userId: currentUser ? currentUser.id : null
                      }
                      if (
                        selectedOption === 'draw' &&
                        signaturePadRef &&
                        !signaturePadRef.isEmpty()
                      ) {
                        const dataUrl = signaturePadRef.toDataURL()
                        updateImagePreview(dataUrl)
                        uploadSignatureDrawing(dataUrl, handleFileUpload).then(
                          fileInfo => {
                            onChange({
                              ...updates,
                              ...fileInfo
                            })
                            setShowModal(false)
                          }
                        )
                      } else if (
                        selectedOption === 'type' &&
                        updates.signedName
                      ) {
                        onChange({
                          ...updates,
                          ...file
                        })
                        setShowModal(false)
                      } else if (selectedOption === 'upload' && file) {
                        onChange({
                          ...updates,
                          ...file
                        })
                        setShowModal(false)
                      }
                    }}
                  >
                    <Trans id='save' />
                  </button>
                </TypeButtons>
              </AddSignature>
            </Wrapper>
          </ModalPage>
        ) : (
          <span />
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

const uploadSignatureDrawing = (dataUrl, handleFileUpload) => {
  const mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0]
  const binary = atob(dataUrl.split(',')[1])
  const data = new Uint8Array(new ArrayBuffer(binary.length))
  for (let i = 0; i < binary.length; i++) {
    data[i] = binary.charCodeAt(i)
  }
  const file = new File([data], 'signature.png', {
    type: mimeType
  })
  return handleFileUpload(file)
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 48px 0;
`
const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const KeyboardIcon = styled(Keyboard)`
  path {
    stroke: ${p => (p.selected ? 'var(--blue-500)' : '#BBBBBB')};
  }
`
const DrawIcon = styled(Draw)`
  path {
    stroke: ${p => (p.selected ? 'var(--blue-500)' : '#BBBBBB')};
  }
`
const UploadIcon = styled(Upload)`
  path {
    stroke: ${p => (p.selected ? 'var(--blue-500)' : '#BBBBBB')};
  }
`
const AddSignature = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
`
const TypeButtons = styled.div`
  padding-top: 40px;
  align-self: center;
`
const Instructions = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const InputLabel = styled.div`
  font-size: 12px;
`
const SignatureInput = styled(Input)`
  width: 320px;
  height: 56px;
  font-size: 32px;
  font-family: 'Yellowtail', cursive;
`
const FileUpload = styled.input.attrs({ type: 'file' })`
  width: 456px;
  padding: 64px 0;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 320px;
  }

  &::after {
    content: 'Drag a file here or click to upload an image';
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
  }
`
const PreviewWrapper = styled.div`
  width: 456px;
  @media screen and (max-width: 768px) {
    width: 320px;
  }
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
`
const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`
