/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import { BrandingInner } from '../../branding'

export default function BrandingPane () {
  const { space } = useOutletContext()
  return (
    <Wrapper withinSpacesUI={!!space}>
      <BrandingInner />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: ${p =>
    p.withinSpacesUI ? 'calc(100% - 34px)' : 'calc(100vh - 80px)'};
  & > .branding {
    flex-direction: column;

    > :first-child {
      height: initial;
      width: 100%;
      flex-direction: row;
      padding: ${p => (p.withinSpacesUI ? '32px 48px 16px' : '16px 48px')};
      flex-wrap: wrap;

      > :first-child {
        padding: 20px 0 0;
      }

      > :nth-child(2) {
        display: none;
      }

      > :nth-child(3) {
        display: flex;
        padding: 0;
        flex: initial;

        > div {
          margin: 0 8px;

          label {
            color: var(--dark-gray-500);
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 4px;
          }

          input[type='text'] {
            min-width: 140px;
          }

          input[type='color'] {
            width: 70px;
          }

          input[type='number'] {
            width: 64px;
          }

          input[type='file'] {
            width: 90px;
            padding-top: 5px;
          }
        }
      }
    }
  }
`
