/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Loading from '../../../../components/loading'
import { GraphQLError as Error } from '../../../../components/system-error'
import { useAlerts } from '../../../../ui/alerts'
import Button from '../../../../ui/button'
import { Subtitle1 } from '../../../../ui/typography'
import { useActivateUserMutation } from './components/mutation.activate-user'
import { useApproveUserMutation } from './components/mutation.approve-user'
import { useDeactivateUserMutation } from './components/mutation.deactivate-user'
import { useImpersonateMutation } from './components/mutation.impersonate'
import { useResetUserSSOMutation } from './components/mutation.reset-user-sso'

export default function UserView () {
  const props = useOutletContext()
  const params = useParams()
  const id = props.id || params.id
  const { data, loading, error } = useQuery(userQuery, { variables: { id } })
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <UserViewInner
      id={id}
      user={data.user}
      isAccountPage={!!props.id}
      viewer={data.viewer}
      tenant={data.tenant}
      hasAffiliations={data.hasAffiliations}
    />
  )
}

const userQuery = gql`
  query User($id: ID!) {
    hasAffiliations
    viewer {
      id
      isKualiAdmin
      user {
        id
        impersonating
      }
    }
    tenant {
      id
      features {
        impersonation
      }
    }
    user(id: $id) {
      id
      ssoId
      name
      email
      username
      role
      firstName
      lastName
      active
      affiliations {
        orgDN: orgDn
        affiliation
      }
      approved
      schoolId
    }
  }
`

const fields = [
  { id: 'firstName', label: <Trans id='pages.identitiy.id.first.name' /> },
  { id: 'lastName', label: <Trans id='pages.identitiy.id.last.name' /> },
  { id: 'name', label: <Trans id='display.name' /> },
  { id: 'username', label: <Trans id='username' /> },
  { id: 'email', label: <Trans id='email' /> },
  { id: 'role', label: <Trans id='role' /> },
  { id: 'schoolId', label: <Trans id='school.id' /> },
  { id: 'affiliations', label: <Trans id='affiliations' /> },
  { id: 'approved', label: <Trans id='approved' /> }
]
function UserViewInner ({
  id,
  user,
  isAccountPage,
  viewer,
  tenant,
  hasAffiliations
}) {
  const alerts = useAlerts()
  const closeAlertsRef = React.useRef()
  const [activateUser, { loading: activateUserLoading }] =
    useActivateUserMutation(id)
  const [deactivateUser, { loading: deactivateUserLoading }] =
    useDeactivateUserMutation(id)
  const approveUser = useApproveUserMutation(id)
  const resetUserSSO = useResetUserSSOMutation(id)
  const impersonate = useImpersonateMutation(id)

  return (
    <Wrapper>
      {fields
        .filter(field => hasAffiliations || field.id !== 'affiliations')
        .map(field => (
          <Field
            key={field.id}
            mb={2}
            px={2}
            py={1}
            className='bg-white dark:bg-light-gray-300'
          >
            <label>{field.label}</label>
            {field.id === 'affiliations' ? (
              <ul>
                {user[field.id].map(a => (
                  <li key={a.affiliation}>
                    {a.affiliation}
                    {a.orgDN ? ` (${a.orgDN})` : ''}
                  </li>
                ))}
              </ul>
            ) : (
              <span>{user[field.id] != null && `${user[field.id]}`}</span>
            )}
          </Field>
        ))}
      <WeirdFlexButOk>
        {!isAccountPage && (
          <>
            {user.active ? (
              <Button
                disabled={deactivateUserLoading}
                transparent
                onClick={() => {
                  closeAlertsRef.current?.()
                  closeAlertsRef.current = alerts.type1(
                    i18n._('deactivate.question'),
                    i18n._('deactivate.question.data'),
                    'error',
                    close => (
                      <Button
                        className='!bg-red-400'
                        ml={2}
                        onClick={() => {
                          close()
                          deactivateUser()
                            .then(() => {
                              alerts.type3(
                                i18n._('user.deactivated'),
                                'success'
                              )
                            })
                            .catch(err => {
                              Sentry.captureException(err)
                              alerts.type3(err.message, 'error')
                            })
                        }}
                      >
                        <Trans id='deactivate' />
                      </Button>
                    )
                  )
                }}
              >
                <Trans id='deactivate' />
              </Button>
            ) : (
              <Button
                disabled={activateUserLoading}
                transparent
                onClick={() =>
                  activateUser()
                    .then(() =>
                      alerts.type3(i18n._('user.activated'), 'success')
                    )
                    .catch(err => {
                      Sentry.captureException(err)
                      alerts.type3(i18n._('failed.activate'), 'error')
                    })
                }
              >
                <Trans id='activate' />
              </Button>
            )}
            {user.ssoId && user.active && viewer.isKualiAdmin && (
              <Button transparent onClick={resetUserSSO}>
                <Trans id='reset.sso' />
              </Button>
            )}
            {!user.approved && (
              <Button transparent onClick={approveUser}>
                <Trans id='approve' />
              </Button>
            )}
            {viewer.user.id !== id &&
              user.approved &&
              !viewer.user.impersonating &&
              tenant.features.impersonation && (
                <Button
                  transparent
                  onClick={() =>
                    impersonate().then(() => (window.location = '/'))
                  }
                >
                  <Trans id='impersonate' />
                </Button>
              )}
          </>
        )}
        <Button as={Link} to='../edit'>
          <Trans id='edit' />
        </Button>
      </WeirdFlexButOk>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 48px;
  max-width: 500px;
  margin: 0 auto;
`

const Field = styled(Subtitle1)`
  display: flex;

  label {
    font-weight: bold;
    width: 120px;
    display: inline-block;
  }

  ul {
    margin: 0;
    padding-left: 16px;
  }
`

const WeirdFlexButOk = styled.div`
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 16px;
  }
`
