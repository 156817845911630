/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'
import { flex, space, width } from 'styled-system'

const Textarea = styled.textarea`
  resize: none;
  height: 90px;
  padding: 8px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 2px;
  box-sizing: border-box;
  ${flex}
  ${space}
  ${width}
`

export default React.forwardRef(({ onChange, onEnter, ...props }, ref) => (
  <Textarea
    className='border border-[#bebebe] bg-white dark:border-light-gray-500 dark:bg-light-gray-300'
    ref={ref}
    onKeyDown={onEnter ? e => e.keyCode === 13 && onEnter(e) : undefined}
    onChange={onChange ? e => onChange(e.target.value, e) : undefined}
    {...props}
  />
))
