/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateSuiteMutation = id => {
  const [updateSuite] = useMutation(updateSuiteMutation)
  return args => updateSuite(getParams(id, args))
}

const updateSuiteMutation = gql`
  mutation UpdateSpace(
    $id: ID!
    $name: String
    $slug: String
    $icon: String
    $color: String
    $invertColor: Boolean
  ) {
    data: updateSuite(
      id: $id
      args: {
        name: $name
        slug: $slug
        icon: $icon
        color: $color
        invertColor: $invertColor
      }
    ) {
      id
      suite {
        id
        name
        slug
        icon
        color
        invertColor
      }
    }
  }
`

const getParams = (id, { name, slug, icon, color, invertColor }) => ({
  variables: { id, name, slug, icon, color, invertColor }
})
