/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  format,
  formatDistance,
  formatDuration,
  formatISODuration
} from 'date-fns'
import React from 'react'
import styled from 'styled-components'

import { getFormatLocale } from '../../../i18n'

function shortDuration (value) {
  if (!value) return ''
  return formatDistance(0, value, { locale: getFormatLocale(i18n.locale) })
}

function longDuration (value) {
  if (!value) return ''
  const minutes = Math.round(value / 1000 / 60)
  return formatDuration({ minutes }, { locale: getFormatLocale(i18n.locale) })
}

const InProgress = styled.span`
  font-style: italic;
`

export function Duration ({ milliseconds, startDate }) {
  const shortValue = React.useMemo(
    () => shortDuration(milliseconds),
    [milliseconds]
  )
  const isoString = React.useMemo(
    () => formatISODuration({ seconds: milliseconds / 1000 }),
    [milliseconds]
  )
  const title = React.useMemo(() => {
    if (!startDate) return longDuration(milliseconds)
    return format(startDate, 'MMM d, yyyy, h:mm a')
  }, [milliseconds, startDate])
  return (
    <time dateTime={isoString} title={title}>
      {shortValue}
    </time>
  )
}

export default function DurationView ({ value }) {
  if (!value) {
    return (
      <InProgress>
        <Trans id='in.progress' />
      </InProgress>
    )
  }
  return <Duration milliseconds={value} />
}
