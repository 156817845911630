/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import Edit from './edit'

export default {
  defaultValue: [],
  Edit,
  meta: {
    hidden: true,
    category: 'Special',
    Icon: null,
    label: 'Table'
  },
  subFields: null,
  sampleValue: [],
  progressiveDisclosure: null,
  validateShape: v => v.any(),
  View: /* istanbul ignore next */ () => null
}
