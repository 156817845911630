/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateDocumentMutation = () => {
  const [updateDocument] = useMutation(mutation)
  return (id, data) => updateDocument(getParams(id, data))
}

const mutation = gql`
  mutation UpdateDocument($id: ID!, $data: JSON!) {
    updateDocument(args: { id: $id, data: $data }) {
      id
      data
    }
  }
`

const getParams = (id, data) => ({
  variables: {
    id,
    data
  }
})
