/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  every,
  find,
  get,
  isEmpty,
  map,
  partition,
  set,
  startsWith
} from 'lodash'
import React from 'react'

import * as Icons from '../../icons'

function isValid (value) {
  if (!value) return true
  if (!value.type || !value.parts) return false
  return every(
    value.parts,
    part => part.formKey && part.data && !isEmpty(part.data)
  )
}

export default class Safeguard extends React.Component {
  state = { value: this.props.value }

  handleChange = (path, value) => {
    const newValue = path ? set(this.state.value, path, value) : value
    this.setState({ value: newValue })
    if (isValid(newValue)) this.props.onChange(newValue)
  }

  render () {
    return (
      <ConditionalVisibilityConfig
        {...this.props}
        onChange={this.handleChange}
        value={this.state.value}
      />
    )
  }
}

class ConditionalVisibilityConfig extends React.Component {
  handleRemovePart = i => {
    const { value, onChange } = this.props
    const type = get(value, 'type') || 'any'
    let parts = get(value, 'parts') || []
    parts = [...parts]
    parts.splice(i, 1)
    let newVal = { type, parts }
    if (!parts.length) newVal = null
    onChange('', newVal)
  }

  handleAddPart = () => {
    const { value, onChange } = this.props
    const type = get(value, 'type') || 'any'
    const parts = get(value, 'parts') || []
    const newVal = {
      type,
      parts: [...parts, { formKey: '', data: {} }]
    }
    onChange('', newVal)
  }

  render () {
    const { gadgets, onChange, value } = this.props
    const parts = get(value, 'parts') || []
    return (
      <div>
        <div>
          {parts.length >= 2 && (
            <div className='flex items-center'>
              <select
                className='kp-select w-16'
                onChange={e => onChange('type', e.target.value)}
                value={get(value, 'type')}
              >
                <option value='any'>
                  <Trans id='pagesbuilder.form.visibility.any' />
                </option>
                <option value='all'>
                  <Trans id='pagesbuilder.form.visibility.all' />
                </option>
              </select>
              <div className='flex-1 pl-2'>
                <Trans id='pagesbuilder.form.visibility.true' />
              </div>
            </div>
          )}
          {map(parts, ({ formKey }, i) => {
            const Chosen = find(gadgets, { formKey })
            const prefix = `parts.${i}`
            const [metaGadgets, dataGadgets] = partition(gadgets, gadget =>
              startsWith(gadget.formKey, 'meta.')
            )
            return (
              <div
                key={i}
                role='row'
                className='my-2 space-y-2 rounded bg-[#0000000d] p-2'
              >
                <div className='flex'>
                  <select
                    className='kp-select flex-1'
                    aria-label={i18n._(
                      'pagesbuilder.form.visibility.conditional'
                    )}
                    autoFocus
                    onChange={e =>
                      onChange(`${prefix}.formKey`, e.target.value)
                    }
                    value={get(value, `${prefix}.formKey`)}
                  >
                    <option value=''>- - -</option>
                    {dataGadgets?.length && (
                      <optgroup label='Form Fields'>
                        {map(dataGadgets, ({ label, formKey }) => (
                          <option key={formKey} value={formKey}>
                            {label} ({formKey})
                          </option>
                        ))}
                      </optgroup>
                    )}
                    {metaGadgets?.length && (
                      <optgroup label='Metadata'>
                        {map(metaGadgets, ({ label, formKey }) => (
                          <option key={formKey} value={formKey}>
                            {label} ({formKey})
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </select>
                  <button
                    className='kp-button-transparent kp-button-icon text-medium-gray-500'
                    aria-label={i18n._(
                      'pagesbuilder.form.visibility.remove.conditional'
                    )}
                    onClick={() => this.handleRemovePart(i)}
                  >
                    <Icons.Delete />
                  </button>
                </div>
                {Chosen && (
                  <Chosen.component
                    gadgets={gadgets}
                    value={get(value, `${prefix}.data`, {})}
                    onChange={val => onChange(`${prefix}.data`, val)}
                    details={Chosen.details || {}}
                  />
                )}
              </div>
            )
          })}
        </div>
        <button className='kp-button-transparent' onClick={this.handleAddPart}>
          <Icons.Add className='mr-2 h-3 w-3 fill-blue-500' />
          <Trans id='pagesbuilder.form.visibility.add' />
        </button>
      </div>
    )
  }
}
