/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import FocusTrap from 'focus-trap-react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../ui/button'
import Popover from '../../ui/popover'
import { useEscapePress } from '../use-escape-click'
import { useOutsideClick } from '../window-click'

const Container = styled.div`
  position: relative;
`

export default ({
  label,
  children,
  buttonProps = { outline: true },
  ...rest
}) => {
  const ref = React.useRef()
  const [showingModal, setShowingModal] = React.useState(false)
  const hide = React.useCallback(() => setShowingModal(false), [])
  const toggle = React.useCallback(() => setShowingModal(a => !a), [])
  useOutsideClick(ref, hide, showingModal)
  useEscapePress(hide, showingModal)
  buttonProps.imActive = buttonProps.activeFilter || showingModal
  return (
    <Container ref={ref}>
      <Button {...buttonProps} onClick={toggle} aria-haspopup='dialog'>
        {label}
      </Button>
      {showingModal && (
        <Popover top={29} {...rest} role='dialog' mobile>
          <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
            <div>{children(hide)}</div>
          </FocusTrap>
        </Popover>
      )}
    </Container>
  )
}
