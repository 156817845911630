/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

export default function TextareaView ({ value, gridded, condensed }) {
  if (condensed) return <Condensed>{value}</Condensed>
  if (!gridded) return <Wrapper>{value}</Wrapper>
  return <GriddedWrapper>{value}</GriddedWrapper>
}

const Condensed = styled.div`
  padding: 0 8px;
`

const Wrapper = styled.div`
  white-space: pre-wrap;
`

const GriddedWrapper = styled(Wrapper)`
  padding: 0 16px 16px 16px;
`
