/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import ReactDOM from 'react-dom'

const Wheatley = React.createContext({})

export function GLaDOS ({ children }) {
  const [companionCube, setCompanionCube] = React.useState(null)
  return (
    <Wheatley.Provider value={{ companionCube, setCompanionCube }}>
      {children}
    </Wheatley.Provider>
  )
}

export function PortalBlue ({ className }) {
  const { setCompanionCube } = React.useContext(Wheatley)
  return <div ref={setCompanionCube} className={`max-lg:z-10 ${className}`} />
}

export function PortalOrange ({ children }) {
  const { companionCube } = React.useContext(Wheatley)
  return companionCube ? ReactDOM.createPortal(children, companionCube) : null
}
