/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export default function useActiveTab () {
  const [active, setActive] = React.useState(!document.hidden)
  React.useEffect(() => {
    function visibilityChange () {
      setActive(!document.hidden)
    }
    document.addEventListener('visibilitychange', visibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', visibilityChange)
    }
  }, [])
  return active
}
