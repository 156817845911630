/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, fromPairs, map, toPairs } from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Highlight from '../../components/highlight'
import { ModalPage } from '../../components/modal-page'
import { traverseTemplate } from '../../formbot/engine/formbot/utils'
import * as Icons from '../../icons'
import * as CustomTitle from '../components/custom-title'
import * as IntegrationPicker from '../components/integration-picker'
import {
  InfoPage,
  InfoTitle,
  SimulateWrapper
} from '../components/simulator-parts'
import SimulationContext from '../components/simulator-parts/context'
import SimulationError from '../components/simulator-parts/error-page'
import SimulationFormEditor from '../components/simulator-parts/form-editor'
import { ReactComponent as IntegrationIcon } from '../components/simulator-parts/integration-icon.svg.jsx'

const Node = ({ details, errors }) => (
  <IntegrationPicker.View details={details} errors={errors} />
)

const Config = ({ value, updateValue, fieldsAll, errors }) => (
  <>
    <CustomTitle.Config
      value={value}
      updateValue={updateValue}
      defaultName='Integration'
      label={i18n._('step.label')}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
    <IntegrationPicker.Config
      value={value}
      updateValue={updateValue}
      errors={errors}
      fieldsAll={fieldsAll}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
  </>
)

const Simulate = ({
  form,
  headless,
  simulateAction,
  simulationState,
  value,
  workflow
}) => {
  const { updateIntegrationData } = React.useContext(SimulationContext)
  const [showJSON, setShowJSON] = React.useState(false)
  const { status } = value
  const transformedData = React.useMemo(() => {
    return transformKeysOut(value.valueToSend, form.template)
  }, [form.template, value.valueToSend])
  if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  }
  return (
    <SimulateWrapper
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      {value.configureOutputs && (
        <div className='h-full min-h-[340px] overflow-auto bg-white p-8'>
          <SimulationFormEditor
            doc={value.integrationData?.[value.stepDefinitionId]}
            handleChange={(key, val) =>
              updateIntegrationData(
                `${value.stepDefinitionId}.data.${key}`,
                val
              )
            }
            header={
              <div className='flex flex-col items-center justify-center pb-2'>
                <div className='flex h-16 w-16 items-center justify-center rounded-full bg-magma-300'>
                  <IntegrationIcon />
                </div>
                <InfoTitle>{value.stepName}</InfoTitle>
                <div className='text-center text-base' htmlFor='output'>
                  <Trans id='integration.configured.return.values.displayed' />
                </div>
              </div>
            }
            labelSize={form.labelSize}
            metaFields={[]}
            template={buildTemplate(
              find(workflow.steps, { _id: value.stepDefinitionId })
            )}
            validations={[]}
          />
        </div>
      )}
      {!value.configureOutputs && (
        <InfoPage justifyContent='center' flexDirection='column'>
          <div className='flex h-16 w-16 items-center justify-center rounded-full bg-magma-300'>
            <IntegrationIcon />
          </div>
          <InfoTitle>{value.stepName}</InfoTitle>
          <div className='text-center text-base'>
            <Trans id='at.step.documbent.sent.for' />
          </div>
          <div className='text-center text-base'>{value.url}</div>
          <button
            className='m-5 text-base text-blue-500 underline'
            onClick={() => setShowJSON(true)}
          >
            <Trans id='view.json.advanced' />
          </button>
        </InfoPage>
      )}
      <TransitionGroup>
        <CSSTransition key={showJSON} timeout={450}>
          {showJSON ? (
            <ModalPage
              title={i18n._('json.preview')}
              onClose={() => setShowJSON(false)}
            >
              <div className='m-auto max-w-[1000px] px-20 py-8'>
                <Highlight language='json'>
                  {JSON.stringify(transformedData, null, 2)}
                </Highlight>
              </div>
            </ModalPage>
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
    </SimulateWrapper>
  )
}

function buildTemplate (stepDefinition) {
  if (!stepDefinition || !stepDefinition.integration?.outputFields) {
    return {}
  } else {
    return {
      id: 'temp',
      type: 'Column',
      children: map(stepDefinition.integration.outputFields, (value, key) => {
        return {
          details: value.details,
          id: key,
          formKey: value.path.replace('data.', ''),
          label: value.label,
          type: value.type
        }
      })
    }
  }
}

export default {
  name: 'Integration',
  Icon: Icons.Actions,
  color: '#C83397',
  validate: IntegrationPicker.validate,
  defaultTemplate: () => ({
    ...IntegrationPicker.defaults()
  }),
  Config,
  Node,
  Simulate
}

function generateKeysOut (template) {
  const keys = {}
  traverseTemplate(template, gadget => {
    if (!gadget.formKey) return
    const toKey =
      (gadget?.customFormKey?.enabled && gadget.customFormKey.value) ||
      gadget.formKey
    keys[gadget.formKey] = { to: toKey }
    if (gadget?.childrenTemplate) {
      keys[gadget.formKey].children = generateKeysOut({
        children: gadget.childrenTemplate
      })
    }
  })
  return keys
}

function transformKeysOut (data, template) {
  return transformKeys(data, generateKeysOut(template))
}

function transformKeys (data, keys) {
  return fromPairs(
    toPairs(data).map(([key, value]) => {
      const keyMap = keys[key]
      if (!keyMap) return [key, value]
      return [
        keyMap.to,
        !keyMap.children
          ? value
          : value?.data
            ? {
                // This is the structure for the Repeater gadget
                // (and eventually Table)
                ...value,
                data: map(value.data, item => ({
                  ...item,
                  data: transformKeys(item.data, keyMap.children)
                }))
              }
            : map(value, item => transformKeys(item, keyMap.children))
      ]
    })
  )
}
