/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function PageNumRedirect () {
  const { pageNum } = useParams()
  const navigate = useNavigate()
  React.useEffect(() => {
    navigate(`../?page=${pageNum}`, { relative: 'path', replace: true })
  }, [])
  return null
}
