/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM11.1653 9.776L8.49867 12.776C8.43611 12.8469 8.35917 12.9037 8.27296 12.9427C8.18675 12.9816 8.09325 13.0017 7.99867 13.0017C7.90409 13.0017 7.81058 12.9816 7.72438 12.9427C7.63817 12.9037 7.56123 12.8469 7.49867 12.776L4.832 9.776C4.74652 9.67974 4.69074 9.56079 4.67139 9.43352C4.65204 9.30624 4.66996 9.17609 4.72298 9.05878C4.77599 8.94147 4.86184 8.84201 4.97015 8.77243C5.07846 8.70285 5.2046 8.66611 5.33334 8.66667H6.83334C6.87754 8.66667 6.91993 8.64911 6.95119 8.61785C6.98244 8.58659 7 8.5442 7 8.5V3.66667C7 3.40145 7.10536 3.1471 7.2929 2.95956C7.48043 2.77202 7.73479 2.66667 8 2.66667C8.26522 2.66667 8.51957 2.77202 8.70711 2.95956C8.89465 3.1471 9 3.40145 9 3.66667V8.5C9 8.5442 9.01756 8.58659 9.04882 8.61785C9.08007 8.64911 9.12247 8.66667 9.16667 8.66667H10.6667C10.7952 8.66663 10.921 8.70374 11.0289 8.77352C11.1368 8.8433 11.2222 8.94279 11.2749 9.06C11.3276 9.17721 11.3453 9.30715 11.3258 9.43418C11.3064 9.56121 11.2507 9.67991 11.1653 9.776Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM11.1653 9.776L8.49867 12.776C8.43611 12.8469 8.35917 12.9037 8.27296 12.9427C8.18675 12.9816 8.09325 13.0017 7.99867 13.0017C7.90409 13.0017 7.81058 12.9816 7.72438 12.9427C7.63817 12.9037 7.56123 12.8469 7.49867 12.776L4.832 9.776C4.74652 9.67974 4.69074 9.56079 4.67139 9.43352C4.65204 9.30624 4.66996 9.17609 4.72298 9.05878C4.77599 8.94147 4.86184 8.84201 4.97015 8.77243C5.07846 8.70285 5.2046 8.66611 5.33334 8.66667H6.83334C6.87754 8.66667 6.91993 8.64911 6.95119 8.61785C6.98244 8.58659 7 8.5442 7 8.5V3.66667C7 3.40145 7.10536 3.1471 7.2929 2.95956C7.48043 2.77202 7.73479 2.66667 8 2.66667C8.26522 2.66667 8.51957 2.77202 8.70711 2.95956C8.89465 3.1471 9 3.40145 9 3.66667V8.5C9 8.5442 9.01756 8.58659 9.04882 8.61785C9.08007 8.64911 9.12247 8.66667 9.16667 8.66667H10.6667C10.7952 8.66663 10.921 8.70374 11.0289 8.77352C11.1368 8.8433 11.2222 8.94279 11.2749 9.06C11.3276 9.17721 11.3453 9.30715 11.3258 9.43418C11.3064 9.56121 11.2507 9.67991 11.1653 9.776Z\" />\n</svg>\n";
export default src;
