/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import acknowledge from './acknowledge'
import approval from './approval'
import conditional from './conditional'
import echo from './echo'
import formfill from './formfill'
import integration from './integration'
import notification from './notification'
import task from './task'

export default {
  formfill,
  approval,
  task,
  acknowledge,
  notification,
  conditional,
  integration,
  echo
}
