/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useSubmitDocumentMutation = () => {
  const [submitDocument] = useMutation(mutation)
  return (appId, pageId, data, tz) =>
    submitDocument(getParams(appId, pageId, data, tz))
}

const mutation = gql`
  mutation submitDocument(
    $appId: ID!
    $pageId: ID
    $data: JSON!
    $timeZone: String
  ) {
    submitDocument(
      args: { appId: $appId, pageId: $pageId, data: $data, timeZone: $timeZone }
    )
  }
`

const getParams = (appId, pageId, data, timeZone) => ({
  variables: { appId, pageId, data, timeZone }
})
