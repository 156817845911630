/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

import * as VoronoiDND from '../voronoi-dnd'
import { getAriaLabel } from './utils'

export default props => {
  if (props.children && props.children.length) return {}

  // The 'inner-' part lets the drop handler recognize that it's
  // inside the section.
  const innerId = `inner-${props.template.id}`

  // If the section is inside a repeater, we want to use the full
  // composite id so it creates a unique Voronoi cell.
  const id = props.template.fullId
    ? props.template.fullId.replace(props.template.id, innerId)
    : innerId
  return {
    gadget: () => (
      <div
        className={cx('border border-light-gray-300 p-8', {
          'border-l-0 border-t-0': !props.template.hideLabel
        })}
      >
        <VoronoiDND.Item
          id={id}
          ariaLabel={getAriaLabel(props.template)}
          inside
        >
          <div className='flex h-[300px] items-center justify-center border border-dashed border-light-gray-400 bg-light-gray-100 text-center after:text-base after:text-[#767676] after:opacity-50 after:content-["Drag_Fields_Here"] dark:border-light-gray-300 dark:bg-light-gray-300 dark:after:text-black' />
        </VoronoiDND.Item>
      </div>
    )
  }
}
