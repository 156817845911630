/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M12.8 9.60001C11.9337 9.60065 11.1049 9.95423 10.505 10.5792L6.98945 8.82113C7.01958 8.6557 7.03649 8.48813 7.04001 8.32001C7.03652 8.15189 7.01962 7.98432 6.98945 7.81889L10.505 6.06081C11.571 7.15636 13.2638 7.34306 14.543 6.50617C15.8222 5.66927 16.3292 4.04339 15.7523 2.62778C15.1755 1.21216 13.6765 0.40367 12.1767 0.699186C10.6769 0.994703 9.59674 2.31139 9.60001 3.84001C9.6035 4.00834 9.62041 4.17613 9.65057 4.34177L6.13505 6.09857C5.22932 5.1592 3.84392 4.86388 2.63385 5.35224C1.42377 5.8406 0.631439 7.01479 0.631439 8.31969C0.631439 9.6246 1.42377 10.7988 2.63385 11.2871C3.84392 11.7755 5.22932 11.4802 6.13505 10.5408L9.65057 12.2983C9.62037 12.4639 9.60346 12.6317 9.60001 12.8C9.60001 14.5673 11.0327 16 12.8 16C14.5673 16 16 14.5673 16 12.8C16 11.0327 14.5673 9.60001 12.8 9.60001Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M12.8 9.60001C11.9337 9.60065 11.1049 9.95423 10.505 10.5792L6.98945 8.82113C7.01958 8.6557 7.03649 8.48813 7.04001 8.32001C7.03652 8.15189 7.01962 7.98432 6.98945 7.81889L10.505 6.06081C11.571 7.15636 13.2638 7.34306 14.543 6.50617C15.8222 5.66927 16.3292 4.04339 15.7523 2.62778C15.1755 1.21216 13.6765 0.40367 12.1767 0.699186C10.6769 0.994703 9.59674 2.31139 9.60001 3.84001C9.6035 4.00834 9.62041 4.17613 9.65057 4.34177L6.13505 6.09857C5.22932 5.1592 3.84392 4.86388 2.63385 5.35224C1.42377 5.8406 0.631439 7.01479 0.631439 8.31969C0.631439 9.6246 1.42377 10.7988 2.63385 11.2871C3.84392 11.7755 5.22932 11.4802 6.13505 10.5408L9.65057 12.2983C9.62037 12.4639 9.60346 12.6317 9.60001 12.8C9.60001 14.5673 11.0327 16 12.8 16C14.5673 16 16 14.5673 16 12.8C16 11.0327 14.5673 9.60001 12.8 9.60001Z\" />\n</svg>\n";
export default src;
