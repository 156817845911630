/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import View from './view'

export default {
  View,
  Edit,
  progressiveDisclosure,
  validateShape: v =>
    v.shape({
      actionId: v.string(),
      signatureType: v.string(),
      userId: v.string(),
      displayName: v.string(),
      signedName: v.string(),
      date: v.string(),
      retrievalId: v.string(),
      filename: v.string(),
      contentType: v.string(),
      filesize: v.number()
    }),

  defaultValue: null,

  sampleValue: {
    actionId: '﻿196cd5184a80000001039179',
    signatureType: 'type|draw|upload',
    userId: '﻿596cd5184a80000001039179',
    displayName: 'John Coctosten',
    signedName: 'John Coctosten',
    date: '﻿2020-10-15 15:45:39.413Z',
    retrievalId: '7daa9459-81df-4358-9031-150afe89f44d',
    filename: 'foo.txt',
    contentType: 'text/plain',
    filesize: 500
  },

  meta: {
    label: 'Signature',
    Icon,
    initialTemplate: () => ({ required: true }),
    category: 'Special'
  }
}
