/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// This is meant to be temporary until we get a proper KualiUI implementation of
// the new button
import styled, { css } from 'styled-components'

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  html.dark {
    background-color: #555;
  }
`

export const TableHeader = styled.thead``

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
  ${TableHeader} & {
    height: 36px;
    background: #eee;
    html.dark & {
      // Outlier: dark:bg-light-gray-300
      background: #333;
    }
  }

  ${TableBody} & {
    border-bottom: 1px solid #efefef;
    html.dark & {
      // Outlier: dark:border-light-gray-300
      border-bottom: 1px solid #333;
    }
  }

  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }

  ${TableBody} &:hover {
    background: #fafafa;
    html.dark & {
      // Outlier: dark:hover:bg-light-gray-300
      background: #333;
    }
  }
`
const cellStyles = css`
  color: #1a1a1a;
  html.dark & {
    // Outlier: dark:text-black
    color: #ffffff;
  }
  font-family: 'Roboto';
  font-size: 14px;
  padding: 0 16px;
  vertical-align: middle;
`

export const TableHeaderCell = styled.th`
  ${cellStyles}
  position: relative;
  font-weight: 500;
  text-align: left;
`

export const TableCell = styled.td`
  ${cellStyles}
  font-weight: 400;
`
