/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useRemoveIdentityMutation = (roleId, identities) => {
  const [removeIdentity] = useMutation(mutation)
  return identityId => removeIdentity(getParams(roleId, identityId, identities))
}

const mutation = gql`
  mutation RemoveIdentityFromRole($roleId: String!, $identityId: String!) {
    removeIdentityFromRoleV2(
      args: { roleId: $roleId, identityId: $identityId }
    ) {
      result {
        id
        identities {
          id
          type
          label
        }
      }
      query {
        viewer {
          id
          spaces(type: ADMIN_ROOT) {
            id
            name
          }
        }
      }
    }
  }
`

const getParams = (roleId, identityId, identities) => ({
  variables: { roleId, identityId },
  optimisticResponse: {
    removeIdentityFromRoleV2: {
      query: null,
      result: {
        __typename: 'PolicyGroup',
        id: roleId,
        identities: identities
          .filter(({ id }) => id !== identityId)
          .map(identity => ({
            ...identity,
            __typename: 'PolicyGroupIdentity'
          }))
      }
    }
  }
})
