/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13 6.33333H12.5V4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19349 0 8.00002 0C6.80655 0 5.66195 0.474106 4.81804 1.31802C3.97413 2.16193 3.50002 3.30653 3.50002 4.5V6.33333H3.00002C2.6464 6.33333 2.30726 6.47381 2.05721 6.72386C1.80716 6.97391 1.66669 7.31305 1.66669 7.66667V14.6667C1.66669 15.0203 1.80716 15.3594 2.05721 15.6095C2.30726 15.8595 2.6464 16 3.00002 16H13C13.3536 16 13.6928 15.8595 13.9428 15.6095C14.1929 15.3594 14.3334 15.0203 14.3334 14.6667V7.66667C14.3334 7.31305 14.1929 6.97391 13.9428 6.72386C13.6928 6.47381 13.3536 6.33333 13 6.33333ZM6.66669 10.3333C6.66563 10.099 6.72633 9.86859 6.84268 9.66521C6.95903 9.46184 7.12691 9.29272 7.32943 9.17488C7.53194 9.05704 7.76193 8.99464 7.99623 8.99398C8.23054 8.99332 8.46088 9.05441 8.66405 9.1711C8.86723 9.28779 9.03607 9.45596 9.15357 9.65868C9.27107 9.86139 9.33307 10.0915 9.33334 10.3258C9.33361 10.5601 9.27212 10.7903 9.15509 10.9933C9.03805 11.1963 8.8696 11.3648 8.66669 11.482V13C8.66669 13.1768 8.59645 13.3464 8.47142 13.4714C8.3464 13.5964 8.17683 13.6667 8.00002 13.6667C7.82321 13.6667 7.65364 13.5964 7.52862 13.4714C7.40359 13.3464 7.33335 13.1768 7.33335 13V11.482C7.13127 11.3658 6.9633 11.1985 6.84629 10.9969C6.72928 10.7953 6.66734 10.5664 6.66669 10.3333ZM5.16669 4.5C5.16669 3.74855 5.4652 3.02788 5.99655 2.49653C6.5279 1.96518 7.24857 1.66667 8.00002 1.66667C8.75147 1.66667 9.47214 1.96518 10.0035 2.49653C10.5348 3.02788 10.8334 3.74855 10.8334 4.5V6C10.8334 6.08841 10.7982 6.17319 10.7357 6.2357C10.6732 6.29821 10.5884 6.33333 10.5 6.33333H5.50002C5.41161 6.33333 5.32683 6.29821 5.26432 6.2357C5.20181 6.17319 5.16669 6.08841 5.16669 6V4.5Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13 6.33333H12.5V4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19349 0 8.00002 0C6.80655 0 5.66195 0.474106 4.81804 1.31802C3.97413 2.16193 3.50002 3.30653 3.50002 4.5V6.33333H3.00002C2.6464 6.33333 2.30726 6.47381 2.05721 6.72386C1.80716 6.97391 1.66669 7.31305 1.66669 7.66667V14.6667C1.66669 15.0203 1.80716 15.3594 2.05721 15.6095C2.30726 15.8595 2.6464 16 3.00002 16H13C13.3536 16 13.6928 15.8595 13.9428 15.6095C14.1929 15.3594 14.3334 15.0203 14.3334 14.6667V7.66667C14.3334 7.31305 14.1929 6.97391 13.9428 6.72386C13.6928 6.47381 13.3536 6.33333 13 6.33333ZM6.66669 10.3333C6.66563 10.099 6.72633 9.86859 6.84268 9.66521C6.95903 9.46184 7.12691 9.29272 7.32943 9.17488C7.53194 9.05704 7.76193 8.99464 7.99623 8.99398C8.23054 8.99332 8.46088 9.05441 8.66405 9.1711C8.86723 9.28779 9.03607 9.45596 9.15357 9.65868C9.27107 9.86139 9.33307 10.0915 9.33334 10.3258C9.33361 10.5601 9.27212 10.7903 9.15509 10.9933C9.03805 11.1963 8.8696 11.3648 8.66669 11.482V13C8.66669 13.1768 8.59645 13.3464 8.47142 13.4714C8.3464 13.5964 8.17683 13.6667 8.00002 13.6667C7.82321 13.6667 7.65364 13.5964 7.52862 13.4714C7.40359 13.3464 7.33335 13.1768 7.33335 13V11.482C7.13127 11.3658 6.9633 11.1985 6.84629 10.9969C6.72928 10.7953 6.66734 10.5664 6.66669 10.3333ZM5.16669 4.5C5.16669 3.74855 5.4652 3.02788 5.99655 2.49653C6.5279 1.96518 7.24857 1.66667 8.00002 1.66667C8.75147 1.66667 9.47214 1.96518 10.0035 2.49653C10.5348 3.02788 10.8334 3.74855 10.8334 4.5V6C10.8334 6.08841 10.7982 6.17319 10.7357 6.2357C10.6732 6.29821 10.5884 6.33333 10.5 6.33333H5.50002C5.41161 6.33333 5.32683 6.29821 5.26432 6.2357C5.20181 6.17319 5.16669 6.08841 5.16669 6V4.5Z\" />\n</svg>\n";
export default src;
