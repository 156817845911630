/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateSpaceMutation = () => {
  const [updateSpace] = useMutation(updateSpaceMutation)
  return (id, args) => updateSpace(getParams(id, args))
}

const updateSpaceMutation = gql`
  mutation UpdateSpace(
    $id: ID!
    $name: String
    $allowChildBranding: Boolean
    $allowChildIntegrations: Boolean
  ) {
    data: updateSpace(
      id: $id
      args: {
        name: $name
        allowChildBranding: $allowChildBranding
        allowChildIntegrations: $allowChildIntegrations
      }
    ) {
      id
      name
      allowChildBranding
      allowChildIntegrations
    }
  }
`

const getParams = (
  id,
  { name, allowChildBranding, allowChildIntegrations }
) => ({
  variables: { id, name, allowChildBranding, allowChildIntegrations }
})
