/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useUpdateFormSettingsMutation = (appId, pageId) => {
  const [updateFormSettings] = useMutation(mutation)
  return app => updateFormSettings(getParams(appId, pageId, app))
}

const mutation = gql`
  mutation UpdateFormSettings(
    $appId: ID!
    $pageId: ID
    $autoStartNewForm: Boolean!
    $titleField: String
    $versionTitleField: String
    $labelSize: String
    $paginated: Boolean!
    $showNewFormButton: Boolean!
    $allowExport: Boolean!
    $removeIntegrationOnDuplicate: Boolean!
    $submissionsDisabled: Boolean!
    $formSchedule: FormScheduleInput
  ) {
    updateAppSettings(
      args: {
        id: $appId
        pageId: $pageId
        autoStartNewForm: $autoStartNewForm
        titleField: $titleField
        versionTitleField: $versionTitleField
        labelSize: $labelSize
        paginated: $paginated
        showNewFormButton: $showNewFormButton
        allowExport: $allowExport
        removeIntegrationOnDuplicate: $removeIntegrationOnDuplicate
        submissionsDisabled: $submissionsDisabled
        formSchedule: $formSchedule
      }
    ) {
      id
      dataset(id: $pageId) {
        id
        allowNewVersions
        autoStartNewForm
        titleField
        versionTitleField
        labelSize
        paginated
        showNewFormButton
        allowExport
        removeIntegrationOnDuplicate
        submissionsDisabled
        formSchedule {
          enabled
          startDate
          endDate
        }
      }
    }
  }
`

const getParams = (
  appId,
  pageId,
  {
    autoStartNewForm,
    titleField,
    versionTitleField,
    labelSize,
    paginated,
    showNewFormButton,
    allowExport,
    removeIntegrationOnDuplicate,
    submissionsDisabled,
    formSchedule
  }
) => {
  const variables = {
    autoStartNewForm,
    titleField,
    versionTitleField,
    labelSize,
    paginated,
    showNewFormButton,
    allowExport,
    removeIntegrationOnDuplicate,
    submissionsDisabled,
    formSchedule
  }
  return {
    variables: { appId, pageId, ...variables },
    optimisticResponse: {
      __typename: 'Mutation',
      updateAppSettings: {
        __typename: 'App',
        id: appId,
        dataset: { __typename: 'Dataset', id: pageId || appId, ...variables }
      }
    }
  }
}
