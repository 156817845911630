/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import React from 'react'

export const useSaveWorkflowSettingsMutation = (appId, pageId, workflow) => {
  const [saveWorkflowSettings] = useMutation(mutation)
  return React.useCallback(
    settingsUpdate =>
      saveWorkflowSettings(getParams(appId, pageId, settingsUpdate, workflow)),
    [saveWorkflowSettings, appId, pageId, workflow]
  )
}

const mutation = gql`
  mutation SaveWorkflowSettings(
    $appId: ID!
    $pageId: ID
    $settingsUpdates: WorkflowSettings!
  ) {
    updateAllWorkflowSettings(
      appId: $appId
      pageId: $pageId
      settingsUpdates: $settingsUpdates
    ) {
      query {
        app(id: $appId, isConfiguration: true) {
          id
          hasDraft
          dataset(id: $pageId) {
            id
            workflow
          }
        }
      }
    }
  }
`

const getParams = (appId, pageId, settingsUpdates, workflow) => ({
  variables: {
    appId,
    pageId,
    settingsUpdates
  },
  optimisticResponse: {
    updateAllWorkflowSettings: {
      __typename: 'UpdateWorkflowResponse',
      query: {
        __typename: 'Query',
        app: {
          __typename: 'App',
          id: appId,
          hasDraft: true,
          dataset: {
            __typename: 'Dataset',
            id: pageId || appId,
            workflow: {
              ...workflow,
              ...settingsUpdates
            }
          }
        }
      }
    }
  }
})
