/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.0194 14.7388H8.39026C7.61386 14.7388 7.12859 13.8983 7.51679 13.2259C7.69699 12.9138 8.02999 12.7216 8.39026 12.7216H11.0194C13.5325 12.6075 14.9797 9.81571 13.6242 7.69637C13.052 6.80151 12.0805 6.24064 11.0194 6.19244H4.96772C4.87485 6.19244 4.79959 6.26771 4.79959 6.36057V8.50557C4.80259 9.02317 4.24412 9.34997 3.79439 9.09377C3.74139 9.06357 3.69272 9.02631 3.64979 8.98297L0.354923 5.69491C0.229056 5.56797 0.158923 5.39617 0.159923 5.21744C0.160656 5.04104 0.230723 4.87204 0.354923 4.74677L3.64305 1.45871C3.76992 1.33284 3.94172 1.26264 4.12045 1.2637C4.20659 1.2561 4.29332 1.26524 4.37599 1.29057C4.64605 1.40057 4.81445 1.67217 4.79285 1.96297V4.04077C4.79285 4.13357 4.86812 4.20884 4.96099 4.20884H11.0127C15.0659 4.56137 17.2175 9.1695 14.8857 12.5034C13.9905 13.7832 12.5751 14.6015 11.0194 14.7388Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11.0194 14.7388H8.39026C7.61386 14.7388 7.12859 13.8983 7.51679 13.2259C7.69699 12.9138 8.02999 12.7216 8.39026 12.7216H11.0194C13.5325 12.6075 14.9797 9.81571 13.6242 7.69637C13.052 6.80151 12.0805 6.24064 11.0194 6.19244H4.96772C4.87485 6.19244 4.79959 6.26771 4.79959 6.36057V8.50557C4.80259 9.02317 4.24412 9.34997 3.79439 9.09377C3.74139 9.06357 3.69272 9.02631 3.64979 8.98297L0.354923 5.69491C0.229056 5.56797 0.158923 5.39617 0.159923 5.21744C0.160656 5.04104 0.230723 4.87204 0.354923 4.74677L3.64305 1.45871C3.76992 1.33284 3.94172 1.26264 4.12045 1.2637C4.20659 1.2561 4.29332 1.26524 4.37599 1.29057C4.64605 1.40057 4.81445 1.67217 4.79285 1.96297V4.04077C4.79285 4.13357 4.86812 4.20884 4.96099 4.20884H11.0127C15.0659 4.56137 17.2175 9.1695 14.8857 12.5034C13.9905 13.7832 12.5751 14.6015 11.0194 14.7388Z\" />\n</svg>\n";
export default src;
