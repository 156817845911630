/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import * as Chooser from '../../data-chooser'
import CategoryTypeahead from '../group-typeahead/category-typeahead'

export function RequiredConfig ({
  Gadgets,
  updateDataLookupSource,
  indexed,
  indexType,
  value
}) {
  return (
    <>
      <Gadgets.Padded>
        <Gadgets.Label>
          <Trans id='data.source.colon' />
        </Gadgets.Label>
        <Chooser.Multiselect
          id='groups'
          onSelect={updateDataLookupSource}
          indexed={indexed}
          indexType={indexType}
        />
      </Gadgets.Padded>
      <Gadgets.Custom configKey='categoryId' label={i18n._('category')}>
        {({ onChange, value }) => (
          <CategoryTypeahead
            id='categoryId'
            onChange={onChange}
            value={value}
          />
        )}
      </Gadgets.Custom>
    </>
  )
}

export function OptionalConfig ({ Gadgets }) {
  return (
    <Gadgets.ConfigBox
      configKey='placeholder.enabled'
      label={i18n._('show.placeholder.text')}
      description={i18n._('show.placeholder.text.data')}
    >
      <Gadgets.Text configKey='placeholder.value' testid='placeholder' />
    </Gadgets.ConfigBox>
  )
}
