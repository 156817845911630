/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { productBuilder } from '../../../components/feature-flags'
import Component from './component'
import { RequiredConfig } from './config'
import { ReactComponent as Icon } from './icon.svg.jsx'

export default {
  RequiredConfig,
  Edit: Component,
  View: Component,
  meta: {
    category: 'Smart',
    Icon,
    label: 'Iframe',
    hidden: !productBuilder
  }
}
