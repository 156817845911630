/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Colors, Controls, FlumeConfig } from 'flume'
import _ from 'lodash'
import React from 'react'

import FlumeLoop from './loop'
import PortList from './port-list'

// The code below this comment is copied to the server using a script. If you're
// updating this file you'll want to first review the caveats and then make sure
// to run that script afterwards: https://docs.kualibuild.ninja/scripts/flume

export const ports = {
  string: {
    name: 'string',
    label: 'Text',
    color: Colors.green,
    controls: [Controls.text({ name: 'string', label: 'Text' })],
    isEmpty: value => !value?.string,
    resolve: data => data?.string ?? ''
  },
  // secret: {
  //   name: 'secret',
  //   label: 'Secret',
  //   color: Colors.yellow,
  //   controls: [
  //     Controls.custom({
  //       name: 'secret',
  //       label: 'Secret',
  //       render: (data, onChange) => {
  //         return (
  //           <Input
  //             onMouseDown={e => e.stopPropagation()}
  //             type='password'
  //             value={data}
  //             onChange={onChange}
  //           />
  //         )
  //       }
  //     })
  //   ],
  //   isEmpty: value => !value?.secret,
  //   resolve: data => data?.secret
  // },
  boolean: {
    name: 'boolean',
    label: 'Boolean',
    color: Colors.blue,
    controls: [Controls.checkbox({ name: 'boolean', label: 'Boolean' })],
    resolve: data => data?.boolean ?? false
  },
  number: {
    name: 'number',
    label: 'Number',
    color: Colors.red,
    controls: [Controls.number({ name: 'number', label: 'Number' })],
    resolve: data => data?.number ?? 0
  },
  json: {
    name: 'json',
    label: 'JSON',
    color: Colors.orange,
    controls: [],
    resolve: () => null,
    isEmpty: () => true
  },
  method: {
    name: 'method',
    label: 'Method',
    color: Colors.pink,
    controls: [
      Controls.select({
        name: 'method',
        label: 'Method',
        options: [
          { value: 'get', label: 'GET' },
          { value: 'post', label: 'POST' },
          { value: 'put', label: 'PUT' },
          { value: 'delete', label: 'DELETE' }
        ]
      })
    ],
    resolve: data => data?.method,
    hideFromLegend: true
  },
  integrationType: {
    name: 'integrationType',
    label: 'Integration Type',
    color: Colors.pink,
    controls: [
      Controls.select({
        name: 'integrationType',
        label: 'Integration Type',
        options: [
          { value: 'fetchOne', label: 'Lookup (Single Select)' },
          { value: 'fetch', label: 'Lookup (List / Multiselect)' },
          { value: 'workflowIntegration', label: 'Workflow' }
        ]
      })
    ],
    resolve: data => data?.integrationType,
    hideFromLegend: true
  },
  returnType: {
    name: 'returnType',
    label: 'Return Type',
    color: Colors.pink,
    controls: [
      Controls.select({
        name: 'returnType',
        label: 'Return Type',
        options: [
          { value: 'string', label: 'Text' },
          { value: 'json', label: 'JSON' }
        ]
      })
    ],
    resolve: data => data?.returnType,
    hideFromLegend: true
  },
  flume: {
    name: 'flume',
    label: 'Flume',
    color: Colors.pink,
    controls: [
      Controls.custom({
        name: 'flume',
        label: 'Node Graph',
        defaultValue: { nodes: {}, comments: {} },
        render: (data, onChange, context, redraw, portProps, inputData) => {
          const { extras, simpleLoop } = JSON.parse(portProps.inputLabel)
          const gadgetNodeTypes = Object.keys(_.pickBy(nodes, 'gadget'))
          const nodeTypes = simpleLoop
            ? _.omit(config.nodeTypes, gadgetNodeTypes)
            : config.nodeTypes
          return (
            <FlumeLoop
              ports={ports}
              defaultNodes={[
                { type: 'loopInputs', x: -100, y: 0 },
                { type: 'loopOutputs', x: 100, y: 0 }
              ]}
              portTypes={config.portTypes}
              nodeTypes={nodeTypes}
              nodes={data.nodes}
              onChange={nodes => onChange({ ...data, nodes })}
              comments={data.comments}
              onCommentsChange={comments => onChange({ ...data, comments })}
              context={{ extras, simpleLoop: true, simpleOut: !!simpleLoop }}
            />
          )
        }
      })
    ],
    resolve: data => data?.flume,
    hideFromLegend: true
  },
  gadget: {
    name: 'gadget',
    label: 'Build Gadget',
    color: Colors.purple,
    controls: [],
    resolve: () => null,
    isEmpty: () => true
  },
  inputs: {
    name: 'inputs',
    label: 'Inputs',
    color: Colors.pink,
    controls: [
      Controls.custom({
        name: 'inputs',
        label: 'Inputs',
        defaultValue: [],
        render: (data, onChange) => (
          <PortList
            value={data}
            onChange={onChange}
            label='Label'
            ports={ports}
            filter={['string', 'number', 'json']}
            required
          />
        )
      })
    ],
    resolve: data => data?.inputs,
    hideFromLegend: true
  },
  portList: {
    name: 'portList',
    label: 'Port List',
    color: Colors.pink,
    controls: [
      Controls.custom({
        name: 'portList',
        label: 'Port List',
        defaultValue: [],
        render: (data, onChange) => (
          <PortList
            value={data}
            onChange={onChange}
            label='Key'
            ports={ports}
            filter={['string', 'boolean', 'number', 'json']}
          />
        )
      })
    ],
    resolve: data => data?.portList,
    hideFromLegend: true
  },
  any: {
    name: 'any',
    label: 'Any',
    color: Colors.grey,
    controls: [],
    resolve: () => null,
    isEmpty: () => true
  }
}
ports.any.acceptTypes = Object.keys(ports)

export const nodes = {
  // secret: {
  //   label: 'Secret',
  //   description: 'Saves the value as a secret value',
  //   inputs: {
  //     secret: { type: 'secret', label: 'Secret', hidePort: true }
  //   },
  //   outputs: {
  //     secret: { type: 'string', label: 'Secret' }
  //   },
  //   resolve: inputs => inputs?.secret
  // },
  inputs: {
    label: 'Inputs - From Form',
    addable: false,
    deletable: false,
    description: 'User-provided inputs',
    initialWidth: 240,
    inputs: {
      inputs: { type: 'inputs', hidePort: true }
    },
    outputs: {
      '': { fromList: ['inputs', 'inputs'] }
    },
    resolve: (inputs, context) => {
      const obj = { ...context.inputs }
      for (const input of inputs.inputs) {
        if (input.type === 'json') {
          const key = `${input.type}${input.key}`
          if (typeof context.inputs[key] === 'string') {
            obj[key] = JSON.parse(context.inputs[key])
          }
        }
      }
      return obj
    }
  },
  outputs: {
    label: 'Outputs - To Form/Workflow',
    addable: false,
    deletable: false,
    root: true,
    description: 'The outputs of your integration',
    inputs: {
      integrationType: { type: 'integrationType', hidePort: true },
      id: {
        type: 'string',
        label: 'ID',
        when: { 'integrationType.integrationType': ['fetchOne'] }
      },
      label: {
        type: 'string',
        label: 'Label',
        when: { 'integrationType.integrationType': ['fetchOne'] }
      },
      result: {
        type: 'json',
        label: 'Data\xa0Array',
        when: { 'integrationType.integrationType': ['fetch'] }
      },
      wfresult: {
        type: 'any',
        label: 'Result',
        when: { 'integrationType.integrationType': ['workflowIntegration'] }
      },
      gadget: {
        type: 'gadget',
        label: 'Gadget',
        array: true,
        when: {
          'integrationType.integrationType': [
            'fetchOne',
            'fetch',
            'workflowIntegration'
          ]
        }
      }
    },
    resolve: (inputs, context) => {
      switch (inputs.integrationType) {
        case 'fetchOne': {
          const gadgets = advancedPorts.array.resolve(inputs, 'gadget')
          const result = { id: inputs.id, label: inputs.label }
          let data = result
          if (!context.test) data = result.data = {}
          for (const gadget of gadgets) {
            data[gadget.id] = gadget.data
          }
          const output = _.map(gadgets, gadget => ({
            type: gadget.type,
            id: gadget.id,
            label: gadget.label
          }))
          return {
            type: inputs.integrationType,
            result,
            output
          }
        }
        case 'fetch':
          return {
            type: inputs.integrationType,
            result: inputs.result,
            output: advancedPorts.array.resolve(inputs, 'output')
          }
        case 'workflowIntegration': {
          const gadgets = advancedPorts.array.resolve(inputs, 'gadget')
          const data = {}
          for (const gadget of gadgets) {
            data[gadget.id] = gadget.data
          }
          return {
            type: inputs.integrationType,
            result: { result: inputs.wfresult, data }
          }
        }
        default:
          return { type: inputs.integrationType }
      }
    }
  },
  loop: {
    label: 'Loop',
    description: 'Iterate over a list of things and transform it',
    inputs: (inputs, connections, context) => {
      const allInputs = _.filter(Object.keys(connections?.inputs), v =>
        v.startsWith('extra')
      )
      allInputs.sort()
      const extras = _.map(allInputs, input => {
        const [{ nodeId, portName }] = connections.inputs[input]
        const node = context.nodes[nodeId]
        const outputs = _.isFunction(nodes[node.type].outputs)
          ? nodes[node.type].outputs(node.inputData, node.connections, context)
          : nodes[node.type].outputs
        const { type, label } = _.find(outputs, { name: portName })
        return { type, label }
      })
      return {
        data: { type: 'json' },
        graph: {
          type: 'flume',
          hidePort: true,
          label: JSON.stringify({ extras, simpleLoop: context.simpleLoop })
        },
        extra: { type: 'any', label: 'Extra', array: true }
      }
    },
    outputs: (inputs, connections, context) => ({
      result: { type: 'json' },
      ...(context.simpleLoop ? {} : { gadgets: { type: 'gadget' } })
    }),
    resolve: async (inputs, context, _id, evaluate) => {
      const extras = advancedPorts.array.resolve(inputs, 'extra')
      const result = []
      let gadgets = null
      const length = inputs.data?.length ?? 0
      for (let i = 0; i < length; ++i) {
        const newContext = {
          ...context,
          loopInput: inputs.data[i],
          i,
          extras,
          simpleLoop: true,
          simpleOut: !!context.simpleLoop
        }
        const [resp] = await evaluate(inputs.graph.nodes, newContext)
        const item = resp.result
        let data = item ?? {}
        if (!context.test) data = item.data = {}
        for (const gadget of resp.gadgets) {
          data[gadget.id] = gadget.data
        }
        result.push(item)
        if (!gadgets) {
          gadgets = _.map(resp.gadgets, gadget => ({
            type: gadget.type,
            id: gadget.id,
            label: gadget.label
          }))
        }
      }
      return { result, gadgets }
    }
  },
  loopInputs: {
    label: 'Inputs',
    addable: false,
    deletable: false,
    initialWidth: 130,
    outputs: (inputData, connections, context) => {
      const outputs = {
        item: { type: 'json', label: 'Item' },
        index: { type: 'number', label: 'Index' }
      }
      _.forEach(context.extras, ({ type, label }, i) => {
        outputs[`extra${i + 1}`] = { type, label }
      })
      return outputs
    },
    resolve: (_inputs, context) => {
      const output = {
        item: context.loopInput,
        index: context.i
      }
      _.forEach(context.extras, (v, i) => {
        output[`extra${i + 1}`] = v
      })
      return output
    }
  },
  loopOutputs: {
    label: 'Outputs',
    addable: false,
    deletable: false,
    root: true,
    initialWidth: 130,
    inputs: (inputs, connections, context) => ({
      result: { type: 'json', label: 'Result' },
      ...(context.simpleOut
        ? {}
        : { gadgets: { type: 'gadget', label: 'Gadget', array: true } })
    }),
    resolve: inputs => ({
      result: inputs.result,
      gadgets: advancedPorts.array.resolve(inputs, 'gadget')
    })
  },
  user: {
    label: 'User',
    description: 'Outputs attributes of the logged-in user',
    initialWidth: 130,
    outputs: {
      id: { type: 'string', label: 'ID' },
      ssoId: { type: 'string', label: 'SSO ID' },
      displayName: { type: 'string', label: 'Display Name' },
      email: { type: 'string', label: 'Email' },
      token: { type: 'string', label: 'Token' }
    },
    resolve: (_inputs, context) => context.user
  },
  host: {
    label: 'Host',
    description: 'Outputs the current host',
    initialWidth: 130,
    outputs: {
      host: { type: 'string', label: 'Host' }
    },
    resolve: (_inputs, context) => ({ host: context.host })
  },
  replaceText: {
    label: 'Replace Text',
    description: 'Replaces text',
    inputs: {
      subject: { type: 'string', label: 'Subject' },
      search: { type: 'string', label: 'Search' },
      regex: { type: 'boolean', label: 'Regular Expression?' },
      replace: { type: 'string', label: 'Replace' },
      global: { type: 'boolean', label: 'Global' }
    },
    outputs: {
      text: { type: 'string', label: 'Text' }
    },
    resolve: inputs => {
      let text = inputs.subject || ''
      if (inputs.regex) {
        const search = new RegExp(inputs.search, inputs.global ? 'g' : '')
        text = text.replace(search, inputs.replace)
      } else if (inputs.global) {
        // TODO:: This should be replaced by String.prototype.replaceAll once we are on a version of node that supports it
        // This short circuits after 100 replacements to avoid infinite loops.
        for (let i = 0; i < 100 && text.includes(inputs.search); ++i) {
          text = text.replace(inputs.search, inputs.replace)
        }
      } else {
        text = text.replace(inputs.search, inputs.replace)
      }
      return { text }
    }
  },
  parseNumber: {
    label: 'Parse Number',
    description: 'Parses a number or returns 0',
    inputs: {
      string: { type: 'string', label: 'String' }
    },
    outputs: {
      number: { type: 'number', label: 'Number' }
    },
    resolve: inputs => ({
      number: parseInt(inputs.string, 10) || 0
    })
  },
  updateParameter: {
    label: 'Update Object Parameter',
    description: 'Updates the value of a parameter in a json object',
    inputs: {
      object: { type: 'json', label: 'Object' },
      key: { type: 'string', label: 'Key' },
      value: { type: 'string', label: 'Value' }
    },
    outputs: {
      object: { type: 'json', label: 'Object' }
    },
    resolve: inputs => ({
      object: _.set(inputs.object, inputs.key, inputs.value)
    })
  },
  composeMessage: {
    label: 'Compose Message',
    description: 'Composes a parameterized string of text',
    initialWidth: 230,
    inputs: {
      template: {
        type: 'string',
        label: 'Template',
        hidePort: true,
        compose: 'data-'
      }
    },
    outputs: {
      message: { type: 'string', label: 'Message' }
    },
    resolve: inputs => {
      const interpolate = /{{([\s\S]+?)}}/g
      const { template, ...rest } = inputs
      const data = _.mapKeys(rest, (_, key) => key.replace('data-', ''))
      return { message: _.template(template, { interpolate })(data) }
    }
  },
  apiCall: {
    label: 'API Call',
    description: 'Calls an API and returns the result',
    inputs: {
      method: { type: 'method', label: 'Method', hidePort: true },
      url: { type: 'string', label: 'Url' },
      body: {
        type: 'string',
        label: 'Body',
        when: { 'method.method': ['put', 'post'] }
      },
      headers: {
        tupleArray: [
          { type: 'string', name: 'name', label: 'Header\xa0Name' },
          { type: 'string', name: 'value', label: 'Header\xa0Value' }
        ]
      },
      returnType: { type: 'returnType', label: 'Return Type', hidePort: true }
    },
    outputs: {
      status: { type: 'number', label: 'Status Code' },
      textBody: {
        type: 'string',
        label: 'Output',
        when: { 'returnType.returnType': ['string'] }
      },
      jsonBody: {
        type: 'json',
        label: 'Output',
        when: { 'returnType.returnType': ['json'] }
      }
    },
    resolve: async inputs => {
      const headers = advancedPorts.tupleArray
        .resolve(inputs, 'headers')
        .filter(header => header.name || header.value)
      const config = {
        method: inputs.method,
        headers: _.mapValues(_.keyBy(headers, 'name'), 'value')
      }
      if (['put', 'post'].includes(inputs.method)) config.body = inputs.body
      const res = await fetch(inputs.url, config)
      const result = { status: res.status }
      if (inputs.returnType === 'string') {
        result.textBody = await res.text()
      } else if (inputs.returnType === 'json') {
        result.jsonBody = await res.json()
      }
      return result
    }
  },
  rename: {
    label: 'Rename',
    description: 'Renames a key in an object',
    inputs: {
      object: { type: 'json', label: 'Object' },
      keys: {
        tupleArray: [
          { type: 'string', name: 'oldKey', label: 'Old\xa0Key' },
          { type: 'string', name: 'newKey', label: 'New\xa0Key' }
        ]
      }
    },
    outputs: {
      object: { type: 'json', label: 'Object' }
    },
    resolve: inputs => {
      const renames = advancedPorts.tupleArray
        .resolve(inputs, 'keys')
        .filter(tuple => tuple.oldKey && tuple.newKey)
      const object = _.cloneDeep(inputs.object)
      _.forEach(renames, ({ oldKey, newKey }) => {
        const value = _.get(object, oldKey)
        _.unset(object, oldKey)
        _.set(object, newKey, value)
      })
      return { object }
    }
  },
  pick: {
    label: 'Pick',
    description: 'Picks out specific fields from a JSON object',
    inputs: {
      object: { type: 'json', label: 'Object' },
      key: { type: 'string', label: 'Key', array: true }
    },
    outputs: {
      result: { type: 'json', label: 'Output' }
    },
    resolve: inputs => ({
      result: _.pick(inputs.object, advancedPorts.array.resolve(inputs, 'key'))
    })
  },
  splitObject: {
    label: 'Split Object',
    description: 'Splits an object into parts',
    inputs: {
      object: { type: 'json', label: 'Object' },
      config: { type: 'portList', label: 'Config', hidePort: true }
    },
    outputs: {
      'data-': { fromList: ['config', 'portList'] }
    },
    resolve: inputs => {
      return inputs.config.reduce((obj, { key, type, label }) => {
        if (label) obj[`data-${type}${key}`] = _.get(inputs.object, label)
        return obj
      }, {})
    }
  },
  createObject: {
    label: 'Create Object',
    description: 'Creates an object from parts',
    inputs: {
      config: { type: 'portList', label: 'Config', hidePort: true },
      'data-': { fromList: ['config', 'portList'] }
    },
    outputs: {
      object: { type: 'json', label: 'Object' }
    },
    resolve: inputs => ({
      object: inputs.config.reduce((obj, { key, type, label }) => {
        if (label) _.set(obj, label, inputs[`data-${type}${key}`])
        return obj
      }, {})
    })
  },
  stringifyJson: {
    label: 'Stringify JSON',
    description: 'Turns JSON into a string',
    inputs: {
      object: { type: 'json', label: 'Object' }
    },
    outputs: {
      out: { type: 'string', label: 'Text' }
    },
    resolve: inputs => ({
      out: JSON.stringify(inputs.object)
    })
  },
  randomString: {
    label: 'Random String',
    description: 'Creates a random string',
    inputs: {
      length: { type: 'number', label: 'Length' }
    },
    outputs: {
      out: { type: 'string', label: 'Text' }
    },
    resolve: inputs => ({
      out: crypto
        .randomBytes(inputs.length)
        .toString('base64')
        .substr(0, inputs.length)
    })
  },
  dateString: {
    label: 'Date String',
    description: 'Returns the date as a string',
    outputs: {
      date: { type: 'string', label: 'Date' }
    },
    resolve: () => ({
      date: new Date().toString()
    })
  },
  hmacSign: {
    label: 'HMAC Sign',
    description: 'Creates an HMAC signature',
    inputs: {
      algorithm: { type: 'string', label: 'Algorithm' },
      secret: { type: 'string', label: 'Secret' },
      value: { type: 'string', label: 'Value' }
    },
    outputs: {
      signature: { type: 'string', label: 'Signature' }
    },
    resolve: inputs => ({
      signature: crypto
        .createHmac(inputs.algorithm || 'sha512', inputs.secret)
        .update(inputs.value)
        .digest('base64')
    })
  },
  truncate: {
    label: 'Truncate',
    description:
      'Removes characters from the right of text until it is shorter than or equal to your defined limit',
    inputs: {
      text: { type: 'string', label: 'Text' },
      limit: { type: 'number', label: 'Limit' }
    },
    outputs: {
      text: { type: 'string', label: 'Text' }
    },
    resolve: ({ text, limit }) => ({ text: text.substr(0, limit) })
  },
  truncateLeft: {
    label: 'Truncate Left',
    description:
      'Removes characters from the left of text until it is shorter than or equal to your defined limit',
    inputs: {
      text: { type: 'string', label: 'Text' },
      limit: { type: 'number', label: 'Limit' }
    },
    outputs: {
      text: { type: 'string', label: 'Text' }
    },
    resolve: ({ text, limit }) => ({ text: text.substr(-limit, limit) })
  },
  lowerCase: {
    label: 'Lower Case',
    description: 'Converts all the letters in a string to lower case',
    inputs: {
      text: { type: 'string', label: 'Text' }
    },
    outputs: {
      text: { type: 'string', label: 'Text' }
    },
    resolve: ({ text }) => ({ text: text.toLowerCase() })
  },
  upperCase: {
    label: 'Upper Case',
    description: 'Converts all the letters in a string to upper case',
    inputs: {
      text: { type: 'string', label: 'Text' }
    },
    outputs: {
      text: { type: 'string', label: 'Text' }
    },
    resolve: ({ text }) => ({ text: text.toUpperCase() })
  },
  joinText: {
    label: 'Join Text',
    description: 'Joins a list of strings into one string',
    inputs: {
      texts: { type: 'json', label: 'Text List' },
      joiner: { type: 'string', label: 'Joiner' }
    },
    outputs: {
      text: { type: 'string', label: 'Text' }
    },
    resolve: ({ texts, joiner }) => ({ text: texts.join(joiner ?? '') })
  },
  gadgetText: {
    gadget: 'Text',
    label: 'Gadget:Text',
    description: 'Creates a Text gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      text: { type: 'string', label: 'Text' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'Text',
        label: inputs.outputLabel,
        id,
        data: inputs.text
      }
    })
  },
  gadgetUrl: {
    gadget: 'Url',
    label: 'Gadget:Url',
    description: 'Creates a Link gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      link: { type: 'string', label: 'Link' },
      linkText: { type: 'string', label: 'Link Text' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'Url',
        label: inputs.outputLabel,
        id,
        data: inputs.link,
        details: {
          customLinkText: {
            enabled: true,
            value: inputs.linkText
          }
        }
      }
    })
  },
  gadgetRichText: {
    gadget: 'RichText',
    label: 'Gadget:RichText',
    description: 'Creates a RichText gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      text: { type: 'string', label: 'RichText' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'RichText',
        label: inputs.outputLabel,
        id,
        data: inputs.text
      }
    })
  },
  gadgetEmail: {
    gadget: 'Email',
    label: 'Gadget:Email',
    description: 'Creates an Email gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      email: { type: 'string', label: 'Email' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'Email',
        label: inputs.outputLabel,
        id,
        data: inputs.email
      }
    })
  },
  gadgetNumber: {
    gadget: 'Number',
    label: 'Gadget:Number',
    description: 'Creates a Number gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      number: { type: 'number', label: 'Number' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'Number',
        label: inputs.outputLabel,
        id,
        data: inputs.number
      }
    })
  },
  gadgetUserTypeahead: {
    gadget: 'UserTypeahead',
    label: 'Gadget:UserTypeahead',
    description: 'Creates a UserTypeahead gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      id: { type: 'string', label: 'ID' },
      label: { type: 'string', label: 'Label' },
      schoolId: { type: 'string', label: 'School ID' },
      displayName: { type: 'string', label: 'Display Name' },
      username: { type: 'string', label: 'Username' },
      email: { type: 'string', label: 'Email' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'UserTypeahead',
        label: inputs.outputLabel,
        id,
        data: {
          id: inputs.id,
          label: inputs.label,
          schoolId: inputs.schoolId,
          displayName: inputs.displayName,
          username: inputs.username,
          email: inputs.email
        }
      }
    })
  },
  gadgetGroupTypeahead: {
    gadget: 'GroupTypeahead',
    label: 'Gadget:GroupTypeahead',
    description: 'Creates a GroupTypeahead gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      id: { type: 'string', label: 'ID' },
      label: { type: 'string', label: 'Label' },
      categoryId: { type: 'string', label: 'Category ID' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'GroupTypeahead',
        label: inputs.outputLabel,
        id,
        data: {
          id: inputs.id,
          label: inputs.label
        }
      }
    })
  },
  gadgetImageUpload: {
    gadget: 'ImageUpload',
    label: 'Gadget:ImageUpload',
    description: 'Creates an ImageUpload gadget',
    inputs: {
      outputLabel: { type: 'string', label: 'Gadget Label', hidePort: true },
      thumbnailUrl: { type: 'string', label: 'Thumbnail URL (40px)' },
      mediumUrl: { type: 'string', label: 'Medium URL (600px)' },
      filename: { type: 'string', label: 'Filename' }
    },
    outputs: {
      gadget: { type: 'gadget', label: 'Build Gadget' }
    },
    resolve: (inputs, _context, id) => ({
      gadget: {
        type: 'ImageUpload',
        label: inputs.outputLabel,
        id,
        data: {
          thumbnail: { temporaryUrl: inputs.thumbnailUrl },
          medium: { temporaryUrl: inputs.mediumUrl },
          filename: inputs.filename
        }
      }
    })
  }
}

function prepare () {
  _.forEach(nodes, node => {
    const oldInputs = node.inputs
    node.inputs = (inputs, connections, context) => {
      const rawInputs = _.isFunction(oldInputs)
        ? oldInputs(inputs, connections, context)
        : oldInputs
      return _.flatMap(rawInputs, (transput, name) => {
        const opts = { inputs, connections, name, transput }
        for (const key in advancedPorts) {
          if (key in transput) {
            const result = advancedPorts[key].build(transput[key], opts)
            if (result) return result
          }
        }
        const { type, label, hidePort } = transput
        return [{ type, name, label, hidePort }]
      })
    }
    const oldOutputs = node.outputs
    node.outputs = (inputs, connections, context) => {
      const rawOutputs = _.isFunction(oldOutputs)
        ? oldOutputs(inputs, connections, context)
        : oldOutputs
      return _.flatMap(rawOutputs, (transput, name) => {
        const opts = { inputs, connections, name, transput }
        for (const key in advancedPorts) {
          if (key in transput) {
            const result = advancedPorts[key].build(transput[key], opts)
            if (result) return result
          }
        }
        const { type, label, hidePort } = transput
        return [{ type, name, label, hidePort }]
      })
    }
  })
}

const advancedPorts = {
  when: {
    build: (when, { inputs }) => {
      return _.every(when, (values, key) => values.includes(_.get(inputs, key)))
        ? null
        : []
    }
  },
  compose: {
    build: (compose, { inputs, name, transput }) => {
      const template = inputs?.[name]?.string || ''
      const re = /{{([\s\S]+?)}}/g
      const ids = [...template.matchAll(re)].map(([, id]) => id)
      return [
        { type: transput.type, name, label: transput.label, hidePort: true },
        ...[...new Set(ids).values()].map(id => ({
          type: 'string',
          name: compose + id,
          label: id
        }))
      ]
    }
  },
  fromList: {
    build: ([key, innerKey], { inputs, name }) => {
      return _.reduce(
        inputs?.[key]?.[innerKey],
        (arr, { key, type, label }) => {
          if (label) arr.push({ name: `${name}${type}${key}`, type, label })
          return arr
        },
        []
      )
    }
  },
  array: {
    build: (_, { inputs, connections, name, transput: { type, label } }) => {
      const nums = Object.keys({ ...inputs, ...connections.inputs })
        .filter(a => a.startsWith(name))
        .map(a => +a.replace(name, ''))
      let num = Math.max(...nums.concat(0)) + 1
      for (; num > 0; --num) {
        const key = `${name}${num}`
        if (key in connections.inputs || !ports[type].isEmpty(inputs[key])) {
          break
        }
      }
      const result = []
      for (let i = 1; i <= num + 1; ++i) {
        result.push({ type, name: `${name}${i}`, label: `${label}\xa0${i}` })
      }
      return result
    },
    resolve: (data, prefix) => {
      const keys = Object.keys(data).filter(key => key.startsWith(prefix))
      keys.sort()
      const result = []
      for (const key of keys) {
        result.push(...(_.isArray(data[key]) ? data[key] : [data[key]]))
      }
      return _.compact(result)
    }
  },
  tupleArray: {
    build: (tuples, { inputs, connections, name }) => {
      const prefix = `${name}_${tuples[0].name}_`
      const nums = Object.keys({ ...inputs, ...connections.inputs })
        .filter(a => a.startsWith(prefix))
        .map(a => +a.replace(prefix, ''))
      let num = Math.max(...nums.concat(0)) + 1
      for (; num > 0; --num) {
        const i = num
        const isEmpty = tuples.every(tuple => {
          const key = `${name}_${tuple.name}_${i}`
          return (
            !(key in connections.inputs) &&
            ports[tuple.type].isEmpty(inputs[key])
          )
        })
        if (!isEmpty) break
      }
      const result = []
      for (let i = 1; i <= num + 1; ++i) {
        tuples.forEach(tuple => {
          result.push({
            type: tuple.type,
            name: `${name}_${tuple.name}_${i}`,
            label: `${tuple.label}\xa0${i}`
          })
        })
      }
      return result
    },
    resolve: (data, prefix) => {
      const keys = Object.keys(data).filter(key => key.startsWith(prefix))
      const result = []
      _.forEach(keys, key => {
        const [, name, num] = key.split('_')
        result[+num] = result[+num] || {}
        result[+num][name] = data[key]
      })
      return _.compact(result)
    }
  }
}

// Code above this comment is copied to the server using a script. This is
// explained in more detail at the top of the file.

const config = new FlumeConfig()

_.forEach(ports, (port, type) => {
  config.addPortType({ type, ...port })
})

const wrapTransput = fn => ports => (inputs, connections, context) => {
  const transputs = fn(inputs, connections, context)
  return _.map(transputs, ({ type, ...args }) => ports[type](args))
}

const props = [
  'label',
  'description',
  'addable',
  'deletable',
  'initialWidth',
  'root'
]

prepare()

_.forEach(nodes, (node, type) => {
  config.addNodeType({
    type,
    inputs: wrapTransput(node.inputs),
    outputs: wrapTransput(node.outputs),
    ..._.pick(node, props)
  })
})

export default config
