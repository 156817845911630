/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6093 13.7273L12.5127 10.6307C13.4556 9.21923 13.8397 7.50755 13.5905 5.82856C13.3412 4.14956 12.4764 2.62331 11.1641 1.54667C9.85185 0.470034 8.18604 -0.0800023 6.4907 0.00355119C4.79537 0.0871046 3.19172 0.798274 1.99169 1.99873C0.791662 3.19918 0.0810594 4.80308 -0.00189467 6.49845C-0.0848487 8.19381 0.465777 9.85943 1.54288 11.1713C2.61998 12.4832 4.14654 13.3475 5.82562 13.5962C7.5047 13.8448 9.21625 13.46 10.6273 12.5167L13.724 15.6127C13.9762 15.8586 14.3144 15.9963 14.6667 15.9963C15.0189 15.9963 15.3572 15.8586 15.6093 15.6127C15.8593 15.3626 15.9997 15.0235 15.9997 14.67C15.9997 14.3164 15.8593 13.9774 15.6093 13.7273ZM6.83334 2.00333C7.78928 2.00333 8.72376 2.2868 9.5186 2.81789C10.3134 3.34898 10.9329 4.10385 11.2988 4.98702C11.6646 5.8702 11.7603 6.84202 11.5738 7.7796C11.3873 8.71717 10.927 9.57839 10.251 10.2543C9.57507 10.9303 8.71385 11.3906 7.77628 11.5771C6.8387 11.7636 5.86688 11.6679 4.98371 11.3021C4.10053 10.9363 3.34567 10.3168 2.81457 9.52192C2.28348 8.72708 2.00001 7.7926 2.00001 6.83666C2.00142 5.55521 2.5111 4.32666 3.41722 3.42054C4.32334 2.51442 5.5519 2.00474 6.83334 2.00333Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6093 13.7273L12.5127 10.6307C13.4556 9.21923 13.8397 7.50755 13.5905 5.82856C13.3412 4.14956 12.4764 2.62331 11.1641 1.54667C9.85185 0.470034 8.18604 -0.0800023 6.4907 0.00355119C4.79537 0.0871046 3.19172 0.798274 1.99169 1.99873C0.791662 3.19918 0.0810594 4.80308 -0.00189467 6.49845C-0.0848487 8.19381 0.465777 9.85943 1.54288 11.1713C2.61998 12.4832 4.14654 13.3475 5.82562 13.5962C7.5047 13.8448 9.21625 13.46 10.6273 12.5167L13.724 15.6127C13.9762 15.8586 14.3144 15.9963 14.6667 15.9963C15.0189 15.9963 15.3572 15.8586 15.6093 15.6127C15.8593 15.3626 15.9997 15.0235 15.9997 14.67C15.9997 14.3164 15.8593 13.9774 15.6093 13.7273ZM6.83334 2.00333C7.78928 2.00333 8.72376 2.2868 9.5186 2.81789C10.3134 3.34898 10.9329 4.10385 11.2988 4.98702C11.6646 5.8702 11.7603 6.84202 11.5738 7.7796C11.3873 8.71717 10.927 9.57839 10.251 10.2543C9.57507 10.9303 8.71385 11.3906 7.77628 11.5771C6.8387 11.7636 5.86688 11.6679 4.98371 11.3021C4.10053 10.9363 3.34567 10.3168 2.81457 9.52192C2.28348 8.72708 2.00001 7.7926 2.00001 6.83666C2.00142 5.55521 2.5111 4.32666 3.41722 3.42054C4.32334 2.51442 5.5519 2.00474 6.83334 2.00333Z\" />\n</svg>\n";
export default src;
