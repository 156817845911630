/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { StaticOutlet } from '../components/static-outlet'

export default function ({ context }) {
  const location = useLocation()

  return (
    <TransitionGroup>
      <CSSTransition key={getTransitionKey(location)} timeout={450}>
        <StaticOutlet key={location.pathname} context={context} />
      </CSSTransition>
    </TransitionGroup>
  )
}

function getTransitionKey (location) {
  if (location.pathname.includes('general-data')) return 'general-data'
  return location.pathname
    .split('/versions')
    .at(0)
    .replace(/\/(edit|view|action)?$/, '')
}
