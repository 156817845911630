/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, isEmpty, map, sortBy } from 'lodash'
import React from 'react'

import { Option, Select } from '../../ui/select'

const LABEL_MAPPINGS = {
  GroupMultiselect: 'Data Lookup (Multiple Groups)',
  GroupTypeahead: 'Data Lookup (Group)',
  UserMultiselect: 'Data Lookup (People)',
  UserTypeahead: 'Data Lookup (Person)'
}

export default function FieldSelector ({ defaultValue, formFields, ...props }) {
  const cleanedUpFields = sortBy(
    map(formFields, ff => {
      return { ...ff, type: LABEL_MAPPINGS[ff.type] || ff.type }
    }),
    ['label', 'type']
  )
  const dataFormFields = filter(cleanedUpFields, ({ formKey }) =>
    formKey.startsWith('data.')
  )
  const metaFormFields = filter(cleanedUpFields, ({ formKey }) =>
    formKey.startsWith('meta.')
  )
  const integrationFormFields = filter(cleanedUpFields, ({ formKey }) =>
    formKey.startsWith('integration.')
  )
  const echoFields = filter(cleanedUpFields, ({ formKey }) =>
    formKey.startsWith('echo.')
  )
  return (
    <Select {...props}>
      <Option value={defaultValue} />
      <optgroup label='Form Fields'>
        {map(dataFormFields, field => (
          <Option key={field.formKey} value={field.formKey}>
            {determineFinalLabel(`${field.label} - ${field.type}`)}
          </Option>
        ))}
      </optgroup>
      <optgroup label='Meta Fields'>
        {map(metaFormFields, field => (
          <Option key={field.formKey} value={field.formKey}>
            {determineFinalLabel(`${field.label} - ${field.type}`)}
          </Option>
        ))}
      </optgroup>
      {!isEmpty(integrationFormFields) && (
        <optgroup label='Integration Fields'>
          {map(integrationFormFields, field => (
            <Option key={field.formKey} value={field.formKey}>
              {determineFinalLabel(`${field.label} - ${field.type}`)}
            </Option>
          ))}
        </optgroup>
      )}
      {!isEmpty(echoFields) && (
        <optgroup label='Echo Fields'>
          {map(echoFields, field => (
            <Option key={field.formKey} value={field.formKey}>
              {determineFinalLabel(`${field.label} - ${field.type}`)}
            </Option>
          ))}
        </optgroup>
      )}
    </Select>
  )
}

const determineFinalLabel = label => {
  return label.length > 70
    ? `${label.substring(0, 45)}...${label.substring(label.length - 25)}`
    : label
}
