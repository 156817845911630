/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import * as Icons from '../icons'

export default function NewButton () {
  return (
    <button
      className='kp-flume-button absolute bottom-4 right-4 flex h-10 w-10 items-center justify-center rounded-full'
      onClick={event => {
        const element = event.currentTarget.previousElementSibling
        const x = window.innerWidth / 2 - 150
        const y = window.innerHeight / 2 - 175
        const e = document.createEvent('MouseEvents')
        e.initMouseEvent(
          'contextmenu',
          true,
          true,
          window,
          1,
          x,
          y,
          x,
          y,
          false,
          false,
          false,
          false,
          2,
          null
        )
        return !element.dispatchEvent(e)
      }}
    >
      <Icons.Add fill='#111' />
    </button>
  )
}
