/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import Toggle from '../../ui/toggle'

const Wrapper = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-gray-100);
  padding: 0 2px;
  height: auto;
  line-height: initial;

  @media screen and (min-width: 1025px) {
    font-size: 18px;
    padding-top: 15px;
  }
`

export default ({ checked, onChange, removeHeader, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {!removeHeader && <Header>View</Header>}
      <Toggle
        aria-label='toggle workflow status switch'
        value={checked}
        onChange={onChange}
        off='Review'
        on='Status'
      />
    </Wrapper>
  )
}
