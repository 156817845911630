/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { partition } from 'lodash'
import React from 'react'

import { productBuilder } from './feature-flags'

const TenantFeaturesContext = React.createContext()

export const useTenantFeaturesContext = () =>
  React.useContext(TenantFeaturesContext)

export const TenantFeaturesContextProvider = ({
  children,
  suites,
  tenantFeatures
}) => {
  const [buildSuites, nonBuildSuites] = partition(suites, {
    name: 'Build'
  })
  const features = tenantFeatures || {}
  return (
    <TenantFeaturesContext.Provider
      value={{
        ...features,
        hasBuild: productBuilder || !!buildSuites.length,
        hasNonBuildSuites: productBuilder || !!nonBuildSuites.length
      }}
    >
      {children}
    </TenantFeaturesContext.Provider>
  )
}
