/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'

import { useAlerts } from '../../../ui/alerts'

export const useRemoveBranding = () => {
  const [removeBranding] = useMutation(mutation)
  const alerts = useAlerts()
  return id =>
    removeBranding(getParams(id)).then(() =>
      alerts.type3(i18n._('branding.removed'), 'success')
    )
}

const mutation = gql`
  mutation removeBranding($id: ID) {
    removeBranding(spaceId: $id) {
      query {
        branding(spaceId: $id) {
          id
          exists
        }
      }
    }
  }
`

const getParams = (id, brand) => ({ variables: { id, brand } })
