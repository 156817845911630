/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import styled from 'styled-components'
import { height, space, width } from 'styled-system'

import { ReactComponent as BuildIllustration } from './build.svg.jsx'
import { ReactComponent as CraigIllustration } from './craig.svg.jsx'
import { ReactComponent as EditGadgetIllustration } from './edit-gadget.svg.jsx'
import { ReactComponent as EmptyIllustration } from './empty.svg.jsx'
import { ReactComponent as FinishedGirlIllustration } from './finished-girl.svg.jsx'
import { ReactComponent as FistBumpIllustration } from './fist-bump.svg.jsx'
import { ReactComponent as JenniferIllustration } from './jennifer.svg.jsx'
import { ReactComponent as LayoutIllustration } from './layout.svg.jsx'
import { ReactComponent as NoAppsIllustration } from './no-apps.svg.jsx'
import { ReactComponent as NoPermissionsIllustration } from './no-permissions.svg.jsx'
import { ReactComponent as OutOfDateIllustration } from './out-of-date.svg.jsx'
import { ReactComponent as PeterIllustration } from './peter.svg.jsx'
import { ReactComponent as ProblemIllustration } from './problem.svg.jsx'
import { ReactComponent as ReviewIllustration } from './review.svg.jsx'
import { ReactComponent as RiddlerIllustration } from './riddler.svg.jsx'
import { ReactComponent as SuccessIllustration } from './success.svg.jsx'

const Base = styled.div`
  flex-shrink: 0;
  ${space}
  ${width}
  ${height}
`

export const Build = styled(Base)``
Build.defaultProps = { as: BuildIllustration }

export const Craig = styled(Base)``
Craig.defaultProps = { as: CraigIllustration }

export const EditGadget = styled(Base)``
EditGadget.defaultProps = { as: EditGadgetIllustration }

export const Empty = styled(Base)``
Empty.defaultProps = { as: EmptyIllustration }

export const FinishedGirl = styled(Base)``
FinishedGirl.defaultProps = { as: FinishedGirlIllustration }

export const FistBump = styled(Base)``
FistBump.defaultProps = { as: FistBumpIllustration }

export const Jennifer = styled(Base)``
Jennifer.defaultProps = { as: JenniferIllustration }

export const Layout = styled(Base)``
Layout.defaultProps = { as: LayoutIllustration }

export const NoApps = styled(Base)``
NoApps.defaultProps = { as: NoAppsIllustration }

export const NoPermissions = styled(Base)``
NoPermissions.defaultProps = { as: NoPermissionsIllustration }

export const OutOfDate = styled(Base)``
OutOfDate.defaultProps = { as: OutOfDateIllustration }

export const Peter = styled(Base)``
Peter.defaultProps = { as: PeterIllustration }

export const Problem = styled(Base)``
Problem.defaultProps = { as: ProblemIllustration }

export const Review = styled(Base)``
Review.defaultProps = { as: ReviewIllustration }

export const Riddler = styled(Base)``
Riddler.defaultProps = { as: RiddlerIllustration }

export const Success = styled(Base)``
Success.defaultProps = { as: SuccessIllustration }
