/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M14.0387 6.85939L3.34266 0.376053C3.14043 0.253584 2.90927 0.187092 2.67287 0.183391C2.43647 0.17969 2.20334 0.238914 1.99737 0.354992C1.7914 0.47107 1.62 0.639828 1.50074 0.84397C1.38147 1.04811 1.31864 1.28029 1.31866 1.51672V14.4834C1.31856 14.7199 1.38139 14.9522 1.50068 15.1564C1.61998 15.3607 1.79146 15.5295 1.99754 15.6456C2.20361 15.7617 2.43686 15.8209 2.67335 15.8171C2.90985 15.8133 3.14108 15.7467 3.34333 15.6241L14.0387 9.14072C14.2349 9.02195 14.3972 8.85456 14.5098 8.65474C14.6224 8.45492 14.6816 8.22943 14.6816 8.00005C14.6816 7.77068 14.6224 7.54518 14.5098 7.34536C14.3972 7.14554 14.2349 6.97816 14.0387 6.85939Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M14.0387 6.85939L3.34266 0.376053C3.14043 0.253584 2.90927 0.187092 2.67287 0.183391C2.43647 0.17969 2.20334 0.238914 1.99737 0.354992C1.7914 0.47107 1.62 0.639828 1.50074 0.84397C1.38147 1.04811 1.31864 1.28029 1.31866 1.51672V14.4834C1.31856 14.7199 1.38139 14.9522 1.50068 15.1564C1.61998 15.3607 1.79146 15.5295 1.99754 15.6456C2.20361 15.7617 2.43686 15.8209 2.67335 15.8171C2.90985 15.8133 3.14108 15.7467 3.34333 15.6241L14.0387 9.14072C14.2349 9.02195 14.3972 8.85456 14.5098 8.65474C14.6224 8.45492 14.6816 8.22943 14.6816 8.00005C14.6816 7.77068 14.6224 7.54518 14.5098 7.34536C14.3972 7.14554 14.2349 6.97816 14.0387 6.85939Z\" />\n</svg>\n";
export default src;
