/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { every, filter, get, some } from 'lodash'
import React from 'react'

export default function escapeStringRegExp (string) {
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
}

export function winnowList (items, query, key) {
  if (!query) return items
  const terms = query
    .trim()
    .split(/\s+/)
    .map(term => new RegExp(escapeStringRegExp(term), 'i'))
  return filter(items, item => every(terms, s => s.test(get(item, key))))
}

export function emphasizeResult (item, query) {
  if (!query) return item
  const ranges = query
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .flatMap(term => {
      const start = item.toLowerCase().indexOf(term)
      if (start === -1) return []
      return [[start, start + term.length]]
    })
  return (
    <span>
      {format(item, ranges).map(([start, end], i) => {
        if (start === end) return null
        return (
          <span key={i} className={cx({ 'font-bold': i % 2 === 1 })}>
            {item.slice(start, end)}
          </span>
        )
      })}
    </span>
  )
}

function format (item, ranges) {
  const result = []
  let prevHighlight = false
  let prevIndex = 0
  for (let i = 0; i < item.length; ++i) {
    const highlight = some(ranges, ([start, end]) => i >= start && i < end)
    if (highlight !== prevHighlight) {
      result.push([prevIndex, i])
      prevHighlight = highlight
      prevIndex = i
    }
  }
  result.push([prevIndex, item.length])
  return result
}
