/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

export default function NotFoundViewEdit ({ NOTFOUND_TYPE }) {
  const str = NOTFOUND_TYPE ? (
    <div>
      <Trans
        id='sorry.we.could.not.find.gadget.named.colon'
        values={{ notFound: NOTFOUND_TYPE }}
      />
    </div>
  ) : (
    <Trans id='sorry.we.could.not.find.this.gadget' />
  )
  return <Wrapper>{str}</Wrapper>
}

const Wrapper = styled.div`
  background-color: #f0ad4e;
  color: white;
  padding: 15px;
  font-size: 20px;
  text-align: center;
`
