/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, values } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import FieldSelector from '../field-selector'

export default function VariablePicker ({ formFields, onSelect }) {
  return (
    <Wrapper className='dark:bg-light-gray-400'>
      <Label>
        <Trans id='select.form.field' />
      </Label>
      <FieldSelector
        defaultValue=''
        formFields={values(formFields)}
        placeholder={i18n._('select.form.field')}
        onChange={formKey => {
          const field = find(formFields, { formKey })
          if (field) onSelect(field)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #e7e7e7;
  border-top: none;
  padding: 0 13px 5px 13px;
`

const Label = styled.label`
  display: block;
  padding-top: 8px;
`
