/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.88 0.744028C15.8333 0.705023 15.7768 0.679557 15.7166 0.67039C15.6564 0.661223 15.5949 0.668707 15.5387 0.692028L0.205336 7.02536C0.144049 7.05092 0.0917928 7.0942 0.0552663 7.14966C0.0187398 7.20511 -0.000391702 7.27021 0.000324577 7.33661C0.00104086 7.40301 0.0215723 7.46768 0.0592866 7.52233C0.0970008 7.57699 0.150179 7.61913 0.212003 7.64336L4.43334 9.28269C4.4844 9.3026 4.53959 9.30961 4.59401 9.3031C4.64843 9.29659 4.70041 9.27676 4.74534 9.24536L10.3453 5.26269C10.4139 5.21314 10.4987 5.19149 10.5826 5.20214C10.6665 5.21278 10.7432 5.25492 10.7972 5.32002C10.8512 5.38512 10.8784 5.4683 10.8734 5.55273C10.8683 5.63715 10.8314 5.7165 10.77 5.77469L6.10334 10.2714C6.07108 10.3025 6.04542 10.3397 6.02789 10.381C6.01037 10.4222 6.00134 10.4666 6.00134 10.5114V15C6.00155 15.0731 6.02576 15.1441 6.07025 15.202C6.11474 15.26 6.17705 15.3017 6.24758 15.3208C6.31811 15.3399 6.39295 15.3353 6.46061 15.3077C6.52826 15.2801 6.58497 15.231 6.622 15.168L8.73334 11.5514C8.75538 11.5138 8.79116 11.4864 8.8331 11.4748C8.87504 11.4632 8.91984 11.4685 8.958 11.4894L12.842 13.6227C12.8875 13.6478 12.9382 13.6619 12.9901 13.6638C13.042 13.6658 13.0936 13.6557 13.1409 13.6342C13.1882 13.6127 13.2298 13.5804 13.2624 13.54C13.295 13.4996 13.3177 13.4521 13.3287 13.4014L15.9953 1.06803C16.0068 1.00864 16.0021 0.947276 15.9819 0.890299C15.9616 0.833322 15.9264 0.782816 15.88 0.744028Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.88 0.744028C15.8333 0.705023 15.7768 0.679557 15.7166 0.67039C15.6564 0.661223 15.5949 0.668707 15.5387 0.692028L0.205336 7.02536C0.144049 7.05092 0.0917928 7.0942 0.0552663 7.14966C0.0187398 7.20511 -0.000391702 7.27021 0.000324577 7.33661C0.00104086 7.40301 0.0215723 7.46768 0.0592866 7.52233C0.0970008 7.57699 0.150179 7.61913 0.212003 7.64336L4.43334 9.28269C4.4844 9.3026 4.53959 9.30961 4.59401 9.3031C4.64843 9.29659 4.70041 9.27676 4.74534 9.24536L10.3453 5.26269C10.4139 5.21314 10.4987 5.19149 10.5826 5.20214C10.6665 5.21278 10.7432 5.25492 10.7972 5.32002C10.8512 5.38512 10.8784 5.4683 10.8734 5.55273C10.8683 5.63715 10.8314 5.7165 10.77 5.77469L6.10334 10.2714C6.07108 10.3025 6.04542 10.3397 6.02789 10.381C6.01037 10.4222 6.00134 10.4666 6.00134 10.5114V15C6.00155 15.0731 6.02576 15.1441 6.07025 15.202C6.11474 15.26 6.17705 15.3017 6.24758 15.3208C6.31811 15.3399 6.39295 15.3353 6.46061 15.3077C6.52826 15.2801 6.58497 15.231 6.622 15.168L8.73334 11.5514C8.75538 11.5138 8.79116 11.4864 8.8331 11.4748C8.87504 11.4632 8.91984 11.4685 8.958 11.4894L12.842 13.6227C12.8875 13.6478 12.9382 13.6619 12.9901 13.6638C13.042 13.6658 13.0936 13.6557 13.1409 13.6342C13.1882 13.6127 13.2298 13.5804 13.2624 13.54C13.295 13.4996 13.3177 13.4521 13.3287 13.4014L15.9953 1.06803C16.0068 1.00864 16.0021 0.947276 15.9819 0.890299C15.9616 0.833322 15.9264 0.782816 15.88 0.744028Z\" />\n</svg>\n";
export default src;
