/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M9.90863 11.122H0.999959C0.778946 11.122 0.566984 11.0342 0.410704 10.8779C0.254423 10.7216 0.166626 10.5097 0.166626 10.2887C0.166626 10.0677 0.254423 9.8557 0.410704 9.69942C0.566984 9.54314 0.778946 9.45534 0.999959 9.45534H9.90863C10.1296 9.45534 10.3416 9.54314 10.4979 9.69942C10.6542 9.8557 10.742 10.0677 10.742 10.2887C10.742 10.5097 10.6542 10.7216 10.4979 10.8779C10.3416 11.0342 10.1296 11.122 9.90863 11.122Z`}/><path d={`M15 6.66667H0.999959C0.778946 6.66667 0.566984 6.57887 0.410704 6.42259C0.254423 6.26631 0.166626 6.05435 0.166626 5.83333C0.166626 5.61232 0.254423 5.40036 0.410704 5.24408C0.566984 5.0878 0.778946 5 0.999959 5H15C15.221 5 15.4329 5.0878 15.5892 5.24408C15.7455 5.40036 15.8333 5.61232 15.8333 5.83333C15.8333 6.05435 15.7455 6.26631 15.5892 6.42259C15.4329 6.57887 15.221 6.66667 15 6.66667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M9.90863 11.122H0.999959C0.778946 11.122 0.566984 11.0342 0.410704 10.8779C0.254423 10.7216 0.166626 10.5097 0.166626 10.2887C0.166626 10.0677 0.254423 9.8557 0.410704 9.69942C0.566984 9.54314 0.778946 9.45534 0.999959 9.45534H9.90863C10.1296 9.45534 10.3416 9.54314 10.4979 9.69942C10.6542 9.8557 10.742 10.0677 10.742 10.2887C10.742 10.5097 10.6542 10.7216 10.4979 10.8779C10.3416 11.0342 10.1296 11.122 9.90863 11.122Z\"/>\n<path d=\"M15 6.66667H0.999959C0.778946 6.66667 0.566984 6.57887 0.410704 6.42259C0.254423 6.26631 0.166626 6.05435 0.166626 5.83333C0.166626 5.61232 0.254423 5.40036 0.410704 5.24408C0.566984 5.0878 0.778946 5 0.999959 5H15C15.221 5 15.4329 5.0878 15.5892 5.24408C15.7455 5.40036 15.8333 5.61232 15.8333 5.83333C15.8333 6.05435 15.7455 6.26631 15.5892 6.42259C15.4329 6.57887 15.221 6.66667 15 6.66667Z\"/>\n</svg>\n";
export default src;
