/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.7333 7.44533L9.19 4.9C9.14338 4.85353 9.08405 4.8219 9.01949 4.8091C8.95493 4.7963 8.88802 4.8029 8.8272 4.82807C8.76638 4.85324 8.71438 4.89585 8.67774 4.95053C8.6411 5.00521 8.62148 5.06951 8.62133 5.13533V6.68066C8.62133 6.72487 8.60377 6.76726 8.57251 6.79852C8.54126 6.82977 8.49887 6.84733 8.45466 6.84733H0.999997C0.778984 6.84733 0.567022 6.93513 0.410742 7.09141C0.254461 7.24769 0.166664 7.45965 0.166664 7.68066C0.166664 7.90168 0.254461 8.11364 0.410742 8.26992C0.567022 8.4262 0.778984 8.514 0.999997 8.514H8.45466C8.49887 8.514 8.54126 8.53156 8.57251 8.56281C8.60377 8.59407 8.62133 8.63646 8.62133 8.68066V10.2267C8.62129 10.2925 8.64077 10.357 8.67731 10.4118C8.71386 10.4666 8.76583 10.5094 8.82666 10.5347C8.86731 10.5512 8.91077 10.5598 8.95466 10.56C9.04299 10.5598 9.12764 10.5246 9.19 10.462L11.7333 7.91666C11.7958 7.85416 11.8309 7.76939 11.8309 7.681C11.8309 7.59261 11.7958 7.50784 11.7333 7.44533Z`}/><path d={`M15 0.165993C14.8905 0.165993 14.7821 0.18757 14.6809 0.22949C14.5798 0.271411 14.4879 0.332853 14.4105 0.410306C14.3331 0.487759 14.2717 0.579703 14.2299 0.680885C14.1881 0.782067 14.1666 0.890502 14.1667 0.999993V15C14.1667 15.221 14.2545 15.433 14.4107 15.5892C14.567 15.7455 14.779 15.8333 15 15.8333C15.221 15.8333 15.433 15.7455 15.5893 15.5892C15.7455 15.433 15.8333 15.221 15.8333 15V0.999993C15.8334 0.890502 15.8119 0.782067 15.7701 0.680885C15.7282 0.579703 15.6669 0.487759 15.5895 0.410306C15.5121 0.332853 15.4202 0.271411 15.3191 0.22949C15.2179 0.18757 15.1095 0.165993 15 0.165993Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11.7333 7.44533L9.19 4.9C9.14338 4.85353 9.08405 4.8219 9.01949 4.8091C8.95493 4.7963 8.88802 4.8029 8.8272 4.82807C8.76638 4.85324 8.71438 4.89585 8.67774 4.95053C8.6411 5.00521 8.62148 5.06951 8.62133 5.13533V6.68066C8.62133 6.72487 8.60377 6.76726 8.57251 6.79852C8.54126 6.82977 8.49887 6.84733 8.45466 6.84733H0.999997C0.778984 6.84733 0.567022 6.93513 0.410742 7.09141C0.254461 7.24769 0.166664 7.45965 0.166664 7.68066C0.166664 7.90168 0.254461 8.11364 0.410742 8.26992C0.567022 8.4262 0.778984 8.514 0.999997 8.514H8.45466C8.49887 8.514 8.54126 8.53156 8.57251 8.56281C8.60377 8.59407 8.62133 8.63646 8.62133 8.68066V10.2267C8.62129 10.2925 8.64077 10.357 8.67731 10.4118C8.71386 10.4666 8.76583 10.5094 8.82666 10.5347C8.86731 10.5512 8.91077 10.5598 8.95466 10.56C9.04299 10.5598 9.12764 10.5246 9.19 10.462L11.7333 7.91666C11.7958 7.85416 11.8309 7.76939 11.8309 7.681C11.8309 7.59261 11.7958 7.50784 11.7333 7.44533Z\" />\n<path d=\"M15 0.165993C14.8905 0.165993 14.7821 0.18757 14.6809 0.22949C14.5798 0.271411 14.4879 0.332853 14.4105 0.410306C14.3331 0.487759 14.2717 0.579703 14.2299 0.680885C14.1881 0.782067 14.1666 0.890502 14.1667 0.999993V15C14.1667 15.221 14.2545 15.433 14.4107 15.5892C14.567 15.7455 14.779 15.8333 15 15.8333C15.221 15.8333 15.433 15.7455 15.5893 15.5892C15.7455 15.433 15.8333 15.221 15.8333 15V0.999993C15.8334 0.890502 15.8119 0.782067 15.7701 0.680885C15.7282 0.579703 15.6669 0.487759 15.5895 0.410306C15.5121 0.332853 15.4202 0.271411 15.3191 0.22949C15.2179 0.18757 15.1095 0.165993 15 0.165993Z\" />\n</svg>\n";
export default src;
