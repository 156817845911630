/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { cloneDeep, isEmpty, isEqual, map } from 'lodash'
import React from 'react'
import {
  Link,
  Navigate,
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { useImmer } from 'use-immer'

import {
  moveWorkflowStatus,
  productBuilder
} from '../../components/feature-flags'
import FormVersionBar from '../../components/form-version-bar'
import FinishedPage from '../../components/formbot-container/finished-page'
import FormbotContainer from '../../components/formbot-container/formbot-container'
import MoreOptionsMenu from '../../components/formbot-container/more-options-menu'
import Wrapper from '../../components/formbot-container/page-wrapper'
import InfoBox from '../../components/info-box'
import { PortalOrange } from '../../components/portals'
import Spinner from '../../components/spinner'
import { CannotDiscard } from '../../components/system-error'
import { useTenantFeaturesContext } from '../../components/tenant-features-context'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { AppIdsProvider } from '../../components/use-app-ids-context'
import { useDocumentTitle } from '../../components/use-document-title'
import { useQuery } from '../../components/use-query'
import { useScrollToTop } from '../../components/use-scroll-to-top'
import { useSingleAlert } from '../../components/use-single-alert'
import { useWindowWidth } from '../../components/use-window-width'
import { ValidationErrors } from '../../components/validation-errors'
import VersionsDropdown, {
  DocumentStatus
} from '../../components/versions-dropdown'
import Formbot, { getPages, validate, validateWithDetails } from '../../formbot'
import { traverseTemplate } from '../../formbot/engine/formbot/utils'
import * as Icons from '../../icons'
import * as Illustrations from '../../illustrations'
import NeedsAuth from '../../pages/needs-auth'
import NotFound from '../../pages/not-found'
import StepIcon from '../../pages/old-actions/components/step-icon'
import { useAlertTracker, useAlerts } from '../../ui/alerts'
import Checkbox from '../../ui/checkbox'
import Popup from '../../ui/pop-up'
import { Popover2 } from '../../ui/popover'
import * as DocumentHistory from '../document-history'
import { useDiscardDocumentMutation } from './components/mutation.discard'
import { useSendbackMutation } from './components/mutation.sendback'
import { useSubmitDocumentMutation } from './components/mutation.submit-document'
import { useWithdrawMutation } from './components/mutation.withdraw'
import SendbackContent from './sendback-content'
import WithCommentContent from './with-comment-content'
import WorkflowTracker from './workflow-tracker'
import * as wfUtils from './workflow-tracker/utils'

export default function RedirectAction () {
  return <NewRedirectAction />
}

const suiteListQuery = gql`
  query SuiteListQuery {
    suites {
      id
      icon
      name
      slug
    }
  }
`

function NewRedirectAction () {
  const [fetchSuites, { data }] = useLazyQuery(suiteListQuery)
  const { search } = useLocation()
  const { actionId } = useParams()
  const lastSuite = window.localStorage.getItem('current-suite')
  React.useEffect(() => {
    if (!productBuilder && !lastSuite) fetchSuites()
  }, [!lastSuite])
  let suite = productBuilder ? 'products' : lastSuite || data?.suites?.[0]?.slug
  if (!suite && !data?.suites) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <Spinner size={100} />
      </div>
    )
  }
  suite = suite ?? 'void'
  return <Navigate to={`/${suite}/actions/${actionId}${search}`} replace />
}

export function Action ({ refetch }) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const host = window.location.origin
  const { pathname } = useLocation()
  const actionPage = pathname.includes('action')
  const { actionId, appId: appId2 } = useParams()
  const rawPageNum = searchParams.get('page')
  const pageNum = parseInt(rawPageNum, 10) || 1
  const scrollRef = useScrollToTop([pageNum])
  const q = getActionQuery(actionId, actionPage)
  const { data, loading, error } = useQuery(q)
  const [newVersionLoading, setNewVersionLoading] = React.useState(false)

  useDocumentTitle(data?.action?.document?.app?.name)
  const alerts = useAlerts()
  const singleAlert = useSingleAlert('type2')
  const [trackAlert, closeTrackedAlerts] = useAlertTracker()
  const [showingWorkflow, setShowingWorkflow] = React.useState(
    !!searchParams.get('showWf')
  )

  const [showingHistory, setShowingHistory] = React.useState(
    moveWorkflowStatus && showingWorkflow
  )
  const [animateFormbot, setAnimateFormbot] = React.useState(false)
  const [animateHistoryButton, setAnimateHistoryButton] = React.useState(false)
  const menuRef = React.useRef()
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false
  const [submitting, setSubmitting] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [shouldValidate, setShouldValidate] = React.useState(false)
  const [doNotShowModal, setDoNotShowModal] = React.useState(false)
  const [realCanSubmit, setRealCanSubmit] = React.useState(false)
  const [warnedSubmission, setWarnedSubmission] = React.useState(false)
  const [formData, updateFormData] = useImmer(null)
  const [discardError, setDiscardError] = React.useState(false)
  const [formChanged, setFormChanged] = React.useState(false)
  const formDataRef = React.useRef()
  formDataRef.current = formData
  const [imageCache, setImageCache] = useImmer({})
  const updateImageCache = (key, val) => {
    setImageCache(draft => {
      draft[key] = val
    })
  }
  const [redirect, setRedirect] = React.useState(null)
  const isSubmitter = data?.action?.type === 'Initiate'
  const isPublished = data?.action?.document?.status === 'published'
  const documentCacheRef = React.useRef()
  const width = useWindowWidth()
  if (data?.action?.document) documentCacheRef.current = data.action.document

  const appHasStartNew =
    documentCacheRef.current?.dataset?.showNewFormButton ||
    documentCacheRef.current?.dataset?.autoStartNewForm

  const structure = React.useMemo(() => {
    const template = cloneDeep(data?.action?.document?.form?.template)
    if (isSubmitter) {
      traverseTemplate(template, (gadget, root, parent, i) => {
        if (gadget.type === 'Section' && gadget.details?.officeUseOnly) {
          parent.children.splice(i, 1)
        }
      })
    }
    const metaFields = data?.action?.document?.form?.metaFields ?? []
    const integrationFields =
      data?.action?.document?.form?.integrationFields ?? []
    return {
      template,
      metaFields,
      integrationFields,
      trashed: data?.action?.document?.form?.trashed || []
    }
  }, [data, isSubmitter])
  const document = {
    data: formData,
    meta: data?.action?.document?.meta || {},
    integration: data?.action?.document?.integration || {}
  }
  const documentRef = React.useRef()
  documentRef.current = document
  const appId = documentCacheRef.current?.app?.id
  const documentId = documentCacheRef.current?.id
  const isLoading =
    loading || data?.action?.status === 'processing' || newVersionLoading
  const showPrivacyModal =
    data?.action?.document?.dataset?.showDraft &&
    !showModal &&
    localStorage.getItem(`privacyModalDismissed-${appId}`) !== 'true'

  React.useEffect(() => {
    const actualData = data?.action?.document?.data
    if (actualData) updateFormData(() => actualData)
  }, [data, updateFormData, structure.template])

  const handleChange = (key, val) => {
    setFormChanged(true)
    return updateFormData(draft => {
      draft[key] = val
    })
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formChanged && currentLocation.pathname !== nextLocation.pathname
  )

  React.useEffect(() => {
    const dataMatches = isEqual(
      data?.action?.document?.data,
      formDataRef.current
    )
    if (dataMatches) return setFormChanged(false)
    if (blocker.state === 'blocked' && formChanged) {
      singleAlert(
        i18n._('pagesrunner.edit.unsaved.edits'),
        'error',
        close => (
          <button
            className='kp-button kp-button-solid'
            onClick={() => {
              if (showPrivacyModal) {
                setShowModal(true)
                close()
              } else {
                saveDocument().then(() => {
                  close()
                  blocker.proceed()
                })
              }
            }}
          >
            <Trans id='save' />
          </button>
        ),
        undefined,
        close => (
          <button
            className='kp-button'
            onClick={() => {
              blocker.proceed()
              close()
            }}
          >
            <Trans id='pagesrunner.edit.close' />
          </button>
        )
      )
    }
  }, [blocker, formData, formDataRef, data])

  const handleError = error => {
    const gqlError = error?.graphQLErrors?.[0]
    // We can remove the first check once we've migrated to elixir
    if (
      gqlError?.extensions?.code === 'CONFLICT' ||
      gqlError?.message?.includes('Action completed')
    ) {
      alerts.type3(gqlError.message, 'info')
    } else if (
      gqlError?.message?.includes('Document has already been submitted')
    ) {
      setDiscardError(true)
    } else {
      trackAlert(
        alerts.type2(i18n._('pagesrunner.action.server.error'), 'error')
      )
    }
    setSubmitting(false)
    setFormChanged(false)
  }

  const submitDoc = useSubmitDocumentMutation(q)

  const handleModalSubmission = async decision => {
    if (decision) {
      if (doNotShowModal) {
        localStorage.setItem(`privacyModalDismissed-${appId}`, true)
      }
      await submitDoc(documentId, formData, actionId, 'saved', undefined, true)
        .then(() => {
          refetch?.()
          setFormChanged(false)
          alerts.type3(i18n._('pagesrunner.action.form.saved'), 'success')
        })
        .catch(handleError)
    }
    setSaving(false)
    setShowModal(false)
  }

  const saveDocument = async () => {
    setSaving(true)
    if (showPrivacyModal) {
      setShowModal(true)
    } else {
      await submitDoc(documentId, formData, actionId, 'saved', undefined, true)
        .then(() =>
          alerts.type3(i18n._('pagesrunner.action.form.saved'), 'success')
        )
        .catch(handleError)
      setSaving(false)
      setFormChanged(false)
      return true
    }
  }

  const discardDoc = useDiscardDocumentMutation(q)
  const discardDocument = () => {
    setFormChanged(false)
    const confirmDiscard = window.confirm(
      i18n._('pagesrunner.action.discard.document')
    )
    if (confirmDiscard) {
      discardDoc(documentId)
        .then(() => {
          alerts.type3(
            i18n._('pagesrunner.action.discard.confirmed'),
            'success'
          )
          return setRedirect('..')
        })
        .catch(handleError)
    }
  }

  const withdrawDoc = useWithdrawMutation(q)

  const withdrawDocument = () => {
    const confirmWithdraw = window.confirm(
      i18n._('pagesrunner.action.withdraw.document')
    )
    if (confirmWithdraw) {
      withdrawDoc(documentId, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .then(res => {
          if (
            res?.data?.withdrawSubmission?.__typename === 'TerminationError'
          ) {
            throw new Error(res.data.withdrawDocument.reason)
          }
          alerts.type3(i18n._('pagesrunner.action.withdraw.alert'), 'success')
          return setRedirect('..')
        })
        .catch(handleError)
    }
  }

  const sendbackDoc = useSendbackMutation(q)
  const sendbackDocument = (comment, to) => {
    setSubmitting(true)
    const validations = validateWithDetails(
      documentRef.current,
      structure,
      true
    )
    if (!isEmpty(validations)) {
      setShouldValidate(true)
      trackAlert(
        alerts.type2(
          close => (
            <ValidationErrors
              label={i18n._('pagesrunner.action.failed.send')}
              validations={validations}
              close={close}
            />
          ),
          'error',
          null,
          true
        )
      )
      setSubmitting(false)
      return
    }
    setSubmitting(true)
    const body = { comment, fromStep: { actionId } }
    sendbackDoc(documentId, formData, to, body)
      .then(response => {
        if (response.data.sendbackDocument === 'Invalid Operation') {
          trackAlert(
            alerts.type2(
              i18n._('pagesrunner.action.invalid.operation'),
              'error'
            )
          )
          setSubmitting(false)
          return
        }
        alerts.type3(i18n._('pagesrunner.action.document.back'), 'success')
        return setRedirect('..')
      })
      .catch(handleError)
  }

  const submitDocument = (status, comment) => {
    const ignoreRequired = status === 'rejected'
    const validations = validateWithDetails(
      documentRef.current,
      structure,
      ignoreRequired
    )
    if (!isEmpty(validations)) {
      setShouldValidate(true)
      trackAlert(
        alerts.type2(
          close => (
            <ValidationErrors
              label={i18n._('pagesrunner.action.failed.submit')}
              validations={validations}
              close={close}
            />
          ),
          'error',
          null,
          true
        )
      )
      setSubmitting(false)
      return
    }
    setFormChanged(false)
    submitDoc(
      documentId,
      formDataRef.current,
      actionId,
      status,
      comment,
      data?.action?.type === 'Initiate' && !appHasStartNew
    )
      .then(resp => {
        if (resp.data.submitDocument === 'submissionsDisabled') {
          alerts.type1(
            i18n._('pagesrunner.action.connot.submit'),
            i18n._('pagesrunner.action.connot.submit.data'),
            'confirm'
          )
          setSubmitting(false)
          setRealCanSubmit(true)
          return
        }
        if (resp.data.submitDocument === 'Invalid Operation') {
          trackAlert(
            alerts.type2(
              i18n._('pagesrunner.action.invalid.operation'),
              'error'
            )
          )
          setSubmitting(false)
          return
        }
        if (status === 'completed' && appId2) {
          alerts.type3(i18n._('pagesrunner.action.completed'), 'success')
          refetch?.()
          return setRedirect('..')
        }
        if (pathname.includes('/document-list') || !appHasStartNew) {
          alerts.type3(i18n._('pagesrunner.action.completed'), 'success')
          return setRedirect('..')
        }
        if (status === 'completed' && !appHasStartNew && !redirect) {
          setRedirect({
            pathname: '/my/submissions',
            state: { fromSubmit: true }
          })
        }
      })
      .catch(handleError)
  }

  /* istanbul ignore next */
  const isSentback = data?.action?.details?.returnMethod === 'Send Back'

  const getPaginationButtons = pages => {
    const prevPage = pages
      .slice(0, pageNum - 1)
      .reverse()
      .find(page => !page.hidden)
    const nextPage = pages.slice(pageNum).find(page => !page.hidden)
    return (
      <div className='flex gap-2'>
        <Link
          className='kp-button-solid w-full'
          data-disabled={!prevPage}
          to={prevPage ? prevPage.href : null}
        >
          <Trans id='pagerunner.action.back' />
        </Link>
        <Link
          className='kp-button-solid w-full'
          data-disabled={!nextPage}
          to={nextPage ? nextPage.href : null}
        >
          <Trans id='pagerunner.action.next' />
        </Link>
      </div>
    )
  }

  const getActionButtons = (validations, options) => {
    if (data?.action?.completed) return null
    const disableButtons = !isEmpty(validations) || submitting
    const sendBackSteps = documentCacheRef.current?.workflow?.sendbackSteps
    const sendbackButtonPopup = hide => (
      <SendbackContent
        submitting={submitting}
        steps={sendBackSteps}
        onClick={sendbackDocument}
        onCancel={hide}
      />
    )
    const sendBackButton = hide => (
      <>
        <Popover2
          role='sub-menu'
          className='-order-1 md:order-1'
          trigger={
            <TooltipTrigger
              as='button'
              className='flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
              disabled={isEmpty(sendBackSteps) || disableButtons}
              tooltipId='noSendback'
            >
              <Icons.Sendback />
              <Trans id='pagerunner.action.send.back' />
            </TooltipTrigger>
          }
          right={96}
          top={width >= 1024 && -4}
          bottom={width < 1024 && -4}
        >
          {() => sendbackButtonPopup(hide)}
        </Popover2>
        {isEmpty(sendBackSteps) && (
          <Tooltip id='noSendback' place='top'>
            <div>
              <Trans id='pagerunner.action.no.valid' />
            </div>
          </Tooltip>
        )}
      </>
    )
    const saveButton = className => (
      <button
        className={cx('kp-button-outline max-lg:w-full', className)}
        disabled={saving || submitting}
        onClick={saveDocument}
      >
        {saving ? `${i18n._('saving')}` : `${i18n._('save')}`}
      </button>
    )
    const shouldShowHistory = moveWorkflowStatus && shouldShowWorkflow
    const moreOptions = additionalButtons =>
      shouldShowHistory || additionalButtons ? (
        <MoreOptionsMenu
          additionalButtons={additionalButtons}
          animateHistoryButton={animateHistoryButton}
          menuRef={menuRef}
          onClick={() => {
            if (!showingHistory) {
              setAnimateFormbot(true)
              setAnimateHistoryButton(false)
            }
            setShowingHistory(!showingHistory)
          }}
          shouldShowHistory={shouldShowHistory}
          width={width}
        />
      ) : null
    switch (data?.action?.type) {
      case 'Initiate': {
        const formSchedule = documentCacheRef.current?.dataset?.formSchedule
        const submissionsDisabled =
          documentCacheRef.current?.dataset?.submissionsDisabled

        const startDate = formSchedule?.startDate
          ? new Date(Number(formSchedule?.startDate))
          : null
        const endDate = formSchedule?.endDate
          ? new Date(Number(formSchedule?.endDate))
          : null

        if (
          submissionsDisabled &&
          data?.action?.details?.returnMethod !== 'Send Back' &&
          !warnedSubmission
        ) {
          alerts.type2(i18n._('pagesrunner.action.disabled'))
          setWarnedSubmission(true)
        } else if (
          formSchedule?.enabled &&
          data?.action?.details?.returnMethod !== 'Send Back' &&
          !warnedSubmission
        ) {
          if (!checkStartAndEndDate(startDate, endDate)) {
            alerts.type2(i18n._('pagesrunner.action.disabled'))
            setWarnedSubmission(true)
          }
        }

        const submittable =
          (!checkCanSubmit({
            startDate,
            endDate,
            enabled: formSchedule?.enabled,
            submissionsDisabled
          }) &&
            data?.action?.details?.returnMethod !== 'Send Back') ||
          realCanSubmit
            ? true
            : disableButtons
        return (
          <>
            {moreOptions()}
            <button
              className='kp-button-solid max-lg:w-full'
              disabled={submittable}
              onClick={() => {
                setSubmitting(true)
                setShowingHistory(false)
                setTimeout(() => {
                  submitDocument('completed')
                }, 1000)
              }}
            >
              {submitting
                ? `${i18n._('pagesrunner.action.submitting')}`
                : `${i18n._('pagesrunner.action.submit')}`}
            </button>
            {saveButton()}
            <button
              className='kp-button-outline max-lg:w-full'
              disabled={submitting}
              onClick={() =>
                isSentback ? withdrawDocument() : discardDocument()
              }
            >
              {isSentback
                ? `${i18n._('pagesrunner.action.withdraw')}`
                : `${i18n._('pagesrunner.action.discard')}`}
            </button>
          </>
        )
      }
      case 'Approval': {
        const denyButtonPopup = hide => (
          <WithCommentContent
            submitting={submitting}
            label={i18n._('pagesrunner.action.deny')}
            onClick={({ comment }) => {
              setSubmitting(true)
              setShowingHistory(false)
              submitDocument('rejected', comment)
            }}
            required
            onCancel={hide}
          />
        )
        const enableDeny = !options?.disableDeny
        const enableSendback = !options?.disableSendback
        return (
          <>
            {(enableDeny || enableSendback || shouldShowWorkflow) &&
              moreOptions(hide => (
                <div className='py-1 dark:bg-light-gray-200'>
                  {enableSendback && sendBackButton(hide)}

                  {enableDeny && (
                    <Popover2
                      role='sub-menu'
                      className='-order-1 md:order-1'
                      trigger={
                        <button
                          className='flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                          disabled={disableButtons}
                        >
                          <Icons.DenyOverride />
                          <Trans id='pagesrunner.action.deny' />
                        </button>
                      }
                      right={96}
                      top={width >= 1024 && -4}
                      bottom={width < 1024 && -4}
                    >
                      {() => denyButtonPopup(hide)}
                    </Popover2>
                  )}
                </div>
              ))}
            <Popover2
              role='dialog'
              className='max-lg:w-full'
              trigger={
                <button
                  className='kp-button-solid max-lg:w-full'
                  disabled={disableButtons}
                >
                  <Trans id='pagesrunner.action.approve' />
                </button>
              }
              bottom={width < 1024 && 54}
            >
              {hide => (
                <WithCommentContent
                  submitting={submitting}
                  label={i18n._('pagesrunner.actions.approve')}
                  onClick={({ comment }) => {
                    setSubmitting(true)
                    setShowingHistory(false)
                    submitDocument('approved', comment)
                  }}
                  onCancel={hide}
                />
              )}
            </Popover2>
            {saveButton('max-lg:pr-0 max-lg:pl-0')}
          </>
        )
      }
      case 'Task': {
        return (
          <>
            {moreOptions(
              hide => !options.disableSendback && sendBackButton(hide)
            )}
            <Popover2
              role='dialog'
              trigger={
                <button className='kp-button-solid' disabled={disableButtons}>
                  <Trans id='pagesrunner.action.mark.complete' />
                </button>
              }
              bottom={width < 1024 && 54}
            >
              {hide => (
                <WithCommentContent
                  submitting={submitting}
                  label={i18n._('pagesrunner.action.complete')}
                  onClick={({ comment }) => {
                    setSubmitting(true)
                    setShowingHistory(false)
                    submitDocument('task completed', comment)
                  }}
                  onCancel={hide}
                />
              )}
            </Popover2>
            {saveButton('max-lg:pr-0 max-lg:pl-0')}
            {!options.disableSendback && sendBackButton}
          </>
        )
      }
      case 'Acknowledge': {
        return (
          <>
            {moreOptions()}
            <Popover2
              className='max-lg:w-full'
              role='dialog'
              trigger={
                <button
                  className='kp-button-solid max-lg:w-full'
                  disabled={disableButtons}
                >
                  <Trans id='acknowledge' />
                </button>
              }
              //
              bottom={width < 1024 && 54}
            >
              {hide => (
                <WithCommentContent
                  submitting={submitting}
                  label={i18n._('acknowledge')}
                  onClick={({ comment }) => {
                    setSubmitting(true)
                    setShowingHistory(false)
                    submitDocument('acknowledged', comment)
                  }}
                  onCancel={hide}
                />
              )}
            </Popover2>
            {saveButton('max-lg:pr-0 max-lg:pl-0')}
          </>
        )
      }
      default: {
        return null
      }
    }
  }
  const isUnauthorized = error?.networkError?.response?.status === 401
  if (isUnauthorized) return <NeedsAuth />
  const notFound =
    !isLoading &&
    !data?.action?.completed &&
    !data?.action?.document &&
    !data?.action?.myRecord
  if (error || notFound) return <NotFound />
  const paginate = data?.action?.document?.dataset?.paginated
  let pages = null
  if (!isLoading && !paginate && rawPageNum) {
    return <Navigate to='.' replace />
  }

  if (discardError) return <CannotDiscard />

  const pageId = data?.action?.details?.datasetId

  const newFormUrl = pageId
    ? `${host}/app/${appId}/page/${pageId}/run`
    : `${host}/app/${appId}/run`
  const currentAction = data?.action || {}
  const { myRecord, type } = currentAction
  const helpLink =
    (type === 'Initiate' && documentCacheRef.current?.app?.helpLink) || null
  if (currentAction.completed || myRecord) {
    const status = currentAction.completed
      ? currentAction.status
      : myRecord.status
    if (
      type === 'Initiate' &&
      documentCacheRef.current?.dataset?.autoStartNewForm &&
      !location.pathname.includes('/document-list') &&
      !appId2
    ) {
      if (status !== 'withdrawn') {
        window.location.assign(newFormUrl)
        alerts.type3(i18n._('pagesrunner.action.form.submitted'), 'info')
      }
    }
    if (
      type === 'Initiate' &&
      currentAction?.status === 'completed' &&
      !appHasStartNew &&
      !redirect
    ) {
      setRedirect({
        pathname: '/my/submissions',
        state: { fromSubmit: true, redirect: true }
      })
    }

    closeTrackedAlerts()
    if (redirect) {
      return <Navigate to={redirect} />
    }
    return (
      <Wrapper>
        <FinishedPage
          status={status}
          branding={data?.action?.branding}
          newFormUrl={
            documentCacheRef.current?.dataset?.showNewFormButton
              ? newFormUrl
              : undefined
          }
        />
      </Wrapper>
    )
  }

  const validations = shouldValidate
    ? validate(documentRef.current, structure)
    : {}
  if (paginate) {
    if ((rawPageNum && pageNum === 1) || pageNum < 1) {
      return <Navigate to='.' replace />
    }
    const maxPage = structure.template?.children?.length ?? 1000
    if (pageNum > maxPage) {
      return <Navigate to={`?page=${maxPage}`} replace />
    }
    pages = map(getPages(document, structure, validations), (page, i) => ({
      index: i,
      errorMsg: page.errorMsg,
      name: page.name,
      hidden: page.hidden,
      format:
        i + 1 < pageNum
          ? 'checked'
          : i + 1 === pageNum
            ? 'selected'
            : 'default',
      href: i === 0 ? '.' : `?page=${i + 1}`
    }))
  }
  const hasWFTroubleshootPerms =
    data?.action?.document?.app?.hasWFTroubleshootPerms
  const pagination = { paginate, pages }
  const simulation = data?.action?.document?.workflow?.simulation
  const workflowResends = data?.action?.document?.meta?.workflowResends
  const shouldShowWorkflow =
    (data?.action?.type !== 'Initiate' ||
      data?.action?.details?.returnMethod === 'Send Back') &&
    simulation
  const workflowToggle =
    shouldShowWorkflow && !moveWorkflowStatus
      ? {
          checked: showingWorkflow,
          onChange: () => setShowingWorkflow(!showingWorkflow)
        }
      : null

  const openHistory =
    shouldShowWorkflow && moveWorkflowStatus
      ? () => {
          menuRef.current.click()
          setAnimateHistoryButton(true)
        }
      : null
  const showSendback = data?.action?.details?.returnMethod === 'Send Back'
  const sendbackData = showSendback
    ? wfUtils.getSendBackData(simulation, data?.action)
    : {}
  const instructions = data?.action?.details?.instructions
  const reassignedBy = data?.action?.details?.reassignedBy
  const reassignedMessage = reassignedBy
    ? `${data?.action?.type} reassigned by ${reassignedBy.displayName}`
    : undefined

  const app = data?.action?.document?.app
  const datasetName =
    app?.type === 'product'
      ? app?.pages?.find(page => page?.id === data?.action?.details?.datasetId)
          ?.label?.value
      : app?.name

  const showVersionDropdown =
    data?.action?.document?.dataset?.allowNewVersions &&
    tenantVersionSettings &&
    data?.action?.document?.versions?.length > 1

  const modalHeader = (
    <PortalOrange>
      <div className='flex flex-1 items-center max-md:flex-wrap'>
        <h1
          title={data?.action?.document?.meta?.title ?? datasetName}
          className='mr-6 max-w-64 truncate text-base font-medium dark:text-white max-md:pb-2'
        >
          {data?.action?.document?.meta?.title ?? datasetName}{' '}
        </h1>
        {showVersionDropdown ? (
          <VersionsDropdown
            document={data?.action?.document}
            onNewVersionLoading={setNewVersionLoading}
            routeIdentifierCallback={version => {
              const loadedVersionId = version?.id
              const foundAction = version?.viewer?.actions?.find(
                ({ id }) => id === actionId
              )
              const theActionsDocumentId = data?.action?.document?.id

              if (foundAction) {
                return '.'
              }
              if (location.pathname.includes('/document-list')) {
                return `../${theActionsDocumentId}/versions/${loadedVersionId}/view`
              }
              return `./versions/${loadedVersionId}/view`
            }}
          />
        ) : (
          <DocumentStatus
            meta={data?.action?.document?.meta}
            simulation={data?.action?.document?.workflow?.simulation}
          />
        )}
      </div>

      {(width > 1024 || !showingHistory) && (
        <div className='small-top-shadow mr-4 flex flex-row-reverse items-center gap-2 max-lg:fixed max-lg:bottom-0 max-lg:left-0 max-lg:w-full max-lg:justify-center max-lg:bg-white max-lg:p-4'>
          {getActionButtons(validations, {
            disableDeny: data?.action?.details?.disableDeny,
            disableSendback: data?.action?.details?.disableSendback,
            shouldShowWorkflow
          })}
        </div>
      )}
    </PortalOrange>
  )

  if (showingWorkflow && !moveWorkflowStatus) {
    return (
      <>
        {modalHeader}
        <FormbotContainer
          helpLink={helpLink}
          workflowToggle={workflowToggle}
          formbot={
            <div ref={scrollRef}>
              {simulation && (
                <WorkflowTracker
                  appId={appId}
                  branding={data?.action?.branding}
                  documentId={documentId}
                  documentQuery={q}
                  hasWFTroubleshootPerms={hasWFTroubleshootPerms}
                  simulation={simulation}
                  workflowResends={workflowResends}
                  dataSetId={data?.action?.details?.datasetId}
                  isTable={data?.action?.document?.dataset?.isTable}
                  firstPageId={data?.action?.document?.app?.firstPageId}
                />
              )}
            </div>
          }
        />
      </>
    )
  }
  if (redirect) {
    closeTrackedAlerts()
    return <Navigate push to={redirect} />
  }
  const formbotProps = {
    animate: animateFormbot,
    hideSidebars: showingHistory,
    openHistory,
    workflowToggle
  }
  return (
    <>
      <Popup
        showModal={showModal}
        title={i18n._('pagesrunner.action.notice')}
        subtitle={i18n._('pagesrunner.action.notice.data')}
        Img={Illustrations.Jennifer}
        width='480'
      >
        <>
          <Checkbox
            label={i18n._('do.not.show.again.for.app')}
            onChange={() => setDoNotShowModal(!doNotShowModal)}
            value={doNotShowModal}
            checked={doNotShowModal}
          />
          <button
            className='kp-button-outline mr-2 mt-4'
            onClick={() => handleModalSubmission(false)}
          >
            <Trans id='cancel' />
          </button>
          <button
            className='kp-button-solid'
            onClick={() => handleModalSubmission(true)}
          >
            <Trans id='pagesrunner.action.continue' />
          </button>
        </>
      </Popup>
      {modalHeader}
      <div className='flex'>
        <div className='flex-grow'>
          <FormbotContainer
            {...formbotProps}
            loading={isLoading}
            helpLink={helpLink}
            paginationButtons={
              !!pagination.paginate && getPaginationButtons(pagination.pages)
            }
            pages={pages}
            workflowToggle={workflowToggle}
            header={
              <>
                {showSendback && (
                  <InfoBox
                    className='!pt-0 pb-6'
                    icon={StepIcon({
                      type: 'Sendback',
                      fillColor: 'white',
                      mr: 0,
                      size: '24px'
                    })}
                    title={
                      i18n._('pagesrunner.action.sent.back.by') +
                      `${sendbackData?.from}`
                    }
                    details={sendbackData?.comment}
                  />
                )}
                {instructions && (
                  <InfoBox
                    className='!pt-0 pb-6'
                    icon={StepIcon({
                      type: 'Instruction',
                      fillColor: 'white',
                      mr: 0,
                      size: '24px'
                    })}
                    title={i18n._('pagesrunner.action.instructions')}
                    details={instructions}
                    backGroundColor='#468DCB'
                  />
                )}
                {reassignedMessage && (
                  <InfoBox
                    className='!pt-0 pb-6'
                    icon={StepIcon({
                      type: 'Instruction',
                      fillColor: 'white',
                      mr: 0,
                      size: '24px'
                    })}
                    title={reassignedMessage}
                    backGroundColor='#468DCB'
                  />
                )}
              </>
            }
            formbot={
              <div ref={scrollRef}>
                {formData && (
                  <AppIdsProvider appId={appId}>
                    <Formbot.Edit
                      className='formbot'
                      document={document}
                      structure={structure}
                      branding={data?.action?.branding}
                      onChange={handleChange}
                      multipageNum={paginate && pageNum}
                      context={{
                        validations,
                        labelSize: data?.action?.document?.dataset?.labelSize,
                        documentMeta: document.meta,
                        currentUser: data?.viewer?.user,
                        actionId,
                        documentId,
                        imageCache,
                        updateImageCache
                      }}
                    />
                  </AppIdsProvider>
                )}
                {isSubmitter && !isPublished && (
                  <FormVersionBar
                    document={data?.action?.document}
                    onRedirect={({ newActionId }) => {
                      navigate(location.pathname.replace(actionId, newActionId))
                    }}
                  />
                )}
              </div>
            }
            width={width}
          />
        </div>
        <DocumentHistory.View
          documentHistory={{
            appId,
            dataSetId: data?.document?.dataset?.id,
            documentId,
            hasWFTroubleshootPerms,
            hasVersions: showVersionDropdown,
            isTable: data?.document?.dataset?.isTable,
            firstPageId: data?.document?.app?.firstPageId,
            simulation,
            workflowResends
          }}
          documentQuery={q}
          hide={() => {
            setAnimateFormbot(true)
            setShowingHistory(false)
          }}
          isVisible={!newVersionLoading && showingHistory}
          width={width}
        />
      </div>
    </>
  )
}

const versionsQuery = `
  versions {
    id
    meta
    viewer {
      actions {
        id
        type
        details
      }
    }
    workflow {
      simulation
    }
  }
`

const getActionQuery = (actionId, actionPage) => ({
  variables: { actionId },
  fetchPolicy: 'no-cache',
  query: gql`
    query ActionPage($actionId: String!) {
      viewer {
        id
        user {
          id
          displayName
          email
        }
      }
      tenant {
        id
        timezone
      }
      action(actionId: $actionId) {
        id
        type
        completed
        details
        consensus
        status
        myRecord
        branding {
          id
          color
          logo
          maxHeight
          alt
        }
        document(keyBy: ID) {
          id
          createdAt
          data
          meta
          integration
          status
          documentSetId
          hasIncompleteVersion
          viewer {
            canCreateVersion
          }
          ${actionPage ? versionsQuery : ''}
          workflow {
            sendbackSteps {
              stepId
              label
            }
            simulation
          }
          form {
            id
            template
            metaFields {
              id
              formKey
              type
              label
              details
            }
            integrationFields {
              id
              formKey
              type
              label
              details
            }
          }
          app {
            id
            name
            helpLink
            hasWFTroubleshootPerms: hasWfTroubleshootPerms
            firstPageId
            type
            pages {
              id
              label {
                value
              }
            }
          }
          dataset {
            id
            showDraft
            allowNewVersions
            formVersion {
              id
            }
            formSchedule {
              enabled
              startDate
              endDate
            }
            autoStartNewForm
            showNewFormButton
            labelSize
            paginated
            submissionsDisabled
            isTable
          }
        }
      }
    }
  `
})

function checkStartAndEndDate (startDate, endDate) {
  const curr = new Date()
  if (startDate != null && curr < startDate) return false
  if (endDate != null && curr > endDate) return false
  return true
}

function checkCanSubmit ({ startDate, endDate, enabled, submissionsDisabled }) {
  if (submissionsDisabled) return false
  if (!enabled) return true
  if (!checkStartAndEndDate(startDate, endDate)) return false
  return true
}
