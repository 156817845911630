/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import FocusTrap from 'focus-trap-react'
import React from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router-dom'

import * as Icons from '../icons'
import { GLaDOS, PortalBlue } from './portals'

export const ModalContext = React.createContext(1)

export function ModalPage ({
  onMouseDown,
  onContextMenu,
  className,
  hidePrintHeader,
  darkTop,
  side,
  width,
  title,
  nav,
  onClick,
  onClose,
  closeAriaLabel,
  children,
  testid
}) {
  const navigate = useNavigate()
  const [ref, setRef] = React.useState(null)
  const depth = React.useContext(ModalContext)
  if (!onClose) onClose = () => navigate('..')
  const alertsEl = document.getElementById('alerts-container')
  const bottomAlertsEl = document.getElementById('bottom-alerts-container')
  const datePickerEl = document.getElementById('datepicker-portal')
  const flumeContextEl = document.getElementById('flume-context-menu')

  return ReactDOM.createPortal(
    <GLaDOS>
      <ModalContext.Provider value={depth + 1}>
        {ref && (
          <FocusTrap
            focusTrapOptions={{ escapeDeactivates: false }}
            containerElements={[
              alertsEl,
              bottomAlertsEl,
              ref,
              datePickerEl,
              flumeContextEl
            ]}
          />
        )}
        <div
          ref={setRef}
          className={cx(
            'kp-modal',
            side ? 'kp-modal-side' : 'kp-modal-full',
            className
          )}
          style={{ '--modal-width': width || '500px' }}
          onKeyUp={event => {
            if (event.key === 'Escape') {
              event.stopPropagation(true)
              event.preventDefault()
              onClose(event)
            }
          }}
        >
          <div
            className={cx(
              'fixed bg-light-gray-100 dark:bg-light-gray-400 max-[500px]:!w-screen print:absolute print:!top-0 print:!min-h-screen',
              side
                ? 'kp-slide-right dark-mode-shadow right-0 w-[500px] shadow-lg shadow-medium-gray-100'
                : 'kp-slide-up left-0 w-screen'
            )}
            style={{
              width,
              top: side ? 0 : depth * 30,
              height: side ? '100dvh' : `calc(100dvh - ${depth * 30}px)`,
              zIndex: side ? 13 + depth : 12 + depth
            }}
            depth={depth}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onContextMenu={onContextMenu}
            data-testid={testid}
          >
            {/* the following style tag can be removed once firefox supports :has */}
            <style>
              {`@media not print{body{overflow:hidden;}}
                @media print{.app-layout{display:none;}}`}
            </style>
            <div
              className={cx(
                'flex items-center justify-between border-b border-b-light-gray-300 pl-6 pr-6 dark:border-b-light-gray-100 dark:bg-light-gray-300 max-[985px]:p-4 max-md:max-h-24 max-md:min-h-16 md:h-16',
                darkTop ? 'bg-light-gray-200' : 'bg-white',
                hidePrintHeader && 'print:hidden'
              )}
            >
              <PortalBlue className='flex w-full items-center justify-between empty:hidden' />
              {title && (
                <h1 className='flex-1 truncate text-base font-medium'>
                  {title}
                </h1>
              )}
              {nav && <div className='flex-1'>{nav}</div>}
              <div className='flex flex-1 justify-end print:hidden'>
                <button
                  className='kp-button-transparent kp-button-icon kp-button-lg text-medium-gray-500 dark:text-medium-gray-300 max-md:absolute max-md:right-2 max-md:top-2 max-md:z-10'
                  onClick={onClose}
                  aria-label={closeAriaLabel || 'close'}
                >
                  <Icons.Close className='h-4 w-4' />
                </button>
              </div>
            </div>
            <div className='h-[calc(100%-64px)] overflow-auto'>{children}</div>
          </div>
          <div
            onClick={onClose}
            data-testid='close-modal'
            className={cx(
              'kp-fade fixed left-0 top-0 h-screen w-screen',
              side
                ? 'bg-transparent opacity-0'
                : 'bg-black opacity-50 dark:bg-light-gray-100'
            )}
            style={{ zIndex: side ? 12 + depth : 11 + depth }}
          />
        </div>
      </ModalContext.Provider>
    </GLaDOS>,
    document.body
  )
}
