/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useMoveAppsToSpaceMutation = () => {
  const [moveApps] = useMutation(moveAppsMutation)
  return (appIds, spaceId, oldSpaceId) =>
    moveApps(getParams(appIds, spaceId, oldSpaceId))
}

const moveAppsMutation = gql`
  mutation MoveAppsToSpace($appIds: [ID!]!, $spaceId: ID!, $oldSpaceId: ID!) {
    data: moveAppsToSpace(appIds: $appIds, spaceId: $spaceId) {
      query {
        space(id: $oldSpaceId) {
          id
          apps {
            id
            name
            createdAt
            createdBy {
              id
              displayName
            }
            tileOptions {
              backgroundColor
              iconName
            }
          }
        }
      }
    }
  }
`

const getParams = (appIds, spaceId, oldSpaceId) => ({
  variables: { appIds, spaceId, oldSpaceId },
  update: cache => {
    appIds.forEach(appId => {
      cache.modify({
        id: `App:${appId}`,
        fields: {
          space: () => ({ __ref: `SpaceInfo:${spaceId}` })
        }
      })
    })
  }
})
