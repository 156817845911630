/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'

import { useAlerts } from '../../../ui/alerts'
import { useQueryContext } from './use-query-context'

export const useUpdateAdvancedIntegrationMutation = id => {
  const alerts = useAlerts()
  const query = useQueryContext()
  const [updateIntegration, result] = useMutation(mutation, {
    refetchQueries: [query],
    onCompleted: () =>
      alerts.type3(i18n._('successfully.updated.integration'), 'success')
  })
  return [(id, args) => updateIntegration({ variables: { id, args } }), result]
}

const mutation = gql`
  mutation UpdateAdvancedIntegration(
    $id: ID!
    $args: UpdateAdvancedIntegrationInput!
  ) {
    updateAdvancedIntegration(id: $id, args: $args) {
      id
      name
      description
      definition
    }
  }
`
