/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export default function Tooltip ({ children, value }) {
  return (
    <div className='group relative'>
      {children}
      <div className='pointer-events-none absolute -top-2 left-[calc(100%+8px)] rounded bg-dark-gray-400 px-5 py-2 text-xs text-white opacity-0 transition-opacity after:absolute after:-left-4 after:h-0 after:w-0 after:border-8 after:border-transparent after:border-r-dark-gray-400 group-hover:opacity-90 dark:text-black'>
        {value}
      </div>
    </div>
  )
}
