/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import localforage from 'localforage'
import shortid from 'shortid'

const defaultView = () => ({ query: '', filters: [], sorts: [], columns: [] })

export async function docListRedirect (insight, value) {
  const storageKey = `query-${insight.datasetId}`
  const existing = (await localforage.getItem(storageKey)) || defaultView()

  existing.filters = insight.filters.map(translateFilter)

  if (insight.details.facetKey && value) {
    existing.filters.push(
      translateFilter({
        value: { values: [value.id || value] },
        formKey: insight.details.facetKey
      })
    )
  }

  if (insight.details.sortField) {
    existing.sorts = [
      {
        id: shortid.generate(),
        ascending: insight.details.sortDirection !== 'asc',
        field: insight.details.sortField || 'meta.serialNumber'
      }
    ]
  }

  if (insight.details.columns) {
    existing.columns = insight.details.columns.map(column => {
      return {
        id: column.formKey,
        label: column.label,
        formKey: column.formKey,
        type: column.type,
        visible: true
      }
    })
  }

  await localforage.setItem(storageKey, existing)
  let url = window.location.pathname
  if (url.startsWith('/dashboard')) {
    url = `/document-list/${insight.datasetId}`
  } else {
    url = url.replace(
      /\/[0-9a-f]+\/dashboard$/,
      `/${insight.datasetId}/document-list`
    )
  }
  window.open(url)
}

const workflowStatuses = [
  'In Progress',
  'Error',
  'Draft',
  'Withdrawn',
  'Rejected',
  'Denied',
  'Complete'
]

function translateFilter (filter) {
  const hasValues = 'values' in filter.value
  const hasWfStatus = workflowStatuses.includes(filter.value.values?.[0])
  const type = hasWfStatus ? 'WorkflowStatus' : hasValues ? 'Radios' : 'Date'
  return { ...filter.value, field: filter.formKey, type }
}

export function formatNumber (num) {
  return new Intl.NumberFormat('en-US').format(num)
}
