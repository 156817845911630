/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get, includes, keyBy, map, without } from 'lodash'
import React from 'react'

import { toDollars } from '../../../../src/pages-builder/calculations/calculation-functions'
import Checkbox from '../../../ui/checkbox'
import { StyledAmountLine } from './view'

export const UI = ({ gadget, onChange, value }) => {
  const values = get(value, 'values') || []
  const options = get(gadget, 'details.options') || []
  return (
    <div>
      <div>Filter</div>
      {map(options, option => (
        <Checkbox
          key={option.id}
          checked={includes(values, option.id)}
          label={<StyledAmountLine {...option} />}
          onChange={() => {
            const newFilter = value || {
              field: gadget.formKey,
              type: 'PaymentOptions',
              values: []
            }
            if (includes(newFilter.values, option.id)) {
              newFilter.values = without(newFilter.values, option.id)
            } else {
              newFilter.values.push(option.id)
            }
            onChange(newFilter.values.length ? newFilter : null)
          }}
        />
      ))}
    </div>
  )
}

export const Pill = ({ label, filter, gadget }) => {
  const options = get(gadget, 'details.options') || []
  const optionMap = keyBy(options, 'id')
  const str = map(filter.values, entry =>
    toDollars(optionMap[entry].amount)
  ).join(', ')
  return (
    <span>
      {label}: {str}
    </span>
  )
}

export const toGraphQL = myFilter =>
  map(myFilter.values, value => ({
    field: `${myFilter.field}.id`,
    type: 'IS',
    value
  }))

export const fromGraphQL = gqlFilters => ({
  field: gqlFilters[0].field.replace('.id', ''),
  type: 'PaymentOptions',
  values: map(gqlFilters, 'value')
})
