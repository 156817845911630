/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useQuery as useOldQuery } from '@apollo/client'
import React from 'react'

export const useQuery = ({ query, ...params }) => {
  const { data, loading, ...rest } = useOldQuery(query, params)
  const [staleData, setStaleData] = React.useState([data || {}, loading])
  React.useEffect(() => {
    setStaleData(oldData => [data && !loading ? data : oldData[0], loading])
  }, [data, loading])
  return { data: staleData[0], loading: staleData[1], ...rest }
}
