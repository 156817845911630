/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M12.69 4.81446C10.3793 3.45513 7.20333 3.64313 3.902 5.31713C3.87064 5.33296 3.83508 5.33852 3.80038 5.33301C3.76568 5.32751 3.73359 5.31122 3.70867 5.28646L1.70467 3.29046C1.56451 3.15127 1.38628 3.0567 1.19244 3.01869C0.998607 2.98068 0.797856 3.00092 0.615513 3.07687C0.433169 3.15282 0.277402 3.28106 0.16786 3.44543C0.0583183 3.6098 -9.10723e-05 3.80293 1.06587e-07 4.00046V11.8671C1.06587e-07 12.0439 0.070238 12.2135 0.195262 12.3385C0.320287 12.4636 0.489856 12.5338 0.666667 12.5338H8.528C8.72585 12.5339 8.91928 12.4753 9.08381 12.3654C9.24834 12.2555 9.37658 12.0993 9.45231 11.9165C9.52803 11.7338 9.54784 11.5326 9.50921 11.3386C9.47059 11.1445 9.37528 10.9663 9.23533 10.8265L7.56867 9.15979C7.55001 9.14134 7.53599 9.11873 7.52777 9.09382C7.51954 9.0689 7.51733 9.04239 7.52133 9.01646C7.52549 8.9905 7.53567 8.96588 7.55107 8.94457C7.56647 8.92327 7.58665 8.90588 7.61 8.89379C9.508 7.92246 11.5147 7.76046 12.9247 8.51713C14.0667 9.12446 14.6667 10.2838 14.6667 11.8658C14.6667 12.0426 14.7369 12.2122 14.8619 12.3372C14.987 12.4622 15.1565 12.5325 15.3333 12.5325C15.5101 12.5325 15.6797 12.4622 15.8047 12.3372C15.9298 12.2122 16 12.0426 16 11.8658C16 8.57579 14.8247 6.07112 12.69 4.81446Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M12.69 4.81446C10.3793 3.45513 7.20333 3.64313 3.902 5.31713C3.87064 5.33296 3.83508 5.33852 3.80038 5.33301C3.76568 5.32751 3.73359 5.31122 3.70867 5.28646L1.70467 3.29046C1.56451 3.15127 1.38628 3.0567 1.19244 3.01869C0.998607 2.98068 0.797856 3.00092 0.615513 3.07687C0.433169 3.15282 0.277402 3.28106 0.16786 3.44543C0.0583183 3.6098 -9.10723e-05 3.80293 1.06587e-07 4.00046V11.8671C1.06587e-07 12.0439 0.070238 12.2135 0.195262 12.3385C0.320287 12.4636 0.489856 12.5338 0.666667 12.5338H8.528C8.72585 12.5339 8.91928 12.4753 9.08381 12.3654C9.24834 12.2555 9.37658 12.0993 9.45231 11.9165C9.52803 11.7338 9.54784 11.5326 9.50921 11.3386C9.47059 11.1445 9.37528 10.9663 9.23533 10.8265L7.56867 9.15979C7.55001 9.14134 7.53599 9.11873 7.52777 9.09382C7.51954 9.0689 7.51733 9.04239 7.52133 9.01646C7.52549 8.9905 7.53567 8.96588 7.55107 8.94457C7.56647 8.92327 7.58665 8.90588 7.61 8.89379C9.508 7.92246 11.5147 7.76046 12.9247 8.51713C14.0667 9.12446 14.6667 10.2838 14.6667 11.8658C14.6667 12.0426 14.7369 12.2122 14.8619 12.3372C14.987 12.4622 15.1565 12.5325 15.3333 12.5325C15.5101 12.5325 15.6797 12.4622 15.8047 12.3372C15.9298 12.2122 16 12.0426 16 11.8658C16 8.57579 14.8247 6.07112 12.69 4.81446Z\"/>\n</svg>";
export default src;
