/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { compact, isArray, isObject, mapValues, reduce, values } from 'lodash'

export function convertTruthyKeys (answers) {
  return isArray(answers)
    ? answers
    : compact(
        values(mapValues(answers, (answer, key) => (answer ? key : null)))
      )
}

export function convertToIdLabel (values, details) {
  const optionsMap = reduce(
    details.options,
    (memo, option) => {
      return Object.assign({}, memo, { [option.key]: option.lbl })
    },
    {}
  )
  if (isArray(values)) {
    return values.map(value => {
      if (isObject(value)) return value
      return { id: value, label: optionsMap[value] }
    })
  }
}
