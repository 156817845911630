/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, find, isObject, map } from 'lodash'
import React from 'react'

import { Option, Select } from '../../../ui/select'

export default function DropdownEdit ({ details, onChange, id, ...props }) {
  const options = filter(details.options, 'key')
  const value = isObject(props.value) ? props.value.id : props.value
  return (
    <Select
      aria-labelledby={props['aria-labelledby']}
      {...(props['aria-describedby'] && {
        'aria-describedby': props['aria-describedby']
      })}
      aria-required={props.required}
      darkerModeDarkerBg={props.darkerModeDarkerBg}
      id={id}
      onChange={val => {
        const item = find(details.options, { key: val })
        onChange(item ? { id: item.key, label: item.lbl } : null)
      }}
      value={value || ''}
    >
      {!details.noEmptyOption && <Option value=''>- - -</Option>}
      {map(options, ({ lbl, key }) => (
        <Option key={key} value={key}>
          {lbl}
        </Option>
      ))}
    </Select>
  )
}
