/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'
import { space, width } from 'styled-system'

const padding = props => {
  if (props.icon) return props.small ? 4 : 8
  return props.small ? 8 : 16
}

const BaseButton = styled.button`
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: ${props => (props.large ? 16 : 14)}px;
  font-weight: 500;
  height: ${props => (props.large ? 40 : props.small ? 24 : 32)}px;
  padding: 0 ${padding}px;
  margin: 0;
  position: relative;
  transition: all 0.1s ease-in-out;
  user-select: none;
  border-radius: 4px;
  outline: none;
  background: none;
  color: #fff;
  border: none;
  &::-moz-focus-inner {
    border: 0;
  }
  [data-whatinput='keyboard'] &:focus::after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    border: 4px solid #8edfee;
    border-radius: 8px;
    z-index: 1;
  }
  &:disabled,
  &[aria-disabled] {
    color: #bbb;
    cursor: default;
  }
  html.dark &:disabled,
  html.dark &[aria-disabled] {
    color: #777;
  }
  ${space}
  ${width}
`

const SolidButton = styled(BaseButton)`
  background: var(--blue-500);
  border: none;
  box-shadow:
    0px 0px 2px rgba(26, 26, 26, 0.14),
    0px 2px 2px rgba(26, 26, 26, 0.12),
    0px 3px 3px rgba(26, 26, 26, 0.2);
  color: var(--white);
  &:hover {
    background: #2f6096;
  }
  html.dark &:hover,
  html.dark &:active {
    background: #406e9f;
  }
  &:active {
    background: #2a5686;
    box-shadow: none;
  }
  &:disabled,
  &[aria-disabled] {
    background: #ccc !important;
    color: #fff;
    box-shadow: none;
  }
  html.dark &:disabled,
  html.dark &[aria-disabled] {
    background: #444 !important;
    border: 1px solid #777;
  }
`

const OutlineButton = styled(BaseButton)`
  background: transparent;
  border: 1px solid #666;
  color: var(--blue-500);
  &[data-active='true'],
  &:hover {
    background: #e5f2f9;
    border-color: #777;
  }
  html.dark &[data-active='true'],
  html.dark &:hover {
    background: #333;
    border-color: #777;
  }
  &:active {
    border-color: #3369a3;
  }
  &:disabled,
  &[aria-disabled] {
    background: transparent;
    border-color: #ccc;
  }
  html.dark &:disabled,
  html.dark &[aria-disabled] {
    background: transparent;
    border-color: #777;
  }
  [data-whatinput='keyboard'] &:focus::after {
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
  }
`

const TransparentButton = styled(BaseButton)`
  background: none;
  border: 1px solid transparent;
  color: #3369a3;
  html.dark & {
    color: #95c4e1;
  }
  background: ${props => (props.imActive ? '#e5f2f9' : 'transparent')};
  html.dark & {
    background: ${props => (props.imActive ? '#333' : 'transparent')};
  }
  &:hover {
    background: #e5f2f9;
  }
  html.dark &:hover {
    background: #444;
  }
  &:active {
    border-color: #ccc;
  }
  &:disabled,
  &[aria-disabled] {
    background: none;
    border-color: transparent;
  }
`

function Button ({ outline, transparent, icon, small, ...rest }) {
  if (rest.disabled) rest['aria-disabled'] = true
  let Component = SolidButton
  if (outline) Component = OutlineButton
  if (transparent) Component = TransparentButton
  if (rest.brandColor) Component = BrandButton
  return (
    <Component
      {...rest}
      icon={icon ? 'true' : undefined}
      small={small ? 'true' : undefined}
    />
  )
}

export default Button

const BrandButtonInner = styled(SolidButton)`
  background: ${p => p.bg};
  &:hover {
    background: ${p => p.bg};
    filter: brightness(0.9);
  }
  &:active {
    background: ${p => p.bg};
    filter: brightness(0.8);
  }
`

function BrandButton ({ brandColor: color, ...props }) {
  return <BrandButtonInner {...props} bg={color} />
}

export const ButtonGroup = styled.div`
  display: flex;
  ${space}

  > :first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }

  > :last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  > :not(:last-child):not(:first-child) {
    border-radius: 0px;
    border-right: none;
  }
`
