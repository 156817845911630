/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13.096 11.2087L9.98402 8.09735C9.96667 8.08016 9.95333 8.05937 9.94494 8.03644C9.93655 8.01351 9.93332 7.98901 9.93549 7.96469C9.93765 7.94036 9.94516 7.91682 9.95746 7.89573C9.96977 7.87464 9.98658 7.85653 10.0067 7.84268L12.0067 6.45601C12.1099 6.38451 12.1908 6.28532 12.2401 6.16986C12.2894 6.0544 12.3051 5.92736 12.2854 5.80338C12.2657 5.67939 12.2113 5.56349 12.1287 5.46901C12.046 5.37453 11.9383 5.30533 11.818 5.26935L3.35269 2.72868C3.26622 2.70295 3.17441 2.70106 3.08696 2.72321C2.9995 2.74537 2.91966 2.79074 2.85587 2.85453C2.79208 2.91832 2.74671 2.99816 2.72456 3.08561C2.7024 3.17307 2.70429 3.26488 2.73002 3.35135L5.27069 11.818C5.30675 11.9382 5.37602 12.0459 5.47053 12.1285C5.56505 12.2111 5.68096 12.2653 5.80494 12.285C5.92892 12.3046 6.05592 12.2888 6.17134 12.2395C6.28676 12.1901 6.3859 12.1092 6.45736 12.006L7.84336 10.006C7.85723 9.98595 7.87533 9.96918 7.89639 9.95688C7.91745 9.94458 7.94095 9.93706 7.96524 9.93483C7.98952 9.93261 8.014 9.93574 8.03695 9.94401C8.05989 9.95227 8.08074 9.96548 8.09802 9.98268L11.21 13.0947C11.335 13.2197 11.5046 13.2899 11.6814 13.2899C11.8581 13.2899 12.0277 13.2197 12.1527 13.0947L13.096 12.1513C13.221 12.0263 13.2912 11.8568 13.2912 11.68C13.2912 11.5032 13.221 11.3337 13.096 11.2087Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.096 11.2087L9.98402 8.09735C9.96667 8.08016 9.95333 8.05937 9.94494 8.03644C9.93655 8.01351 9.93332 7.98901 9.93549 7.96469C9.93765 7.94036 9.94516 7.91682 9.95746 7.89573C9.96977 7.87464 9.98658 7.85653 10.0067 7.84268L12.0067 6.45601C12.1099 6.38451 12.1908 6.28532 12.2401 6.16986C12.2894 6.0544 12.3051 5.92736 12.2854 5.80338C12.2657 5.67939 12.2113 5.56349 12.1287 5.46901C12.046 5.37453 11.9383 5.30533 11.818 5.26935L3.35269 2.72868C3.26622 2.70295 3.17441 2.70106 3.08696 2.72321C2.9995 2.74537 2.91966 2.79074 2.85587 2.85453C2.79208 2.91832 2.74671 2.99816 2.72456 3.08561C2.7024 3.17307 2.70429 3.26488 2.73002 3.35135L5.27069 11.818C5.30675 11.9382 5.37602 12.0459 5.47053 12.1285C5.56505 12.2111 5.68096 12.2653 5.80494 12.285C5.92892 12.3046 6.05592 12.2888 6.17134 12.2395C6.28676 12.1901 6.3859 12.1092 6.45736 12.006L7.84336 10.006C7.85723 9.98595 7.87533 9.96918 7.89639 9.95688C7.91745 9.94458 7.94095 9.93706 7.96524 9.93483C7.98952 9.93261 8.014 9.93574 8.03695 9.94401C8.05989 9.95227 8.08074 9.96548 8.09802 9.98268L11.21 13.0947C11.335 13.2197 11.5046 13.2899 11.6814 13.2899C11.8581 13.2899 12.0277 13.2197 12.1527 13.0947L13.096 12.1513C13.221 12.0263 13.2912 11.8568 13.2912 11.68C13.2912 11.5032 13.221 11.3337 13.096 11.2087Z\" />\n</svg>\n";
export default src;
