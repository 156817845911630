/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, isEmpty, isEqual, map } from 'lodash'
import React from 'react'
import {
  Link,
  Navigate,
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { useImmer } from 'use-immer'

import { moveWorkflowStatus } from '../../components/feature-flags'
import FormVersionBar from '../../components/form-version-bar'
import FormbotContainer from '../../components/formbot-container/formbot-container'
import MoreOptionsMenu from '../../components/formbot-container/more-options-menu'
import { PortalOrange } from '../../components/portals'
import { useTenantFeaturesContext } from '../../components/tenant-features-context'
import { AppIdsProvider } from '../../components/use-app-ids-context'
import { useDocumentTitle } from '../../components/use-document-title'
import { useQuery } from '../../components/use-query'
import { useScrollToTop } from '../../components/use-scroll-to-top'
import { useSingleAlert } from '../../components/use-single-alert'
import { useWindowWidth } from '../../components/use-window-width'
import VersionsDropdown, {
  DocumentStatus
} from '../../components/versions-dropdown'
import Formbot, { getPages, validate } from '../../formbot'
import Forbidden from '../../pages/forbidden'
import NotFound from '../../pages/not-found'
import { useAlerts } from '../../ui/alerts'
import WorkflowTracker from '../action/workflow-tracker'
import * as DocumentHistory from '../document-history'
import { useUpdateDocumentMutation } from './components/mutation.update-document'

export default function RedirectEditForm () {
  const { appId: idOrPublishUrl, documentId } = useParams()
  const q = getRedirectEditFormQuery(idOrPublishUrl)
  const { data } = useQuery(q)

  const realId = data?.app?.id
  if (!realId) return null
  return <Navigate to={`/document-list/${realId}/${documentId}/edit`} replace />
}

const getRedirectEditFormQuery = idOrPublishUrl => ({
  variables: { idOrPublishUrl },
  query: gql`
    query RedirectEditFormPage($idOrPublishUrl: ID!) {
      app: appRedirect(id: $idOrPublishUrl) {
        id
      }
    }
  `
})

export function EditForm () {
  const alerts = useAlerts()
  const singleAlert = useSingleAlert('type2')
  const width = useWindowWidth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { documentId, versionId } = useParams()
  const rawPageNum = searchParams.get('page')
  const pageNum = parseInt(rawPageNum, 10) || 1
  const workingDocumentId = versionId ?? documentId
  const scrollRef = useScrollToTop([pageNum])
  const q = getEditFormQuery(workingDocumentId)
  const { data, loading, error } = useQuery(q)
  const [newVersionLoading, setNewVersionLoading] = React.useState(false)
  useDocumentTitle(data?.document?.app?.name)
  const updateDocument = useUpdateDocumentMutation()
  const notFound = !loading && !data?.document
  const [showingWorkflow, setShowingWorkflow] = React.useState(
    !!searchParams.get('showWf')
  )
  const [showingHistory, setShowingHistory] = React.useState(
    moveWorkflowStatus && showingWorkflow
  )
  const [animateFormbot, setAnimateFormbot] = React.useState(false)
  const [animateHistoryButton, setAnimateHistoryButton] = React.useState(false)
  const menuRef = React.useRef()
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false
  const [saving, setSaving] = React.useState(false)
  const [formData, updateFormData] = useImmer(null)
  const [formChanged, setFormChanged] = React.useState(false)
  const [imageCache, setImageCache] = useImmer({})

  const updateImageCache = (key, val) => {
    setImageCache(draft => {
      draft[key] = val
    })
  }
  const structure = React.useMemo(
    () => ({
      template: cloneDeep(data?.document?.form?.template),
      metaFields: data?.document?.form?.metaFields ?? [],
      integrationFields: data?.document?.form?.integrationFields ?? [],
      trashed: []
    }),
    [data]
  )
  const appId = data?.document?.app?.id
  const document = {
    data: formData,
    meta: data?.document?.meta,
    integration: data?.document?.integration
  }
  const isPublished = data?.document?.status === 'published'

  React.useEffect(() => {
    const actualData = data?.document?.data
    if (actualData) {
      updateFormData(() => actualData)
    }
  }, [data, updateFormData])

  const handleUpgradeRedirect = ({ newDocumentId }) => {
    navigate(location.pathname.replace(documentId, newDocumentId))
  }

  const handleChange = (key, val) => {
    setFormChanged(true)
    return updateFormData(draft => {
      draft[key] = val
    })
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formChanged && currentLocation.pathname !== nextLocation.pathname
  )

  React.useEffect(() => {
    const dataMatch = isEqual(formData, data?.document?.data)
    if (dataMatch) return setFormChanged(false)
    if (blocker.state === 'blocked' && formChanged) {
      singleAlert(
        i18n._('pagesrunner.edit.unsaved.edits'),
        'error',
        close => (
          <button
            className='kp-button kp-button-solid'
            onClick={() => {
              saveDocument()
              close()
            }}
          >
            <Trans id='save' />
          </button>
        ),
        undefined,
        close => (
          <button
            className='kp-button'
            onClick={() => {
              blocker.proceed()
              close()
            }}
          >
            <Trans id='pagesrunner.edit.close' />
          </button>
        )
      )
    }
  }, [blocker, data, formData, formChanged])

  const saveDocument = () => {
    setSaving(true)
    setTimeout(async () => {
      try {
        setFormChanged(false)
        await updateDocument(workingDocumentId, formData)
        setSaving(false)
        alerts.type3(i18n._('pagesrunner.edit.document.saved'), 'success')
        navigate('../view')
      } catch (error) {
        const message =
          error?.graphQLErrors?.[0]?.message ??
          i18n._('pagesrunner.edit.document.error')
        alerts.type3(message, 'error')
        setSaving(false)
      }
    }, 2000)
  }

  const getPaginationButtons = pages => {
    const prevPage = pages
      .slice(0, pageNum - 1)
      .reverse()
      .find(page => !page.hidden)
    const nextPage = pages.slice(pageNum).find(page => !page.hidden)
    const prevHref = prevPage ? prevPage.href : null
    const nextHref = nextPage ? nextPage.href : null
    return (
      <div className='flex gap-2'>
        <Link
          className='kp-button-solid w-full'
          to={prevHref}
          onClick={e => {
            if (!prevPage) e.preventDefault()
          }}
          data-disabled={!prevPage}
        >
          <Trans id='pagesrunner.edit.back' />
        </Link>
        <Link
          className='kp-button-solid w-full'
          to={nextHref}
          data-disabled={!nextPage}
        >
          <Trans id='pagesrunner.edit.next' />
        </Link>
      </div>
    )
  }

  const getActionButtons = validations => {
    if (loading) return null
    return (
      <>
        <button
          className='kp-button-solid max-lg:w-full'
          data-test='submit-approval'
          disabled={!isEmpty(validations) || saving}
          onClick={saveDocument}
        >
          {saving
            ? `${i18n._('saving')}`
            : `${i18n._('pagesrunner.edit.document.apply.edits')}`}
        </button>
      </>
    )
  }
  if (error || notFound) return <NotFound />
  const paginate = data?.document?.dataset?.paginated
  let pages = null
  if (!loading && !paginate && rawPageNum) return <Navigate to='.' replace />
  const validations = formData ? validate(document, structure) : {}
  if (paginate) {
    if ((rawPageNum && pageNum === 1) || pageNum < 1) {
      return <Navigate to='.' replace />
    }
    const maxPage = structure.template?.children?.length ?? 1000
    if (pageNum > maxPage) {
      return <Navigate to={`?page=${maxPage}`} replace />
    }
    pages = map(getPages(document, structure, validations), (page, i) => ({
      index: i,
      errorMsg: page.errorMsg,
      name: page.name,
      hidden: page.hidden,
      format:
        i + 1 < pageNum
          ? 'checked'
          : i + 1 === pageNum
            ? 'selected'
            : 'default',
      href: i === 0 ? location.pathname : `?page=${i + 1}`
    }))
  }

  const hasWFTroubleshootPerms = data?.document?.app?.hasWFTroubleshootPerms
  const pagination = { paginate, pages }
  const simulation = data?.document?.workflow?.simulation
  const workflowResends = data?.document?.meta?.workflowResends
  const hasSimulationSteps = (simulation?.steps || []).length > 1
  const workflowToggle =
    hasSimulationSteps && !moveWorkflowStatus
      ? {
          checked: showingWorkflow,
          onChange: () => setShowingWorkflow(!showingWorkflow)
        }
      : null

  const openHistory =
    hasSimulationSteps && moveWorkflowStatus
      ? () => {
          menuRef.current.click()
          setAnimateHistoryButton(true)
        }
      : null
  if (data?.document && !data.document.viewer.canEdit) {
    return <Forbidden message={i18n._('pagesrunner.edit.document.forbidden')} />
  }

  const titleValue = data?.document?.meta?.title
  const showVersionDropdown =
    data?.document?.dataset?.allowNewVersions &&
    tenantVersionSettings &&
    (data?.document?.viewer?.canCreateVersion ||
      data?.document?.versions?.length > 1)

  const modalHeader = (
    <PortalOrange>
      <div className='flex flex-1 items-center max-md:flex-wrap'>
        <h1
          title={titleValue}
          className='mr-6 max-w-64 truncate text-base font-medium dark:text-white max-md:pb-2'
          data-testid='doc-title'
        >
          {titleValue}
        </h1>
        {showVersionDropdown ? (
          <VersionsDropdown
            document={data?.document}
            onNewVersionLoading={setNewVersionLoading}
            routeIdentifierCallback={version => {
              const initialActionId =
                version?.meta?.simulation?.steps?.[0]?.actionId
              const hasActions = version?.viewer?.actions?.length
              const initialActionRoute = `../../${initialActionId}/action`
              const onVersionRoute = location.pathname.includes('versions')
              const workflowStatus = version?.meta?.workflowStatus

              if (version?.id === documentId && !versionId) {
                return '../view'
              }
              if (version?.id === documentId && onVersionRoute) {
                return '../../view'
              }
              if (version?.id === versionId && onVersionRoute) {
                return '.'
              }
              if (workflowStatus === 'Draft' && hasActions) {
                return initialActionRoute
              }
              if (onVersionRoute) {
                return `../../versions/${version?.id}/view`
              }
              return `../versions/${version?.id}/view`
            }}
          />
        ) : (
          <DocumentStatus
            meta={data?.document?.meta}
            simulation={data?.document?.workflow?.simulation}
          />
        )}
      </div>
      {(width > 1024 || !showingHistory) && (
        <div className='small-top-shadow mr-4 flex items-center gap-2 max-lg:fixed max-lg:bottom-0 max-lg:left-0 max-lg:w-full max-lg:justify-center max-lg:bg-white max-lg:p-4'>
          <Link
            to='../view'
            relative='path'
            className='kp-button-outline max-sm:w-full'
          >
            <Trans id='pagesrunner.edit.discard' />
          </Link>
          {getActionButtons(validations)}
          {moveWorkflowStatus && hasSimulationSteps && (
            <MoreOptionsMenu
              animateHistoryButton={animateHistoryButton}
              menuRef={menuRef}
              onClick={() => {
                if (!showingHistory) {
                  setAnimateFormbot(true)
                  setAnimateHistoryButton(false)
                }
                setShowingHistory(!showingHistory)
              }}
              width={width}
            />
          )}
        </div>
      )}
    </PortalOrange>
  )

  if (showingWorkflow && !moveWorkflowStatus) {
    return (
      <>
        {modalHeader}
        <FormbotContainer
          workflowToggle={workflowToggle}
          formbot={
            <div ref={scrollRef}>
              {hasSimulationSteps && (
                <WorkflowTracker
                  appId={appId}
                  branding={data?.document?.app?.branding}
                  documentId={workingDocumentId}
                  documentQuery={q}
                  hasWFTroubleshootPerms={hasWFTroubleshootPerms}
                  simulation={simulation}
                  workflowResends={workflowResends}
                />
              )}
            </div>
          }
        />
      </>
    )
  }

  const formbotProps = {
    animate: animateFormbot,
    hideSidebars: showingHistory,
    openHistory,
    workflowToggle
  }

  return (
    <>
      {modalHeader}
      <div className='flex'>
        <div className='flex-grow'>
          <FormbotContainer
            {...formbotProps}
            loading={loading || newVersionLoading}
            paginationButtons={
              !!pagination.paginate && getPaginationButtons(pagination.pages)
            }
            pages={pages}
            formbot={
              formData && (
                <AppIdsProvider appId={appId}>
                  <div ref={scrollRef}>
                    <Formbot.Edit
                      className='formbot'
                      document={document}
                      structure={structure}
                      branding={data?.document?.app?.branding}
                      onChange={handleChange}
                      multipageNum={paginate && pageNum}
                      context={{
                        validations,
                        labelSize: data?.document?.dataset?.labelSize,
                        documentMeta: document.meta,
                        documentId: workingDocumentId,
                        imageCache,
                        updateImageCache
                      }}
                    />
                    {!isPublished && (
                      <FormVersionBar
                        document={data?.document}
                        onRedirect={handleUpgradeRedirect}
                      />
                    )}
                  </div>
                </AppIdsProvider>
              )
            }
            width={width}
          />
        </div>
        <DocumentHistory.View
          documentHistory={{
            appId,
            dataSetId: data?.document?.dataset?.id,
            documentId,
            hasWFTroubleshootPerms,
            hasVersions: showVersionDropdown,
            isTable: data?.document?.dataset?.isTable,
            firstPageId: data?.document?.app?.firstPageId,
            simulation,
            workflowResends
          }}
          documentQuery={q}
          hide={() => {
            setAnimateFormbot(true)
            setShowingHistory(false)
          }}
          isVisible={!newVersionLoading && showingHistory}
          width={width}
        />
      </div>
    </>
  )
}

const getEditFormQuery = documentId => ({
  variables: { documentId },
  query: gql`
    query EditFormPage($documentId: ID!) {
      document(id: $documentId, keyBy: ID) {
        id
        documentSetId
        data
        integration
        status
        workflow {
          simulation
        }
        meta
        form {
          id
          template
          metaFields {
            id
            formKey
            label
            type
            details
          }
          integrationFields {
            id
            formKey
            label
            type
            details
          }
        }
        app {
          id
          name
          hasWFTroubleshootPerms: hasWfTroubleshootPerms
          branding {
            id
            color
            logo
            maxHeight
            alt
          }
        }
        dataset {
          id
          paginated
          labelSize
          allowNewVersions
          formVersion {
            id
          }
        }
        viewer {
          canEdit
          canCreateVersion
        }
        versions {
          id
          meta
          viewer {
            actions {
              id
              type
              details
            }
          }
          workflow {
            simulation
          }
        }
        hasIncompleteVersion
      }
    }
  `
})
