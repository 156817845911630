/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import * as Icons from '../../../icons'

const IconObj = {
  Acknowledge: { icon: 'Like', fill: '#9270C0' },
  Approval: { icon: 'CheckCircle', fill: '#49AA30' },
  Notification: { icon: 'Email', fill: '#eFBA4D' },
  Initiate: { icon: 'Sendback', fill: '#666' },
  Sendback: { icon: 'Sendback', fill: '#666' },
  Task: { icon: 'FingerPointing', fill: '#468DCB' },
  Instruction: { icon: 'AlertInfo', fill: '#468DCB' }
}

export default ({ type, fillColor, mr, ml, size }) => {
  const iconDetail = IconObj[type]
  const fill = fillColor ?? iconDetail.fill
  const Component = Icons[iconDetail.icon]
  return size ? (
    <Component fill={fill} ml={ml} mr={mr} height={size} width={size} />
  ) : (
    <Component fill={fill} ml={ml} mr={mr} />
  )
}
