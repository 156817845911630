/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get } from 'lodash'

export const options = purpose =>
  !purpose || purpose === 'form link'
    ? [
        {
          id: 'OVERLAPS',
          label: "overlaps with the other document's",
          exposes: 'sharedGadget-terms',
          transform: (filter, formData) => termsOverlapFilter(filter, formData)
        }
      ]
    : null

function termsOverlapFilter (filter, formData) {
  if (!filter.value) return null
  const termKey = filter.value.split('KUALI_THIS_FORM_DATA.')[1]
  const myEndTermEndDate = get(formData, `${termKey}.endTerm.endDate`)
  const myStartTermStartDate = get(formData, `${termKey}.startTerm.startDate`)

  const myEndToTheirStart = !myEndTermEndDate
    ? null
    : {
        field: 'OR',
        operators: [
          {
            field: `${filter.field}.startTerm.startDate`,
            operators: null,
            type: 'IS_EMPTY',
            value: null
          },
          {
            field: `${filter.field}.startTerm.startDate`,
            operators: null,
            type: 'IS_ON_OR_BEFORE',
            value: `${myEndTermEndDate}`
          }
        ],
        type: 'OR',
        value: 'OR'
      }

  const myStartToTheirEnd = !myStartTermStartDate
    ? null
    : {
        field: 'OR',
        operators: [
          {
            field: `${filter.field}.endTerm.endDate`,
            operators: null,
            type: 'IS_EMPTY',
            value: null
          },
          {
            field: `${filter.field}.endTerm.endDate`,
            operators: null,
            type: 'IS_ON_OR_AFTER',
            value: `${myStartTermStartDate}`
          }
        ],
        type: 'OR',
        value: 'OR'
      }

  const full = {
    field: 'AND',
    operators: [myEndToTheirStart, myStartToTheirEnd],
    type: 'AND',
    value: 'AND'
  }

  if (!myEndTermEndDate && !myStartTermStartDate) return null
  if (myEndTermEndDate && myStartTermStartDate) return full
  return myStartToTheirEnd || myEndToTheirStart
}
