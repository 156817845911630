/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import { Craig, Jennifer } from '../../../illustrations'

export default function EmptyState ({ illustration, message }) {
  return (
    <div>
      {illustration === 'jennifer' ? (
        <JenniferIllustration />
      ) : (
        <CraigIllustration />
      )}

      <Box className='bg-white dark:bg-light-gray-300'>
        <BoxMain>{message}</BoxMain>
      </Box>
    </div>
  )
}

const CraigIllustration = styled(Craig)`
  flex-shrink: 0;
  z-index: 1;
  position: relative;
  margin-right: 15px;
  margin-top: 50px;
`

const JenniferIllustration = styled(Jennifer)`
  flex-shrink: 0;
  z-index: 1;
  position: relative;
  margin-right: 15px;
  margin-top: 50px;
`

const Box = styled.div`
  position: relative;
  z-index: 0;

  border-radius: 10px;
  overflow: hidden;
  padding: 24px 28px;
  width: 300px;
  margin-left: 90px;
  margin-right: 20px;
  font-size: 16px;
  line-height: 24px;
  margin-top: -250px;
`

const BoxMain = styled.div`
  margin-bottom: 0;
`
