/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export const useEscapePress = (handler, when = true) => {
  React.useLayoutEffect(() => {
    if (when) {
      const handle = e => {
        if (e.keyCode === 27) {
          handler(e)
          e.preventDefault()
          e.stopPropagation()
        }
      }
      document.addEventListener('keydown', handle)
      return () => {
        document.removeEventListener('keydown', handle)
      }
    }
  }, [handler, when])
}
