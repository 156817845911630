/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get } from 'lodash'

import { creditsGadget } from '../../../components/feature-flags'
import { RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validation'
import View from './view'

export default {
  defaultValue: null,
  RequiredConfig,
  Edit,
  meta: {
    category: 'Basic',
    Icon,
    label: 'Credits',
    hidden: !creditsGadget
  },
  progressiveDisclosure,
  sortSuffix: '.sortKey',
  validateShape,
  validations: {
    custom: {
      enabled: true,
      value: validateCredits
    }
  },
  View
}

export function validateCredits (value) {
  const errors = []

  switch (value.type) {
    case 'single':
      validateNumber(get(value, 'credits'), errors)
      break
    case 'multiple':
      get(value, 'credits', []).forEach(credits =>
        validateNumber(credits, errors)
      )
      break
    case 'range': {
      const minCredits = get(value, 'credits.min')
      const maxCredits = get(value, 'credits.max')

      validateNumber(minCredits, errors)
      validateNumber(maxCredits, errors)

      if (
        !errors.length &&
        isFinite(minCredits) &&
        isFinite(maxCredits) &&
        minCredits >= maxCredits
      ) {
        errors.push('Min credits must be higher than max credits')
      }
      break
    }
    default:
      errors.push(`Invalid credit type: ${value.type}`)
  }

  return errors.length ? errors : null
}

const validateNumber = (credits, errors) => {
  if (!credits) {
    return
  }

  if (isFinite(credits) && credits < 0) {
    errors.push('Must be positive')
  }

  if (!isFinite(credits)) {
    errors.push('Must be numeric')
  }

  const afterDecimal = credits.toString().split('.')[1]
  if (afterDecimal && afterDecimal.length > 2) {
    errors.push('Must be no more than 2 decimal places')
  }
}
