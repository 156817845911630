/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

import PopoverButton from '../../components/data-table/popover-button'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { useIds } from '../../components/use-ids'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Checkbox from '../../ui/checkbox'
import { ReactComponent as DraftModeShareIllustration } from './illustration-draft-mode-share.svg.jsx'
import { useToggleAnonymousCreateMutation } from './mutation.toggle-anonymous-create'

const dataGadgets = [
  'UserTypeahead',
  'UserMultiselect',
  'GroupTypeahead',
  'GroupMultiselect',
  'DataFill',
  'DataLookup',
  'DataMultiselect',
  'FormTypeahead',
  'IntegrationTypeahead',
  'IntegrationMultiselect',
  'IntegrationFill',
  'DataLink'
]

const Share = ({ query, canAnonymousCreate, getSchema }) => {
  const { appId, datasetId } = useIds()
  const ref = React.useRef()
  const ref2 = React.useRef()
  const toggleAnonymousCreate = useToggleAnonymousCreateMutation(appId, query)
  const alerts = useAlerts()
  const copyClick = e => {
    e.preventDefault()
    ref.current.select()
    if (typeof document.execCommand === 'function') {
      document.execCommand('copy')
      ref.current.blur()
      alerts.type3(i18n._('pagesbuilder.form.share.copied'), 'success')
    }
  }
  const copyClick2 = e => {
    e.preventDefault()
    ref2.current.select()
    if (typeof document.execCommand === 'function') {
      document.execCommand('copy')
      ref.current.blur()
      alerts.type3(
        i18n._('pagesbuilder.form.share.copied.anonymous'),
        'success'
      )
    }
  }
  const anonymousDisabled = !!getSchema().find(
    g => g.formKey.startsWith('data.') && dataGadgets.includes(g.type)
  )
  const basePath = datasetId ? `app/${appId}/page/${datasetId}` : `app/${appId}`
  return (
    <div className='relative w-96'>
      <div className='relative p-4'>
        <div className='sr-only' id='shareFormTitle'>
          <Trans id='pagesbuilder.form.share.form' />
        </div>
        <label htmlFor='share-input' className='mb-1 block text-xs'>
          <Trans id='pagesbuilder.form.sharable.link' />
        </label>
        <div className='flex items-center'>
          <input
            type='text'
            className='kp-input flex-1'
            id='share-input'
            ref={ref}
            value={`${window.location.origin}/${basePath}/run`}
            readOnly
          />
          <button className='kp-button-solid ml-1' onClick={copyClick}>
            <Trans id='pagesbuilder.form.share.copy' />
          </button>
        </div>
        <label
          htmlFor='share-input-anonymous'
          className='mb-1 block pt-4 text-xs'
        >
          <Trans id='pagesbuilder.form.sharable.link.anonymous' />
        </label>
        <div className='flex items-center'>
          <input
            type='text'
            className='kp-input flex-1'
            id='share-input-anonymous'
            disabled={!canAnonymousCreate}
            ref={ref2}
            value={`${window.location.origin}/${basePath}/start`}
            readOnly
          />
          <button
            className='kp-button-solid ml-1'
            onClick={copyClick2}
            disabled={!canAnonymousCreate}
          >
            <Trans id='pagesbuilder.form.share.copy' />
          </button>
        </div>
      </div>
      <div className='relative flex items-center border-t border-light-gray-200 px-4 pb-2 dark:border-light-gray-300'>
        <Checkbox
          label={i18n._('pagesbuilder.form.share.allow.anon')}
          checked={canAnonymousCreate}
          disabled={anonymousDisabled}
          onChange={toggleAnonymousCreate}
        />
        {anonymousDisabled && (
          <TooltipTrigger
            as={Icons.AlertError}
            label={i18n._('pagesbuilder.form.share.anon.disabled')}
            mt={2}
            ml={2}
            fill='#EF6C05'
            tooltipId='anonymous-disabled'
          />
        )}
        {anonymousDisabled && (
          <Tooltip id='anonymous-disabled' place='bottom'>
            <div className='w-32'>
              <Trans id='pagesbuilder.form.share.gadgets' />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

const EmptyShare = () => {
  const { routes } = useIds()
  return (
    <div className='flex w-72 px-8 pb-4 pt-6'>
      <div className='relative pr-6'>
        <div className='pb-4 text-sm font-medium uppercase text-medium-gray-500'>
          <Trans id='pagesbuilder.form.share.draft.mode' />
        </div>
        <div className='pb-6 text-sm'>
          <Trans id='pagesbuilder.form.share.must.publish' />
        </div>
        <Link className='kp-button-solid' to={routes.publish()}>
          <Trans id='pagesbuilder.form.share.publish.options' />
        </Link>
      </div>
      <DraftModeShareIllustration className='shrink-0' />
    </div>
  )
}

export default ({
  transparent,
  small,
  query,
  isPublished,
  canAnonymousCreate,
  getSchema
}) => (
  <PopoverButton
    buttonProps={{ transparent, small }}
    label={
      <>
        <Icons.Share
          className={cx(
            'mr-2',
            transparent ? 'fill-blue-500' : 'fill-white dark:fill-black'
          )}
        />
        <span>
          <Trans id='pagesbuilder.form.share.share.form' />
        </span>
      </>
    }
    aria-labelledby='shareFormTitle'
  >
    {() =>
      isPublished ? (
        <Share
          query={query}
          canAnonymousCreate={canAnonymousCreate}
          getSchema={getSchema}
        />
      ) : (
        <EmptyShare />
      )
    }
  </PopoverButton>
)
