/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM10.3333 11.3333C10.1565 11.3333 9.98696 11.2631 9.86193 11.1381C9.73691 11.013 9.66667 10.8435 9.66667 10.6667C9.66667 10.4899 9.73691 10.3203 9.86193 10.1953C9.98696 10.0702 10.1565 10 10.3333 10C10.5986 10 10.8529 9.89464 11.0404 9.70711C11.228 9.51957 11.3333 9.26522 11.3333 9C11.3333 8.73478 11.228 8.48043 11.0404 8.29289C10.8529 8.10536 10.5986 8 10.3333 8H6.66667C6.62247 8 6.58008 8.01756 6.54882 8.04881C6.51756 8.08007 6.5 8.12246 6.5 8.16667V9.33333C6.49992 9.43216 6.47055 9.52874 6.4156 9.61089C6.36065 9.69303 6.2826 9.75705 6.19129 9.79486C6.09998 9.83267 5.99951 9.84257 5.90258 9.82331C5.80565 9.80405 5.7166 9.7565 5.64667 9.68667L3.64667 7.68667C3.55304 7.59292 3.50044 7.46583 3.50044 7.33333C3.50044 7.20083 3.55304 7.07375 3.64667 6.98L5.64667 4.98C5.7166 4.91016 5.80565 4.86261 5.90258 4.84336C5.99951 4.8241 6.09998 4.834 6.19129 4.87181C6.2826 4.90962 6.36065 4.97363 6.4156 5.05578C6.47055 5.13792 6.49992 5.23451 6.5 5.33333V6.5C6.5 6.5442 6.51756 6.58659 6.54882 6.61785C6.58008 6.64911 6.62247 6.66667 6.66667 6.66667H10.3333C10.9522 6.66667 11.5457 6.9125 11.9833 7.35008C12.4208 7.78767 12.6667 8.38116 12.6667 9C12.6667 9.61884 12.4208 10.2123 11.9833 10.6499C11.5457 11.0875 10.9522 11.3333 10.3333 11.3333Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM10.3333 11.3333C10.1565 11.3333 9.98696 11.2631 9.86193 11.1381C9.73691 11.013 9.66667 10.8435 9.66667 10.6667C9.66667 10.4899 9.73691 10.3203 9.86193 10.1953C9.98696 10.0702 10.1565 10 10.3333 10C10.5986 10 10.8529 9.89464 11.0404 9.70711C11.228 9.51957 11.3333 9.26522 11.3333 9C11.3333 8.73478 11.228 8.48043 11.0404 8.29289C10.8529 8.10536 10.5986 8 10.3333 8H6.66667C6.62247 8 6.58008 8.01756 6.54882 8.04881C6.51756 8.08007 6.5 8.12246 6.5 8.16667V9.33333C6.49992 9.43216 6.47055 9.52874 6.4156 9.61089C6.36065 9.69303 6.2826 9.75705 6.19129 9.79486C6.09998 9.83267 5.99951 9.84257 5.90258 9.82331C5.80565 9.80405 5.7166 9.7565 5.64667 9.68667L3.64667 7.68667C3.55304 7.59292 3.50044 7.46583 3.50044 7.33333C3.50044 7.20083 3.55304 7.07375 3.64667 6.98L5.64667 4.98C5.7166 4.91016 5.80565 4.86261 5.90258 4.84336C5.99951 4.8241 6.09998 4.834 6.19129 4.87181C6.2826 4.90962 6.36065 4.97363 6.4156 5.05578C6.47055 5.13792 6.49992 5.23451 6.5 5.33333V6.5C6.5 6.5442 6.51756 6.58659 6.54882 6.61785C6.58008 6.64911 6.62247 6.66667 6.66667 6.66667H10.3333C10.9522 6.66667 11.5457 6.9125 11.9833 7.35008C12.4208 7.78767 12.6667 8.38116 12.6667 9C12.6667 9.61884 12.4208 10.2123 11.9833 10.6499C11.5457 11.0875 10.9522 11.3333 10.3333 11.3333Z\" />\n</svg>\n";
export default src;
