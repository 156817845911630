/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.3333 10H4.66667C4.48986 10 4.32029 10.0702 4.19526 10.1953C4.07024 10.3203 4 10.4899 4 10.6667C4 10.8435 4.07024 11.013 4.19526 11.1381C4.32029 11.2631 4.48986 11.3333 4.66667 11.3333H11.3333C11.5101 11.3333 11.6797 11.2631 11.8047 11.1381C11.9298 11.013 12 10.8435 12 10.6667C12 10.4899 11.9298 10.3203 11.8047 10.1953C11.6797 10.0702 11.5101 10 11.3333 10Z`}/><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z`}/><path d={`M5.33333 7.33332C6.06971 7.33332 6.66667 6.73637 6.66667 5.99999C6.66667 5.26361 6.06971 4.66666 5.33333 4.66666C4.59695 4.66666 4 5.26361 4 5.99999C4 6.73637 4.59695 7.33332 5.33333 7.33332Z`}/><path d={`M10.6666 7.33332C11.403 7.33332 12 6.73637 12 5.99999C12 5.26361 11.403 4.66666 10.6666 4.66666C9.93027 4.66666 9.33331 5.26361 9.33331 5.99999C9.33331 6.73637 9.93027 7.33332 10.6666 7.33332Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11.3333 10H4.66667C4.48986 10 4.32029 10.0702 4.19526 10.1953C4.07024 10.3203 4 10.4899 4 10.6667C4 10.8435 4.07024 11.013 4.19526 11.1381C4.32029 11.2631 4.48986 11.3333 4.66667 11.3333H11.3333C11.5101 11.3333 11.6797 11.2631 11.8047 11.1381C11.9298 11.013 12 10.8435 12 10.6667C12 10.4899 11.9298 10.3203 11.8047 10.1953C11.6797 10.0702 11.5101 10 11.3333 10Z\" />\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z\" />\n<path d=\"M5.33333 7.33332C6.06971 7.33332 6.66667 6.73637 6.66667 5.99999C6.66667 5.26361 6.06971 4.66666 5.33333 4.66666C4.59695 4.66666 4 5.26361 4 5.99999C4 6.73637 4.59695 7.33332 5.33333 7.33332Z\" />\n<path d=\"M10.6666 7.33332C11.403 7.33332 12 6.73637 12 5.99999C12 5.26361 11.403 4.66666 10.6666 4.66666C9.93027 4.66666 9.33331 5.26361 9.33331 5.99999C9.33331 6.73637 9.93027 7.33332 10.6666 7.33332Z\" />\n</svg>\n";
export default src;
