/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import useDebouncedCallback from '../../components/use-debounced-callback'
import { useAlerts } from '../../ui/alerts'
import { useUpdateAppHelpLink } from './mutation.update-app-help-link'

function isValidHttpUrl (string) {
  try {
    const url = new URL(string)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch {
    return false
  }
}

export default function HelpLinkInput ({ appId, helpLink: initialHelpLink }) {
  const alerts = useAlerts()
  const [helpLink, setHelpLink] = React.useState(initialHelpLink || '')
  const updateAppHelpLink = useUpdateAppHelpLink(appId)
  const updateHelpLink = React.useCallback(
    helpLink => {
      helpLink = helpLink.trim()
      if (helpLink && !isValidHttpUrl(helpLink)) return
      updateAppHelpLink(helpLink || null)
        .then(() =>
          alerts.type3(
            i18n._('pagesbuilder.form.help.settings.saved'),
            'success'
          )
        )
        .catch(() =>
          alerts.type3(i18n._('pagesbuilder.form.help.error.saving'), 'error')
        )
    },
    [alerts, updateAppHelpLink]
  )
  const debouncedUpdateHelpLink = useDebouncedCallback(updateHelpLink)
  const handleChange = React.useCallback(
    value => {
      setHelpLink(value)
      debouncedUpdateHelpLink(value)
    },
    [debouncedUpdateHelpLink]
  )
  const isValid = isValidHttpUrl(helpLink) || !helpLink?.trim()
  return (
    <>
      <label
        className='block pb-1 pt-5 text-xs text-medium-gray-500'
        htmlFor='custom-help-link'
      >
        <Trans id='pagesbuilder.form.help.add.custom' />
      </label>
      <input
        className='kp-input w-full'
        id='custom-help-link'
        type='url'
        value={helpLink || ''}
        onChange={e => handleChange(e.target.value)}
        placeholder={i18n._('pagesbulder.form.help.address')}
      />
      {!isValid && (
        <div className='mb-4 text-xs text-red-400'>
          <Trans id='pagesbuilder.form.help.invalid' />
        </div>
      )}
    </>
  )
}
