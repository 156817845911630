/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { usePageContext } from '../../components/use-page-context'
import AnimatedOutlet from '../app-modals-outlet'

export default function ExternalOuter () {
  return (
    <>
      <AnimatedOutlet />
      <External />
    </>
  )
}

function External () {
  const page = usePageContext()
  if (!page) return null
  const host = window.location.host.replace(/:\d*$/, '')
  const subdomain = host.split('.').slice(0, -2).join('.')
  const src = page.details.src.value
    .replace(/\{\{HOST\}\}/g, host)
    .replace(/\{\{SUBDOMAIN\}\}/g, subdomain)
  const Component = page.type === 'iframe' ? Iframe : JsComponent
  return <Component key={page.id} pageId={page.id} src={src} />
}

function Iframe ({ src }) {
  return (
    <iframe
      className='block h-full w-full'
      id='external'
      title='external'
      src={src}
    />
  )
}

function JsComponent ({ pageId, src }) {
  if (!document.externalApps) document.externalApps = {}
  const [ref, setRef] = React.useState()
  React.useEffect(() => {
    if (!ref) return
    async function load () {
      if (document.externalApps[pageId]) {
        document.externalApps[pageId]?.start?.(ref)
      } else {
        try {
          document.externalApps[pageId] = await import(
            /* webpackIgnore: true */ src
          )
          document.externalApps[pageId]?.start?.(ref)
        } catch (err) {
          console.error(err)
        }
      }
    }
    load()
    return () => document.externalApps[pageId]?.stop?.(ref)
  }, [ref, src, pageId])
  return <div className='h-full w-full' key={pageId} ref={setRef} />
}
