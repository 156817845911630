/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './string-validation'
import View from './view'

export default {
  defaultValue: '',
  Edit,
  meta: {
    category: 'Basic',
    Icon,
    label: 'Rich Text'
  },
  validations: {
    safeTextLength: { enabled: true }
  },
  progressiveDisclosure,
  sampleValue: '<b>Sample Text</b>',
  validateShape,
  View
}
