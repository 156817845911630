/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Loading from '../../../components/loading'
import { ModalPage } from '../../../components/modal-page'
import { GraphQLError as Error } from '../../../components/system-error'
import { useQuery } from '../../../components/use-query'

export default function LogDialog ({ log, onClose }) {
  const { data, loading, error } = useQuery({
    query: gql`
      query SystemAuditLog($id: ID!) {
        auditLog(args: { id: $id }) {
          id
          timestamp
          user {
            id
            displayName
            label
            email
            username
          }
          component
          componentId
          contextualComponents {
            component
            componentId
          }
          eventType
          data
        }
      }
    `,
    variables: { id: log.id }
  })

  const cleanLog = React.useMemo(() => {
    if (!data?.auditLog) return null

    const cleaned = deepPick(
      data.auditLog,
      (value, key, obj) => key !== '__typename'
    )
    if (typeof cleaned.data === 'string') {
      cleaned.data = JSON.parse(cleaned.data)
    }
    return JSON.stringify(cleaned, null, 2)
  }, [data])

  return (
    <ModalPage title={i18n._('log.detail')} onClose={onClose}>
      {!cleanLog || error ? (
        loading ? (
          <Loading />
        ) : (
          <Error error={error} />
        )
      ) : (
        <CodeWrapper className='text-sm'>
          <Code className='bg-light-gray-200'>{cleanLog}</Code>
        </CodeWrapper>
      )}
    </ModalPage>
  )
}

const Code = styled.pre`
  padding: 8px;
  width: 100%;
  min-width: 500px;
  border-radius: 4px;
`

const CodeWrapper = styled.div`
  padding: 0 16px;
  box-sizing: border-box;
`

export function useDialog () {
  const [visible, setVisible] = React.useState(false)
  const show = React.useCallback(() => setVisible(() => true), [])
  const hide = React.useCallback(() => setVisible(() => false), [])
  return [visible, { show, hide }]
}

function deepPick (obj, predicate) {
  if (_.isArray(obj)) {
    return _.reduce(
      obj,
      (newArr, value, index) => {
        if (predicate(value, index, obj)) {
          newArr.push(deepPick(value, predicate))
        }
        return newArr
      },
      []
    )
  }
  if (_.isObject(obj)) {
    return _.reduce(
      obj,
      (newObj, value, key) => {
        if (predicate(value, key, obj)) {
          newObj[key] = deepPick(value, predicate)
        }
        return newObj
      },
      {}
    )
  }
  return obj
}
