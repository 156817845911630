/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { throttle } from 'lodash'
import React from 'react'

export const SMALL = 'small'
export const MEDIUM = 'medium'
export const LARGE = 'large'

export function useWindowWidth () {
  const [width, setWidth] = React.useState(window.innerWidth)
  React.useEffect(() => {
    const handler = throttle(() => setWidth(window.innerWidth), 100)
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])
  return width
}

export function sizeFromWidth (width) {
  if (width < 768) return SMALL
  if (width < 1024) return MEDIUM
  return LARGE
}
