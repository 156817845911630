/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isNaN } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import ConfigBox from '../../ui/config-box'
import Input from '../../ui/input'
import { Flex, Space, Wrapper } from '../../ui/layout'
import { Body1 } from '../../ui/typography'
import * as Errors from './errors'

export const Config = ({ value, updateValue, errors }) => {
  const [enabled, setEnabled] = React.useState(value.voting.rule !== 'first')
  const responsesErrors = Errors.filter(errors, 'consensus-response')
  const approvalsErrors = Errors.filter(errors, 'consensus-approval')
  return (
    <ConfigBox
      configKey='consensusPicker'
      description={
        <div>
          <HelpText>
            <Trans id='by.default.help.text' />
          </HelpText>
          <HelpText>
            <Trans id='enable.this.setting.help.text' />
          </HelpText>
        </div>
      }
      enabled={enabled}
      label={i18n._('advanced.group.response.options')}
      save={() => () => {
        const useAdvanced = !enabled
        setEnabled(useAdvanced)
        updateValue(draft => {
          draft.voting.rule = useAdvanced ? 'percentage' : 'first'
        })
      }}
    >
      <Wrapper px={3}>
        {value.voting.rule === 'percentage' && (
          <>
            <Flex>
              <NumberInput
                value={value.voting.responses}
                onChange={value =>
                  updateValue(draft => {
                    draft.voting.responses = value
                  })
                }
              />
              <Body1>
                <Trans id='percentage.recipients.must.respond' />
              </Body1>
            </Flex>
            <Errors.Config errors={responsesErrors} />
            <Flex>
              <NumberInput
                value={value.voting.value}
                onChange={value =>
                  updateValue(draft => {
                    draft.voting.value = value
                  })
                }
              />
              <Body1>
                <Trans id='percentage.responses.approvals.to.continue' />
              </Body1>
            </Flex>
            <Errors.Config errors={approvalsErrors} />
          </>
        )}
      </Wrapper>
      <Space h='20px' />
    </ConfigBox>
  )
}

const NumberInput = ({ value, onChange }) => (
  <NumberInputWrapper
    type='number'
    value={value === null ? '' : value}
    onChange={value => {
      if (value === '') return onChange(null)
      const number = parseInt(value, 10)
      if (!isNaN(number) && number >= 0 && number <= 100) onChange(number)
    }}
  />
)

const NumberInputWrapper = styled(Input)`
  margin: 8px 0;
  margin-right: 8px;
  width: 64px;
  flex-shrink: 0;
`

export const defaults = () => ({
  voting: {
    rule: 'first',
    value: 100,
    responses: 100
  }
})

const validateResponses = Errors.wrap(
  ({ voting: { rule, value } }) =>
    rule === 'percentage' && value === null ? ['Response % is required'] : [],
  'consensus-response'
)

const validateApprovals = Errors.wrap(
  ({ voting: { rule, responses } }) =>
    rule === 'percentage' && responses === null
      ? ['Approval % is required']
      : [],
  'consensus-approval'
)

const HelpText = styled.p`
  color: white;
`

export const validate = value => [
  ...validateResponses(value),
  ...validateApprovals(value)
]
