/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { includes } from 'lodash'
import React from 'react'

import * as Icons from '../../../icons'
import { Flex } from '../../../ui/layout'
import SelectedItem from './selected-item'

export default ({ template: gadget, context }) => {
  const selected = gadget.id && context?.selected?.id === gadget.id
  const selectedLink = gadget.id && includes(context?.selectedLinks, gadget.id)
  if (!selected && !selectedLink) return {}
  const Icon = gadget.type === 'DataLink' ? Icons.Link : Icons.Anchor
  return {
    label: label => {
      if (!context?.selectedLinks?.length) return label
      return (
        <Flex>
          {label} <Icon fill='#3369a3' ml={1} />
        </Flex>
      )
    },
    component: component => {
      let rootSection = gadget.type === 'Section' && gadget.sectionDepth === 1
      if (!gadget.root) rootSection = false
      return (
        <SelectedItem
          showLinkIcon={context?.selectedLinks?.length}
          secondary={!selected}
          gadget={gadget}
          context={context}
          rootSection={rootSection}
          isA11yDragging={context?.a11yDragItem?.id === gadget.id}
          isCanonicalGadget={!!context.canonicalGadgetsMap[gadget.id]}
        >
          {component}
        </SelectedItem>
      )
    }
  }
}
