/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { range } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

import Radios from '../../../ui/radios'

export default function LinearScaleView ({ id, value, ...props }) {
  if (!props.details) return <div>{value}</div>
  const details = props.details || {}
  const from = parseInt(details.from) || 0
  const to = parseInt(details.to) || 0
  const leftLabel =
    details?.labelEnds?.enabled && (details?.labelEnds?.value?.left ?? '')
  const rightLabel =
    details?.labelEnds?.enabled && (details?.labelEnds?.value?.right ?? '')
  const modifier = from < to ? 1 : -1
  const numbers = range(from, to + modifier)
  const random = shortid.generate()
  return (
    <OverflowWrapper>
      <Wrapper role='group' aria-labelledby={props['aria-labelledby']}>
        {leftLabel && <Label>{leftLabel}</Label>}
        <MyRadios
          options={numbers.map(num => ({ id: num, label: num }))}
          disabled
          value={value}
          id={`${id}${random}`}
          name={`${id}${random}`}
        />
        {rightLabel && <Label>{rightLabel}</Label>}
      </Wrapper>
    </OverflowWrapper>
  )
}

const OverflowWrapper = styled.div`
  overflow: auto;
`

const Wrapper = styled.div`
  display: flex;
  max-width: 0;
`

const Label = styled.div`
  padding: 0 8px;
`

const MyRadios = styled(Radios)`
  flex-direction: row;
  padding-top: 8px;
  > label {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 12px;
    margin: 0 !important;
  }
  > label > input {
    margin: 0;
    margin-bottom: 4px;
  }
`
