/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import { ModalPage } from '../../../components/modal-page'
import * as Icons from '../../../icons'
import { Tab, TabInner, Tabs } from '../../../ui/tabs'
import { useGetUserWithApiKeys } from './:id/api-keys'

export default function UserDetails ({ forCurrentUser }) {
  const ctx = useOutletContext()
  const id = forCurrentUser ? ctx.currentUser?.id ?? 'loading' : null
  const { user, canCreateApiKeys, loading } = useGetUserWithApiKeys(id)
  if (id === 'loading' || loading) return null
  const showApiKeys = user?.active && canCreateApiKeys
  return (
    <ModalPage
      title={
        id ? (
          <Trans id='pages.identity.users.my.account' />
        ) : (
          <Trans id='pages.identity.users.person.details' />
        )
      }
      nav={<Nav id={id} user={user} showApiKeys={showApiKeys} />}
    >
      <Wrapper>
        <Outlet context={{ id }} />
      </Wrapper>
    </ModalPage>
  )
}

function Nav ({ id, user, showApiKeys }) {
  const hasOldNonExpiringKeys = React.useMemo(
    () =>
      user?.apiKeys?.some(key => {
        if (key.expiresAt) return false
        return daysSince(key.createdAt) > 365
      }) ?? false,
    [user?.apiKeys]
  )

  return (
    <Tabs>
      <Tab to='view'>
        <TabInner>
          <Trans id='pages.identity.users.general' />
        </TabInner>
      </Tab>
      {showApiKeys && (
        <Tab to='api-keys'>
          <TabInner>
            {hasOldNonExpiringKeys ? (
              <span title={i18n._('pages.identity.users.non.expiring')}>
                <Trans id='pages.identity.users.api.keys' />{' '}
                <Icons.AlertWarning fill='#f7bd1b' />
              </span>
            ) : (
              <Trans id='pages.identity.users.api.keys' />
            )}
          </TabInner>
        </Tab>
      )}
      <Tab to='history'>
        <TabInner>
          <Trans id='pages.identity.users.history' />
        </TabInner>
      </Tab>
    </Tabs>
  )
}

function daysSince (date) {
  const now = new Date()
  const then = new Date(date)
  const diffMs = now.getTime() - then.getTime()
  return Math.round(diffMs / 1000 / 60 / 60 / 24)
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`
