/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isEqual } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import { VisuallyHidden } from '../../../ui/a11y'
import Radios from '../../../ui/radios'
import { defaultOptions, permittedCreditTypes } from './config'
import CreditsInput from './credits-input'

export default class CreditsEdit extends React.Component {
  constructor (props) {
    super(props)
    this.focusRef = React.createRef()
    this.state = {}
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      this.props.value === nextProps.value &&
      this.props.error === nextProps.error &&
      isEqual(this.props.context, nextProps.context) &&
      isEqual(this.props.details, nextProps.details)
    ) {
      return false
    }
    return true
  }

  componentDidUpdate () {
    if (this.focusRef && this.state.added) {
      this.focusRef.focus()
      this.setState({ added: false })
    }
  }

  render () {
    const { details, id, onChange } = this.props
    const options = get(details, 'options') || defaultOptions
    const checkedOptions = []

    permittedCreditTypes
      .map(c => c.type)
      .forEach(creditType => {
        if (options[creditType]) {
          const option = permittedCreditTypes.find(o => o.type === creditType)

          checkedOptions.push({
            id: option.type, // this is the value
            htmlId: `${id}-${option.type}`,
            ariaLabel: option.label,
            label: this.renderOption(option.type)
          })
        }
      })

    if (checkedOptions.length) {
      return (
        <>
          <Radios
            nowrap
            id={this.props.id}
            pt={1}
            value={this.selectedType()}
            onChange={val => onChange({ type: val })}
            options={checkedOptions}
            {...(this.props['aria-describedby'] && {
              'aria-describedby': this.props['aria-describedby']
            })}
          />
        </>
      )
    } else {
      return null
    }
  }

  selectedType () {
    return (
      get(this.props, 'value.type') || // the value on the document
      get(this.props, 'details.options.selected') || // the value on the form configuration
      defaultOptions.selected // the system default
    )
  }

  renderOption (creditType) {
    switch (creditType) {
      case 'single':
        return this.renderSingle()
      case 'multiple':
        return this.renderMultiple()
      case 'range':
        return this.renderRange()
      default:
        throw new Error(i18n._('invalid.credit.type'))
    }
  }

  renderSingle () {
    return (
      <div>
        <Span>A single credit option</Span>
        {this.selectedType() === 'single' && (
          <StyledCreditInput
            aria-labelledby={this.props['aria-labelledby']}
            aria-required={this.props.required}
            id={`${this.props.id}-input-single`}
            value={get(this.props, 'value.credits')}
            onChange={val => {
              return this.props.onChange({
                type: 'single',
                credits: this.parseValue(val)
              })
            }}
          />
        )}
      </div>
    )
  }

  renderMultiple () {
    const shouldRender = this.selectedType() === 'multiple'

    return (
      <div>
        <Span>
          <Trans id='multiple.credit.options' />
        </Span>
        {shouldRender && (
          <MultipleCreditControls>
            {this.renderMultipleInputs()}
            {this.renderMultipleAdd()}
          </MultipleCreditControls>
        )}
      </div>
    )
  }

  renderMultipleAdd () {
    const creditsList = [...get(this.props, 'value.credits', [])]
    return (
      <TransparentButton
        data-testid='credits-multiple-add'
        onClick={() => {
          creditsList.push('')
          this.setState({ added: true })
          return this.props.onChange({
            type: 'multiple',
            credits: creditsList
          })
        }}
      >
        <Icons.Add fill='rgb(52, 107, 167)' width='12px' mt={1} ml={2} />
        <VisuallyHidden>
          <Span>
            <Trans id='add.another.credit.amount' />
          </Span>
        </VisuallyHidden>
      </TransparentButton>
    )
  }

  renderMultipleInputs () {
    const creditsList = [...get(this.props, 'value.credits', [''])]

    return creditsList.map((credits, index) => (
      <div key={`multiple-${index}`} style={{ position: 'relative' }}>
        <StyledCreditInput
          aria-required={this.props.required}
          id={`${this.props.id}-input-multiple-${index}`}
          data-testid={`credits-multiple-${index}`}
          value={credits}
          ref={el => {
            if (index === creditsList.length - 1) {
              this.focusRef = el
            }
          }}
          onChange={val => {
            creditsList[index] = this.parseValue(val)

            return this.props.onChange({
              type: 'multiple',
              credits: creditsList
            })
          }}
        />
        {creditsList.length > 1 && (
          <RemoveButton
            data-testid={`credits-multiple-${index}-remove`}
            onClick={() => {
              creditsList.splice(index, 1)
              return this.props.onChange({
                type: 'multiple',
                credits: creditsList
              })
            }}
          >
            <Icons.Close fill='rgb(52, 107, 167)' width='12px' />
            <VisuallyHidden>
              <Span>
                <Trans id='remove.credts.amount' values={credits} />
              </Span>
            </VisuallyHidden>
          </RemoveButton>
        )}
      </div>
    ))
  }

  renderRange () {
    return (
      <div>
        <Span>
          <Trans id='variable.credit.options' />
        </Span>
        {this.selectedType() === 'range' && (
          <MinMaxCredits>
            <label htmlFor={`${this.props.id}-input-min`}>
              <Trans id='from' />
            </label>
            <StyledCreditInput
              aria-required={this.props.required}
              id={`${this.props.id}-input-min`}
              data-testid='credits-min'
              value={get(this.props, 'value.credits.min', '')}
              onChange={val => {
                return this.props.onChange(
                  this.buildRange(
                    this.parseValue(val),
                    get(this.props, 'value.credits.max')
                  )
                )
              }}
            />
            <label htmlFor={`${this.props.id}-input-max`}>
              <Trans id='to' />
            </label>
            <StyledCreditInput
              aria-required={this.props.required}
              id={`${this.props.id}-input-max`}
              data-testid='credits-max'
              value={get(this.props, 'value.credits.max', '')}
              onChange={val => {
                return this.props.onChange(
                  this.buildRange(
                    get(this.props, 'value.credits.min'),
                    this.parseValue(val)
                  )
                )
              }}
            />
          </MinMaxCredits>
        )}
      </div>
    )
  }

  parseValue (val) {
    // cast to a float if possible, otherwise pass whatever was entered to likely fail validation
    const floatVal = parseFloat(val)
    return !isNaN(floatVal) ? floatVal : val
  }

  buildRange (min, max) {
    return {
      type: 'range',
      credits: {
        min,
        max
      }
    }
  }
}

const StyledCreditInput = styled(CreditsInput)`
  display: block;
  background: none;
  width: 5rem;
  margin: 10px 5px;
`

const Error = styled.div`
  color: var(--red-500);
  padding-left: 16px;
  padding-bottom: 8px;
`

const MinMaxCredits = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const MultipleCreditControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const TransparentButton = styled.button`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

const RemoveButton = styled(TransparentButton)`
  display: inline;
  position: absolute;
  left: 4rem;
  top: 1.2rem;
`
const Span = styled.span`
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
`
