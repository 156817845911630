/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isString, unescape } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import { Attachment } from '../../../icons'

export default function SimulatedEmail ({
  branding,
  toEmail,
  subject,
  body,
  exportOptions
}) {
  const from = useEmailFrom()
  return (
    <EmailContent>
      <HeaderRow>
        <strong>
          <Trans id='from.colon' />
        </strong>
        {from}
      </HeaderRow>
      <HeaderRow>
        <strong>
          <Trans id='to.colon' />
        </strong>
        {toEmail}
      </HeaderRow>
      <HeaderRow>
        <strong>
          <Trans id='subject.colon' />
        </strong>
        {unescape(subject)}{' '}
        {exportOptions?.length > 0 && (
          <>
            <StyledTooltip id='attachmentInfo' place='top'>
              <div>
                <Trans id='email.has.exported.document.attached' />
              </div>
            </StyledTooltip>
            <TooltipTrigger
              as={Attachment}
              label={i18n._('attachment')}
              tooltipId='attachmentInfo'
            />
          </>
        )}
      </HeaderRow>
      <img
        style={{ marginTop: '15px' }}
        alt={branding.alt}
        height={branding.height}
        width={branding.width}
        src={branding.emailLogo || branding.logo}
      />
      {isString(body) && (
        <EmailBody
          className='prose prose-sm prose-ol:list-inside'
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
      {!isString(body) && <EmailBody>{body}</EmailBody>}
      <EmailFooter>
        <p className='mb-3'>
          <Trans id='email.automatically.generated' />
        </p>
      </EmailFooter>
    </EmailContent>
  )
}

const emailFromSettingsQuery = gql`
  query EmailSettingsQuery {
    tenant {
      id
      features {
        emailSettings {
          sendFrom
          enabled
        }
      }
    }
  }
`

function useEmailFrom (defaultFrom = 'no-reply@mail.kualibuild.com') {
  const { data, loading } = useQuery(emailFromSettingsQuery)
  const { enabled, sendFrom } = data?.tenant?.features?.emailSettings ?? {}
  if (loading) return ''
  return enabled ? sendFrom : defaultFrom
}

const EmailContent = styled.div`
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  padding: 16px;
  min-height: 340px;
`

const HeaderRow = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 16px;
  border-bottom: 1px solid #dddddd;
`

const EmailBody = styled.div`
  p {
    font-size: 14px;
    font-family: Helvetica;
    line-height: 28px;
    color: #444444;
    margin-left: 10px;
  }
  margin: 40px 0;
`

const EmailFooter = styled.div`
  p {
    color: #555555;
    font-size: 14px;
  }
`

const StyledTooltip = styled(Tooltip)`
  width: 300px;
`
