/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

import * as Icons from '../icons'

export function AppIcon ({
  className,
  iconName,
  isProduct,
  isDataset,
  isCreate
}) {
  if (isCreate) {
    return <Icons.Add className='h-10 w-10 fill-white dark:fill-black' />
  }
  if (!iconName) return null
  const abs = iconName.startsWith('http')
  let src = iconName
  if (!abs) src = `${process.env.PUBLIC_URL}/icons/${iconName}`
  if (!abs && isProduct) {
    src = `${process.env.PUBLIC_URL}/icons-product/${iconName}.svg`
  }
  if (!abs && isDataset) {
    src = `${process.env.PUBLIC_URL}/icons-dataset/${iconName}.svg`
  }
  return (
    <img
      className={cx(className, 'aspect-square w-3/4 shrink-0', {
        invert: !abs && !isDataset
      })}
      alt=''
      src={src}
    />
  )
}

export function AppSquare ({ children, className, backgroundColor }) {
  return (
    <div
      className={cx(
        className,
        'flex aspect-square shrink-0 items-center justify-center rounded'
      )}
      style={{ background: backgroundColor || '#000' }}
    >
      {children}
    </div>
  )
}
