/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { BrandingHeader } from '../../../components/branding-header'

export default function FormbotView (props) {
  const {
    className,
    context,
    document,
    Formbot,
    onChange,
    structure,
    noGrid,
    branding
  } = props
  return (
    <div className={className}>
      {branding && <BrandingHeader branding={branding} />}
      {Formbot.render('View', structure, document, {
        context,
        onChange,
        noGrid
      })}
    </div>
  )
}
