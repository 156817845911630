/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { compact, find, get, map, set } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { formbot } from '../../formbot'
import * as Icons from '../../icons'
import Button from '../../ui/button'
import { Flex } from '../../ui/layout'
import { Option, Select } from '../../ui/select'
import * as Errors from './errors'
import FieldSelector from './field-selector'

const fromWorkflow = (rule, gadgets) => {
  const expressions = map(rule.expressions, expr => {
    const formKey = get(expr, 'left.formKey')
    if (!formKey) return {}
    if (!expr.operator) return { formKey, data: {} }
    const { pd } = find(gadgets, { formKey }) || {}
    const obj = pd
      ? pd.fromWorkflowFormat({ right: {}, ...expr })
      : { formKey, data: {} }
    return { ...obj, clientId: shortid.generate() }
  })
  return { ...rule, expressions }
}

const toWorkflow = (rule, gadgets) => {
  const expressions = map(rule.expressions, expr => {
    const { formKey } = expr
    if (!expr.formKey) return {}
    const gadget = find(gadgets, { formKey }) || {}
    if (!gadget.pd) return {}
    const obj = gadget.pd.toWorkflowFormat({ data: {}, ...expr }, gadget)
    if (!get(expr, 'data.type')) delete obj.operator
    if (!get(expr, 'data.value') && get(expr, 'data.value') !== 0) {
      delete obj.right
    }
    return obj
  })
  return { ...rule, expressions: compact(expressions) }
}

export default function Safeguard ({ value, onChange, errors, fieldsAll }) {
  const gadgets = React.useMemo(() => {
    const mapper = ({ label, formKey, details, type }) => {
      const definition = formbot.getGadget(type)
      if (!get(definition, 'progressiveDisclosure')) return null
      return {
        formKey,
        label,
        details,
        pd: definition.progressiveDisclosure,
        type
      }
    }
    return compact(map(fieldsAll, mapper))
  }, [fieldsAll])
  const [curValue, updateCurValue] = useImmer(fromWorkflow(value, gadgets))
  React.useEffect(() => {
    onChange(toWorkflow(curValue, gadgets))
  }, [curValue, gadgets, onChange])
  return (
    <Conditions
      gadgets={gadgets}
      updateValue={updateCurValue}
      errors={errors}
      value={curValue}
    />
  )
}

class Conditions extends React.Component {
  handleRemovePart = i => {
    this.props.updateValue(draft => {
      draft.expressions.splice(i, 1)
    })
  }

  handleAddPart = () => {
    this.props.updateValue(draft => {
      draft.expressions.push({ clientId: shortid.generate() })
    })
  }

  render () {
    const { gadgets, updateValue, errors, value } = this.props
    return (
      <>
        <AnyAll>
          <div>
            <Trans id='route.here' />
          </div>
          <Select
            aria-label='Logical Operator'
            onChange={val =>
              updateValue(draft => {
                draft.logicalOperator = val
              })
            }
            value={value.logicalOperator}
          >
            <Option value='or'>
              <Trans id='when.any.following.true' />
            </Option>
            <Option value='and'>
              <Trans id='when.all.following.true' />
            </Option>
            <Option value='always'>
              <Trans id='always' />
            </Option>
          </Select>
        </AnyAll>
        {value.logicalOperator === 'always'
          ? null
          : map(value.expressions, ({ clientId, formKey, data }, i) => {
              const Chosen = find(gadgets, { formKey })
              return (
                <React.Fragment key={clientId}>
                  <Wrapper role='row'>
                    <FieldSelector
                      aria-label={i18n._('branch.condition')}
                      autoFocus
                      className='min-w-[130px]'
                      defaultValue='- - -'
                      formFields={gadgets}
                      onChange={val =>
                        updateValue(draft => {
                          set(draft, `expressions.${i}.formKey`, val)
                          set(draft, `expressions.${i}.data`, {})
                        })
                      }
                      value={formKey}
                    />
                    {Chosen && (
                      <Chosen.pd.component
                        gadgets={gadgets}
                        value={data || {}}
                        onChange={val =>
                          updateValue(draft => {
                            set(draft, `expressions.${i}.data`, val)
                          })
                        }
                        details={Chosen.details || {}}
                      />
                    )}
                    {value.expressions.length >= 2 && (
                      <Button
                        icon
                        transparent
                        aria-label={i18n._('remove.branch.conditional')}
                        onClick={() => this.handleRemovePart(i)}
                      >
                        <Icons.Delete />
                      </Button>
                    )}
                    {i === value.expressions.length - 1 ? (
                      <Button
                        icon
                        transparent
                        aria-label={i18n._('add.branch.condition')}
                        onClick={this.handleAddPart}
                      >
                        <Icons.Add />
                      </Button>
                    ) : (
                      <Spacer />
                    )}
                  </Wrapper>
                  <Errors.Config
                    errors={errors.filter(error => error.j === i)}
                  />
                </React.Fragment>
              )
            })}
      </>
    )
  }
}

const Wrapper = styled(Flex)`
  margin-top: 8px;
  > :first-child {
    flex: 1;
    margin-right: 8px;
  }
`

const AnyAll = styled(Flex)`
  > :first-child {
    flex-shrink: 0;
  }
  > :last-child {
    flex: 1;
    margin-left: 8px;
  }
`

const Spacer = styled.div`
  width: 34px;
`
