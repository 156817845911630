/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useOutlet } from 'react-router-dom'

export function StaticOutlet ({ context }) {
  const myOutlet = useOutlet(context)
  const [outlet, setOutlet] = React.useState(myOutlet)
  React.useEffect(() => setOutlet(myOutlet), [context])
  return outlet
}

export function StaticOutlet2 ({ context }) {
  const myOutlet = useOutlet(context)
  const ref = React.useRef()
  if (myOutlet) ref.current = myOutlet
  return ref.current
}
