/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useParams } from 'react-router-dom'

import PopoverButton from '../../../components/data-table/popover-button'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'

const Share = ({ params }) => {
  const { appId, pageId, tableId } = useParams()
  const datasetId = tableId || pageId || appId
  const [filterId, setFilterId] = React.useState(null)
  const shareFilter = useShareFilterMutation(datasetId, params)
  React.useEffect(() => {
    if (!filterId) {
      shareFilter().then(resp => setFilterId(resp.data.shareFilter.id))
    }
  }, [filterId, shareFilter])
  const ref = React.useRef()
  const alerts = useAlerts()
  const copyClick = e => {
    e.preventDefault()
    ref.current.select()
    if (typeof document.execCommand === 'function') {
      document.execCommand('copy')
      ref.current.blur()
      alerts.type3(
        i18n._({
          id: 'pagesbuilder.doclist.share.copied',
          message: 'Share Link Copied'
        }),
        'success'
      )
    }
  }
  return (
    <div className='relative w-[470px] p-4 max-[500px]:max-w-full'>
      <div className='sr-only' id='shareSearchesTitle'>
        <Trans id='pagesbuilder.doclist.share.search' message='Share Search' />
      </div>
      <div
        tabIndex={0}
        className='mb-1 block rounded-sm border border-[#fff0a2] bg-[#fff9e8] px-2 py-1 text-sm dark:bg-light-gray-400'
      >
        <Trans
          id='pagesbuilder.doclist.share.people'
          message='Only people who have access to <span>this app</span> and <span>this data</span> can view'
          components={{ span: <span className='font-bold' /> }}
        />
      </div>
      {filterId ? (
        <div className='flex items-center'>
          <input
            type='text'
            className='kp-input flex-1'
            ref={ref}
            data-testid='share-input'
            value={`${window.location.href}?id=${filterId}`}
            readOnly
          />
          <button className='kp-button-solid ml-1' onClick={copyClick}>
            <Trans id='pagesbuilder.doclist.share.copy' message='Copy' />
          </button>
        </div>
      ) : (
        <div>
          <Trans id='pagesbuilder.doclist.share.loading' message='loading' />
        </div>
      )}
    </div>
  )
}

const shareLabel = (
  <>
    <Icons.Share className='mr-2 fill-blue-500' />
    <Trans id='pagesbuilder.doclist.share.share' message='Share' />
  </>
)

export default ({ params }) => (
  <PopoverButton
    label={shareLabel}
    buttonProps={{ transparent: true, small: true }}
    aria-labelledby='shareSearchesTitle'
  >
    {() => <Share params={params} />}
  </PopoverButton>
)

const useShareFilterMutation = (appId, params) => {
  const [shareFilter] = useMutation(mutation)
  return React.useCallback(() => {
    const { query, sort, fields, columns } = params
    return shareFilter({ variables: { appId, query, sort, fields, columns } })
  }, [appId, shareFilter, params])
}

const mutation = gql`
  mutation ShareFilter(
    $appId: ID!
    $sort: [String!]
    $fields: Operator
    $query: String
    $columns: [String!]!
  ) {
    shareFilter(
      args: {
        appId: $appId
        connection: {
          query: $query
          sort: $sort
          fields: $fields
          columns: $columns
        }
      }
    ) {
      id
    }
  }
`
