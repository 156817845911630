/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function Tooltip ({ id, ...props }) {
  React.useEffect(() => {
    if (!id) return
    const escaped = id.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
    const target = document.querySelector(`[data-tip][data-for="${escaped}"]`)
    const handleKeyDown = ({ key }) => {
      if (key === 'Escape' || key === 'esc') {
        ReactTooltip.hide(target)
      }
    }
    if (target) {
      target.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (target) {
        target.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [id])

  return (
    <ReactTooltip
      effect='solid'
      event='mouseenter focus'
      eventOff='mouseleave blur'
      id={id}
      role='tooltip'
      {...props}
    />
  )
}

export function TooltipTrigger ({
  as: TriggerElement = 'span',
  label,
  tooltipId,
  forwardedProps,
  ...otherProps
}) {
  return (
    <TriggerElement
      aria-describedby={tooltipId}
      aria-label={label}
      data-tip
      data-for={tooltipId}
      tabIndex={0}
      {...otherProps}
      {...forwardedProps}
    />
  )
}

export function ConditionalTooltip ({ active, id, label, value, children }) {
  if (!active) return children
  return (
    <>
      <TooltipTrigger tooltipId={id} label={label}>
        {children}
      </TooltipTrigger>
      <Tooltip id={id} place='bottom'>
        {value}
      </Tooltip>
    </>
  )
}
