/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'
import styled from 'styled-components'

import { STATUS_CLASS_MAP, STATUS_LABEL_MAP } from './consts'

const Status = styled.div`
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  color: #121212;
  background-color: #bbbbbb;
  border-radius: 2.7381px;
  text-transform: uppercase;
  display: inline-block;
  padding: 2px 7px;
  white-space: nowrap;

  &.in-progress {
    background-color: #fbee6f;
  }
  &.denied {
    background-color: #f7bd1b;
  }
  &.error {
    background-color: var(--red-400);
    color: #ffffff;
  }
  &.complete {
    background-color: #aadc9f;
  }
  &.draft {
    background-color: #f7bd1b;
  }
  &.withdrawn {
    background-color: pink;
  }
`

export default function WorkflowStatusView ({ value }) {
  if (!value) return null
  const statusClass = STATUS_CLASS_MAP[value] || ''
  const statusLabel = STATUS_LABEL_MAP[value] || value
  return <Status className={statusClass}>{lookupValue(statusLabel)}</Status>
}

function lookupValue (value) {
  switch (value) {
    case 'In Progress':
      return i18n._({ id: 'in.progress', message: 'In Progress' })
    case 'Denied':
      return i18n._({ id: 'denied', message: 'Denied' })
    case 'Error':
      return i18n._({ id: 'error', message: 'Error' })
    case 'Complete':
      return i18n._({ id: 'complete', message: 'Complete' })
    case 'Draft':
      return i18n._({ id: 'draft', message: 'Draft' })
    case 'Withdrawn':
      return i18n._({ id: 'withdrawn', message: 'Withdrawn' })
    default:
      return value
  }
}
