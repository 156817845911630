/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export default function ({ left, top, width, height }) {
  return (
    <div
      className='absolute z-[1] flex items-center justify-center border border-dashed border-[#558dd8] bg-[#e9f8fe] text-center transition-all duration-300 after:text-[10px] after:font-medium after:text-[#558dd8] after:opacity-50 after:content-["Drop_Here"] motion-reduce:transition-none dark:bg-light-gray-200 dark:after:opacity-100'
      style={{ top, left, width, height }}
    />
  )
}
