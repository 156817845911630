/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { includes, omit } from 'lodash'
import React from 'react'

import VoronoiContext from './context'

const mid = (x1, x2) => x1 + (x2 - x1) / 2

const positions = ['inside', 'left', 'right', 'top', 'bottom']
const expected = [
  'id',
  'subscribe',
  'padding',
  'data',
  'ariaLabel', // this is meant to help calculate the drop zone aria-label in the Voronoi component
  ...positions
]

export default function Item (props) {
  const { onRecalculate } = React.useContext(VoronoiContext)
  const ref = React.useRef()
  React.useEffect(
    () => onRecalculate(props.id, update),
    [props.ariaLabel, props.id]
  )

  const update = () => {
    const { id, padding: p, inside, data, ariaLabel } = props
    if (!id || !ref.current) return
    const dimensions = ref.current.getBoundingClientRect()
    const { top, right, bottom, left, width, height } = dimensions
    const points = []
    if (inside) points.push([mid(left, right), mid(top, bottom), 'inside'])
    if (props.left) points.push([left + p, mid(top, bottom), 'left'])
    if (props.right) points.push([right - p, mid(top, bottom), 'right'])
    if (props.top) points.push([mid(left, right), top + p, 'top'])
    if (props.bottom) points.push([mid(left, right), bottom - p, 'bottom'])
    return {
      points,
      dimensions: { top, left, width, height },
      data,
      ariaLabel
    }
  }

  return (
    <div
      ref={ref}
      {...omit(props, expected)}
      className={cx('group/voronoi', props.className, {
        'h-full': !includes(['Column', 'Row'], props.data)
      })}
    />
  )
}
