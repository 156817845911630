/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, find, isEqual, map, reject, uniqBy } from 'lodash'
import React from 'react'
import shortid from 'shortid'

import Checkbox from '../../../ui/checkbox'
import { convertToIdLabel, convertTruthyKeys } from './utils'

export default class CheckboxesEdit extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    if (
      this.props.value === nextProps.value &&
      this.props.error === nextProps.error &&
      isEqual(this.props.context, nextProps.context) &&
      isEqual(this.props.details, nextProps.details)
    ) {
      return false
    }
    return true
  }

  render () {
    const { id, value = [], details, onChange } = this.props
    const myValue = convertToIdLabel(convertTruthyKeys(value), details)
    const options = filter(details.options, 'key')
    const random = shortid.generate()
    return (
      <div
        role='group'
        id={id}
        aria-labelledby={this.props['aria-labelledby']}
        {...(this.props['aria-describedby'] && {
          'aria-describedby': this.props['aria-describedby']
        })}
      >
        {map(options, o => (
          <Checkbox
            key={o.key}
            id={`${o.key}${id}${random}`}
            name={`${id}${random}`}
            checked={!!find(myValue, { id: o.key })}
            onChange={checked =>
              onChange(
                checked
                  ? uniqBy([...myValue, { id: o.key, label: o.lbl }], 'id')
                  : reject(myValue, { id: o.key })
              )
            }
            label={o.lbl}
          />
        ))}
      </div>
    )
  }
}
