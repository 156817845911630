/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

/**
 * @param {import('react').PropsWithChildren<{ direction: 'x' | 'y', height: number }>} param0
 * @returns
 */
export default function Scrollbox ({ ...props }) {
  const [atTop, setAtTop] = React.useState(true)
  const [atBottom, setAtBottom] = React.useState(false)
  const scrollbox = React.useRef(null)
  React.useLayoutEffect(() => {
    const element = scrollbox.current
    function setState ({ scrollHeight, clientHeight, scrollTop }) {
      const scrollTopMax = scrollHeight - clientHeight
      setAtTop(scrollTop === 0)
      setAtBottom(scrollTop >= scrollTopMax)
    }
    const handleScroll = _.throttle(e => setState(e.target), 10)
    element.addEventListener('scroll', handleScroll)
    setState(element)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [scrollbox])
  return (
    <ScrollboxWrapper
      ref={scrollbox}
      {...props}
      atTop={atTop}
      atBottom={atBottom}
    />
  )
}

const ScrollboxWrapper = styled.div`
  ${props =>
    props.direction === 'x'
      ? css`
          overflow-x: auto;
        `
      : css`
          overflow-y: auto;
        `};
  ${props =>
    props.height != null
      ? css`
          max-height: ${p => p.height}px;
          width: 100%;
        `
      : ''}

  &::before,
  &::after {
    content: '';
    display: block;
    position: sticky;
    pointer-events: none;
    height: 1px;
    width: 100%;
    box-shadow: 0px 0px 7px black;
    z-index: 1;
  }

  &::before {
    top: -1px;
    opacity: ${props => (props.atTop ? '0' : '1')};
  }

  &::after {
    bottom: -1px;
    opacity: ${props => (props.atBottom ? '0' : '1')};
  }
`
