/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`} fill={`none`}{...props}><path d={`M5.66126 7.51024L4.95459 10.3389L7.78259 9.63158L12.0253 5.38891L9.90392 3.26758L5.66126 7.51024Z`} stroke={`#3369A3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M14.5007 2.91406L15.2073 3.62073C15.3003 3.7136 15.374 3.82386 15.4243 3.94523C15.4746 4.0666 15.5005 4.19669 15.5005 4.32806C15.5005 4.45944 15.4746 4.58953 15.4243 4.71089C15.374 4.83226 15.3003 4.94253 15.2073 5.0354L13 7.24206`} stroke={`#3369A3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M12.0251 5.38879L14.5005 2.91412C14.6879 2.72659 14.7933 2.47228 14.7933 2.20712C14.7933 1.94196 14.6879 1.68765 14.5005 1.50012L13.7931 0.792786C13.6056 0.605315 13.3513 0.5 13.0861 0.5C12.821 0.5 12.5667 0.605315 12.3791 0.792786L9.90381 3.26679`} stroke={`#3369A3`} strokeLinecap={`round`} strokeLinejoin={`round`}/><path d={`M12.5 9.5V14.5C12.5 14.7652 12.3946 15.0196 12.2071 15.2071C12.0196 15.3946 11.7652 15.5 11.5 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605357 15.0196 0.5 14.7652 0.5 14.5V4.5C0.5 4.23478 0.605357 3.98043 0.792893 3.79289C0.98043 3.60536 1.23478 3.5 1.5 3.5H6.5`} stroke={`#3369A3`} strokeLinecap={`round`} strokeLinejoin={`round`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M5.66126 7.51024L4.95459 10.3389L7.78259 9.63158L12.0253 5.38891L9.90392 3.26758L5.66126 7.51024Z\" stroke=\"#3369A3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M14.5007 2.91406L15.2073 3.62073C15.3003 3.7136 15.374 3.82386 15.4243 3.94523C15.4746 4.0666 15.5005 4.19669 15.5005 4.32806C15.5005 4.45944 15.4746 4.58953 15.4243 4.71089C15.374 4.83226 15.3003 4.94253 15.2073 5.0354L13 7.24206\" stroke=\"#3369A3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M12.0251 5.38879L14.5005 2.91412C14.6879 2.72659 14.7933 2.47228 14.7933 2.20712C14.7933 1.94196 14.6879 1.68765 14.5005 1.50012L13.7931 0.792786C13.6056 0.605315 13.3513 0.5 13.0861 0.5C12.821 0.5 12.5667 0.605315 12.3791 0.792786L9.90381 3.26679\" stroke=\"#3369A3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  <path d=\"M12.5 9.5V14.5C12.5 14.7652 12.3946 15.0196 12.2071 15.2071C12.0196 15.3946 11.7652 15.5 11.5 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605357 15.0196 0.5 14.7652 0.5 14.5V4.5C0.5 4.23478 0.605357 3.98043 0.792893 3.79289C0.98043 3.60536 1.23478 3.5 1.5 3.5H6.5\" stroke=\"#3369A3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n";
export default src;
