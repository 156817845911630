/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

/*
This gadget is never persisted to the server. It is injected into the form
template as it is rendered.

Our gadgets only render their bottom and right borders. The sections that
contain these gadgets are responsible for rendering the top and left borders.

Some gadgets are rendered outside of sections, whether from being placed outside
a section or from being inside a section with hideLabel=true. This gadget will
be injected to render their borders.
*/

export default {
  layout: true,
  View: ({ children }) => children,
  Edit: ({ children }) => children,
  Assembler: ({ gadget }) => (
    <div className='border-l border-t border-light-gray-300'>{gadget}</div>
  ),
  meta: { hidden: true }
}
