/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M11.8 11.892C11.7495 11.7702 11.6641 11.6661 11.5544 11.5929C11.4448 11.5197 11.3159 11.4806 11.184 11.4807H9.16669C9.14478 11.4808 9.12306 11.4765 9.1028 11.4682C9.08254 11.4598 9.06413 11.4475 9.04864 11.432C9.03315 11.4166 9.02087 11.3981 9.01253 11.3779C9.00418 11.3576 8.99993 11.3359 9.00002 11.314V1C9.00002 0.734783 8.89466 0.48043 8.70713 0.292893C8.51959 0.105357 8.26524 0 8.00002 0C7.7348 0 7.48045 0.105357 7.29291 0.292893C7.10538 0.48043 7.00002 0.734783 7.00002 1V11.314C7.00002 11.3582 6.98246 11.4006 6.95121 11.4319C6.91995 11.4631 6.87756 11.4807 6.83335 11.4807H4.81469C4.68285 11.4807 4.55399 11.5198 4.44438 11.5931C4.33478 11.6663 4.24935 11.7704 4.1989 11.8922C4.14846 12.014 4.13525 12.1481 4.16097 12.2774C4.18668 12.4067 4.25015 12.5254 4.34335 12.6187L7.52802 15.804C7.58994 15.866 7.66346 15.9152 7.74439 15.9487C7.82533 15.9823 7.91208 15.9995 7.99969 15.9995C8.0873 15.9995 8.17405 15.9823 8.25498 15.9487C8.33591 15.9152 8.40944 15.866 8.47135 15.804L11.6567 12.6187C11.7498 12.5253 11.813 12.4064 11.8386 12.2771C11.8641 12.1477 11.8507 12.0137 11.8 11.892Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11.8 11.892C11.7495 11.7702 11.6641 11.6661 11.5544 11.5929C11.4448 11.5197 11.3159 11.4806 11.184 11.4807H9.16669C9.14478 11.4808 9.12306 11.4765 9.1028 11.4682C9.08254 11.4598 9.06413 11.4475 9.04864 11.432C9.03315 11.4166 9.02087 11.3981 9.01253 11.3779C9.00418 11.3576 8.99993 11.3359 9.00002 11.314V1C9.00002 0.734783 8.89466 0.48043 8.70713 0.292893C8.51959 0.105357 8.26524 0 8.00002 0C7.7348 0 7.48045 0.105357 7.29291 0.292893C7.10538 0.48043 7.00002 0.734783 7.00002 1V11.314C7.00002 11.3582 6.98246 11.4006 6.95121 11.4319C6.91995 11.4631 6.87756 11.4807 6.83335 11.4807H4.81469C4.68285 11.4807 4.55399 11.5198 4.44438 11.5931C4.33478 11.6663 4.24935 11.7704 4.1989 11.8922C4.14846 12.014 4.13525 12.1481 4.16097 12.2774C4.18668 12.4067 4.25015 12.5254 4.34335 12.6187L7.52802 15.804C7.58994 15.866 7.66346 15.9152 7.74439 15.9487C7.82533 15.9823 7.91208 15.9995 7.99969 15.9995C8.0873 15.9995 8.17405 15.9823 8.25498 15.9487C8.33591 15.9152 8.40944 15.866 8.47135 15.804L11.6567 12.6187C11.7498 12.5253 11.813 12.4064 11.8386 12.2771C11.8641 12.1477 11.8507 12.0137 11.8 11.892Z\" />\n</svg>\n";
export default src;
