/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, max, min, reduce } from 'lodash'

import { CALC_FUNCTIONS } from './calculation-constants'

const { SUM, SUBTRACT, PRODUCT, MIN, MAX, AVG, MEDIAN, MODE, RANGE, COUNT } =
  CALC_FUNCTIONS

const isNumber = val => (val || val === 0) && typeof +val === 'number'

function toNumbers (nums) {
  return nums.map(num => +num)
}

function undecimal (nums) {
  const numDecimals = max(nums.map(countDecimals))
  const factor = 10 ** numDecimals
  return [nums.map(num => Math.round(num * factor)), factor]
}

function countDecimals (num) {
  const stringNum = `${num}`
  if (stringNum.includes('e')) return 0
  if (!stringNum.includes('.')) return 0
  const [, decimal] = `${num}`.split('.')
  return decimal.length
}

module.exports = {
  none: () => '',

  // Functions

  [SUM]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    if (!validNums.length) return null
    const [vals, factor] = undecimal(validNums)
    const num = reduce(vals, (a, b) => a + b)
    return num / factor
  },
  [SUBTRACT]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    if (!validNums.length) return null
    const [vals, factor] = undecimal(validNums)
    const num = reduce(vals, (a, b) => a - b)
    return num / factor
  },
  [PRODUCT]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    if (!validNums.length) return null
    const [vals, factor] = undecimal(validNums)
    const num = reduce(vals, (a, b) => a * b)
    return num / factor ** vals.length
  },
  [MIN]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    return min(toNumbers(validNums))
  },
  [MAX]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    return max(toNumbers(validNums))
  },
  [AVG]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    if (!validNums.length) return null
    const [vals, factor] = undecimal(validNums)
    const sum = reduce(vals, (a, b) => a + b)
    return sum / vals.length / factor
  },
  [MEDIAN]: (nums = []) => {
    const filtered = nums.filter(Boolean)
    if (!filtered.length) return null
    const sorted = Array.from(filtered).sort((a, b) => a - b)
    const middle = Math.floor(sorted.length / 2)

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2
    }

    return sorted[middle]
  },
  [MODE]: (nums = []) => {},
  [RANGE]: (nums = []) => {
    nums.sort()
    return nums[0] + '-' + nums[nums.length - 1]
  },
  [COUNT]: (nums = []) => {
    const validNums = filter(nums, isNumber)
    if (!validNums.length) return null
    return validNums.length
  },

  // todo :: create format file

  // Format

  toDollars: num => {
    if (!num && num !== 0) return null
    num = num / 100

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(num)
  },

  toPercent: num => {
    if (!num && num !== 0) return null

    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2
    }).format(num)
  }
}
