import { useEffect, useState } from 'react'

// Checks if the user is in print mode

export default function usePrintMode () {
  const [isPrinting, setIsPrinting] = useState(() => {
    return window.matchMedia('print').matches
  })

  useEffect(() => {
    const printMediaQuery = window.matchMedia('print')
    const handleChange = e => {
      setIsPrinting(e.matches)
    }
    printMediaQuery.addEventListener('change', handleChange)
    return () => printMediaQuery.removeEventListener('change', handleChange)
  }, [])

  return isPrinting
}
