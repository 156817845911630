/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6148 0.792631C15.4159 0.649994 15.1684 0.59223 14.9268 0.632045C14.6853 0.67186 14.4695 0.805993 14.3268 1.00494L5.68991 13.0468L1.57607 8.93046C1.49037 8.84472 1.38862 8.77669 1.27663 8.73027C1.16464 8.68386 1.0446 8.65995 0.923371 8.65992C0.80214 8.65989 0.682092 8.68374 0.570079 8.73011C0.458066 8.77647 0.356282 8.84445 0.270539 8.93015C0.184796 9.01585 0.116773 9.11761 0.0703541 9.2296C0.0239349 9.34159 2.85959e-05 9.46163 2.56383e-08 9.58286C-2.85446e-05 9.70409 0.0238212 9.82413 0.0701876 9.93615C0.116554 10.0482 0.184529 10.1499 0.270231 10.2357L5.1533 15.1181C5.24919 15.2104 5.3636 15.2812 5.48895 15.3259C5.6143 15.3706 5.74771 15.3881 5.88035 15.3773C6.01298 15.3664 6.14179 15.3275 6.25824 15.2631C6.37468 15.1987 6.4761 15.1103 6.55576 15.0037L15.8271 2.08063C15.9698 1.88167 16.0275 1.63421 15.9877 1.39266C15.9479 1.15112 15.8138 0.935282 15.6148 0.792631Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6148 0.792631C15.4159 0.649994 15.1684 0.59223 14.9268 0.632045C14.6853 0.67186 14.4695 0.805993 14.3268 1.00494L5.68991 13.0468L1.57607 8.93046C1.49037 8.84472 1.38862 8.77669 1.27663 8.73027C1.16464 8.68386 1.0446 8.65995 0.923371 8.65992C0.80214 8.65989 0.682092 8.68374 0.570079 8.73011C0.458066 8.77647 0.356282 8.84445 0.270539 8.93015C0.184796 9.01585 0.116773 9.11761 0.0703541 9.2296C0.0239349 9.34159 2.85959e-05 9.46163 2.56383e-08 9.58286C-2.85446e-05 9.70409 0.0238212 9.82413 0.0701876 9.93615C0.116554 10.0482 0.184529 10.1499 0.270231 10.2357L5.1533 15.1181C5.24919 15.2104 5.3636 15.2812 5.48895 15.3259C5.6143 15.3706 5.74771 15.3881 5.88035 15.3773C6.01298 15.3664 6.14179 15.3275 6.25824 15.2631C6.37468 15.1987 6.4761 15.1103 6.55576 15.0037L15.8271 2.08063C15.9698 1.88167 16.0275 1.63421 15.9877 1.39266C15.9479 1.15112 15.8138 0.935282 15.6148 0.792631Z\" />\n</svg>\n";
export default src;
