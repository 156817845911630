import React from 'react'

export function BrandingHeader ({ branding }) {
  const maxHeight = (branding?.maxHeight > 80 ? 80 : branding?.maxHeight) ?? 80
  return (
    <div
      style={{ backgroundColor: branding?.color }}
      className='relative -top-3 -mx-6 flex h-20 items-center justify-center print:hidden'
    >
      <img
        src={branding?.logo}
        style={{ maxHeight }}
        alt={branding?.alt || ''}
      />
    </div>
  )
}
