/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import * as Icons from '../../icons'
import { StatefulDismissable } from '../fyi'

export default ({ openHistory, width }) => {
  return (
    <StatefulDismissable messageKey='change-message' maxClose={3}>
      {dismiss => (
        <div
          className={cx(
            'flex flex-col gap-4 bg-dark-gray-200 p-4 text-white',
            width <= 1024 ? '' : 'mr-[15px] w-[205px]'
          )}
        >
          <div className='flex flex-col gap-2'>
            <div className='flex'>
              <span className='bg-orange-300 px-2 py-1 text-xs font-medium uppercase text-dark-gray-500'>
                <Trans id='change' />
              </span>
              <div className='flex flex-1 justify-end print:hidden'>
                <button
                  className='kp-button-transparent kp-button-icon kp-button-lg m-0 h-4 p-0 text-medium-gray-200'
                  onClick={dismiss}
                  aria-label={i18n._('close')}
                >
                  <Icons.Close className='h-4 w-4' />
                </button>
              </div>
            </div>
            <div className='font-medium'>
              <Trans id='workflow.history.moved' />
            </div>
          </div>
          <div>
            <Trans id='workflow.history.moved.details.a' />{' '}
            <span>
              <Icons.MenuVertical className='inline fill-white' />
            </span>{' '}
            <Trans id='workflow.history.moved.details.b' />
          </div>
          <div className='self-end'>
            <button
              aria-label={`${i18n._('workflow.history.moved')} ${i18n._(
                'show.me'
              )}`}
              className='rounded bg-blue-500 px-4 py-2'
              onClick={openHistory}
            >
              <span>
                <Icons.Visible className='mr-2 inline fill-white' />
              </span>
              <span>
                <Trans id='show.me' />
              </span>
            </button>
          </div>
        </div>
      )}
    </StatefulDismissable>
  )
}
