/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { subFields } from '../../integration-utils'
import { OptionalConfig, RequiredConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validation'
import View from './view'

export default {
  getAssembler: ({ Basic }, _, details, context) =>
    details?.headless && !context.configMode ? HeadlessAssembler : Basic,
  RequiredConfig,
  OptionalConfig,
  defaultValue: null,
  Edit,
  sortSuffix: '.label',
  meta: {
    label: 'Data Lookup (Integration)',
    Icon,
    hidden: true
  },
  validations: {
    integrationFields: {
      enabled: true
    }
  },
  sampleValue: null,
  subFields,
  progressiveDisclosure,
  validateShape,
  View
}

const HeadlessAssembler = ({ gadget }) => gadget
