/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useRemovePageMutation = (appId, pageId) => {
  const [removePage] = useMutation(mutation)
  return () => removePage(getParams(appId, pageId))
}

const mutation = gql`
  mutation RemoveAppPage($appId: ID!, $pageId: ID!) {
    removePage(appId: $appId, pageId: $pageId) {
      id
      pages {
        id
      }
      maintenanceTables {
        id
      }
    }
  }
`

const getParams = (appId, pageId) => ({
  variables: { appId, pageId }
})
