/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { omit } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useAlerts } from '../../../ui/alerts'
import { useSignFileUploadMutation } from '../file-upload/parts/mutation.sign-file-upload'
import {
  InvalidFileType,
  useUploadFile
} from '../file-upload/parts/upload-file'
import DisplayImage from './parts/display-image'

export default function ImageUploadEdit ({
  context,
  formKey,
  details,
  onChange,
  value,
  ...props
}) {
  const alerts = useAlerts()
  const cacheKey = 'rowId' in details ? `${details.rowId}.${formKey}` : formKey
  const [imagePreview, setImagePreview] = React.useState(
    context?.imageCache?.[`${cacheKey}-thumb`]
  )
  const updateImagePreview = imagePreview => {
    setImagePreview(imagePreview)
    if (context?.updateImageCache) {
      context.updateImageCache(`${cacheKey}-thumb`, imagePreview)
    }
  }
  const [imagePreviewZoomed, setImagePreviewZoomed] = React.useState(
    context?.imageCache?.[`${cacheKey}-zoomed`]
  )
  const updateImagePreviewZoomed = imagePreviewZ => {
    setImagePreviewZoomed(imagePreviewZ)
    if (context?.updateImageCache) {
      context.updateImageCache(`${cacheKey}-zoomed`, imagePreviewZ)
    }
  }
  const [uploadType, setUploadType] = React.useState('original')
  const [uploading, setUploading] = React.useState(false)
  const [uploads, updateUploads] = useImmer({})
  if (Object.keys(uploads).length === 3) {
    updateUploads(() => {
      return {}
    })
    setUploadType('original')
    onChange({
      filename: uploads.original.filename,
      date: new Date(),
      original: uploads.original,
      medium: uploads.medium,
      thumbnail: uploads.thumbnail
    })
    setUploading(false)
  }
  const accepts = 'image/*'
  const uploadMutation = useSignFileUploadMutation()
  const {
    progress,
    error,
    handleChange: handleFileUpload,
    raiseError,
    checkForBadFileType
  } = useUploadFile(
    async file => {
      if (!file) return
      updateUploads(draft => {
        draft[file.imageType] = omit(file, ['imageType'])
      })
    },
    accepts,
    uploadMutation
  )

  if (value) {
    return (
      <Wrapper>
        <DisplayImage
          fieldLabel={props.a11yDesc}
          imagePreview={imagePreview}
          imagePreviewZoomed={imagePreviewZoomed}
          onDelete={() => onChange()}
          value={value}
        />
      </Wrapper>
    )
  }
  return (
    <Wrapper
      onKeyUp={e => {
        if (e.key === 'Escape') e.stopPropagation()
      }}
    >
      <div className='relative flex h-48 w-full max-w-md cursor-pointer items-center justify-center rounded-lg border border-dashed border-medium-gray-100 bg-light-gray-200'>
        <input
          type='file'
          disabled={uploading || context?.configMode}
          className='absolute inset-0 h-full w-full cursor-pointer opacity-0'
          accept={accepts}
          aria-labelledby={props['aria-labelledby']}
          {...(props['aria-describedby'] && {
            'aria-describedby': props['aria-describedby']
          })}
          id={cacheKey}
          onChange={async e => {
            try {
              setUploading(true)
              const original = e.target.files[0]
              const name = original?.name
              const type = original?.type
              checkForBadFileType(type)
              const e2 = await loadFile(original)
              const img = await loadImage(e2.target.result)
              original.height = img.height
              original.width = img.width
              original.imageType = 'original'
              await handleFileUpload(original)
              const medium = await resizeImage(img, { maxSize: 640, type })
              const e3 = await loadFile(medium)
              updateImagePreviewZoomed(e3.target.result)
              const mediumImg = await loadImage(e3.target.result)
              medium.height = mediumImg.height
              medium.width = mediumImg.width
              medium.imageType = 'medium'
              medium.name = `medium-${name}`
              setUploadType(medium.imageType)
              await handleFileUpload(medium)
              const thumbnail = await resizeImage(img, { maxSize: 40, type })
              const e4 = await loadFile(thumbnail)
              updateImagePreview(e4.target.result)
              thumbnail.imageType = 'thumbnail'
              thumbnail.name = `thumbnail-${name}`
              setUploadType(thumbnail.imageType)
              await handleFileUpload(thumbnail)
            } catch (error) {
              setUploading(false)
              if (error instanceof ImageResizeError) {
                if (error.type.includes('svg')) {
                  return alerts.type1(
                    i18n._('unable.to.upload.image'),
                    i18n._('unable.to.upload.image.different.browser'),
                    'error'
                  )
                }
              }
              if (error instanceof InvalidFileType) {
                return alerts.type1(
                  i18n._('unable.to.upload.image'),
                  i18n._({
                    id: 'invalid.file.type',
                    message: 'Invalid file type'
                  }),
                  'error'
                )
              }
              Sentry.captureException(error)
              raiseError(new Error(i18n._('error.uploading.image')))
            }
          }}
        />

        {uploading ? (
          <div className='flex-row items-center justify-center'>
            <label
              id='image-upload-label'
              className='px-4 text-medium-gray-500'
            >
              {i18n._('uploading.typeof.image', {
                uploadType,
                progress: progress || 0
              })}
            </label>
            <div
              className='mt-4 h-4 rounded-lg bg-wintergreen-300'
              style={{ width: `${progress}%` }}
            />
          </div>
        ) : (
          <label id='image-upload-label' className='text-medium-gray-500'>
            <Trans id='image.upload.instructions' />
          </label>
        )}
      </div>
      {error && (
        <div className='text-red-500'>
          <Trans id='error.uploading.colon' /> {error.message}
        </div>
      )}
    </Wrapper>
  )
}

function loadFile (data) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = resolve
    reader.readAsDataURL(data)
  })
}

function loadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

class ImageResizeError extends Error {
  constructor (type) {
    super('Error resizing image')
    this.type = type
  }
}

const resizeImage = (image, opts) => {
  const { maxSize, type } = opts
  const { height, width } = image
  let newHeight = height
  let newWidth = width
  if (width > height) {
    if (width > maxSize) {
      const ratio = maxSize / width
      newHeight = height * ratio
      newWidth = maxSize
    }
  } else {
    if (height > maxSize) {
      const ratio = maxSize / height
      newHeight = maxSize
      newWidth = width * ratio
    }
  }
  const canvas = document.createElement('canvas')
  canvas.height = newHeight
  canvas.width = newWidth
  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, 0, 0, newWidth, newHeight)
  return new Promise((resolve, reject) =>
    canvas.toBlob(
      blob => {
        if (blob) return resolve(blob)
        reject(new ImageResizeError(type))
      },
      type,
      1
    )
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
`
