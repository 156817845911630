/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.6213 7.01601C13.858 5.07601 10.9333 3.02534 8 3.06667C5.06666 3.02467 2.142 5.07667 0.378664 7.01601C0.13695 7.28625 0.00331879 7.6361 0.00331879 7.99867C0.00331879 8.36125 0.13695 8.7111 0.378664 8.98134C2.12133 10.9007 5.00466 12.9333 7.89266 12.9333H8.098C10.996 12.9333 13.8787 10.9007 15.6233 8.98067C15.8647 8.71029 15.998 8.36042 15.9976 7.99796C15.9972 7.6355 15.8633 7.2859 15.6213 7.01601ZM4.93333 8.00001C4.93333 7.39348 5.11319 6.80057 5.45016 6.29626C5.78713 5.79195 6.26608 5.39889 6.82644 5.16678C7.3868 4.93467 8.0034 4.87394 8.59827 4.99227C9.19315 5.11059 9.73958 5.40267 10.1685 5.83155C10.5973 6.26043 10.8894 6.80686 11.0077 7.40173C11.1261 7.99661 11.0653 8.61321 10.8332 9.17357C10.6011 9.73393 10.2081 10.2129 9.70375 10.5498C9.19944 10.8868 8.60653 11.0667 8 11.0667C7.18667 11.0667 6.40665 10.7436 5.83154 10.1685C5.25643 9.59336 4.93333 8.81334 4.93333 8.00001Z`}/><path d={`M8 9.33332C8.73638 9.33332 9.33333 8.73637 9.33333 7.99999C9.33333 7.26361 8.73638 6.66666 8 6.66666C7.26362 6.66666 6.66666 7.26361 6.66666 7.99999C6.66666 8.73637 7.26362 9.33332 8 9.33332Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.6213 7.01601C13.858 5.07601 10.9333 3.02534 8 3.06667C5.06666 3.02467 2.142 5.07667 0.378664 7.01601C0.13695 7.28625 0.00331879 7.6361 0.00331879 7.99867C0.00331879 8.36125 0.13695 8.7111 0.378664 8.98134C2.12133 10.9007 5.00466 12.9333 7.89266 12.9333H8.098C10.996 12.9333 13.8787 10.9007 15.6233 8.98067C15.8647 8.71029 15.998 8.36042 15.9976 7.99796C15.9972 7.6355 15.8633 7.2859 15.6213 7.01601ZM4.93333 8.00001C4.93333 7.39348 5.11319 6.80057 5.45016 6.29626C5.78713 5.79195 6.26608 5.39889 6.82644 5.16678C7.3868 4.93467 8.0034 4.87394 8.59827 4.99227C9.19315 5.11059 9.73958 5.40267 10.1685 5.83155C10.5973 6.26043 10.8894 6.80686 11.0077 7.40173C11.1261 7.99661 11.0653 8.61321 10.8332 9.17357C10.6011 9.73393 10.2081 10.2129 9.70375 10.5498C9.19944 10.8868 8.60653 11.0667 8 11.0667C7.18667 11.0667 6.40665 10.7436 5.83154 10.1685C5.25643 9.59336 4.93333 8.81334 4.93333 8.00001Z\" />\n<path d=\"M8 9.33332C8.73638 9.33332 9.33333 8.73637 9.33333 7.99999C9.33333 7.26361 8.73638 6.66666 8 6.66666C7.26362 6.66666 6.66666 7.26361 6.66666 7.99999C6.66666 8.73637 7.26362 9.33332 8 9.33332Z\" />\n</svg>\n";
export default src;
