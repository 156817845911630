/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../../ui/button'

function humanReadableFilesize (size) {
  if (size < 1e3) return `${size} bytes`
  if (size < 1e6) return `${(size / 1e3).toFixed(1)} kB`
  return `${(size / 1e6).toFixed(1)} MB`
}

function FileLink ({ value }) {
  return value.temporaryUrl ? (
    <strong>
      <a
        href={value?.permaLink ? value.permaLink : value.temporaryUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        {value.filename}
      </a>
    </strong>
  ) : (
    <strong>{value.filename}</strong>
  )
}

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export default function DisplayFile ({
  value,
  noValue = null,
  onRemove,
  ...props
}) {
  if (!value) return noValue
  const handleRemove = e => {
    e.preventDefault()
    onRemove(value)
  }
  return (
    <FileWrapper {...props}>
      <FileLink value={value} />
      &nbsp;
      <i>({humanReadableFilesize(value.filesize)})</i>
      &nbsp;
      {onRemove && (
        <Button outline onClick={handleRemove}>
          <Trans id='delete' />
        </Button>
      )}
    </FileWrapper>
  )
}
