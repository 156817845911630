/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM12.6613 6.41L8.90534 11.3073C8.79884 11.4463 8.66178 11.5589 8.50479 11.6363C8.34779 11.7138 8.17507 11.7541 8 11.7541C7.82493 11.7541 7.65221 11.7138 7.49522 11.6363C7.33822 11.5589 7.20117 11.4463 7.09467 11.3073L3.33867 6.41C3.27119 6.32323 3.22153 6.22397 3.19258 6.11793C3.16362 6.01189 3.15593 5.90116 3.16994 5.79214C3.18396 5.68311 3.21941 5.57794 3.27424 5.48267C3.32908 5.3874 3.40222 5.30392 3.48946 5.23705C3.5767 5.17017 3.67631 5.12121 3.78254 5.09299C3.88878 5.06477 3.99956 5.05785 4.10848 5.07262C4.21741 5.0874 4.32233 5.12358 4.41722 5.17908C4.5121 5.23458 4.59507 5.3083 4.66134 5.396L7.868 9.57667C7.88345 9.59749 7.90355 9.6144 7.9267 9.62606C7.94985 9.63771 7.97542 9.64378 8.00134 9.64378C8.02726 9.64378 8.05282 9.63771 8.07597 9.62606C8.09913 9.6144 8.11923 9.59749 8.13467 9.57667L11.3413 5.396C11.4076 5.3083 11.4906 5.23458 11.5855 5.17908C11.6803 5.12358 11.7853 5.0874 11.8942 5.07262C12.0031 5.05785 12.1139 5.06477 12.2201 5.09299C12.3264 5.12121 12.426 5.17017 12.5132 5.23705C12.6005 5.30392 12.6736 5.3874 12.7284 5.48267C12.7833 5.57794 12.8187 5.68311 12.8327 5.79214C12.8467 5.90116 12.8391 6.01189 12.8101 6.11793C12.7811 6.22397 12.7315 6.32323 12.664 6.41H12.6613Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM12.6613 6.41L8.90534 11.3073C8.79884 11.4463 8.66178 11.5589 8.50479 11.6363C8.34779 11.7138 8.17507 11.7541 8 11.7541C7.82493 11.7541 7.65221 11.7138 7.49522 11.6363C7.33822 11.5589 7.20117 11.4463 7.09467 11.3073L3.33867 6.41C3.27119 6.32323 3.22153 6.22397 3.19258 6.11793C3.16362 6.01189 3.15593 5.90116 3.16994 5.79214C3.18396 5.68311 3.21941 5.57794 3.27424 5.48267C3.32908 5.3874 3.40222 5.30392 3.48946 5.23705C3.5767 5.17017 3.67631 5.12121 3.78254 5.09299C3.88878 5.06477 3.99956 5.05785 4.10848 5.07262C4.21741 5.0874 4.32233 5.12358 4.41722 5.17908C4.5121 5.23458 4.59507 5.3083 4.66134 5.396L7.868 9.57667C7.88345 9.59749 7.90355 9.6144 7.9267 9.62606C7.94985 9.63771 7.97542 9.64378 8.00134 9.64378C8.02726 9.64378 8.05282 9.63771 8.07597 9.62606C8.09913 9.6144 8.11923 9.59749 8.13467 9.57667L11.3413 5.396C11.4076 5.3083 11.4906 5.23458 11.5855 5.17908C11.6803 5.12358 11.7853 5.0874 11.8942 5.07262C12.0031 5.05785 12.1139 5.06477 12.2201 5.09299C12.3264 5.12121 12.426 5.17017 12.5132 5.23705C12.6005 5.30392 12.6736 5.3874 12.7284 5.48267C12.7833 5.57794 12.8187 5.68311 12.8327 5.79214C12.8467 5.90116 12.8391 6.01189 12.8101 6.11793C12.7811 6.22397 12.7315 6.32323 12.664 6.41H12.6613Z\"/>\n</svg>\n";
export default src;
