/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import * as Chooser from '../../data-chooser'

export function RequiredConfig ({ Gadgets, onChange, value }) {
  const onSelect = source => {
    onChange({ ...value, source })
  }
  return (
    <Gadgets.Padded>
      <Gadgets.Label>Display associated data:</Gadgets.Label>
      <Chooser.Typeahead onSelect={onSelect} />
    </Gadgets.Padded>
  )
}
