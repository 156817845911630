/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { cloneDeep, filter, identity, map, mapValues, reduce } from 'lodash'

import renderGadget from './gadget-renderer'
import renderEmptyForm from './gadget-renderer-empty'
import renderer from './renderer'
import { structureToSchema, traverseTemplate } from './utils'

export default function createFormbot (options = {}) {
  const Formbot = {
    renderGadget,
    renderEmptyForm,
    options
  }

  const context = (Formbot.context = {
    decorators: [],
    gadgets: {}
  })

  Formbot.registerGadget = (key, gadgetDefinition) => {
    if (context.gadgets[key]) {
      const msg = <Trans id='gadget.already.registered.at.key' values={key} />
      throw Error(`DUPLICATE_GADGET_KEY: ${msg}`)
    }
    gadgetDefinition.meta = {
      icon: 'extension',
      category: 'Element',
      ...(gadgetDefinition.meta || {})
    }
    context.gadgets[key] = gadgetDefinition
  }

  Formbot.getGadget = key => context.gadgets[key] || context.gadgets.NotFound

  Formbot.registerDecorator = (pattern, val) =>
    context.decorators.push({ pattern, val })

  Formbot.getDecorators = _pattern => {
    if (_pattern.type === 'Grouping') return []
    const matching = filter(context.decorators, ({ pattern }) => {
      for (const key in pattern) {
        if (!(key in _pattern) || _pattern[key] !== pattern[key]) return false
      }
      return true
    })
    return map(matching, 'val')
  }

  Formbot.decorate = (decorators, parts, props, gadgetDefinition) =>
    reduce(
      decorators,
      (memo, dec) => {
        const decorations = dec(props, gadgetDefinition)
        return mapValues(memo, (part, key) =>
          (decorations[key] || identity)(part)
        )
      },
      parts
    )

  Formbot.render = (mode, structure, document, props = {}) => {
    if (!props.noGrid) {
      structure = cloneDeep(structure)
      appendRoots(structure.template)
      structure.template = addBorders(structure.template)
    }

    // when rendering inside repeaters, we also want the existing
    // gadgetInstances for the entire form so conditional visibility works
    const context = props.context ?? {}
    const existingGadgetInstances = context.gadgetInstances ?? []
    const gadgetInstances = [
      ...structureToSchema(structure, Formbot),
      ...existingGadgetInstances
    ]
    const toRender = props.multipageNum
      ? structure.template.children[props.multipageNum - 1]
      : structure.template
    return renderer(Formbot, mode, toRender, document, gadgetInstances, {
      ...props,
      context: {
        ...context,

        // pass through gadgetInstances so that rendering works inside Repeaters
        gadgetInstances
      }
    })
  }

  return Formbot
}

function appendRoots (template) {
  if (!template) return
  template.root = true
  if (template.type === 'Row') return
  if (template.type === 'Section' && !template.hideLabel) return
  if (template.children) template.children.forEach(appendRoots)
}

/*
Our gadgets only render their bottom and right borders. The sections that
contain these gadgets are responsible for rendering the top and left borders.

Some gadgets are rendered outside of sections, whether from being placed outside
a section or from being inside a section with hideLabel=true. In these cases we
want to surround these gadgets with a Grouping gadget which will render the
missing border.

This function's job is to ensure all gadgets have a single pixel border on all
sides. It does this by inject Grouping gadgets into the template where needed.

Note: The grouping gadgets must have reproducible IDs for drag-n-drop to work.
*/
function addBorders (template) {
  if (!template) return
  const idRef = { value: 0 }
  const wrapper = { type: 'Column', root: true, children: [template] }
  traverseTemplate(wrapper, gadget => {
    if (gadget.type === 'Column' && gadget.root) {
      gadget.children = updateChildren(gadget.children, idRef)
    }
    if (gadget.type === 'Section' && gadget.hideLabel && !gadget.root) {
      gadget.children = gadget.children &&
        gadget.children.length && [createGrouping(gadget.children, idRef)]
    }
  })
  return wrapper.children[0]
}

function updateChildren (children, idRef) {
  const newChildren = []
  let grouping = []
  for (let i = 0; i < children.length; ++i) {
    const gadget = children[i]
    if (gadget.type === 'Section') {
      if (grouping.length) {
        newChildren.push(createGrouping(grouping, idRef))
        grouping = []
      }
      if (gadget.hideLabel && gadget.children) {
        gadget.children = updateChildren(gadget.children, idRef)
      }
      newChildren.push(gadget)
    } else if (gadget.type === 'Column') {
      if (!gadget.root) gadget.children = updateChildren(gadget.children, idRef)
      newChildren.push(gadget)
    } else {
      grouping.push(gadget)
    }
  }
  if (grouping.length) {
    newChildren.push(createGrouping(grouping, idRef))
    grouping = []
  }
  return newChildren
}

function createGrouping (children, idRef) {
  return { id: `grouping-${idRef.value++}`, type: 'Grouping', children }
}
