/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13 0H3C2.20462 0.000882284 1.44207 0.317236 0.879654 0.879654C0.317236 1.44207 0.000882284 2.20462 0 3L0 13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H13C13.7956 16 14.5587 15.6839 15.1213 15.1213C15.6839 14.5587 16 13.7956 16 13V3C15.9991 2.20462 15.6828 1.44207 15.1203 0.879654C14.5579 0.317236 13.7954 0.000882284 13 0V0ZM13.6667 8C13.6667 9.12076 13.3343 10.2164 12.7117 11.1482C12.089 12.0801 11.204 12.8064 10.1685 13.2353C9.13309 13.6642 7.99371 13.7764 6.89449 13.5578C5.79526 13.3391 4.78556 12.7994 3.99306 12.0069C3.20056 11.2144 2.66087 10.2047 2.44222 9.10551C2.22357 8.00629 2.33579 6.86691 2.76468 5.83146C3.19358 4.79601 3.91989 3.911 4.85177 3.28834C5.78365 2.66568 6.87924 2.33333 8 2.33333C9.50289 2.33333 10.9442 2.93036 12.0069 3.99306C13.0696 5.05577 13.6667 6.49711 13.6667 8Z`}/><path d={`M10 7.66667H8.33333V4.66667C8.33333 4.48986 8.2631 4.32029 8.13807 4.19526C8.01305 4.07024 7.84348 4 7.66667 4C7.48986 4 7.32029 4.07024 7.19526 4.19526C7.07024 4.32029 7 4.48986 7 4.66667V8.33333C7 8.51014 7.07024 8.67971 7.19526 8.80474C7.32029 8.92976 7.48986 9 7.66667 9H10C10.1768 9 10.3464 8.92976 10.4714 8.80474C10.5964 8.67971 10.6667 8.51014 10.6667 8.33333C10.6667 8.15652 10.5964 7.98695 10.4714 7.86193C10.3464 7.7369 10.1768 7.66667 10 7.66667Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13 0H3C2.20462 0.000882284 1.44207 0.317236 0.879654 0.879654C0.317236 1.44207 0.000882284 2.20462 0 3L0 13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H13C13.7956 16 14.5587 15.6839 15.1213 15.1213C15.6839 14.5587 16 13.7956 16 13V3C15.9991 2.20462 15.6828 1.44207 15.1203 0.879654C14.5579 0.317236 13.7954 0.000882284 13 0V0ZM13.6667 8C13.6667 9.12076 13.3343 10.2164 12.7117 11.1482C12.089 12.0801 11.204 12.8064 10.1685 13.2353C9.13309 13.6642 7.99371 13.7764 6.89449 13.5578C5.79526 13.3391 4.78556 12.7994 3.99306 12.0069C3.20056 11.2144 2.66087 10.2047 2.44222 9.10551C2.22357 8.00629 2.33579 6.86691 2.76468 5.83146C3.19358 4.79601 3.91989 3.911 4.85177 3.28834C5.78365 2.66568 6.87924 2.33333 8 2.33333C9.50289 2.33333 10.9442 2.93036 12.0069 3.99306C13.0696 5.05577 13.6667 6.49711 13.6667 8Z\" />\n<path d=\"M10 7.66667H8.33333V4.66667C8.33333 4.48986 8.2631 4.32029 8.13807 4.19526C8.01305 4.07024 7.84348 4 7.66667 4C7.48986 4 7.32029 4.07024 7.19526 4.19526C7.07024 4.32029 7 4.48986 7 4.66667V8.33333C7 8.51014 7.07024 8.67971 7.19526 8.80474C7.32029 8.92976 7.48986 9 7.66667 9H10C10.1768 9 10.3464 8.92976 10.4714 8.80474C10.5964 8.67971 10.6667 8.51014 10.6667 8.33333C10.6667 8.15652 10.5964 7.98695 10.4714 7.86193C10.3464 7.7369 10.1768 7.66667 10 7.66667Z\" />\n</svg>\n";
export default src;
