import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import { useOutsideClick } from '../../../../components/window-click'
import * as Icons from '../../../../icons'
import Input from '../../../../ui/input'

function RuleSearch ({ decisionTree, handleValueChange, hide, idPrefix }) {
  const [query, setQuery] = React.useState('')
  const [selected, setSelected] = React.useState(-1)
  const options = filterCombinationsByQuery(decisionTree, query)
  const ref = React.useRef()
  useOutsideClick(ref, hide)
  const resultsRef = React.useRef()

  return (
    <div ref={ref} className='relative'>
      <div className='absolute -left-1 -top-10 z-40 w-[600px] border border-light-gray-400 bg-white shadow-2xl'>
        <div className='relative flex w-full items-center justify-between gap-4 border-b border-light-gray-400 p-2'>
          <h3 className='uppercase'>
            <Trans id='rules' />
          </h3>
          <div className='relative flex-1'>
            <Input
              role='combobox'
              aria-haspopup='listbox'
              aria-controls={`${idPrefix}-results`}
              aria-activedescendant={
                selected > -1 ? `${idPrefix}-results-${selected}` : ''
              }
              aria-label={i18n._('search.rules')}
              autoComplete='off'
              className='ml-4 w-full'
              placeholder={i18n._('search')}
              value={query}
              autoFocus
              onChange={e => {
                setQuery(e)
                setSelected(-1)
              }}
              onKeyDown={e => {
                if (e.key === 'ArrowDown') {
                  e.preventDefault()
                  setSelected(
                    selected === options.length - 1 ? -1 : selected + 1
                  )
                }
                if (e.key === 'ArrowUp') {
                  e.preventDefault()
                  setSelected(
                    selected === -1 ? options.length - 1 : selected - 1
                  )
                }
                if (e.key === 'Escape') {
                  hide()
                }
                if ((e.key === 'Enter' || e.key === 'Tab') && selected > -1) {
                  handleValueChange(options[selected])
                }
              }}
            />
          </div>
          <button
            aria-label={i18n._('close.rules.search')}
            className='kp-button-icon kp-button-transparent ml-2 text-medium-gray-500'
            onClick={hide}
          >
            <Icons.Close />
          </button>
        </div>
        <div className='h-72 max-h-96 w-full min-w-full overflow-auto bg-white'>
          <ul
            id={`${idPrefix}-results`}
            className='m-0 list-none overflow-y-auto p-0'
            ref={resultsRef}
            role='listbox'
          >
            {options.map((option, i) => (
              <Option
                key={option.label}
                id={`${idPrefix}-results-${i}`}
                isSelected={selected === i}
                onClick={() => handleValueChange(option)}
              >
                <HighlightSearchText text={option.label} query={query} />
              </Option>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

function Option ({ children, id, isSelected, onClick }) {
  const ref = React.useRef()
  React.useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({ block: 'nearest' })
    }
  }, [isSelected])
  return (
    <li
      aria-selected={isSelected}
      id={id}
      className={cx(
        'flex min-h-10 w-full cursor-pointer items-center px-4 py-1 hover:bg-light-gray-100 dark:hover:bg-light-gray-300',
        { 'bg-light-gray-100 dark:bg-light-gray-300': isSelected }
      )}
      onClick={onClick}
      ref={ref}
      role='option'
    >
      {children}
    </li>
  )
}

function HighlightSearchText ({ text, query }) {
  return (
    <div className={cx('flex', 'flex-wrap', 'items-center', 'gap-1')}>
      {text.split(/(%{.*?}%)/g).map((part, index) => {
        if (part.startsWith('%{') && part.endsWith('}%')) {
          return <InputBadge part={part} query={query} key={index} />
        } else {
          return (
            <span key={index}>
              <OptionSegments query={query} text={part} />
            </span>
          )
        }
      })}
    </div>
  )
}

const InputBadge = ({ part, query }) => {
  const [gadgetType, ruleText] = part.slice(2, -2).split('$$')

  return (
    <div
      className='rounded-full bg-blue-100 px-2 py-1 text-sm font-medium text-dark-gray-300'
      title={gadgetType}
    >
      <OptionSegments query={query} text={ruleText} />
    </div>
  )
}

const OptionSegments = ({ query, text }) => {
  if (!query) return text

  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const regex = new RegExp(`(${escapedQuery})`, 'gi')
  const segments = text.split(regex).filter(Boolean)

  return (
    <>
      {segments.map((segment, segmentIndex) => {
        const isMatch = query && new RegExp(escapedQuery, 'gi').test(segment)
        return isMatch ? (
          <mark key={segmentIndex} className='bg-yellow-200 font-bold'>
            {segment}
          </mark>
        ) : (
          <span key={segmentIndex}>{segment}</span>
        )
      })}
    </>
  )
}

function filterCombinationsByQuery (node, queryString) {
  const combinationsWithLabels = getCombinationsWithLabels(node)

  return combinationsWithLabels.filter(({ label }) =>
    label.toLowerCase().includes(queryString.toLowerCase())
  )
}

function getCombinationsWithLabels (node) {
  function getAllCombinations (node) {
    if (!node.children || node.children.length === 0) {
      return node.gadgets ? node.gadgets.map(gadget => [gadget]) : []
    }

    const childCombinations = node.children.flatMap(getAllCombinations)

    if (!node.gadgets || node.gadgets.length === 0) {
      return childCombinations
    }

    return node.gadgets.flatMap(gadget =>
      childCombinations.length > 0
        ? childCombinations.map(combination => [gadget, ...combination])
        : [[gadget]]
    )
  }

  function getRuleTextAndGadget (combination) {
    return combination
      .map(gadget => {
        if (gadget.type === 'Spacer') {
          return gadget.ruleText
        }
        return ` %{${gadget.formKey}$$${gadget.ruleText}}%`
      })
      .join(' ')
  }

  const combinations = getAllCombinations(node)
  return combinations.map(combination => ({
    label: getRuleTextAndGadget(combination),
    value: combination
  }))
}

export default RuleSearch
