/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, isEmpty, map } from 'lodash'
import React from 'react'
import { useImmer } from 'use-immer'

import FormbotContainer from '../../components/formbot-container/formbot-container'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { useDocumentTitle } from '../../components/use-document-title'
import { useScrollToTop } from '../../components/use-scroll-to-top'
import { ValidationErrors } from '../../components/validation-errors'
import Formbot, { getPages, validate, validateWithDetails } from '../../formbot'
import { traverseTemplate } from '../../formbot/engine/formbot/utils'
import { useAlerts } from '../../ui/alerts'

export default function FormPreview (props) {
  useDocumentTitle('Form - Preview')
  const [pageNum, setPageNum] = React.useState(props.paginate ? 1 : null)
  React.useEffect(() => {
    setPageNum(props.paginate ? 1 : null)
  }, [props.paginate])
  const scrollRef = useScrollToTop([pageNum])
  const alerts = useAlerts()
  const [shouldValidate, setShouldValidate] = React.useState(false)
  const [formData, updateFormData] = useImmer({})
  const [imageCache, setImageCache] = useImmer({})
  const updateImageCache = (key, val) => {
    setImageCache(draft => {
      draft[key] = val
    })
  }
  const meta = {
    createdBy: props.user,
    submittedAt: null,
    createdAt: +new Date(),
    updatedAt: +new Date(),
    serialNumber: '0001'
  }
  const structure = React.useMemo(() => {
    const template = cloneDeep(props.template)
    traverseTemplate(template, (gadget, root, parent, i) => {
      if (gadget.type === 'Section' && gadget.details?.officeUseOnly) {
        parent.children.splice(i, 1)
      }
    })
    return {
      template,
      metaFields: props.metaFields,
      integrationFields: props.integrationFields,
      trashed: []
    }
  }, [props.template, props.metaFields, props.integrationFields])
  const document = { data: formData, meta }

  const handleChange = (key, val) =>
    updateFormData(draft => {
      draft[key] = val
    })

  const runValidations = () => {
    setShouldValidate(true)
    const validations = validateWithDetails(document, structure)
    if (isEmpty(validations)) {
      return alerts.type3(i18n._('pagesbuilder.form.preview.valid'), 'success')
    }
    alerts.type2(
      close => (
        <ValidationErrors
          label={i18n._('pagesbuilder.form.preview.validation.errors')}
          validations={validations}
          close={close}
        />
      ),
      'error',
      null,
      true
    )
  }

  const getButtons = pages => {
    const actions = (
      <div>
        <TooltipTrigger tooltipId='form-preview-submit'>
          <button
            className='kp-button-brand kp-button-lg mb-2 w-full'
            style={{ '--kp-brand': props.branding.color }}
            onClick={runValidations}
          >
            <Trans id='pagesbuilder.form.preview.submit' />
          </button>
        </TooltipTrigger>
        <Tooltip id='form-preview-submit' place='left'>
          <div className='w-64 text-center'>
            <Trans id='pagesbuilder.form.preview.submit.disabled' />
          </div>
        </Tooltip>
        <TooltipTrigger tooltipId='form-preview-save'>
          <button
            className='kp-button-brand kp-button-lg mb-2 w-full'
            style={{ '--kp-brand': props.branding.color }}
          >
            <Trans id='save' />
          </button>
        </TooltipTrigger>
        <Tooltip id='form-preview-save' place='left'>
          <div>
            <Trans id='pagesbuilder.form.preview.cannot.save' />
          </div>
        </Tooltip>
        <TooltipTrigger tooltipId='form-preview-discard'>
          <button className='kp-button-outline kp-button-lg w-full'>
            <Trans id='pagesbuilder.form.preview.discard' />
          </button>
        </TooltipTrigger>
        <Tooltip id='form-preview-discard' place='left'>
          <div>
            <Trans id='pagesbuilder.form.preview.cannot.discard' />
          </div>
        </Tooltip>
      </div>
    )
    if (!pages) return actions
    const prevPage = pages
      .slice(0, pageNum - 1)
      .reverse()
      .find(page => !page.hidden)
    const nextPage = pages.slice(pageNum).find(page => !page.hidden)
    return (
      <div>
        <div className='mb-2 flex items-center'>
          <button
            className='kp-button-brand kp-button-lg mr-2 w-full'
            style={{ '--kp-brand': props.branding.color }}
            disabled={!prevPage}
            onClick={
              prevPage ? () => setPageNum(prevPage.index + 1) : undefined
            }
          >
            <Trans id='pagesbuilder.form.preview.back' />
          </button>
          <button
            className='kp-button-brand kp-button-lg w-full'
            style={{ '--kp-brand': props.branding.color }}
            disabled={!nextPage}
            onClick={
              nextPage ? () => setPageNum(nextPage.index + 1) : undefined
            }
          >
            <Trans id='pagesbuilder.form.preview.next' />
          </button>
        </div>
        {actions}
      </div>
    )
  }

  const validations = shouldValidate ? validate(document, structure) : {}
  let pages = null
  if (props.paginate) {
    pages = map(getPages(document, structure, validations), (page, i) => ({
      index: i,
      errorMsg: page.errorMsg,
      name: page.name,
      hidden: page.hidden,
      handleClick: () => setPageNum(i + 1),
      format:
        i + 1 < pageNum ? 'checked' : i + 1 === pageNum ? 'selected' : 'default'
    }))
  }
  const buttons = getButtons(pages)
  return (
    <FormbotContainer
      actionButtons={buttons}
      helpLink={props.helpLink}
      branding={props.branding}
      pages={pages}
      className='bg-light-gray-200'
      formbot={
        <div ref={scrollRef}>
          <Formbot.Edit
            className='formbot'
            document={document}
            onChange={handleChange}
            multipageNum={pageNum}
            structure={structure}
            branding={props.branding}
            context={{
              actionId: '1234',
              validations,
              labelSize: props.labelSize,
              documentMeta: document.meta,
              currentUser: props.user,
              imageCache,
              updateImageCache
            }}
          />
        </div>
      }
    />
  )
}
