/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import * as Icons from '../../../icons'
import {
  AffiliationManager,
  GroupManager,
  RoleManager,
  UserManager
} from './member-manager'
import { useRemovePolicyGroupMutation } from './mutation.remove-policy-group'
import PermissionManager from './permission-manager'

function Description ({ group }) {
  if (group.name === 'All Authenticated Users') {
    return (
      <div className='m-2 min-w-[350px] flex-1'>
        <Trans id='pagesbuilder.permissions.all.authenticated' />
      </div>
    )
  }

  if (group.name === 'All Anonymous Users') {
    return (
      <div className='m-2 min-w-[350px] flex-1'>
        <Trans id='pagesbuilder.permissions.all.anonymous' />
      </div>
    )
  }

  return (
    <div className='m-2 flex min-w-[350px] flex-1 flex-col gap-3'>
      <UserManager
        label={i18n._('pagesbuilder.permissions.users')}
        id={`${group.id}-users`}
        group={group}
        leaveLast={group.name === 'Administrators'}
      />
      <GroupManager
        label={i18n._('pagesbuilder.permissions.groups')}
        id={`${group.id}-groups`}
        group={group}
      />
      <RoleManager
        label={i18n._('pagesbuilder.permissions.roles')}
        id={`${group.id}-roles`}
        group={group}
      />
      <AffiliationManager
        label={i18n._('pagesbuilder.permissions.affiliations')}
        id={`${group.id}-affiliations`}
        group={group}
      />
    </div>
  )
}

export default function PolicyGroup ({ group }) {
  const deleteRole = useRemovePolicyGroupMutation(group.id)
  return (
    <div className='mb-5 bg-white p-4 shadow-md dark:bg-light-gray-300'>
      {group.removable && (
        <button
          className='kp-button-transparent kp-button-icon float-right text-medium-gray-500'
          onClick={() => {
            const msg = i18n._('pagesbuilder.permissions.confirm.delete')
            if (window.confirm(msg)) deleteRole()
          }}
        >
          <Icons.Close />
        </button>
      )}
      <h2 className='my-2 ml-2 text-2xl'>{group.name}</h2>
      <div className='flex flex-wrap'>
        <Description group={group} />
        <PermissionManager
          group={group}
          policy={group.policies[0]}
          modifiable={group.name !== 'Administrators'}
          authenticated={group.name !== 'All Anonymous Users'}
        />
      </div>
    </div>
  )
}
