/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Chooser from '../../data-chooser'
import { ReactComponent as Illustration } from './illustration-integration.svg.jsx'

export function RequiredConfig ({ Gadgets, updateDataLookupSource }) {
  return (
    <Gadgets.Padded>
      <Gadgets.Label>Data Source:</Gadgets.Label>
      <Chooser.Multiselect onSelect={updateDataLookupSource} />
      <Flex>
        <Message className='bg-light-gray-100 text-dark-gray-200 dark:bg-light-gray-300'>
          <Trans id='choose.source.to.begin' />
        </Message>
        <Illustration />
      </Flex>
    </Gadgets.Padded>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
`

const Message = styled.div`
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 134px;
  height: 90px;
`
