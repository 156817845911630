/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

import * as Icons from '../../../icons'

export default ({ label, refreshing, refreshSimulation }) => (
  <button
    className='kp-button-transparent'
    disabled={refreshing}
    onClick={refreshSimulation}
  >
    <Icons.Sync
      className={cx('mr-2', {
        'animate-spin [animation-duration:2s]': refreshing
      })}
    />
    {label}
  </button>
)
