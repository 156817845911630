/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

function isRichText (el) {
  if (!el) return false
  if (el.contentEditable === 'true') return true
  return isRichText(el.parentElement)
}

export default function isEditable (el) {
  if (!el) return false
  const nodeName = el.nodeName.toLowerCase()
  return (
    nodeName === 'textarea' ||
    (nodeName === 'input' &&
      /^(?:text|email|number|search|tel|url|password)$/i.test(el.type)) ||
    isRichText(el)
  )
}
