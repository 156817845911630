/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import { Support } from '../../icons'

export default function HelpLink ({ helpLink, children = 'Form Help' }) {
  return (
    <MyLink href={helpLink} target='_blank' rel='noopener noreferrer'>
      <Support />
      <LinkText>{children}</LinkText>
    </MyLink>
  )
}

const MyLink = styled.a`
  height: 48px;
  color: #3369a3;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 4px;
  text-decoration: none;
  &:hover {
    background: #ddd;
  }
`

const LinkText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 8px;
  display: inline-block;
`
