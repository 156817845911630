/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { useLocalStorageState } from '../components/use-local-storage-state'

const ThemeContext = React.createContext()
const startingSysPref = window.matchMedia('(prefers-color-scheme: dark)')

export function ThemeProvider ({ children }) {
  const systemPref = useSysPrefersDark()
  const [local, setLocal] = useLocalStorageState('darkModePref', 'light', {
    jsonify: false
  })
  const dark = local === 'default' ? systemPref : local === 'dark'

  React.useEffect(() => changeTheme(dark), [dark])

  return (
    <ThemeContext.Provider value={{ setTheme: setLocal, theme: local }}>
      {children}
    </ThemeContext.Provider>
  )
}

function useSysPrefersDark () {
  const [sysPref, setSysPref] = React.useState(startingSysPref.matches)
  React.useEffect(() => {
    const handleChange = mql => setSysPref(mql.matches)
    startingSysPref.addEventListener('change', handleChange)
    return () => startingSysPref.removeEventListener('change', handleChange)
  }, [])
  return sysPref
}

export function useTheme () {
  const { setTheme, theme } = React.useContext(ThemeContext)
  return [theme, setTheme]
}

function changeTheme (dark) {
  if (dark) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}
