/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

const addClassName = (el, cn) => {
  /* istanbul ignore if */
  if (!el) return el
  return React.cloneElement(el, { className: cx(el.props.className, cn) })
}

export const validationDecorator = (props, manifest) => {
  if (manifest.layout) return {}
  const required = get(props, 'template.required')
  const validations = get(props, ['context', 'validations', props.formKey])
  const isConfig = get(props, ['context', 'configMode'])
  return {
    label: label =>
      addClassName(
        label,
        cx({ 'required-field': required, 'invalid-field': validations })
      ),
    gadget: isConfig
      ? null
      : gadget => {
          const errors = map(validations, (message, i) => (
            <ValidationMessage className='text-red-400' key={i + message}>
              {message}
            </ValidationMessage>
          ))
          return React.cloneElement(gadget, { errors })
        },
    component: isConfig
      ? null
      : component => {
          const errors = map(validations, (message, i) => (
            <ValidationMessage className='text-red-400' key={i}>
              {message}
            </ValidationMessage>
          ))
          return React.cloneElement(component, { errors })
        }
  }
}

const ValidationMessage = styled.div`
  padding: 0.5em;
`
