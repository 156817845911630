/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { keyBy, mapValues } from 'lodash'
import React from 'react'

import ConfigWrapper from '../../../components/dnd-config-wrapper'
import ErrorBoundary from '../../../components/error-boundary'
import { expandTableColumns, structureToSchema } from '../formbot/utils'
import Edit from './edit'
import {
  FormbotContext,
  FormbotDataContext,
  IdFormkeyMapContext
} from './hooks'
import View from './view'

export function bind (Formbot) {
  return {
    Edit: wrap(Edit, Formbot),
    View: wrap(View, Formbot),
    ConfigWrapper: wrap(ConfigWrapper, Formbot)
  }
}

function wrap (Component, Formbot) {
  return props => {
    const idMap = React.useMemo(() => {
      if (!props.structure) return {}
      let schema = structureToSchema(props.structure, Formbot)
      schema = expandTableColumns(schema, Formbot)
      return mapValues(
        keyBy(schema, g => (g.id.startsWith('meta') ? g.id : `data.${g.id}`)),
        'formKey'
      )
    }, [props.structure])
    return (
      <ErrorBoundary>
        <FormbotContext.Provider value={Formbot}>
          <FormbotDataContext.Provider value={props.document}>
            <IdFormkeyMapContext.Provider value={idMap}>
              <Component {...props} Formbot={Formbot} />
            </IdFormkeyMapContext.Provider>
          </FormbotDataContext.Provider>
        </FormbotContext.Provider>
      </ErrorBoundary>
    )
  }
}
