/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery } from '@apollo/client'
import { map } from 'lodash'
import React from 'react'

import * as Lookups from '../lookup-controls'
import useDebouncedValue from '../use-debounced-value'

export const Multiselect = ({
  id,
  standalone,
  hideResults,
  placeholder,
  categoryId,
  onAdd,
  onRemove,
  onChange,
  value,
  noDarkerModeDarkerBg,
  ...props
}) => {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const [fetch, { data, loading }] = useLazyQuery(GROUPS_QUERY)
  React.useEffect(() => {
    fetch({ variables: { categoryId, query: debouncedQuery } })
  }, [categoryId, debouncedQuery, fetch])
  const options = map(data?.groupsConnection?.edges, ({ node: group }) => ({
    id: group.newId,
    label: group.name
  }))
  return (
    <Lookups.Multiselect
      id={id}
      standalone={standalone}
      hideResults={hideResults}
      placeholder={placeholder}
      options={options}
      onAdd={onAdd}
      onRemove={onRemove}
      onChange={onChange}
      value={value}
      query={query}
      setQuery={setQuery}
      aria={props['aria-labelledby']}
      gridded={props.gridded}
      loading={loading}
      noDarkerModeDarkerBg={noDarkerModeDarkerBg}
    />
  )
}

const GROUPS_QUERY = gql`
  query GetGroups($categoryId: ID, $query: String) {
    groupsConnection(
      args: { limit: 50, categoryId: $categoryId, query: $query }
    ) {
      edges {
        node {
          id
          newId
          name
        }
      }
    }
  }
`
