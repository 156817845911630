/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import styled from 'styled-components'

import { H3, Subtitle1 } from './typography'

export const WhiteBox = styled.div`
  background: white;
  display: inline-block;
  height: fit-content;
  padding: 40px 32px;
  width: ${p => p.width}px;
  border-radius: 10px;
  ${p => p.styles}
  html.dark & {
    // Outlier: dark:bg-light-gray-300
    background: #333;
  }
`

export const Title = styled(H3)`
  font-weight: bold;
  padding-bottom: 8px;
`

const ImgBox = styled.div`
  margin-right: 24px;
`

export const Subtitle = Subtitle1

export default ({ title, subtitle, children, width = 300, className, Img }) => (
  <WhiteBox width={width} className={className}>
    {Img && (
      <ImgBox>
        <Img />
      </ImgBox>
    )}
    <div>
      <Title>{title}</Title>
      <Subtitle1>{subtitle}</Subtitle1>
      {children}
    </div>
  </WhiteBox>
)
