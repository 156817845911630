/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useDeleteAppMutation = id => {
  const [deleteApp] = useMutation(mutation)
  return () => deleteApp(getParams(id))
}

const mutation = gql`
  mutation DeleteApp($id: ID!) {
    deleteApp(id: $id)
  }
`

const getParams = id => ({
  variables: { id }
})
