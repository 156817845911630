/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, find, isObject } from 'lodash'
import React from 'react'

import { TextView } from '../../gadget-helpers'

export default function DropdownView ({ value, details, gridded, condensed }) {
  if (value == null) return <div />
  let label = value?.label
  if (!isObject(value)) {
    const options = filter(details?.options, 'key')
    const option = find(options, { key: value }) || {}
    label = option.lbl
  }
  return <TextView {...{ condensed, gridded }}>{label}</TextView>
}
