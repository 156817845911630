/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.3333 7.81334C15.1565 7.81334 14.987 7.88358 14.8619 8.00861C14.7369 8.13363 14.6667 8.3032 14.6667 8.48001C14.6675 8.91478 14.5469 9.34115 14.3184 9.71102C14.0898 10.0809 13.7625 10.3796 13.3733 10.5733C13.3301 10.5915 13.2836 10.6008 13.2367 10.6008C13.1897 10.6008 13.1433 10.5915 13.1 10.5733C13.0553 10.5559 13.0148 10.529 12.9815 10.4945C12.9481 10.46 12.9226 10.4186 12.9067 10.3733C12.5216 9.21829 12.2514 8.02809 12.1 6.82001C11.88 5.41334 11.72 4.39334 11.0533 3.99334C10.8536 3.89754 10.6349 3.84781 10.4133 3.84781C10.1918 3.84781 9.97309 3.89754 9.77333 3.99334L9.37333 4.14001C8.9405 4.33828 8.47508 4.45577 8 4.48668C7.52408 4.45156 7.05861 4.32955 6.62667 4.12668L6.22667 4.00001C6.02691 3.90421 5.8082 3.85448 5.58667 3.85448C5.36513 3.85448 5.14642 3.90421 4.94667 4.00001C4.28 4.40001 4.12 5.42001 3.9 6.82668C3.74857 8.03476 3.47837 9.22496 3.09333 10.38C3.07741 10.4253 3.05189 10.4666 3.01853 10.5011C2.98516 10.5357 2.94473 10.5626 2.9 10.58C2.85672 10.5982 2.81026 10.6075 2.76333 10.6075C2.7164 10.6075 2.66994 10.5982 2.62667 10.58C2.23645 10.3857 1.90846 10.086 1.67987 9.71485C1.45128 9.34369 1.33123 8.91591 1.33333 8.48001C1.33333 8.3032 1.2631 8.13363 1.13807 8.00861C1.01305 7.88358 0.843478 7.81334 0.666667 7.81334C0.489856 7.81334 0.320286 7.88358 0.195262 8.00861C0.0702379 8.13363 0 8.3032 0 8.48001C-1.60255e-06 9.45308 0.386094 10.3864 1.07354 11.0751C1.76098 11.7638 2.69359 12.1516 3.66667 12.1533H12.3333C13.3064 12.1516 14.239 11.7638 14.9265 11.0751C15.6139 10.3864 16 9.45308 16 8.48001C16 8.3032 15.9298 8.13363 15.8047 8.00861C15.6797 7.88358 15.5101 7.81334 15.3333 7.81334Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.3333 7.81334C15.1565 7.81334 14.987 7.88358 14.8619 8.00861C14.7369 8.13363 14.6667 8.3032 14.6667 8.48001C14.6675 8.91478 14.5469 9.34115 14.3184 9.71102C14.0898 10.0809 13.7625 10.3796 13.3733 10.5733C13.3301 10.5915 13.2836 10.6008 13.2367 10.6008C13.1897 10.6008 13.1433 10.5915 13.1 10.5733C13.0553 10.5559 13.0148 10.529 12.9815 10.4945C12.9481 10.46 12.9226 10.4186 12.9067 10.3733C12.5216 9.21829 12.2514 8.02809 12.1 6.82001C11.88 5.41334 11.72 4.39334 11.0533 3.99334C10.8536 3.89754 10.6349 3.84781 10.4133 3.84781C10.1918 3.84781 9.97309 3.89754 9.77333 3.99334L9.37333 4.14001C8.9405 4.33828 8.47508 4.45577 8 4.48668C7.52408 4.45156 7.05861 4.32955 6.62667 4.12668L6.22667 4.00001C6.02691 3.90421 5.8082 3.85448 5.58667 3.85448C5.36513 3.85448 5.14642 3.90421 4.94667 4.00001C4.28 4.40001 4.12 5.42001 3.9 6.82668C3.74857 8.03476 3.47837 9.22496 3.09333 10.38C3.07741 10.4253 3.05189 10.4666 3.01853 10.5011C2.98516 10.5357 2.94473 10.5626 2.9 10.58C2.85672 10.5982 2.81026 10.6075 2.76333 10.6075C2.7164 10.6075 2.66994 10.5982 2.62667 10.58C2.23645 10.3857 1.90846 10.086 1.67987 9.71485C1.45128 9.34369 1.33123 8.91591 1.33333 8.48001C1.33333 8.3032 1.2631 8.13363 1.13807 8.00861C1.01305 7.88358 0.843478 7.81334 0.666667 7.81334C0.489856 7.81334 0.320286 7.88358 0.195262 8.00861C0.0702379 8.13363 0 8.3032 0 8.48001C-1.60255e-06 9.45308 0.386094 10.3864 1.07354 11.0751C1.76098 11.7638 2.69359 12.1516 3.66667 12.1533H12.3333C13.3064 12.1516 14.239 11.7638 14.9265 11.0751C15.6139 10.3864 16 9.45308 16 8.48001C16 8.3032 15.9298 8.13363 15.8047 8.00861C15.6797 7.88358 15.5101 7.81334 15.3333 7.81334Z\" />\n</svg>\n";
export default src;
