/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import DataTableRow from '../../../components/data-table/row'
import { FormbotDataContext } from '../../../formbot/engine/formbot-react/hooks'

export default function Row ({ document, columns, appId, ...props }) {
  const actionId = isMyDraft(document)
  const to = actionId ? `${actionId}/action` : `${document.id}/view`
  return (
    <FormbotDataContext.Provider value={document}>
      <DataTableRow
        document={document}
        columns={columns}
        appId={appId}
        linkTo={to}
        {...props}
      />
    </FormbotDataContext.Provider>
  )
}

export function isMyDraft (document) {
  const actions = document?.viewer?.actions
  if (!actions) return
  if (actions.length === 1 && actions[0].type === 'Initiate') {
    return actions[0]?.id
  }
}
