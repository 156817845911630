/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { get, keyBy, unescape } from 'lodash'
import React from 'react'

import * as Icons from '../../icons'
import * as CustomTitle from '../components/custom-title'
import * as EmailBuilder from '../components/email-builder'
import * as PersonPicker from '../components/person-picker'
import { SimulateHeader, SimulateWrapper } from '../components/simulator-parts'
import SimulationError from '../components/simulator-parts/error-page'
import SimulatedEmail from '../components/simulator-parts/simulated-email'
import { addImagesToBody } from '../components/simulator-parts/utils'

const Node = ({ details, errors, fbProps }) => (
  <>
    <PersonPicker.View details={details} errors={errors} prefix='To: ' />
    <EmailBuilder.View
      details={{
        ...details,
        subject: unescape(
          addVariableNamesToSubject(details.subject, fbProps?.fieldsAll)
        )
      }}
      errors={errors}
    />
  </>
)

const Config = ({
  value,
  updateValue,
  errors,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsEmails,
  fieldsGroups,
  fieldsAll,
  workflowSettings
}) => (
  <>
    <CustomTitle.Config
      value={value}
      updateValue={updateValue}
      defaultName='Notification'
      label={i18n._('step.label')}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
    <PersonPicker.Config
      value={value}
      updateValue={updateValue}
      errors={errors}
      fieldsMultiUsers={fieldsMultiUsers}
      fieldsUsers={fieldsUsers}
      fieldsEmails={fieldsEmails}
      fieldsGroups={fieldsGroups}
      title={i18n._('who.will.receive.notification')}
    />
    <div className='mb-2 w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
    <EmailBuilder.Config
      appAllowExport={workflowSettings.appAllowExport}
      value={value}
      updateValue={updateValue}
      errors={errors}
      fieldsAll={fieldsAll}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
  </>
)

const Simulate = ({
  value,
  simulateAction,
  simulationState,
  branding,
  headless,
  form,
  imageCache
}) => {
  const { assignees, status, exportOptions } = value
  const [currentUser, setCurrentUser] = React.useState()
  if (!currentUser && assignees.length) {
    setCurrentUser(assignees[0])
  }
  const changeUser = newUser => setCurrentUser(newUser.user)
  if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  }
  const bodyWithImages = addImagesToBody(value.body, imageCache)
  return (
    <SimulateWrapper
      headless={headless}
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <SimulateHeader
        selectedUser={currentUser}
        onCurrentUserChange={changeUser}
        userOptions={assignees}
      />
      <SimulatedEmail
        branding={branding}
        toEmail={currentUser?.email}
        subject={value.subject}
        body={bodyWithImages}
        exportOptions={exportOptions}
      />
    </SimulateWrapper>
  )
}

const addVariableNamesToSubject = (subject, fieldsAll) => {
  const formFields = keyBy(fieldsAll, 'formKey')
  return subject.replace(/{{([^}]*)}}/g, (_, key) => {
    const label = get(formFields[key], 'label', 'Missing')
    return `[${label}]`
  })
}

export default {
  name: 'Notification',
  Icon: Icons.Email,
  color: '#efba4d',
  validate: (value, fieldsAll, lineage) => [
    ...PersonPicker.validate(value, fieldsAll),
    ...EmailBuilder.validate(value, fieldsAll, lineage)
  ],
  defaultTemplate: () => ({
    ...PersonPicker.defaults(),
    ...EmailBuilder.defaults()
  }),
  Config,
  Node,
  Simulate
}
