/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, get, range, reduce, set } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { useFormbot } from '../../engine/formbot-react/hooks'

export default function TableEdit ({
  value,
  onChange,
  details,
  fbRenderEdit,
  ...props
}) {
  const formbot = useFormbot()
  const initEmptyItem = React.useCallback(() => {
    return reduce(
      get(details, 'gadgets'),
      (item, gadget) => {
        const definition = formbot.getGadget(gadget.type)
        const defaultValue = definition?.defaultValue ?? null
        item[gadget.formKey] = cloneDeep(defaultValue)
        return item
      },
      {}
    )
  }, [details, formbot])
  const handleAdd = React.useCallback(
    e => {
      e.preventDefault()
      onChange([...value, initEmptyItem()])
    },
    [value, initEmptyItem]
  )

  const gadgets = get(details, 'gadgets', [])
  const minRows = get(details, 'minRows', null)
  const maxRows = get(details, 'maxRows', null)
  const numRows = get(value, 'length', 0)

  const disableRemoveButton = minRows === null ? false : numRows <= minRows
  const disableAddRow = maxRows === null ? false : numRows >= maxRows

  React.useEffect(() => {
    if (minRows === null) return
    const tableLength = get(value, 'length', 0)
    if (tableLength < minRows) {
      const minValues = range(tableLength, minRows).map(() => initEmptyItem())
      onChange([...value, ...minValues])
    }
  }, [minRows, value])

  const headers = React.useMemo(() => {
    const headerCells = gadgets.map(gadget => (
      <HeaderCell key={gadget.formKey}>{gadget.label}</HeaderCell>
    ))
    headerCells.push(<HeaderCell key='__actions' />)
    return headerCells
  }, [gadgets])

  const handleItemChange = (index, item) => {
    const valueCopy = cloneDeep(value)
    if (item === null) valueCopy.splice(index, 1)
    else valueCopy.splice(index, 1, item)
    onChange(valueCopy)
  }

  return (
    <>
      {numRows > 0 && (
        <div>
          <Table cellspacing='0'>
            <thead>
              <HeaderRow className='bg-light-gray-200 dark:bg-light-gray-300'>
                {headers}
              </HeaderRow>
            </thead>
            <TableBody>
              {value.map((item, index) => (
                <TableEditRow
                  key={index}
                  item={item}
                  index={index}
                  gadgets={gadgets}
                  fbRenderEdit={fbRenderEdit}
                  onChange={handleItemChange}
                  disableRemoveButton={disableRemoveButton}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {!disableAddRow && (
        <div>
          <Button disabled={disableAddRow} outline onClick={handleAdd} mt={3}>
            <Icons.Add mr={2} />
            <Trans id='add.another.row' />
          </Button>
        </div>
      )}
    </>
  )
}

function TableEditRow ({
  item,
  index,
  gadgets,
  fbRenderEdit,
  onChange,
  disableRemoveButton
}) {
  const handleChange = (key, value) => {
    const newItem = cloneDeep(item)
    set(newItem, key, value)
    onChange(index, newItem)
  }
  const handleRemove = React.useCallback(
    e => {
      e.preventDefault()
      if (disableRemoveButton) return
      onChange(index, null)
    },
    [disableRemoveButton, index, onChange]
  )
  return (
    <TableRow>
      {gadgets.map(gadget => (
        <TableEditCell
          key={gadget.formKey}
          index={index}
          value={get(item, gadget.formKey)}
          gadget={gadget}
          onChange={handleChange}
          fbRenderEdit={fbRenderEdit}
        />
      ))}
      <TableCell>
        <CellWrapper>
          <RemoveButtonWrapper className='border-b border-r border-[#e7e7e7] dark:border-light-gray-300'>
            <Button
              disabled={disableRemoveButton}
              transparent
              title={i18n._('remove.row')}
              icon
              small
              onClick={handleRemove}
            >
              <DeleteIcon />
            </Button>
          </RemoveButtonWrapper>
        </CellWrapper>
      </TableCell>
    </TableRow>
  )
}

function TableEditCell ({ index, value, gadget, onChange, fbRenderEdit }) {
  const handleChange = newValue => onChange(gadget.formKey, newValue)
  return (
    <TableCell>
      <CellWrapper>
        {fbRenderEdit(
          gadget.type,
          value,
          gadget.details,
          handleChange,
          `${index}.${gadget.formKey}`,
          `${index}.${gadget.formKey}`
        )}
      </CellWrapper>
    </TableCell>
  )
}

const Table = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  padding: 0;
  height: 1px; /* Hack to get chrome to render table cells without auto padding */
`

const HeaderRow = styled.tr`
  padding: 0;
`

const HeaderCell = styled.th`
  text-align: left;
  border-right: 1px solid #dadada;
  border: 1px solid #e7e7e7;
  html.dark & {
    // Outlier: dark:border-light-gray-300
    border-right: 1px solid #333;
    border: 1px solid #333;
  }
  border-bottom: none;
  border-right: none;
  padding: 8px 16px;
  &:last-child {
    border-right: 1px solid #e7e7e7;
  }
`

const TableBody = styled.tbody`
  padding: 0;
`

const TableRow = styled.tr`
  padding: 0;
  height: 100%;
  border-left: 1px solid #e7e7e7;
  html.dark & {
    // Outlier: dark:border-light-gray-300
    border-left: 1px solid #333;
  }
`

const TableCell = styled.td`
  padding: 0;
  height: inherit;
  html.dark & input {
    // Outlier
    background: none;
  }
`

const RemoveButtonWrapper = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`

const CellWrapper = styled.div`
  margin-left: -1px;
  display: grid;
  height: 100%;
`

const DeleteIcon = styled(Icons.Delete)`
  [disabled=''] > & {
    fill: #d7d7d7;
    html.dark & {
      // Outlier: dark:fill-medium-gray-800
      fill: #888;
    }
  }
`
