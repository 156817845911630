/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import {
  endsWith,
  filter,
  find,
  get,
  includes,
  invert,
  isEmpty,
  map,
  startsWith,
  trim
} from 'lodash'
import React from 'react'

export function check (data, config, formData) {
  switch (config.type) {
    case 'beginsWith':
      return startsWith(data, config.value)
    case 'contains':
      return includes(data, config.value)
    case 'notBeginsWith':
      return !startsWith(data, config.value)
    case 'notContains':
      return !includes(data, config.value)
    case 'notEndsWith':
      return !endsWith(data, config.value)
    case 'endsWith':
      return endsWith(data, config.value)
    case 'is':
      return data === config.value
    case 'isEmpty':
      return isEmpty(data)
    case 'isNot':
      return data !== config.value
    case 'isNotEmpty':
      return !isEmpty(data)
    case 'isSameAs': {
      const value = get(formData, config.value.formKey)
      if (value == null) return false
      return trim(data) === trim(value)
    }
    case 'isNotSameAs': {
      const value = get(formData, config.value.formKey)
      if (value == null) return false
      return trim(data) !== trim(value)
    }
    default:
      return true
  }
}

export function configIsValid (configData) {
  if (includes(['isEmpty', 'isNotEmpty'], configData.type)) return true
  return configData.type && configData.value
}

export function component ({ value, onChange, gadgets }) {
  const handleRefChange = e => {
    const formKey = e.target.value
    const gadget = find(gadgets, { formKey }) || null
    if (!gadget) return onChange({ ...value, value: null })
    onChange({ ...value, value: { formKey, label: gadget.label } })
  }
  const textGadgets = filter(gadgets, { type: 'Text' })
  return (
    <div className='flex items-center gap-2'>
      <select
        className='kp-select flex-1'
        onChange={e => onChange({ ...value, type: e.target.value })}
        value={value.type || ''}
      >
        <option value='' />
        <option value='beginsWith'>
          <Trans id='begins.with' />
        </option>
        <option value='contains'>
          <Trans id='contains' />
        </option>
        <option value='notBeginsWith'>
          <Trans id='does.not.begin.with' />
        </option>
        <option value='notContains'>
          <Trans id='does.not.contain' />
        </option>
        <option value='notEndsWith'>
          <Trans id='does.not.end.with' />
        </option>
        <option value='endsWith'>
          <Trans id='ends.with' />
        </option>
        <option value='is'>
          <Trans id='is' />
        </option>
        <option value='isEmpty'>
          <Trans id='is.empty' />
        </option>
        <option value='isNot'>
          <Trans id='is.not' />
        </option>
        <option value='isNotEmpty'>
          <Trans id='is.not.empty' />
        </option>
        <option value='isSameAs'>
          <Trans id='is.same.as' />
        </option>
        <option value='isNotSameAs'>
          <Trans id='is.not.same.as' />
        </option>
      </select>
      {hasTextValue(value.type) && (
        <input
          type='text'
          className='kp-input flex-1'
          onChange={e => onChange({ ...value, value: e.target.value })}
          value={value.value || ''}
        />
      )}
      {hasRefValue(value.type) && (
        <select
          className='kp-select flex-1'
          onChange={handleRefChange}
          value={value.value?.formKey}
        >
          <option value='' />
          {map(textGadgets, gadget => (
            <option key={gadget.formKey} value={gadget.formKey}>
              {gadget.label}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

const hasTextValue = type =>
  type && !includes(['isEmpty', 'isNotEmpty', 'isSameAs', 'isNotSameAs'], type)

const hasRefValue = type => includes(['isSameAs', 'isNotSameAs'], type)

const uiToWf = {
  beginsWith: 'begins with',
  contains: 'contains',
  notBeginsWith: 'does not begin with',
  notContains: 'does not contain',
  notEndsWith: 'does not end with',
  endsWith: 'ends with',
  is: 'is',
  isEmpty: 'is empty',
  isNot: 'is not',
  isNotEmpty: 'is not empty',
  isSameAs: 'is same as',
  isNotSameAs: 'is not same as'
}
const wfToUi = invert(uiToWf)

export function toWorkflowFormat (expr, gadget) {
  const newExpr = {
    left: {
      dataType: 'text',
      formKey: expr.formKey,
      label: gadget.label
    },
    operator: uiToWf[expr.data.type]
  }
  if (hasTextValue(expr.data.type)) {
    newExpr.right = {
      dataType: 'text',
      value: expr.data.value
    }
  }
  if (hasRefValue(expr.data.type)) {
    newExpr.right = {
      dataType: 'text',
      formKey: expr.data.value?.formKey,
      label: expr.data.value?.label
    }
  }
  return newExpr
}

export function fromWorkflowFormat (expr) {
  const newExpr = {
    formKey: expr.left.formKey,
    data: { type: wfToUi[expr.operator] }
  }
  if (hasTextValue(newExpr.data.type)) {
    newExpr.data.value = expr.right.value
  }
  if (hasRefValue(newExpr.data.type)) {
    newExpr.data.value = {
      formKey: expr.right.formKey,
      label: expr.right.formKey
    }
  }
  return newExpr
}
