/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useSimulateActionMutation = () => {
  const [simulateAction] = useMutation(mutation)
  return simulateAction
}

const mutation = gql`
  mutation SimulateAction($id: ID!, $action: String!, $data: JSON) {
    simulateAction(args: { id: $id, action: $action, data: $data })
  }
`
