/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M13.4333 2.69333C13.3582 2.67799 13.2906 2.63718 13.242 2.57779C13.1934 2.51839 13.1668 2.44406 13.1667 2.36733V1.33333C13.1667 0.979711 13.0262 0.640573 12.7761 0.390524C12.5261 0.140476 12.187 0 11.8333 0L3.5 0C2.96957 0 2.46086 0.210714 2.08579 0.585786C1.71071 0.960859 1.5 1.46957 1.5 2V14C1.5 14.5304 1.71071 15.0391 2.08579 15.4142C2.46086 15.7893 2.96957 16 3.5 16H13.1667C13.5203 16 13.8594 15.8595 14.1095 15.6095C14.3595 15.3594 14.5 15.0203 14.5 14.6667V4C14.5001 3.69263 14.3939 3.39467 14.1995 3.15656C14.0052 2.91845 13.7345 2.75481 13.4333 2.69333ZM3.5 1.33333H11.6667C11.7109 1.33333 11.7533 1.35089 11.7845 1.38215C11.8158 1.41341 11.8333 1.4558 11.8333 1.5V2.5C11.8333 2.5442 11.8158 2.5866 11.7845 2.61785C11.7533 2.64911 11.7109 2.66667 11.6667 2.66667H3.5C3.32319 2.66667 3.15362 2.59643 3.0286 2.4714C2.90357 2.34638 2.83333 2.17681 2.83333 2C2.83333 1.82319 2.90357 1.65362 3.0286 1.5286C3.15362 1.40357 3.32319 1.33333 3.5 1.33333Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.4333 2.69333C13.3582 2.67799 13.2906 2.63718 13.242 2.57779C13.1934 2.51839 13.1668 2.44406 13.1667 2.36733V1.33333C13.1667 0.979711 13.0262 0.640573 12.7761 0.390524C12.5261 0.140476 12.187 0 11.8333 0L3.5 0C2.96957 0 2.46086 0.210714 2.08579 0.585786C1.71071 0.960859 1.5 1.46957 1.5 2V14C1.5 14.5304 1.71071 15.0391 2.08579 15.4142C2.46086 15.7893 2.96957 16 3.5 16H13.1667C13.5203 16 13.8594 15.8595 14.1095 15.6095C14.3595 15.3594 14.5 15.0203 14.5 14.6667V4C14.5001 3.69263 14.3939 3.39467 14.1995 3.15656C14.0052 2.91845 13.7345 2.75481 13.4333 2.69333ZM3.5 1.33333H11.6667C11.7109 1.33333 11.7533 1.35089 11.7845 1.38215C11.8158 1.41341 11.8333 1.4558 11.8333 1.5V2.5C11.8333 2.5442 11.8158 2.5866 11.7845 2.61785C11.7533 2.64911 11.7109 2.66667 11.6667 2.66667H3.5C3.32319 2.66667 3.15362 2.59643 3.0286 2.4714C2.90357 2.34638 2.83333 2.17681 2.83333 2C2.83333 1.82319 2.90357 1.65362 3.0286 1.5286C3.15362 1.40357 3.32319 1.33333 3.5 1.33333Z\" />\n</svg>\n";
export default src;
