/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346633 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87103 0 6.41775 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.96571 10.6057L3.96571 8.60572C3.8052 8.445 3.71504 8.22715 3.71504 8C3.71504 7.77286 3.8052 7.555 3.96571 7.39429L5.96571 5.39429C6.08215 5.2832 6.22751 5.20713 6.38516 5.1748C6.54281 5.14246 6.70637 5.15515 6.85714 5.21143C7.0135 5.27534 7.14714 5.38461 7.24084 5.52516C7.33454 5.66571 7.384 5.83109 7.38286 6V6.85715C7.38286 6.93292 7.41296 7.0056 7.46654 7.05918C7.52012 7.11276 7.5928 7.14286 7.66857 7.14286H11.4286C11.6559 7.14286 11.8739 7.23317 12.0347 7.39391C12.1954 7.55466 12.2857 7.77268 12.2857 8C12.2857 8.22733 12.1954 8.44535 12.0347 8.6061C11.8739 8.76684 11.6559 8.85715 11.4286 8.85715H7.71429C7.63851 8.85715 7.56584 8.88725 7.51226 8.94083C7.45867 8.99441 7.42857 9.06708 7.42857 9.14286V10C7.42621 10.1743 7.37004 10.3436 7.26775 10.4848C7.16546 10.626 7.02205 10.7321 6.85714 10.7886C6.70637 10.8449 6.54281 10.8575 6.38516 10.8252C6.22751 10.7929 6.08215 10.7168 5.96571 10.6057Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346633 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87103 0 6.41775 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.96571 10.6057L3.96571 8.60572C3.8052 8.445 3.71504 8.22715 3.71504 8C3.71504 7.77286 3.8052 7.555 3.96571 7.39429L5.96571 5.39429C6.08215 5.2832 6.22751 5.20713 6.38516 5.1748C6.54281 5.14246 6.70637 5.15515 6.85714 5.21143C7.0135 5.27534 7.14714 5.38461 7.24084 5.52516C7.33454 5.66571 7.384 5.83109 7.38286 6V6.85715C7.38286 6.93292 7.41296 7.0056 7.46654 7.05918C7.52012 7.11276 7.5928 7.14286 7.66857 7.14286H11.4286C11.6559 7.14286 11.8739 7.23317 12.0347 7.39391C12.1954 7.55466 12.2857 7.77268 12.2857 8C12.2857 8.22733 12.1954 8.44535 12.0347 8.6061C11.8739 8.76684 11.6559 8.85715 11.4286 8.85715H7.71429C7.63851 8.85715 7.56584 8.88725 7.51226 8.94083C7.45867 8.99441 7.42857 9.06708 7.42857 9.14286V10C7.42621 10.1743 7.37004 10.3436 7.26775 10.4848C7.16546 10.626 7.02205 10.7321 6.85714 10.7886C6.70637 10.8449 6.54281 10.8575 6.38516 10.8252C6.22751 10.7929 6.08215 10.7168 5.96571 10.6057Z\" />\n</svg>\n";
export default src;
