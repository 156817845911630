/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const CALC_FUNCTIONS = {
  SUM: 'sum',
  SUBTRACT: 'subtract',
  PRODUCT: 'product',
  MIN: 'min',
  MAX: 'max',
  AVG: 'avg',
  MEDIAN: 'median',
  MODE: 'mode',
  RANGE: 'range',
  COUNT: 'count'
}
