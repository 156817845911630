/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, findIndex, get, last } from 'lodash'
import React from 'react'

import FYI from '../../components/fyi'
import { useLocalStorageState } from '../../components/use-local-storage-state'
import * as Icons from '../../icons'
import Checkbox from '../../ui/checkbox'
import * as CustomTitle from '../components/custom-title'
import * as EchoBuilder from '../components/echo-builder'
import {
  InfoPage,
  InfoTitle,
  SimulateWrapper
} from '../components/simulator-parts'
import SimulationError from '../components/simulator-parts/error-page'
import { Simulation } from '../engine/simulate'

const Node = ({ details, Flowbot, fbProps }) => {
  return (
    <div key={details.clientId} className='p-2'>
      <div
        className='absolute w-[calc(100%-16px)] overflow-hidden overflow-ellipsis whitespace-nowrap px-1 font-bold'
        title={i18n._('for.each.value.in.label', {
          label: details?.echoField?.label
        })}
      >
        <Trans
          id='for.each.value.in.label'
          values={{ label: details?.echoField?.label }}
        />
      </div>
      <div className='h-8 w-64' />
      <Flowbot
        parentId={`${details.clientId}::0`}
        a11yLabel={`in echo subflow for ${details.stepName || 'Echo Step'}`}
        nodes={details.subflows[0].steps}
        {...fbProps}
        lineage={[...(fbProps.lineage || []), details._id]}
      />
    </div>
  )
}

const Config = ({ value, updateValue, errors, fieldsIterable }) => (
  <>
    <FYI
      className='m-4 bg-yellow-50 px-4 py-2 shadow-md dark:bg-light-gray-500'
      icon={<Icons.AlertWarning className='mt-1 fill-orange-400' />}
      messageKey='echo_step_warning'
      maxClose={2}
    >
      <div>
        <div className='text-base font-medium'>
          <Trans id='be.careful' />
        </div>
        <div>
          <Trans id='any.workflow.steps.add.repeated' />
        </div>
      </div>
    </FYI>
    <CustomTitle.Config
      value={value}
      updateValue={updateValue}
      defaultName='Echo'
      label={i18n._('step.label')}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
    <EchoBuilder.Config
      value={value}
      updateValue={updateValue}
      errors={errors}
      fieldsIterable={fieldsIterable}
    />
    <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
  </>
)

export const Simulate = ({ value, ...rest }) => {
  const activeSubflowIndex = findIndex(value.subflows, { active: true })
  const activeSubflow = value.subflows?.[activeSubflowIndex]
  const hasInnerSubflow = last(activeSubflow?.states)?.type === 'echo'
  return !hasInnerSubflow ? (
    <div className='flex h-full flex-col border-4 border-orange-400'>
      <div className='absolute -top-0 left-0 -translate-x-full bg-orange-400 p-2 font-medium text-white'>
        <div>
          <Trans id='echo' />
        </div>
        {activeSubflowIndex >= 0 ? (
          <div>
            <Trans id='item' /> {activeSubflowIndex + 1}/{value.subflows.length}
          </div>
        ) : null}
      </div>
      <SimulateInner value={value} {...rest} />
    </div>
  ) : (
    <SimulateInner value={value} {...rest} />
  )
}
const SimulateInner = ({
  form,
  headless,
  value,
  simulateAction,
  simulationState,
  ...rest
}) => {
  const [simulatorPreferences, setSimulatorPreferences] = useLocalStorageState(
    'simulatorPreferences',
    {}
  )
  const { status } = value
  if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  }
  if (status === 'No Values') {
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <NoValues />
      </SimulateWrapper>
    )
  }
  const activeSubflow = find(get(value, 'subflows', []), sf => sf.active)
  if (!activeSubflow) {
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <Intro
          toggleShowIntros={checked => {
            simulationState.meta.simulatorPreferences.hideEchoIntro = checked
            setSimulatorPreferences({
              ...simulatorPreferences,
              hideEchoIntro: checked
            })
          }}
        />
      </SimulateWrapper>
    )
  } else {
    return (
      <SubflowWrapper
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
        {...rest}
      />
    )
  }
}

const NoValues = () => {
  return (
    <InfoPage justifyContent='center' flexDirection='column'>
      <Icons.Echo className='h-[72px] w-[72px] fill-orange-400' />
      <InfoTitle>
        <Trans id='no.values' />
      </InfoTitle>
      <div className='w-72 text-center text-base'>
        <Trans id='no.values.in.field.enumerating.nothing' />
      </div>
    </InfoPage>
  )
}

const Intro = ({ toggleShowIntros }) => {
  return (
    <InfoPage justifyContent='center' flexDirection='column'>
      <Icons.Echo className='h-[72px] w-[72px] fill-orange-400' />
      <InfoTitle>
        <Trans id='echo' />
      </InfoTitle>
      <div className='w-72 text-center text-base'>
        <Trans id='workflow.performs.multiple.tasks.occur' />
      </div>
      <div className='mt-8 text-center text-sm text-dark-gray-400'>
        <Checkbox
          id='disable-echo-intro'
          label={i18n._('do.not.show.again')}
          onChange={toggleShowIntros}
        />
      </div>
    </InfoPage>
  )
}

const SubflowWrapper = ({ headless, value, ...props }) => {
  const activeSubflowIndex = findIndex(value.subflows, { active: true })
  const activeSubflow = value.subflows[activeSubflowIndex]
  return (
    <SimulateWrapper headless={headless} buttons={<div />}>
      <SubStep
        {...props}
        subflow={activeSubflow}
        subflowIndex={activeSubflowIndex}
        value={value}
      />
    </SimulateWrapper>
  )
}

const SubStep = ({
  simulateAction,
  simulationState,
  subflow,
  subflowIndex,
  value,
  workflow,
  ...props
}) => {
  const latestState = last(subflow.states)
  if (latestState.status === 'complete') {
    return (
      <SimulateWrapper
        headless
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <InfoPage justifyContent='center' flexDirection='column'>
          <Icons.Like className='h-[72px] w-[72px] fill-orange-400' />
          <InfoTitle>
            <Trans
              id='echo.path.complete.of'
              values={{
                sub: subflowIndex + 1,
                length: value?.subflows?.length
              }}
            />
          </InfoTitle>
        </InfoPage>
      </SimulateWrapper>
    )
  }
  const stepDefinition = find(workflow.steps, { _id: value.stepDefinitionId })
  const subflowDefinition = find(stepDefinition.subflows, {
    _id: subflow.flowId
  })
  return (
    <Simulation
      {...props}
      headless
      simulateAction={simulateAction}
      simulationState={simulationState}
      value={latestState}
      workflow={subflowDefinition}
    />
  )
}

export default {
  name: 'Echo',
  Icon: Icons.Echo,
  color: '#EF6C05',
  truncate: () => true,
  validate: EchoBuilder.validate,
  defaultTemplate: () => ({
    echoField: null,
    subflows: [{ steps: [] }]
  }),
  helpText: <Trans id='step.will.repeat.whatever.nested.inside.each' />,
  Config,
  Node,
  Simulate
}
