/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`49`} height={`59`} viewBox={`0 0 49 59`} fill={`none`}{...props}><path d={`M48.875 27.8178C48.875 26.8876 48.6918 25.9665 48.3358 25.1071C47.9798 24.2477 47.4581 23.4669 46.8003 22.8091C46.1426 22.1514 45.3617 21.6296 44.5023 21.2736C43.643 20.9177 42.7219 20.7345 41.7917 20.7345H23.9105C23.7987 20.7342 23.6885 20.7075 23.5889 20.6565C23.4894 20.6055 23.4033 20.5316 23.3378 20.441C23.2723 20.3503 23.2292 20.2454 23.212 20.1349C23.1949 20.0244 23.2041 19.9114 23.239 19.8051C24.8031 15.8408 25.3108 11.5381 24.7123 7.31862C23.0463 1.36862 19.516 -0.331378 16.7563 0.0511225C15.399 0.281356 14.1719 0.997837 13.3043 2.06673C12.4367 3.13562 11.9879 4.48381 12.0417 5.85946C12.0417 13.6398 6.0095 22.1398 0.612001 25.8656C0.42303 25.996 0.268588 26.1704 0.161977 26.3738C0.0553649 26.5771 -0.000222305 26.8034 6.68158e-07 27.033V55.695C-7.03793e-05 56.024 0.114417 56.3428 0.323807 56.5967C0.533197 56.8506 0.824428 57.0236 1.1475 57.0861C2.159 57.2788 3.077 57.4686 3.944 57.6528C7.75676 58.5597 11.6642 59.0077 15.5833 58.9873H34C39.5477 58.9873 40.375 55.8706 40.375 54.0289C40.3762 53.0514 40.1522 52.0868 39.7205 51.2098C41.2154 50.45 42.3509 49.132 42.8811 47.5411C43.4114 45.9503 43.2938 44.2146 42.5538 42.7098C43.3129 42.3241 43.987 41.7903 44.5364 41.1399C45.0858 40.4894 45.4993 39.7355 45.7526 38.9226C46.0059 38.1097 46.0937 37.2544 46.011 36.407C45.9283 35.5596 45.6767 34.7373 45.271 33.9888C46.3648 33.372 47.275 32.4755 47.9083 31.3911C48.5416 30.3067 48.8752 29.0735 48.875 27.8178Z`} fill={`#3369A3`}/></svg>
);

let src = "<svg width=\"49\" height=\"59\" viewBox=\"0 0 49 59\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M48.875 27.8178C48.875 26.8876 48.6918 25.9665 48.3358 25.1071C47.9798 24.2477 47.4581 23.4669 46.8003 22.8091C46.1426 22.1514 45.3617 21.6296 44.5023 21.2736C43.643 20.9177 42.7219 20.7345 41.7917 20.7345H23.9105C23.7987 20.7342 23.6885 20.7075 23.5889 20.6565C23.4894 20.6055 23.4033 20.5316 23.3378 20.441C23.2723 20.3503 23.2292 20.2454 23.212 20.1349C23.1949 20.0244 23.2041 19.9114 23.239 19.8051C24.8031 15.8408 25.3108 11.5381 24.7123 7.31862C23.0463 1.36862 19.516 -0.331378 16.7563 0.0511225C15.399 0.281356 14.1719 0.997837 13.3043 2.06673C12.4367 3.13562 11.9879 4.48381 12.0417 5.85946C12.0417 13.6398 6.0095 22.1398 0.612001 25.8656C0.42303 25.996 0.268588 26.1704 0.161977 26.3738C0.0553649 26.5771 -0.000222305 26.8034 6.68158e-07 27.033V55.695C-7.03793e-05 56.024 0.114417 56.3428 0.323807 56.5967C0.533197 56.8506 0.824428 57.0236 1.1475 57.0861C2.159 57.2788 3.077 57.4686 3.944 57.6528C7.75676 58.5597 11.6642 59.0077 15.5833 58.9873H34C39.5477 58.9873 40.375 55.8706 40.375 54.0289C40.3762 53.0514 40.1522 52.0868 39.7205 51.2098C41.2154 50.45 42.3509 49.132 42.8811 47.5411C43.4114 45.9503 43.2938 44.2146 42.5538 42.7098C43.3129 42.3241 43.987 41.7903 44.5364 41.1399C45.0858 40.4894 45.4993 39.7355 45.7526 38.9226C46.0059 38.1097 46.0937 37.2544 46.011 36.407C45.9283 35.5596 45.6767 34.7373 45.271 33.9888C46.3648 33.372 47.275 32.4755 47.9083 31.3911C48.5416 30.3067 48.8752 29.0735 48.875 27.8178Z\" fill=\"#3369A3\"/>\n</svg>";
export default src;
