/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { OptionalConfig } from './config'
import Edit from './edit'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validation'
import View from './view'

export default {
  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,
  OptionalConfig,
  defaultValue: '',
  Edit,
  meta: {
    category: 'Basic',
    Icon,
    label: 'Link'
  },
  validations: {
    regex: {
      enabled: true,
      value: {
        re: '^https?://',
        message: 'link must start with http:// or https://'
      }
    },
    safeTextLength: { enabled: true }
  },
  progressiveDisclosure,
  sampleValue: 'https://kuali.co',
  validateShape,
  View
}
