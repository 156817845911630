/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'

import AppsOrProducts from '../../components/apps-or-products'
import { ModalPage } from '../../components/modal-page'
import { Tab, TabInner, Tabs } from '../../ui/tabs'

export default function Usage () {
  return (
    <ModalPage
      title={
        <span className='relative'>
          <span
            after={i18n._({
              id: 'experimental',
              message: 'experimental'
            })}
            className='after:absolute after:left-16 after:rounded-sm after:bg-yellow-200 after:px-2 after:text-[9px] after:font-medium after:uppercase after:text-dark-gray-500 after:content-[attr(after)] dark:after:text-white'
          />
          <Trans id='usage' />
        </span>
      }
      nav={
        <Tabs>
          <Tab to='general'>
            <TabInner>
              <Trans id='general' />
            </TabInner>
          </Tab>
          <Tab to='apps'>
            <TabInner>
              <AppsOrProducts />
            </TabInner>
          </Tab>
        </Tabs>
      }
    >
      <Outlet />
    </ModalPage>
  )
}
