/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { isNil } from 'lodash'
import React from 'react'

import { Option, Select } from '../../../ui/select'

export default function BooleanEdit ({
  details,
  onChange,
  errors,
  id,
  value,
  ...props
}) {
  const options = [
    { key: 'true', lbl: i18n._('true'), val: true },
    { key: 'false', lbl: i18n._('false'), val: false }
  ]
  return (
    <>
      <Select
        aria-labelledby={props['aria-labelledby']}
        {...(props['aria-describedby'] && {
          'aria-describedby': props['aria-describedby']
        })}
        aria-required={props.required}
        id={id}
        onChange={val => {
          const booleanVal = isNil(val) ? val : Boolean(val)
          onChange(booleanVal)
        }}
        value={value || ''}
      >
        <Option value=''>- - -</Option>
        {options.map(({ lbl, key, val }) => (
          <Option key={key} value={val}>
            {lbl}
          </Option>
        ))}
      </Select>
    </>
  )
}
