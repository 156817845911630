/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { isEqual } from 'lodash'
import React from 'react'

import { BrandingHeader } from '../../../components/branding-header'
import useFocusFirstInput from '../../../components/use-focus-first-input'
import StaticFormbot from '../../static'

export default class FormbotEdit extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.props, nextProps)
  }

  render () {
    return <FormbotEditInternal {...this.props} />
  }
}

function FormbotEditInternal (props) {
  const {
    className,
    context,
    document,
    Formbot,
    multipageNum,
    onChange,
    structure,
    noGrid,
    highlight,
    hideOfficeUse,
    branding
  } = props
  const focusRef = useFocusFirstInput()
  return (
    <div className={className} ref={focusRef}>
      {branding && <BrandingHeader branding={branding} />}

      {Formbot.render('Edit', structure, document, {
        context,
        onChange,
        multipageNum,
        noGrid,
        highlight,
        hideOfficeUse,
        StaticFormbot
      })}
    </div>
  )
}
