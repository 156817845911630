/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { nothing } from 'immer'
import {
  cloneDeep,
  filter,
  get,
  includes,
  isEmpty,
  keyBy,
  set,
  some
} from 'lodash'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import shortid from 'shortid'

import isEditable from '../../components/is-editable'
import { PortalOrange } from '../../components/portals'
import onWindowClick from '../../components/window-click'
import { FormbotDND, formbotDND } from '../../formbot'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import * as VoronoiDND from '../../voronoi-dnd'
import { utils } from '../../voronoi-dnd-formbot'
import { useUndoRedoImmer } from '../workflow/components/use-undo-redo-immer'
import Config from './config'
import EmptyConfig from './empty-config'
import GadgetList from './gadget-list'
import InitialContent from './initial-content'
import * as UI from './ui'

const {
  traverseForm,
  traverseFormSafe,
  addGadgetToForm,
  findGadgetById,
  buildIsInvalidFunction,
  removeGadgetFromForm,
  clearUselessLayout,
  getDropZoneProps,
  getAriaLabel
} = utils

const linkableGadgets = ['IntegrationFill', 'IntegrationTypeahead']

export default function FormDND (props) {
  const [selected, setSelected] = React.useState(null)
  const [previewItem, setPreviewItem] = React.useState({})
  const [dragging, setDragging] = React.useState(false)
  const [a11yDragItem, setA11yDragItem] = React.useState(null)
  const [isInvalid, setIsInvalid] = React.useState(() => () => false)
  const [configErrors, setConfigErrors] = React.useState({})
  const [configUpdated, setConfigUpdated] = React.useState(false)
  const [copied, setCopied] = React.useState(null)
  const container = React.useRef()
  const configPanel = React.useRef()
  const gadgetList = React.useRef()
  const alerts = useAlerts()
  const [searchParams, setSearchParams] = useSearchParams()

  const [, update, undo, redo] = useUndoRedoImmer(
    props.template,
    undefined,
    props.update
  )

  const gadgetMapById = {}
  traverseForm(props.template, (gadget, parent) => {
    gadgetMapById[gadget.id] = { ...gadget, parent }
  })

  const gadgetIndexTypesMap = keyBy(props.gadgetIndexTypes, 'id')
  const canonicalGadgets = filter(props.gadgetIndexTypes, 'canonicalGadget')
  const canonicalGadgetsMap = keyBy(canonicalGadgets, 'id')

  const availableCanonicalGadgets =
    !!canonicalGadgets.length &&
    canonicalGadgets
      .filter(git => !gadgetMapById[git.id])
      .map(git => git.canonicalGadget)

  React.useEffect(() => {
    let cleanup
    const timeout = setTimeout(() => {
      cleanup = onWindowClick(() => {
        if (selected) setSelected(null)
      })
    }, 0)
    return () => {
      clearTimeout(timeout)
      cleanup?.()
    }
  }, [selected, configUpdated, props.template])

  const flagBasedUpdate = (changes, cacheOrDatabase) =>
    update(() => changes ?? nothing, cacheOrDatabase)

  const showGadget = searchParams.get('showGadget')
  React.useEffect(() => {
    if (showGadget) {
      setSearchParams(prev => prev.delete('showGadget'), { replace: true })
    }
    const gadget = showGadget && findGadgetById(props.template, showGadget)
    if (gadget) {
      setSelected(cloneDeep(gadget))
      const el = document.getElementById(`gadget-${showGadget}`)
      if (!el) return
      const { y } = el.getBoundingClientRect()
      if (y + 400 > window.innerHeight) {
        const amountToScroll = y - window.innerHeight + 400
        const el2 = container.current
        const top = el2.scrollTop + amountToScroll
        el2.scrollTo({ top })
      }
    }
  }, [showGadget, setSearchParams, props.onShowGadget, props.template])

  const handleDrop = (dropContext, dragContext) => {
    if (a11yDragItem) {
      endA11yDrag(true)
    }
    let { id: gid, dir } = dropContext
    let { id, type, details, canonicalGadget } = dragContext
    let form = cloneDeep(props.template)
    if (dir === 'inside') gid = gid.substring(6)
    if (canonicalGadget) {
      const isInvalid = buildIsInvalidFunction(form, dragContext, gadgetMapById)
      if (isInvalid(gid, dir)) return
      const formKey = canonicalGadget.formKey.split('.*.').pop()
      form = addGadgetToForm(form, gid, dir, { ...canonicalGadget, formKey })
    } else if (type) {
      const isInvalid = buildIsInvalidFunction(form, { type }, gadgetMapById)
      if (isInvalid(gid, dir)) return
      const gadgetDef = formbotDND.context.gadgets[type]
      id = shortid.generate()
      const newGadget = {
        type,
        id,
        label: get(gadgetDef, 'meta.hideLabel')
          ? ''
          : `${i18n._('pagesbuilder.form.dnd.new')}` +
            ` ${get(gadgetDef, 'meta.label', type)}`
      }
      if (!gadgetDef.layout) {
        newGadget.formKey = shortid.generate()
      }
      if (get(gadgetDef, 'meta.initialTemplate')) {
        Object.assign(newGadget, gadgetDef.meta.initialTemplate(details))
      }
      if (newGadget.type === 'DataLink') id = null
      form = addGadgetToForm(form, gid, dir, newGadget)
    } else if (id) {
      const gadget = findGadgetById(form, id)
      const isInvalid = buildIsInvalidFunction(form, gadget, gadgetMapById)
      if (isInvalid(gid, dir)) return onSelect(id, form)
      form = removeGadgetFromForm(form, id)
      form = addGadgetToForm(form, gid, dir, gadget)
      form = clearUselessLayout(form)
    }
    flagBasedUpdate(form)
    id && onSelect(id, form)
  }

  const handleUpdatePreview = (path, value) => {
    const previewItemClone = cloneDeep(previewItem)
    set(previewItemClone, path, value)
    setPreviewItem(previewItemClone)
  }

  const onSelect = (id, updatedTemplate) => {
    const template = updatedTemplate || props.template
    const gadget = findGadgetById(template, id)
    const selectedClone = cloneDeep(gadget)
    setSelected(selectedClone)
  }

  const onUpdateConfig = (id, selectedLinks) => async (value, typeUpdated) => {
    let updated
    let form = traverseFormSafe(props.template, (gadget, parent, i) => {
      if (updated) return
      if (gadget.id === id) {
        parent.children[i] = cloneDeep(value)
        updated = true
        const preview = previewItem
        delete preview[gadget.formKey]
        setPreviewItem(preview)
      }
    })
    if (typeUpdated) {
      const [, ...childLinks] = selectedLinks || []
      if (childLinks) {
        for (let i = 0; i < childLinks.length; ++i) {
          form = removeGadgetFromForm(form, childLinks[i])
        }
      }
    }
    setSelected(value)
    try {
      setConfigUpdated(true)
      await flagBasedUpdate(form, 'database')
    } catch (err) {
      if (err?.response?.status === 412 && err?.response?.data?.errors) {
        const matches = err.response.data.errors[0].match(/"(.*?)"/g)
        if (matches?.length === 3) {
          const sourceType = matches.map(match => match.split('"').join(''))[1]
          const errorMsg = i18n._({
            id: 'pagesbuilder.form.dnd.different.field.key',
            values: { source: sourceType }
          })
          const configErrorsClone = cloneDeep(configErrors)
          set(configErrorsClone, [id, 'formKey'], errorMsg)
          setConfigErrors(configErrorsClone)
        }
      }
      throw err
    }
  }

  const { template, user, isTable } = props
  const selectedLinks = getLinked(selected, props.template)
  const [pseudoSelectedLink, setPseudoSelectedLink] = React.useState(null)
  const [pseudoSelected, setPseudoSelected] = React.useState(null)
  const pseudoSelectedLinks = getLinked(pseudoSelected, props.template)

  const beginDrag = (gadget, isSubfield) => {
    setPseudoSelectedLink(
      includes(selectedLinks, gadget?.id) ? gadget.id : null
    )
    setPseudoSelected(isSubfield ? selected : null)
    setSelected(
      selected?.id && gadget?.id && selected.id === gadget.id ? selected : null
    )
    setIsInvalid(() => buildIsInvalidFunction(template, gadget, gadgetMapById))
    setDragging(true)
  }
  const beginA11yDrag = (gadget, isSubfield) => {
    setA11yDragItem(gadget)
    beginDrag(gadget, isSubfield)
  }
  const beginMouseDrag = (gadget, isSubfield) => {
    setA11yDragItem(null)
    beginDrag(gadget, isSubfield)
  }
  const endDrag = (didDrop, gadget) => {
    if (pseudoSelected) {
      setSelected(pseudoSelected)
    }
    if (!didDrop && gadget && gadget.id) {
      setSelected(cloneDeep(findGadgetById(props.template, gadget.id)))
    } else if (!didDrop && pseudoSelectedLink) {
      setSelected(cloneDeep(findGadgetById(props.template, pseudoSelectedLink)))
    }
    setDragging(false)
    setIsInvalid(() => () => false)
    setPseudoSelectedLink(null)
    setPseudoSelected(null)
  }
  const endA11yDrag = didDrop => {
    setA11yDragItem(null)
    endDrag(didDrop)
  }
  const confirmRemoveGadget = async (heading, message) =>
    new Promise(resolve => {
      alerts.type1(
        heading,
        message,
        'error',
        close => (
          <>
            <button
              className='kp-button-transparent mr-2'
              onClick={() => {
                close()
                resolve(false)
              }}
            >
              <Trans id='cancel' />
            </button>
            <button
              className='kp-button-solid'
              onClick={() => {
                close()
                resolve(true)
              }}
            >
              <Trans id='pagesbuilder.form.dnd.continue' />
            </button>
          </>
        ),
        true
      )
    })
  const removeGadget = async gadget => {
    const { id, formKey, type, children } = gadget
    if (type === 'Section' && children && children.length) {
      const shouldRemove = await confirmRemoveGadget(
        i18n._('pagesbuilder.form.dnd.all.removed'),
        i18n._('pagesbuilder.form.dnd.all.removed.data')
      )
      if (!shouldRemove) return
    }
    const links = getLinked(gadget, props.template)
    const hasLinked = links && links[0] === id
    if (hasLinked) {
      const shouldRemove = await confirmRemoveGadget(
        i18n._('pagesbuilder.form.dnd.auto.removed'),
        i18n._('pagesbuilder.form.dnd.auto.removed.data')
      )
      if (!shouldRemove) return
    }
    const conditionalVisibilityReferences = getConditionalVisibilityReferences(
      gadget,
      props.template
    )
    if (conditionalVisibilityReferences.length > 0) {
      const shouldRemove = await confirmRemoveGadget(
        i18n._('pagesbuilder.form.dnd.delete.problems'),
        i18n._('pagesbuilder.form.dnd.delete.problems.data')
      )
      if (!shouldRemove) return
    }
    const workflowReferences = formKey
      ? await props.findReferences(
          formKey.startsWith('data.') ? formKey : `data.${formKey}`
        )
      : []
    if (workflowReferences.length > 0) {
      const shouldRemove = await confirmRemoveGadget(
        i18n._('pagesbuilder.form.dnd.break.workflow'),
        i18n._('pagesbuilder.form.dnd.break.workflow.data')
      )
      if (!shouldRemove) return
    }
    setSelected(null)
    let form = cloneDeep(props.template)
    if (type === 'Section') {
      const integrationGadgets = []
      traverseForm(configSelected, gadget => {
        if (includes(linkableGadgets, gadget.type)) {
          integrationGadgets.push(gadget)
        }
      })
      for (let i = 0; i < integrationGadgets.length; ++i) {
        const gadget = integrationGadgets[i]
        const linked = getLinked(gadget, props.template)
        if (linked) {
          for (let j = 0; j < linked.length; ++j) {
            form = removeGadgetFromForm(form, linked[j])
          }
        }
      }
    }
    if (hasLinked) {
      for (let i = 0; i < links.length; ++i) {
        form = removeGadgetFromForm(form, links[i])
      }
    } else {
      form = removeGadgetFromForm(form, id)
    }
    form = clearUselessLayout(form)
    flagBasedUpdate(form)
    alerts.type3(
      `${gadget.label} ` + `${i18n._('pagesbuilder.form.dnd.deleted')}`,
      'success'
    )
  }

  const duplicateGadget = (gadget, isInTable) => {
    let form = cloneDeep(props.template)
    const newGadget = cloneDeep(gadget)
    const compiled = newGadget.label + `${i18n._('pagesbuilder.form.dnd.copy')}`
    const { id, formKey, label } = gadget
    if (id) newGadget.id = shortid.generate()
    if (formKey) newGadget.formKey = shortid.generate()
    if (label) newGadget.label = compiled
    delete newGadget.customFormKey
    const direction = isInTable ? 'right' : 'bottom'
    if (newGadget.details?.options?.length) {
      newGadget.details.options.forEach(option => {
        option.key = shortid.generate()
      })
    }
    form = addGadgetToForm(form, id, direction, newGadget)
    flagBasedUpdate(form)

    onSelect(newGadget.id, form)
  }

  const keyboardCopyGadget = id => {
    const gadget = findGadgetById(template, id)
    if (includes(['Repeater', 'Section', 'Table'], gadget.type)) {
      return alerts.type3(
        `${gadget.type} ` + `${i18n._('pagesbuilder.form.dnd.cannot.copy')}`,
        'warning'
      )
    }

    const parent = utils.t.findValue(
      template,
      (g, parent) => g.id === id && parent
    )
    setCopied({ gadget, isInTable: parent?.type === 'Table' })
    alerts.type3(
      `${gadget.label} ` + `${i18n._('pagesbuilder.form.dnd.copied')}`,
      'success'
    )
  }

  React.useEffect(() => {
    const fn = e => {
      if (isEditable(e.target)) return
      if (
        (e.ctrlKey || e.metaKey) &&
        e.shiftKey &&
        e.key === 'z' &&
        redo &&
        !configUpdated &&
        !selected
      ) {
        e.preventDefault()
        redo()
        // deselect()
      }
      if (
        (e.ctrlKey || e.metaKey) &&
        !e.shiftKey &&
        e.key === 'z' &&
        undo &&
        !configUpdated &&
        !selected
      ) {
        e.preventDefault()
        undo()
        // deselect()
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'v' && copied) {
        e.preventDefault()
        duplicateGadget(copied.gadget, copied.isInTable)
      }
      if (e.key === 'Escape') {
        endA11yDrag()
        if (
          selected &&
          (!configPanel.current ||
            !configPanel.current.contains(document.activeElement))
        ) {
          setSelected(null)
        } else if (
          !selected &&
          container.current.contains(document.activeElement) &&
          gadgetList.current
        ) {
          const toFocus = gadgetList.current.querySelectorAll(
            'button, [role="button"], [tabindex="0"]'
          )[0]
          toFocus?.focus()
        }
      }
    }

    document.addEventListener('keydown', fn)
    return () => document.removeEventListener('keydown', fn)
  }, [configUpdated, endA11yDrag, redo, selected, setA11yDragItem, undo])

  React.useEffect(() => {
    if (configUpdated && selected == null) {
      update(() => props.template, 'cache')
      setConfigUpdated(false)
    }
  }, [configUpdated, selected])

  const withoutProgDisc = cloneDeep(template)
  traverseForm(withoutProgDisc, gadget => {
    delete gadget.conditionalVisibility
  })
  const configSelected = pseudoSelected || selected
  return (
    <FormbotDND.ConfigWrapper>
      {!props.preview && (
        <PortalOrange>
          <button
            className='kp-button-transparent'
            title={
              undo && !selected && !configUpdated
                ? null
                : `${i18n._('pagesbuilder.form.dnd.nothing.undo')}`
            }
            disabled={!undo || selected || configUpdated}
            onClick={() => {
              if (!undo) return
              undo()
              // deselect()
            }}
          >
            <Icons.Undo
              className={cx(
                'mr-2',
                undo && !selected && !configUpdated
                  ? 'fill-blue-500'
                  : 'fill-light-gray-500'
              )}
            />
            <Trans id='pagesbuilder.form.dnd.undo' />
          </button>
          <button
            className='kp-button-transparent'
            title={
              redo && !selected && !configUpdated
                ? null
                : `${i18n._('pagesbuilder.form.dnd.nothing.redo')}`
            }
            disabled={!redo || selected || configUpdated}
            onClick={() => {
              if (!redo) return
              redo()
              // deselect()
            }}
          >
            <Icons.Redo
              className={cx(
                'mr-2',
                redo && !selected && !configUpdated
                  ? 'fill-blue-500'
                  : 'fill-light-gray-500'
              )}
            />
            <Trans id='pagesbuilder.form.dnd.redo' />
          </button>
        </PortalOrange>
      )}
      <div className='flex text-sm'>
        <div className='z-10 w-[190px] border-r border-light-gray-300 bg-white print:hidden'>
          <UI.Scrollable isTable={isTable}>
            <GadgetList
              ref={gadgetList}
              isAnonymous={props.canAnonymousCreate}
              canonicalGadgets={availableCanonicalGadgets}
              gadgets={formbotDND.context.gadgets}
              gadgetIndexTypesMap={gadgetIndexTypesMap}
              gadgetMapById={gadgetMapById}
              beginDrag={beginMouseDrag}
              beginA11yDrag={beginA11yDrag}
              endDrag={endDrag}
              a11yDragItem={a11yDragItem}
            />
          </UI.Scrollable>
        </div>
        <div className='flex-1 bg-white'>
          <UI.Scrollable
            isTable={isTable}
            ref={container}
            className='m-0 print:!h-[auto] print:!overflow-visible'
          >
            <VoronoiDND.Gatherer
              dragging={dragging || !!a11yDragItem}
              container={container}
            >
              <div className='flex-1 px-6 pb-[500px] pt-3'>
                {template && !isEmpty(template) ? (
                  <FormbotDND.Edit
                    className='formbot-config'
                    document={{
                      data: previewItem,
                      meta: {
                        createdBy: user,
                        submittedAt: null,
                        createdAt: +new Date(),
                        updatedAt: +new Date(),
                        serialNumber: '0001'
                      }
                    }}
                    onChange={handleUpdatePreview}
                    context={{
                      a11yDragItem,
                      canonicalGadgetsMap,
                      copy: keyboardCopyGadget,
                      labelSize: props.labelSize,
                      documentMeta: {
                        createdBy: user
                      },
                      beginDrag,
                      beginA11yDrag,
                      endDrag,
                      remove: removeGadget,
                      select: onSelect,
                      selected,
                      selectedLinks: pseudoSelectedLink
                        ? [pseudoSelectedLink]
                        : dragging
                          ? null
                          : selectedLinks,
                      template: withoutProgDisc,
                      configMode: true
                    }}
                    structure={{
                      template: withoutProgDisc,
                      metaFields: props.metaFields,
                      integrationFields: props.integrationFields,
                      trashed: []
                    }}
                  />
                ) : (
                  <VoronoiDND.Item
                    id='inner-ROOT'
                    ariaLabel={getAriaLabel({ label: 'form' })}
                    inside
                  >
                    <InitialContent />
                  </VoronoiDND.Item>
                )}
                <VoronoiComponents
                  onDrop={handleDrop}
                  isInvalid={isInvalid}
                  a11yDragItem={a11yDragItem}
                  endA11yDrag={endA11yDrag}
                />
              </div>
            </VoronoiDND.Gatherer>
          </UI.Scrollable>
          <div hidden id='keyboard-movable-help'>
            <Trans id='pagesbuilder.form.dnd.enter.escape' />
          </div>
        </div>
        <div className='z-10 w-[350px] border-l border-light-gray-300 bg-white print:hidden'>
          <UI.Scrollable isTable={isTable} onClick={e => e.stopPropagation()}>
            {configSelected ? (
              <Config
                ref={configPanel}
                key={configSelected.id}
                selectedLinks={(selectedLinks || pseudoSelectedLinks)?.map(a =>
                  findGadgetById(props.template, a)
                )}
                value={configSelected}
                gadgetIndexType={gadgetIndexTypesMap[configSelected.id]}
                update={onUpdateConfig(configSelected.id, selectedLinks)}
                structure={{
                  template,
                  metaFields: props.metaFields,
                  integrationFields: props.integrationFields,
                  trashed: []
                }}
                isProduct={props.isProduct}
                errors={configErrors[configSelected.id]}
                removeGadget={() => removeGadget(configSelected)}
                duplicateGadget={isInTable =>
                  duplicateGadget(configSelected, isInTable)
                }
                gadgets={formbotDND.context.gadgets}
                beginDrag={() => beginMouseDrag({ type: 'DataLink' }, true)}
                beginA11yDrag={gadget => beginA11yDrag(gadget, true)}
                endDrag={endDrag}
              />
            ) : template && !isEmpty(template) ? (
              <EmptyConfig />
            ) : null}
          </UI.Scrollable>
        </div>
      </div>
    </FormbotDND.ConfigWrapper>
  )
}

function VoronoiComponents ({ onDrop, isInvalid, a11yDragItem, endA11yDrag }) {
  const [dropZoneProps, setDropZoneProps] = React.useState(null)
  const onHover = React.useCallback(
    dropContext => {
      const dropZoneProps = getDropZoneProps(dropContext, isInvalid)
      setDropZoneProps(dropZoneProps)
    },
    [isInvalid]
  )
  return (
    <>
      {dropZoneProps && <VoronoiDND.DropZone {...dropZoneProps} />}
      <VoronoiDND.Voronoi
        onDrop={onDrop}
        onHover={onHover}
        a11yDragItem={a11yDragItem}
        endA11yDrag={endA11yDrag}
        isInvalid={isInvalid}
      />
    </>
  )
}

function getLinked (selected, template) {
  let id = selected?.id
  if (selected?.type === 'DataLink') {
    id = selected.details.parentId
    if (id) id = id.replace('data.', '')
  }
  if (!id) return null
  const linked = []
  traverseFormSafe(template, gadget => {
    if (gadget.details?.parentId === `data.${id}`) linked.push(gadget.id)
  })
  if (!linked.length) return null
  return [id, ...linked]
}

function getConditionalVisibilityReferences (gadget, template) {
  const references = []
  traverseFormSafe(template, g => {
    if (!g.conditionalVisibility?.enabled) return
    const parts = get(g, 'conditionalVisibility.value.parts', [])
    if (some(parts, part => part.formKey === `data.${gadget.formKey}`)) {
      references.push(g)
    }
  })
  return references
}
