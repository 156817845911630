/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery } from '@apollo/client'
import _, { isArray, isObject } from 'lodash'
import React from 'react'

import Spinner from '../../../../components/spinner'
import { useIds } from '../../../../components/use-ids'
import { gadgets } from '../../../../formbot'
import * as Icons from '../../../../icons'
import { formatNumber } from '../../utils'

export default function Chart ({ value, insight }) {
  const { appId, datasetId } = useIds()
  const [page, setPage] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const rows = loading ? [] : value.rows
  const [fetchValue] = useLazyQuery(query)
  function refetchData (page) {
    setPage(page)
    setLoading(true)
    fetchValue({
      fetchPolicy: 'cache-and-network',
      variables: {
        appId,
        datasetId: datasetId || 'BUILD',
        insightId: insight.id,
        options: { page }
      },
      onCompleted: () => setLoading(false)
    })
  }
  if (insight.details.columns.length === 0) {
    return <div>Table Columns Not Configured</div>
  }
  return (
    <div>
      <div className='min-h-72 overflow-auto'>
        <table className='w-full border-collapse rounded-lg bg-white'>
          <thead>
            <tr>
              {insight.details.columns.map(column => (
                <th
                  key={column.id}
                  className='p-2 text-left font-medium text-medium-gray-500'
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map(value => (
              <tr
                key={value._id}
                className='cursor-pointer border border-transparent border-y-light-gray-300 last:border-b-0 hover:bg-light-gray-200'
                onClick={() =>
                  window.open(
                    datasetId
                      ? `/app/${appId}/page/${insight.datasetId}/document-list/${value._id}/view`
                      : `/document-list/${appId}/${value._id}/view`
                  )
                }
              >
                {insight.details.columns.map(column => (
                  <td
                    key={column.id}
                    className='px-2 py-1 text-medium-gray-500'
                  >
                    <Value
                      value={_.get(value, column.formKey)}
                      type={column.type}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {loading && (
          <div className='flex justify-center bg-white py-16'>
            <Spinner size={120} />
          </div>
        )}
      </div>
      <div className='flex items-center justify-end gap-2 pt-3'>
        <span>
          {formatNumber(page * 5 + 1)} -{' '}
          {formatNumber(Math.min((page + 1) * 5, value.limit))} of{' '}
          {formatNumber(value.limit)}
        </span>
        <button
          aria-label='previous page'
          className='kp-button-transparent kp-button-icon'
          disabled={!page}
          onClick={() => refetchData(page - 1)}
        >
          <Icons.SelectDownArrow className='rotate-90' />
        </button>
        <button
          aria-label='next-page'
          className='kp-button-transparent kp-button-icon'
          disabled={(page + 1) * 5 >= value.limit}
          onClick={() => refetchData(page + 1)}
        >
          <Icons.SelectDownArrow className='-rotate-90' />
        </button>
      </div>
    </div>
  )
}

function Value ({ value, type }) {
  const { View } = gadgets[type] || {}
  if (View) return <View value={value} condensed />
  return stringify(value)
}

function stringify (value) {
  value = value?.label || value
  if (isArray(value)) return value.map(stringify).join(', ')
  if (isObject(value)) return ''
  return value
}

const query = gql`
  query FetchInsight(
    $appId: ID!
    $datasetId: ID!
    $insightId: ID!
    $options: JSON!
  ) {
    app(id: $appId) {
      id
      dashboard(id: $datasetId) {
        id
        insight(id: $insightId) {
          id
          value(options: $options)
        }
      }
    }
  }
`
