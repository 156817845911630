/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as filters from './filters'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validation'
import View from './view'

export default {
  Edit: View,
  View,
  sortSuffix: '.label',
  meta: {
    hidden: true,
    category: 'Special',
    label: 'Payment Options'
  },
  defaultValue: null,
  sampleValue: { id: '', label: '- - -', amount: 0 },
  filters,
  progressiveDisclosure,
  validateShape
}
