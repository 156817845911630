/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import DisplaySignature from './parts/display-signature'
import SignatureModal from './parts/signature-modal'

export default function SignatureEdit ({
  context,
  formKey,
  details,
  onChange,
  value,
  ...others
}) {
  const cacheKey = 'rowId' in details ? `${details.rowId}.${formKey}` : formKey
  const [showModal, setShowModal] = React.useState(false)
  const [imagePreview, setImagePreview] = React.useState(
    context?.imageCache?.[cacheKey]
  )
  const currentUser = context?.currentUser
  const actionId = context?.actionId
  const updateImagePreview = imagePreview => {
    setImagePreview(imagePreview)
    if (context?.updateImageCache) {
      context.updateImageCache(cacheKey, imagePreview)
    }
  }
  if (!actionId) {
    return (
      <Wrapper>
        <DisplaySignature value={value} />
      </Wrapper>
    )
  }
  if (value) {
    return (
      <Wrapper>
        <DisplaySignature imagePreview={imagePreview} value={value} />
        {currentUser?.id === value.userId && (
          <Button outline ml={3} onClick={() => onChange()}>
            <Trans id='remove' />
          </Button>
        )}
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <button
        className='kp-button-solid'
        aria-labelledby={others['aria-labelledby']}
        {...(others['aria-describedby'] && {
          'aria-describedby': others['aria-describedby']
        })}
        onClick={() => !context?.configMode && setShowModal(true)}
      >
        <Icons.Edit height={12} width={12} fill='#fff' />
        <ButtonText>
          <Trans id='sign.this.form' />
        </ButtonText>
      </button>
      <SignatureModal
        actionId={actionId}
        currentUser={currentUser}
        formKey={formKey}
        imagePreview={imagePreview}
        updateImagePreview={updateImagePreview}
        onChange={onChange}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  flex-wrap: wrap;
`
const ButtonText = styled.span`
  padding-left: 8px;
`
