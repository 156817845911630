/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Illustration from '../../illustrations'
import Button from '../../ui/button'

export const NoMatching = ({ text, subtext, reset }) => (
  <Wrapper className='text-sm'>
    <Illustration.Empty width={214} height={298} />
    <Message>
      <p className='pb-3'>
        <strong>{text ?? <Trans id='no.matching.search.results' />}</strong>
      </p>
      <p className='pb-3'>
        {subtext ?? <Trans id='please.try.again.general.search.terms' />}
      </p>
    </Message>
    <Button onClick={reset}>
      <Trans id='clear.all.filters' />
    </Button>
  </Wrapper>
)

export const NoResults = ({ text, subtext }) => (
  <Wrapper className='text-sm'>
    <Illustration.Review width={342} height={303} />
    <Message>
      <p className='pb-3'>
        <strong>{text ?? 'No results yet'}</strong>
      </p>
      <p className='pb-3'>
        {subtext ?? "We're still waiting on submissions to come in"}
      </p>
    </Message>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 144px;
`

const Message = styled.div`
  margin: 25px 0;
  text-align: center;
`
