/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export function validateShape (v) {
  return v.oneOfType([
    v.shape({ type: v.oneOf(['single']), credits: v.number() }),
    v.shape({ type: v.oneOf(['multiple']), credits: v.arrayOf(v.number()) }),
    v.shape({
      type: v.oneOf(['range']),
      credits: v.shape({ min: v.number(), max: v.number() })
    })
  ])
}
