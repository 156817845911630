/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useDiscardDraftMutation = (appId, pageId) => {
  const [discardDraft] = useMutation(mutation)
  return () => discardDraft(getParams(appId, pageId))
}

const mutation = gql`
  mutation DiscardDraft($id: ID!, $pageId: ID) {
    discardDraft(args: { id: $id, pageId: $pageId }) {
      id
      hasDraft
      dataset(id: $pageId) {
        id
        hasDraft
        isPublished
        draft {
          changes {
            id
            action
            type
            descriptor
            descriptorId
          }
        }
      }
    }
  }
`

const getParams = (id, pageId) => ({ variables: { id, pageId } })
