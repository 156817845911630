/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path d={`M15.4126 13.3334L9.18131 1.43338C9.06838 1.21788 8.89863 1.03738 8.69045 0.911443C8.48228 0.785507 8.24361 0.718933 8.00031 0.718933C7.75701 0.718933 7.51834 0.785507 7.31016 0.911443C7.10199 1.03738 6.93224 1.21788 6.81931 1.43338L0.587309 13.3334C0.480856 13.5366 0.428645 13.7639 0.435724 13.9932C0.442803 14.2225 0.508933 14.4461 0.627719 14.6424C0.746505 14.8387 0.913928 15.001 1.1138 15.1136C1.31367 15.2263 1.53922 15.2854 1.76864 15.2854H14.2313C14.4607 15.2854 14.6863 15.2263 14.8862 15.1136C15.086 15.001 15.2534 14.8387 15.3722 14.6424C15.491 14.4461 15.5571 14.2225 15.5642 13.9932C15.5713 13.7639 15.5191 13.5366 15.4126 13.3334ZM7.33331 5.61538C7.33331 5.43857 7.40355 5.269 7.52857 5.14398C7.6536 5.01895 7.82316 4.94872 7.99998 4.94872C8.17679 4.94872 8.34636 5.01895 8.47138 5.14398C8.59641 5.269 8.66664 5.43857 8.66664 5.61538V9.61538C8.66664 9.79219 8.59641 9.96176 8.47138 10.0868C8.34636 10.2118 8.17679 10.282 7.99998 10.282C7.82316 10.282 7.6536 10.2118 7.52857 10.0868C7.40355 9.96176 7.33331 9.79219 7.33331 9.61538V5.61538ZM8.03331 13.2887H8.01464C7.75174 13.2879 7.49934 13.1854 7.31023 13.0028C7.12112 12.8201 7.00995 12.5714 6.99998 12.3087C6.99517 12.1781 7.01645 12.0477 7.06258 11.9254C7.10871 11.8031 7.17876 11.6911 7.26863 11.5962C7.3585 11.5012 7.4664 11.4251 7.58602 11.3723C7.70564 11.3195 7.83458 11.2911 7.96531 11.2887H7.98398C8.24683 11.289 8.49938 11.391 8.68875 11.5733C8.87813 11.7556 8.98965 12.0041 8.99998 12.2667C9.00515 12.3977 8.98409 12.5283 8.93803 12.651C8.89198 12.7738 8.82186 12.886 8.7318 12.9812C8.64174 13.0764 8.53355 13.1527 8.4136 13.2055C8.29365 13.2583 8.16435 13.2866 8.03331 13.2887Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M15.4126 13.3334L9.18131 1.43338C9.06838 1.21788 8.89863 1.03738 8.69045 0.911443C8.48228 0.785507 8.24361 0.718933 8.00031 0.718933C7.75701 0.718933 7.51834 0.785507 7.31016 0.911443C7.10199 1.03738 6.93224 1.21788 6.81931 1.43338L0.587309 13.3334C0.480856 13.5366 0.428645 13.7639 0.435724 13.9932C0.442803 14.2225 0.508933 14.4461 0.627719 14.6424C0.746505 14.8387 0.913928 15.001 1.1138 15.1136C1.31367 15.2263 1.53922 15.2854 1.76864 15.2854H14.2313C14.4607 15.2854 14.6863 15.2263 14.8862 15.1136C15.086 15.001 15.2534 14.8387 15.3722 14.6424C15.491 14.4461 15.5571 14.2225 15.5642 13.9932C15.5713 13.7639 15.5191 13.5366 15.4126 13.3334ZM7.33331 5.61538C7.33331 5.43857 7.40355 5.269 7.52857 5.14398C7.6536 5.01895 7.82316 4.94872 7.99998 4.94872C8.17679 4.94872 8.34636 5.01895 8.47138 5.14398C8.59641 5.269 8.66664 5.43857 8.66664 5.61538V9.61538C8.66664 9.79219 8.59641 9.96176 8.47138 10.0868C8.34636 10.2118 8.17679 10.282 7.99998 10.282C7.82316 10.282 7.6536 10.2118 7.52857 10.0868C7.40355 9.96176 7.33331 9.79219 7.33331 9.61538V5.61538ZM8.03331 13.2887H8.01464C7.75174 13.2879 7.49934 13.1854 7.31023 13.0028C7.12112 12.8201 7.00995 12.5714 6.99998 12.3087C6.99517 12.1781 7.01645 12.0477 7.06258 11.9254C7.10871 11.8031 7.17876 11.6911 7.26863 11.5962C7.3585 11.5012 7.4664 11.4251 7.58602 11.3723C7.70564 11.3195 7.83458 11.2911 7.96531 11.2887H7.98398C8.24683 11.289 8.49938 11.391 8.68875 11.5733C8.87813 11.7556 8.98965 12.0041 8.99998 12.2667C9.00515 12.3977 8.98409 12.5283 8.93803 12.651C8.89198 12.7738 8.82186 12.886 8.7318 12.9812C8.64174 13.0764 8.53355 13.1527 8.4136 13.2055C8.29365 13.2583 8.16435 13.2866 8.03331 13.2887Z\" />\n</svg>\n";
export default src;
